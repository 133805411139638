export const colors = [
  {
    name: 'black',
    color: '#000000',
  },
  {
    name: 'dark gray 4',
    color: '#434343',
  },
  {
    name: 'dark gray 3',
    color: '#666666',
  },
  {
    name: 'dark gray 2',
    color: '#999999',
  },
  {
    name: 'dark gray 1',
    color: '#b7b7b7',
  },
  {
    name: 'gray',
    color: '#cccccc',
  },
  {
    name: 'light gray 1',
    color: '#d9d9d9',
  },
  {
    name: 'light gray 2',
    color: '#efefef',
  },
  {
    name: 'light gray 3',
    color: '#f3f3f3',
  },
  {
    name: 'white',
    color: '#ffffff',
  },
  {
    name: 'red berry',
    color: '#980000',
  },
  {
    name: 'red',
    color: '#ff0000',
  },
  {
    name: 'orange',
    color: '#ff9900',
  },
  {
    name: 'yellow',
    color: '#ffff00',
  },
  {
    name: 'green',
    color: '#00ff00',
  },
  {
    name: 'cyan',
    color: '#00ffff',
  },
  {
    name: 'cornflower blue',
    color: '#4a86e8',
  },
  {
    name: 'blue',
    color: '#0000ff',
  },
  {
    name: 'purple',
    color: '#9900ff',
  },
  {
    name: 'magenta',
    color: '#ff00ff',
  },
  {
    name: 'light red berry 3',
    color: '#e6b8af',
  },
  {
    name: 'light red 3',
    color: '#f4cccc',
  },
  {
    name: 'light orange 3',
    color: '#fce5cd',
  },
  {
    name: 'light yellow 3',
    color: '#fff2cc',
  },
  {
    name: 'light green 3',
    color: '#d9ead3',
  },
  {
    name: 'light cyan 3',
    color: '#d0e0e3',
  },
  {
    name: 'light cornflower blue 3',
    color: '#c9daf8',
  },
  {
    name: 'light blue 3',
    color: '#cfe2f3',
  },
  {
    name: 'light purple 3',
    color: '#d9d2e9',
  },
  {
    name: 'light magenta 3',
    color: '#ead1dc',
  },
  {
    name: 'light red berry 2',
    color: '#dd7e6b',
  },
  {
    name: 'light red 2',
    color: '#ea9999',
  },
  {
    name: 'light orange 2',
    color: '#f9cb9c',
  },
  {
    name: 'light yellow 2',
    color: '#ffe599',
  },
  {
    name: 'light green 2',
    color: '#b6d7a8',
  },
  {
    name: 'light cyan 2',
    color: '#a2c4c9',
  },
  {
    name: 'light cornflower blue 2',
    color: '#a4c2f4',
  },
  {
    name: 'light blue 2',
    color: '#9fc5e8',
  },
  {
    name: 'light purple 2',
    color: '#b4a7d6',
  },
  {
    name: 'light magenta 2',
    color: '#d5a6bd',
  },
  {
    name: 'light red berry 1',
    color: '#cc4125',
  },
  {
    name: 'light red 1',
    color: '#e06666',
  },
  {
    name: 'light orange 1',
    color: '#f6b26b',
  },
  {
    name: 'light yellow 1',
    color: '#ffd966',
  },
  {
    name: 'light green 1',
    color: '#93c47d',
  },
  {
    name: 'light cyan 1',
    color: '#76a5af',
  },
  {
    name: 'light cornflower blue 1',
    color: '#6d9eeb',
  },
  {
    name: 'light blue 1',
    color: '#6fa8dc',
  },
  {
    name: 'light purple 1',
    color: '#8e7cc3',
  },
  {
    name: 'light magenta 1',
    color: '#c27ba0',
  },
  {
    name: 'dark red berry 1',
    color: '#a61c00',
  },
  {
    name: 'dark red 1',
    color: '#cc0000',
  },
  {
    name: 'dark orange 1',
    color: '#e69138',
  },
  {
    name: 'dark yellow 1',
    color: '#f1c232',
  },
  {
    name: 'dark green 1',
    color: '#6aa84f',
  },
  {
    name: 'dark cyan 1',
    color: '#45818e',
  },
  {
    name: 'dark cornflower blue 1',
    color: '#3c78d8',
  },
  {
    name: 'dark blue 1',
    color: '#3d85c6',
  },
  {
    name: 'dark purple 1',
    color: '#674ea7',
  },
  {
    name: 'dark magenta 1',
    color: '#a64d79',
  },
  {
    name: 'dark red berry 2',
    color: '#85200c',
  },
  {
    name: 'dark red 2',
    color: '#990000',
  },
  {
    name: 'dark orange 2',
    color: '#b45f06',
  },
  {
    name: 'dark yellow 2',
    color: '#bf9000',
  },
  {
    name: 'dark green 2',
    color: '#38761d',
  },
  {
    name: 'dark cyan 2',
    color: '#134f5c',
  },
  {
    name: 'dark cornflower blue 2',
    color: '#1155cc',
  },
  {
    name: 'dark blue 2',
    color: '#0b5394',
  },
  {
    name: 'dark purple 2',
    color: '#351c75',
  },
  {
    name: 'dark magenta 2',
    color: '#741b47',
  },
  {
    name: 'dark red berry 3',
    color: '#5b0f00',
  },
  {
    name: 'dark red 3',
    color: '#660000',
  },
  {
    name: 'dark orange 3',
    color: '#783f04',
  },
  {
    name: 'dark yellow 3',
    color: '#7f6000',
  },
  {
    name: 'dark green 3',
    color: '#274e13',
  },
  {
    name: 'dark cyan 3',
    color: '#0c343d',
  },
  {
    name: 'dark cornflower blue 3',
    color: '#1c4587',
  },
  {
    name: 'dark blue 3',
    color: '#073763',
  },
  {
    name: 'dark purple 3',
    color: '#20124d',
  },
  {
    name: 'dark magenta 3',
    color: '#4c1130',
  },
];
