import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ChecklistSectionPreviewComponent } from './checklist-section-preview.component';

const components = [ChecklistSectionPreviewComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatDividerModule],
  providers: [],
  exports: components,
})
export class ChecklistSectionPreviewModule {}
