import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caseFormCaseOrRepeatableCaseFileUsageSelf',
  standalone: true,
})
export class CaseFormCaseOrRepeatableCaseFileUsageSelfPipe implements PipeTransform {
  transform(createRepeatableCase: boolean, caseId?: number): string {
    const useRepeatableCaseSelf = createRepeatableCase && !caseId;

    return useRepeatableCaseSelf ? 'repeatable-case' : 'case';
  }
}
