import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'apex-sanitize-html',
  templateUrl: './sanitize-html.component.html',
  standalone: true,
})
export class SanitizeHtmlComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer) {}
  @Input() textString: string;

  innerHtml: SafeHtml = '';

  ngOnInit(): void {
    if (this.textString) {
      this.innerHtml = this.sanitize(this.textString);
    }
  }

  sanitize(string: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(string);
  }
}
