import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
  standalone: true,
})
export class JoinPipe implements PipeTransform {
  transform(elements: string[], separator = ', '): string {
    if (!elements) {
      return '';
    }

    return elements.join(separator);
  }
}
