import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import { environment } from 'projects/apex/src/environments/environment';
import { UserService } from '../../services/user/user.service';
import { CollectionResponse } from '../../utils/types';

type Request = {
  // case_id: number;
  // case_name: string;
  // client_name: string;
};

const second = 1000;
const time = 5 * 60 * second;

@Component({
  selector: 'apex-requests-button',
  templateUrl: './requests-button.component.html',
})
export class RequestsButtonComponent implements OnInit, OnDestroy {
  requests: CollectionResponse<Request> | null = null;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      timer(second, time)
        .pipe(
          filter((_) => this.userService.profile?.role !== 1),
          mergeMap((_) =>
            this.http.get<CollectionResponse<Request>>(`${environment.api}/request`, {
              withCredentials: true,
              params: {
                limit: 5,
                page: 0,
                search: '',
              },
            }),
          ),
        )
        .subscribe({
          next: (requests) => {
            this.requests = requests;
          },
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
