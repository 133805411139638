import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { AutocompleteModule } from 'projects/apex/src/app/components/autocomplete/autocomplete.module';
import { BoxModule } from 'projects/apex/src/app/components/box/box.module';
import { ModelRoleModule } from 'projects/apex/src/app/components/model-role/model-role.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { FileUsageModule } from '../../../../components/file-usage/file-usage.module';
import { EditorInitPipeModule } from '../../../../pipes/editor-init/editor-init.pipe.module';
import { ObjectSelectorModule } from '../../components/selector/object-selector.module';
import { CaseTenancyInfoComponent } from './case-info.component';
import { EntryCardModule } from './entry-card/entry-card.module';
import { EntryGroupModule } from './entry-group/entry-group.module';
import { TenancyInfoComponent } from './info.component';
import { TenanciesComponent } from './tenancies.component';
import { TenancyRoutingModule } from './tenancy-routing.module';
import { TenancyComponent } from './tenancy.component';
import { TenancyService } from './tenancy.service';

@NgModule({
  declarations: [TenanciesComponent, TenancyComponent, TenancyInfoComponent, CaseTenancyInfoComponent],
  imports: [
    CommonModule,
    FormsModule,

    MatIconModule,
    MatChipsModule,
    MatDividerModule,
    MatTooltipModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatRippleModule,
    MatPaginatorModule,

    FileUsageModule,
    ObjectSelectorModule,
    TenancyRoutingModule,
    EntryCardModule,
    EntryGroupModule,
    TranslateModule,
    ModelRoleModule,
    AutocompleteModule,
    BoxModule,
    EditorComponent,
    EditorInitPipeModule,
  ],
  providers: [TenancyService],
  exports: [TenanciesComponent, TenancyComponent, TenancyInfoComponent, CaseTenancyInfoComponent],
})
export class TenancyModule {}
