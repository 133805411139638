import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NavigationExtras } from '@angular/router';
import { Case } from 'projects/apex/src/app/models/case';
import { onDialogClose } from '../../../utils/functions';
import { CaseViewComponent } from './view.component';

@Component({
  selector: 'apex-case-view-dialog',
  templateUrl: './dialog.component.html',
})
export class CaseViewDialogComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() caseChange = new EventEmitter();

  @ViewChild('viewComponent') viewComponent: CaseViewComponent;

  selectedTabIndex = 0;

  disableSave = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      caseId: number;
      case: Case;
      highlighted: boolean;
      contentClass: string;
      isClient?: boolean;
    },
    public ref: MatDialogRef<CaseViewComponent>,
  ) {}

  ngOnInit(): void {
    this.ref?.addPanelClass(['apex-fullscreen-dialog', 'phone']);
    this.ref.disableClose = true;
    onDialogClose(this.ref).subscribe(() => {
      this.close();
    });
  }

  close(whereTo?: { commands: (string | number)[]; extras?: NavigationExtras }): void {
    this.ref.close({
      case: this.data?.case,
      navigate: whereTo,
    });
  }
}
