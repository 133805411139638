import { File as ApexFile } from 'projects/apex/src/app/models/file';
import { Folder } from '../../models/folder';

// export interface FileSystemDirectoryReader {
//   readEntries: (fn: (fe: FileEntry[]) => void) => FileEntry[];
// }

// export interface FileEntry {
//   isDirectory: boolean;
//   isFile: boolean;
//   name: string;
//   fullPath: string;
//   size: number;
//   file: (success: (value: File | PromiseLike<File>) => void, error: () => void) => void;
//   createReader: () => FileSystemDirectoryReader;
// }

export enum UploadStatus {
  Pending = 'pending',
  Ongoing = 'ongoing',
  Success = 'success',
  Error = 'error',
}

export interface UploadData {
  target: Folder;
  name: string;
  folder: string;
  type: string;
  size: number;
  status: UploadStatus;
  file?: File;
  File?: ApexFile;
  Folder?: Folder;
  FolderId?: string;
  canceled?: boolean;
  ready: boolean;
}
