import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { BoxComponent } from './box.component';

@NgModule({
  imports: [CommonModule, MatExpansionModule, MatIconModule, MatProgressBarModule],
  exports: [BoxComponent],
  declarations: [BoxComponent],
  providers: [],
})
export class BoxModule {}
