import { Component, Input } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import moment from 'moment';
import { Observable } from 'rxjs';
import { Profile } from '../../models/profile';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'apex-topbar',
  templateUrl: './topbar.component.html',
})
export class TopbarComponent {
  @Input() drawer: MatDrawer;

  get startOfDay(): number {
    return moment().startOf('day').unix();
  }

  get endOfDay(): number {
    return moment().endOf('day').unix();
  }

  profile$: Observable<Profile>;

  constructor(private userService: UserService) {
    this.profile$ = this.userService.profile$;
  }

  toggle(): void {
    void this.drawer?.toggle();
  }
}
