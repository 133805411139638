import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ILanguage } from '@transifex/native';
import { noop } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TranslationService } from '../translate/translation.service';

@Component({
  selector: 'apex-language-picker',
  templateUrl: 'language-picker.component.html',
  providers: [
    TranslationService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguagePickerComponent),
      multi: true,
    },
  ],
})
export class LanguagePickerComponent implements OnInit, ControlValueAccessor {
  protected value = 'en';

  languages$: Observable<ILanguage[]>;

  onChange: (value: string) => void = noop;
  onTouched: () => void = noop;

  modelChange = new EventEmitter<string>();

  protected innerDisabled = false;
  protected innerRequired = false;

  @Input()
  get disabled(): boolean {
    return this.innerDisabled;
  }

  set disabled(value: boolean | string) {
    this.innerDisabled = coerceBooleanProperty(value);
  }

  @Input()
  get required(): boolean {
    return this.innerRequired;
  }

  set required(value: boolean | string) {
    this.innerRequired = coerceBooleanProperty(value);
  }

  constructor(private readonly translationService: TranslationService) {}

  @Input()
  get language(): string {
    return this.value;
  }

  set language(value: string) {
    this.value = value;
  }

  ngOnInit(): void {
    this.languages$ = of([]).pipe(mergeMap(() => this.translationService.getLanguages()));
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    if (!value) {
      return;
    }

    this.value = value;
  }
}
