import { NgModule } from '@angular/core';

import { HistoryBackDirective } from './history-back.directive';

@NgModule({
  imports: [],
  exports: [HistoryBackDirective],
  declarations: [HistoryBackDirective],
  providers: [],
})
export class HistoryBackModule {}
