<div class="container df f1 c">
  <header class="toolbar df r jsb ac f1">
    <h1 class="mat-body-1">
      <span
        *ngIf="!selectedParent"
        class="apls">
        <T str="Select Objects"></T>
      </span>
      <span *ngIf="selectedParent">
        <button
          type="button"
          mat-icon-button
          (click)="back(selectedParent.ParentId)">
          <mat-icon>arrow_back</mat-icon>
        </button>
        {{ selectedParent.name }}
      </span>
    </h1>
    <button
      type="button"
      mat-icon-button
      (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <main class="df ac jc c">
    <mat-spinner *ngIf="loading"></mat-spinner>

    <T *ngIf="!loading && !filteredObjects.length"> No objects found "></T>

    <cdk-virtual-scroll-viewport
      *ngIf="!loading && filteredObjects.length"
      class="viewport"
      itemSize="10">
      <div
        class="item df r ac new"
        mat-ripple
        (click)="toggleSelected(obj)"
        [ngClass]="{ selected: selectedObjects[obj.id] }"
        *cdkVirtualFor="let obj of filteredObjects">
        <mat-icon class="amr">{{ obj | icon }}</mat-icon>
        <div
          class="f2 oh"
          [matTooltip]="obj.description"
          matTooltipShowDelay="1003">
          <div class="df c">
            <span [innerHTML]="obj.name | highlight: filterSearch"> </span>
            <div class="mat-caption truncate">
              <ng-container *ngIf="obj.ParentId">
                {{ getPath(obj.ParentId) }}
              </ng-container>
              <ng-container *ngIf="!obj.ParentId">
                {{ obj.Customer?.name }}
              </ng-container>
            </div>
          </div>

          <ng-container *ngIf="obj.meta === 'safety' && obj.type === 'product'">
            <span class="gray-color">
              {{ obj.data.type }}
              <ng-container *ngIf="obj.data.type && obj.data.serialNumber"> / </ng-container>
              {{ obj.data.serialNumber }}
            </span>
          </ng-container>
        </div>
        <button
          type="button"
          mat-icon-button
          *ngIf="canMove(obj)"
          (click)="setParent(obj); $event.stopPropagation()">
          <mat-icon>arrow_right</mat-icon>
        </button>
      </div>
    </cdk-virtual-scroll-viewport>
  </main>
  <mat-divider></mat-divider>
  <footer class="df c f1">
    <div class="df r ac jsb">
      <mat-checkbox
        (change)="filter()"
        [(ngModel)]="filterSelected">
        <T
          str="Show selected {count, plural, =0 {} other {(#)}}"
          [vars]="{ count: selectedCount() }"></T>
      </mat-checkbox>

      <button
        mat-button
        color="primary"
        (click)="selectAll()">
        <T
          *ngIf="!selectedCount()"
          str="Select all"></T>
        <T
          *ngIf="selectedCount()"
          str="Select none"></T>
      </button>
    </div>

    <div class="df ac jsb">
      <div class="df f1">
        <mat-form-field class="f1 amr">
          <mat-label>
            <T str="Search"></T>
          </mat-label>
          <input
            matInput
            [(ngModel)]="filterSearch"
            (keyup)="filter()"
            #searchInput />
        </mat-form-field>
      </div>
      <div>
        <button
          mat-raised-button
          color="primary"
          (click)="select()">
          <T str="Select"></T>
        </button>
      </div>
    </div>
  </footer>
</div>
