import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment, { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { t } from '../../../components/translate/translate.function';
import { Case, Contractor } from '../../../models/case';
import { User } from '../../../models/user';
import { snack } from '../../../modules/snack.module';
import { UserService } from '../../../services/user/user.service';
import { isFromBeforeTo } from '../../../utils/functions';

@Component({
  selector: 'apex-contractor-dialog',
  templateUrl: 'form-dialog.component.html',
})
export class ContractorDialogComponent implements OnDestroy {
  contractor: Contractor;

  fromBeforeTo: boolean;

  minDate: Moment;
  maxDate: Moment;

  currentUser: User;

  private subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<ContractorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      case: Case;
      view: boolean;
      edit: boolean;
      contractor: Contractor;
    },
    private userService: UserService,
  ) {
    if (this.data?.contractor) {
      this.contractor = new Contractor(this.data.contractor);
    } else {
      this.contractor = new Contractor();
    }

    this.checkFromBeforeTo();

    this.subscription.add(
      this.userService.filteredProfile$.subscribe({
        next: (user) => {
          this.currentUser = user;
          this.setMinMax();
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  save(): void {
    if (this.contractor.id) {
      if (this.data?.edit) {
        this.dialogRef.close({
          contractor: this.contractor,
        });

        return;
      }

      if (this.data?.case?.Contractors?.find((c) => c.id === this.contractor.id)) {
        snack(t('Contractor already added'));
        this.contractor.id = null;
      } else {
        this.dialogRef.close({
          contractor: this.contractor,
        });
      }
    }
  }

  checkFromBeforeTo(): boolean {
    this.fromBeforeTo = isFromBeforeTo(this.contractor.CaseContractor.from, this.contractor.CaseContractor.to);

    return this.fromBeforeTo;
  }

  setMinMax(): void {
    const contractor = this.data?.case?.Contractors?.find((c) => c.id === this.currentUser.id);
    const parentContractor = contractor
      ? this.data?.case?.Contractors?.find(
          (c) => c.id === contractor.CaseContractor.CreatorId && contractor.CaseContractor.CreatorId !== contractor.id,
        )
      : null;

    this.minDate = moment(this.data?.case?.from);
    this.maxDate = moment(this.data?.case?.to);

    if (parentContractor?.CaseContractor) {
      this.minDate = parentContractor?.CaseContractor?.from
        ? moment(parentContractor?.CaseContractor?.from)
        : this.minDate;
      this.maxDate = parentContractor?.CaseContractor?.to ? moment(parentContractor?.CaseContractor?.to) : this.maxDate;
    }

    this.contractor.CaseContractor.from = this.contractor?.CaseContractor?.from || this.minDate.toDate();
    this.contractor.CaseContractor.to = this.contractor?.CaseContractor?.to || this.maxDate.toDate();
  }
}
