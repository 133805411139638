import { Injectable } from '@angular/core';
import { UserApi } from '../../../../../../../generated/apex-rest';
import { t } from '../../../components/translate/translate.function';
import { snack, snackErr } from '../../../modules/snack.module';
import { UserService } from '../../../services/user/user.service';
import { restAxiosConfig } from '../../../utils/rest-axios-config';

@Injectable()
export default class ProfileService {
  userApi: UserApi;

  constructor(private userService: UserService) {
    this.userApi = new UserApi(restAxiosConfig());
  }

  get userId(): number {
    return this.userService.profile.id;
  }

  get hasStartpage(): boolean {
    return !!this.userService?.profile?.startpage;
  }

  async setStartpage(userId: number, startPage: string): Promise<void> {
    try {
      await this.userApi.userControllerCreateStartPage(userId, { startPage });

      this.userService.profile.startpage = startPage;

      snack(t('Startpage set'));
    } catch (err) {
      snackErr(t('Could not set startpage'), err);
    }
  }

  async deleteStartpage(userId: number): Promise<void> {
    try {
      await this.userApi.userControllerDeleteStartPage(userId);

      this.userService.profile.startpage = null;
      snack(t('Startpage cleared'));
    } catch (err) {
      snackErr(t('Could not clear startpage'), err);
    }
  }
}
