import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadService } from 'projects/apex/src/app/components/upload/upload.service';
import { UploadData } from 'projects/apex/src/app/components/upload/upload.types';
import { Folder } from 'projects/apex/src/app/models/folder';
import { FolderService } from 'projects/apex/src/app/services/folder/folder.service';
import { sortNatural } from 'projects/apex/src/app/utils/functions';
import { CollectionResponse } from 'projects/apex/src/app/utils/types';
import { Subscription } from 'rxjs';
import { Profile } from '../../../models/profile';
import { UserService } from '../../../services/user/user.service';
import { t } from '../../translate/translate.function';
import { FolderExt } from '../folder.types';
import { folderIcon } from '../folder.utils';

@Component({
  selector: 'apex-folder-tree',
  templateUrl: './tree.component.html',
})
export class FolderTreeComponent implements OnInit, AfterViewInit {
  @Output() folderChange = new EventEmitter<FolderExt>();
  @Output() foldersChange = new EventEmitter<FolderExt[]>();
  @Output() folderDrop = new EventEmitter<FolderExt>();

  private readonly defaultPagination = {
    orderBy: 'name',
    order: 'asc',
    page: String(0),
    limit: String(25),
  } as const;

  folderValue: FolderExt;

  @Input()
  get folder(): FolderExt {
    return this.folderValue;
  }

  set folder(folder: FolderExt) {
    this.folderValue = folder;
    this.folderChange.emit(this.folder);
  }

  homeFolders: FolderExt[];
  folders: FolderExt[];
  loading: boolean;

  loadingFolderId: string;

  profile: Profile;

  sub = new Subscription();

  constructor(
    private ref: ElementRef,
    private folderService: FolderService,
    private uService: UploadService,
    private userService: UserService,
  ) {
    this.profile = this.userService.profile;
  }

  ngOnInit(): void {
    this.loading = true;
    this.folderService.children('home', { params: this.defaultPagination }).subscribe({
      next: (res: CollectionResponse<FolderExt>) => {
        this.homeFolders = res.Collection;
        this.folders = res.Collection;
        this.sort();
        this.sub.add(
          this.uService.complete.subscribe({
            next: (data: UploadData) => {
              if (data.Folder) {
                this.addFolder(data.Folder);
              }
            },
          }),
        );
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  ngAfterViewInit(): void {
    this.ref.nativeElement.classList.add('steppable');
  }

  toggleExpanded(folder: FolderExt): void {
    folder.expanded = !folder.expanded;

    if (folder.hasChildren && !folder.Children?.length) {
      this.loadingFolderId = folder.id;
      this.folderService.children(folder.id.toString(), { params: this.defaultPagination }).subscribe({
        next: (res: CollectionResponse<FolderExt>) => {
          folder.childrenCount = res.count;
          this.folders = this.folders.concat(res.Collection);
          folder.Children = sortNatural(res.Collection, 'name');
          this.loadingFolderId = null;
        },
      });
    }
  }

  loadMoreChildren(folder: FolderExt): void {
    folder.childrenPage = (folder.childrenPage ?? 0) + 1;

    this.folderService
      .children(folder.id, {
        params: { ...this.defaultPagination, page: String(folder.childrenPage) },
      })
      .subscribe({
        next: (res: CollectionResponse<FolderExt>) => {
          this.folders = (this.folders ?? []).concat(res.Collection);
          folder.Children = (folder.Children ?? []).concat(res.Collection);
        },
      });
  }

  updateFolder(folder: Folder, remove?: boolean): void {
    const folderExt = this.folders.find((f) => f.id === folder.id);

    if (folderExt) {
      if (remove) {
        const parent = this.folders.find((f) => f.id === folderExt.ParentId);

        if (parent) {
          parent.childrenCount = parent.childrenCount - 1;
          parent.Children = parent.Children.filter((c) => c.id !== folderExt.id);

          // @todo why is it that we need to skip the first element?
          const idx = this.folders.indexOf(folderExt, 1);

          if (idx !== -1) {
            // @todo why is it the we splice without restriction to deleteCount?
            this.folders.splice(idx);
          }
        }
      } else {
        Object.assign(folderExt, folder);
      }

      this.sort();
    }
  }

  addFolder(folder: Folder): void {
    const parent = this.folders.find((f) => f.id === folder.ParentId);

    if (parent) {
      const children = this.folders.filter((f) => f.ParentId === parent.id);

      if (!parent?.hasChildren || (parent?.hasChildren && children?.length)) {
        parent.Children = parent.Children?.length ? parent.Children.concat([folder]) : [folder];
        this.folders.push(folder as FolderExt);
        this.sort();
      }
    }
  }

  sort(): void {
    this.folders = sortNatural(this.folders, 'name');
  }

  uploadFromDragEvent(event: DragEvent, target: Folder): void {
    event.preventDefault();
    event.stopPropagation();

    if (target.modelName !== 'upload') {
      const entries = UploadService.getEntriesFromDragEvent(event);

      void this.uService.uploadFromDragEvent(entries, target);
    }
  }

  folderName(folder: Folder): string {
    if (folder.modelName && !folder.modelId) {
      if (folder.modelName === 'mom') {
        const parent = this.folders.find((f) => f.id === folder.ParentId);

        if (parent?.modelName === 'project') {
          return t('Common MOM');
        }
      }

      return t(folder.name);
    }

    if (folder.modelName === 'system-copied') {
      return t('System Copied');
    }

    return folder.name;
  }

  folderIcon(folder: Folder): string {
    return folderIcon(folder, this.profile);
  }
}
