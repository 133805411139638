<apex-box
  class="no-padding"
  icon="show_chart"
  [ngClass]="{
    new: state === MeterState.Normal,
    error: state === MeterState.Error,
    warning: state === MeterState.Warning,
  }">
  <ng-container title>
    {{ meter?.name }}
  </ng-container>

  <section class="df c aps">
    <div class="df ac ams">
      <mat-icon
        class="amrs"
        [matTooltip]="'Meter' | translate">
        show_chart
      </mat-icon>
      <a
        class="mat-h3 rm"
        [routerLink]="
          topLevelObjId === meter?.ObjectId
            ? ['/object', 'project', topLevelObjId, 'meter', meter?.id]
            : ['/object', 'project', topLevelObjId, 'object', meter?.ObjectId, 'meter', meter?.id]
        ">
        {{ meter?.name }}
      </a>
    </div>

    <div class="df ac ams">
      <mat-icon class="amrs">
        {{ meter?.Object | icon }}
      </mat-icon>
      <a
        class="mat-h3 rm"
        [routerLink]="
          topLevelObjId === meter?.ObjectId
            ? ['/object', 'project', topLevelObjId]
            : ['/object', 'project', topLevelObjId, 'object', meter?.ObjectId]
        ">
        {{ meter?.Object?.name }}
      </a>
    </div>

    <div class="df ac ams">
      <mat-icon
        class="amrs"
        fontSet="material-icons-sharp"
        [matTooltip]="'Type' | translate">
        donut_small
      </mat-icon>
      <span class="mat-h3 rm">
        {{ meter?.type | translateType }}
      </span>
    </div>

    <div
      class="df ac ams"
      *ngIf="meter?.description">
      <mat-icon
        class="amrs"
        fontSet="material-icons-sharp"
        [matTooltip]="'Description' | translate">
        description
      </mat-icon>
      <span class="mat-h3 rm">
        {{ meter.description }}
      </span>
    </div>

    <div
      class="df ac ams"
      *ngIf="meter.externalNumber">
      <mat-icon
        class="amrs"
        fontSet="material-icons-sharp"
        [matTooltip]="'External' | translate">
        stacked_line_chart
      </mat-icon>
      <span class="mat-h3 rm">
        {{ meter.externalNumber }}
      </span>
    </div>

    <div class="df ac ams">
      <mat-icon
        class="amrs fg-warning"
        fontSet="material-icons-sharp"
        [matTooltip]="'Warning' | translate">
        warning
      </mat-icon>
      <span class="mat-h3 rm"> {{ meter?.warning }} % </span>
    </div>

    <div class="df ac ams">
      <mat-icon
        class="amrs"
        color="warn"
        fontSet="material-icons-sharp"
        [matTooltip]="'Error' | translate">
        error
      </mat-icon>
      <span class="mat-h3 rm"> {{ meter?.error }} % </span>
    </div>
  </section>

  <apex-meter-value-form
    class="db amh amvs"
    [caseId]="caseId"
    [meter]="meter"
    (valueSaved)="meter = $event">
  </apex-meter-value-form>

  <apex-meter-values
    class="df f1"
    [meter]="meter">
  </apex-meter-values>
</apex-box>
