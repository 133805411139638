import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { ProgressComponent } from './progress.component';

@NgModule({
  imports: [CommonModule, TranslateModule, MatProgressSpinnerModule, MatTooltipModule, MatIconModule],
  declarations: [ProgressComponent],
  exports: [ProgressComponent],
})
export class ProgressModule {}
