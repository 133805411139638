import { Component, Input } from '@angular/core';

@Component({
  selector: 'apex-progress',
  templateUrl: './progress.component.html',
})
export class ProgressComponent {
  @Input() count = 0;
  @Input() completed = 0;
  @Input() small: boolean;

  @Input() showPercentage = false;

  get percent(): number {
    return Math.floor(((this.completed || 0) / (this.count || 1)) * 100);
  }
}
