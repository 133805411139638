<ng-container *ngIf="profile$ | async as profile; else noProfile">
  <section class="profile">
    <a
      routerLink="/user/profile"
      routerLinkActive="active">
      <apex-avatar
        class="amr"
        [user]="profile">
      </apex-avatar>

      <div class="df c">
        {{ profile.name }}

        <ng-container *ngIf="profile.Customer?.name">
          <span class="mat-caption green-background add-margin-top-extra-small">
            {{ profile.Customer.name }}
          </span>
        </ng-container>
      </div>
    </a>
  </section>

  <aside class="amb">
    <apex-assume-user></apex-assume-user>
  </aside>

  <nav class="df c f1">
    <div class="df c f1">
      <mat-slide-toggle
        class="ap case-toggle-notify-label"
        [checked]="menuState$ | async"
        (change)="toggleMenu()">
        <T
          class="text-white"
          str="Keep sidebar open"></T>
      </mat-slide-toggle>

      <apex-sidebar-items [profile]="profile"></apex-sidebar-items>
    </div>
  </nav>
</ng-container>

<ng-template #noProfile>
  <section class="f1 df c jfe">
    <a
      routerLink="/auth/sign-in"
      routerLinkActive="active">
      <T
        str="Sign in"
        context="sidebar"
        _context="sidebar"></T>
    </a>
  </section>
</ng-template>
