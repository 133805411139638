import { Component, Input } from '@angular/core';
import { t } from '../../../components/translate/translate.function';
import { CaseLog } from '../../../models/case-log';

@Component({
  selector: 'apex-case-logs',
  templateUrl: './case-logs.component.html',
})
export class CaseLogsComponent {
  @Input() caseId: number;
  @Input() caseLogs: CaseLog[] = [];

  @Input() disableToggleActions = false;
  @Input() disableMessageThumbnails = false;

  @Input() showUserCard = true;

  showSystemMessages = false;

  get visible(): number {
    return this.showSystemMessages ? this.count : this.count - this.system;
  }

  get system(): number {
    return this.caseLogs?.filter((l) => l.system || !l.UserId).length;
  }

  get count(): number {
    return this.caseLogs?.length ?? 0;
  }

  get filterTooltip(): string {
    return t('{showSystemMessages, select, true { Hide system messages } other { Show system messages }}', {
      showSystemMessages: this.showSystemMessages,
    });
  }
}
