import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FilePreviewModule } from 'projects/apex/src/app/components/file-preview/file-preview.module';
import { FolderModule } from 'projects/apex/src/app/components/folder/folder.module';
import { UploadModule } from 'projects/apex/src/app/components/upload/upload.module';
import { InViewDirectiveModule } from '../../directives/in-view/in-view.directive.module';
import { FileSizePipeModule } from '../../pipes/file-size/file-size.module';
import { FileUsagePreviewModule } from '../file-usage-preview/file-usage-preview.module';
import { TranslateModule } from '../translate/translate.module';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { FileUsageComponent } from './file-usage.component';
import { FileUsageService } from './file-usage.service';
import { FileService } from './file.service';

@NgModule({
  declarations: [FileUsageComponent, DeleteDialogComponent],
  imports: [
    CommonModule,
    FormsModule,

    MatMenuModule,
    MatDialogModule,
    MatDividerModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatCheckboxModule,
    MatButtonModule,

    FileUsagePreviewModule,
    TranslateModule,
    DragDropModule,
    FilePreviewModule,
    FolderModule,
    InViewDirectiveModule,
    UploadModule,
    FileSizePipeModule,
  ],
  exports: [FileUsageComponent],
  providers: [FileUsageService, FileService],
})
export class FileUsageModule {}
