import { Location } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { CaseAccess } from 'projects/apex/src/app/models/case';
import { Tenancy } from 'projects/apex/src/app/models/tenancy';
import { snack, snackErr } from 'projects/apex/src/app/modules/snack.module';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import { nameCompare } from 'projects/apex/src/app/utils/functions';
import { Subscription, forkJoin, noop } from 'rxjs';
import { EntryGroup } from '../entry-group/entry-group.model';
import { EntryGroupService } from '../entry-group/entry-group.service';
import { EntryCard } from './entry-card.model';
import { EntryCardService } from './entry-card.service';

@Component({
  selector: 'apex-entry-card',
  templateUrl: './form.component.html',
})
export class EntryCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() entryCardId: number;
  @Input() tenancyId: number;
  @Input() caseView = false;
  @Input() caseAccess: CaseAccess;

  @ViewChild('entryGroupInput') entryGroupInput: ElementRef<HTMLInputElement>;

  entryCard: EntryCard;
  tenancy: Tenancy;

  entryGroups: EntryGroup[] = [];
  viewEntryGroups: EntryGroup[] = [];

  entryGroupFilterSearch = '';

  loading = false;
  expand = false;
  failedToLoad = false;
  viewOnly = false;

  private subscription = new Subscription();

  constructor(
    private service: EntryCardService,
    private entryGroupService: EntryGroupService,
    private route: ActivatedRoute,
    private location: Location,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.entryCardId) {
      this.init();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  init(): void {
    this.loading = true;
    this.failedToLoad = false;
    this.expand = !this.caseView;

    this.viewOnly =
      this.caseView && !this.caseAccess?.caseManager && !this.caseAccess?.contractor && !this.caseAccess?.admin;

    if (this.entryCardId && this.tenancyId) {
      const tenancyRoute = `tenancy/${this.tenancyId}`;

      this.service.route = `${tenancyRoute}/entry-card`;
      this.entryGroupService.route = `${tenancyRoute}/entry-group`;

      const sub = forkJoin([this.service.get(this.entryCardId), this.entryGroupService.query()]).subscribe({
        next: ([entryCard, entryGroups]) => {
          this.entryCard = entryCard;
          this.tenancy = entryCard?.Tenancy;
          this.entryGroups = (entryGroups || []).sort(nameCompare);
          this.viewEntryGroups = [...this.entryGroups];

          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
          this.failedToLoad = true;
          snackErr(t('Could not load entry card'), err);
        },
      });

      this.subscription.add(sub);
    } else if (!this.caseView) {
      const sub = this.route.data.subscribe({
        next: (d: { tenancy: Tenancy; entryCard: EntryCard; entryGroups: EntryGroup[] }) => {
          this.tenancy = d.tenancy;
          this.entryCard = d.entryCard;

          if (this.entryCard) {
            this.entryCard.EntryGroups = d.entryCard?.EntryGroups || [];

            this.entryCard.TenancyId = this.tenancy.id;
          }

          this.entryGroups = (d.entryGroups || []).sort(nameCompare);
          this.viewEntryGroups = [...this.entryGroups];

          this.service.route = `tenancy/${this.tenancy.id}/entry-card`;

          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });

      this.subscription.add(sub);
    }
  }

  setUserId(id: number): void {
    if (id) {
      const sub = this.userService.get(id).subscribe({
        next: (user) => {
          this.entryCard.UserId = user.id;
          this.entryCard.name = user.name;
          this.entryCard.mail = user.mail;
          this.entryCard.mobile = Number(user.mobile);
        },
      });

      this.subscription.add(sub);
    }
  }

  setUser(): void {
    if (this.entryCard.UserId) {
      this.entryCard.UserId = this.caseView ? this.entryCard.UserId : null;
    }
  }

  addGroup(group: EntryGroup): void {
    if (group?.id && !this.entryCard.EntryGroups.find((g) => g.id === group.id)) {
      this.entryCard.EntryGroups.push(group);
      this.entryGroupFilterSearch = '';
      this.entryGroupInput.nativeElement.value = '';
    }
  }

  removeGroup(group: EntryGroup): void {
    this.entryCard.EntryGroups = this.entryCard.EntryGroups.filter((sg) => sg.id !== group.id);
  }

  onChange(): void {
    this.viewEntryGroups = this.entryGroups
      .filter((eg) => {
        if (typeof this.entryGroupFilterSearch === 'string') {
          const searchInput = this.entryGroupFilterSearch.trim().toLowerCase();

          return eg.name.toLowerCase().includes(searchInput);
        } else {
          this.entryGroupFilterSearch = '';
        }

        return true;
      })
      .sort(nameCompare);
  }

  saveCard(): void {
    this.save(() => (!this.caseView ? this.location.back() : null));
  }

  saveCardAndContinue(): void {
    this.save(() => {
      this.entryCard = new EntryCard();
      this.entryCard.EntryGroups = [];
    });
  }

  deleteCard(): void {
    if (this.entryCard?.id) {
      const sub = this.service.delete(this.entryCard).subscribe({
        next: (_) => {
          snack(t('Deleted'));
          !this.caseView ? this.location.back() : noop();
        },
        error: (err) => snackErr(t('Could not delete'), err),
      });

      this.subscription.add(sub);
    }
  }

  private save(whenSaved?: (ec: EntryCard) => void): void {
    const sub = this.service.save(this.entryCard).subscribe({
      next: (ec) => {
        snack(t('Saved'));

        if (typeof whenSaved === 'function') {
          whenSaved(ec);
        }
      },
      error: (err) => snackErr(t('Could not save'), err),
    });

    this.subscription.add(sub);
  }
}
