import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutocompleteModule } from 'projects/apex/src/app/components/autocomplete/autocomplete.module';
import { AvatarModule } from 'projects/apex/src/app/components/avatar/avatar.module';
import { FileUsageModule } from 'projects/apex/src/app/components/file-usage/file-usage.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { TranslatorModule } from 'projects/apex/src/app/components/translator/translator.module';
import { UserCardModule } from 'projects/apex/src/app/directives/user-card/user-card.module';
import { MarkedPipeModule } from 'projects/apex/src/app/pipes/marked/marked.pipe.module';
import { EmbeddedMarkingsViewerModule } from '../../../../components/embedded-markings-viewer/embedded-markings-viewer.module';
import { FileUsageService } from '../../../../components/file-usage/file-usage.service';
import { ItemModule } from '../../../../components/item/item.module';
import { CaseService } from '../../../case/case.service';
import { CaseContractorsModule } from '../../../case/contractor/case-contractors.module';
import { CaseLogsModule } from '../../../case/logs/case-logs.module';
import { CaseNewMessageModule } from '../../../case/new-message/new-message.module';
import { CategoryIsPublicForClientPipe } from '../../../case/pipes/category-is-public-for-client.pipe';
import { ObjectService } from '../../../object/object.service';
import { ChecklistItemService, ChecklistService } from '../../checklist.service';
import { DeviationDialogComponent } from './component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    FileUsageModule,
    TranslateModule,
    CaseContractorsModule,
    AvatarModule,
    TranslatorModule,
    UserCardModule,
    MarkedPipeModule,
    CaseNewMessageModule,
    AutocompleteModule,
    CaseLogsModule,
    EmbeddedMarkingsViewerModule,
    CategoryIsPublicForClientPipe,

    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatCheckboxModule,
    MatListModule,
    MatTooltipModule,
    MatDatepickerModule,
    ItemModule,
  ],
  exports: [],
  declarations: [DeviationDialogComponent],
  providers: [ChecklistService, ChecklistItemService, CaseService, ObjectService, FileUsageService],
})
export class DeviationDialogModule {}
