import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import ProfileService from '../../features/user/profile/profile.service';
import { TranslateModule } from '../translate/translate.module';
import UtilsButtonComponent from './utils-button.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDividerModule,
    TranslateModule,
  ],
  exports: [UtilsButtonComponent],
  declarations: [UtilsButtonComponent],
  providers: [ProfileService],
})
export default class UtilsButtonModule {}
