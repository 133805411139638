import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { Tenancy } from 'projects/apex/src/app/models/tenancy';
import { snack, snackErr } from 'projects/apex/src/app/modules/snack.module';
import { Subscription } from 'rxjs';
import { EntryGroup } from './entry-group.model';
import { EntryGroupService } from './entry-group.service';

@Component({
  selector: 'apex-entry-group',
  templateUrl: './form.component.html',
})
export class EntryGroupComponent implements OnInit, OnDestroy {
  tenancy: Tenancy;
  entryGroup: EntryGroup;

  private subscription = new Subscription();

  constructor(
    private service: EntryGroupService,
    private route: ActivatedRoute,
    private location: Location,
  ) {}

  ngOnInit(): void {
    const sub = this.route.data.subscribe({
      next: (d: { tenancy: Tenancy; entryGroup: EntryGroup }) => {
        this.tenancy = d.tenancy;
        this.entryGroup = d.entryGroup;

        this.service.route = `tenancy/${this.tenancy.id}/entry-group`;
      },
    });

    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  saveGroupAndContinue(): void {
    this.save(() => {
      this.entryGroup = new EntryGroup();
    });
  }

  saveGroup(): void {
    this.save(() => this.location.back());
  }

  deleteGroup(): void {
    if (this.entryGroup?.id) {
      const sub = this.service.delete(this.entryGroup).subscribe({
        next: (_) => {
          snack(t('Deleted'));
          this.location.back();
        },
        error: (err) => snackErr(t('Could not delete'), err),
      });

      this.subscription.add(sub);
    }
  }

  private save(whenSaved?: () => void): void {
    const sub = this.service.save(this.entryGroup).subscribe({
      next: (_) => {
        snack(t('Saved'));

        if (typeof whenSaved === 'function') {
          whenSaved();
        }
      },
      error: (err) => snackErr(t('Could not save'), err),
    });

    this.subscription.add(sub);
  }
}
