import { orderBy } from 'lodash-es';
import { ModelRole } from '../../models/model-role';
import { Checklist, ChecklistItem, ChecklistSection } from './checklist.model';

export interface NewChecklistItemDialogData {
  checklist: Checklist;
  modelRoles: ModelRole[];
  notifyContractor: boolean;
  sortOrder: number;
}

export interface NewChecklistItemDialogReturnData {
  created: boolean;
  item?: ChecklistItem;
}

export interface NewChecklistDialogData {
  isAdmin?: boolean;
  new: boolean;
  templateId: number;
}

export const sortChecklistItems = (items: ChecklistItem[]): ChecklistItem[] =>
  orderBy(items, ['sortOrder', 'Case.name'], ['asc', 'asc']);

export const sortChecklistSections = (sections: ChecklistSection[]): ChecklistSection[] =>
  orderBy(sections, ['sortOrder', 'name'], ['asc', 'asc']);
