import { HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ResolveFn } from '@angular/router';
import { AxiosError } from 'axios';
import { t } from '../../../components/translate/translate.function';
import { snackErr } from '../../../modules/snack.module';
import { ConsentService } from '../consent.service';
import { CompanyConsentReminderDialogComponent } from './company-consent-reminder-dialog.component';
import { CompanyConsentReminderDialogData } from './company-consent-reminder-dialog.data';

export const CompanyConsentReminderResolver: ResolveFn<void> = async () => {
  const consentService = new ConsentService();

  const matDialog = inject(MatDialog);

  try {
    const consents = await consentService.readMissingUserConsent();

    if (consents.length > 0) {
      matDialog
        .open<CompanyConsentReminderDialogComponent, CompanyConsentReminderDialogData, string | null>(
          CompanyConsentReminderDialogComponent,
          {
            data: {
              consents,
            },
          },
        )
        .afterClosed()
        .subscribe();
    }
  } catch (err) {
    if (err instanceof AxiosError && err?.response?.status !== HttpStatusCode.Unauthorized) {
      snackErr(t('Could not resolve missing consents'), err);
    }
  }
};
