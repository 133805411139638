import { Injectable } from '@angular/core';
import { ILanguage, ITranslateParams, ITranslationConfig, tx } from '@transifex/native';
import { setAngularLocaleData } from '../../utils/locale-init';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  init(config: ITranslationConfig): void {
    return tx.init(config);
  }

  setCurrentLocale(localeCode: string): Promise<void> {
    const currentLocale = this.getCurrentLocale();

    if (currentLocale === localeCode) {
      return Promise.resolve();
    }

    setAngularLocaleData(localeCode);

    return tx.setCurrentLocale(localeCode);
  }

  getCurrentLocale(): string {
    return tx.getCurrentLocale();
  }

  getLanguages(config?: { refresh?: boolean }): Promise<ILanguage[]> {
    return tx.getLanguages(config);
  }

  translate(str: string, params?: ITranslateParams): string {
    return tx.translate(str, params);
  }
}
