import { NgModule } from '@angular/core';

import { ReduceHtmlToStringPipe } from './reduce-html-to-string.pipe';

@NgModule({
  imports: [],
  exports: [ReduceHtmlToStringPipe],
  declarations: [ReduceHtmlToStringPipe],
  providers: [],
})
export class ReduceHtmlToStringModule {}
