import { Injectable } from '@angular/core';
import { HttpService } from 'projects/apex/src/app/services/http/http.service';
import { Observable } from 'rxjs';
import { EntryCard } from './entry-card.model';

@Injectable()
export class EntryCardService extends HttpService<EntryCard> {
  route = 'entry-card';

  save(entryCard: EntryCard, message?: string): Observable<EntryCard> {
    const options = {
      ...this.options,
      params: {
        message: message ?? '',
      },
    };

    return entryCard.id
      ? this.http.post<EntryCard>(
          `${this.apiUrl}/tenancy/${entryCard.TenancyId}/entry-card/${entryCard.id}`,
          entryCard,
          options,
        )
      : this.http.post<EntryCard>(`${this.apiUrl}/tenancy/${entryCard.TenancyId}/entry-card`, entryCard, options);
  }

  delete(entryCard: EntryCard, message?: string): Observable<null> {
    const options = {
      ...this.options,
      params: {
        message: message ?? '',
      },
    };

    return this.http.delete<null>(`${this.apiUrl}/tenancy/${entryCard.TenancyId}/entry-card/${entryCard.id}`, options);
  }
}
