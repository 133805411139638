<ng-template
  cdk-portal
  #hoverTemplate="cdkPortal">
  <div
    *ngIf="hoverCase"
    class="hover-case-template AK-50-bg mat-elevation-z2 ap"
    [ngClass]="{
      'case-complete': hoverCase?.completed,
      highlighted: isHoverCaseHighlighted,
      offline: !hoverCase.createdAt,
    }">
    <div class="df ac">
      <div class="case-id df ac">
        <p class="mat-headline-6">#{{ hoverCase.id }}</p>
      </div>
      <p class="mat-headline-6">&nbsp;- {{ hoverCase.name }}</p>
    </div>
    <p *ngIf="hoverCase.description">
      {{ hoverCase.description }}
    </p>
    <p class="df r ac">
      <span
        *ngIf="hoverCase.Object"
        class="df jfs ac">
        <mat-icon>{{ hoverCase.Object | icon }}</mat-icon>
        {{ hoverCase.Object.name }}
      </span>
      <span
        *ngIf="hoverCase.Project"
        class="df r ac">
        <mat-icon svgIcon="project"></mat-icon>
        {{ hoverCase.Project.name }}
      </span>
      <span
        *ngIf="hoverCase.Apartment"
        class="df r ac">
        <mat-icon svgIcon="apartment"></mat-icon>
        {{ hoverCase.Apartment.name }}
      </span>
    </p>
  </div>
</ng-template>

<ng-container *ngIf="fileUsageViewerData">
  <apex-file-usage-viewer
    #fileUsageViewer
    *ngIf="!loading && fileUsageViewerData?.fileUsages?.length"
    [fileUsageViewerData]="fileUsageViewerData"
    (markingChange)="newCase($event); fileUsageViewer?.fileViewer?.imageViewer?.exitFullscreen()"
    (markingClicked)="viewCase($event); fileUsageViewer?.fileViewer?.imageViewer?.exitFullscreen()"
    (selectedIndexChanged)="selectedIndex = $event; selectedIndexChanged.emit($event)"
    (markingEnter)="hoverMarking($event)"
    (markingLeave)="hoverMarkingEnd(); hoverCase = undefined">
  </apex-file-usage-viewer>
  <div
    *ngIf="!loading && !fileUsageViewerData?.fileUsages?.length"
    class="f1 df c ac jc">
    <div class="mat-body-2">
      <T str="No files found"></T>
    </div>
  </div>
  <div
    *ngIf="loading"
    class="f1 df c ac jc">
    <mat-spinner></mat-spinner>
  </div>
</ng-container>
