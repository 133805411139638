<div
  class="position-relative bg-white"
  [matTooltip]="'Click to copy' | translate"
  matTooltipPosition="above"
  #container>
  <div>
    <h2
      *ngIf="title"
      class="text-align-center truncate remove-margin aph apt color-black">
      {{ title }}
    </h2>

    <div>
      <img
        class="position-absolute"
        width="48"
        src="/assets/apex-logo-only-mountain-fv2.svg" />

      <img
        [src]="imageUrl"
        [width]="size"
        [height]="size" />
    </div>

    <h3
      *ngIf="name"
      class="text-align-center truncate apb aph remove-margin color-black">
      {{ name }}
    </h3>
  </div>
</div>
