import { Customer } from './customer';
import { UUIDModel } from './model';
import { ModelRole } from './model-role';
import { User } from './user';
import { UserInvite } from './user-invite';

export class CRMUser extends UUIDModel<CRMUser> {
  CreatorId: number;
  Creator?: User;

  CustomerId: number;
  Customer?: Customer;

  UserId: number;
  User?: User;

  firstName: string;
  lastName: string;
  mail: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postal: string;
  country: string;
  language: string;
  mobile: string;
  dateOfBirth: Date;

  name?: string;
  zip?: string;
  address?: string;

  UserInvites: UserInvite[];
  ModelRoles: ModelRole[];
}
