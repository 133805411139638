import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import {
  NotificationApi,
  ResponseUserNotificationDto,
  UserNotificationPageOptionsOrderBy,
} from '../../../../../../../generated/apex-rest';
import { TranslateModule } from '../../../components/translate/translate.module';
import { restAxiosConfig } from '../../../utils/rest-axios-config';

@Component({
  selector: 'apex-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    TranslateModule,
    MatDividerModule,
    MatIconModule,
    MatProgressSpinnerModule,
    RouterModule,
  ],
})
export class NotificationListComponent implements OnInit {
  private readonly notificationApi = new NotificationApi(restAxiosConfig());
  loading = false;

  notifications: ResponseUserNotificationDto[] = [];

  async ngOnInit(): Promise<void> {
    this.loading = true;

    const response = await this.notificationApi.userNotificationControllerReadCollectionForUser(
      0,
      25,
      'DESC',
      UserNotificationPageOptionsOrderBy.CreatedAt,
    );

    this.notifications = response.data.Collection;

    this.loading = false;
  }

  async markAsRead(notification: ResponseUserNotificationDto): Promise<void> {
    await this.notificationApi.userNotificationControllerUpdate(notification.id, { read: true });
  }
}
