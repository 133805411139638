import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '../translate/translate.module';
import { ImageCropDialogComponent } from './image-crop-dialog.component';
import { ImageCropComponent } from './image-crop.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, TranslateModule],
  exports: [ImageCropComponent, ImageCropDialogComponent],
  declarations: [ImageCropComponent, ImageCropDialogComponent],
  providers: [],
})
export class ImageCropModule {}
