<ng-container *ngIf="!ref?.childNodes?.length">
  <div class="df w apl ac amh amt mat-elevation-z2 search-container">
    <mat-form-field class="f1 amr amt">
      <input
        matInput
        type="text"
        name="searchInput"
        autofocus
        [placeholder]="'Search' | translate"
        [(ngModel)]="search"
        (ngModelChange)="onChangeSearch()" />
    </mat-form-field>

    <mat-checkbox
      class="amr p-amb"
      [(ngModel)]="active"
      (change)="onChangeSearch()">
      <T str="Show only active"></T>
    </mat-checkbox>
  </div>

  <ng-container *ngIf="viewAgreements$ | async as viewAgreements">
    <div class="am mat-elevation-z2">
      <mat-nav-list>
        <ng-container *ngFor="let agreement of viewAgreements">
          <mat-list-item
            lines="3"
            [routerLink]="['/object', 'project', objectId, 'agreement', agreement.id]">
            <span
              *ngIf="agreement.name"
              matListItemTitle
              [innerHTML]="agreement.name | highlight: search"></span>

            <span
              *ngIf="agreement.Object?.name"
              matListItemLine
              [innerHTML]="agreement.Object?.name | highlight: search">
            </span>

            <div matListItemLine>
              <span [matTooltip]="'Period' | translate">
                {{ agreement?.from | dater | date: 'shortDate' }} - {{ agreement?.to | dater | date: 'shortDate' }}
              </span>
            </div>

            <div matListItemMeta>
              <ng-container *ngIf="agreement.active">
                <mat-icon
                  matListItemIcon
                  class="success-color"
                  [matTooltip]="'Active' | translate">
                  check_circle
                </mat-icon>
              </ng-container>
            </div>
          </mat-list-item>
          <mat-divider></mat-divider>
        </ng-container>

        <mat-paginator
          class="AK-50-bg"
          [pageSizeOptions]="[5, 10, 25, 50]"
          [length]="count"
          [pageIndex]="page"
          [pageSize]="limit"
          (page)="onChangePage($event)">
        </mat-paginator>
      </mat-nav-list>
    </div>

    <ng-container *ngIf="!viewAgreements?.length">
      <section class="df ac jc">
        <T str="No agreements to show"></T>
      </section>
    </ng-container>
  </ng-container>
</ng-container>

<div #ref>
  <ng-content #ref></ng-content>
</div>

<button
  mat-fab
  class="fab-button"
  [matTooltip]="'New agreement' | translate"
  [routerLink]="['new']">
  <mat-icon> add </mat-icon>
</button>
