import { Pipe, PipeTransform } from '@angular/core';
import { Case } from '../../../../models/case';

@Pipe({
  name: 'caseFormCaseOrRepeatableCaseFileUsageSelfId',
  standalone: true,
})
export class CaseFormCaseOrRepeatableCaseFileUsageSelfIdPipe implements PipeTransform {
  transform(c: Case): number {
    if (c?.RepeatableCase?.id) {
      return c.RepeatableCase.id;
    }

    return c?.id ?? 0;
  }
}
