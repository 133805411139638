import { Component } from '@angular/core';
import { BaseItemComponent } from './base-item.component';

/**
 * Top main, subtitle small.
 */
@Component({
  selector: 'apex-subtitle-item',
  templateUrl: './subtitle-item.component.html',
})
export class SubtitleItemComponent extends BaseItemComponent {}
