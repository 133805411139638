import { computed, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { intersection, uniqBy } from 'lodash-es';
import {
  ObjectApi,
  ProjectApi,
  ResponseObjectInvolvedUserDtoCollectionResponse,
  ResponseProjectInvolvedUserDtoCollectionResponse,
} from '../../../../../../generated/apex-rest';
import { MemCacheService } from '../../services/mem-cache/MemCacheService';
import { UserService } from '../../services/user/user.service';
import { restAxiosConfig } from '../../utils/rest-axios-config';
import { ProjectInvolvedUserRole } from './involved-users-dialog/project-involved-user-role.type';
import { ProjectInvolvedUserType } from './involved-users-dialog/project-involved-user.type';

export type ExtendedProjectInvolvedUserType = Omit<
  ProjectInvolvedUserType,
  'fieldId' | 'fieldName' | 'fieldColor' | 'role' | 'agreementName' | 'agreementId' | 'companyId' | 'companyName'
> & {
  agreements?: {
    id: number;
    name: string;
  }[];
  companyId?: number;
  companyName?: string;
  roles?: ProjectInvolvedUserRole[];
  fieldNames?: string[];
  fieldColors?: string[];
};

@Injectable()
export class ProjectInvolvedService {
  constructor(
    private readonly userService: UserService,
    private readonly memCacheService: MemCacheService,
  ) {}

  protected readonly projectApi = new ProjectApi(restAxiosConfig());
  protected readonly objectApi = new ObjectApi(restAxiosConfig());

  initialSelectedUserIds: number[] = [];

  profile = toSignal(this.userService.profile$);

  userFavoriteSet = computed(() => new Set(this.profile().UserFavorites.filter((f) => f.modelName === 'user')));

  userFavoriteIdSet = computed(
    () =>
      new Set(
        this.profile()
          .UserFavorites.filter((f) => f.modelName === 'user')
          .map((favorite) => favorite.modelId),
      ),
  );

  involvedType: string;
  responseProjectInvolvedUserDtos = signal<ExtendedProjectInvolvedUserType[]>([]);
  selectedUsers = signal<ExtendedProjectInvolvedUserType[]>([]);
  selectedRoles = signal<ProjectInvolvedUserRole[]>([]);
  onlySelected = signal(false);
  contractorCount = signal(0);
  initialSelectedCount: number = 0;
  notifyOnAddSms: false;
  sendEmail: boolean;

  otherUserFavorites = [];

  selectedUserCount = computed(() => {
    const totalSelected = this.selectedUsers().length;

    return totalSelected > this.initialSelectedCount ? totalSelected - this.initialSelectedCount : 0;
  });

  viewUsers = computed<ExtendedProjectInvolvedUserType[]>(() => {
    this.contractorCount();

    if (this.onlySelected()) {
      return this.selectedUsers();
    }

    const userFavoriteSet = this.userFavoriteSet();

    const userFavoritesArray = Array.from(userFavoriteSet)
      .filter((item) => !this.initialSelectedUserIds.includes(item.modelId))
      .map(
        (item): ExtendedProjectInvolvedUserType => ({
          id: item.modelId,
          name: item.name,
          roles: ['favorite'],
        }),
      );

    const responseInvolvedWithFavoriteRole = this.responseProjectInvolvedUserDtos().map((user) => ({
      ...user,
      roles: [
        ...user.roles,
        ...(this.userFavoriteIdSet().has(user.id) ? (['favorite'] as ProjectInvolvedUserRole[]) : []),
      ],
    }));

    const allUsers = uniqBy([...responseInvolvedWithFavoriteRole, ...userFavoritesArray], (user) => user.id);

    if (this.selectedRoles().length > 0) {
      return allUsers.filter((user) => intersection(this.selectedRoles(), user.roles).length > 0);
    }

    return allUsers;
  });

  toggleSelectRole(role: ProjectInvolvedUserRole): void {
    const index = this.selectedRoles().indexOf(role);

    if (index > -1) {
      this.selectedRoles.set(this.selectedRoles().filter((selectedRole) => selectedRole !== role));
    } else {
      this.selectedRoles.set([...this.selectedRoles(), role]);
    }
  }

  async updateSelectedRoles(role: ProjectInvolvedUserRole): Promise<void> {
    this.toggleSelectRole(role);
  }

  toggleSelectUser(user: ExtendedProjectInvolvedUserType): void {
    const exists = this.selectedUsers().some((selectedUser) => selectedUser.id === user.id);

    let updatedSelectedUsers: ExtendedProjectInvolvedUserType[];

    if (exists) {
      updatedSelectedUsers = this.selectedUsers().filter((selectedUser) => selectedUser.id !== user.id);
    } else {
      updatedSelectedUsers = [...this.selectedUsers(), user];
    }

    this.selectedUsers.set(updatedSelectedUsers);
  }

  setInvolvedType(type: string): void {
    this.involvedType = type;
  }

  async readInvolvedUsers(id: number): Promise<void> {
    if (this.involvedType === 'project') {
      await this.readProjectInvolvedUsers(id);

      return;
    }

    await this.readObjectInvolvedUsers(id);
  }

  processInvolvedUsersResponse(
    data?: ResponseProjectInvolvedUserDtoCollectionResponse | ResponseObjectInvolvedUserDtoCollectionResponse,
  ): void {
    const users = data?.Collection.filter((user) => !this.initialSelectedUserIds.includes(user.id)) ?? [];

    const aggregatedUsers = this.aggregateUsers(users);

    this.responseProjectInvolvedUserDtos.set(aggregatedUsers);
  }

  aggregateUsers(users: ProjectInvolvedUserType[]): ExtendedProjectInvolvedUserType[] {
    const userMap = new Map<number, ExtendedProjectInvolvedUserType>();

    uniqBy(users, 'id').forEach((userId) => {
      const user = users.find((u) => u.id === userId.id);

      const uniqueUser = userMap.get(userId.id) || {
        id: user.id,
        name: user.name,
        agreements: [],
        roles: [],
        companyName: user.companyName,
        fieldNames: [],
        fieldColors: [],
      };

      const rolesForUser = users.filter((u) => u.id === userId.id).map((u) => u.role);
      const fieldNamesForUser = users.filter((u) => u.id === userId.id).map((u) => u.fieldName);
      const fieldColorsForUser = users.filter((u) => u.id === userId.id).map((u) => u.fieldColor);

      uniqueUser.roles = [...new Set([...uniqueUser.roles, ...rolesForUser])];
      uniqueUser.fieldNames = [...new Set([...uniqueUser.fieldNames, ...fieldNamesForUser])];
      uniqueUser.fieldColors = [...new Set([...uniqueUser.fieldColors, ...fieldColorsForUser])];

      return userMap.set(userId.id, uniqueUser as ExtendedProjectInvolvedUserType);
    });

    return Array.from(userMap.values());
  }

  async readObjectInvolvedUsers(objectId: number): Promise<void> {
    const data = await this.memCacheService.cachedOrFetch(`involved-object-${objectId}`, async () => {
      const response = await this.objectApi.objectInvolvedControllerRead(objectId);

      return response?.data;
    });

    this.processInvolvedUsersResponse(data);
  }

  async readProjectInvolvedUsers(projectId: number): Promise<void> {
    const data = await this.memCacheService.cachedOrFetch(`involved-project-${projectId}`, async () => {
      const response = await this.projectApi.projectInvolvedControllerRead(projectId);

      return response?.data;
    });

    this.processInvolvedUsersResponse(data);
  }
}
