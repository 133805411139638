import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateModule } from '../translate/translate.module';

import { ColorPickerComponent } from './color-picker.component';

@NgModule({
  declarations: [ColorPickerComponent],
  exports: [ColorPickerComponent],
  imports: [
    CommonModule,
    FormsModule,

    MatMenuModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,

    TranslateModule,
  ],
  providers: [],
})
export class ColorPickerModule {}
