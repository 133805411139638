import { cloneDeep } from 'lodash-es';
import moment, { Moment, unitOfTime } from 'moment';
import { Case, Contractor } from '../../models/case';
import { Model } from '../../models/model';

export enum RepeatableCaseInterval {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Yearly = 'yearly',
}

export enum CreateCaseTime {
  OneHourBefore = '1',
  ThreeHoursBefore = '3',
  SixHoursBefore = '6',
  TwelveHoursBefore = '12',
  OneDayBefore = '24',
}

export enum RepeatYearOn {
  Day = 'day',
  Week = 'week',
}

export enum RepeatWeekOn {
  EveryWeek = 'every-week',
  EveryTwoWeeks = 'every-two-weeks',
}

export class RepeatableCase extends Model<RepeatableCase> {
  case: Case;

  CreatorId: number;

  interval: RepeatableCaseInterval;
  endDate: Date;
  createCaseTime: CreateCaseTime;

  repeatWeekOn: RepeatWeekOn;
  repeatYearOn: RepeatYearOn;

  ChecklistTemplateId: number;

  RepeatableCaseContractors?: Contractor[];

  Occurrences: RepeatableCase[] = [];

  constructor(rc?: Partial<RepeatableCase>) {
    super(rc);

    if (this.case) {
      this.case = new Case(this.case);
      this.case.Contractors = this.RepeatableCaseContractors;

      const rcForCase = { ...this };

      delete rcForCase.case;
      this.case.RepeatableCase = new RepeatableCase(rcForCase);

      this.case.from = this.case.from ?? this.case.createdAt;
      this.case.to = this.case.to ?? moment.unix(this.case.deadline).toDate();
      this.case.RepeatableCaseId = rcForCase.id;
    }

    this.repeatWeekOn = this.repeatWeekOn ?? RepeatWeekOn.EveryWeek;
    this.repeatYearOn = this.repeatYearOn ?? RepeatYearOn.Day;
    this.endDate = this.endDate ?? null;
  }

  static createOccurrences(
    rc: RepeatableCase,
    endDate: Moment,
    granularity: unitOfTime.StartOf = 'd',
    startDate?: Moment,
    values: RepeatableCase[] = [],
    i = 0,
  ): RepeatableCase[] {
    const newRc = cloneDeep(rc);

    const interval: Record<RepeatableCaseInterval, unitOfTime.DurationConstructor> = {
      daily: 'day',
      weekly: 'week',
      monthly: 'month',
      quarterly: 'quarter',
      yearly: 'year',
    };

    newRc.case.from = newRc.case.from ?? newRc.case.createdAt;
    newRc.case.to = newRc.case.to ?? moment.unix(newRc.case.deadline).toDate();
    newRc.endDate = newRc.endDate ?? null;

    if (newRc.interval) {
      const intervalValue = interval[newRc.interval];
      let amount = 1;

      // repeat every two weeks
      if (newRc.interval === RepeatableCaseInterval.Weekly && newRc.repeatWeekOn === RepeatWeekOn.EveryTwoWeeks) {
        amount = 2;
      }

      if (newRc.interval === RepeatableCaseInterval.Yearly && newRc.repeatYearOn === RepeatYearOn.Week) {
        const diff = moment(newRc.case.to).diff(newRc.case.from, 'd');
        const toHours = moment(newRc.case.to).hour();
        const toMinutes = moment(newRc.case.to).minute();

        const dayOfWeek = moment(newRc.case.from).isoWeekday();
        const weekNumber = moment(newRc.case.from).isoWeek();

        const newFrom = moment(newRc.case.from).add(1, intervalValue);

        const oldEndOfYear = moment(newRc.case.from).endOf('year');
        const oldEndOfYearWeekNumber = moment(oldEndOfYear).isoWeek();

        const newEndOfYear = moment(newFrom).endOf('year');
        const newEndOfYearWeekNumber = moment(newEndOfYear).isoWeek();

        // if from is in last week of year, make sure that the next one also falls on the last week of the next year.
        // we do this so that we don't get weird scenarios where the last week 53, and the next year might not have 53
        // or the last week is 1, and we end up in an infinite loop
        if (oldEndOfYearWeekNumber === weekNumber) {
          newFrom.isoWeek(newEndOfYearWeekNumber);
        } else {
          newFrom.isoWeek(weekNumber);
        }

        newFrom.isoWeekday(dayOfWeek);

        newRc.case.from = moment(newFrom).toDate();
        newRc.case.to = moment(newFrom).hour(toHours).minute(toMinutes).add(diff, 'd').toDate();
      } else {
        newRc.case.from = moment(newRc.case.from).add(amount, intervalValue).toDate();
        newRc.case.to = moment(newRc.case.to).add(amount, intervalValue).toDate();
      }

      if (
        !moment(newRc.case.from).isSameOrBefore(endDate, 'd') ||
        ((newRc.endDate ? moment(newRc.endDate).isValid() : false) &&
          !moment(newRc.case.from).isSameOrBefore(newRc.endDate, granularity))
      ) {
        return values;
      } else {
        if (startDate && !moment(newRc.case.from).isBetween(startDate, endDate, 'day', '[]')) {
          return this.createOccurrences(newRc, endDate, granularity, startDate, values, ++i);
        }

        values.push(newRc);

        return this.createOccurrences(newRc, endDate, granularity, startDate, values, ++i);
      }
    }

    return values;
  }

  assignOccurrences(endDate: Moment, granularity: unitOfTime.StartOf = 'd', startDate?: Moment): void {
    if (!endDate?.isValid()) {
      throw new Error('endDate is invalid');
    }

    this.Occurrences = []; // Reset because if data doesn't reset we end up with a lot of em. No idea why.
    this.Occurrences = RepeatableCase.createOccurrences(this, endDate, granularity, startDate);
  }

  // @todo we should probably create occurresWithin(start, end) and call that
  getOccurrencesWithinWeek(week: Moment): RepeatableCase[] {
    return [this, ...this.Occurrences].filter((rc) =>
      rc.case.from
        ? moment(rc.case.from).isBetween(
            moment(week).startOf('isoWeek'),
            moment(week).endOf('isoWeek'),
            'isoWeek',
            '[]',
          )
        : false,
    );
  }

  // @todo we should probably create occurresWithin(start, end) and call that
  fromOccurresWithin(startDate: Moment, endDate: Moment, granularity: unitOfTime.StartOf = 'd'): boolean {
    return this.Occurrences.some((rc) =>
      rc.case.from ? moment(rc.case.from).isBetween(startDate, endDate, granularity, '[]') : false,
    );
  }
}
