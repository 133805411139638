import { NgModule } from '@angular/core';

import { URLCachePipe } from './url-cache.pipe';

@NgModule({
  declarations: [URLCachePipe],
  exports: [URLCachePipe],
  imports: [],
  providers: [],
})
export class URLCacheModule {}
