import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { firstCharIsLowerCase } from '../../../utils/functions';
import { AutocompleteBase } from '../autocomplete-base';
import { addParentToModelName } from '../autocomplete.component';
import { AutocompleteService } from '../autocomplete.service';
import { AutocompleteString, AutocompleteTypes } from '../autocomplete.types';

@Component({
  selector: 'apex-autocomplete-viewer',
  templateUrl: './viewer.component.html',
})
export class AutocompleteViewerComponent extends AutocompleteBase implements OnInit, OnChanges, OnDestroy {
  @Input() id: number | string;
  @Input() params: Record<string, string | number | boolean>;

  @Input()
  get type(): AutocompleteTypes | AutocompleteString {
    return this.innerType;
  }

  set type(t: AutocompleteTypes | AutocompleteString) {
    if (firstCharIsLowerCase(t)) {
      this.innerType = t as AutocompleteTypes;

      return;
    }

    this.innerType = AutocompleteTypes[t];
  }

  value = '';

  private subscription: Subscription;
  private subject: Subject<void> = new Subject<void>();

  private innerType: AutocompleteTypes = AutocompleteTypes.User;

  constructor(private service: AutocompleteService) {
    super();
  }

  ngOnInit(): void {
    this.subscription = this.subject.pipe(debounceTime(25)).subscribe({
      next: () => {
        this.service
          .queryId(this.innerType, this.getParams(this.params, { id: this.id }))
          .pipe(take(1))
          .subscribe({
            next: (a) => {
              this.value = addParentToModelName(a.name, a);
            },
          });
      },
    });

    this.do();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.id) {
      this.do();
    }

    if (simpleChanges.type) {
      this.do();
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  do(): void {
    if (!this.type || !this.id) {
      this.value = '';

      return;
    }

    this.subject.next();
  }
}
