import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Agreement } from './agreement.model';
import { AgreementService } from './agreement.service';

@Component({
  selector: 'apex-case-agreement-info',
  templateUrl: './case-agreement-info.component.html',
})
export class CaseAgreementInfoComponent implements OnChanges, OnDestroy {
  @Input() agreement: Agreement;

  objProjectId: number;

  expand = false;

  private subscription = new Subscription();

  constructor(private service: AgreementService) {}

  ngOnChanges(change: SimpleChanges): void {
    if (change.agreement && this.agreement) {
      this.expand = false;

      const sub = this.service.getTopLevelObject(this.agreement.id).subscribe({
        next: (objProject) => {
          this.objProjectId = objProject?.id;
        },
      });

      this.subscription.add(sub);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
