import { t } from '@transifex/native';
import { environment } from '../../../environments/environment';
import { SidebarItemAccess } from './sidebar-item-access.enum';
import { SidebarItem } from './sidebar-items.types';

export const staticSidebarItems: SidebarItem[] = [
  {
    routerLink: '/case',
    icon: 'list',
    translation: t('Cases'),
  },
  {
    routerLink: '/request',
    icon: 'inbox',
    translation: t('Requests'),
    access: SidebarItemAccess.IsPayed,
  },
  {
    href: environment.portalUrl,
    routerLink: 'portal',
    icon: 'roofing',
    translation: t('Portal'),
    access: SidebarItemAccess.HasPortal,
  },
  {
    routerLink: '/mom-builder/project',
    icon: 'topic',
    translation: t('MOM Builder'),
  },
  {
    routerLink: '/takeover',
    icon: 'volunteer_activism',
    translation: t('Takeover'),
  },
  {
    routerLink: '/crm-user',
    icon: 'account_box',
    translation: t('CRM User'),
    access: SidebarItemAccess.HasCustomerRightCommercial,
  },
  {
    routerLink: '/project',
    icon: 'domain',
    translation: t('Projects'),
  },
  {
    routerLink: '/project/unwanted-incident',
    icon: 'sentiment_neutral',
    translation: t('Unwanted Incident'),
  },
  {
    routerLink: '/client',
    icon: 'people',
    translation: t('Clients'),
  },
  {
    href: environment.inspectionUrl,
    routerLink: 'inspection',
    icon: 'assignment',
    translation: t('Inspection'),
    access: SidebarItemAccess.IsPayed,
  },
  {
    routerLink: '/checklists',
    icon: 'checklist',
    translation: t('Checklists'),
  },
  {
    routerLink: '/folder',
    icon: 'folder_open',
    translation: t('Files'),
  },
  {
    // should no longer required payment
    routerLink: '/company',
    icon: 'people',
    translation: t('Companies and Users'),
  },
  {
    routerLink: '/reporting',
    icon: 'insert_chart',
    translation: t('Reporting'),
    access: SidebarItemAccess.IsPayed,
  },
  {
    routerLink: '/signing',
    icon: 'gesture',
    translation: t('Signing'),
    access: SidebarItemAccess.HasCustomerRightSigning,
  },
  {
    routerLink: '/article',
    icon: 'article',
    translation: t('Article'),
    access: SidebarItemAccess.IsPayed,
  },
  {
    routerLink: '/addon/start',
    icon: 'track_changes',
    translation: t('Addon'),
    access: SidebarItemAccess.HasCustomerRightAddon,
  },
  {
    routerLink: '/addon/project',
    icon: 'exposure',
    translation: t('Addon Projects'),
    access: SidebarItemAccess.HasAddonWork,
  },
  {
    routerLink: '/construction-site/checkin',
    icon: 'how_to_reg',
    translation: t('Checkin'),
  },
  {
    routerLink: '/construction-site',
    icon: 'construction',
    translation: t('Construction Sites'),
  },
  {
    routerLink: '/admin/start',
    icon: 'settings',
    translation: t('Admin'),
    access: SidebarItemAccess.IsAdmin,
  },

  /** Safety Things */
  {
    routerLink: '/object/safety/product-type',
    icon: 'merge_type',
    translation: t('Safety Product Types'),
    access: SidebarItemAccess.HasCustomerRightSafety,
  },
  {
    routerLink: '/object/safety/sale',
    icon: 'payment',
    translation: t('Safety Sales'),
    access: SidebarItemAccess.HasCustomerRightSafety,
  },
  {
    routerLink: '/object/safety/control',
    icon: 'policy',
    translation: t('Safety Controls'),
    access: SidebarItemAccess.HasCustomerRightSafety,
  },
];
