import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Promise } from 'bluebird';
import { orderBy, random } from 'lodash-es';
import { Observable, Subject, Subscription, firstValueFrom, forkJoin, merge, of } from 'rxjs';
import { catchError, concatMap, debounceTime, delay, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { firstCharIsLowerCase, naturalCompareByKey, presentDownloadURL, urlExpired } from '../../utils/functions';

import { File as ApexFile } from '../../models/file';
import { FileUsage } from '../../models/file-usage';
import { SplitDialogComponent } from '../upload/split-dialog/split-dialog.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { FileUsageService } from './file-usage.service';
import {
  ChangeType,
  FileUsageChangeEventResponse,
  FileUsageSortBy,
  FileUsageSortOrder,
  FileUsageViewType,
  FileUsageViewTypeString,
} from './file-usage.types';
import { FileService } from './file.service';

import { constants } from '../../utils/constants';

import { FolderDialogComponent } from 'projects/apex/src/app/components/folder/dialog.component';
import { snack, snackErr } from '../../modules/snack.module';
import { FileUsageViewerDialogComponent } from '../file-usage-viewer/file-usage-viewer-dialog.component';
import { FileUsageViewerMode } from '../file-usage-viewer/file-usage-viewer.types';
import { t } from '../translate/translate.function';

import { createImagesFromPDF } from '../../utils/pdf';
import getPinturaLocale from '../../utils/pintura/get-pintura-locale';
import { isTypeImage, openPinturaEditor } from '../../utils/pintura/pintura';

@Component({
  selector: 'apex-file-usage',
  templateUrl: './file-usage.component.html',
})
export class FileUsageComponent implements OnChanges, OnDestroy {
  @HostBinding('style')
  get style(): string {
    if (this.imageCropAspectRatio) {
      return `aspect-ratio: ${this.imageCropAspectRatio}`;
    }

    return '';
  }

  @Input() pattern? = '';

  @Input() locked? = false;

  @Output() fuChange: EventEmitter<FileUsageChangeEventResponse> = new EventEmitter<FileUsageChangeEventResponse>();
  @Output() fileUsagesChange: EventEmitter<FileUsage[]> = new EventEmitter<FileUsage[]>();
  @Output()
  changeComplete: EventEmitter<FileUsageChangeEventResponse> = new EventEmitter<FileUsageChangeEventResponse>();
  @Output() selectionChanged = new EventEmitter<Record<string, boolean>>();
  @Output() loaded = new EventEmitter<void>();

  @Input() self: string;
  @Input() selfId: string | number;
  @Input() name: string;

  @Input() title?: string;
  @Input() description = '';

  @Input() disabled = false;
  @Input() allowEdit = true;
  @Input() disableToolsAsClient = false;

  @Input() singleIndex?: number;

  @Input() splitPdf?: 'ask' | 'never' | 'always';

  @Input() imageCropAspectRatio: number | undefined = undefined;

  @Input()
  get view(): FileUsageViewType | FileUsageViewTypeString {
    return this.innerView;
  }

  set view(v: FileUsageViewType | FileUsageViewTypeString) {
    if (firstCharIsLowerCase(v)) {
      this.innerView = v as FileUsageViewType;

      return;
    }

    this.innerView = FileUsageViewType[v];
  }

  get acceptPattern(): string {
    return this.pattern.replace('/,', '/*,').replace('/, ', '/*, ');
  }

  @ViewChild('fileUploadInput') fileUploadInput: HTMLInputElement;
  @ViewChild('editFileUploadInput') editFileUploadInput: HTMLInputElement;
  @ViewChild('wrap') private wrap: ElementRef<HTMLDivElement>;

  FileUsageViewType = FileUsageViewType;

  fileUsages: FileUsage[] = [];

  dropOver = false;
  dropValid = false;
  dropInvalid = false;

  loading = true;
  inView = false;
  uploading = false;
  selected = {};
  updating = {};

  hover = false;
  tapped = false;

  sortBy: FileUsageSortBy = FileUsageSortBy.Sort;
  sortOrder: FileUsageSortOrder = FileUsageSortOrder.Ascending;

  SortBy = FileUsageSortBy;
  SortOrder = FileUsageSortOrder;

  private parsedPattern: string[] = [];

  private innerView: FileUsageViewType = FileUsageViewType.List;

  private saving$: Subject<boolean> = new Subject<boolean>();

  private save$: Subject<FileUsage> = new Subject<FileUsage>();
  private save$func = {
    next: (fu: FileUsage): Subscription => {
      if (this.isNew()) {
        this.updating[fu.id] = false;
        this.saving$.next(true);

        return null;
      }

      return this.service.save(fu).subscribe({
        next: (f) => {
          this.fuChange.emit({
            type: ChangeType.Save,
            fileUsage: f,
          });
          this.fileUsagesChange.emit(this.fileUsages);
        },
        error: (err) => {
          console.error(err);
          snackErr(t('Could not save'), err);

          this.updating[fu.id] = false;
          this.saving$.next(true);
        },
        complete: () => {
          this.updating[fu.id] = false;
          this.saving$.next(true);
          this.changeComplete.emit({ type: ChangeType.Save });
        },
      });
    },
  };
  private save$$: Subscription = this.save$
    .pipe(
      concatMap((fu) => of(fu).pipe(delay(constants.requestDebounceTime))),
      /*groupBy((fu => fu.id)),
    mergeMap(g => g.pipe(debounceTime(constants.inputDebounceTime)))*/
    )
    .subscribe(this.save$func);

  private delete$: Subject<{
    fileUsage: FileUsage;
    deleteFiles: boolean;
  }> = new Subject<{ fileUsage: FileUsage; deleteFiles: boolean }>();
  private delete$func = {
    next: (res: { fileUsage: FileUsage; deleteFiles: boolean }): FileUsage[] | Subscription => {
      if (res.fileUsage.isNew) {
        this.updating[res.fileUsage.id] = false;

        const idx = this.fileUsages.indexOf(res.fileUsage);

        if (idx !== -1) {
          const deletedElement = this.fileUsages.splice(idx, 1);

          this.fuChange.emit({ type: ChangeType.Delete });
          this.fileUsagesChange.emit(this.fileUsages);

          return deletedElement;
        }
      }

      if (this.view === FileUsageViewType.Single) {
        if (this.singleIndex >= this.fileUsages.length - 1) {
          this.singleIndex -= 1;
        }
      }

      return this.service.delete(res.fileUsage, res.deleteFiles).subscribe({
        next: (f) => {
          this.fileUsages.splice(this.fileUsages.indexOf(res.fileUsage), 1);
          this.fuChange.emit({
            type: ChangeType.Delete,
            fileUsage: f,
          });
          this.fileUsagesChange.emit(this.fileUsages);
        },
        error: (err) => {
          console.error(err);
          snackErr(t('Could not delete'), err);
          this.updating[res.fileUsage.id] = false;
        },
        complete: () => {
          this.updating[res.fileUsage.id] = false;
          this.changeComplete.emit({ type: ChangeType.Delete });
        },
      });
    },
  };
  private delete$$: Subscription = this.delete$
    .pipe(concatMap((g) => of(g).pipe(delay(constants.requestDebounceTime))))
    .subscribe(this.delete$func);

  private sort$: Subject<void> = new Subject<void>();
  private sort$func = {
    next: (): false | Subscription => {
      if (this.isNew() || !this.allowEdit || this.disableToolsAsClient) {
        return false;
      }

      this.loading = true;

      const sorts = this.fileUsages
        .map((fu, i) => ({
          id: fu.id,
          FileId: fu.FileId,
          fileSort: i,
          new: i,
          old: fu.fileSort,
        }))
        .filter((d) => d.new !== d.old);

      return this.service.sort(this.self, this.selfId, this.name, sorts).subscribe({
        next: () => {
          this.fileUsages.forEach((fu) => {
            fu.fileSort = sorts.find((s) => s.id === fu.id)?.new ?? fu.fileSort;
          });
          this.fileUsagesChange.emit(this.fileUsages);
          this.loading = false;
        },
        error: (err) => {
          console.error(err);
          snackErr(t('Could not save'), err);
          this.loading = false;
        },
      });
    },
  };
  private sort$$: Subscription = this.sort$.pipe(debounceTime(constants.inputDebounceTime)).subscribe(this.sort$func);

  private uploading$: Subject<void> = new Subject<void>();

  private uploadFile$: Subject<File> = new Subject<File>();
  private uploadFile$$: Subscription = this.uploadFile$
    .pipe(
      mergeMap((f) => this.fileService.sign(f)),
      mergeMap((d) => this.fileService.upload(d.signedData, d.file)),
      mergeMap((f) => this.fileService.save(f)),
      mergeMap((f) => this.attachFile(f)),
      delay(constants.requestDebounceTime),
    )
    .subscribe({
      next: (fu) => {
        this.zone.run(() => {
          snack(t('Uploaded {name}', { name: fu.File.name }));
        });

        this.view === FileUsageViewType.Single ? this.fileUsages.unshift(fu) : this.fileUsages.push(fu);

        this.fileUsagesChange.emit(this.fileUsages);

        this.uploading$.next();
        this.singleIndex = 0;
      },
      error: (err) => {
        console.error(err);
        snackErr(t('Could not add file'), err);
        this.uploading$.next();
      },
    });

  private all$$: Subscription;

  private valid = false;
  private pinturaLocale = getPinturaLocale();

  constructor(
    private service: FileUsageService,
    private fileService: FileService,
    private dialog: MatDialog,
    private zone: NgZone,
  ) {}

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): boolean | Promise<void> {
    event.preventDefault();

    this.dropOver = false;

    if (this.uploading || this.disabled) {
      return false;
    }

    const types = this.typesFromList(event.dataTransfer.files);

    if (!this.validTypes(types) || !event.dataTransfer.files.length) {
      return false;
    }

    this.uploading = true;

    const files = event.dataTransfer.files;

    if (!files.length) {
      return false;
    }

    return this.processFiles(files);
  }

  @HostListener('dragenter', ['$event'])
  onDragEnter(event: DragEvent): void {
    event.preventDefault();
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent): void {
    if (event?.dataTransfer?.dropEffect !== 'copy') {
      event.dataTransfer.dropEffect = 'copy';
    }

    event.preventDefault();

    this.dropOver = true;

    if (this.uploading || this.disabled) {
      this.dropInvalid = true;

      return;
    }

    const types = this.typesFromList(event.dataTransfer.items);

    this.dropValid = this.validTypes(types);
    this.dropInvalid = !this.dropValid;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent): void {
    event.preventDefault();

    this.dropOver = false;

    if (this.uploading || this.disabled) {
      return;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.all$$?.unsubscribe();

    let hasRequired = true;

    if (!this.self) {
      hasRequired = false;
    }

    if (!this.name) {
      hasRequired = false;
    }

    if (this.selfId === null || !String(this.selfId).length) {
      hasRequired = false;
    }

    if (!this.title) {
      this.title = t('FileUsage');
    }

    if (this.pattern) {
      this.parsedPattern = `${this.pattern}`
        .split(',')
        .map((s) => s.trim())
        .filter((s) => s);
    }

    this.valid = hasRequired;
    this.selected = {};

    if (
      changes.selfId?.firstChange ||
      (changes.selfId?.previousValue !== 'new' && changes.selfId?.previousValue !== 0)
    ) {
      this.fileUsages = [];
      this.loader();
    }
  }

  ngOnDestroy(): void {
    this.save$$.unsubscribe();
    this.delete$$.unsubscribe();
    this.sort$$.unsubscribe();
    this.uploadFile$$.unsubscribe();
    this.all$$?.unsubscribe();
  }

  loader(): void {
    if (!this.valid || !this.inView) {
      this.loading = false;

      return;
    }

    this.loading = true;

    if (this.isNew()) {
      // No selfId means it's a new item and we can not save to the db just yet.
      this.loading = false;
    } else {
      this.all$$ = this.service.all(this.self, this.selfId, this.name).subscribe({
        next: (fus: FileUsage[]) => {
          this.fileUsages = fus;
          this.singleIndex = 0;
          this.loading = false;
          this.loaded.emit();

          this.sortFileUsages();
        },
        error: (err) => {
          snackErr(t('Failed to load files'), err);
        },
      });
    }
  }

  onFileInput($event: Event): boolean | Promise<void> {
    const files = ($event.target as HTMLInputElement).files;

    if (this.uploading || this.disabled) {
      return false;
    }

    if (!files.length) {
      return false;
    }

    const types = this.typesFromList(files);

    if (!this.validTypes(types)) {
      snack(
        t('{count, plural, one {Your file is} other {One or more of your files are}} the wrong type.', {
          count: files.length,
        }),
      );

      return false;
    }

    this.uploading = true;

    if (!files.length) {
      return false;
    }

    return this.processFiles(files);
  }

  onClick(event: Event, fileUsage: FileUsage): void {
    event.stopPropagation();

    if (!fileUsage) {
      return;
    }

    this.selected[fileUsage.id] = !this.selected[fileUsage.id];
    this.selectionChanged.emit(this.selected);
  }

  onChange(event: KeyboardEvent, fileUsage: FileUsage): void {
    if (['Shift', 'Control', 'Alt', 'Tab', 'Meta'].includes(event.key) || this.disabled) {
      return;
    }

    this.updating[fileUsage.id] = true;
    this.save$.next(fileUsage);
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.fileUsages, event.previousIndex, event.currentIndex);

    this.sort$.next();
  }

  anySelected(): boolean {
    return Object.values(this.selected).filter((v) => v).length > 0;
  }

  manySelected(): number {
    return Object.values(this.selected).filter((v) => v).length;
  }

  anyUpdating(): boolean {
    return Object.values(this.updating).filter((v) => v).length > 0;
  }

  singleSize(): number | { width: number; height: number } {
    return {
      width: this.wrap.nativeElement.offsetWidth,
      height: this.wrap.nativeElement.offsetHeight,
    };
  }

  singleFile(): ApexFile {
    return this.fileUsages[this.fileUsages.length - 1].File;
  }

  delete(): void {
    const selected = Object.keys(this.selected)
      .map((n) => Number(n))
      .filter((v) => this.selected[v]);

    if ((selected.length === 0 && this.view !== FileUsageViewType.Single) || this.disabled) {
      return;
    }

    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .pipe(take(1))
      .subscribe({
        next: (res: { delete: boolean; deleteFiles: boolean }) => {
          if (res && res.delete) {
            if (this.view === FileUsageViewType.Single) {
              this.delete$.next({
                fileUsage: this.fileUsages[this.singleIndex],
                deleteFiles: res.deleteFiles,
              });
            } else {
              this.fileUsages
                .filter((f) => selected.includes(f.id))
                .forEach((fu) => {
                  this.selected[fu.id] = false;
                  this.selectionChanged.emit(this.selected);

                  this.updating[fu.id] = true;
                  this.delete$.next({
                    fileUsage: fu,
                    deleteFiles: res.deleteFiles,
                  });
                });
            }
          }
        },
      });

    return;
  }

  saveAll(): Observable<boolean> {
    if (this.isNew()) {
      console.error('saveAll() requires you to have changed selfId');

      return of(false);
    }

    if (this.disabled) {
      return of(false);
    }

    if (!this.fileUsages.length) {
      return of(true);
    }

    const o = this.saving$.pipe(take(this.fileUsages.length));

    this.fileUsages.forEach((fu) => {
      if (fu.selfId === String(0)) {
        fu.id = null;
      }

      fu.selfId = String(this.selfId);

      this.updating[fu.id] = true;
      this.save$.next(fu);
    });

    return o;
  }

  isNew(): boolean {
    return this.selfId === 0 || `${this.selfId}` === 'new';
  }

  isValid(): boolean {
    return this.valid;
  }

  viewFiles(): void {
    if (!this.fileUsages.length) {
      return;
    }

    const selected = this.fileUsages.filter((f) => this.selected[f.id] === true);

    this.dialog.open(FileUsageViewerDialogComponent, {
      data: {
        fileUsages: selected.length ? selected : this.fileUsages,
        mode: FileUsageViewerMode.View,
        editable: false,
        client: false,
        startingIndex: this.view === FileUsageViewType.Single ? this.singleIndex : null,
      },
    });
  }

  openFolder(): void {
    this.dialog
      .open(FolderDialogComponent, { data: { splitPdf: this.splitPdf } })
      .afterClosed()
      .pipe(
        filter((data: { files: ApexFile[] }) => {
          if (data?.files?.length) {
            this.uploading$waitFor(data?.files?.length);

            return true;
          }

          return false;
        }),
        map((data: { files: ApexFile[] }) => data?.files),
        mergeMap((files: ApexFile[]) => merge(...files.map((f) => this.attachFile(f)))),
        catchError((err: Error) => {
          snackErr(t('Could not add file'), err);
          this.uploading$.next();

          return of(null);
        }),
      )
      .subscribe({
        next: (fu: FileUsage) => {
          if (fu) {
            this.zone.run(() => {
              snack(t('Uploaded {name}', { name: fu.File.name }));
            });
            this.view === FileUsageViewType.Single ? this.fileUsages.unshift(fu) : this.fileUsages.push(fu);

            this.fileUsagesChange.emit(this.fileUsages);

            this.singleIndex = 0;
          }

          this.uploading$.next();
        },
      });
  }

  sortFileUsages(): void {
    this.singleIndex = 0;

    if (this.sortBy === FileUsageSortBy.Name) {
      const compare = naturalCompareByKey<FileUsage>('fileName');

      this.fileUsages.sort(compare);

      return;
    }

    this.fileUsages = orderBy(this.fileUsages, [this.sortBy], [this.sortOrder]);
  }

  attachFiles(files: ApexFile[]): void {
    const fus = files.map((file) => this.attachFile(file));

    const fus$ = forkJoin(fus).pipe(
      take(1),
      tap((fileUsages) => this.fileUsages.push(...fileUsages)),
      tap(() => this.fileUsagesChange.emit(this.fileUsages)),
      delay(constants.requestDebounceTime),
    );

    fus$.subscribe();
  }

  async addFiles(images: File[]): Promise<void> {
    return Promise.resolve(images)
      .then((files) => {
        if (files.map((f) => f.type).includes('application/pdf')) {
          const confirmation =
            this.splitPdf === 'ask'
              ? this.dialog.open(SplitDialogComponent).afterClosed().toPromise()
              : this.splitPdf === 'always';

          return [files, confirmation];
        }

        return [files, false];
      })
      .spread((files: File[], shouldSplit: boolean) => {
        if (typeof shouldSplit === 'undefined') {
          return [[]];
        }

        return Promise.map(files, (f) => {
          if (shouldSplit === true && f.type === 'application/pdf') {
            return createImagesFromPDF(f);
          }

          return [f];
        });
      })
      .then((files: File[][]) => {
        const fileSizzle = [];

        files.forEach((u) => {
          u.forEach((l) => {
            fileSizzle.push(l);
          });
        });

        this.uploading$waitFor(fileSizzle.length);

        return fileSizzle;
      })
      .then((files) => {
        files.forEach((f) => {
          this.uploadFile$.next(f);
        });
      });
  }

  validType(type: string): boolean {
    if (!this.parsedPattern.length) {
      return true;
    }

    let allowed = false;

    this.parsedPattern.forEach((p) => {
      if (type.startsWith(p)) {
        allowed = true;
      }
    });

    return allowed;
  }

  isFileUsageImage(fileUsage: FileUsage): boolean {
    return isTypeImage(fileUsage?.File);
  }

  async editImage(fileUsage: FileUsage): Promise<void> {
    const editedImage = await openPinturaEditor(fileUsage.File.signed.url, {
      imageCropAspectRatio: this.imageCropAspectRatio,
    });

    if (editedImage) {
      return this.editedImage(editedImage, fileUsage);
    }
  }

  async onEditFileInput($event: Event): Promise<boolean> {
    const files = ($event.target as HTMLInputElement).files;
    const src = files.item(0);

    if (!src) {
      return false;
    }

    if (!isTypeImage(src)) {
      snackErr(t('Please select an image file'), new Error(`"${src.type}" is not a valid image`));

      return false;
    }

    const editedImage = await openPinturaEditor(src, {
      imageCropAspectRatio: this.imageCropAspectRatio,
    });

    if (editedImage) {
      void this.addFiles([editedImage]);
    }

    return true;
  }

  download(fileUsage: FileUsage): void {
    if (!fileUsage.File) {
      return;
    }

    of(fileUsage.File)
      .pipe(
        mergeMap((file) => {
          if (!urlExpired(file.signed.url)) {
            return of(file).pipe(
              map((f) => ({
                url: f.signed.url,
                name: f.name,
              })),
            );
          }

          if (fileUsage) {
            return this.service.get(fileUsage.id).pipe(
              take(1),
              map((fu) => ({
                url: fu.File.signed.url,
                name: fu.File.name,
              })),
            );
          }

          return this.fileService.url(file.id).pipe(
            map((urls) => ({
              url: urls.signed,
              name: file.name,
            })),
          );
        }),
        take(1),
      )
      .subscribe((f) => {
        presentDownloadURL(f.url, f.name);
      });
  }

  private async editedImage(file: File, fileUsage: FileUsage): Promise<void> {
    this.updating[fileUsage.id] = true;
    this.uploading = true;

    try {
      const signed = await firstValueFrom(this.fileService.sign(file));
      const uploadedFile = await firstValueFrom(this.fileService.upload(signed.signedData, file));
      const savedFile = await firstValueFrom(this.fileService.save(uploadedFile));

      const savedfileUsage = await this.service.swap(fileUsage, savedFile.id);

      const foundFileUsageIdx = this.fileUsages.findIndex((fu) => fu.id === savedfileUsage.id);

      if (foundFileUsageIdx !== -1) {
        this.fileUsages[foundFileUsageIdx] = savedfileUsage;
      }

      snack(t('Image edited'));
    } catch (err) {
      snackErr(t('Could not edit image'), err);
    }

    this.updating[fileUsage.id] = false;
    this.uploading = false;
  }

  private uploading$waitFor(count: number): void {
    this.uploading$.pipe(take(count)).subscribe({
      next: () => {
        this.fuChange.emit({ type: ChangeType.Upload });
      },
      error: () => {
        this.uploading = false;
        this.sort$.next();
      },
      complete: () => {
        this.uploading = false;
        this.sort$.next();
        this.changeComplete.emit({ type: ChangeType.Upload });
      },
    });
  }

  private validTypes(types: string[]): boolean {
    return types.map((type) => this.validType(type)).filter((s) => s).length === types.length;
  }

  private typesFromList(list: DataTransferItemList | FileList): string[] {
    const types = [];
    let length = list.length;

    while (length--) {
      types.push(list[length].type);
    }

    return types;
  }

  private processFiles(fileList: FileList): Promise<void> {
    return Promise.resolve(fileList)
      .then((fl) => {
        const files: File[] = [];

        for (let i = 0, j = fl.length; i < j; i++) {
          files.push(fl[i]);
        }

        return files;
      })
      .then((files) => this.addFiles(files));
  }

  private attachFile(file: ApexFile): Observable<FileUsage> {
    if (!this.validTypes([file.type])) {
      throw Error(t('Invalid file type'));
    }

    const fu = new FileUsage();

    fu.File = file;
    fu.FileId = file.id;
    fu.name = this.name;
    fu.self = this.self;
    fu.selfId = String(this.selfId);
    fu.fileName = file.name;
    fu.fileSort = this.fileUsages[this.fileUsages.length - 1]
      ? this.fileUsages[this.fileUsages.length - 1].fileSort + 1
      : 1;

    if (this.isNew()) {
      fu.isNew = true;
      fu.id = Date.now() + random(10, 1000, false); // @todo Is this high enough resolution to avoid conflicts?

      return of(fu);
    }

    return this.service.save(fu);
  }
}
