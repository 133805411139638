import { NgModule } from '@angular/core';
import { MarkedPipe } from './marked.pipe';

@NgModule({
  imports: [],
  exports: [MarkedPipe],
  declarations: [MarkedPipe],
  providers: [],
})
export class MarkedPipeModule {}
