/// <reference types="google.visualization" />

import { Component, ElementRef, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { maxBy, minBy } from 'lodash-es';
import moment from 'moment';

import { t } from '../translate/translate.function';

import { Color } from './charts.colors';

@Component({
  selector: 'apex-sparkline',
  templateUrl: 'sparkline.component.html',
})
export class SparklineComponent implements OnChanges, OnDestroy {
  @Input() values: { createdAt?: Date; value: number }[] = [];

  @Input() width = 96;
  @Input() height = 48;

  @ViewChild('chart') chart: ElementRef<HTMLDivElement>;
  gchart: google.visualization.LineChart;

  constructor() {
    google.charts.setOnLoadCallback(() => {
      this.createChart();
    });
  }

  ngOnChanges(): void {
    if (this.gchart) {
      this.updateChart();
    }
  }

  ngOnDestroy(): void {
    this.gchart?.clearChart();
  }

  public getTimeSeries(): [Date, number][] {
    return this.values.map((v) => [moment(v.createdAt).toDate(), v.value]);
  }

  private createChart(): void {
    if (!this.chart) {
      console.error('Can not find div#chart');

      return;
    }

    this.gchart = new google.visualization.LineChart(this.chart.nativeElement);

    this.updateChart();
  }

  private updateChart(): void {
    if (!this.gchart) {
      console.error('Can not find gchart');

      return;
    }

    const data = new google.visualization.DataTable();

    data.addColumn('date', t('Date'));
    data.addColumn('number', t('Value'));

    data.addRows([...this.getTimeSeries()]);

    const minValue = minBy(this.values, (m) => m.value)?.value ?? 0;
    const maxValue = maxBy(this.values, (m) => m.value)?.value ?? 0;

    const options: google.visualization.LineChartOptions = {
      width: this.width,
      height: this.height,
      backgroundColor: 'transparent',
      legend: 'none',
      enableInteractivity: false,
      vAxis: {
        textPosition: 'none',
        baselineColor: 'transparent',
        gridlines: {
          color: 'transparent',
        },
        minValue,
        maxValue,
      },
      hAxis: {
        textPosition: 'none',
        baselineColor: 'transparent',
        gridlines: {
          color: 'transparent',
        },
      },
      axisTitlesPosition: 'none',
      titlePosition: 'none',
      theme: 'maximized',
      chartArea: {
        width: '100%',
        height: '100%',
      },
      series: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        0: {
          color: Color.AK700,
        },
      },
      // curveType: 'function',
    };

    this.gchart.draw(data, options);
  }
}
