import { HttpService } from 'projects/apex/src/app/services/http/http.service';
import { File as ApexFile } from '../../models/file';

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  CreateFileSigningPolicyDto,
  FileApi,
  ResponseFileSigningPolicyDto,
} from '../../../../../../generated/apex-rest';
import { restAxiosConfig } from '../../utils/rest-axios-config';
import { SignedData, SignedFile } from './file-usage.types';

interface FileUrls {
  path: string;
  url: string;
  signed: string;
  thumb: string;
}

@Injectable()
export class FileService extends HttpService<ApexFile> {
  route = 'file';

  fileApi = new FileApi(restAxiosConfig());

  url(id: number): Observable<FileUrls> {
    return this.http.get<FileUrls>(`${this.apiUrl}/file/${id}/url`, this.options);
  }

  sign(file: File): Observable<SignedFile> {
    const data = {
      name: file.name,
      type: file.type,
    };

    return this.http.post<SignedData>(`${this.apiUrl}/file/sign`, data, this.options).pipe(
      mergeMap((sd) =>
        of({
          signedData: sd,
          file,
        }),
      ),
    );
  }

  // This does not return a completed ApexFile, but a Partial one in reality
  upload(data: SignedData, file: File): Observable<ApexFile> {
    const formData = new FormData();

    // @todo Doing this like a peasant :(
    formData.append('AWSAccessKeyId', data.awsAccessKeyId ?? data.awsaccesskeyid);
    formData.append('policy', data.policy);
    formData.append('signature', data.signature);

    formData.append('bucket', data.bucket);
    formData.append('key', data.key);
    formData.append('acl', data.acl);

    formData.append('Content-Type', data.type);

    if (data.xAmzMetaFilename) {
      formData.append('x-amz-meta-filename', data.xAmzMetaFilename);
    }

    formData.append('file', file, data.key);

    return this.http.post(data.bucketUrl, formData).pipe(
      map(
        (_) =>
          ({
            name: file.name,
            path: data.key,
            size: file.size,
            type: data.type,
            description: '',
          }) as ApexFile,
      ),
      catchError((err) => {
        console.error(err);

        return of(null);
      }),
    );
  }

  async restSign(createFileSigningPolicyDto: CreateFileSigningPolicyDto): Promise<ResponseFileSigningPolicyDto> {
    if (!createFileSigningPolicyDto) {
      throw new Error('File is required');
    }

    const response = await this.fileApi.fileControllerSign(createFileSigningPolicyDto);

    return response.data.Entity;
  }
}
