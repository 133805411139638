import { Model } from './model';
import { ProjectField } from './project-field';
import { User } from './user';

export class Field extends Model<Field> {
  name: string;
  status: number;
  CustomerId: number;
  ProjectField?: ProjectField;
  Users?: User[];

  color?: string;
}
