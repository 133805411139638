import { FileUsage } from '../../models/file-usage';

export enum ChangeType {
  Save = 'save',
  Update = 'update',
  Delete = 'delete',
  Upload = 'upload',
}

export interface FileUsageChangeEventResponse {
  type: ChangeType;
  fileUsage?: FileUsage;
}

export enum FileUsageViewType {
  Grid = 'grid',
  List = 'list',
  Single = 'single',
}

export type FileUsageViewTypeString = keyof typeof FileUsageViewType;

export enum FileUsageSortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

export enum FileUsageSortBy {
  Date = 'createdAt',
  Name = 'fileName',
  Sort = 'fileSort',

  FileDate = 'file.createdAt',
  FileName = 'file.name',
}

export interface SignedData {
  acl: 'private' | 'public';
  awsaccesskeyid: string;
  awsAccessKeyId: string;
  bucket: string;
  bucketUrl: string;
  key: string;
  policy: string;
  signature: string;
  type: string;
  xAmzMetaFilename: string;
}

export interface SignedFile {
  signedData: SignedData;
  file: File;
}
