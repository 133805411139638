import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToQueryParams',
  standalone: true,
})
export class StringToQueryParamsPipe implements PipeTransform {
  transform(link: string): Record<string, string> {
    const url = new URL(link, 'https://app.apexapp.io/');

    if (url.searchParams.size > 0) {
      const params = {};

      url.searchParams.forEach((value, key) => {
        params[key] = value;
      });

      return params;
    }

    return {};
  }
}
