import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { Client } from 'projects/apex/src/app/models/client';
import { Profile } from 'projects/apex/src/app/models/profile';
import { User } from 'projects/apex/src/app/models/user';
import { environment } from 'projects/apex/src/environments/environment';
import { HttpService } from '../http/http.service';

import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslationService } from '../../components/translate/translation.service';
import UserPortalReminderDialogComponent from '../../components/user-portal-reminder/user-portal-reminder.dialog';
import { CustomerRight } from '../../models/customer-right';
import { FileUsage } from '../../models/file-usage';
import { UserFavorite } from '../../models/user-favorite';
import { hasUserCustomerRight } from '../../utils/functions';
import { DeleteResponse, EntityResponse } from '../../utils/types';

const ensureLocale = async (profile: Profile, translationService: TranslationService): Promise<Profile | null> => {
  if (environment.test) {
    return profile;
  }

  if (!profile) {
    return null;
  }

  // Check if one is set and use it.
  // In the future we can allow profile.locale to be nullable and it will use the
  // browser locale if it's not set.
  if (profile.locale) {
    await translationService.setCurrentLocale(profile.locale);
  }

  document.getElementsByTagName('body')[0].classList.remove('login');

  return profile;
};

@Injectable()
export class UserService extends HttpService<User> {
  route = 'user';

  private actualProfile: Profile;
  private actualProfile$: BehaviorSubject<Profile> = new BehaviorSubject<Profile>(null);

  userFavorites$ = this.actualProfile$.pipe(map((profile) => profile?.UserFavorites ?? []));

  constructor(
    protected http: HttpClient,
    private router: Router,
    private matDialog: MatDialog,
    private translationService: TranslationService,
  ) {
    super(http);
  }

  get profile(): Profile {
    return this.actualProfile;
  }

  get profile$(): Observable<Profile | null> {
    return this.actualProfile$.pipe(
      mergeMap((profile) => ensureLocale(profile, this.translationService)),
      map((profile) => (profile ? new Profile(profile) : null)),
    );
  }

  /**
   * Emits user only if it's set.
   *
   * @deprecated DO NOT USE THIS - HANDLE OBSERVABLES BETTER IF YOU NEED THIS :)
   */
  get filteredProfile$(): Observable<Profile> {
    return this.actualProfile$.pipe(
      filter((profile) => profile?.id > 0), // Don't next the nullses.
      mergeMap((profile) => ensureLocale(profile, this.translationService)),
      map((profile) => new Profile(profile)),
      take(1),
    );
  }

  /**
   * Now that logout goes to API this should not be needed?
   */
  clearProfile(): boolean {
    this.actualProfile = null;
    this.actualProfile$.next(null);

    return true;
  }

  setProfile(profile: Profile): boolean {
    this.actualProfile = profile;
    this.actualProfile$.next(profile);

    return true;
  }

  getProfile(): Observable<Profile> {
    return this.http.get<Profile>(`${this.apiUrl}/profile`, this.options).pipe(
      take(1),
      tap((p) => {
        this.actualProfile = p;
      }),
      tap((p) => this.actualProfile$.next(p)),
      map((p) => new Profile(p)),
      tap((p) => {
        const hostname = window.location.hostname;
        const port = window.location.port;
        const isPortal =
          hostname.includes('portal.apexapp.io') || (hostname.includes('app.heimdaltest.no') && port === '4203');

        const overrideRedirect = (window.location.search ?? '').includes('overrideRedirect');

        if (!isPortal && p.tenancyCount > 0 && !p.Customer?.CustomerRight?.Commercial) {
          const alwaysRedirectToPortal = localStorage.getItem('alwaysRedirectToPortal') === 'true';
          const hidePortalReminder = localStorage.getItem('hidePortalReminder') === 'true';

          if (!hidePortalReminder) {
            if (alwaysRedirectToPortal && !overrideRedirect) {
              window.location.href = environment.portalUrl;

              return;
            }

            this.matDialog
              .open(UserPortalReminderDialogComponent, {})
              .afterClosed()
              .pipe(take(1))
              .subscribe((result) => {
                if (result) {
                  window.location.href = environment.portalUrl;
                }
              });
          }
        }
      }),
    );
  }

  isAdmin(user: User): boolean {
    return !!user?.Customer?.Admins?.find((admin) => admin.id === user.id);
  }

  isClient(): boolean {
    return this.actualProfile.role === 1;
  }

  favorite(model: string, modelId: number, isFav: boolean): Observable<void | UserFavorite> {
    if (isFav) {
      return this.http.delete<void>(`${this.apiUrl}/favorite/${model}/${modelId}`, this.options);
    }

    return this.http.post<UserFavorite>(`${this.apiUrl}/favorite/${model}/${modelId}`, {}, this.options);
  }

  favorites(model: string): UserFavorite[] {
    return this.actualProfile?.UserFavorites?.filter((f) => f.modelName === model) ?? [];
  }

  favoritesCount(model: string): number {
    return this.favorites(model).length;
  }

  favorites$(model: string): Observable<UserFavorite[]> {
    return of(this.favorites(model));
  }

  hasCustomerRight(right: keyof CustomerRight): boolean {
    if (!this?.actualProfile?.paymentStatus) {
      return true;
    }

    return hasUserCustomerRight(this.actualProfile?.Customer, right);
  }

  hasPayment(): boolean {
    return this.profile?.Customer?.payed === 1;
  }

  postAvatar(userId: number, fileId: number): Observable<FileUsage> {
    return this.http.post<FileUsage>(`${this.apiUrl}/user/${userId}/avatar`, { fileId }, this.options);
  }

  deleteAvatar(userId: number): Observable<DeleteResponse> {
    return this.http.delete<DeleteResponse>(`${this.apiUrl}/user/${userId}/avatar`, this.options);
  }

  disable(userId: number): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/${this.route}/${userId}/disable`, { id: userId }, this.options);
  }

  /**
   * @deprecated DO NOT USER THESE THINGS
   * @param userId
   * @returns
   */
  getClient(userId: number): Observable<EntityResponse<Client>> {
    return this.http.get<EntityResponse<Client>>(`${this.apiUrl}/client/${userId}`, this.options);
  }

  changePassword(newPassword: string, verifyPassword: string): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.apiUrl}/user/change-password`,
      {
        newPassword,
        verifyPassword,
      },
      this.options,
    );
  }
}
