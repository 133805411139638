import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { Case } from 'projects/apex/src/app/models/case';
import { Subscription } from 'rxjs';
import { t } from '../../../components/translate/translate.function';
import { snack, snackAct, snackErr } from '../../../modules/snack.module';
import { CaseService } from '../case.service';
import { RepeatableCase } from '../repeatable-case.model';
import { CaseDialogFormData } from './dialog-types';
import { CaseFormComponent } from './form.component';

@Component({
  selector: 'apex-case-form-dialog',
  templateUrl: './dialog.component.html',
})
export class CaseFormDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() showSaveAndContinue = true;
  @Input() info: Record<string, string>;

  @ViewChild('formComponent') formComponent: CaseFormComponent;

  @Output() saveAndContinueExecuted = new EventEmitter<Case>();

  case: Case;
  repeatableCase: RepeatableCase;

  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CaseDialogFormData,
    public dialogRef: MatDialogRef<CaseFormDialogComponent>,
    private route: ActivatedRoute,
    private router: Router,
    private caseService: CaseService,
  ) {}

  ngOnInit(): void {
    this.dialogRef.addPanelClass(['apex-fullscreen-dialog', 'phone']);
    this.case = new Case(cloneDeep(this.data.case));
  }

  ngAfterViewInit(): void {
    if (this.data?.files?.length > 0) {
      this.formComponent?.files?.attachFiles(this.data.files);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  save(): void {
    if (!this.formComponent?.loading && this.formComponent?.form?.valid) {
      const sub = this.formComponent.save().subscribe({
        next: (d: Case | RepeatableCase) => {
          if (d instanceof RepeatableCase) {
            snack(t('Repeatable case saved'));

            this.repeatableCase = d;
            this.case.RepeatableCase = this.repeatableCase;
          } else {
            snackAct(t('Case saved'), {
              msg: t('Open'),
              fn: () => {
                void this.router.navigate(['/', 'case'], {
                  fragment: `list-${d.id}`,
                });
              },
            });

            this.case = d;
          }

          this.dialogRef.close(this.case);
        },
        error: () => {
          this.formComponent.loading = false;
          snack(t('Could not save the case'));
        },
      });

      this.subscription.add(sub);
    }
  }

  saveAndContinue(): void {
    if (!this.formComponent?.loading && this.formComponent?.form?.valid) {
      const sub = this.formComponent.save().subscribe({
        next: (d: Case | RepeatableCase) => {
          snack(t('Case saved'));
          this.case.name = '';
          this.case.description = '';
          this.case.location = '';
          this.case.files = [];
          this.formComponent?.markingsChanged([]);

          if (this.formComponent?.files?.selfId) {
            this.formComponent.files.selfId = 0;
          }

          if (this.formComponent?.files?.fileUsages) {
            this.formComponent.files.fileUsages = [];
          }

          this.case.Contractors = [];

          const possibleField = Number(this.route.snapshot.queryParamMap.get('Field'));

          if (possibleField > 0) {
            this.case.FieldId = possibleField;
            this.formComponent.fieldChange(this.case.FieldId);
          } else {
            this.case.FieldId = null;
          }

          if (d instanceof RepeatableCase) {
            this.repeatableCase = d;
            this.case.RepeatableCase = this.repeatableCase;
          } else {
            this.saveAndContinueExecuted.emit(d);
          }
        },
        error: (err) => {
          this.formComponent.loading = false;
          snackErr(t('Could not save the case'), err);
        },
      });

      this.subscription.add(sub);
    }
  }

  saveAndComplete(): void {
    if (
      !this.formComponent?.loading &&
      this.formComponent?.form?.valid &&
      (this.case.ProjectId || this.case.ObjectId)
    ) {
      const sub = this.formComponent.save().subscribe({
        next: (d: Case | RepeatableCase) => {
          if (d instanceof RepeatableCase) {
            snack(t('Repeatable case saved'));

            this.repeatableCase = d;
            this.case.RepeatableCase = this.repeatableCase;
          } else {
            snackAct(t('Case saved and completed'), {
              msg: t('Open'),
              fn: () => {
                void this.router.navigate(['/', 'case'], {
                  fragment: `list-${d.id}`,
                });
              },
            });

            this.case = d;
          }

          this.caseService.finish(this.case).subscribe({
            next: () => {
              this.dialogRef.close(this.case);
            },
          });
        },
        error: () => {
          this.formComponent.loading = false;
          snack(t('Could not save the case'));
        },
      });

      this.subscription.add(sub);
    }
  }

  navigateAndViewMom(): void {
    const projectId = this.case?.ProjectId;
    const apartmentId = this.case?.ApartmentId;

    void this.router.navigate(['/', 'mom-viewer', projectId, apartmentId]).then(() => {
      this.dialogRef.close();
    });
  }

  navigateAndViewCases(): void {
    const queryParams = {
      ProjectId: this.case?.ProjectId,
      Apartment: this.case?.ApartmentId,
      showCaseManagers: true,
    };

    void this.router.navigate(['/', 'case'], { queryParams }).then(() => {
      this.dialogRef.close();
    });
  }

  navigateAndViewRequests(): void {
    const queryParams = {
      Apartment: this.case?.ApartmentId,
      fromRequest: true,
    };

    void this.router.navigate(['/', 'case'], { queryParams }).then(() => {
      this.dialogRef.close();
    });
  }
}
