import { inject } from '@angular/core';

import { ResolveFn } from '@angular/router';
import { environment } from 'projects/apex/src/environments/environment';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import { Profile } from '../../models/profile';
import { UserService } from './user.service';

/**
 * Used to do the actual fetch of the profile.
 *
 * Should be run once at app init for routes that need the profile.
 */
export const GetProfileResolver: ResolveFn<Profile> = () => {
  const userService = inject(UserService);

  if (environment.test) {
    return of(
      new Profile({
        id: 1,
        name: 'Test',
        CustomerId: 101,
        UserFavorites: [],

        mailNewCase: true,
        mailNewMessage: true,
        mailChecklist: true,

        locale: 'nb',

        ical: '',
      }),
    );
  }

  if (userService.profile?.id > 0) {
    return userService.profile$.pipe(take(1));
  }

  return userService.getProfile();
};
