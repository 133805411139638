import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { Tenancy } from 'projects/apex/src/app/models/tenancy';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TenancyService } from './tenancy.service';

@Component({
  selector: 'apex-tenancy-info',
  templateUrl: './info.component.html',
})
export class TenancyInfoComponent implements OnInit, OnDestroy {
  @Input() tenancy: Tenancy;
  @Input() tenancyId: number;

  @Input() topLevelObjId: number;

  get payoffLine(): string {
    return t('Tenancy at {name}', { name: this.tenancy?.Customer?.name ?? '' });
  }

  private subscription = new Subscription();

  constructor(private service: TenancyService) {}

  ngOnInit(): void {
    if (this.tenancyId) {
      const sub = this.service
        .get(this.tenancyId)
        .pipe(map((res) => res.Entity))
        .subscribe({
          next: (tenancy) => {
            this.tenancy = tenancy;
          },
        });

      this.subscription.add(sub);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
