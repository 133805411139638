import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject, Subscription, debounceTime } from 'rxjs';
import StatusService from '../../interceptors/status/status.service';
import { trackPage } from '../../utils/analytics';

@Component({
  selector: 'apex-page-info',
  templateUrl: './page-info.component.html',
  styleUrls: ['./page-info.component.scss'],
})
export class PageInfoComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() icon = '';
  @Input() fontSet = '';
  @Input() returnDestination: string[] = [];
  @Input() showHelpButton = true;

  @Input() progressValue = 100;

  @ViewChild('titleRef') titleElement: ElementRef<HTMLSpanElement>;

  url: string;
  inflight = false;
  private subscriptions = new Subscription();

  titleChange$ = new Subject<string>();

  constructor(
    private titleService: Title,
    private statusService: StatusService,
  ) {
    this.subscriptions.add(
      this.titleChange$.pipe(debounceTime(125)).subscribe({
        next: (title) => {
          const url = new URL(window.location.href, 'https://exmaple.com');

          trackPage(title, window.location.href, `${url.pathname}${url.search}${url.hash}`);
        },
      }),
    );
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.statusService.requestStatusInflightThrottled$.subscribe((inflight) => {
        this.inflight = inflight;
      }),
    );
  }

  ngOnDestroy(): void {
    this.setTitle('');

    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    // If a title is provided we'll use that one.
    if (!this.title && this.titleElement?.nativeElement?.innerText) {
      this.setTitle(this.titleElement?.nativeElement?.innerText);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.title) {
      this.setTitle(this.title);
      this.progressValue = 100;
    }
  }

  setTitle(str: string): void {
    if (str) {
      this.titleService.setTitle(`${str} - APEX`);
    } else {
      this.titleService.setTitle('APEX');
    }

    this.titleChange$.next(this.titleService.getTitle());
  }
}
