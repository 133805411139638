<div mat-dialog-title>
  <div class="df ac jsb f1 oh">
    <h1 class="rm truncate">
      <T
        str="File"
        *ngIf="!data?.file?.name">
      </T>
      <ng-container *ngIf="viewFile?.name">
        {{ viewFile?.name }}
      </ng-container>
    </h1>
    <div class="df ac">
      <button
        mat-icon-button
        [matTooltip]="'Download {fileName}' | translate: { fileName: viewFile?.name ?? '' }"
        (click)="downloadFile(viewFile)">
        <mat-icon>download</mat-icon>
      </button>

      <button
        *ngIf="data?.showFolderAction && data.file && data.file.FolderId"
        type="button"
        mat-icon-button
        [matTooltip]="'Go to folder' | translate"
        (click)="goToFolder()">
        <mat-icon>folder</mat-icon>
      </button>

      <button
        type="button"
        mat-icon-button
        [matTooltip]="'Close' | translate"
        (click)="ref.close()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
</div>
<mat-dialog-content class="f1 df c">
  <apex-file-viewer
    [apexAutoFocus]="true"
    class="f1"
    [file]="viewFile"
    [edit]="false"
    [showFolderAction]="data?.showFolderAction">
  </apex-file-viewer>

  <ng-container *ngIf="data?.files?.length > 1">
    <apex-file-viewer-preview
      class="amrs"
      [files]="data.files"
      (fileChanged)="viewFile = $event">
    </apex-file-viewer-preview>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions class="df">
  <div class="df f1 jfe">
    <button
      mat-raised-button
      type="button"
      (click)="ref.close()">
      <T str="Close"></T>
    </button>
  </div>
</mat-dialog-actions>
