import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CroppedImageResult, ImageCropDialogData } from './image-crop.types';

@Component({
  selector: 'apex-image-crop-dialog',
  templateUrl: './image-crop-dialog.component.html',
})
export class ImageCropDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ImageCropDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageCropDialogData,
  ) {}

  save(croppedImageResult: CroppedImageResult): void {
    if (this.dialogRef) {
      this.dialogRef.close(croppedImageResult);
    }
  }
}
