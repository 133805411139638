import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FileViewerModule } from 'projects/apex/src/app/components/file-viewer/file-viewer.module';
import { TranslateModule } from '../translate/translate.module';
import { UserFavoriteButtonModule } from '../user-favorite-button/user-favorite-button.module';
import { SearchComponent } from './search.component';
import { SearchService } from './search.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,

    TranslateModule,
    UserFavoriteButtonModule,
    FileViewerModule,
  ],
  exports: [SearchComponent],
  declarations: [SearchComponent],
  providers: [SearchService],
})
export class SearchModule {}
