import { CaseCategory } from '../../models/case-category';
import { ChecklistGroupTemplateChecklist } from '../../models/checklist-group-template-checklist';
import { Field } from '../../models/field';
import { Model } from '../../models/model';
import { ProjectToFieldTemplate } from '../../models/project-to-field-template';
import { User } from '../../models/user';
import { CommercialType } from '../object/object.model';
import { MeterType } from '../object/project/meter/meter.model';

interface ChecklistTemplateData {
  meterTypes: MeterType[] | null;
  objectTypes: CommercialType[] | null;
  fields: Field[] | null;
  tenancy: boolean;
  agreement: boolean;
  apartment: boolean;
}

export class ChecklistTemplate extends Model<ChecklistTemplate> {
  name: string;
  description: string;

  CategoryId?: number;
  CreatorId: number;
  CustomerId: number;

  connectedChecklistsCount?: number;
  customerAccess: boolean;

  ChecklistGroupTemplateChecklist?: ChecklistGroupTemplateChecklist;

  ProjectToFieldTemplates?: ProjectToFieldTemplate[];
}

export class ChecklistTemplateItem extends Model<ChecklistTemplateItem> {
  name: string;
  description: string;

  ChecklistTemplateId: number;

  SectionId: number;

  CategoryId?: number;
  Category?: CaseCategory;
  CreatorId: number;

  sortOrder: number;

  data: ChecklistTemplateData;

  constructor(checklistTemplateItem?: Partial<ChecklistTemplateItem>) {
    super(checklistTemplateItem);

    this.data = checklistTemplateItem.data ?? ({} as ChecklistTemplateData);
  }
}

export class ChecklistTemplateSection extends Model<ChecklistTemplateSection> {
  name: string;
  description: string;

  ChecklistTemplateId: number;
  ChecklistTemplate?: ChecklistTemplate;

  ParentId: number;
  Parent?: ChecklistTemplateSection;

  CreatorId: number;
  Creator: User;

  sortOrder: number;
}
