import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { CommercialType, MetaType, Obj } from '../../object.model';
import { ObjectSelectorConfig, ObjectSelectorDialogComponent } from './dialog.component';

import { SafetyType } from '../../safety/safety.model';

import { noop } from 'lodash-es';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { booleanFromBooleanOrString, unique } from 'projects/apex/src/app/utils/functions';
import { Subscription } from 'rxjs';
import { ObjectService, QueryParams } from '../../object.service';

@Component({
  selector: 'apex-object-selector',
  templateUrl: './selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ObjectSelectorComponent),
      multi: true,
    },
    ObjectService,
  ],
})
export class ObjectSelectorComponent implements OnChanges, ControlValueAccessor, OnDestroy {
  @HostBinding('class.multiple')
  get classMultiple(): boolean {
    return this.multiple;
  }

  @Input() multiple = false;
  @Input() meta: MetaType;
  @Input() type: SafetyType | CommercialType;

  @Input() label: string;

  @Input() filterFunc: (o: Obj) => boolean;
  @Input() disabled = false;

  @Input() queryParams: QueryParams = {
    children: true,
  };

  @Input()
  get required(): boolean | string {
    return this.innerRequired;
  }

  set required(value: boolean | string) {
    this.innerRequired = booleanFromBooleanOrString(value);
  }

  @Input() hint: string;
  @Input() showHint = true;

  @Input() tenancyId: number;
  @Input() agreementId: number;

  private innerRequired = false;

  private innerValue: number | number[];

  private overlayRef: OverlayRef;

  private onChanged: (v: number | number[]) => void = noop;
  private onTouched: (v?: number | number[]) => void = noop;

  private subscription: Subscription;

  get value(): number | number[] {
    return this.innerValue;
  }

  set value(v: number | number[]) {
    if (this.multiple && !(v instanceof Array)) {
      this.innerValue = [v];
    } else {
      this.innerValue = v;
    }

    this.onChanged(v);
    this.onTouched();
  }

  get loop(): number[] {
    return this.innerValue instanceof Array ? this.innerValue : this.innerValue ? [this.innerValue] : [];
  }

  constructor(
    private elementRef: ElementRef,
    private injector: Injector,
    private overlay: Overlay,
  ) {}

  writeValue(v: number | number[]): void {
    if (!v) {
      return;
    }

    if (this.multiple && !(v instanceof Array)) {
      this.innerValue = [v];
    } else {
      this.innerValue = v;
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.multiple) {
      if (typeof this.multiple === 'string') {
        this.multiple = this.multiple === 'true';
      }

      if (this.multiple) {
        this.innerValue = [];
      }

      if (!this.label) {
        this.label = this.multiple ? t('Objects') : t('Object');
      }
    }

    if (changes.disabled) {
      if (typeof this.disabled === 'string') {
        this.disabled = this.disabled === 'true';
      }
    }
  }

  remove(id: number): void {
    if (this.innerValue instanceof Array) {
      const idx = this.innerValue.indexOf(id);

      if (idx !== -1) {
        this.innerValue.splice(idx, 1);
      }
    } else {
      this.innerValue = null;
    }

    this.onChanged(this.innerValue);
  }

  click(): void {
    if (this.disabled) {
      return;
    }

    if (this.overlayRef) {
      return;
    }

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
        {
          originX: 'center',
          originY: 'center',
          overlayX: 'center',
          overlayY: 'center',
        },
      ])
      .withPush(false);

    const overlayConfig = new OverlayConfig({
      disposeOnNavigation: true,
      hasBackdrop: false,
      positionStrategy,
    });

    this.overlayRef = this.overlay.create(overlayConfig);

    const osc: ObjectSelectorConfig = {
      multiple: this.multiple,
      meta: this.meta,
      type: this.type,
      current: this.innerValue,
      queryParams: this.queryParams,
      tenancyId: this.tenancyId,
      agreementId: this.agreementId,
    };

    if (this.filterFunc) {
      osc.filterFunc = this.filterFunc;
    }

    const portalInjector = Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: OverlayRef,
          useValue: this.overlayRef,
        },
        {
          provide: ObjectSelectorConfig,
          useValue: osc,
        },
      ],
    });
    const selectorDialog = new ComponentPortal(ObjectSelectorDialogComponent, null, portalInjector);

    this.subscription = this.overlayRef.attach(selectorDialog).instance.closed.subscribe({
      next: (ids: number[]) => {
        if (ids) {
          if (this.innerValue instanceof Array) {
            const iv = this.innerValue;
            const na = iv.filter((n) => ids.includes(n));

            na.push(...ids);

            this.innerValue = na.filter(unique);
          } else {
            this.innerValue = ids.pop();
          }
        }

        if (typeof this.innerValue === 'undefined') {
          this.innerValue = null;
        }

        this.onChanged(this.innerValue);
        delete this.overlayRef;
      },
    });
  }

  ngOnDestroy(): void {
    this.subscription ? this.subscription.unsubscribe() : noop();
  }
}
