import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CollectionResponse, DeleteResponse, EntityResponse } from 'projects/apex/src/app/utils/types';
import { environment } from 'projects/apex/src/environments/environment';
import { Observable } from 'rxjs';
import { BaseModel } from '../../models/model';
import { HttpExtra } from './http-extra';
import { HttpOptions } from './http.service.types';

@Injectable()
export abstract class BaseService<T extends BaseModel<T>> {
  readonly apiUrl = environment.api;

  private headers: HttpHeaders = new HttpHeaders({
    'content-type': 'application/json',
  });

  get options(): HttpOptions {
    return {
      headers: this.headers,
      withCredentials: true,
      params: {} as HttpParams,
    };
  }

  constructor(protected http: HttpClient) {}
}

@Injectable()
export abstract class HttpService<T extends BaseModel<T>, V = number> extends BaseService<T> {
  public abstract readonly route: string;

  /**
   * get
   */
  public get(id: V): Observable<T> {
    return this.http.get<T>(`${this.apiUrl}/${this.route}/${id}`, this.options);
  }

  /**
   * query
   */
  public query(queryParams: Record<string, string> = {}): Observable<T[]> {
    return this.http.get<T[]>(`${this.apiUrl}/${this.route}`, {
      ...this.options,
      params: { ...this.options.params, ...queryParams },
    });
  }

  /**
   * save
   */
  public save(model: T): Observable<T> {
    return model.id
      ? this.http.post<T>(`${this.apiUrl}/${this.route}/${model.id}`, model, this.options)
      : this.http.post<T>(`${this.apiUrl}/${this.route}`, model, this.options);
  }

  /**
   * delete
   */
  public delete(model: T): Observable<T> {
    return this.http.delete<T>(`${this.apiUrl}/${this.route}/${model.id}`, this.options);
  }
}

@Injectable()
export class APIService<T extends BaseModel<T>> {
  protected apiUrl = environment.api;
  protected route: string;

  private readonly defaultHeaders: { [name: string]: string | string[] } = {
    'content-type': 'application/json',
  };

  constructor(protected http: HttpClient) {}

  getRoute(extra: HttpExtra): string {
    return extra?.route || this.route;
  }

  options(extra?: HttpExtra): HttpOptions {
    let headers = new HttpHeaders(extra?.headers || this.defaultHeaders);

    const url = new URL(location.href);

    if (url?.searchParams?.get('InspectionId')) {
      headers = headers.set('InspectionId', url.searchParams.get('InspectionId'));
      headers = headers.set('path', url.pathname);
    }

    return {
      headers,
      withCredentials: true,
      params: extra?.params || {},
    };
  }

  /**
   * get
   */
  public get(id: number | string, extra?: HttpExtra): Observable<EntityResponse<T>> {
    return this.http.get<EntityResponse<T>>(`${this.apiUrl}/${this.getRoute(extra)}/${id}`, this.options(extra));
  }

  /**
   * query
   */
  public query(extra?: HttpExtra): Observable<CollectionResponse<T>> {
    return this.http.get<CollectionResponse<T>>(`${this.apiUrl}/${this.getRoute(extra)}`, this.options(extra));
  }

  /**
   * save
   */
  public save(model: T, extra?: HttpExtra): Observable<EntityResponse<T>> {
    return model.id
      ? this.http.post<EntityResponse<T>>(
          `${this.apiUrl}/${this.getRoute(extra)}/${model.id}`,
          model,
          this.options(extra),
        )
      : this.http.post<EntityResponse<T>>(`${this.apiUrl}/${this.getRoute(extra)}`, model, this.options(extra));
  }

  /**
   * delete
   */
  public delete(id: number | string, extra?: HttpExtra): Observable<DeleteResponse> {
    return this.http.delete<DeleteResponse>(`${this.apiUrl}/${this.route}/${id}`, this.options(extra));
  }
}
