import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment, { Moment, isMoment } from 'moment';
import { take } from 'rxjs/operators';
import { FilterChipsComponent } from '../../../components/filter-chips/filter-chips.component';
import { CaseParams } from '../../../models/case-params';
import { User } from '../../../models/user';
import { FilterType } from '../../../services/filter/filter.service.types';
import { UserService } from '../../../services/user/user.service';
import { CaseFilterDialogComponent } from './filter-dialog.component';

@Component({
  selector: 'apex-case-filter-shortcuts',
  templateUrl: './filter-shortcuts.component.html',
})
export class CaseFilterShortcutsComponent implements OnInit {
  @Input() count = 0;
  @Input() standardParams: CaseParams = new CaseParams();
  @Input() refreshing = false;

  @Output() refresh = new EventEmitter();

  @ViewChild('project') project: FilterChipsComponent;
  @ViewChild('apartment') apartment: FilterChipsComponent;
  @ViewChild('client') client: FilterChipsComponent;

  @ViewChild('filterButton') filterButton: MatButtonToggle;

  queryParamsValue: CaseParams = this.standardParams;

  @Input()
  get queryParams(): CaseParams {
    return this.queryParamsValue;
  }

  set queryParams(queryParams: CaseParams) {
    this.queryParamsValue = queryParams;
    this.hasFilters = this.filtersSelected();
  }

  commercial = false;
  moment = moment;
  public hasFilters = false;

  FilterType = FilterType;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userService.profile$.pipe(take(1)).subscribe((user: User) => {
      this.commercial = !!user?.Customer?.CustomerRight?.Commercial;
    });
  }

  openFilters(): void {
    this.dialog
      .open(CaseFilterDialogComponent, {
        data: {
          standardParams: this.standardParams,
          count: this.count,
        },
      })
      .afterClosed()
      .subscribe({
        next: () => {
          this.filterButton.checked = this.filtersSelected();
        },
      });
  }

  updateQueryParams(key: string, values: number | number[] | boolean | Moment): void {
    if (isMoment(values)) {
      values = values.valueOf() / 1000;
    }

    void this.router.navigate([], {
      queryParams: { [key]: values },
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }

  filtersSelected(): boolean {
    const filters = [
      'ContractorId',

      'Field',
      'Category',
      'CaseStatus',

      'Project',
      'Apartment',
      'Client',

      'Object',

      'deadline',
      'showArchived',
      'textSearch',
      'overdue',

      'Tenancy',
      'Agreement',
      'Checklist',
      'showChecklistItems',
      'hideWithChecklist',
      'Meter',
      'Tag',

      'hideFromProjectMom',
    ];

    for (const fn of filters) {
      if (this.queryParams[fn]) {
        if (Array.isArray(this.queryParams[fn]) && !(this.queryParams[fn] as Array<number | string>).length) {
          return false;
        }

        return true;
      }
    }

    return false;
  }

  foundGPS(objectId: number): void {
    void this.router.navigate([], {
      queryParams: {
        Project: null,
        Apartment: null,
        Client: null,
        Object: objectId,
      },
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }
}
