<div mat-dialog-title>
  <div class="df jsb f1 ac oh">
    <div class="oh">
      <h1 class="rm truncate">
        <ng-container *ngIf="!data?.text">
          <T
            str="No file"
            *ngIf="!viewer?.fileUsage?.fileName"></T>
          <ng-container *ngIf="viewer?.fileUsage?.fileName">
            {{ viewer.fileUsage.fileName }}
          </ng-container>
        </ng-container>
        <ng-container *ngIf="data?.text">
          {{ data?.text }}
        </ng-container>
      </h1>

      <div
        class="mat-caption green-background df ac"
        *ngIf="viewer?.fileUsage">
        <apex-autocomplete-viewer
          [type]="selfToAutocompleteType(viewer.fileUsage.self)"
          [id]="viewer.fileUsage.selfId">
        </apex-autocomplete-viewer>
        /
        <span class="amlxs">
          <T [str]="viewer.fileUsage.name | titlecase"> </T>
        </span>
      </div>
    </div>
  </div>

  <div class="df">
    <button
      [disabled]="viewer?.downloading"
      mat-icon-button
      [matTooltip]="viewer?.downloadTooltip"
      (click)="
        viewer?.downloadFileUsage(
          viewer?.fileUsage,
          viewer?.fileViewer?.imageViewer?.image,
          viewer?.fileViewer?.imageViewer?.box
        )
      ">
      <mat-icon>download</mat-icon>
    </button>

    <button
      *ngIf="data?.editable"
      mat-icon-button
      (click)="data.editLocked = !data.editLocked">
      <mat-icon>{{ data.editLocked ? 'lock' : 'lock_open' }}</mat-icon>
    </button>

    <button
      mat-icon-button
      [disabled]="ref.disableClose"
      (click)="ref.close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>

<div
  mat-dialog-content
  class="df">
  <apex-file-usage-viewer
    #viewer
    [fileUsageViewerData]="data"
    (markingsChange)="setSaveStatus(); setOriginalMarkings()">
  </apex-file-usage-viewer>
</div>

<div mat-dialog-actions>
  <div class="f1">
    <div *ngIf="data.showFileUsageViewerComment">
      <apex-textarea
        (textChange)="data.fileUsageViewerComment = $event"
        [value]="data.fileUsageViewerComment"
        [label]="'Comment' | translate">
      </apex-textarea>
    </div>

    <div class="f1 df jsb ac">
      <div>
        <button
          *ngIf="data.mode === 'mark' && !data.deleteDisabled"
          mat-icon-button
          color="warn"
          [disabled]="!data.editable || data.editLocked || getMarkingsCount() <= 0"
          (click)="deleteAllMarkings()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>

      <div class="df">
        <button
          mat-button
          type="button"
          [disabled]="ref.disableClose"
          (click)="ref.close()">
          <T str="Cancel"></T>
        </button>

        <button
          mat-raised-button
          color="primary"
          [disabled]="saveDisabled || !originalMarkings || ref.disableClose"
          (click)="submit()">
          <T str="Ok"></T>
        </button>
      </div>
    </div>
  </div>

  <mat-progress-bar
    *ngIf="ref.disableClose"
    mode="determinate"
    [value]="loadingProgress">
  </mat-progress-bar>
</div>
