import { Customer } from '../../models/customer';
import { Model } from '../../models/model';
import { ModelRole } from '../../models/model-role';
import { User } from '../../models/user';
import { SafetyType } from './safety/safety.model';

export type ObjectWithDepth = Obj & { depth: number };

export class Obj extends Model<Obj> {
  ParentId?: number;
  CreatorId: number | null;
  CustomerId: number;
  Customer?: Customer;
  name: string;
  description: string;
  note?: string;
  type: CommercialType | SafetyType;

  meta: MetaType;

  data: {
    // @todo Fix this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };

  Children?: Obj[];
  RelatedIds: number[] = [];
  Roles: ModelRole[] = [];

  constructor(name: string) {
    super();

    this.name = name;
    this.data = {};

    this.RelatedIds = [];
    this.Roles = [];
  }

  // @todo Make this generic like so
  /*
    constructor(model?: Partial<Obj>) {
    super(model);

    this.data = {};

    this.RelatedIds = [];
    this.Roles = [];
  }
  */
}

export enum MetaType {
  Commercial = 'commercial',
  Safety = 'safety',
}

export type MetaTypeString = keyof typeof MetaType;

export enum CommercialType {
  Project = 'project',
  Building = 'building',
  Floor = 'floor',
  Unit = 'unit',
  Room = 'room',
  Residence = 'residence',
  Elevator = 'elevator',
}

export type CommercialTypesString = keyof typeof CommercialType;

export class ObjectLog extends Model<ObjectLog> {
  ObjectId: number;
  Object?: Obj;

  Creator?: User;
  CreatorId: number | null;

  message: string;
}
