import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription, noop } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'apex-textarea',
  templateUrl: './textarea.component.html',
})
export class TextareaComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() value: string;
  @Input() readonly = false;
  @Output() debouncedChange = new EventEmitter<string>();
  @Output() textChange = new EventEmitter<string>();

  valueChanged: Subject<string> = new Subject<string>();
  isDisabled: boolean;

  private subscription: Subscription;

  ngOnInit(): void {
    this.subscription = this.valueChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((value) => {
      this.debouncedChange.emit(value);
    });
  }

  ngOnDestroy(): void {
    this.subscription ? this.subscription.unsubscribe() : noop();
  }
}
