import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '../translate/translate.module';

import { AgendaButtonComponent } from './agenda-button.component';

@NgModule({
  imports: [MatButtonModule, MatIconModule, MatTooltipModule, TranslateModule],
  exports: [AgendaButtonComponent],
  declarations: [AgendaButtonComponent],
  providers: [],
})
export class AgendaButtonModule {}
