import { Obj } from '../features/object/object.model';
import { Field } from './field';
import { Model } from './model';
import { User } from './user';

export class ObjectField extends Model<ObjectField> {
  name: string;
  description: string;
  fullName: string;

  CreatorId: number;
  Creator?: User;

  ObjectId: number;
  Object?: Obj;

  FieldId: number;
  Field?: Field;

  Contractors?: User[];

  color?: string;

  get basePrefix(): string {
    return !!this.Field?.name ? `${this.Field?.name} -` : '';
  }

  get actualColor(): string {
    return !this.color || this.color === '' ? this.Field?.color : this.color;
  }

  set actualColor(color: string) {
    this.color = color !== this.Field?.color ? color : null;
  }
}
