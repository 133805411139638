import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Meter, MeterState } from '../../object/project/meter/meter.model';
import { MeterService } from '../../object/project/meter/meter.service';

@Component({
  selector: 'apex-case-meter-info',
  templateUrl: './case-meter-info.component.html',
})
export class CaseMeterInfoComponent implements OnChanges, OnDestroy {
  @Input()
  get meter(): Meter {
    return this.meterValue;
  }

  set meter(meter: Meter) {
    this.meterValue = new Meter(meter);
    this.state = this.meter.getState();
  }

  @Input() caseId: number;

  meterValue: Meter;
  topLevelObjId: number;
  state: MeterState;

  MeterState = MeterState;

  private subscription = new Subscription();

  constructor(private meterService: MeterService) {}

  ngOnChanges(change: SimpleChanges): void {
    if (change.meter && this.meter) {
      this.subscription.add(
        this.meterService
          .getTopLevelObject(this.meter.id)
          .pipe(map((res) => res.Entity))
          .subscribe({
            next: (objProject) => {
              this.topLevelObjId = objProject.id;
            },
          }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
