<div class="df f1 t-ac d-ac t-jfs jsb ams p-c">
  <div class="d-f1">
    <mat-form-field>
      <mat-select
        [(ngModel)]="selectedGranularity"
        (selectionChange)="granularityChange(selectedGranularity)"
        [matTooltip]="'Change granularity of chart' | translate">
        <mat-option
          *ngFor="let granularity of granularities"
          [value]="granularity.name">
          {{ granularity.translatedName | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="f1 df">
    <button
      mat-icon-button
      [color]="selectedChartType === chartType.name ? 'primary' : ''"
      *ngFor="let chartType of chartTypes"
      (click)="chartChange(chartType.name)"
      [matTooltip]="chartType.translatedName">
      <mat-icon>
        {{ chartType.icon }}
      </mat-icon>
    </button>
  </div>
</div>
