import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Autocomplete } from 'projects/apex/src/app/models/autocomplete';
import { HttpService } from 'projects/apex/src/app/services/http/http.service';
import { Observable } from 'rxjs';
import { AutocompleteTypes } from './autocomplete.types';

@Injectable()
export class AutocompleteService extends HttpService<Autocomplete> {
  public route = 'autocomplete';

  queryString(type: AutocompleteTypes, query: HttpParams): Observable<Autocomplete[]> {
    return this.http.get<Autocomplete[]>(`${this.apiUrl}/${this.route}/${type}`, {
      headers: this.options.headers,
      withCredentials: this.options.withCredentials,
      params: query,
    });
  }

  queryId(type: AutocompleteTypes, query: HttpParams): Observable<Autocomplete> {
    return this.http.get<Autocomplete>(`${this.apiUrl}/${this.route}/${type}`, {
      headers: this.options.headers,
      withCredentials: this.options.withCredentials,
      params: query,
    });
  }
}
