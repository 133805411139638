import { Injectable } from '@angular/core';
import globalAxios, { AxiosError } from 'axios';
import { snackErr } from '../../modules/snack.module';
import StatusService from './status.service';

@Injectable()
export class AxiosConfigService {
  constructor(private statusService: StatusService) {
    globalAxios.interceptors.response.use(
      (response) => {
        this.statusService.requestFinished(response.config.method === 'post');

        return response;
      },
      (error) => {
        const isAxiosError = error instanceof AxiosError;
        const shouldSnack =
          isAxiosError && !error?.response?.config?.url.includes('/company-consents/missing-user-consents');

        if (shouldSnack) {
          snackErr(
            error?.response?.statusText ?? 'Failure with error',
            new Error(error?.response?.data?.message ?? error.message),
          );
        }

        this.statusService.requestFinished(error?.response?.config.method === 'post');

        throw error;
      },
    );

    globalAxios.interceptors.request.use((config) => {
      this.statusService.requestStarted(config.method === 'post');

      return config;
    });
  }
}
