<form
  #form="ngForm"
  class="df c f1"
  [@.disabled]="!afterViewInit">
  <section class="common df c">
    <div class="df c">
      <mat-form-field class="f1 amhs">
        <mat-label>
          <T str="Case name"></T>
        </mat-label>
        <input
          matInput
          name="name"
          minlength="3"
          cdkFocusInitial
          [(ngModel)]="case.name"
          (ngModelChange)="newAddon.name = $event"
          [disabled]="!!case?.EntryCardId || !!case?.fromProjectMom"
          required />
      </mat-form-field>
      <mat-form-field class="f1 amhs">
        <mat-label>
          <T str="Description"></T>
        </mat-label>
        <textarea
          matInput
          name="description"
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="10"
          [(ngModel)]="case.description">
        </textarea>
        <mat-hint align="end"> {{ case.description?.length || 0 }} / 4096</mat-hint>
      </mat-form-field>
    </div>

    <div class="df ac w">
      <apex-autocomplete
        class="f1 amhs"
        name="CaseCategoryId"
        type="CaseCategory"
        [label]="'Category' | translate"
        [(ngModel)]="case.CaseCategoryId"
        required>
      </apex-autocomplete>

      <apex-autocomplete
        *ngIf="!case?.ObjectId"
        [disabled]="case.fromProjectMom"
        class="f1 amhs"
        name="FieldId"
        type="Field"
        [label]="'Discipline' | translate"
        [(ngModel)]="case.FieldId"
        (ngModelChange)="fieldChange($event)"
        [params]="{
          ProjectId: case.ProjectId,
        }">
      </apex-autocomplete>

      <apex-autocomplete
        *ngIf="case?.ObjectId"
        class="object-field f1 amhs"
        name="ObjectFieldId"
        type="ObjectField"
        [label]="'Discipline' | translate"
        [(ngModel)]="case.ObjectFieldId"
        (modelChange)="fieldChange($event?.id)"
        [params]="{
          ObjectId: case.ObjectId,
          TenancyId: case.TenancyId,
        }">
      </apex-autocomplete>

      <apex-autocomplete
        class="f1 amhs"
        name="CaseManagerId"
        type="User"
        [label]="'Case manager' | translate"
        [(ngModel)]="case.CaseManagerId"
        [params]="{
          subscribed: true,
          ProjectId: case.ProjectId,
        }">
      </apex-autocomplete>

      <apex-autocomplete
        *ngIf="case.ClientId"
        class="f1 amhs"
        name="ClientId"
        type="Client"
        [label]="'Client' | translate"
        [(ngModel)]="case.ClientId"
        [params]="{
          ProjectId: case.ProjectId,
          ApartmentId: case.ApartmentId,
        }">
      </apex-autocomplete>

      <div class="df f1 amls">
        <mat-form-field
          *ngIf="hasProject"
          class="f1 amrs">
          <mat-label>
            <T str="Location"></T>
          </mat-label>
          <input
            matInput
            name="location"
            [(ngModel)]="case.location" />
        </mat-form-field>
      </div>
    </div>
    <div class="df ac amhs w">
      <apex-tag-chips
        #tagPicker
        [model]="case"
        class="df f1 c">
      </apex-tag-chips>
    </div>
  </section>

  <mat-accordion
    class="ams"
    multi
    #formAccordion>
    <mat-expansion-panel
      class="property"
      #property
      *ngIf="hasCommercial"
      [expanded]="false"
      [matTooltip]="'Both Project and Object can not be selected at the same time' | translate"
      [matTooltipDisabled]="!case?.ProjectId"
      [disabled]="!!case.ProjectId">
      <mat-expansion-panel-header
        class="new"
        [ngClass]="{
          'bg-color-main': !propertyGroup.invalid,
          'bg-color-warn': propertyGroup.invalid,
          'bg-color-empty':
            !propertyGroup.invalid && !case.ObjectId && !case.AgreementId && !case.TenancyId && !case.MeterId,
          'bg-color-disabled disabled': case.ProjectId,
        }">
        <mat-panel-title class="df ac oh">
          <ng-container *ngIf="case.ObjectId">
            <div class="df ac aprs oh">
              <mat-icon
                class="amrs overflow-visible"
                [matTooltip]="'Object' | translate"
                fontSet="material-icons-sharp">
                {{ case.Object | icon }}
              </mat-icon>
              <div class="truncate">
                {{ selectedObjectPath }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="case.TenancyId">
            <div class="df ac aprs oh">
              <mat-icon
                class="amrs overflow-visible"
                [matTooltip]="'Tenancy' | translate"
                fontSet="material-icons-sharp">
                home_work
              </mat-icon>
              <div class="truncate">
                {{ case.Tenancy?.name }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="case.AgreementId">
            <div class="df ac aprs oh">
              <mat-icon
                class="amrs overflow-visible"
                [matTooltip]="'Agreement' | translate"
                fontSet="material-icons-sharp">
                description
              </mat-icon>
              <div class="truncate">
                {{ case.Agreement?.name }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="case.MeterId">
            <div class="df ac aprs oh">
              <mat-icon
                class="amrs overflow-visible"
                [matTooltip]="'Meter' | translate"
                fontSet="material-icons-sharp">
                show_chart
              </mat-icon>
              <div class="truncate">
                {{ case.Meter?.name }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!case.ObjectId && !case.TenancyId && !case.AgreementId && !case.MeterId">
            <div class="df ac amrs">
              <mat-icon
                class="amrs"
                [matTooltip]="'Property' | translate"
                fontSet="material-icons-sharp">
                donut_large
              </mat-icon>
              <T str="Property"></T>
            </div>
          </ng-container>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <main
        ngModelGroup="property"
        #propertyGroup="ngModelGroup"
        class="df c">
        <apex-object-selector
          class="amhs ambs"
          [(ngModel)]="case.ObjectId"
          (ngModelChange)="objectChange($event)"
          name="ObjectId"
          [multiple]="false"
          [label]="'Object' | translate"
          [disabled]="!!case.ProjectId"
          [hint]="'Note: Cases created for external projects will be owned by owner of the project.' | translate"
          [tenancyId]="case.TenancyId"
          [agreementId]="case.AgreementId">
        </apex-object-selector>

        <div class="df ac w p-amvs">
          <apex-autocomplete
            class="amhs f1"
            [(ngModel)]="case.TenancyId"
            (ngModelChange)="tenancyChange($event)"
            type="Tenancy"
            [label]="'Tenancy' | translate"
            name="TenancyId"
            [disabled]="!!case.ProjectId"
            [params]="{
              ObjectId: case.ObjectId,
              AgreementId: case.AgreementId,
            }">
          </apex-autocomplete>
          <div
            class="df afe amhs"
            *ngIf="case.TenancyId"
            [matTooltip]="'Tenant' | translate">
            <ng-container *ngIf="case.Tenancy?.TenantCustomer">
              <mat-icon class="amrs"> business</mat-icon>
              {{ case.Tenancy.TenantCustomer.name }}
            </ng-container>
          </div>
        </div>

        <ng-container *ngIf="case.ObjectId">
          <div class="df ac w p-amvs">
            <apex-autocomplete
              class="amhs f1"
              [(ngModel)]="case.ObjectOwnershipId"
              (ngModelChange)="objectOwnershipChange($event)"
              type="ObjectOwnership"
              [label]="'Ownership' | translate"
              name="ObjectOwnershipId"
              [disabled]="!!case.ProjectId"
              [params]="{
                ObjectId: case.ObjectId,
                ObjectOwnershipId: case.ObjectOwnershipId,
              }">
            </apex-autocomplete>
          </div>
        </ng-container>

        <apex-autocomplete
          class="amhs"
          [(ngModel)]="case.AgreementId"
          (ngModelChange)="agreementChange($event)"
          (modelChange)="setName('Agreement', $event)"
          type="Agreement"
          [label]="'Agreement' | translate"
          name="AgreementId"
          [disabled]="!!case.ProjectId"
          [params]="{
            ObjectId: case.ObjectId,
            TenancyId: case.TenancyId,
          }">
        </apex-autocomplete>

        <apex-autocomplete
          class="amhs"
          [(ngModel)]="case.MeterId"
          (ngModelChange)="meterChange($event)"
          (modelChange)="setName('Meter', $event)"
          type="Meter"
          [label]="'Meter' | translate"
          name="MeterId"
          [disabled]="!!case.ProjectId"
          [params]="{
            ObjectId: case.ObjectId,
          }">
        </apex-autocomplete>
      </main>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="pbk"
      #pbk
      *ngIf="hasProject"
      [expanded]="false"
      [matTooltip]="'Both Project and Object can not be selected at the same time' | translate"
      [matTooltipDisabled]="!case?.ObjectId"
      [disabled]="!!(case.ObjectId || case.TenancyId || case.AgreementId)">
      <mat-expansion-panel-header
        class="new"
        [ngClass]="{
          'bg-color-main': !pbkGroup.invalid,
          'bg-color-warn': pbkGroup.invalid,
          'bg-color-empty': !pbkGroup.invalid && !case.ProjectId && !case.ApartmentId,
          'bg-color-disabled': case.ObjectId || case.TenancyId || case.AgreementId,
        }">
        <mat-panel-title class="df ac oh">
          <div class="df ac f1 oh">
            <mat-icon
              class="amrs"
              [matTooltip]="'Project' | translate">
              business
            </mat-icon>
            <ng-container *ngIf="!case.ProjectId">
              <T str="Project"></T>
            </ng-container>
            <ng-container *ngIf="case.ProjectId">
              <div class="truncate">
                {{ project?.name }}
                <ng-container *ngIf="case.ApartmentId"> / {{ case.Apartment?.name }} </ng-container>
              </div>
            </ng-container>
          </div>

          <mat-slide-toggle
            name="notifyCient"
            [matTooltip]="'Send notification to customers' | translate"
            *ngIf="!case.id && case.Apartment?.Clients?.length"
            [(ngModel)]="case.notifyClient"
            (click)="$event.stopPropagation()">
            <T str="Notify"></T>
          </mat-slide-toggle>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <main
        ngModelGroup="pbk"
        #pbkGroup="ngModelGroup"
        class="df c">
        <apex-autocomplete
          class="amhs ambs"
          name="ProjectId"
          type="Project"
          [label]="'Project' | translate"
          [(ngModel)]="case.ProjectId"
          (ngModelChange)="projectChange($event)"
          (modelChange)="$event && $event.id && $event.Parent && (externalCustomerId = $event.Parent.id)"
          [required]="showAddon"
          [disabled]="!!case.ObjectId || !!case.fromProjectMom"
          [hint]="'Note: Cases created for external prosjects will be owned by owner of the project.' | translate">
        </apex-autocomplete>

        <apex-autocomplete
          class="amhs p-amts"
          name="ApartmentId"
          type="Apartment"
          [label]="'Unit' | translate"
          [(ngModel)]="case.ApartmentId"
          (ngModelChange)="apartmentChange($event)"
          [params]="{
            ProjectId: case.ProjectId,
          }"
          [required]="showAddon"
          [disabled]="!!case.ObjectId || !case.ProjectId || !!case.fromProjectMom">
        </apex-autocomplete>

        <section *ngIf="case.Apartment?.Clients?.length">
          <header class="mat-caption amhs ambs">
            <T str="Customers"></T>
          </header>
          <ng-container *ngFor="let client of case.Apartment?.Clients; first as first">
            <div
              class="df ac amhs ambs"
              [class.amts]="!first">
              <mat-icon
                class="amrs client-color"
                [matTooltip]="'Customer' | translate"
                svgIcon="client"></mat-icon>
              {{ client.name }}
            </div>
          </ng-container>
        </section>
      </main>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="marking"
      *ngIf="!case.id && floorplans?.length">
      <mat-expansion-panel-header
        class="new"
        [ngClass]="{
          'bg-color-main': case.Markings?.length,
          'bg-color-empty': !case.Markings?.length,
        }">
        <mat-panel-title class="df ac">
          <mat-icon
            class="amrs"
            [matTooltip]="'Marking' | translate"
            svgIcon="floorplan"></mat-icon>
          <T
            str="Markings ({count})"
            [vars]="{ count: case.Markings?.length || 0 }"></T>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <main
        class="aphs apbs set-height"
        style="--height: 800px">
        <apex-file-usage-viewer
          #marking
          [fileUsageViewerData]="fileUsageViewerData"
          (markingsChange)="markingsChanged($event)">
        </apex-file-usage-viewer>
      </main>
    </mat-expansion-panel>

    <apex-case-contractors
      #caseContractors
      [case]="case"
      (addContractor)="addContractor($event.id, $event)"
      (removeContractor)="removeContractor($event)">
    </apex-case-contractors>

    <input
      matInput
      class="hide"
      name="contractor"
      [(ngModel)]="case.Contractors[0]"
      [required]="showAddon" />

    <mat-expansion-panel
      class="planning"
      [expanded]="false">
      <mat-expansion-panel-header
        class="new"
        [ngClass]="{
          'bg-color-main': !planningGroup.invalid,
          'bg-color-warn': planningGroup.invalid || !fromBeforeTo,
          'bg-color-empty': !planningGroup.invalid && !case.from && !case.to && !deadline,
        }">
        <mat-panel-title class="df ac oh">
          <div class="df ac f1 oh">
            <mat-icon
              class="amrs"
              [matTooltip]="'Planning' | translate"
              fontSet="material-icons-sharp">
              date_range
            </mat-icon>
            <div class="df c oh">
              <div class="truncate">
                <T
                  str="Planning"
                  *ngIf="!case.from || !case.to"></T>
                <T
                  *ngIf="case.from && case.to"
                  str="Planned: {from} - {to}"
                  [vars]="{
                    from: case?.from | dater | date: 'shortDate',
                    to: case?.to | dater | date: 'shortDate',
                  }">
                </T>
              </div>
              <div *ngIf="deadline">
                <span class="mat-caption">
                  <T
                    str="Deadline: {deadline}"
                    [vars]="{ deadline: deadline | dater | date }"></T>
                </span>
              </div>
            </div>
          </div>
          <div class="df ac">
            <button
              color="primary"
              mat-mini-fab
              (click)="$event.stopPropagation()"
              [matTooltip]="'Set planned number of days' | translate"
              [matMenuTriggerFor]="deadlineMenu">
              {{ selectedToDateDays }}
            </button>

            <mat-menu #deadlineMenu="matMenu">
              <div
                mat-menu-item
                (click)="setNewToDate(1)">
                1
              </div>
              <div
                mat-menu-item
                (click)="setNewToDate(7)">
                7
              </div>
              <div
                mat-menu-item
                (click)="setNewToDate(14)">
                14
              </div>
              <div
                mat-menu-item
                (click)="setNewToDate(28)">
                28
              </div>
              <div
                mat-menu-item
                (click)="setNewToDate(92)">
                92
              </div>
            </mat-menu>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <main
        ngModelGroup="planning"
        #planningGroup="ngModelGroup"
        class="df c">
        <section class="time-planning df c">
          <div class="from-wrapper df f1 ac ams p-minw-50">
            <apex-date-time
              class="df f1"
              name="startTime"
              #startTime="ngModel"
              [dateTitle]="'Start date' | translate"
              [timeTitle]="'Start time' | translate"
              (ngModelChange)="startTimeChange($event)"
              [(ngModel)]="case.from">
            </apex-date-time>
            -
            <ng-container *ngIf="!fromBeforeTo && !startTime.invalid">
              <T class="from-before-to-error mat-error mat-caption"> 'Start' time must be before 'End' time "></T>
            </ng-container>
          </div>
          <apex-date-time
            class="df f1 ams p-minw-50"
            name="endTime"
            #endTime="ngModel"
            [dateTitle]="'End date' | translate"
            [timeTitle]="'End time' | translate"
            [reverseViewOrder]="true"
            (ngModelChange)="endTimeChange($event, case.to)"
            [(ngModel)]="case.to">
          </apex-date-time>
          <mat-form-field class="f1 ams">
            <mat-label>
              <T str="Deadline"></T>
            </mat-label>
            <input
              matInput
              name="deadline"
              [(ngModel)]="deadline"
              [matDatepicker]="picker" />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </section>

        <mat-checkbox
          class="ams"
          name="createRepeatableCase"
          [(ngModel)]="createRepeatableCase">
          <T str="Create repeatable case"></T>
        </mat-checkbox>
        <section
          class="repeat-planning df w"
          *ngIf="createRepeatableCase">
          <mat-form-field class="f1 ams">
            <mat-label>
              <T str="Repeat"></T>
            </mat-label>
            <mat-select
              name="interval"
              [(ngModel)]="case.RepeatableCase.interval"
              [required]="createRepeatableCase && !case.RepeatableCase?.createCaseTime">
              <mat-option [value]="null">
                <T str="Never"></T>
              </mat-option>
              <mat-option
                *ngFor="let interval of intervals | keyvalue: bogusSort"
                [value]="interval.value">
                {{ translatedIntervals[interval.value] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="f1 ams"
            *ngIf="case.RepeatableCase.interval === intervals.Yearly">
            <mat-label>
              <T str="Repeat on"></T>
            </mat-label>
            <mat-select
              name="repeatYearOn"
              [(ngModel)]="case.RepeatableCase.repeatYearOn">
              <mat-option
                *ngFor="let repeatYearOn of repeatYearOns | keyvalue: bogusSort"
                [value]="repeatYearOn.value">
                {{ translatedRepeatYearOns[repeatYearOn.value] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="f1 ams"
            *ngIf="case.RepeatableCase.interval === intervals.Weekly">
            <mat-label>
              <T str="Repeat every"></T>
            </mat-label>
            <mat-select
              name="repeatWeekOn"
              [(ngModel)]="case.RepeatableCase.repeatWeekOn">
              <mat-option
                *ngFor="let repeatWeekOn of repeatWeekOns | keyvalue: bogusSort"
                [value]="repeatWeekOn.value">
                {{ translatedRepeatWeekOns[repeatWeekOn.value] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="f1 ams">
            <mat-label>
              <T str="Active until"></T>
            </mat-label>
            <input
              matInput
              name="endDate"
              (click)="endDatepicker.open()"
              [(ngModel)]="case.RepeatableCase.endDate"
              [matDatepicker]="endDatepicker" />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="endDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatepicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="f1 ams">
            <mat-label>
              <T str="Create case"></T>
            </mat-label>
            <mat-select
              name="createCasetimes"
              [(ngModel)]="case.RepeatableCase.createCaseTime"
              [required]="createRepeatableCase && !case.RepeatableCase?.interval">
              <mat-option [value]="null"> --</mat-option>
              <mat-option
                *ngFor="let time of createCaseTimes | keyvalue: bogusSort"
                [value]="time.value">
                <T
                  str="{hours, plural, =0 {} =1 {1 hour} other {# hours}} before start"
                  [vars]="{ hours: time.value }">
                </T>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </section>

        <mat-checkbox
          class="ams"
          name="fromRequest"
          [(ngModel)]="case.fromRequest"
          (ngModelChange)="fromRequestChange()">
          <T str="From request"></T>
        </mat-checkbox>
        <section
          class="from-request-time"
          *ngIf="case.fromRequest">
          <apex-date-time
            class="df f1 ams"
            name="fromRequestTime"
            [dateTitle]="'Date' | translate"
            [timeTitle]="'Time' | translate"
            [(ngModel)]="case.fromRequestTime">
          </apex-date-time>
        </section>
      </main>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="checklist-template"
      *ngIf="!case.Checklist"
      [expanded]="false">
      <mat-expansion-panel-header
        class="new"
        [ngClass]="{
          'bg-color-main': case.ChecklistTemplateId,
          'bg-color-empty': !case.ChecklistTemplateId,
        }">
        <mat-panel-title class="df ac">
          <div class="df ac f1">
            <mat-icon
              class="amrs"
              [matTooltip]="'Checklist Template' | translate">
              list_alt
            </mat-icon>
            <ng-container *ngIf="!case.ChecklistTemplateId">
              <T str="Checklist Template"></T>
            </ng-container>
            <ng-container *ngIf="case.ChecklistTemplateId">
              {{ checklistTemplate?.name }}
            </ng-container>
          </div>

          <button
            mat-icon-button
            type="button"
            *ngIf="case.ChecklistTemplateId"
            [matTooltip]="'Go to checklist template' | translate"
            (click)="$event.stopPropagation()"
            [routerLink]="['/', 'checklist-template', case.ChecklistTemplateId]">
            <mat-icon>launch</mat-icon>
          </button>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <main class="df c">
        <apex-autocomplete
          class="f1 amhs"
          name="checklistTemplate"
          type="ChecklistTemplate"
          [(ngModel)]="case.ChecklistTemplateId"
          (ngModelChange)="checklistTemplateChange($event)"
          [label]="'Checklist Template' | translate">
        </apex-autocomplete>

        <ng-container *ngIf="case.ChecklistTemplateId">
          <ng-container *ngIf="!checklistTemplateItems.length">
            <div class="amhs ambs">
              <T str="No checklist template items to show"></T>
            </div>
          </ng-container>

          <ng-container *ngIf="checklistTemplateItems.length">
            <div class="amhs ambs mat-caption">
              <T str="Checklist Items"></T>
            </div>
            <apex-checklist-section-preview
              [checklistTemplate]="checklistTemplate"
              [checklistTemplateItems]="checklistTemplateItems"
              [checklistTemplateSections]="checklistTemplateSections">
            </apex-checklist-section-preview>
          </ng-container>
        </ng-container>
      </main>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="addon"
      *ngIf="showAddon">
      <mat-expansion-panel-header
        class="new"
        [ngClass]="{
          'bg-color-main': !addonGroup.invalid,
          'bg-color-warn': addonGroup.invalid,
        }">
        <div class="mat-h3 rm df ac f1">
          <mat-icon
            class="amrs"
            [matTooltip]="'Involved' | translate"
            fontSet="material-icons-sharp">
            track_changes
          </mat-icon>
          <T str="Addon"></T>
        </div>
      </mat-expansion-panel-header>

      <main
        ngModelGroup="addon"
        #addonGroup="ngModelGroup"
        class="df c">
        <div class="df d-ac p-c w">
          <mat-form-field class="amhs f1">
            <mat-label>
              <T str="Name"></T>
            </mat-label>
            <input
              matInput
              [(ngModel)]="newAddon.name"
              name="name"
              required />
          </mat-form-field>
          <div class="amhs f1">
            <apex-autocomplete
              type="AddonCategory"
              name="AddonCategoryId"
              [label]="'Addon Category' | translate"
              [(ngModel)]="newAddon.AddonCategoryId"
              [params]="{
                customer: externalCustomerId ? externalCustomerId : profile?.CustomerId,
              }"
              [required]="true"
              [disabled]="!project">
            </apex-autocomplete>
          </div>
        </div>

        <div class="df ac w">
          <mat-form-field class="amhs f1">
            <mat-label>
              <T str="Description"></T>
            </mat-label>
            <textarea
              matInput
              name="description"
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="10"
              [(ngModel)]="newAddon.description">
            </textarea>
            <mat-hint align="end"> {{ newAddon.description?.length }} / 4096</mat-hint>
          </mat-form-field>

          <apex-autocomplete
            class="amhs f1"
            name="ClientId"
            type="Client"
            [label]="'Customer' | translate"
            [(ngModel)]="case.ClientId"
            [params]="{
              ApartmentId: case.ApartmentId,
            }"
            [disabled]="!case.ApartmentId"
            [required]="true">
          </apex-autocomplete>
        </div>

        <div class="df ac w">
          <mat-form-field class="amhs f1">
            <mat-label>
              <T str="Price in"></T>
            </mat-label>
            <input
              matInput
              name="priceIn"
              type="number"
              [(ngModel)]="newAddon.priceIn"
              [disabled]="!case.ApartmentId"
              required />
          </mat-form-field>
          <mat-form-field class="amhs f1">
            <mat-label>
              <T str="Price out"></T>
            </mat-label>
            <input
              matInput
              name="priceOut"
              type="number"
              [(ngModel)]="newAddon.priceOut"
              [disabled]="!case.ApartmentId"
              required />
          </mat-form-field>
          <mat-form-field class="amhs f1">
            <mat-label>
              <T str="VAT"></T>
              %
            </mat-label>
            <input
              matInput
              name="VAT"
              type="number"
              [(ngModel)]="newAddon.VAT"
              [disabled]="!case.ApartmentId"
              required />
          </mat-form-field>
        </div>

        <T class="amhs mat-caption"> Prices are excluding VAT "></T>
      </main>
    </mat-expansion-panel>
  </mat-accordion>

  <apex-box
    class="ams"
    *ngIf="!case.id">
    <T
      title
      str="Message"></T>

    <mat-icon icon>message</mat-icon>

    <apex-case-new-message
      class="db ams"
      #newMessage
      [required]="false">
    </apex-case-new-message>
  </apex-box>

  <section
    class="file ams"
    *ngIf="!case?.id">
    <apex-file-usage
      #files
      name="files"
      view="List"
      [title]="'Files' | translate"
      [self]="createRepeatableCase | caseFormCaseOrRepeatableCaseFileUsageSelf: case.id"
      [selfId]="case | caseFormCaseOrRepeatableCaseFileUsageSelfId">
    </apex-file-usage>
  </section>
</form>
