<mat-form-field>
  <input
    #input
    matInput
    name="query"
    [(ngModel)]="query"
    [matAutocomplete]="auto"
    (ngModelChange)="req.next($event)"
    (focus)="focusChange.emit(true)"
    (focusout)="focusChange.emit(false)" />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      *ngFor="let r of results"
      [value]="r.name"
      (click)="select(r)">
      <div class="w-100 h-100 df ac">
        <div class="f1 truncate">
          <div class="df ac">
            <mat-icon *ngIf="r.model === 'folder'">folder</mat-icon>
            <mat-icon *ngIf="r.model === 'file'">insert_drive_file</mat-icon>
            <div class="df c">
              <div class="mat-body-2">{{ r?.name }}</div>
              <div
                class="mat-caption"
                *ngIf="r?.Parent">
                {{ r.Parent.name }}
                <ng-container *ngIf="r?.Parent?.Parent"> / {{ r.Parent.Parent.name }} </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
