import { NgModule } from '@angular/core';

import { MatDialogModule } from '@angular/material/dialog';
import { EditorInitPipe } from './editor-init.pipe';

@NgModule({
  imports: [MatDialogModule],
  exports: [EditorInitPipe],
  declarations: [EditorInitPipe],
  providers: [],
})
export class EditorInitPipeModule {}
