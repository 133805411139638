import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChecklistTemplateSection } from '../checklist-template.model';

@Component({
  selector: 'apex-checklist-template-section-form',
  templateUrl: './form.component.html',
})
export class ChecklistTemplateSectionFormComponent implements OnInit {
  section: ChecklistTemplateSection;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { section: ChecklistTemplateSection },
    public ref: MatDialogRef<ChecklistTemplateSectionFormComponent>,
  ) {}

  ngOnInit(): void {
    this.section = new ChecklistTemplateSection(this.data.section);
  }
}
