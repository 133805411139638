<h1 mat-dialog-title>
  <T str="Explorer"></T>
</h1>
<mat-dialog-content class="f1 df">
  <mat-tab-group class="f1 df c">
    <mat-tab>
      <ng-template mat-tab-label>
        <T str="Texts"></T>
      </ng-template>
      <apex-text-list
        #textList
        [(selection)]="selectedTexts"
        [multiple]="true"
        [filterFn]="textFilter">
      </apex-text-list>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions>
  <div>
    <button
      mat-button
      (click)="textList.newText()">
      <T str="New"></T>
    </button>
  </div>
  <div>
    <button
      mat-button
      (click)="ref.close()">
      <T str="Back"></T>
    </button>
    <button
      mat-button
      mat-raised-button
      color="primary"
      (click)="choose()">
      <T str="Choose"></T>
    </button>
  </div>
</mat-dialog-actions>
