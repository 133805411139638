import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QrCodeDialogComponent } from '../qr-code-dialog/qr-code-dialog.component';
import { QrCodeDialogData } from '../qr-code-dialog/qr-code-dialog.data';
import { t } from '../translate/translate.function';

/**
 * text = encodedText
 * dark = hexcolor (000/000000)
 * light = hexcolor (fff/ffffff)
 * format = svg | png (png)
 * margin = border margin (4)
 * size = pixel (150)
 * ecLevel = error correction level (L, M, Q, H)
 */

@Component({
  selector: 'apex-qr-code',
  templateUrl: 'qr-code.component.html',
})
export class QrCodeComponent {
  @Input() text = '';
  /**
   * Allows the user to override the desired path for this link.
   * It uses location.origin for it's base. Reflecting the current URL.**/
  @Input() path = '';
  @Input() size = 256;
  @Input() title = '';
  @Input() name = '';
  /**
   * Allows override of tooltip for the button.
   */
  @Input() tooltip = t('QR Code');

  constructor(private dialog: MatDialog) {}

  click(e?: Event): void {
    e?.stopPropagation();

    this.dialog.open<QrCodeDialogComponent, QrCodeDialogData>(QrCodeDialogComponent, {
      data: {
        tooltip: this.tooltip,
        size: this.size,
        title: this.title,
        name: this.name,
        text: this.text,
        path: this.path,
      },
    });
  }
}
