import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Checklist } from '../../checklist/checklist.model';

@Component({
  selector: 'apex-checklist-group-checklist-dialog',
  templateUrl: './dialog.component.html',
})
export class ChecklistGroupChecklistDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { checklist: Checklist },
    public ref: MatDialogRef<ChecklistGroupChecklistDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.ref?.addPanelClass(['apex-fullscreen-dialog', 'phone']);
  }
}
