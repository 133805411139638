import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual, orderBy } from 'lodash-es';
import {
  FileUsageViewerData,
  FileUsageViewerMode,
} from 'projects/apex/src/app/components/file-usage-viewer/file-usage-viewer.types';
import { CollectionResponse } from 'projects/apex/src/app/utils/types';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, skipWhile, switchMap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '../../../components/confirm-dialog/confirm-dialog.types';
import { FileUsageViewerDialogComponent } from '../../../components/file-usage-viewer/file-usage-viewer-dialog.component';
import { t } from '../../../components/translate/translate.function';
import { Inspection } from '../../../models/inspection';
import { snack } from '../../../modules/snack.module';
import { InspectionNestService } from '../inspection-nest.service';
import { BaseInspectionService } from '../inspection.service';

@Component({
  selector: 'apex-inspection-box',
  templateUrl: './inspection-box.component.html',
  providers: [
    // this is because we will be used on older pages so far.
    BaseInspectionService,
  ],
})
export class InspectionBoxComponent implements OnInit, OnChanges, OnDestroy {
  @Input() projectId: number;
  @Input() apartmentId: number;
  @Input() objectId: number;

  @Input() expanded = false;

  inspections: Inspection[];
  expand = false;
  loading = false;

  count: number;
  limit = 5;
  page = 0;

  private subscription: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: BaseInspectionService,
    private dialog: MatDialog,
    private inspectionNestService: InspectionNestService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.queryParams
        .pipe(
          skipWhile((qp) => {
            this.expand = qp.inspectionsExpand === 'true';

            if (!this.sanityCheck()) {
              return true;
            }

            return !!(qp.inspectionPage === this.page && qp.inspectionLimit === this.limit && this.inspections);
          }),
          map((params) => ({
            inspectionPage: params.inspectionPage,
            inspectionLimit: params.inspectionLimit,
          })),
          distinctUntilChanged((x, y) => isEqual(x, y)),
          switchMap((qp) => {
            this.loading = true;

            return this.serviceFunc(qp.inspectionPage ?? 0, qp.inspectionLimit ?? 5);
          }),
        )
        .subscribe({
          next: (res) => {
            if (res) {
              this.count = res.count;
              this.page = res.page;
              this.limit = res.limit;
              this.inspections = orderBy(res.Collection, ['createdAt', 'id'], ['desc', 'desc']);
            }

            this.loading = false;
          },
        }),
    );
  }

  ngOnChanges(): void {
    this.sanityCheck();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changePage(e: PageEvent): void {
    void this.router.navigate([], {
      queryParams: {
        inspectionPage: e.pageIndex,
        inspectionLimit: e.pageSize,
      },
      queryParamsHandling: 'merge',
    });
  }

  toggleExpand(): void {
    void this.router.navigate([], {
      queryParams: {
        inspectionsExpand: !this.expand,
      },
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
      replaceUrl: true,
    });
  }

  showInspection(inspection: Inspection): void {
    if (inspection?.FileUsage?.File?.signed?.url) {
      const data: FileUsageViewerData = {
        fileUsages: [inspection?.FileUsage],
        mode: FileUsageViewerMode.View,
        editable: false,
        client: false,
      };

      this.dialog.open(FileUsageViewerDialogComponent, { data });
    } else {
      snack(t('Could not find signed file URL'));
    }
  }

  private sanityCheck(): boolean {
    if (this.projectId && this.objectId) {
      console.error('objectId should be used alone, not with projectId');

      return false;
    }

    if (this.apartmentId && this.objectId) {
      console.error('objectId should be used alone, not with apartmentId');

      return false;
    }

    if (this.apartmentId && !this.projectId) {
      console.error('aparmentId requires projectId');

      return false;
    }

    if (!this.projectId && !this.objectId) {
      console.error('One of projectId or objectId is required');

      return false;
    }

    return true;
  }

  private serviceFunc(page: number, limit: number): Observable<CollectionResponse<Inspection>> {
    if (this.projectId && this.apartmentId) {
      return this.service.queryForApartment(this.projectId, this.apartmentId, page, limit);
    }

    if (this.projectId && !this.apartmentId) {
      return this.service.queryForProject(this.projectId, page, limit);
    }

    if (this.objectId) {
      return this.service.queryForObject(this.objectId, page, limit);
    }

    throw new Error('No known function for your configuration');
  }

  async deleteInspection(inspection: Inspection): Promise<void> {
    this.dialog
      .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
        data: {
          text: t('Are you sure you want to delete this inspection?'),
          del: true,
        },
      })
      .afterClosed()
      .pipe(skipWhile((result) => !result))
      .subscribe({
        next: async () => {
          await this.inspectionNestService.delete(inspection.id);
          snack(t('Inspection deleted'));

          this.inspections = this.inspections.filter((i) => i.id !== inspection.id);
        },
      });
  }
}
