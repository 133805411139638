import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { t } from '@transifex/native';
import html2canvas from 'html2canvas';
import QRCode, { QRCodeToDataURLOptions } from 'qrcode';
import { snack } from '../../modules/snack.module';
import { presentDownload } from '../../utils/functions';
import { TranslateModule } from '../translate/translate.module';

@Component({
  selector: 'apex-qr-code-image',
  templateUrl: './qr-code-image.component.html',
  styleUrls: ['./qr-code-image.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    TranslateModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    ClipboardModule,
    MatTooltipModule,
    CommonModule,
  ],
})
export default class QrCodeImageComponent implements OnChanges {
  @Input() append = '';
  @Input() track = true;
  @Input() path = '';
  @Input() size = 256;
  @Input() margin = 4;
  @Input() dark = '000';
  @Input() light = 'fff';
  @Input() ecLevel: 'L' | 'M' | 'Q' | 'H' = 'M';
  @Input() text = '';
  @Input() title = '';
  @Input() name = '';

  @ViewChild('container') container?: ElementRef<HTMLDivElement>;

  imageUrl = '';

  @HostListener('click', ['$event'])
  onClick($event: Event): void {
    $event.preventDefault();

    void this.copyToClipboard();
  }

  @HostListener('contextmenu', ['$event'])
  onContextMenu($event: Event): void {
    $event.preventDefault();

    void this.copyToClipboard();
  }

  createText(text: string): string {
    let qm = false;

    if (this.append) {
      qm = text.includes('?');

      if (!qm) {
        text = `${text}?${this.append}`;
      } else {
        text = `${text}${this.append}`;
      }
    }

    if (this.track) {
      qm = text.includes('?');

      const track = 'utm_source=apex&utm_medium=dialog&utm_campaign=qr';

      if (!qm) {
        text = `${text}?${track}`;
      } else {
        text = `${text}&${track}`;
      }
    }

    return text;
  }

  async ngOnChanges(): Promise<string> {
    const qrCodeOptions = {
      errorCorrectionLevel: this.ecLevel,
      margin: this.margin,
      width: this.size,
      color: {
        dark: this.dark,
        light: this.light,
      },
    } as QRCodeToDataURLOptions;

    if (this.path) {
      this.text = location.origin + this.path;
    }

    const url = this.createText(this.text || location.href);

    this.imageUrl = await QRCode.toDataURL(url, qrCodeOptions);

    return this.imageUrl;
  }

  async convertToImage(): Promise<Blob> {
    const canvas = await html2canvas(this.container.nativeElement, {
      allowTaint: true,
    });

    return new Promise<Blob>((resolve) => {
      canvas.toBlob((b) => resolve(b), 'image/png', 1);
    });
  }

  get fileName(): string {
    const prefix = t('qr-code');

    return `${prefix}${this.title ? `-${this.title}` : ''}${this.name ? `-${this.name}` : ''}.png`;
  }

  async download(): Promise<void> {
    const blob = await this.convertToImage();

    presentDownload(this.fileName, blob, 'image/png');
  }

  async copyToClipboard(): Promise<void> {
    if (!navigator.clipboard) {
      return;
    }

    const blob = await this.convertToImage();
    const clipboardItem = new ClipboardItem({ 'image/png': blob });

    await navigator.clipboard.write([clipboardItem]);

    snack(t('Copied to clipboard'));
  }
}
