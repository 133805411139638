import { Pipe, PipeTransform } from '@angular/core';
import { Profile } from '../../../models/profile';
import { SidebarItemAccess } from '../sidebar-item-access.enum';
import { SidebarItem } from '../sidebar-items.types';

const sidebarAccessFunctions: Record<SidebarItemAccess, (profile: Profile) => boolean> = {
  [SidebarItemAccess.IsPayed]: (profile) => profile.isPayed,
  [SidebarItemAccess.IsAdmin]: (profile) => profile.isAdmin(),
  [SidebarItemAccess.HasCustomerRightCommercial]: (profile) => profile.hasCustomerRight('Commercial'),
  [SidebarItemAccess.HasCustomerRightAddon]: (profile) => profile.hasCustomerRight('Addon'),
  [SidebarItemAccess.HasAddonWork]: (profile) => profile.hasAddonWork(),
  [SidebarItemAccess.HasPortal]: (profile) => profile.hasPortal(),
  [SidebarItemAccess.HasCustomerRightSafety]: (profile) => profile.hasCustomerRight('Safety'),
  [SidebarItemAccess.HasCustomerRightSigning]: (profile) => profile.hasCustomerRight('Signing'),
};

@Pipe({
  name: 'sidebarItemAccess',
  standalone: true,
})
export class SidebarItemAccessPipe implements PipeTransform {
  transform(sidebarItems: SidebarItem[], profile: Profile): SidebarItem[] {
    if (!profile) {
      return null;
    }

    return sidebarItems.filter((item) => {
      if (sidebarAccessFunctions[item.access]) {
        return sidebarAccessFunctions[item.access](profile);
      }

      return true;
    });
  }
}
