import { NgModule } from '@angular/core';
import { ApexCurrencyPipe } from './currency.pipe';

@NgModule({
  imports: [],
  exports: [ApexCurrencyPipe],
  declarations: [ApexCurrencyPipe],
  providers: [],
})
export class ApexCurrencyPipeModule {}
