import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnChanges, ViewChild } from '@angular/core';
import { environment } from 'projects/apex/src/environments/environment';
import { take } from 'rxjs/operators';
import { snackErr } from '../../modules/snack.module';
import { locale } from '../../utils/functions';
import { t } from '../translate/translate.function';

@Component({
  selector: 'apex-translator',
  templateUrl: './translator.component.html',
})
export class TranslatorComponent implements OnChanges {
  @ViewChild('originalElement')
  private originalEl: ElementRef<HTMLDivElement>;

  @ViewChild('translatedElement')
  private translatedEl: ElementRef<HTMLDivElement>;

  loading: boolean;
  translated = false;

  private headers: HttpHeaders = new HttpHeaders({
    'content-type': 'application/json',
  });

  constructor(private http: HttpClient) {}

  ngOnChanges(): void {
    this.translated = false;
  }

  get originalHTML(): string {
    return this.originalEl.nativeElement.innerHTML;
  }

  set translatedHTML(text: string) {
    if (this.translatedEl?.nativeElement) {
      this.translatedEl.nativeElement.innerHTML = text;
    }
  }

  translate(): void {
    if (this.translated) {
      this.translated = false;

      return;
    }

    this.loading = true;
    this.http
      .get(`${environment.api}/translate`, {
        headers: this.headers,
        withCredentials: true,
        params: {
          lang: locale(),
          text: this.originalHTML,
        },
      })
      .pipe(take(1))
      .subscribe({
        next: (res: { lang: string; text: string; translation: string }) => {
          this.loading = false;
          this.translated = true;

          this.translatedHTML = res.translation;
        },
        error: (err) => {
          this.loading = false;
          snackErr(t('Could not translate'), err);
        },
      });
  }
}
