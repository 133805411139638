<div class="fab-container">
  <div>
    <button
      [matMenuTriggerFor]="menu"
      mat-fab
      class="fab-toggler"
      [color]="color">
      <mat-icon>{{ icon }}</mat-icon>
    </button>

    <mat-menu
      #menu="matMenu"
      [overlapTrigger]="true">
      <button
        mat-menu-item
        *ngFor="let btn of filteredButtons"
        (click)="btn.click()">
        <mat-icon
          *ngIf="btn.svgIcon"
          [ngClass]="btn.iconColor"
          [svgIcon]="btn.svgIcon">
        </mat-icon>

        <mat-icon
          *ngIf="btn.icon"
          [ngClass]="btn.iconColor">
          {{ btn.icon }}
        </mat-icon>
        <span
          *ngIf="btn.text"
          [ngClass]="btn.textColor">
          {{ btn.text }}
        </span>
      </button>
    </mat-menu>
  </div>
</div>
