import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { remove } from 'lodash-es';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';

import { BaseService } from '../../services/http/http.service';
import { UserService } from '../../services/user/user.service';
import { Bookmark, BookmarkFolder } from './bookmark.model';

@Injectable()
export class BookmarkService extends BaseService<Bookmark> {
  route = 'bookmark';

  public bookmarks: Bookmark[] = [];
  public folders: BookmarkFolder[] = [];

  public data$: ReplaySubject<boolean> = new ReplaySubject();

  constructor(
    client: HttpClient,
    private userService: UserService,
  ) {
    super(client);

    this.userService.profile$
      .pipe(
        mergeMap((_) => forkJoin([this.getBookmarks(), this.getFolders()])),
        take(1),
      )
      .subscribe({
        next: (_) => {
          this.data$.next(true);
        },
      });
  }

  public getBookmarks(): Observable<Bookmark[]> {
    return this.http.get<Bookmark[]>(this.bookmarkUrl(null), this.options).pipe(
      tap((bs) => {
        this.bookmarks = bs;
      }),
    );
  }

  public getFolders(): Observable<BookmarkFolder[]> {
    return this.http.get<BookmarkFolder[]>(this.folderUrl(null), this.options).pipe(
      tap((fs) => {
        this.folders = fs;
      }),
    );
  }

  public saveBookmark(bookmark: Bookmark): Observable<Bookmark> {
    return this.http.post<Bookmark>(this.bookmarkUrl(bookmark), bookmark, this.options).pipe(
      tap((b) => {
        if (!bookmark.id) {
          this.bookmarks.push(b);
          this.data$.next(true);
        }
      }),
    );
  }

  public saveFolder(folder: BookmarkFolder): Observable<BookmarkFolder> {
    return this.http.post<BookmarkFolder>(this.folderUrl(folder), folder, this.options).pipe(
      tap((f) => {
        if (!folder.id) {
          this.folders.push(f);
          this.data$.next(true);
        }
      }),
    );
  }

  public deleteBookmark(bookmark: Bookmark): Observable<void> {
    return this.http.delete<void>(this.bookmarkUrl(bookmark), this.options).pipe(
      tap((_) => {
        remove(this.bookmarks, (b) => b.id === bookmark.id);
        this.data$.next(true);
      }),
    );
  }

  deleteFolder(folder: BookmarkFolder): Observable<void> {
    return this.http.delete<void>(this.folderUrl(folder), this.options).pipe(
      tap((_) => {
        remove(this.folders, (f) => f.id === folder.id);
        this.data$.next(true);
      }),
    );
  }

  private bookmarkUrl(b: Bookmark): string {
    return b?.id ? `${this.apiUrl}/bookmark/${b.id}` : `${this.apiUrl}/bookmark`;
  }

  private folderUrl(f: BookmarkFolder): string {
    return f?.id ? `${this.apiUrl}/bookmark/folder/${f.id}` : `${this.apiUrl}/bookmark/folder`;
  }
}
