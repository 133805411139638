import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import { padStart } from 'lodash-es';
import moment, { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { Case } from '../../../models/case';
import { RepeatableCase } from '../repeatable-case.model';

interface CaseViewData extends Case {
  RepeatableCase?: RepeatableCase;
  height: number;
  top: number;
  width: number;
  left: number;
}

interface RangeCaseViewData extends Case {
  RepeatableCase?: RepeatableCase;
  fromToday: boolean;
  toToday: boolean;
}

@Component({
  selector: 'apex-calendar-day-view',
  templateUrl: './day.component.html',
})
export class CalendarDayViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() cases: Case[] = [];
  @Input() repeatableCases: RepeatableCase[] = [];
  @Input() futureRepeatableCases: RepeatableCase[] = [];

  @Input() dateMoment: Moment;
  @Input() showTime = true;
  @Input() showRange = true;

  @Input() loading = false;

  @Output() viewCase: EventEmitter<Case> = new EventEmitter<Case>();
  @Output() createCase: EventEmitter<{ moment: Moment }> = new EventEmitter<{ moment: Moment }>();
  @Output() editRepeatableCase: EventEmitter<Case> = new EventEmitter<Case>();
  @Output() createCaseFromRepeatableCase: EventEmitter<Case> = new EventEmitter<Case>();
  @Output() deleteRepeatableCase: EventEmitter<Case> = new EventEmitter<Case>();

  casesCombined: Array<Case | RepeatableCase> = [];
  viewCases: CaseViewData[] = [];
  rangeCases: RangeCaseViewData[] = [];

  showAll = false;

  dateDay: string;

  d: number;
  w: number;
  m: number;
  y: number;

  currentMoment: Moment;

  timeValues: string[] = [];

  private subscription = new Subscription();

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.timeValues = this.buildTimesArray();

    const sub = this.route.queryParams.subscribe({
      next: (params) => {
        if (this.dateMoment?.isValid()) {
          this.currentMoment = moment(this.dateMoment);
        } else {
          this.d = Number(params.date);
          this.w = Number(params.week);
          this.m = Number(params.month) - 1;
          this.y = Number(params.year);

          this.currentMoment = moment().set({
            date: this.d,
            month: this.m,
            year: this.y,
          });

          this.dateDay = moment(this.currentMoment).format('ddd');
        }
      },
    });

    this.subscription.add(sub);
  }

  ngOnChanges(): void {
    this.casesCombined = [...this.cases, ...this.repeatableCases, ...this.futureRepeatableCases];

    if (this.currentMoment?.isValid) {
      this.rangeCases = [];
      this.viewCases = this.createCaseViewData();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openCase(c: Case, menuTrigger?: MatMenuTrigger): void {
    if (menuTrigger) {
      menuTrigger.closeMenu();
    }

    this.viewCase.emit(c);
  }

  makeCase(time: string): void {
    const tempTime = moment(time, 'HH:mm');
    const currentDayTime = moment(this.currentMoment).set({
      second: 0,
      minute: tempTime.minute(),
      hour: tempTime.hour(),
    });
    const slot = { moment: currentDayTime };

    this.createCase.emit(slot);
  }

  createCaseViewData(): CaseViewData[] {
    const viewCasesData: CaseViewData[] = [];

    this.casesCombined
      .sort((a, b) => {
        [a, b] = [a instanceof RepeatableCase ? a.case : a, b instanceof RepeatableCase ? b.case : b];

        if (
          moment(a.from).isSame(b.from, 'hour') &&
          moment(a.from).isSame(b.from, 'minute') &&
          moment(a.to).isAfter(b.to)
        ) {
          return -1;
        }

        if (a.from < b.from) {
          return -1;
        }

        if (a.from > b.from) {
          return 1;
        }

        return 0;
      })
      .forEach((cc) => {
        const c = cc instanceof RepeatableCase ? cc.case : cc;

        const caseStart = moment(c.from).diff(moment(this.currentMoment).startOf('day'), 'hour', true) * (100 / 24);
        const caseLength = moment(c.to).diff(moment(c.from), 'hour', true) * (100 / 24);

        if (moment(c.from)?.isSame(this.currentMoment, 'd') && moment(c.to).isSame(this.currentMoment, 'd')) {
          let width = 100;
          let left = 0;

          const prev = viewCasesData[viewCasesData.length - 1];

          if (caseStart - 1 < prev?.top && caseStart + 1 > prev.top) {
            width = prev.width * 0.8;
            prev.width = width / 2.2;
            left = 100 - width;
          }

          viewCasesData.push({
            ...c,
            RepeatableCase: cc instanceof RepeatableCase ? cc : cc.RepeatableCase,
            height: caseLength,
            top: caseStart,
            width,
            left,
          });
        } else if (!moment(c.from).isSame(moment(c.to), 'd') && this.currentMoment.isBetween(c.from, c.to, 'd', '[]')) {
          this.rangeCases.push({
            ...c,
            RepeatableCase: cc instanceof RepeatableCase ? cc : cc.RepeatableCase,
            fromToday: moment(c.from).isSame(this.currentMoment, 'd'),
            toToday: moment(c.to).isSame(this.currentMoment, 'd'),
          });
        }
      });

    return viewCasesData;
  }

  private buildTimesArray(): string[] {
    const times: string[] = [];

    for (let i = 0; i < 24; i++) {
      const time = `${padStart(i.toString(), 2, '0')}:00`;

      times.push(time);
    }

    return times;
  }
}

// day could fetch data for itself -> pass from to (same date) to cases and get the correct stuff
