<ng-container *ngIf="model && name">
  <header class="bg-color-main df ac jsb">
    <div class="mat-body-1 rm">{{ label }}</div>
    <div class="df ac">
      <button
        mat-icon-button
        color="warn"
        type="button"
        [disabled]="!textUsagesList?.selectedOptions?.selected?.length"
        (click)="delete()">
        <mat-icon>delete</mat-icon>
      </button>
      <button
        mat-icon-button
        type="button"
        (click)="add()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </header>
  <main class="oa">
    <mat-selection-list #textUsagesList>
      <mat-list-option
        *ngFor="let tu of textUsages"
        [value]="tu">
        {{ tu.textName }}
      </mat-list-option>
    </mat-selection-list>
  </main>
</ng-container>
