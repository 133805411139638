import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';

import { ObjectGPSSearchButtonComponent } from './gps-search-button.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatTooltipModule, MatIconModule, MatMenuModule, TranslateModule],
  exports: [ObjectGPSSearchButtonComponent],
  declarations: [ObjectGPSSearchButtonComponent],
  providers: [],
})
export class ObjectGPSSearchButtonModule {}
