<form
  *ngIf="meter?.id"
  class="df ac"
  #valueForm="ngForm"
  novalidate
  (ngSubmit)="onSubmitValue(); valueForm.reset()">
  <mat-form-field class="f1 amr">
    <mat-label>
      <T str="Value"></T>
    </mat-label>
    <input
      matInput
      type="number"
      name="value"
      required
      [(ngModel)]="value" />
    <mat-hint>
      <ng-container *ngIf="isError(value)">Error!</ng-container>
      <ng-container *ngIf="isWarning(value) && !isError(value)">Warning!</ng-container>
    </mat-hint>
  </mat-form-field>
  <button
    [disabled]="!valueForm.valid"
    type="submit"
    color="primary"
    mat-raised-button>
    <T str="Save"></T>
  </button>
</form>
