<mat-icon
  [matTooltip]="'Search for nearby objects' | translate"
  (click)="searchGPS()"
  [class.animate-rotate]="loading">
  gps_not_fixed
</mat-icon>

<div [matMenuTriggerFor]="menu"></div>
<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <button
      mat-menu-item
      *ngFor="let object of results"
      (click)="select(object)">
      {{ object.name }}
    </button>
  </ng-template>
</mat-menu>
