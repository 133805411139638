import { CommonModule } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import QrCodeImageContainerComponent from '../qr-code-image-container/qr-code-image-container.component';
import QrCodeImageComponent from '../qr-code-image/qr-code-image.component';
import { TranslateModule } from '../translate/translate.module';
import { QrCodeDialogData } from './qr-code-dialog.data';

@Component({
  selector: 'apex-qr-code-dialog',
  templateUrl: './qr-code-dialog.component.html',
  styleUrls: ['./qr-code-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    CommonModule,
    TranslateModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    QrCodeImageComponent,
    QrCodeImageContainerComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class QrCodeDialogComponent {
  @ViewChild('qrCodeImageContainer') qrCodeImageContainer?: QrCodeImageContainerComponent;

  hasClipboardItem = !!window.ClipboardItem;

  constructor(
    public dialogRef: MatDialogRef<QrCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QrCodeDialogData,
  ) {}

  async downloadQr(): Promise<void> {
    await this.qrCodeImageContainer?.download();
  }

  async copyToClipboard(): Promise<void> {
    await this.qrCodeImageContainer?.copyToClipboard();
  }

  ok(): void {
    this.dialogRef.close();
  }
}
