import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from 'lodash-es';
import { Observable, Subject, Subscription, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { FileUsageDialogComponent } from '../../../components/file-usage-dialog/file-usage-dialog.component';
import { FileUsageService } from '../../../components/file-usage/file-usage.service';
import { t } from '../../../components/translate/translate.function';
import { FileUsage } from '../../../models/file-usage';
import { snack, snackErr } from '../../../modules/snack.module';
import { UserService } from '../../../services/user/user.service';
import { constants } from '../../../utils/constants';
import { markFormPristine } from '../../../utils/functions';
import { CommercialType } from '../../object/object.model';
import { MeterType } from '../../object/project/meter/meter.model';
import { ChecklistTemplate, ChecklistTemplateItem } from '../checklist-template.model';
import { ChecklistTemplateItemService } from '../checklist-template.service';

@Component({
  selector: 'apex-checklist-template-item-form',
  templateUrl: './form.component.html',
})
export class ChecklistTemplateItemFormComponent implements OnInit, OnDestroy {
  @Input() checklistTemplate: ChecklistTemplate;
  @Input() checklistTemplateItem: ChecklistTemplateItem;
  @Input() checklistTemplateItems: ChecklistTemplateItem[] = [];
  @Input() disabled: boolean;
  @Input() templateCategory: number;

  @Output() listChange = new EventEmitter();

  @ViewChild('checklistTemplateItemForm') checklistTemplateItemForm: NgForm;

  saved = false;

  fuCount: number;
  fileUsages: FileUsage[] = [];

  MeterType = MeterType;
  ObjectType = CommercialType;

  nameFocus = false;
  descFocus = false;

  saveData$ = new Subject<void>();
  saveData$$ = this.saveData$
    .pipe(
      debounceTime(constants.inputDebounceTime),
      filter((_) => !!this.checklistTemplateItem.name && !!this.checklistTemplateItem.CategoryId),
      switchMap((_) =>
        this.save().pipe(
          catchError((err) => {
            snackErr(t('Could not save'), err);

            return of(err);
          }),
        ),
      ),
      tap((saved) => {
        this.checklistTemplateItem.id = saved.id;
      }),
      tap((_) => markFormPristine(this.checklistTemplateItemForm)),
      tap((_) => {
        this.saved = true;
      }),
      debounceTime(1500),
      tap((_) => {
        this.saved = false;
      }),
    )
    .subscribe();

  private subscription = new Subscription();

  private _associate = false;

  get associateUnit(): boolean {
    return this._associate || this.hasAssociateValues;
  }

  set associateUnit(value: boolean) {
    const data = this.checklistTemplateItem.data;

    if (!value) {
      data.meterTypes = [];
      data.objectTypes = [];
      data.fields = [];
      data.tenancy = false;
      data.agreement = false;
      data.apartment = false;
    }

    this._associate = value;
  }

  get hasAssociateValues(): boolean {
    const data = this.checklistTemplateItem.data;

    return (
      !!data?.meterTypes?.length ||
      !!data?.objectTypes?.length ||
      !!data?.fields?.length ||
      data?.tenancy ||
      data?.agreement ||
      data?.apartment
    );
  }

  get hasCommercial(): boolean {
    return this.userService.hasCustomerRight('Commercial');
  }

  get hasProject(): boolean {
    return this.userService.hasCustomerRight('Project');
  }

  constructor(
    private service: ChecklistTemplateItemService,
    private fuService: FileUsageService,
    private dialog: MatDialog,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    if (!this.checklistTemplateItem) {
      this.checklistTemplateItem = new ChecklistTemplateItem();
    }

    if (!this.checklistTemplateItem.id) {
      this.checklistTemplateItem.CategoryId = this.checklistTemplate.CategoryId;
    }

    if (this.checklistTemplateItem?.id) {
      this.fetchFileUsages();
    }

    if (this.hasAssociateValues) {
      this.associateUnit = true;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.saveData$$?.unsubscribe();
  }

  remove(): void {
    if (this.checklistTemplateItem?.id) {
      this.subscription.add(
        this.service.deleteItem(this.checklistTemplate.id, this.checklistTemplateItem).subscribe({
          next: (item) => {
            const index = this.checklistTemplateItems.map((cti) => cti.id).indexOf(item.id);

            if (index !== -1) {
              this.checklistTemplateItems.splice(index, 1);
              this.listChange.emit();
            }

            snack(t('Deleted'));
          },
          error: (err) => snackErr(t('Could not delete'), err),
        }),
      );
    } else {
      const index = this.checklistTemplateItems.indexOf(this.checklistTemplateItem);

      if (index !== -1) {
        this.checklistTemplateItems.splice(index, 1);
        this.listChange.emit();
      }
    }
  }

  save(): Observable<ChecklistTemplateItem> {
    return this.service.saveItem(this.checklistTemplate.id, this.checklistTemplateItem);
  }

  duplicateItem(): void {
    const newTemplateItem = new ChecklistTemplateItem(this.checklistTemplateItem);

    newTemplateItem.id = null;
    newTemplateItem.sortOrder = this.checklistTemplateItem.sortOrder + 1;

    const sub = this.service.saveItem(this.checklistTemplate.id, newTemplateItem).subscribe({
      next: (item) => {
        let fuSaves = [];

        this.fileUsages.forEach((fu) => {
          const newFu = cloneDeep(fu);

          newFu.selfId = String(item.id);
          fuSaves = [...fuSaves, this.fuService.save(newFu)];
        });

        const $ = fuSaves.length ? forkJoin(fuSaves) : of(null);

        const sub1 = $.subscribe({
          next: (_) => {
            this.checklistTemplateItems.splice(item.sortOrder, 0, item);
            this.listChange.emit();
            snack(t('Duplicated'));
          },
          error: (err) => snackErr(t('Could not duplicate'), err),
        });

        this.subscription.add(sub1);
      },
      error: (err) => snackErr(t('Could not duplicate'), err),
    });

    this.subscription.add(sub);
  }

  openFileUsage(): void {
    const sub = this.dialog
      .open(FileUsageDialogComponent, {
        data: {
          self: 'checklist-template-item',
          selfId: this.checklistTemplateItem.id,
          name: 'files',
          title: t('Files'),
        },
      })
      .afterClosed()
      .subscribe({
        next: (fus: FileUsage[] = []) => {
          this.fileUsages = fus;
          this.fuCount = this.fileUsages.length;
        },
      });

    this.subscription.add(sub);
  }

  fetchFileUsages(): void {
    const sub = this.fuService.all('checklist-template-item', this.checklistTemplateItem.id, 'files').subscribe({
      next: (fus) => {
        this.fileUsages = fus;
        this.fuCount = this.fileUsages?.length;
      },
    });

    this.subscription.add(sub);
  }
}
