import { NgModule } from '@angular/core';
import { FileSizePipe } from './file-size.pipe';

@NgModule({
  imports: [],
  exports: [FileSizePipe],
  declarations: [FileSizePipe],
  providers: [],
})
export class FileSizePipeModule {}
