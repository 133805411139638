import { Injectable } from '@angular/core';
import { CollectionResponse, DeleteResponse, EntityResponse } from 'projects/apex/src/app/utils/types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { APIService } from '../../../../services/http/http.service';
import { Project } from '../project.model';

import { Params } from '@angular/router';
import { Meter, MeterState, MeterValue } from './meter.model';

@Injectable()
export class MeterService extends APIService<Meter> {
  queryByObject(
    objectId: number,
    page: number,
    limit: number,
    state?: MeterState[],
  ): Observable<CollectionResponse<Meter>> {
    const url = this.url(objectId);

    return this.http
      .get<CollectionResponse<Meter>>(
        url,
        this.options({
          params: {
            page: String(page),
            limit: String(limit),
            state,
          },
        }),
      )
      .pipe(
        tap((res) => {
          if (res?.Collection?.length) {
            res.Collection = res.Collection.map((c) => new Meter(c));
          }
        }),
      );
  }

  getByObject(objectId: number, meterId: number): Observable<EntityResponse<Meter>> {
    const url = this.url(objectId, meterId);

    return this.http.get<EntityResponse<Meter>>(url, this.options());
  }

  saveByObject(meter: Meter): Observable<EntityResponse<Meter>> {
    const url = this.url(meter.ObjectId, meter.id);

    return this.http.post<EntityResponse<Meter>>(url, meter, this.options());
  }

  deleteByObject(meter: Meter): Observable<DeleteResponse> {
    const url = this.url(meter.ObjectId, meter.id);

    return this.http.delete<DeleteResponse>(url, this.options());
  }

  getTopLevelObject(meterId: number): Observable<EntityResponse<Project>> {
    const url = `${this.apiUrl}/meter/${meterId}/top-level-object`;

    return this.http.get<EntityResponse<Project>>(url, this.options());
  }

  getStateListFromParams(qp: Params): MeterState[] {
    const states = [];

    if (qp.meterShowNormal !== 'false') {
      states.push(MeterState.Normal);
    }

    if (qp.meterShowWarning !== 'false') {
      states.push(MeterState.Warning);
    }

    if (qp.meterShowError !== 'false') {
      states.push(MeterState.Error);
    }

    return states;
  }

  private url(objectId: number, meterId?: number): string {
    return meterId ? `${this.apiUrl}/object/${objectId}/meter/${meterId}` : `${this.apiUrl}/object/${objectId}/meter`;
  }
}

@Injectable()
export class MeterValueService extends APIService<MeterValue> {
  queryByObject(
    objectId: number,
    meterId: number,
    page: number,
    limit: number,
  ): Observable<CollectionResponse<MeterValue>> {
    return this.http.get<CollectionResponse<MeterValue>>(
      this.url(objectId, meterId),
      this.options({
        params: {
          page: String(page),
          limit: String(limit),
        },
      }),
    );
  }

  saveByObject(meter: Meter, value: MeterValue): Observable<EntityResponse<MeterValue>> {
    return this.http.post<EntityResponse<MeterValue>>(this.url(meter.ObjectId, meter.id), value, this.options());
  }

  private url(objectId: number, meterId: number): string {
    return `${this.apiUrl}/object/${objectId}/meter/${meterId}/value`;
  }
}
