import { Pipe, PipeTransform } from '@angular/core';
import { ResponseProjectApartmentCardDto } from '../../../../../../../generated/apex-rest';
import { Filter } from '../../../models/filter';
import { ProjectService } from '../../project/project.service';

@Pipe({
  name: 'apartmentFieldNumbersForProject',
  standalone: true,
})
export class ApartmentFieldNumbersForProjectPipe implements PipeTransform {
  constructor(private projectService: ProjectService) {}

  async transform(filters?: Filter[]): Promise<ResponseProjectApartmentCardDto[]> {
    if (filters?.length !== 1) {
      return [];
    }

    const projectId = filters[0]?.id;

    if (!projectId) {
      return [];
    }

    return this.projectService.getProjectApartmentFieldNumbers(projectId);
  }
}
