import { Injectable } from '@angular/core';
import { orderBy } from 'lodash-es';
import { BehaviorSubject, Observable, firstValueFrom, from } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateSidebarItemPinnedDto,
  DeleteResponseDto,
  ResponseSidebarItemPinnedDto,
  SidebarApi,
  SidebarModel,
  UpdateSidebarItemPinnedDto,
} from '../../../../../../../generated/apex-rest';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { UserService } from '../../../services/user/user.service';
import { restAxiosConfig } from '../../../utils/rest-axios-config';

@Injectable()
export class SidebarService {
  private readonly sidebarApi = new SidebarApi(restAxiosConfig());

  menuState$ = new BehaviorSubject<boolean>(false);
  bookmarkMenuState$ = new BehaviorSubject<boolean>(false);

  sidebarItemsPinned: ResponseSidebarItemPinnedDto[] = [];

  constructor(
    private localStorageService: LocalStorageService,
    private userService: UserService,
  ) {
    const initialSate = localStorage.getItem('apexHamburgerMenuState') === 'true';

    const initialBookmarkState = localStorage.getItem('apexBookmark') === 'true';

    this.menuState$.next(initialSate);

    this.bookmarkMenuState$.next(initialBookmarkState);
  }

  async readCollection(): Promise<void> {
    const profile = await firstValueFrom(this.userService.profile$);

    if (!profile) {
      return;
    }

    const collectionResponse = await this.sidebarApi.sidebarControllerReadCollection();

    this.sidebarItemsPinned = orderBy(collectionResponse.data.Collection, ['sortOrder', 'modelId']);
  }

  async create(modelId: string, model: SidebarModel): Promise<ResponseSidebarItemPinnedDto> {
    const createSidebarItemPinnedDto: CreateSidebarItemPinnedDto = {
      model,
      modelId,
    };

    const response = await this.sidebarApi.sidebarControllerCreateSidebarItemPinned(createSidebarItemPinnedDto);

    return response.data.Entity;
  }

  updateSortOrder(
    sidebarItemPinnedId: string,
    updateSidebarItemPinnedDto: UpdateSidebarItemPinnedDto,
  ): Observable<ResponseSidebarItemPinnedDto> {
    return from(this.sidebarApi.sidebarControllerUpdate(sidebarItemPinnedId, updateSidebarItemPinnedDto)).pipe(
      map((response) => response.data.Entity),
    );
  }

  // UUID
  async delete(sidebarItemPinnedId: string): Promise<DeleteResponseDto> {
    const response = await this.sidebarApi.sidebarControllerDelete(sidebarItemPinnedId);

    return response.data;
  }

  setSidebarExpansionState(state: boolean): void {
    this.localStorageService.setItem('apexSidebarExpansion', state);
  }

  getSidebarExpansionState(): boolean {
    const storedSidebarExpansionState = this.localStorageService.getItem('apexSidebarExpansion');

    return storedSidebarExpansionState === null ? true : (storedSidebarExpansionState as boolean);
  }

  setMenuState(state: boolean): void {
    this.localStorageService.setItem('apexHamburgerMenuState', state);

    this.menuState$.next(state);
  }

  toggleMenuState(): void {
    const state = localStorage.getItem('apexHamburgerMenuState') === 'true';

    this.menuState$.next(!state);
  }

  // localStorage for bookmark expansion panel
  getBookmarkState(): boolean {
    const storedBookmarkState = this.localStorageService.getItem('apexBookmark');

    return storedBookmarkState === null ? true : (storedBookmarkState as boolean);
  }

  setBookmarkState(state: boolean): void {
    this.localStorageService.setItem('apexBookmark', state);
  }
}
