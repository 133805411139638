import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { ChecklistItemComponent } from 'projects/apex/src/app/features/checklist/item/item.component';
import { Checklist, ChecklistItem } from '../checklist.model';

@Component({
  selector: 'apex-checklist-item-list',
  templateUrl: './list.component.html',
})
export class ChecklistItemListComponent {
  @Output() deleteComplete = new EventEmitter();

  @Input() checklist: Checklist;
  @Input() checklistItems: ChecklistItem[] = [];

  @Input() sectionName: string;
  @Input() sectionNameOffset = 0;
  @Input() checklistId: number;

  @ViewChildren(ChecklistItemComponent) checklistItemComponents: QueryList<ChecklistItemComponent>;

  filterChecklistItem(item: ChecklistItem, fromTemplate: boolean): boolean {
    return fromTemplate ? !!item.ChecklistTemplateItemId : !item.ChecklistTemplateItemId;
  }

  filteredChecklistItems(items: ChecklistItem[], fromTemplate: boolean): ChecklistItem[] {
    return items?.filter((item) => this.filterChecklistItem(item, fromTemplate)) ?? [];
  }

  hasBoth(): boolean {
    let fromTemplate = false;
    let notFromTemplate = false;

    for (const item of this.checklistItems) {
      if (fromTemplate && notFromTemplate) {
        return true;
      }

      item.ChecklistTemplateItemId ? (fromTemplate = true) : (notFromTemplate = true);
    }

    return fromTemplate && notFromTemplate;
  }

  get itemsFromTemplateCount(): number {
    return this.checklistItems?.filter((item: ChecklistItem) => this.filterChecklistItem(item, true))?.length ?? 0;
  }
}
