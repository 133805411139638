export enum AutocompleteTypes {
  User = 'user',
  Customer = 'customer',

  CRMUser = 'crm-user',

  AddonCategory = 'addon-category',
  AddonCustomer = 'addon-customer',

  Checklist = 'checklist',
  ChecklistTemplate = 'checklist-template',
  ChecklistGroup = 'checklist-group',
  ChecklistGroupTemplate = 'checklist-group-template',

  Object = 'object',
  ObjectField = 'object-field',
  ObjectOwnership = 'object-ownership',
  Tenancy = 'tenancy',
  Agreement = 'agreement',
  Meter = 'meter',

  Project = 'project',
  Apartment = 'apartment',
  Client = 'client',

  ProjectExtended = 'project-extended',

  Field = 'field',
  CaseCategory = 'casecategory',
  Tag = 'tag',
}

export type AutocompleteString = keyof typeof AutocompleteTypes;
