import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { FileUsageViewerModule } from '../../components/file-usage-viewer/file-usage-viewer.module';
import { FileUsageViewerDirective } from './file-usage-viewer.directive';

@NgModule({
  declarations: [FileUsageViewerDirective],
  exports: [FileUsageViewerDirective],
  imports: [MatDialogModule, FileUsageViewerModule],
  providers: [],
})
export class FileUsageViewerDirectiveModule {}
