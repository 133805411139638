/// <reference types="hammerjs" />

import { BreakpointObserver } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Observable, Subject, Subscription, firstValueFrom, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { FileUsageComponent } from '../../../components/file-usage/file-usage.component';
import { t } from '../../../components/translate/translate.function';
import { Case, Contractor } from '../../../models/case';
import { File as ApexFile } from '../../../models/file';
import { FileUsage } from '../../../models/file-usage';
import { User } from '../../../models/user';
import { snack, snackErr } from '../../../modules/snack.module';
import { UserService } from '../../../services/user/user.service';
import { constants } from '../../../utils/constants';
import { HammerTimeInputType } from '../../../utils/types';
import { CaseDialogFormData } from '../../case/form/dialog-types';
import { CaseFormDialogComponent } from '../../case/form/dialog.component';
import { CaseViewDialogComponent } from '../../case/view/dialog.component';
import { Meter, MeterState } from '../../object/project/meter/meter.model';
import { Checklist, ChecklistItem } from '../checklist.model';
import { ChecklistItemService, ChecklistService } from '../checklist.service';
import { DeleteChecklistItemDialogComponent } from './delete-item-dialog.component';
import { DeviationDialogComponent } from './deviation/component';
import { DeviationDialogInput, DeviationDialogOutput } from './deviation/types';

enum CaseOperation {
  FinishCase = 'finishCase',
  CompleteCase = 'completeCase',
  ReopenCase = 'reopenCase',
}

@Component({
  selector: 'apex-checklist-item',
  templateUrl: './item.component.html',
})
export class ChecklistItemComponent implements OnInit, OnDestroy {
  @Input() checklist: Checklist;
  @Input() checklistItem: ChecklistItem;
  @Input() checklistItems: ChecklistItem[] = [];

  @Input() sectionName: string;

  @ViewChild('fileUsage') fileUsage: FileUsageComponent;
  @ViewChild('ciForm') ciForm: ElementRef;

  @HostBinding('class.not-applicable')
  get classNotApplicable(): boolean {
    return !!this.checklistItem.notApplicable;
  }

  Cases: Case[] = [];

  overlayOpen = false;

  caseDeadline: Date;
  user: User;
  caseContractors: Contractor[] = [];
  fileUsages: FileUsage[] = [];
  files: ApexFile[] = [];

  swiping = false;
  distance = 0;
  addTransitionClass = false;
  deleteItem = false;

  isWeb = false;

  showMessageBox = false;
  savingMessage = false;

  showSaved = false;
  showNoteField = false;

  saveNote$ = new Subject<void>();
  saveNote$$ = this.saveNote$.pipe(
    debounceTime(constants.inputDebounceTime),
    switchMap((_) => this.saveNote()),
    tap((_) => {
      this.showSaved = true;
    }),
    debounceTime(3000),
    tap((_) => {
      this.showSaved = false;
    }),
    catchError((err) => {
      snackErr(t('Could not save'), err);

      return of(err);
    }),
  );

  async toggleNotApplicable(): Promise<void> {
    this.checklistItem.notApplicable = !!!this.checklistItem.notApplicable;

    try {
      await firstValueFrom(this.service.saveItem(this.checklist.id, this.checklistItem));
    } catch (err) {
      snackErr(t('Could not save'), err);
    }
  }

  get tooltip(): string {
    return this.showNoteField ? t('Close') : t('Note');
  }

  get meterIconClass(): string {
    switch (this._meterState) {
      case MeterState.Error:
        return 'fg-error';
      case MeterState.Warning:
        return 'fg-warning';
      default:
        return '';
    }
  }

  get meterTooltip(): string {
    switch (this._meterState) {
      case MeterState.Error:
        return t('Meter has error');
      case MeterState.Warning:
        return t('Meter has warning');
      default:
        return '';
    }
  }

  get meterState(): MeterState {
    return this._meterState;
  }

  set meterState(state: MeterState) {
    this._meterState = state;
  }

  private _meterState: MeterState;

  private subscription = new Subscription();

  constructor(
    private service: ChecklistItemService,
    private cService: ChecklistService,
    private userService: UserService,
    private dialog: MatDialog,
    private bpObserver: BreakpointObserver,
    private platform: Platform,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const sub = this.userService.profile$.subscribe({
      next: (user) => {
        this.user = user;
      },
    });

    const sub1 = this.route.queryParams.subscribe({
      next: (params) => {
        if (Number(params.open) === this.checklistItem.id) {
          this.openCase();
        }
      },
    });

    // @TODO this is an ok+ way to check if you are on web
    this.isWeb = !this.platform.ANDROID && !this.platform.IOS;

    this.subscription.add(sub);
    this.subscription.add(sub1);

    this.Cases = [...(this.checklistItem.Cases ?? [])];
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  inView(): void {
    // const sub = this.getConnectedCase();
    // this.subscription.add(sub);
    this.fixMetaFromCase();
  }

  updateCase(): void {
    const sub = this.getConnectedCase();

    this.subscription.add(sub);
  }

  fixMetaFromCase(): void {
    const c = this.checklistItem.Case;

    if (c) {
      this.caseDeadline = moment.unix(c.deadline).toDate();
      this.caseContractors = c.Contractors || [];

      this.cService.$countCompleted.next(true);

      if (c.Meter) {
        this.checklistItem.Case.Meter = new Meter(c.Meter);

        this.meterState = this.checklistItem.Case.Meter.state;
      }
    }
  }

  getConnectedCase(): Subscription {
    return this.service
      .getConnectedCase(this.checklistItem.ChecklistId, this.checklistItem.id, this.checklistItem.CaseId)
      .pipe(map((response) => response.Entity))
      .subscribe({
        next: (c) => {
          this.checklistItem.Case = c;

          this.fixMetaFromCase();
        },
      });
  }

  completeReopenCase(tc: Case): void {
    const [$completeReopen, operation] = this.completeReopen(tc);
    // const successMessage = operation === CaseOperation.ReopenCase ? t('Reopened') : t('Completed');
    const failMessage = operation === CaseOperation.ReopenCase ? t('Could not reopen') : t('Could not complete');

    const sub = $completeReopen.subscribe({
      next: (c) => {
        tc = c; // Iz binding missing??

        if (c.id === this.checklistItem.Case.id) {
          this.checklistItem.Case = c;
        } else {
          for (let i = 0, j = this.checklistItem?.Cases?.length; i < j; i++) {
            if (this.checklistItem?.Cases[i]?.id === c.id) {
              this.checklistItem.Cases[i] = c;
            }
          }
        }

        this.cService.$countCompleted.next(true);
        // Removed snack as it provides way to many little snacks.
        // snack(successMessage);
      },
      error: (err) => {
        if (tc) {
          tc.completed = tc.completed || 0;
        }

        snackErr(failMessage, err);
      },
    });

    this.subscription.add(sub);
  }

  completeReopen(tc: Case, completeBeforeArchive?: boolean): [Observable<Case>, CaseOperation] {
    let returnValue: [Observable<Case>, CaseOperation];
    const isUserCaseManager = tc?.CaseManagerId === this.user?.id;

    if (!tc?.completed || completeBeforeArchive) {
      if (isUserCaseManager) {
        returnValue = this.completeFinishReopenCase(tc, CaseOperation.FinishCase);
      } else {
        returnValue = this.completeFinishReopenCase(tc, CaseOperation.CompleteCase);
      }
    } else {
      returnValue = this.completeFinishReopenCase(tc, CaseOperation.ReopenCase);
    }

    return returnValue;
  }

  completeFinishReopenCase(tc: Case, operation: CaseOperation): [Observable<Case>, CaseOperation] {
    if (operation === CaseOperation.FinishCase) {
      return [this.service[operation](tc?.id), operation];
    }

    return [this.service[operation](this.checklist.id, this.checklistItem.id), operation];
  }

  archiveCase(): Observable<Case> {
    if (!this.checklistItem.Case) {
      snackErr(t('Could not archive case'), new Error(t('No case found')));

      return of(null);
    }

    return this.checklistItem.Case.completed
      ? this.service.archiveCase(this.checklistItem.Case.id)
      : this.completeReopen(this.checklistItem.Case, true)[0].pipe(
          tap((c: Case) => {
            this.checklistItem.Case = c;
          }),
          switchMap((c) => this.service.archiveCase(c.id)),
        );
  }

  archiveCaseSelf(): Subscription {
    return this.archiveCase()
      .pipe(take(1))
      .subscribe({
        error: (err) => {
          snackErr(null, err);
        },
      });
  }

  remove(event: Event): void {
    event?.stopPropagation();

    const sub = this.dialog
      .open<DeleteChecklistItemDialogComponent>(DeleteChecklistItemDialogComponent)
      .afterClosed()
      .subscribe({
        next: (res: { del: boolean; archiveCase: boolean }) => {
          if (res?.del) {
            this.service.deleteItem(this.checklist.id, this.checklistItem, res.archiveCase).subscribe({
              next: (item) => {
                const index = this.checklistItems.map((cti) => cti.id).indexOf(item.id);

                if (index !== -1) {
                  this.checklistItems.splice(index, 1);

                  this.cService.$countCompleted.next(true);
                  snack(t('Deleted'));
                }
              },
              error: (err) => {
                snackErr(t('Could not delete'), err);
              },
            });
          } else {
            this.distance = 0;
            this.deleteItem = false;
          }
        },
      });

    this.subscription.add(sub);
  }

  addContractor(userId: number, notifyContractor: boolean): void {
    if (!this.caseContractors?.find((cc) => cc.id === userId)) {
      const sub = this.service
        .addContractor(this.checklistItem.Case?.id, userId, notifyContractor)
        .pipe(debounceTime(constants.requestDebounceTime))
        .subscribe({
          next: (c) => {
            this.checklistItem.Case = c;
          },
          error: (err) => {
            snackErr(t('Could not add collaborator as contractor on case(s)'), err);
          },
        });

      this.subscription.add(sub);
    }
  }

  panStart(): void {
    this.swiping = true;
    this.addTransitionClass = false;
  }

  pan(event: Event): void {
    const panEvent = HammerTimeInputType(event);

    if (panEvent.deltaX <= 0) {
      this.distance = 0;

      return;
    }

    this.distance = panEvent.deltaX;

    if (this.distance > this.ciForm?.nativeElement?.offsetWidth * 0.4) {
      this.deleteItem = true;
    } else {
      this.deleteItem = false;
    }
  }

  panEnd(): void {
    this.addTransitionClass = true;
    this.swiping = false;

    if (this.deleteItem) {
      this.distance = this.ciForm?.nativeElement?.offsetWidth;
      this.remove(null);
    } else {
      this.distance = 0;
    }
  }

  openCase(): void {
    if (!this.swiping) {
      const sub = this.dialog
        .open(CaseViewDialogComponent, {
          data: {
            case: this.checklistItem.Case,
          },
        })
        .afterClosed()
        .subscribe({
          next: (res) => {
            this.updateCase();

            if (res?.navigate) {
              void this.router.navigate(res.navigate.commands, res.navigate.extras);
            }
          },
        });

      this.subscription.add(sub);
    }
  }

  addCase($event: Event): void {
    $event?.stopPropagation();

    this.dialog
      .open<CaseFormDialogComponent, CaseDialogFormData, Case>(CaseFormDialogComponent, {
        data: {
          case: {
            ProjectId: this.checklist.ProjectId,
            ApartmentId: this.checklist.ApartmentId,
            ObjectId: this.checklist.ObjectId,

            CaseCategoryId: this.checklist.CategoryId,

            CaseManagerId: this.checklist.CaseManagerId,

            FieldId: this.checklist.FieldId,
          },
        },
      })
      .afterClosed()
      .pipe(
        filter((c) => !!c?.id),
        mergeMap((c) => forkJoin([of(c), this.service.addCase(this.checklistItem, c.id)])),
      )
      .subscribe({
        next: ([c, _]) => {
          if (!this.checklistItem.Cases) {
            this.checklistItem.Cases = [];
          }

          this.checklistItem.Cases?.push(c);
        },
      });
  }

  openCaseAdded(c: Case): void {
    this.subscription.add(
      this.dialog
        .open<CaseViewDialogComponent>(CaseViewDialogComponent, {
          data: {
            caseId: c.id,
          },
        })
        .afterClosed()
        .subscribe({
          next: (res) => {
            if (res?.case) {
              const rc = res.case as Case;

              c.name = rc.name;
              c.completed = rc.completed;
              c.description = rc.description;
            }
          },
        }),
    );
  }

  completeReopenDeviation(e: MouseEvent, deviation: Case): void {
    e.stopPropagation();

    let obs$: Observable<Case>;

    if (!!deviation.completed) {
      obs$ = this.service.reopenDeviation(this.checklistItem, deviation.id).pipe(
        take(1),
        map((response) => response.Entity),
      );
    }

    if (!deviation.completed) {
      obs$ = this.service.completeDeviation(this.checklistItem, deviation.id).pipe(
        take(1),
        map((response) => response.Entity),
      );
    }

    const sub = obs$.subscribe((changedCase) => {
      const staleCaseIndex = this.Cases.findIndex((c) => c.id === changedCase.id);

      if (staleCaseIndex !== -1) {
        this.Cases[staleCaseIndex] = changedCase;

        this.checklistItem.Cases = this.Cases;
      }
    });

    this.subscription.add(sub);
  }

  openDeviationDialog(deviation?: Case, itemCase = false): void {
    if (!deviation && itemCase) {
      return;
    }

    if (!this.checklistItem.Cases) {
      this.checklistItem.Cases = [];
    }

    const deviationCase =
      !!deviation && !itemCase
        ? deviation
        : new Case({
            ObjectId: this.checklistItem.Case?.ObjectId ?? this.checklist.ObjectId,
            ProjectId: this.checklistItem.Case?.ProjectId ?? this.checklist.ProjectId,
            ApartmentId: this.checklistItem.Case?.ApartmentId ?? this.checklist.ApartmentId,
          });

    this.dialog
      .open<DeviationDialogComponent, DeviationDialogInput, DeviationDialogOutput>(DeviationDialogComponent, {
        panelClass: ['apex-fullscreen-dialog', 'phone'],
        data: {
          deviationCase,
          item: this.checklistItem,
          cases: this.Cases,
          CaseManagerId: this.checklist.CaseManagerId,
          CaseCategoryId: this.checklist.CategoryId,
          itemCase: itemCase ? this.checklistItem.Case : undefined,
          addToDeviations: !itemCase,
        },
      })
      .afterClosed()
      .subscribe(() => {
        // Force reload of the connected case incase it has been updated
        this.getConnectedCase();
      });
  }

  private saveNote(): Observable<ChecklistItem> {
    return this.service.saveItem(this.checklistItem.ChecklistId, this.checklistItem);
  }

  get percentCompleted(): number {
    return Math.floor(
      ((this.checklistItem.Cases?.filter((cm) => cm.completed).length ?? 1) / (this.checklistItem.Cases?.length ?? 1)) *
        100,
    );
  }

  async removeDeviation(deviation?: Case, itemCase = false): Promise<void> {
    if (!deviation && itemCase) {
      return;
    }

    try {
      await firstValueFrom(this.service.removeDeviation(this.checklistItem, deviation.id));

      const idx = this.checklistItem.Cases?.findIndex((c) => c.id === deviation.id) ?? -1;

      if (idx !== -1) {
        this.checklistItem.Cases.splice(idx, 1);
      }

      const casesIdx = this.Cases.findIndex((c) => c.id === deviation.id);

      if (casesIdx !== -1) {
        this.Cases.splice(idx, 1);
      }

      snack(t('Deviation removed from item'));
    } catch (err) {
      snackErr(t('Could not remove'), err);
    }
  }
}
