import { NgZone, Pipe, PipeTransform } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Editor, RawEditorOptions } from 'tinymce';
import { environment } from '../../../environments/environment';
import { FolderDialogComponent } from '../../components/folder/dialog.component';
import { t } from '../../components/translate/translate.function';

const TransifexToTinyMCELanguage = {
  en: 'en_GB',
  da: 'da',
  de: 'de',
  el: 'el',
  et: 'et',
  lt: 'lt',
  lv: 'lv',
  nb: 'nb_NO',
  pt: 'pt_PT',
  sv: 'sv_SE',
};

const defaultEditorOptions: RawEditorOptions = {
  outputFormat: 'html',
  menubar: false,
  statusbar: false,
  font_formats: 'Titilium Web: titillium web, Open Sans: open sans',
  promotion: false,
  branding: false,
  setup: null,
  selector: 'textarea',
  paste_data_images: false,
  paste_as_text: true,
  block_unsupported_drop: true,
  content_css: `${environment.appUrl}/styles.css`,
  content_style: `
    @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700');
    @import url('https://fonts.googleapis.com/css?family=Titillium+Web:200,300,400,400i,700');
    body { font-family: 'Open Sans', sans-serif; }
    h1,h2,h3,h4,h5,h6 { font-family: 'Titilium Web', sans-serif; }
    html { background-color: #f9f9f9 !important; }
    body { background-color: #f9f9f9 !important; }
  `,
  plugins: ['table', 'image', 'code', 'media', 'wordcount'],
  toolbar: [
    'bold italic underline strikethrough | alignleft aligncenter alignright | h1 h2 h3 h4 h5 | table | fileSelectorButton',
  ],
  base_url: `${environment.appUrl}/tinymce/`,
};

interface InputOptions extends RawEditorOptions {
  fileSelector?: boolean;
}

@Pipe({
  name: 'editorInit',
})
export class EditorInitPipe implements PipeTransform {
  constructor(
    private ngZone: NgZone,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {}

  transform(inputOptions: InputOptions): RawEditorOptions {
    const language = TransifexToTinyMCELanguage[this.route?.snapshot?.data?.profile?.locale ?? 'en'] ?? 'en';

    const options: RawEditorOptions = {
      ...defaultEditorOptions,
      ...inputOptions,

      language_url: `${environment.appUrl}/tinymce/languages/${language}.js`,
      language: `${language}`,
    };

    if (inputOptions.fileSelector) {
      options.setup = (editor): void => this.startDialog(editor);
    }

    return options;
  }

  startDialog(editor: Editor): void {
    editor.ui.registry.addButton('fileSelectorButton', {
      tooltip: t('Insert file'),
      icon: 'image',

      onAction: () => {
        this.ngZone.run(() => {
          this.dialog
            .open(FolderDialogComponent, { data: { splitPdf: 'ask' } })
            .afterClosed()
            .subscribe({
              next: (fu) => {
                if (!fu?.files?.length) {
                  return;
                }

                fu.files.forEach((file) => {
                  if (file.type.startsWith('image/')) {
                    editor.insertContent(`<img data-file-id='${file.id}' src='${file.signed.url}' />`);
                  }

                  if (file.type.startsWith('video/')) {
                    editor.insertContent(
                      `<video controls='controls'>
                        <source src='${file.signed.url}' data-file-id='${file.id}'>
                      </video>`,
                    );
                  }
                });
              },
            });
        });
      },
    });
  }
}
