import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AvatarModule } from '../../../components/avatar/avatar.module';
import { BoxModule } from '../../../components/box/box.module';
import { FilePreviewLoaderModule } from '../../../components/file-preview/file-preview-loader.module';
import { ItemModule } from '../../../components/item/item.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { TranslatorModule } from '../../../components/translator/translator.module';
import { FileUsageViewerDirectiveModule } from '../../../directives/file-usage-viewer/file-usage-viewer.module';
import { UserCardModule } from '../../../directives/user-card/user-card.module';
import { FormatCaseLogMessagePipeModule } from '../../../pipes/format-case-log-message/format-case-log-message.module';
import { MarkedPipeModule } from '../../../pipes/marked/marked.pipe.module';
import { CaseService } from '../case.service';

import { CaseLogsComponent } from './case-logs.component';
import { CaseLogUserCompanyPipe } from './pipes/case-log-company.pipe';
import { CaseLogEmailPipe } from './pipes/case-log-email.pipe';
import { CaseLogUserPipe } from './pipes/case-log-user.pipe';
import { CaseLogSystemMessageComponent } from './system-message.component';
import { CaseLogUserMessageComponent } from './user-message.component';

@NgModule({
  declarations: [CaseLogsComponent, CaseLogUserMessageComponent, CaseLogSystemMessageComponent],
  exports: [CaseLogsComponent, CaseLogUserMessageComponent, CaseLogSystemMessageComponent],
  providers: [CaseService],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatDividerModule,
    MatRippleModule,
    MatProgressBarModule,
    AvatarModule,
    TranslateModule,
    UserCardModule,
    BoxModule,
    ItemModule,
    TranslatorModule,
    FormatCaseLogMessagePipeModule,
    MarkedPipeModule,
    FilePreviewLoaderModule,
    FileUsageViewerDirectiveModule,
    CaseLogUserPipe,
    CaseLogUserCompanyPipe,
    CaseLogEmailPipe,
  ],
})
export class CaseLogsModule {}
