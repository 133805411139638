import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '../../../components/translate/translate.module';
import { ProjectInvolvedService } from '../project-involved.service';
import { InvolvedCardsComponent } from './cards/project-involved-cards.component';
import { ProjectInvolvedFiltersComponent } from './filter/project-involved-filters.component';

@Component({
  selector: 'apex-project-involved-users',
  templateUrl: './project-involved.component.html',
  standalone: true,
  imports: [InvolvedCardsComponent, ProjectInvolvedFiltersComponent, TranslateModule],
})
export class ProjectInvolvedComponent implements OnInit {
  constructor(private involvedService: ProjectInvolvedService) {}

  @Input() id: number;
  @Input() type: string;

  ngOnInit(): void {
    if (!this.id) {
      return;
    }

    this.involvedService.setInvolvedType(this.type);

    void this.involvedService.readInvolvedUsers(this.id);
  }
}
