import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { t } from '../../translate/translate.function';

const grannyDay = {
  name: 'day',
  translatedName: t('Day'),
};
const grannyWeek = {
  name: 'week',
  translatedName: t('Week'),
};
const grannyMonth = {
  name: 'month',
  translatedName: t('Month'),
};
const grannyQuarter = {
  name: 'quarter',
  translatedName: t('Quarter'),
};

@Component({
  selector: 'apex-chart-controls',
  templateUrl: './chart-controls.component.html',
  styleUrls: [],
})
export class ChartControlsComponent implements OnInit, OnDestroy {
  chartTypes = [
    {
      name: 'line',
      icon: 'stacked_line_chart',
      translatedName: t('Line'),
    },
    {
      name: 'column',
      icon: 'bar_chart',
      translatedName: t('Column'),
    },
  ];

  granularities = [grannyDay, grannyWeek, grannyMonth];

  selectedChartType = 'line';
  selectedGranularity = 'week';

  projectView = false;

  subs: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.route.queryParams.subscribe({
        next: (qp) => {
          const g = qp?.granularity;

          this.selectedGranularity = g ? g : this.selectedGranularity;

          const ct = qp?.chartType;

          this.granularities =
            ct === 'column'
              ? [grannyWeek, grannyMonth, grannyQuarter]
              : [grannyDay, grannyWeek, grannyMonth, grannyQuarter];
          this.selectedChartType = ct ? ct : this.selectedChartType;
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  toggleProjectView(): void {
    this.projectView = !this.projectView;
    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        projectView: this.projectView,
      },
      queryParamsHandling: 'merge',
    });
  }

  chartChange(ct: string): void {
    this.granularities = ct === 'column' ? [grannyWeek, grannyMonth] : [grannyDay, grannyWeek, grannyMonth];

    const granularity = ct === 'column' ? 'month' : 'week';

    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        chartType: ct,
        granularity,
      },
      queryParamsHandling: 'merge',
    });
    this.selectedChartType = ct;
    this.selectedGranularity = granularity;
  }

  granularityChange(gt: string): void {
    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        granularity: gt,
      },
      queryParamsHandling: 'merge',
    });
    this.selectedGranularity = gt;
  }
}
