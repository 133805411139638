import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseCaseCategorySummaryDto } from '../../../../../generated/apex-rest';
import { t } from '../components/translate/translate.function';
import { Autocomplete } from './autocomplete';
import { CaseCategory } from './case-category';
import { Filter } from './filter';

export const projectClientCategories: number[] = [3, 4, 5, 6, 7, 8, 14];

const caseCategoryCamelCaseToCategoryId: ResponseCaseCategorySummaryDto = {
  addon: 14,
  claim: 5,
  completionInspection: 7,
  deviation: 19,
  hse: 12,
  inspectionCommonArea: 1,
  maintenance: 18,
  oneYearInspection: 4,
  operations: 17,
  other: 11,
  preInspection: 1,
  projecting: 10,
  qa: 13,
  request: 6,
  safetyInspection: 20,
  startUp: 22,
  task: 9,
  takeoverInspection: 3,
  trialOperations: 21,
  // mom: 23,
} as const;

export const findCaseCategoryByCamelCase = (camelCase: string): CaseCategory | null => {
  const id = caseCategoryCamelCaseToCategoryId[camelCase];

  return caseCategories.find((cc) => cc.id === id) ?? null;
};

export const caseCategories: CaseCategory[] = [
  {
    id: 14,
    name: t('Addon'),
    status: 1,
  },
  {
    id: 5,
    name: t('Claim'),
    status: 1,
  },
  {
    id: 7,
    name: t('Completion inspection'),
    status: 1,
  },
  {
    id: 19,
    name: t('Deviation'),
    status: 1,
  },
  {
    id: 12,
    name: t('HSE'),
    status: 1,
  },
  {
    id: 8,
    name: t('Inspection commonarea'),
    status: 1,
  },
  {
    id: 18,
    name: t('Maintenance'),
    status: 1,
  },
  {
    id: 4,
    name: t('One year inspection'),
    status: 1,
  },
  {
    id: 17,
    name: t('Operations'),
    status: 1,
  },
  {
    id: 11,
    name: t('Other'),
    status: 1,
  },
  {
    id: 1,
    name: t('Pre inspection'),
    status: 1,
  },
  {
    id: 10,
    name: t('Projecting'),
    status: 1,
  },
  {
    id: 13,
    name: t('QA'),
    status: 1,
  },
  {
    id: 6,
    name: t('Request'),
    status: 1,
  },
  {
    id: 3,
    name: t('Takeover inspection'),
    status: 1,
  },
  {
    id: 9,
    name: t('Task'),
    status: 1,
  },
  {
    id: 20,
    name: t('Safety inspection'),
    status: 1,
  },
  {
    id: 21,
    name: t('Trial operations'),
    status: 1,
  },
  {
    id: 22,
    name: t('Start-up'),
    status: 1,
  },
  {
    id: 23,
    name: t('MOM'),
    status: 1,
  },
];

const getTransformedCategories = (): CaseCategory[] =>
  caseCategories.map((cc) => ({
    id: cc.id,
    name: t(cc.name),
    status: 1,
  }));

export const queryCaseCategories = (q?: string): CaseCategory[] => {
  if (!q || q === '*') {
    return getTransformedCategories();
  }

  return getTransformedCategories().filter(
    (cc) => cc.name.toLowerCase().includes(String(q).toLowerCase()) || String(cc.id).includes(String(q).toLowerCase()),
  );
};

export const queryCaseCategories$ = (q?: string): Observable<CaseCategory[]> => of(queryCaseCategories(q));

export const getCaseCategory = (id: number | string): CaseCategory => {
  const found = getTransformedCategories().find((cc) => String(cc.id) === String(id));

  if (found) {
    return found;
  }

  throw new Error(`CaseCategory with ${id} can not be found`);
};

export const getCaseCategory$ = (id: number | string): Observable<CaseCategory> => of(getCaseCategory(id));

export const queryCaseCategoriesAsAutocomplete$ = (q?: string): Observable<Autocomplete[]> =>
  queryCaseCategories$(q).pipe(
    map((ccs) =>
      ccs.map((cc) => ({
        id: cc.id,
        name: cc.name,
        Parent: null,
      })),
    ),
  );

export const getCaseCategoryAsAutocomplete$ = (id: number | string): Observable<Autocomplete> =>
  getCaseCategory$(id).pipe(
    map((result) => ({
      id: result.id,
      name: result.name,
      Parent: null,
    })),
  );

export const queryCaseCategoriesAsFilter$ = (q?: string): Observable<Filter[]> =>
  queryCaseCategories$(q).pipe(
    map((results) =>
      results.map((cc) => ({
        id: cc.id,
        name: cc.name,
        Parent: null,
      })),
    ),
  );

export const getCaseCategoryAsFilter$ = (id: number | string): Observable<Filter> =>
  getCaseCategory$(id).pipe(
    map((f) => ({
      id: f.id,
      name: f.name,
      Parent: null,
    })),
  );
