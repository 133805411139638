import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash-es';
import { ResponseSidebarItemPinnedDto } from '../../../../../../../generated/apex-rest';
import { SidebarItem } from '../sidebar-items.types';

// purpose of this pipe is to sort sidebarItems by sortOrder and modelId
// and return new array of sidebarItems with sortOrder because static sidebarItems doesn´t have sortOrder
@Pipe({
  name: 'sortSidebarItems',
  standalone: true,
})
export class SortSidebarItemsPipe implements PipeTransform {
  transform(
    // sidebarItems is filtered list that includes bookmarks and sidebarItems from FilterSidebarItemsPipe
    sidebarItems: SidebarItem[],
    responseSidebarItemPinnedDtos: ResponseSidebarItemPinnedDto[],
  ): SidebarItem[] {
    // adding sortOrder to sidebarItem objects which isn´t here yet and returning new array
    const sidebarItemsWithSortOrder: SidebarItem[] = sidebarItems.map((sidebarItem: SidebarItem): SidebarItem => {
      const responseSidebarItemPinnedDto: ResponseSidebarItemPinnedDto = responseSidebarItemPinnedDtos.find(
        (item: ResponseSidebarItemPinnedDto) => {
          // If modelId is not a number, it is a routerLink which means it is a static sidebarItem
          if (Number.isNaN(Number(item.modelId))) {
            return item.modelId === sidebarItem.routerLink && !sidebarItem.modelId;
          }

          // If modelId is a number, it is a bookmark
          return item.modelId === sidebarItem.modelId;
        },
      );

      // adding sortOrder to sidebarItems from responseSidebarItemPinnedDtos (backend)
      return {
        ...sidebarItem,
        sortOrder: responseSidebarItemPinnedDto?.sortOrder,
      };
    });

    return orderBy(sidebarItemsWithSortOrder, ['sortOrder', 'modelId']);
  }
}
