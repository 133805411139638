<mat-menu
  #folderMenu="matMenu"
  [overlapTrigger]="false">
  <ng-container matMenuContent>
    <div class="aphs">
      <form
        #bookmarkForm="ngForm"
        (click)="$event?.stopPropagation()"
        (keydown)="$event?.stopPropagation()">
        <div class="df r ac">
          <mat-form-field>
            <mat-label>
              <T str="Add"></T>
            </mat-label>
            <input
              matInput
              #addInput
              name="addInput"
              required />
          </mat-form-field>
          <div class="df r">
            <button
              mat-icon-button
              type="button"
              [matTooltip]="'Add bookmark' | translate"
              (click)="add(addInput.value, 'b'); bookmarkForm.resetForm()">
              <mat-icon>bookmark</mat-icon>
            </button>
            <button
              mat-icon-button
              type="button"
              [matTooltip]="'Add folder' | translate"
              (click)="add(addInput.value, 'f'); bookmarkForm.resetForm()">
              <mat-icon>create_new_folder</mat-icon>
            </button>
          </div>
        </div>
      </form>
    </div>

    <mat-divider></mat-divider>

    <a
      mat-menu-item
      [routerLink]="['/', 'bookmark']"
      *ngIf="!id">
      <mat-icon>bookmarks</mat-icon>
      <T str="Manage bookmarks"></T>
    </a>

    <mat-divider></mat-divider>
    <div
      class="ap"
      *ngIf="!folders.length && !bookmarks.length">
      <T str="No bookmarks found"></T>
    </div>

    <ng-container *ngIf="folders.length">
      <ng-container *ngFor="let folder of folders">
        <button
          mat-menu-item
          [matMenuTriggerFor]="menu.folderMenu">
          <mat-icon>folder</mat-icon>
          {{ folder.name }}
        </button>
        <apex-bookmark-folder
          #menu
          [id]="folder.id">
        </apex-bookmark-folder>
      </ng-container>
    </ng-container>

    <ng-container *ngFor="let bookmark of bookmarks">
      <div class="df">
        <button
          class="df f1"
          type="button"
          mat-menu-item
          [title]="bookmark?.name"
          (click)="click(bookmark)">
          <mat-icon>bookmark</mat-icon>
          {{ bookmark.name }}
        </button>

        <apex-bookmark-pin-buttons
          [pinnedBookmarks]="sidebarService.sidebarItemsPinned"
          [bookmark]="bookmark"></apex-bookmark-pin-buttons>
      </div>
    </ng-container>
  </ng-container>
</mat-menu>
