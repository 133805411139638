import { CommonModule } from '@angular/common';
import { Component, computed, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '../../../../../components/translate/translate.module';
import { UserFavoriteButtonModule } from '../../../../../components/user-favorite-button/user-favorite-button.module';
import { JoinPipe } from '../../../../../pipes/join/join.pipe';
import { TranslateStringArrayPipe } from '../../../../../pipes/translate-string-array/translate-string-array.pipe';
import { ExtendedProjectInvolvedUserType, ProjectInvolvedService } from '../../../project-involved.service';
import { ProjectInvolvedUserType } from '../../project-involved-user.type';
import { TranslateProjectInvolvedUserRolePipe } from '../../translate-project-involved-user-role.pipe';

@Component({
  selector: 'apex-project-involved-card',
  templateUrl: './project-involved-card.component.html',
  styleUrls: ['./project-involved-card.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    UserFavoriteButtonModule,
    TranslateModule,
    JoinPipe,
    TranslateStringArrayPipe,
    TranslateProjectInvolvedUserRolePipe,
  ],
})
export class InvolvedCardComponent implements OnInit {
  @Input({
    required: true,
  })
  extendedUser: ExtendedProjectInvolvedUserType;
  fieldColors: string[] = [];

  ngOnInit(): void {
    this.extendedUser.fieldNames = this.extendedUser.fieldNames?.filter((name) => name);

    this.extendedUser.fieldColors = this.extendedUser.fieldColors?.filter((color) => color);

    this.fieldColors = this.extendedUser.fieldColors = this.extendedUser.fieldColors?.slice(0, 4);
  }

  @Input()
  set user(value: ProjectInvolvedUserType) {
    this.extendedUser = { ...value };
  }
  get user(): ExtendedProjectInvolvedUserType {
    return this.extendedUser;
  }

  constructor(protected readonly projectInvolvedService: ProjectInvolvedService) {}

  isSelected = computed(() =>
    this.projectInvolvedService.selectedUsers().some((user) => user.id === this.extendedUser.id),
  );
}
