import { Addon } from './addon';
import { AddonApartment } from './addon-apartment';
import { Apartment } from './apartment';
import { Case, SimplePath } from './case';
import { FileUsage } from './file-usage';
import { Marking } from './marking';
import { Model } from './model';
import { User } from './user';

export enum AddonOrderStatus {
  New = 'new',
  Denied = 'denied',
  Approved = 'accepted',
  Canceled = 'canceled',
  Signed = 'signed',
}

export class AddonOrder extends Model<AddonOrder> {
  priceIn: number;
  priceOut: number;
  VAT: number;
  status: AddonOrderStatus;
  ClientId: number;
  Client?: User;
  ClientComment: string;
  AddonId: number;
  Addon: Addon;
  AddonApartmentId: number;
  AddonApartment: AddonApartment;
  ApartmentId: number;
  Apartment?: Apartment;
  ManagerId?: number;
  Manager?: User;
  ManagerComment?: string;
  Markings: Marking[];
  quantity: number;
  seller?: string;
  sellerOrgnr?: string;
  dateProcessed?: Date;
  dateSignature?: Date;

  unitPrice: boolean;

  price?: number;

  CaseId?: number;
  Case?: Case;

  invoiced?: number;

  Contractor?: User;

  addonCategoryPath?: SimplePath[];
  floorplansWithMarkings?: FileUsage[];

  /**
   * Gets attached if the case has an AddonOrder and a signed file for it.
   */
  SignedFileUsage?: FileUsage[];

  priceOutVat?(): number {
    return this.priceOut * (this.VAT / 100);
  }

  priceIncVat?(): number {
    return this.priceOut + this.priceOutVat();
  }

  totalIn?(): number {
    return (this.unitPrice ? this.quantity || 1 : 1) * this.priceIn;
  }

  totalVat?(): number {
    return this.totalExVat() * (this.VAT / 100);
  }

  totalExVat?(): number {
    return (this.unitPrice ? this.quantity || 1 : 1) * this.priceOut;
  }

  totalIncVat?(): number {
    return this.totalExVat() + this.totalVat();
  }

  priceExVatDiff?(): number {
    return this.totalExVat() - this.totalIn();
  }
}
