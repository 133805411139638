<form
  *ngIf="text"
  #form="ngForm"
  class="f1 df c"
  (change)="save()">
  <header class="apl apts df p-c">
    <div class="f1 df ac">
      <mat-form-field class="f1 amrs">
        <mat-label>
          <T str="Title"></T>
        </mat-label>
        <input
          matInput
          name="name"
          [(ngModel)]="text.name"
          required />
      </mat-form-field>
      <div
        *ngIf="canEdit"
        class="mat-body-2 amrs">
        {{ canEdit ? 'Edit access' : 'View access' }}
      </div>
    </div>
    <div class="df jsb ac apbs">
      <mat-checkbox
        name="customerAccess"
        [(ngModel)]="text.customerAccess"
        (ngModelChange)="save()"
        [matTooltip]="'Grant read access to everyone in the company' | translate">
        <T str="Company access"></T>
      </mat-checkbox>
      <button
        mat-icon-button
        class="amrs"
        color="warn"
        [disabled]="!text?.id"
        (click)="delete()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </header>

  <editor
    class="df f1"
    [init]="{ height: '100%', placeholder: 'Text' | translate } | editorInit"
    [required]="true"
    name="content"
    [(ngModel)]="text.html"
    (ngModelChange)="save()">
  </editor>
</form>
