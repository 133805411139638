import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { Case } from 'projects/apex/src/app/models/case';
import { CaseLog } from 'projects/apex/src/app/models/case-log';
import { Subscription, concat } from 'rxjs';
import { CaseService } from '../case.service';

@Component({
  selector: 'apex-case-new-message',
  templateUrl: './new-message.component.html',
})
export class CaseNewMessageComponent {
  @Input() caseLog: CaseLog = new CaseLog({
    forContractor: 1,
    sendNotifications: true,
  });
  @Input() cases: Case[];
  @Input() disabled: boolean;

  @Input() required = true;

  @Output() new = new EventEmitter<CaseLog>();
  @Output() messageComplete = new EventEmitter();
  @Output() inputBlur = new EventEmitter<boolean>();

  @ViewChild('form') newMessageForm: NgForm;
  @ViewChild('messageTextArea') messageTextArea: MatInput;

  get forClient(): boolean {
    switch (this.caseLog.forClient) {
      case 1:
        return true;
      case 0:
        return false;
    }
  }

  set forClient(value: boolean) {
    this.caseLog.forClient = value ? 1 : 0;
  }

  get forContractor(): boolean {
    switch (this.caseLog.forContractor) {
      case 1:
        return true;
      case 0:
        return false;
    }
  }

  set forContractor(value: boolean) {
    this.caseLog.forContractor = value ? 1 : 0;
  }

  saving: boolean;
  sub: Subscription;

  showAlternatives = false;

  constructor(private caseService: CaseService) {}

  save(): void {
    if (this.cases?.length && this.newMessageForm.valid) {
      this.saving = true;
      this.sub = concat(...this.cases.map((c) => this.caseService.postCaseLog(c.id, this.caseLog))).subscribe({
        next: (log: CaseLog) => this.new.emit(log),
        complete: () => {
          this.saving = false;
          this.showAlternatives = false;

          this.newMessageForm.reset();
          this.caseLog = new CaseLog({
            forContractor: 1,
            sendNotifications: true,
          });

          this.messageComplete.emit();
        },
      });
    }
  }

  keydown(event: KeyboardEvent): void {
    if (event.key === 'Enter' && event.ctrlKey) {
      this.save();
    }
  }

  isCaseManagerOnAllCases(): boolean {
    return this.cases?.filter((c) => c.access.caseManager)?.length === this.cases?.length;
  }

  anyCaseHasWrongCategory(): boolean {
    return this.cases?.some((c) => c.CaseCategoryId === 7 || c.CaseCategoryId === 1);
  }

  setFocus(): void {
    this.messageTextArea?.focus();
  }

  onBlur(): void {
    this.inputBlur.emit(true);
  }
}
