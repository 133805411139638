<h1 mat-dialog-title>
  <T str="Tenancies"></T>
</h1>
<div mat-dialog-content>
  <p class="rmt">
    <T
      str="You are administrator of one or more tenancies, would you like to access the portal for administration of those?">
    </T>
  </p>

  <p>
    <T str="You can always access the portal from the menu."> </T>
  </p>

  <div class="df c">
    <mat-checkbox
      class="amb"
      [(ngModel)]="alwaysRedirectToPortal">
      <T str="Always go straight to portal"></T>
    </mat-checkbox>

    <mat-checkbox
      [(ngModel)]="hidePortalReminder"
      name="hidePortalReminder">
      <T str="Don't ask this again"></T>
    </mat-checkbox>
  </div>
</div>
<div
  mat-dialog-actions
  class="df jsb">
  <button
    mat-stroked-button
    (click)="onClick(false)">
    <T str="No"></T>
  </button>
  <button
    mat-flat-button
    color="primary"
    cdkFocusInitial
    (click)="onClick(true)">
    <T str="Yes"></T>
  </button>
</div>
