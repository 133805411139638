import { Pipe, PipeTransform } from '@angular/core';
import { t } from '@transifex/native';
import { ObjectInvolvedUser, ProjectInvolvedUser } from '../../../../../../../generated/apex-rest';
import { ProjectInvolvedUserRole } from './project-involved-user-role.type';

const translateRoles: Record<ProjectInvolvedUserRole, () => string> = {
  [ProjectInvolvedUser.ProjectManager]: () => t('Project manager'),
  [ProjectInvolvedUser.Contractor]: () => t('Contractor'),
  [ProjectInvolvedUser.AddonManager]: () => t('Addon manager'),
  [ProjectInvolvedUser.CaseManager]: () => t('Case manager'),
  [ProjectInvolvedUser.UnwantedIncidentManager]: () => t('Unwanted incident manager'),
  [ObjectInvolvedUser.AgreementInvolved]: () => t('Agreement'),
  [ObjectInvolvedUser.Contact]: () => t('Contact'),
  [ObjectInvolvedUser.Custodian]: () => t('Custodian'),
  favorite: () => t('Favorite'),
};

@Pipe({
  name: 'translateProjectInvolvedUserRole',
  standalone: true,
})
export class TranslateProjectInvolvedUserRolePipe implements PipeTransform {
  transform(value: ProjectInvolvedUserRole): string {
    return translateRoles[value]();
  }
}
