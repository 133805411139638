import { Pipe, PipeTransform } from '@angular/core';

type InputContractor = {
  id: number;
  name: string;

  CaseContractor: {
    UserId: number;
    CreatorId: number;
  };
};

@Pipe({
  name: 'stepsFromCreator',
  standalone: true,
})
export class StepsFromCreatorPipe implements PipeTransform {
  transform(contractor: InputContractor, contractors: InputContractor[], doneSteps = 0): number {
    if (doneSteps > 10) {
      return 0;
    }

    const creatorId = contractor?.CaseContractor?.CreatorId;

    if (creatorId) {
      const creator = contractors.find((c) => c.id === creatorId);

      if (creator) {
        return 1 + this.transform(creator, contractors, doneSteps + 1);
      }
    }

    return 0;
  }
}
