import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { HammerTimeInputType } from '../../utils/types';

@Directive({
  selector: '[apexHammerTime]',
})
export class HammerTimeDirective implements OnInit {
  private _parentOverflowHidden = false;
  @Input()
  get parentOverflowHidden(): boolean {
    return this._parentOverflowHidden;
  }
  set parentOverflowHidden(value: BooleanInput) {
    this._parentOverflowHidden = coerceBooleanProperty(value);
  }

  private _disableUserDragOnChildren = true;
  @HostBinding('class.disable-user-drag-on-children')
  @Input()
  get disableUserDragOnChildren(): boolean {
    return this._disableUserDragOnChildren;
  }
  set disableUserDragOnChildren(value: BooleanInput) {
    this._disableUserDragOnChildren = coerceBooleanProperty(value);
  }

  private _disableHammerTime = false;
  @Input()
  get disableHammerTime(): boolean {
    return this._disableHammerTime;
  }
  set disableHammerTime(value: BooleanInput) {
    this._disableHammerTime = coerceBooleanProperty(value);
  }

  private _snapToEdges = false;
  @Input()
  get snapToEdges(): boolean {
    return this._snapToEdges;
  }
  set snapToEdges(value: BooleanInput) {
    this._snapToEdges = coerceBooleanProperty(value);
  }

  private _preventGhostClickOnPan = true;
  @HostBinding('class.pointer-events-disable')
  @Input()
  get preventGhostClickOnPan(): boolean {
    return this._preventGhostClickOnPan && this.panning;
  }
  set preventGhostClickOnPan(value: BooleanInput) {
    this._preventGhostClickOnPan = coerceBooleanProperty(value);
  }

  panning = false;
  prevDeltaX = 0;
  private _panDistance = 0;

  @HostBinding('class.transition') addTransitionClass = false;
  @HostBinding('style.transform')
  get panTranslateX(): string {
    return `translateX(${this._panDistance}px`;
  }

  @HostListener('panStart')
  panStart(): void {
    if (this.disableHammerTime) {
      return;
    }

    this.panning = true;
    this.addTransitionClass = false;
  }

  @HostListener('pan', ['$event'])
  pan(event: Event): void {
    if (this.disableHammerTime) {
      return;
    }

    const panEvent = HammerTimeInputType(event);

    this._panDistance = panEvent.deltaX + this.prevDeltaX;
  }

  @HostListener('panEnd')
  panEnd(): void {
    if (this.disableHammerTime) {
      return;
    }

    this.panning = false;
    this.addTransitionClass = true;

    if (this.snapToEdges && this.parentElement) {
      const elementScrollWidth = this.element.nativeElement.scrollWidth;
      const parentOffsetWidth = this.parentElement.offsetWidth;

      const diff = parentOffsetWidth - elementScrollWidth;

      if (this._panDistance > 0) {
        this._panDistance = 0;
      }

      if (this._panDistance < diff) {
        this._panDistance = diff;
      }
    }

    this.prevDeltaX = this._panDistance;
  }

  parentElement: HTMLElement;

  constructor(private element: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    if (this.disableHammerTime) {
      return;
    }

    if (this.element?.nativeElement?.parentElement) {
      this.parentElement = this.element.nativeElement.parentElement;
    }

    if (this.parentOverflowHidden && this.parentElement.style) {
      this.element.nativeElement.parentElement.style.overflow = 'hidden';
    }
  }
}
