import { Model } from './model';
import { Text } from './text';

export class TextUsage extends Model<TextUsage> {
  textName: string;

  TextId: number;
  Text: Text;

  model: string;
  modelId: number;
  name: string;
}
