import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[apexSelectOnFocus]',
})
export class SelectOnFocusDirective {
  @Input('apexSelectOnFocus') public condition: boolean | string;

  constructor(private el: ElementRef<HTMLInputElement>) {}

  @HostListener('focus') onFocus(): void {
    if (this.condition || typeof this.condition === 'string') {
      this.el?.nativeElement?.select();
    }
  }
}
