import { Folder } from '../../models/folder';
import { Profile } from '../../models/profile';

export const folderIcon = (folder: Folder, profile: Profile): string => {
  const customerProjectTemplateFolder = profile?.Customer?.projectTemplateFolder;
  const customerProjectEntityTemplateFolder = profile?.Customer?.projectEntityTemplateFolder;

  if (customerProjectTemplateFolder && customerProjectTemplateFolder === folder.id) {
    return 'snippet_folder';
  }

  if (customerProjectEntityTemplateFolder && customerProjectEntityTemplateFolder === folder.id) {
    return 'snippet_folder';
  }

  return folder.modelName
    ? folder.modelName === 'share'
      ? 'people'
      : 'topic'
    : folder.shared
      ? 'folder_shared'
      : 'folder';
};
