import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reduceHtmlToString',
})
export class ReduceHtmlToStringPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    const div = document.createElement('div');

    div.innerHTML = value;

    const texts: string[] = [];

    div.childNodes.forEach((childNode) => texts.push(childNode.textContent));

    return texts.reduce<string>((prev, curr) => `${prev} ${curr}`, '').trim();
  }
}
