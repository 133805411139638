<div mat-dialog-title>
  <div class="df jsb ac f1">
    <h1>#{{ data?.case?.id }}</h1>
    <button
      mat-icon-button
      (click)="close()"
      tabindex="-1"
      [apexAutoFocus]="true">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
<div
  mat-dialog-content
  [ngClass]="data?.contentClass ? data?.contentClass : ''"
  class="df oh rp">
  <apex-case-view
    #viewComponent
    [id]="data?.caseId"
    [case]="data?.case"
    (caseChange)="caseChange.emit($event); data.case = $event; data.case?.archivedAt ? close() : null"
    [highlighted]="data?.highlighted"
    (printClicked)="close()"
    (next)="next.emit()"
    (back)="back.emit()"
    (closeDialogEvent)="close()"
    (navigateOnClose)="close($event)"
    (selectedTabIndexChange)="selectedTabIndex = $event">
  </apex-case-view>
</div>
<div
  mat-dialog-actions
  *ngIf="selectedTabIndex">
  <div></div>
  <div>
    <button
      class="amr"
      mat-button
      type="button"
      (click)="close()">
      <T str="Cancel"></T>
    </button>
    <button
      mat-raised-button
      type="button"
      color="primary"
      (click)="viewComponent.save()"
      [disabled]="
        disableSave ||
        !viewComponent?.formComponent?.form ||
        viewComponent?.formComponent?.form.invalid ||
        !viewComponent?.formComponent?.form.dirty ||
        (!viewComponent?.case.id && viewComponent?.formComponent?.files?.uploading)
      ">
      <T str="Save"></T>
    </button>
  </div>
</div>
