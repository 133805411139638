import { Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Observable } from 'rxjs';
import { Profile } from '../../models/profile';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'apex-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent {
  @Input() icon = '';
  @Input() svgIcon = '';
  @Input() text = '';
  @Input() autofocus = false;
  @Input() disabled = false;

  @Input() color: ThemePalette = undefined;
  @Input() raised: boolean | 'true' | 'false' = false;
  @Input() direction: 'vertical' | 'horizontal' = 'vertical';

  profile$: Observable<Profile>;

  constructor(private userService: UserService) {
    this.profile$ = this.userService.profile$;
  }
}
