import orderBy from 'lodash-es/orderBy';
import { Model } from '../../../../models/model';
import { User } from '../../../../models/user';
import { isPercentIncrease } from '../../../../utils/math';
import { Obj } from '../../object.model';

export enum MeterState {
  Normal = 'normal',
  Error = 'error',
  Warning = 'warning',
}

export class Meter extends Model<Meter> {
  ObjectId: number;
  Object?: Obj;

  CreatorId: number;
  Creator?: User;

  name: string;
  description: string;

  type: MeterType;
  warning: number;
  error: number;

  externalNumber: string;

  Values?: MeterValue[];
  state?: MeterState;

  isAddValueOpen?: boolean;

  constructor(meter?: Partial<Meter>) {
    super(meter);
    this.updateState();
  }

  getState(): MeterState {
    let state = MeterState.Normal;

    const sortedValues = orderBy(this.Values, ['createdAt'], ['desc']);

    if (this.Values?.length) {
      const value = sortedValues[0].value;
      const prev = sortedValues.length > 1 ? sortedValues[1].value : 0;

      if (this.warning && isPercentIncrease(value, prev, this.warning)) {
        state = MeterState.Warning;
      }

      if (this.error && isPercentIncrease(value, prev, this.error)) {
        state = MeterState.Error;
      }
    }

    this.state = state;

    return state;
  }

  updateState(): void {
    this.state = this.getState();
  }
}

export class MeterValue extends Model<MeterValue> {
  CreatorId: number;
  Creator?: User;

  MeterId: number;
  Meter?: Meter;

  value: number;
}

export enum MeterType {
  ColdWater = 'cold-water',
  HotWater = 'hot-water',
  DistrictHeating = 'district-heating',
  Power = 'power',
  Gas = 'gas',
  Temperature = 'temperature',
  Moisture = 'moisture',
  SprinklerPressure = 'sprinkler-pressure',
  Other = 'other',
}
