<div class="df r ac">
  <apex-file-preview
    class="f1"
    size="64"
    [file]="file"></apex-file-preview>
  <div
    class="add-margin-left mat-caption"
    *ngIf="file?.Creator?.name">
    <T
      str="File created by {creatorName}"
      [vars]="{
        creatorName: file.Creator.name,
      }">
    </T>
  </div>
</div>
