<ng-container *ngIf="viewFiles?.length">
  <div
    class="display-flex gap-small"
    apexHammerTime
    parentOverflowHidden
    snapToEdges>
    <ng-container *ngFor="let viewFile of viewFiles">
      <div
        class="mat-elevation-z2 file-wrapper oh position-relative selectable"
        [class.selected-border]="selectedFile === viewFile"
        matRipple
        [matRippleDisabled]="!selectable"
        (click)="selectable && $event.stopPropagation(); selectable && fileClicked(viewFile)">
        <ng-container *ngIf="!viewFile.icon; else showIcon">
          <img
            class="object-fit-cover height-100 w-100 placeholder-image-on-error"
            [src]="viewFile.file.signed?.url"
            [alt]="" />
        </ng-container>

        <ng-template #showIcon>
          <div class="df ac jc height-100 width-100">
            <mat-icon> {{ viewFile.icon }}</mat-icon>
          </div>
        </ng-template>

        <ng-container *ngIf="showFileName">
          <div
            class="mat-elevation-z1 mat-caption position-absolute bottom-0 left-0 w-100"
            [matTooltip]="viewFile.file?.name ?? '' | translate">
            <div class="apxs truncate">
              {{ viewFile.file?.name }}
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
