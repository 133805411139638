import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import { PDFDocumentProxy, TypedArray } from 'pdfjs-dist/types/src/display/api';
import { concat, from, Observable, of } from 'rxjs';
import { filter, map, mergeMap, take, tap, toArray } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { t } from '../components/translate/translate.function';
import { snack } from '../modules/snack.module';

GlobalWorkerOptions.workerSrc = environment.pdfWorkerSrc;

const getPDFFromUrl = (url: string): Observable<PDFDocumentProxy> => from(getDocument(url).promise);
const getPDFFromBuffer = (source: TypedArray): Observable<PDFDocumentProxy> => from(getDocument(source).promise);

export const getPDFPage = (source: string | TypedArray, pageNumber: number, scale = 1): Observable<Blob> =>
  of(source).pipe(
    filter((s) => !!s),
    mergeMap((f) => {
      if (typeof f === 'string') {
        return getPDFFromUrl(f);
      }

      return getPDFFromBuffer(f);
    }),
    filter((pdf) => typeof pdf.getPage === 'function'),
    mergeMap((pdf) =>
      from(pdf.getPage(pageNumber)).pipe(
        filter((page) => typeof page.render === 'function'),
        mergeMap((page) => {
          const canvas = document.createElement('canvas');

          const viewport = page.getViewport({ scale });
          const canvasContext = canvas.getContext('2d');

          canvas.width = viewport.width;
          canvas.height = viewport.height;

          return new Promise<HTMLCanvasElement>((resolve) =>
            page
              .render({
                canvasContext,
                viewport,
              })
              .promise.then((_) => {
                resolve(canvasContext.canvas);
              }),
          );
        }),
        mergeMap(
          (canvas) =>
            new Promise<Blob>((resolve) =>
              canvas.toBlob(
                (b) => {
                  resolve(b);
                },
                'image/webp',
                0.7,
              ),
            ),
        ),
        tap(() => pdf?.destroy()),
      ),
    ),
  );

export const createImagesFromPDF = (file: File): Promise<File[]> => createImagesFromPDF$(file).toPromise();

export const createImagesFromPDF$ = (file: File): Observable<File[]> =>
  of(file).pipe(
    mergeMap(
      (f) =>
        new Observable<ArrayBuffer>((obs) => {
          const fileReader = new FileReader();

          fileReader.onerror = (err): void => obs.error(err);
          fileReader.onabort = (err): void => obs.error(err);
          fileReader.onload = (): void => obs.next(fileReader.result as ArrayBuffer);
          fileReader.onloadend = (): void => obs.complete();

          return fileReader.readAsArrayBuffer(f);
        }),
    ),
    mergeMap((buffer) => getPDFFromBuffer(new Int8Array(buffer))),
    mergeMap((pdfDocument) => {
      const numPages = pdfDocument.numPages;

      if (!numPages) {
        snack(t('No pages in PDF, not doing anything.'));

        return of([]);
      }

      const arrayMedObservables = Array.from({ length: numPages }, (_, i) =>
        createImageFromPDFPage$(pdfDocument, i + 1, file.name),
      );

      return concat(...arrayMedObservables).pipe(toArray());
    }),
  );

export const createImageFromPDFPage = (
  pdf: PDFDocumentProxy,
  idx: number,
  name: string,
  pdfViewScale = 2,
): Promise<File> => createImageFromPDFPage$(pdf, idx, name, pdfViewScale).toPromise();

export const createImageFromPDFPage$ = (
  pdf: PDFDocumentProxy,
  index: number,
  name: string,
  pdfViewScale = 2,
): Observable<File> =>
  of(pdf).pipe(
    mergeMap((pdfDocument) => from(pdfDocument.getPage(index))),
    mergeMap((page) => {
      const canvas = document.createElement('canvas');

      const viewport = page.getViewport({ scale: pdfViewScale });
      const canvasContext = canvas.getContext('2d');

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      return from(
        page.render({
          canvasContext,
          viewport,
        }).promise,
      ).pipe(map((_) => canvas));
    }),
    mergeMap(
      (canvas) =>
        new Observable<File>((obs) =>
          canvas.toBlob(
            (blob) => {
              snack(
                t('Converted page {index} for file {name}', {
                  index,
                  name,
                }),
              );

              return obs.next(
                new File([blob], name.replace('.pdf', `-${index}-pdf.webp`), {
                  lastModified: Date.now(),
                  type: 'image/webp',
                }),
              );
            },
            'image/webp',
            0.7,
          ),
        ),
    ),
    take(1),
  );
