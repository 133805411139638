import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, mergeMap, take } from 'rxjs/operators';

import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '../../../components/confirm-dialog/confirm-dialog.types';
import { snack } from '../../../modules/snack.module';

import { t } from '../../../components/translate/translate.function';
import { ApartmentActionsService } from './apartment-actions.service';

@Component({
  selector: 'apex-apartment-delete-takeover-button',
  template: `
    <div class="df jc">
      <button
        type="button"
        (click)="deleteTakeover()"
        mat-raised-button
        color="primary">
        Slett alle overtakelser
      </button>
    </div>
  `,
})
export class ApartmentDeleteTakeoverButtonComponent {
  @Input() projectId: number;
  @Input() apartmentId: number;

  constructor(
    private dialog: MatDialog,
    private service: ApartmentActionsService,
  ) {}

  deleteTakeover(): void {
    if (!this.projectId || !this.apartmentId) {
      return;
    }

    this.dialog
      .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
        data: {
          title: t('Delete Takeovers'),
          text: t('Are you sure you want to delete?'),
          description: t('Note: This can not be undone!'),
        },
      })
      .afterClosed()
      .pipe(
        filter((r) => r),
        mergeMap((_) => this.service.deleteTakeover(this.projectId, this.apartmentId)),
        take(1),
      )
      .subscribe({
        next: (res) => {
          if (res.delete) {
            snack(t('All takeovers deleted'));
          }
        },
      });
  }
}
