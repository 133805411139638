<h1 mat-dialog-title>
  <T str="Consents missing your approval"></T>
</h1>
<div mat-dialog-content>
  <p>
    <T str="These consents are awaiting your approval"></T>
  </p>
  <a
    class="db mat-elevation-z2 aps amb"
    mat-ripple
    *ngFor="let consent of data.consents"
    [mat-dialog-close]="null"
    [routerLink]="['/', 'consent', consent.id, 'consent']">
    <T
      str="{consentName} from {companyName}"
      [vars]="{ consentName: consent.name, companyName: consent.Company?.name ?? '' }">
    </T>
  </a>
</div>
<div mat-dialog-actions>
  <button
    mat-raised-button
    cdkFocusInitial
    [mat-dialog-close]="null">
    <T str="Do it later"></T>
  </button>
</div>
