import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { AvatarModule } from 'projects/apex/src/app/components/avatar/avatar.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { UserCardModule } from 'projects/apex/src/app/directives/user-card/user-card.module';

import { MeterValuesComponent } from './value-list.component';

@NgModule({
  imports: [CommonModule, MatTableModule, TranslateModule, AvatarModule, UserCardModule],
  exports: [MeterValuesComponent],
  declarations: [MeterValuesComponent],
  providers: [],
})
export class MeterValuesModule {}
