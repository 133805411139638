import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import MsgReader from '@kenjiuno/msgreader';
import { readEml } from 'eml-parse-js';
import { ReadedEmlJson } from 'eml-parse-js/dist/interface';
import { filetypemime } from 'magic-bytes.js';
import { File as ApexFile } from 'projects/apex/src/app/models/file';
import { JoinPipe } from '../../pipes/join/join.pipe';
import { ToEmailStringPipe } from '../../pipes/to-email-string/to-email-string.pipe';
import { FileViewerDialogComponent } from '../file-viewer/dialog.component';
import { ItemModule } from '../item/item.module';
import { TranslateModule } from '../translate/translate.module';
import { Attachment } from './models/attachment.type';
import { Mail } from './models/mail.type';

@Component({
  selector: 'apex-file-mail-viewer',
  standalone: true,
  templateUrl: './file-mail-viewer.component.html',
  imports: [
    CommonModule,
    MatButtonModule,
    ToEmailStringPipe,
    MatCardModule,
    ItemModule,
    TranslateModule,
    MatListModule,
    MatIconModule,
    ItemModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    JoinPipe,
  ],
})
export class FileMailViewerComponent implements OnChanges {
  @Input() file: ApexFile;

  failedToParseMail: boolean;
  mail: Mail;

  constructor(private dialog: MatDialog) {}

  async ngOnChanges(): Promise<void> {
    this.mail = undefined;
    this.failedToParseMail = undefined;

    try {
      const response = await fetch(this.file.signed.url);

      if (this.file.type === 'application/vnd.ms-outlook') {
        const data = await response.arrayBuffer();

        this.mail = await this.parseMSGdata(data);
      } else if (this.file.type === 'message/rfc822') {
        const data = await response.text();

        this.mail = await this.parseEmlData(data);
      } else {
        this.failedToParseMail = true;
      }
    } catch {
      this.failedToParseMail = true;
    }
  }

  isolateMimeTypeFromString(str: string): string | null {
    const regexFileType = /^(.*?);/;

    return str.match(regexFileType)[1].toLowerCase();
  }

  parseEmlData(data: string): Promise<Mail> {
    return new Promise((resolve, reject) => {
      readEml(data, (err, jsonEML) => {
        if (err) {
          reject(err);
        } else {
          const emlJson: ReadedEmlJson = jsonEML;

          if (emlJson.html) {
            const cidMatches = emlJson.html.matchAll(/src=["|'](cid:.*?)["|']/g);

            if (cidMatches) {
              [...cidMatches].forEach((result) => {
                const cid = result[1];
                const matchingAttachment = emlJson.attachments.find((attachment) =>
                  attachment.id.includes(cid.substring(4)),
                );

                emlJson.html = emlJson.html.replace(
                  cid,
                  `data:${this.isolateMimeTypeFromString(matchingAttachment.contentType)};base64,${
                    matchingAttachment.data64
                  }`,
                );
              });
            }
          }

          const regExpBCC = /<(.*?)>/g;

          const parsedMail: Mail = {
            recipients: emlJson.to
              ? Array.isArray(emlJson.to)
                ? emlJson.to.map((recip) => recip.email)
                : [emlJson.to.email]
              : [],
            cc: emlJson.cc
              ? Array.isArray(emlJson.cc)
                ? emlJson.cc.map((recip) => recip.email)
                : [emlJson.cc.email]
              : [],
            bcc: emlJson.headers.Bcc
              ? emlJson.headers.Bcc.match(regExpBCC).map((emailAddress) => emailAddress.slice(1, -1))
              : [],
            subject: emlJson.subject ?? null,
            messageDeliveryTime: emlJson.date ? emlJson.date.toString() : null,
            body: emlJson.text ?? null,
            html: emlJson.html ?? null,
            attachments: emlJson.attachments
              ? emlJson.attachments.map((file) => ({
                  name: file.name,
                  contentType: this.isolateMimeTypeFromString(file.contentType) || null,
                  contentUINT8: file.data as Uint8Array,
                  id: file.id,
                }))
              : [],
            from: emlJson.from ? (Array.isArray(emlJson.from) ? emlJson.from[0].email : emlJson.from.email) : null,
          };

          resolve(parsedMail);
        }
      });
    });
  }

  parseMSGdata(data: ArrayBuffer): Promise<Mail> {
    return new Promise((resolve, reject) => {
      const msgReader = new MsgReader(data);
      const mailData = msgReader.getFileData();

      if (mailData.error) {
        reject(mailData.error);
      }

      const parsedMail: Mail = {
        recipients: mailData.recipients.filter((recip) => recip.recipType === 'to').map((recip) => recip.email) ?? [],
        cc: mailData.recipients.filter((recip) => recip.recipType === 'cc').map((recip) => recip.email) ?? [],
        bcc: mailData.recipients.filter((recip) => recip.recipType === 'bcc').map((recip) => recip.email) ?? [],
        subject: mailData.subject ?? null,
        messageDeliveryTime: mailData.messageDeliveryTime ?? null,
        body: mailData.body ?? null,
        html: mailData.bodyHtml ?? null,
        attachments:
          mailData.attachments.map((file, index) => ({
            name: file.name,
            contentUINT8: msgReader.getAttachment(index).content,
            contentType: filetypemime(msgReader.getAttachment(index).content)[0],
            id: file.pidContentId,
          })) ?? null,
        from: mailData.senderEmail ?? null,
      };

      resolve(parsedMail);
    });
  }

  openAttachment(mailAttachment: Attachment): void {
    const blob = new Blob([mailAttachment.contentUINT8], { type: mailAttachment.contentType });

    const file = {
      id: -1,
      name: mailAttachment.name,
      size: blob.size,
      type: blob.type,
      signed: {
        url: URL.createObjectURL(blob),
      },
    };

    this.dialog.open(FileViewerDialogComponent, {
      data: {
        file,
        showFolderAction: false,
      },
      autoFocus: false,
    });
  }
}
