<div class="oh df c bg-white">
  <apex-qr-code-image
    [path]="data.path"
    [text]="data.text"
    [title]="data.title"
    [name]="data.name"></apex-qr-code-image>
</div>

<div class="df c gap-horizontal-extra-small amt">
  <mat-form-field>
    <mat-label>
      <T str="Title"></T>
    </mat-label>
    <input
      matInput
      type="text"
      [(ngModel)]="data.title" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      <T str="Name"></T>
    </mat-label>
    <input
      matInput
      type="text"
      [(ngModel)]="data.name" />
  </mat-form-field>
</div>
