<apex-page-info [title]="group?.name">
  <apex-progress
    [count]="group?.caseCount"
    [completed]="group?.casesCompleted"
    [small]="true"></apex-progress>
</apex-page-info>

<div class="am">
  <mat-expansion-panel
    #panel
    class="am">
    <mat-expansion-panel-header class="bg-color-main">
      <mat-panel-title>
        <T str="Info"></T>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <apex-checklist-group-form
      class="db ams"
      #form
      [disabled]="!canEdit"
      [group]="group"></apex-checklist-group-form>
  </mat-expansion-panel>
</div>

<apex-model-role
  class="f1 am df c"
  #modelRole
  *ngIf="group?.id"
  [disabled]="!canEdit"
  model="checklist-group"
  [modelId]="group.id"
  role="collaborator"
  [label]="'Collaborator' | translate"
  [title]="'Collaborators' | translate">
</apex-model-role>

<div class="amh amb amts df jfe">
  <button
    type="button"
    mat-button
    (click)="expandAll()">
    <T str="Expand all"></T>
  </button>

  <button
    type="button"
    mat-button
    (click)="collapseAll()">
    <T str="Collapse all"></T>
  </button>
</div>

<div
  *ngIf="group?.Children?.length || group?.Checklists?.length ? true : false"
  class="mat-elevation-z2 am">
  <apex-checklist-group-tree
    [group]="group"
    [hideHeader]="true"></apex-checklist-group-tree>
</div>

<div class="am df jsb ac">
  <button
    mat-button
    color="warn"
    [disabled]="!canEdit"
    (click)="deleteGroup()">
    <T str="Delete"></T>
  </button>
  <div>
    <button
      mat-button
      apexHistoryBack>
      <T str="Back"></T>
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!canEdit"
      (click)="form?.save()">
      <T str="Save"></T>
    </button>
  </div>
</div>
