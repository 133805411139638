<ng-container *ngIf="caseLog">
  <div class="df ac jc amr left">
    <mat-icon> info_outline </mat-icon>
  </div>
  <div class="df jsb f1 ac">
    <apex-title-item class="f1">
      <span
        class="df jsb"
        title>
        <span
          class="amr"
          *ngIf="caseLog | caseLogUser as user"
          [apexUserCard]="user">
          {{ user.name }}
        </span>
        <span [matTooltip]="caseLog.createdAt | date: 'short'">
          {{ caseLog.createdAt | date: 'short' }}
        </span>
      </span>

      <ng-container *ngIf="!caseLog.translate">
        <apex-translate-regexp>{{ caseLog.message }}</apex-translate-regexp>
      </ng-container>
      <ng-container *ngIf="caseLog.translate">
        <div [innerHTML]="caseLog | formatCaseLogMessage"></div>
      </ng-container>
    </apex-title-item>
  </div>
</ng-container>
