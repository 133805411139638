import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '../translate/translate.module';
import { UserFavoriteButtonModule } from '../user-favorite-button/user-favorite-button.module';
import { FilterChipsComponent } from './filter-chips.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatInputModule,

    UserFavoriteButtonModule,
    TranslateModule,
  ],
  declarations: [FilterChipsComponent],
  exports: [FilterChipsComponent],
})
export class FilterChipsModule {}
