import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Project, ProjectAddonCount, ProjectSalesSummaryData } from '../../models/project';
import { ProjectField } from '../../models/project-field';
import { APIService } from '../../services/http/http.service';

import { uniqBy } from 'lodash-es';
import {
  ObjectApi,
  ProjectApartmentApi,
  ProjectApi,
  ResponseObjectUnwantedIncidentInfoDto,
  ResponseProjectApartmentCardDto,
  ResponseProjectUnwantedIncidentInfoDto,
} from '../../../../../../generated/apex-rest';
import { AddonApartment } from '../../models/addon-apartment';
import { Field } from '../../models/field';
import { restAxiosConfig } from '../../utils/rest-axios-config';
import { EntityResponse } from '../../utils/types';
import { SalesScreenProject } from '../reporting/sales-screen/sales-screen.types';
import { FormField } from './fields/fields.types';
import { ProjectInvolvedUsers } from './involved-users/involved-users.types';

@Injectable()
export class ProjectService extends APIService<Project> {
  private readonly projectApi = new ProjectApi(restAxiosConfig());
  private readonly objectApi = new ObjectApi(restAxiosConfig());
  private readonly projectApartmentApi = new ProjectApartmentApi(restAxiosConfig());

  public route = 'project';

  getProjectByCustomerId(): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.apiUrl}/getProjectByCustomerId`, this.options());
  }

  getProjectsUserCanEditAddonOn(): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.apiUrl}/${this.route}/addon`, this.options());
  }

  getProjectUserIsAddonContractorOn(): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.apiUrl}/${this.route}/addon/contractor`, this.options());
  }

  getProjectAddonsCount(): Observable<ProjectAddonCount[]> {
    return this.http.get<ProjectAddonCount[]>(`${this.apiUrl}/${this.route}/addons-count`, this.options());
  }

  getFields(projectId: number): Observable<ProjectField[]> {
    if (!projectId) {
      throw new Error('projectId is required');
    }

    return this.http.get<ProjectField[]>(`${this.apiUrl}/${this.route}/${projectId}/fields`, this.options());
  }

  saveFields(data: { ProjectId: number; Fields: FormField[] }): Observable<null> {
    if (!data.ProjectId) {
      throw new Error('ProjectId is required');
    }

    return this.http.post<null>(`${this.apiUrl}/${this.route}/${data.ProjectId}/fields`, data, this.options());
  }

  getCustomerFields(projectId: number): Observable<Field[]> {
    if (!projectId) {
      throw new Error('projectId is required');
    }

    const url = `${this.apiUrl}/${this.route}/${projectId}/customer-fields`;

    return this.http.get<Field[]>(url, this.options());
  }

  getInvolvedUsers(projectId: number): Observable<ProjectInvolvedUsers> {
    if (!projectId) {
      throw new Error('projectId is required');
    }

    const url = `${this.apiUrl}/${this.route}/${projectId}/involved-users`;

    return this.http.get<ProjectInvolvedUsers>(url, this.options());
  }

  bulkEditAddonApartment(ProjectId: number, addonApartments: AddonApartment[]): Observable<AddonApartment[]> {
    return this.http.post<AddonApartment[]>(
      `${this.apiUrl}/${this.route}/${ProjectId}/addon-apartment/bulk`,
      addonApartments,
      this.options(),
    );
  }

  setFieldAccess(projectId: number, value: boolean): Observable<EntityResponse<Project>> {
    const options = {
      ...this.options(),
      params: {
        fieldAccess: String(value),
      },
    };

    return this.http.get<EntityResponse<Project>>(`${this.apiUrl}/project/${projectId}/set-field-access`, options);
  }

  getProjectSalesReport(projectId: number): Observable<SalesScreenProject> {
    if (!projectId) {
      throw new Error('projectId is required');
    }

    const url = `${this.apiUrl}/${this.route}/${projectId}/sales-report`;

    return this.http.get<SalesScreenProject>(url, this.options());
  }

  getProjectSalesSummary(projectId: number): Observable<ProjectSalesSummaryData> {
    if (!projectId) {
      throw new Error('projectId is required');
    }

    const url = `${this.apiUrl}/${this.route}/${projectId}/sales-summary`;

    return this.http.get<ProjectSalesSummaryData>(url, this.options());
  }

  async getUnwantedIncidentProjectInfo(projectId: number): Promise<ResponseProjectUnwantedIncidentInfoDto> {
    if (!projectId) {
      throw new Error('projectId is required');
    }

    const response = await this.projectApi.nonAuthProjectControllerReadUnwantedIncidentInfo(projectId);

    return response.data.Entity;
  }

  async getUnwantedIncidentObjectInfo(objectId: number): Promise<ResponseObjectUnwantedIncidentInfoDto> {
    if (!objectId) {
      throw new Error('objectId is required');
    }

    const response = await this.objectApi.nonAuthObjectControllerReadProjectUnwantedIncidentInfo(objectId);

    return response.data.Entity;
  }

  async getProjectApartmentFieldNumbers(projectId: number): Promise<ResponseProjectApartmentCardDto[]> {
    if (!projectId) {
      throw new Error('projectId is required');
    }

    try {
      const response = await this.projectApartmentApi.projectApartmentControllerReadApartments(projectId);

      const filteredResponse = response.data.Collection.filter((item) => item.fieldNumber !== null);

      return uniqBy(filteredResponse, 'fieldNumber');
    } catch (error) {
      console.error(error);

      return [];
    }
  }
}
