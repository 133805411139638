<form #form="ngForm">
  <div class="df row">
    <mat-form-field class="f1">
      <mat-label>
        <T str="Message"></T>
      </mat-label>
      <textarea
        #messageTextArea="matInput"
        matInput
        (focus)="showAlternatives = true"
        (keydown)="keydown($event)"
        (blur)="onBlur()"
        [(ngModel)]="caseLog.message"
        name="message"
        cdkTextareaAutosize
        [required]="required"
        [disabled]="saving"></textarea>
    </mat-form-field>
  </div>
  <div
    *ngIf="showAlternatives"
    class="df row jsb">
    <div class="df column">
      <p>
        <T str="Visible to"></T>
      </p>
      <mat-checkbox
        [(ngModel)]="forContractor"
        name="forContractor"
        [disabled]="saving || !isCaseManagerOnAllCases()">
        <T str="Contractor"></T>
      </mat-checkbox>
      <mat-checkbox
        [(ngModel)]="forClient"
        name="forClient"
        [disabled]="saving">
        <T str="Customer"></T>
      </mat-checkbox>
    </div>
    <div class="df column">
      <p>
        <T str="Notification"></T>
      </p>
      <mat-checkbox
        [(ngModel)]="caseLog.sendNotifications"
        name="sendNotifications"
        [disabled]="saving">
        <T str="Email"></T>
      </mat-checkbox>
      <mat-checkbox
        [(ngModel)]="caseLog.sendSMS"
        name="sendSMS"
        [disabled]="saving">
        <T str="SMS"></T>
      </mat-checkbox>
    </div>
  </div>
  <mat-error
    class="mat-caption"
    *ngIf="anyCaseHasWrongCategory()">
    <p>
      <T str="Clients will not get any communication on email or SMS for selected category."></T>
    </p>
  </mat-error>
</form>
