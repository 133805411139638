import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { of } from 'rxjs';

import { EntryGroup } from './entry-group.model';
import { EntryGroupService } from './entry-group.service';

export const EntryGroupResolver: ResolveFn<EntryGroup> = (route: ActivatedRouteSnapshot) => {
  const service = inject(EntryGroupService);

  const tid = Number(route.paramMap.get('tid'));
  const egid = Number(route.paramMap.get('egid'));

  service.route = `tenancy/${tid}/entry-group`;

  return tid ? (egid ? service.get(egid) : of(new EntryGroup())) : of(new EntryGroup());
};

export const EntryGroupsResolver: ResolveFn<EntryGroup[]> = (route: ActivatedRouteSnapshot) => {
  const service = inject(EntryGroupService);

  const tid = Number(route.paramMap.get('tid'));

  service.route = `tenancy/${tid}/entry-group`;

  return tid ? service.query() : of([]);
};
