import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FileUsage } from '../../models/file-usage';
import { FileUsageService } from '../file-usage/file-usage.service';
import { File as ApexFile } from './../../models/file';

@Component({
  selector: 'apex-file-preview-loader',
  templateUrl: './file-preview-loader.component.html',
})
export class FilePreviewLoaderComponent implements OnChanges, OnDestroy {
  @Input() fileUsageId: number;
  @Input() self: string;
  @Input() selfId: string | number;

  @Input() fileUsages: FileUsage[];

  file: ApexFile | null = null;
  fileUsage: FileUsage | null = null;

  private subscriptions = new Subscription();

  constructor(private fileUsageService: FileUsageService) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.fileUsageId) {
      if (this.fileUsages?.length) {
        const fileUsage = this.fileUsages.find((fu) => Number(fu.id) === Number(this.selfId));

        if (fileUsage) {
          this.file = fileUsage?.File ?? null;
          this.fileUsage = fileUsage;

          return;
        }
      }

      this.subscriptions.add(
        this.fileUsageService
          .get(this.fileUsageId)
          .pipe(take(1))
          .subscribe((fileUsage) => {
            this.file = fileUsage?.File ?? null;
            this.fileUsage = fileUsage;
          }),
      );
    } else {
      this.file = null;
      this.fileUsage = null;
    }
  }
}
