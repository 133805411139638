<div
  class="df c ac jc"
  [ngClass]="{ small: small }"
  [matTooltip]="'{completed} out of {count} completed - {percent}%' | translate: { completed, count, percent }">
  <div
    class="checklist-completed-spinner"
    *ngIf="count > 0 && count !== completed">
    <mat-progress-spinner
      class="spinner-background"
      [diameter]="small ? 24 : 40"
      [mode]="'determinate'"
      [value]="100">
    </mat-progress-spinner>
    <mat-progress-spinner
      [diameter]="small ? 24 : 40"
      [mode]="'determinate'"
      [value]="(completed / count) * 100">
    </mat-progress-spinner>
    <div
      class="percent mat-caption"
      *ngIf="showPercentage">
      {{ completed / count | percent }}
    </div>
  </div>
  <mat-icon
    color="primary"
    class="complete-icon"
    *ngIf="completed > 0 && count === completed">
    check_circle_outline
  </mat-icon>
</div>
