import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import moment, { Moment } from 'moment';
import { FileUsageComponent } from 'projects/apex/src/app/components/file-usage/file-usage.component';
import { ObjectFieldService } from 'projects/apex/src/app/features/object/project/field/object-field.service';
import { Case, Contractor, NewAddon } from 'projects/apex/src/app/models/case';
import { ObjectField } from 'projects/apex/src/app/models/object-field';
import { User } from 'projects/apex/src/app/models/user';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import { EMPTY, Observable, Subscription, forkJoin, merge, of } from 'rxjs';
import { expand, last, map, mergeMap, take } from 'rxjs/operators';
import {
  FileUsageViewerData,
  FileUsageViewerMode,
} from '../../../components/file-usage-viewer/file-usage-viewer.types';
import { FileUsageService } from '../../../components/file-usage/file-usage.service';
import { t } from '../../../components/translate/translate.function';
import { Apartment } from '../../../models/apartment';
import { Autocomplete } from '../../../models/autocomplete';
import { FileUsage } from '../../../models/file-usage';
import { Marking, MarkingModelType } from '../../../models/marking';
import { Project } from '../../../models/project';
import { snack } from '../../../modules/snack.module';
import { isFromBeforeTo, removeUndefined } from '../../../utils/functions';
import { ApartmentService } from '../../apartment/apartment.service';
import {
  ChecklistTemplate,
  ChecklistTemplateItem,
  ChecklistTemplateSection,
} from '../../checklist-template/checklist-template.model';
import {
  ChecklistTemplateItemService,
  ChecklistTemplateSectionService,
  ChecklistTemplateService,
} from '../../checklist-template/checklist-template.service';
import {
  checklistTemplateItemsSortFunc,
  checklistTemplateSectionsSortFunc,
} from '../../checklist-template/form/form.component';
import { ObjectService } from '../../object/object.service';
import { AgreementService } from '../../object/project/agreement/agreement.service';
import { MeterService } from '../../object/project/meter/meter.service';
import { ObjectOwnershipService } from '../../object/project/ownership/object-ownership.service';
import { CommercialObject, Project as ObjProject } from '../../object/project/project.model';
import { TenancyService } from '../../object/project/tenancy/tenancy.service';
import { ProjectService } from '../../project/project.service';
import { CaseService } from '../case.service';
import { CaseNewMessageComponent } from '../new-message/new-message.component';
import {
  CreateCaseTime,
  RepeatWeekOn,
  RepeatYearOn,
  RepeatableCase,
  RepeatableCaseInterval,
} from '../repeatable-case.model';
import { RepeatableCaseService } from '../repeatable-case.service';

@Component({
  selector: 'apex-case-form',
  templateUrl: './form.component.html',
})
export class CaseFormComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() case: Case;
  @Input() newAddon: NewAddon = new NewAddon();
  @Input() createRepeatableCase = false;

  @ViewChild('form') form: NgForm;
  @ViewChild('files', { static: false }) files: FileUsageComponent;

  @ViewChild('startTime') startTime: NgModel;
  @ViewChild('endTime') endTime: NgModel;

  @ViewChild('pbk') pbkPanel: MatExpansionPanel;
  @ViewChild('property') propertyPanel: MatExpansionPanel;

  @ViewChild('formAccordion') formAccordion: MatAccordion;
  @ViewChild('newMessage') messageComponent: CaseNewMessageComponent;

  loading = false;
  profile: User;
  externalCustomerId: number;
  maxDescriptionLength = 4096;

  deadline: Date;

  project: Project;
  floorplans: FileUsage[] = [];

  subscription = new Subscription();

  fromBeforeTo: boolean;

  intervals = RepeatableCaseInterval;
  translatedIntervals = {
    [RepeatableCaseInterval.Daily]: t('Daily'),
    [RepeatableCaseInterval.Weekly]: t('Weekly'),
    [RepeatableCaseInterval.Monthly]: t('Monthly'),
    [RepeatableCaseInterval.Quarterly]: t('Quarterly'),
    [RepeatableCaseInterval.Yearly]: t('Yearly'),
  };
  createCaseTimes = CreateCaseTime;

  repeatWeekOns = RepeatWeekOn;
  translatedRepeatWeekOns = {
    [RepeatWeekOn.EveryWeek]: t('Week'),
    [RepeatWeekOn.EveryTwoWeeks]: t('Two weeks'),
  };

  repeatYearOns = RepeatYearOn;
  translatedRepeatYearOns = {
    [RepeatYearOn.Day]: t('Day'),
    [RepeatYearOn.Week]: t('Week'),
  };

  fileUsageViewerData: FileUsageViewerData;
  selectedObjectPath: string;

  checklistTemplateSections: ChecklistTemplateSection[] = [];
  checklistTemplateItems: ChecklistTemplateItem[] = [];
  checklistTemplate: ChecklistTemplate;

  afterViewInit = false;
  selectedToDateDays = 7;

  constructor(
    private route: ActivatedRoute,
    private caseService: CaseService,
    private userService: UserService,
    private projectService: ProjectService,
    private fileUsageService: FileUsageService,
    private fieldService: ObjectFieldService,
    private repeatableCaseService: RepeatableCaseService,
    private objectService: ObjectService,
    private tenancyService: TenancyService,
    private agreementService: AgreementService,
    private apartmentService: ApartmentService,
    private checklistTemplateService: ChecklistTemplateService,
    private checklistTemplateItemService: ChecklistTemplateItemService,
    private checklistTemplateSectionService: ChecklistTemplateSectionService,
    private meterService: MeterService,
    private objectOwnershipService: ObjectOwnershipService,
  ) {}

  ngOnInit(): void {
    if (!this.case) {
      this.case = this.caseService.newCaseFromParams(this.route.snapshot.queryParams);
    }

    this.subscription.add(
      this.userService.filteredProfile$.pipe(take(1)).subscribe({
        next: (user: User) => {
          this.profile = user;

          if (!this.case.CaseCategoryId) {
            this.case.CaseCategoryId = 5;
          }

          if (!this.case.CaseManagerId && !(this.case.id || this.case.RepeatableCaseId)) {
            this.case.CaseManagerId = this.profile.id;
          }

          this.deadline = this.case.deadline
            ? moment(this.case.deadline * 1000).toDate()
            : moment().add(7, 'days').toDate();

          if (this.case.ProjectId) {
            if (this.case.id) {
              this.subscription.add(
                this.projectService
                  .get(this.case.ProjectId)
                  .pipe(
                    take(1),
                    map((res) => res.Entity),
                  )
                  .subscribe((project) => {
                    this.project = project;
                  }),
              );
            } else {
              this.getProject(this.case.ProjectId);
            }
          }

          if (this.case.ProjectId || this.case.ApartmentId || this.case.ObjectId) {
            this.getFloorplans();
          }
        },
      }),
    );

    this.case.RepeatableCase = new RepeatableCase(this.case?.RepeatableCase);

    if (!this.case.RepeatableCase.repeatYearOn) {
      this.case.RepeatableCase.repeatYearOn = RepeatYearOn.Day;
    }

    if (!this.case.RepeatableCase.repeatWeekOn) {
      this.case.RepeatableCase.repeatWeekOn = RepeatWeekOn.EveryWeek;
    }

    this.case.Contractors = this.case.Contractors || [];
    this.case.Markings = this.case.Markings || [];

    if (!this.case.id && !this.case.from && !this.case.to) {
      this.case.from = moment().toDate();
      this.case.to = moment(this.deadline).toDate();
    }

    this.checkFromBeforeTo();

    if (!this.case.id && this.case.ObjectId) {
      this.objectChange(this.case.ObjectId);
    }

    if (!this.case.id && this.case.TenancyId) {
      this.tenancyChange(this.case.TenancyId);
    }

    if (!this.case.id && this.case.AgreementId) {
      this.agreementChange(this.case.AgreementId);
    }

    if (!this.case.id && this.case.MeterId) {
      this.meterChange(this.case.MeterId);
    }

    if (!this.case.id && (this.case.ChecklistTemplateId || this.case.RepeatableCase?.ChecklistTemplateId)) {
      const templateId = this.case.ChecklistTemplateId || this.case.RepeatableCase?.ChecklistTemplateId;

      this.checklistTemplateChange(templateId);
    }

    if (!this.case.id && !this.case.notifyContractor) {
      this.case.notifyContractor = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.case) {
      this.deadline = this.case?.deadline
        ? moment(this.case.deadline * 1000).toDate()
        : moment().add(7, 'days').toDate();
      this.case.RepeatableCase = new RepeatableCase(this.case?.RepeatableCase);

      if (this.case.ProjectId) {
        if (this.case.id) {
          this.subscription.add(
            this.projectService
              .get(this.case.ProjectId)
              .pipe(
                take(1),
                map((res) => res.Entity),
              )
              .subscribe((project) => {
                this.project = project;
              }),
          );
        } else {
          this.getProject(this.case.ProjectId);
        }
      }

      if (this.case.ObjectId) {
        this.objectChange(this.case.ObjectId);
      }

      if (this.case.TenancyId) {
        this.tenancyChange(this.case.TenancyId);
      }

      if (this.case.ProjectId && this.case.ApartmentId) {
        this.apartmentChange(this.case.ApartmentId);
      }

      if (this.case.ProjectId || this.case.ApartmentId || this.case.ObjectId) {
        this.getFloorplans();
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.afterViewInit = true;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  projectChange(id: number): void {
    this.case.Apartment = new Apartment();
    this.case.ApartmentId = null;
    this.case.ClientId = null;
    this.getFloorplans();
    this.newAddon.AddonCategoryId = null;

    if (id) {
      this.propertyPanel?.close();

      this.case.ObjectId = null;
      this.case.TenancyId = null;
      this.case.AgreementId = null;
      this.getProject(id);
    } else {
      this.project = null;
    }
  }

  apartmentChange(id: number): void {
    this.case.ClientId = null;
    this.case.Apartment = new Apartment();
    this.getFloorplans();

    if (id) {
      this.subscription.add(
        this.apartmentService.get(id).subscribe({
          next: (a) => {
            this.case.Apartment = a;
          },
        }),
      );
    }

    if (id === null) {
      this.newAddon.priceIn = null;
      this.newAddon.priceOut = null;
      this.newAddon.VAT = null;
    }
  }

  objectChange(id: number): void {
    this.case.ProjectId = null;
    this.getFloorplans();

    let objects: string[] = [];

    if (!id && !this.case.id) {
      this.case.Object = null;
    }

    if (id) {
      this.pbkPanel?.close();

      this.subscription.add(
        this.objectService
          .get<CommercialObject>(id)
          .pipe(
            expand((o, i) => {
              if (i === 0) {
                this.case.Object = o;
              }

              objects = [o?.name, ...objects];

              return o.ParentId ? this.objectService.get<CommercialObject>(o.ParentId) : EMPTY;
            }),
            last(),
          )
          .subscribe({
            next: (cObj: ObjProject) => {
              this.selectedObjectPath = objects.join(' / ');

              if (cObj?.data && !this.case.id) {
                this.case.CaseManagerId = cObj.data.standardCaseManagerId || this.case.CaseManagerId;
                this.addContractor(cObj.data.standardContractorId || this.case.ContractorId);

                this.case.CaseCategoryId = cObj.data.standardCaseCategoryId || this.case.CaseCategoryId;
              }
            },
          }),
      );
    }
  }

  objectOwnershipChange(objectOwnershipId: string): void {
    this.case.ProjectId = null;

    if (!objectOwnershipId && !this.case.id) {
      this.case.ObjectOwnership = null;

      return;
    }

    if (!objectOwnershipId) {
      return;
    }

    this.pbkPanel?.close();

    this.subscription.add(
      this.objectOwnershipService.getByObject(this.case.ObjectId, objectOwnershipId).subscribe({
        next: ({ Entity: objectOwnership }) => {
          const topLevel: ObjProject = objectOwnership.objectTree.find((o) => !o.ParentId) as ObjProject;

          this.case.ObjectOwnership = objectOwnership;

          if (topLevel.data && !this.case.id) {
            this.case.CaseManagerId = topLevel.data.standardCaseManagerId || this.case.CaseManagerId;
            this.addContractor(topLevel.data.standardContractorId || this.case.ContractorId);
          }
        },
      }),
    );
  }

  tenancyChange(id: number): void {
    this.case.ProjectId = null;
    this.getFloorplans();

    if (!id && !this.case.id) {
      this.case.Tenancy = null;
    }

    if (id) {
      this.pbkPanel?.close();

      this.subscription.add(
        forkJoin([
          this.tenancyService.tree(id).pipe(map((res) => res.Collection)),
          this.tenancyService.get(id).pipe(map((res) => res.Entity)),
        ]).subscribe({
          next: ([objects, tenancy]) => {
            const topLevel: ObjProject = objects.find((o) => !o.ParentId) as ObjProject;

            this.case.Tenancy = tenancy;

            if (topLevel.data && !this.case.id) {
              this.case.CaseManagerId = topLevel.data.standardCaseManagerId || this.case.CaseManagerId;
              this.addContractor(topLevel.data.standardContractorId || this.case.ContractorId);
            }
          },
        }),
      );
    }
  }

  agreementChange(id: number): void {
    this.case.ProjectId = null;
    this.getFloorplans();

    if (!id && !this.case.id) {
      this.case.Agreement = null;
    }

    if (id) {
      this.pbkPanel?.close();

      this.subscription.add(
        this.agreementService.getTopLevelObject(id).subscribe({
          next: (project) => {
            if (project?.data && !this.case.id) {
              this.case.CaseManagerId = project.data.standardCaseManagerId || this.case.CaseManagerId;
              this.addContractor(project.data.standardContractorId || this.case.ContractorId);
            }
          },
        }),
      );
    }
  }

  meterChange(id: number): void {
    this.case.ProjectId = null;
    this.getFloorplans();

    if (!id && !this.case.id) {
      this.case.Meter = null;
    }

    if (id) {
      this.pbkPanel?.close();

      this.subscription.add(
        this.meterService
          .getTopLevelObject(id)
          .pipe(map((res) => res.Entity))
          .subscribe({
            next: (project) => {
              if (project.data && !this.case.id) {
                this.case.CaseManagerId = project.data.standardCaseManagerId || this.case.CaseManagerId;
                this.addContractor(project.data.standardContractorId || this.case.ContractorId);
              }
            },
          }),
      );
    }
  }

  fieldChange(id: number): void {
    if (id && this.project) {
      const contractorId = this.getContractorIdByField(id);

      if (contractorId) {
        this.addContractorsFromProjectField();
      }
    } else if (id && this.case.ObjectId) {
      this.addContractorsFromObjectField();
    }
  }

  checklistTemplateChange(id: number): void {
    if (this.case.RepeatableCase) {
      this.case.RepeatableCase.ChecklistTemplateId = id;
    }

    if (!id) {
      this.checklistTemplateItems = [];
    } else {
      this.subscription.add(
        forkJoin([
          this.checklistTemplateService.get(id),
          this.checklistTemplateItemService.getItems(id),
          this.checklistTemplateSectionService.getSections(id),
        ]).subscribe({
          next: ([template, items, sections]) => {
            this.checklistTemplate = template?.Entity;
            this.checklistTemplateItems = (items || []).sort(checklistTemplateItemsSortFunc);
            this.checklistTemplateSections = (sections || []).sort(checklistTemplateSectionsSortFunc);
          },
        }),
      );
    }
  }

  checkFromBeforeTo(): boolean {
    this.fromBeforeTo = isFromBeforeTo(this.case.from, this.case.to);

    return this.fromBeforeTo;
  }

  contractorChange(id: number): void {
    if (id && this.project && !this.case.FieldId) {
      this.project.Fields?.some((f) => {
        // .some is used to bail early here
        if (f.Users.map((u) => u.id).includes(id)) {
          this.case.FieldId = f.id;

          return true;
        }

        return false;
      });
    }
  }

  getProject(id: number): void {
    this.subscription.add(
      this.projectService
        .get(id)
        .pipe(
          take(1),
          map((res) => res.Entity),
        )
        .subscribe((project: Project) => {
          this.project = project;

          if (this.project.DefaultCaseManagerId) {
            this.case.CaseManagerId = this.project.DefaultCaseManagerId;
          }

          if (this.case.FieldId) {
            this.addContractor(this.getContractorIdByField(Number(this.case.FieldId)));
          }

          if (this.project.ContractorId) {
            this.addContractor(this.project.ContractorId);
          }
        }),
    );
  }

  getFloorplans(): void {
    if (!this.case.id) {
      this.subscription.add(
        forkJoin([
          this.case.ProjectId ? this.fileUsageService.all('project', this.case.ProjectId, 'floorplans') : of([]),
          this.case.ApartmentId ? this.fileUsageService.all('apartment', this.case.ApartmentId, 'floorplans') : of([]),
          this.case.ObjectId ? this.objectService.getFloorplansForParentsAndChildren(this.case.ObjectId) : of([]),
          this.case.TenancyId ? this.tenancyService.getFloorplans(this.case.TenancyId) : of([]),
        ])
          .pipe(map((f: FileUsage[][]) => [].concat(...f)))
          .subscribe({
            next: (fileUsages: FileUsage[]) => {
              fileUsages.forEach((f) => {
                f.Markings = this.case.Markings?.length ? this.case.Markings.filter((m) => m.FileUsageId === f.id) : [];
              });
              this.floorplans = fileUsages;

              this.fileUsageViewerData = {
                fileUsages: this.floorplans,
                mode: FileUsageViewerMode.Mark,
                editable: true,
                client: false, // This is quite bad isn't it
                modelData: {
                  model: MarkingModelType.Case,
                  modelId: this.case.id,
                },
              };
            },
          }),
      );
    }
  }

  markingsChanged(markings: Marking[]): void {
    this.case.Markings = markings?.map((m) => Object.assign(m, { model: 'case' }));

    this.floorplans.forEach((f) => {
      f.Markings = this.case.Markings?.length ? this.case.Markings.filter((m) => m.FileUsageId === f.id) : [];
    });
  }

  getContractorIdByField(fieldId: number): number {
    return this.project.Fields.find((f) => f.id === fieldId)?.ProjectField?.UserId;
  }

  saveRepeatableCase(c: Case): Observable<RepeatableCase> {
    const repeatableCase = new RepeatableCase(c.RepeatableCase);

    repeatableCase.case = c;
    repeatableCase.interval = this.case?.RepeatableCase?.interval;
    repeatableCase.endDate = this.case?.RepeatableCase?.endDate;
    repeatableCase.createCaseTime = this?.case.RepeatableCase?.createCaseTime;

    return this.repeatableCaseService.save(repeatableCase).pipe(
      take(1),
      map((rc) => new RepeatableCase(rc)),
      mergeMap((rc) => {
        if (this.files) {
          this.files.selfId = rc.id;

          return this.files.saveAll().pipe(
            last(),
            mergeMap((_) => {
              this.loading = false;

              return of(rc);
            }),
          );
        } else {
          this.loading = false;
        }

        return of(rc);
      }),
    );
  }

  saveCase(caseToSave: Case): Observable<Case> {
    this.loading = true;

    return this.caseService.save(caseToSave).pipe(
      take(1),
      mergeMap((c) => {
        if (this.files) {
          this.files.selfId = c.id;

          return this.files.saveAll().pipe(
            last(),
            mergeMap((_) => {
              this.loading = false;

              return of(c);
            }),
          );
        } else {
          this.loading = false;
        }

        return of(c);
      }),
      mergeMap((c) => {
        if (this.messageComponent?.caseLog?.message) {
          this.messageComponent.cases = [c];

          this.messageComponent.save();

          return of(c);
        } else {
          return of(c);
        }
      }),
    );
  }

  save(): Observable<Case | RepeatableCase> {
    if (!this.invalid) {
      if (this.showAddon) {
        this.case.NewAddon = this.newAddon;
      }

      this.case.deadline = moment(this.deadline).valueOf();

      const caseToSave = removeUndefined(cloneDeep(this.case)) as Case;

      if (
        !this.case.id &&
        this.createRepeatableCase &&
        (this.case.RepeatableCase?.interval || this.case.RepeatableCase?.createCaseTime)
      ) {
        return this.saveRepeatableCase(caseToSave);
      } else if (
        this.case.id &&
        this.createRepeatableCase &&
        (this.case.RepeatableCase?.interval || this.case.RepeatableCase?.createCaseTime)
      ) {
        return merge(this.saveCase(caseToSave), this.saveRepeatableCase(caseToSave));
      } else {
        return this.saveCase(caseToSave);
      }
    }

    throw new Error('Form is not valid');
  }

  get showAddon(): boolean {
    return !this.case.id && this.case.CaseCategoryId === 14;
  }

  bogusSort(): number {
    return 0;
  }

  get invalid(): boolean {
    return !!(this.form?.invalid || this.files?.uploading || !this.fromBeforeTo);
  }

  hasCommercial(): boolean {
    return this.userService.hasCustomerRight('Commercial');
  }

  get hasProject(): boolean {
    return this.userService.hasCustomerRight('Project');
  }

  setName(type: string, autocomplete: Autocomplete): void {
    this.case[type] = {
      ...this.case[type],
      name: '',
    };
    this.case[type].name = autocomplete?.name;
  }

  addContractor(id: number, cc?: Contractor): void {
    if (id && !this.case.Contractors?.find((c) => c.id === id)) {
      this.subscription.add(
        this.userService.get(id).subscribe({
          next: (user) => {
            const contractor = new Contractor({
              ...cc,
              ...user,
            });

            if (!this.case.Contractors?.find((c) => c.id === contractor.id)) {
              this.contractorChange(id);
              this.case.Contractors.push(contractor);
            }
          },
        }),
      );
    }
  }

  editContractor(cc: Contractor): void {
    const contractor = this.case.Contractors?.find((c) => c.id === cc.id);

    if (contractor?.CaseContractor && cc?.CaseContractor) {
      contractor.CaseContractor.from = cc.CaseContractor.from;
      contractor.CaseContractor.to = cc.CaseContractor.to;
    }
  }

  removeContractor(contractor: Contractor): void {
    const idx = this.case.Contractors.indexOf(contractor);

    if (idx !== -1) {
      this.case.Contractors.splice(idx, 1);
      snack(t('Deleted'));
    }
  }

  addContractorsFromObjectField(): void {
    if (this.case?.ObjectId && this.case?.ObjectFieldId) {
      this.subscription.add(
        this.fieldService.get(this.case.ObjectId, this.case.ObjectFieldId).subscribe({
          next: (field: ObjectField) => {
            this.case.ObjectField = field;

            const contractors =
              field.Contractors?.filter((c) => !this.case.Contractors.find((cc) => cc.id === c.id)) ?? [];

            this.case.Contractors = this.case.Contractors.concat(contractors as Contractor[]);
          },
        }),
      );
    }
  }

  addContractorsFromProjectField(): void {
    if (this.case?.ProjectId && this.case?.FieldId) {
      this.subscription.add(
        this.projectService.getFields(this.case?.ProjectId).subscribe({
          next: (fields) => {
            const field = fields.find((projectField) => projectField.FieldId === this.case.FieldId);

            if (field) {
              const fieldUsers = field.Field.Users || [];

              const findProjectFields = this.project.Fields.filter((f) => f.id === field.FieldId).flatMap(
                (f) => f.Users,
              );

              const contractors: Contractor[] =
                fieldUsers
                  .filter((user) => findProjectFields.some((fpUser) => fpUser.id === user.id))
                  .map((user) => new Contractor(user))
                  .filter((contractor) => !this.case.Contractors.find((cc) => cc.id === contractor.id)) ?? [];

              this.case.Contractors = this.case.Contractors.concat(contractors);
            }
          },
        }),
      );
    }
  }

  fromRequestChange(): void {
    if (this.case?.fromRequest && this.case?.id && !this.case.fromRequestTime) {
      this.case.fromRequestTime = moment(this.case.createdAt).toDate();
    }
  }

  startTimeChange(newFrom: Moment): void {
    if (!newFrom) {
      return;
    }

    const oldFrom = moment(this.case.from);
    const interval = newFrom.diff(oldFrom, 'hours');

    const oldTo = moment(this.case.to);
    const newTo = moment(this.case.to).add(interval, 'hours');

    this.case.from = newFrom.toDate();
    this.case.to = newTo.toDate();

    this.endTimeChange(newTo, oldTo.toDate());
  }

  endTimeChange(newTo: Moment, oldTo: Date): void {
    if (!newTo || !oldTo) {
      return;
    }

    const interval = newTo.diff(oldTo, 'hours');
    const deadline = moment(this.deadline).add(interval, 'hours');

    this.deadline = deadline.toDate();
    this.case.to = newTo.toDate();

    this.checkFromBeforeTo();
  }

  setNewToDate(days: number): void {
    const n = moment(this.case.from).add(days, 'days');

    this.selectedToDateDays = days;
    this.endTimeChange(n, this.case.to);
  }
}
