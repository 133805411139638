import { Obj } from '../features/object/object.model';
import { Customer } from './customer';
import { Model } from './model';
import { ModelRole } from './model-role';
import { User } from './user';

export class Tenancy extends Model<Tenancy> {
  CreatorId: number;
  Creator: User;

  CustomerId: number;
  Customer: Customer;

  name: string;
  description?: string;

  TenantCustomerId: number;
  TenantCustomer: Customer;

  ContactId: number;
  Contact: User;

  active: boolean;

  from: Date;
  to: Date;

  VAT: number;
  informationInternal: string;
  informationExternal: string;

  Objects: Obj[];

  administrators?: ModelRole[];
  tenants: ModelRole[];
  topObject?: Obj;
  indirectObjects?: Obj[];
  objectTree: Obj[];

  ObjectPath?: Obj[];
}
