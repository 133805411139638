<div
  class="df date-time-wrapper"
  [ngClass]="{ 'reverse-view-order': reverseViewOrder }">
  <mat-form-field>
    <mat-label>
      {{ dateTitle }}
    </mat-label>
    <input
      matInput
      autocomplete="off"
      name="dateDate"
      #dateInput="ngModel"
      [min]="minDate"
      [max]="maxDate"
      [disabled]="disabled || disableDate"
      (click)="picker.open()"
      [matDatepicker]="picker"
      [(ngModel)]="date"
      (blur)="onDateBlur()" />
    <mat-datepicker-toggle
      matIconPrefix
      [for]="picker">
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error>
      <T str="Invalid date"></T>
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>
      {{ timeTitle }}
    </mat-label>
    <input
      type="text"
      matInput
      autocomplete="off"
      name="dateTime"
      #timeInput="ngModel"
      apexValidateTime
      [disabled]="disabled || disableTime"
      [matAutocomplete]="timeAutocomplete"
      [errorStateMatcher]="invalidTimeInputErrorStateMatcher"
      [(ngModel)]="time"
      (blur)="onTimeBlur()" />
    <mat-autocomplete #timeAutocomplete="matAutocomplete">
      <mat-option
        *ngFor="let t of times"
        [value]="t">
        {{ t }}
      </mat-option>
    </mat-autocomplete>
    <mat-error>
      <T str="Invalid time"></T>
    </mat-error>
  </mat-form-field>
</div>
