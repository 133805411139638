import { InjectionToken } from '@angular/core';
import { AuthDestination } from '../../../../../../generated/apex-rest';

// Make it a bit safer.
type Provider = {
  provide: InjectionToken<AuthDestination>;
  useValue: AuthDestination;
};

export const AUTH_DESTINATION = new InjectionToken<AuthDestination>('AuthDestination');

export const appAuthDestinationProvider = (): Provider => ({
  provide: AUTH_DESTINATION,
  useValue: AuthDestination.App,
});

export const inspectionAuthDestinationProvider = (): Provider => ({
  provide: AUTH_DESTINATION,
  useValue: AuthDestination.Inspection,
});

export const portalAuthDestinationProvider = (): Provider => ({
  provide: AUTH_DESTINATION,
  useValue: AuthDestination.Portal,
});
