import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { TextService } from '../../features/text/text.service';
import { EditorInitPipeModule } from '../../pipes/editor-init/editor-init.pipe.module';
import { TextEditComponent } from './text-edit.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    TranslateModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatTooltipModule,
    EditorModule,
    EditorInitPipeModule,
  ],
  providers: [TextService],
  declarations: [TextEditComponent],
  exports: [TextEditComponent],
})
export class TextEditModule {}
