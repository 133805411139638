import { PinturaEditorDefaultOptions } from '@pqina/pintura';
import getPinturaLocale from './get-pintura-locale';

const pinturaDefaultOptions = (fileName?: string): PinturaEditorDefaultOptions => {
  const options: PinturaEditorDefaultOptions = {
    animations: 'never',
    locale: getPinturaLocale(),
    util: 'annotate',
    utils: [
      'crop',
      'annotate',
      'sticker',
      'resize',
      // 'filter',
      'finetune',
      //'decorate',
      // 'frame',
      'redact',
    ],
    stickerEnableSelectImage: false,
    stickers: ['✔️', '❌', '👍', '👎', '🦄', '👻'],

    enablePasteImage: true,
    enableDropImage: true,

    imageWriter: {
      mimeType: 'image/webp',
      quality: 1,
    },
  };

  if (fileName) {
    options.imageWriter.renameFile = (_: File): string => fileName;
  }

  return options;
};

export default pinturaDefaultOptions;
