<h1 mat-dialog-title>
  <ng-container *ngIf="data?.tooltip; else defaultTitle">
    {{ data.tooltip }}
  </ng-container>
  <ng-template #defaultTitle>
    <T str="QR Code"></T>
  </ng-template>
</h1>

<div
  mat-dialog-content
  class="min-height-250">
  <apex-qr-code-image-container
    #qrCodeImageContainer
    [data]="data"></apex-qr-code-image-container>
</div>

<div
  class="df jfe"
  mat-dialog-actions>
  <div class="df gap-extra-small ac">
    <button
      (click)="downloadQr()"
      [matTooltip]="'Download' | translate"
      mat-icon-button
      color="primary"
      target="_blank">
      <mat-icon>cloud_download</mat-icon>
    </button>
    <button
      *ngIf="hasClipboardItem"
      (click)="copyToClipboard()"
      [matTooltip]="'Copy to clipboard' | translate"
      mat-icon-button
      color="primary"
      type="button">
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>

  <button
    mat-raised-button
    color="primary"
    (click)="ok()"
    cdkFocusInitial>
    <T str="Ok"></T>
  </button>
</div>
