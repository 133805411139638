import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Tenancy } from 'projects/apex/src/app/models/tenancy';
import { Subscription, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { TenancyService } from './tenancy.service';

@Component({
  selector: 'apex-case-tenancy-info',
  templateUrl: './case-info.component.html',
})
export class CaseTenancyInfoComponent implements OnInit, OnDestroy {
  @Input() tenancyId: number;

  tenancy: Tenancy;
  topLevelObjectId: number;

  private subscription = new Subscription();

  constructor(private service: TenancyService) {}

  ngOnInit(): void {
    if (this.tenancyId) {
      this.subscription.add(
        forkJoin({
          tenancy: this.service.get(this.tenancyId).pipe(map((res) => res.Entity)),
          objects: this.service.tree(this.tenancyId).pipe(map((res) => res.Collection)),
        }).subscribe({
          next: ({ tenancy, objects }) => {
            this.topLevelObjectId = objects.find((o) => !o.ParentId)?.id;
            this.tenancy = tenancy;
          },
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
