import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ChecklistGroup } from 'projects/apex/src/app/models/checklist-group';
import { Profile } from 'projects/apex/src/app/models/profile';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import { Subscription } from 'rxjs';
import { mergeMap, skipWhile } from 'rxjs/operators';
import { ChecklistBulkDeleteDialogComponent } from '../../../components/checklist-bulk-delete/checklist-bulk-delete-dialog.component';
import { t } from '../../../components/translate/translate.function';
import { snack, snackErr } from '../../../modules/snack.module';
import { ChecklistGroupService } from '../checklist-group.service';
import { ChecklistGroupTreeComponent } from '../tree/tree.component';

@Component({
  selector: 'apex-checklist-group-form-page',
  templateUrl: './form.component.html',
})
export class ChecklistGroupFormPageComponent implements OnInit, OnDestroy {
  @ViewChild(ChecklistGroupTreeComponent) tree: ChecklistGroupTreeComponent;

  group: ChecklistGroup;

  profile: Profile;

  subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private us: UserService,
    private cgs: ChecklistGroupService,
  ) {}

  ngOnInit(): void {
    this.group = this.route.snapshot.data.group;
    this.profile = this.route.snapshot.data.profile;
    this.route.data.subscribe({
      next: (data: { group: ChecklistGroup }) => {
        this.group = data.group;
      },
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  deleteGroup(): void {
    const sub = this.dialog
      .open(ChecklistBulkDeleteDialogComponent, {
        data: {
          type: 'checklistGroup',
          selectedChecklists: [this.group],
        },
      })
      .afterClosed()
      .pipe(
        skipWhile((res: { del: boolean; completeAndArchiveCheck: boolean }) => !res?.del),
        mergeMap((res: { del: boolean; completeAndArchiveCheck: boolean }) =>
          this.cgs.bulkDelete([this.group], res.completeAndArchiveCheck),
        ),
      )
      .subscribe({
        next: () => {
          snack(t('Deleted'));

          void this.router.navigate(['/checklist-group']);
        },
        error: (err) => {
          snackErr(t('Could not delete'), err);
        },
      });

    this.subscription.add(sub);
  }

  get canEdit(): boolean {
    return (this.profile?.id && this.us.isAdmin(this.profile)) || this.profile?.id === this.group?.CaseManagerId;
  }

  expandAll(): void {
    this.tree.expandAll();
  }

  collapseAll(): void {
    this.tree.collapseAll();
  }
}
