import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '../../../../components/translate/translate.module';
import { ExtendedProjectInvolvedUserType, ProjectInvolvedService } from '../../project-involved.service';
import { InvolvedCardComponent } from './card/project-involved-card.component';

@Component({
  selector: 'apex-project-involved-cards',
  templateUrl: './project-involved-cards.component.html',
  standalone: true,
  imports: [InvolvedCardComponent, CommonModule, TranslateModule],
})
export class InvolvedCardsComponent implements OnInit {
  constructor(protected projectInvolvedService: ProjectInvolvedService) {}

  otherFavorites: ExtendedProjectInvolvedUserType[] = [];

  ngOnInit(): void {
    this.otherFavorites = this.projectInvolvedService.otherUserFavorites.filter((user) => user.modelName === 'user');
  }

  noUsersFound(): boolean {
    return (
      this.projectInvolvedService.viewUsers().length === 0 &&
      !this.projectInvolvedService.selectedRoles().includes('favorite')
    );
  }
}
