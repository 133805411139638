import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { BookmarkPinButtonsComponent } from '../../components/bookmark-pin-buttons/bookmark-pin-buttons.component';
import { PageInfoModule } from '../../components/page-info/page-info.module';
import { QrCodeModule } from '../../components/qr-code/qr-code.module';
import { TranslateModule } from '../../components/translate/translate.module';
import { BookmarkFolderComponent } from './bookmark-folder.component';
import { BookmarkRoutingModule } from './bookmark-routing.module';
import { BookmarkService } from './bookmark.service';
import { BookmarkButtonComponent } from './button.component';
import { BookmarkManageComponent } from './manage.component';
import { BookmarkPinnedOrNotPipe } from './pipes/pinned-bookmark.pipe';

@NgModule({
  declarations: [BookmarkButtonComponent, BookmarkFolderComponent, BookmarkManageComponent],
  exports: [BookmarkButtonComponent],
  imports: [
    CommonModule,
    RouterModule,

    BookmarkRoutingModule,

    FormsModule,

    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    MatListModule,
    MatInputModule,
    MatTreeModule,
    TranslateModule,
    PageInfoModule,
    QrCodeModule,
    BookmarkPinnedOrNotPipe,
    BookmarkPinButtonsComponent,
  ],
  providers: [BookmarkService],
})
export class BookmarkModule {}
