import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep, uniq } from 'lodash-es';
import { ChecklistTemplateService } from 'projects/apex/src/app/features/checklist-template/checklist-template.service';
import { ChecklistItem } from 'projects/apex/src/app/features/checklist/checklist.model';
import { InfoPageData } from 'projects/apex/src/app/features/inspection/info/info.types';
import { CommercialObject } from 'projects/apex/src/app/features/object/project/project.model';
import { Inspection } from 'projects/apex/src/app/models/inspection';
import { APIService } from 'projects/apex/src/app/services/http/http.service';
import { CollectionResponse } from 'projects/apex/src/app/utils/types';
import { EMPTY, Observable, forkJoin, from, merge, of } from 'rxjs';
import { catchError, concatMap, expand, finalize, map, mergeMap, take, tap, toArray } from 'rxjs/operators';
import { AutocompleteService } from '../../components/autocomplete/autocomplete.service';
import { AutocompleteTypes } from '../../components/autocomplete/autocomplete.types';
import { FileUsageService } from '../../components/file-usage/file-usage.service';
import { FileService } from '../../components/file-usage/file.service';
import { t } from '../../components/translate/translate.function';
import { Apartment } from '../../models/apartment';
import { Case } from '../../models/case';
import { CaseCategory } from '../../models/case-category';
import { ChecklistGroupTemplate } from '../../models/checklist-group-template';
import { Field } from '../../models/field';
import { File as ApexFile } from '../../models/file';
import { FileUsage } from '../../models/file-usage';
import { Marking } from '../../models/marking';
import { ObjectField } from '../../models/object-field';
import { Project } from '../../models/project';
import { Tag } from '../../models/tag';
import { User } from '../../models/user';
import { MarkingService } from '../../services/marking/marking.service';
import { UserService } from '../../services/user/user.service';
import { CaseCategoryService } from '../case/case-category.service';
import { CaseService } from '../case/case.service';
import { ChecklistGroupTemplateService } from '../checklist-group-template/checklist-group-template.service';
import {
  ChecklistTemplate,
  ChecklistTemplateItem,
  ChecklistTemplateSection,
} from '../checklist-template/checklist-template.model';
import {
  ChecklistTemplateItemService,
  ChecklistTemplateSectionService,
} from '../checklist-template/checklist-template.service';
import { ClientService } from '../client/client.service';
import { FieldService } from '../field/field.service';
import { Obj } from '../object/object.model';
import { ObjectService } from '../object/object.service';
import { ObjectFieldService } from '../object/project/field/object-field.service';
import { ProjectService } from '../project/project.service';
import { InspectionDexie } from './inspection.dexie';

export const uuidRegEx = '([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})';

interface ChecklistTemplateData {
  groups: ChecklistGroupTemplate[];
  checklists: ChecklistTemplate[];
  sections: ChecklistTemplateSection[];
  items: ChecklistTemplateItem[];
}

interface OfflineData {
  project: Project;
  object: Obj;
  caseCategories: CaseCategory[];
  fields: Field[];
  fileUsages: FileUsage[];
  caseManager: User;
  client: User;
  checklistData: ChecklistTemplateData;
  objects: Obj[];
  objectFields: ObjectField[];
  tags: Tag[];
}

@Injectable()
export class BaseInspectionService extends APIService<Inspection> {
  route = 'inspection';

  queryForProject(projectId: number, page: number, limit: number): Observable<CollectionResponse<Inspection>> {
    const url = `${this.apiUrl}/project/${projectId}/inspection`;

    return this.http.get<CollectionResponse<Inspection>>(
      url,
      this.options({
        params: {
          page: String(page),
          limit: String(limit),
        },
      }),
    );
  }

  queryForApartment(
    projectId: number,
    apartmentId: number,
    page: number,
    limit: number,
  ): Observable<CollectionResponse<Inspection>> {
    const url = `${this.apiUrl}/project/${projectId}/apartment/${apartmentId}/inspection`;

    return this.http.get<CollectionResponse<Inspection>>(
      url,
      this.options({
        params: {
          page: String(page),
          limit: String(limit),
        },
      }),
    );
  }

  queryForObject(objectId: number, page: number, limit: number): Observable<CollectionResponse<Inspection>> {
    const url = `${this.apiUrl}/object/${objectId}/inspection`;

    return this.http.get<CollectionResponse<Inspection>>(
      url,
      this.options({
        params: {
          page: String(page),
          limit: String(limit),
        },
      }),
    );
  }
}

@Injectable()
export class InspectionService extends BaseInspectionService {
  route = 'inspection';
  currentInspectionId: number;

  sw = false;

  offline = false;
  savingOfflineData = false;

  savingInspection: number;

  dexie = new InspectionDexie();

  dexieIdString = 'dexieId';
  fileString = 'file';

  constructor(
    protected http: HttpClient,
    private projectService: ProjectService,
    private userService: UserService,
    private clientService: ClientService,
    private caseService: CaseService,
    private caseCategoryService: CaseCategoryService,
    private fileUsageService: FileUsageService,
    private fileService: FileService,
    private fieldService: FieldService,
    private objectFieldService: ObjectFieldService,
    private markingService: MarkingService,
    private objectService: ObjectService,
    private checklistGroupTemplateService: ChecklistGroupTemplateService,
    private checklistTemplateService: ChecklistTemplateService,
    private checklistTemplateItemService: ChecklistTemplateItemService,
    private checklistTemplateSectionService: ChecklistTemplateSectionService,
    private autocompleteService: AutocompleteService,
  ) {
    super(http);
    void this.dexie.Projects.toArray().then((projects) => {
      if (projects?.length) {
        this.offline = true;
      }
    });
    void this.dexie.Objects.toArray().then((objects) => {
      if (objects?.length) {
        this.offline = true;
      }
    });
  }

  setOfflineMode(value: boolean, data?: InfoPageData): void {
    this.offline = value;

    if (this.offline) {
      this.savingOfflineData = true;
      this.setOffline(data)
        .pipe(take(1))
        .subscribe({
          error: () => {
            this.savingOfflineData = false;
            this.offline = false;
          },
          complete: () => {
            this.savingOfflineData = false;
          },
        });
    } else {
      this.clearOfflineDataInDexie()
        .pipe(take(1))
        .subscribe({
          error: () => {
            this.savingOfflineData = false;
          },
          complete: () => {
            this.savingOfflineData = false;
          },
        });
    }
  }

  setOffline(data: InfoPageData): Observable<unknown> {
    return this.getOfflineData(data).pipe(mergeMap((d: OfflineData) => this.setOfflineDataInDexie(d)));
  }

  getChecklistTemplates(id: number): Observable<ChecklistTemplateData> {
    return forkJoin({
      groups: of([]),
      checklists: this.checklistTemplateService.get(id).pipe(map((e) => [e.Entity])),
      sections: this.checklistTemplateSectionService.getSections(id),
      items: this.checklistTemplateItemService.getItems(id),
    });
  }

  getChecklistGroupTemplates(id: number): Observable<ChecklistTemplateData> {
    const data = {
      groups: [],
      checklists: [],
      sections: [],
      items: [],
    };

    return this.checklistGroupTemplateService.get(id).pipe(
      map((e) => e.Entity),
      mergeMap((group) => {
        data.groups.push(group);

        return merge(
          ...group.Checklists.map((c) => this.getChecklistTemplates(c.id)),
          ...group.Children.map((c) => this.getChecklistGroupTemplates(c.id)),
        );
      }),
      tap((d) => {
        data.groups = data.groups.concat(d.groups);
        data.sections = data.sections.concat(d.sections);
        data.items = data.items.concat(d.items);
      }),
      toArray(),
      map(() => data),
    );
  }

  getChecklistTemplateData(data: InfoPageData): Observable<ChecklistTemplateData> {
    if (data.ChecklistTemplate) {
      return this.getChecklistTemplates(data.ChecklistTemplate);
    }

    if (data.ChecklistGroupTemplate) {
      return this.getChecklistGroupTemplates(data.ChecklistGroupTemplate);
    }

    return of(null);
  }

  addChecklistTemplateDataToDexie(data: ChecklistTemplateData): Observable<number[]> {
    if (data) {
      return merge(
        from(this.dexie.ChecklistGroupTemplates.bulkAdd(data.groups)),
        from(this.dexie.ChecklistTemplates.bulkAdd(data.checklists)),
        from(this.dexie.ChecklistTemplateSections.bulkAdd(data.sections)),
        from(this.dexie.ChecklistTemplateItems.bulkAdd(data.items)),
      ).pipe(toArray());
    }

    return of([]);
  }

  getObjects(objectId: number): Observable<Obj[]> {
    return this.objectService.get<CommercialObject>(objectId).pipe(
      expand((o) => (o.ParentId ? this.objectService.get<CommercialObject>(o.ParentId) : EMPTY)),
      toArray(),
    );
  }

  getTags(data: InfoPageData): Observable<Tag[]> {
    let params = new HttpParams();

    if (data.Project) {
      params = params.append('Project', data.Project);
    }

    if (data.Object) {
      params = params.append('Object', data.Object);
    }

    return this.autocompleteService.queryString(AutocompleteTypes.Tag, params).pipe(
      map((autocompletes) =>
        autocompletes.map((autocomplete) => ({
          id: String(autocomplete.id),
          name: autocomplete.name,
          CustomerId: autocomplete.Parent?.id,
          Customer: autocomplete.Parent ?? null,
        })),
      ),
    );
  }

  getOfflineData(data: InfoPageData): Observable<OfflineData> {
    return forkJoin({
      project: data.Project ? this.projectService.get(data.Project).pipe(map((res) => res.Entity)) : of(null),
      object: data.Object ? this.objectService.get<Obj>(data.Object) : of(null),
      caseManager: data.InspectionManagerId ? this.userService.get(data.InspectionManagerId) : of(null),
      client: data.Client ? this.clientService.get(data.Client).pipe(map((res) => res.Entity)) : of(null),
      caseCategories: this.caseCategoryService
        .query()
        .pipe(
          map((caseCategories: CaseCategory[]) => caseCategories.map((cc) => Object.assign(cc, { name: t(cc.name) }))),
        ),
      fields: this.fieldService.query(),
      objectFields: data.Object ? this.objectFieldService.query(data.Object) : of(null),
      checklistData: this.getChecklistTemplateData(data),
      fileUsages: of([]),
      objects: data.Object ? this.getObjects(data.Object) : of([] as Obj[]),
      tags: this.getTags(data),
    }).pipe(
      mergeMap((res) => {
        const objectIds: number[] = [];

        if (res.object) {
          objectIds.push(res.object.id);
        }

        if (res.objects) {
          objectIds.push(...res.objects.map((o) => o.id));
        }

        return forkJoin(
          [].concat(
            res.project ? [this.fileUsageService.all('project', res.project.id, 'floorplans')] : [],
            res.project
              ? res.project.Apartments.map((a: Apartment) => this.fileUsageService.all('apartment', a.id, 'floorplans'))
              : [],
            objectIds.length > 0
              ? objectIds.map((objectId) => this.fileUsageService.all('object', objectId, 'floorplans').pipe(take(1)))
              : [],
            res.checklistData?.items?.length
              ? res.checklistData.items.map((i) => this.fileUsageService.all('checklist-template-item', i.id, 'files'))
              : [],
          ),
        ).pipe(
          map((arrays: FileUsage[][]) => [].concat(...arrays)),
          mergeMap((fileUsages: FileUsage[]) =>
            fileUsages.length ? forkJoin(fileUsages.map((f) => this.saveFileAsBlob(f))) : of([]),
          ),
          mergeMap((fileUsages: FileUsage[]) => {
            fileUsages = fileUsages.filter((f) => f);
            fileUsages.forEach((f) => {
              f.isNew = true;
            });

            res.fileUsages = fileUsages.filter((f) => f);

            return of(res);
          }),
        );
      }),
    );
  }

  getOfflineDataObject(
    ObjectId: number,
  ): Observable<{ object: Obj; caseCategories: CaseCategory[]; fileUsages: FileUsage[] }> {
    return forkJoin([
      this.objectService.get<Obj>(ObjectId),
      this.caseCategoryService.query().pipe(
        // @note cc.name translations is handled by caseCategories
        map((caseCategories: CaseCategory[]) => caseCategories.map((cc) => Object.assign(cc, { name: t(cc.name) }))),
      ),
    ]).pipe(
      map(([object, caseCategories]) => ({
        object,
        caseCategories,
        fileUsages: [],
      })),
    );
  }

  saveLocalInspection(inspection: Inspection): Observable<Inspection> {
    return this.userService.filteredProfile$.pipe(
      mergeMap((user: User) => {
        inspection.UserId = user.id;
        inspection.User = user;

        if (inspection.id) {
          return from(this.dexie.Inspections.put(inspection)).pipe(
            mergeMap((id: number) => from(this.dexie.Inspections.get(id))),
          );
        } else {
          return from(this.dexie.Inspections.add(inspection)).pipe(
            mergeMap((id: number) => from(this.dexie.Inspections.get(id))),
          );
        }
      }),
    );
  }

  clearOfflineDataInDexie(): Observable<unknown> {
    return from(
      this.dexie.FileUsages.where('self').anyOf('project', 'apartment', 'checklist-template-item').toArray(),
    ).pipe(
      mergeMap((fileUsages: FileUsage[]) =>
        merge(
          from(this.dexie.Profile.clear()),
          from(this.dexie.Projects.clear()),
          from(this.dexie.Objects.clear()),
          from(this.dexie.CaseCategories.clear()),
          from(this.dexie.Fields.clear()),
          from(this.dexie.ObjectFields.clear()),
          from(
            this.dexie.Files.where('id')
              .anyOf(fileUsages.map((f) => f.FileId))
              .delete(),
          ),
          from(this.dexie.FileUsages.where('self').anyOf('project', 'apartment', 'checklist-template-item').delete()),
          from(this.dexie.ChecklistGroupTemplates.clear()),
          from(this.dexie.ChecklistTemplates.clear()),
          from(this.dexie.ChecklistTemplateSections.clear()),
          from(this.dexie.ChecklistTemplateItems.clear()),
          from(this.dexie.ObjectFields.clear()),
          from(this.dexie.Tags.clear()),
        ),
      ),
    );
  }

  deleteInspectionChecklistData(inspection: Inspection): Observable<unknown[]> {
    if (inspection.data?.checklistData?.items) {
      return merge(
        ...inspection.data.checklistData.items.map((i) =>
          forkJoin({
            cases: from(this.dexie.Cases.bulkDelete(i.Cases?.map((c) => c.id) ?? [])),
            case: from(this.dexie.Cases.delete(i.CaseId)),
            fileUsages: from(
              this.dexie.FileUsages.where({
                self: 'case',
                selfId: String(i.CaseId),
                name: 'files',
              }).toArray(),
            ),
          }),
        ),
      ).pipe(
        mergeMap((res) => {
          if (res.fileUsages?.length) {
            return forkJoin({
              fileUsages: from(this.dexie.FileUsages.bulkDelete(res.fileUsages.map((fu) => fu.id))),
              files: from(this.dexie.Files.bulkDelete(res.fileUsages.map((fu) => fu.FileId))),
            });
          }

          return of(null);
        }),
        toArray(),
      );
    }

    return of([]);
  }

  setOfflineDataInDexie(data: OfflineData): Observable<unknown> {
    return this.userService.filteredProfile$.pipe(
      mergeMap((profile) =>
        merge(
          from(this.dexie.Profile.add(profile)),
          from(this.dexie.CaseCategories.bulkAdd(data.caseCategories)),
          from(this.dexie.Fields.bulkAdd(data.fields)),
          from(this.dexie.Files.bulkAdd(data.fileUsages.map((fu2: FileUsage) => fu2.File))),
          from(this.dexie.FileUsages.bulkAdd(data.fileUsages)),
          from(this.dexie.Tags.bulkAdd(data.tags)),
          data.project ? from(this.dexie.Projects.add(data.project)) : of(null),
          data.object ? from(this.dexie.Objects.add(data.object)) : of(null),
          data.objects?.length ? from(this.dexie.Objects.bulkAdd(data.objects)) : of(null),
          data.caseManager ? from(this.dexie.Users.add(data.caseManager)) : of(null),
          data.client ? from(this.dexie.Clients.add(data.client)) : of(null),
          data.object ? from(this.dexie.ObjectFields.bulkAdd(data.objectFields)) : of(null),
          this.addChecklistTemplateDataToDexie(data.checklistData),
        ),
      ),
    );
  }

  saveFileAsBlob(fileUsage: FileUsage): Observable<FileUsage> {
    return this.http.get(fileUsage.File.signed.url, { responseType: 'blob' }).pipe(
      map((response) => {
        fileUsage.File[this.fileString] = response;

        return fileUsage;
      }),
      catchError(() => of(undefined)),
    );
  }

  completeInspection(inspectionToComplete: Inspection): Observable<Inspection> {
    this.savingInspection = inspectionToComplete.id;

    const inspection = cloneDeep(inspectionToComplete);
    const inspectionId = inspection.id;

    let projectFloorPlans$: Observable<FileUsage[]> = of([]);
    let apartmentFloorPlans$: Observable<FileUsage[]> = of([]);

    if (inspection.ProjectId) {
      projectFloorPlans$ = this.fileUsageService.all('project', inspection.ProjectId, 'floorplans');
    }

    if (inspection.ApartmentId) {
      apartmentFloorPlans$ = this.fileUsageService.all('apartment', inspection.ApartmentId, 'floorplans');
    }

    return merge(projectFloorPlans$, apartmentFloorPlans$).pipe(
      mergeMap((f) => from(f)),
      toArray(),
      mergeMap((fileUsages) =>
        this.saveInspectionCases(inspectionId, fileUsages, inspectionToComplete?.data?.checklistData?.items),
      ),
      mergeMap((cases: Case[]) => {
        // if we are offline deviations are set in inspection.data.checklistData.items.Cases,
        // online inspection.data.checklistData is undefined
        const deviationIds =
          inspection.data?.checklistData?.items?.flatMap((item) => item.Cases?.map((c) => c.id) ?? []) ?? [];
        const deviationIdsSet = new Set(deviationIds);

        // when we are offline this will remove deviations from inspection.data.caseIds,
        // online it will have no effect since deviationIdsSet is empty
        inspection.data.caseIds = inspection.data.caseIds.filter((caseId) => !deviationIdsSet.has(caseId));

        // when we are online this array will be empty,
        // when we are offline it will be the cases that were saved in last step
        const savedCaseIds = cases.map((c) => c.id);

        // when online this array will be inspection.caseIds unchanged,
        // when offline it will be inspection.caseIds without deviation ids and then savedCases added
        inspection.data.caseIds = uniq([...inspection.data.caseIds, ...savedCaseIds]);

        return inspection.data?.Files ? this.saveInspectionFiles(inspection) : of([]);
      }),
      mergeMap((files: ApexFile[]) => {
        delete inspection.data.Cases;
        inspection.data.Files = files.map((f) => ({ id: f.id }) as ApexFile);

        return inspection.data?.checklistData?.items?.length
          ? this.moveChecklistCasesToItems(inspection.data.checklistData.items)
          : of([]);
      }),
      mergeMap((items: ChecklistItem[]) => {
        if (inspection.data?.checklistData?.items?.length) {
          inspection.data.checklistData.items = items;
        }

        return this.save(Object.assign(inspection, { id: undefined }));
      }),
      map((res) => res.Entity),
      mergeMap((ins: Inspection) =>
        merge(
          this.deleteInspectionChecklistData(inspectionToComplete),
          from(this.dexie.Inspections.delete(inspectionId)),
        ).pipe(
          toArray(),
          map(() => ins),
        ),
      ),
      finalize(() => {
        this.savingInspection = null;
      }),
    );
  }

  saveChecklistItemFiles(caseId: number): Observable<FileUsage[]> {
    return from(
      this.dexie.FileUsages.where({
        self: 'case',
        selfId: String(caseId),
        name: 'files',
      }).toArray(),
    ).pipe(
      mergeMap((fileUsages: FileUsage[]) => {
        if (!fileUsages?.length) {
          return of([]);
        }

        return merge(
          ...fileUsages.map((fileUsage) =>
            from(this.dexie.Files.get(fileUsage.FileId)).pipe(
              mergeMap((f) => this.fileService.sign(f.file as File)),
              mergeMap((d) => this.fileService.upload(d.signedData, d.file)),
              mergeMap((f) => {
                f.name = fileUsage.fileName;

                return this.fileService.save(f);
              }),
              map((f) => {
                fileUsage.FileId = f.id;
                fileUsage.File = f;

                return fileUsage;
              }),
            ),
          ),
        ).pipe(toArray());
      }),
    );
  }

  saveDeviationFiles(item: ChecklistItem): Observable<FileUsage[]> {
    const { Cases: deviations } = item;

    const deviationIds = deviations?.map((deviation) => deviation.id) ?? null;

    if (deviationIds) {
      return merge(...deviationIds.map((deviationId) => this.saveChecklistItemFiles(deviationId)));
    }

    return of([] as FileUsage[]);
  }

  moveChecklistCasesToItems(items: ChecklistItem[]): Observable<ChecklistItem[]> {
    return merge(
      ...items.map((i) =>
        forkJoin({
          item: of(i),
          case: from(this.dexie.Cases.get(i.CaseId)).pipe(
            map((caze) => ({
              ...caze,
              ChecklistId: null,
            })),
          ),
          cases: i.Cases?.length ? from(this.dexie.Cases.bulkGet(i.Cases.map((c) => c.id))) : of([]),
          fileUsages: this.saveChecklistItemFiles(i.CaseId),
          deviationFileUsages: this.saveDeviationFiles(i),
        }),
      ),
    ).pipe(
      map((res) => {
        res.item.Case = res.case;
        res.item.Cases = res.cases;

        if (res.item.Case) {
          delete res.item.Case.id;

          res.item.Case.FileUsages = res.fileUsages;
        }

        res.item.Cases?.forEach((deviation, i) => {
          res.item.Cases[i].FileUsages = res.deviationFileUsages.filter(
            (fileUsages) => String(fileUsages.selfId) === String(deviation.id),
          );
        });

        return res.item;
      }),
      toArray(),
    );
  }

  filterItemCases(caze: Case, items?: ChecklistItem[]): boolean {
    const caseIsItemCase = items?.some((item) => item.CaseId === caze.id || item.Case?.id === caze.id) ?? false;
    const caseIsDeviation = items?.some((item) => item.Cases?.some((deviation) => deviation.id === caze.id)) ?? false;

    return !caseIsItemCase && !caseIsDeviation;
  }

  saveInspectionCases(InspectionId: number, fileUsages: FileUsage[], items?: ChecklistItem[]): Observable<Case[]> {
    return from(this.dexie.Cases.where('InspectionId').equals(InspectionId).toArray()).pipe(
      map((cases) => cases.filter((caze) => this.filterItemCases(caze, items))),
      mergeMap((cases) => from(cases)),
      concatMap((c) => this.saveInspectionCase(c)),
      mergeMap((c) => this.saveInspectionCaseFiles(c)),
      mergeMap((c) => this.saveInspectionCaseMarkings(c, fileUsages)),
      toArray(),
      mergeMap((cases) =>
        from(
          this.dexie.Cases.where('InspectionId')
            .equals(InspectionId)
            .and((caze) => this.filterItemCases(caze, items))
            .delete(),
        ).pipe(map(() => cases)),
      ),
    );
  }

  saveInspectionCaseFiles(c: Case): Observable<Case> {
    return from(
      this.dexie.FileUsages.where({
        self: 'case',
        selfId: String(c[this.dexieIdString]),
        name: 'files',
      }).toArray(),
    ).pipe(
      mergeMap((fileUsages: FileUsage[]) =>
        fileUsages?.length
          ? forkJoin(
              fileUsages.map((fu: FileUsage) => {
                const dexieFileId = fu.FileId;

                fu.id = null;

                return from(this.dexie.Files.get(fu.FileId)).pipe(
                  mergeMap((f: ApexFile) => (f ? this.fileService.sign(f.file as File) : of(null))),
                  mergeMap((d) => (d ? this.fileService.upload(d.signedData, d.file) : of(null))),
                  mergeMap((f: ApexFile) => (f ? this.fileService.save(f) : of(null))),
                  mergeMap((f: ApexFile) => {
                    if (!f) {
                      console.error(fu.selfId, fu.FileId, c.id, c.name, c.Apartment.name);

                      return EMPTY;
                    }

                    fu.File = f;

                    if (f) {
                      fu.FileId = f.id;
                    }

                    fu.selfId = String(c.id);

                    return this.fileUsageService.save(fu);
                  }),
                  mergeMap(() => (dexieFileId ? from(this.dexie.Files.delete(dexieFileId)) : of(null))),
                );
              }),
            )
          : of([]),
      ),
      mergeMap(() =>
        from(
          this.dexie.FileUsages.where({
            self: 'case',
            selfId: String(c.id),
            name: 'files',
          }).delete(),
        ).pipe(map(() => c)),
      ),
    );
  }

  saveInspectionCaseMarkings(c: Case, fileUsages: FileUsage[]): Observable<Case> {
    const markingsObservable = c.Markings.map((m) => {
      const fileUsage = fileUsages.find((fu) => fu.id === m.FileUsageId);

      return fileUsage
        ? this.markingService.saveMarking(fileUsage, Object.assign(m, { modelId: c.id, id: null }))
        : of(m);
    });

    if (!markingsObservable.length) {
      return of(c);
    }

    return forkJoin(markingsObservable).pipe(
      mergeMap((marking: Marking[]) => {
        c.Markings = marking;

        return of(c);
      }),
    );
  }

  saveInspectionCase(c: Case): Observable<Case> {
    const dexieId = c.id;

    c.id = undefined;
    c.deadline = c.deadline * 1000;

    if (c.FileUsages) {
      delete c.FileUsages;
    }

    if (c.Tags?.length) {
      this.parseTagsForCaseSave(c);
    }

    return this.caseService.save(c).pipe(
      mergeMap((savedCase: Case) =>
        c.Contractors?.length
          ? forkJoin(
              c.Contractors.map((contractor) => this.caseService.addContractor(savedCase.id, contractor.id)),
            ).pipe(
              map(() => {
                savedCase.Contractors.concat(c.Contractors);

                return savedCase;
              }),
            )
          : of(savedCase),
      ),
      map((savedCase: Case) => {
        savedCase[this.dexieIdString] = dexieId;

        return savedCase;
      }),
    );
  }

  saveInspectionFiles(inspection: Inspection): Observable<ApexFile[]> {
    return from(
      this.dexie.Files.where('id')
        .anyOf(inspection.data.Files.map((f) => f.id))
        .toArray(),
    ).pipe(
      mergeMap((files: ApexFile[]) =>
        files?.length
          ? forkJoin(
              // @todo Fix this
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              files.map((file: any) =>
                this.fileService.sign(file.file).pipe(
                  mergeMap((d) => this.fileService.upload(d.signedData, d.file)),
                  mergeMap((f) => {
                    f.name = file.name;

                    return this.fileService.save(f);
                  }),
                ),
              ),
            )
          : of([]),
      ),
      mergeMap((files: ApexFile[]) =>
        from(
          this.dexie.Files.where('id')
            .anyOf(inspection.data.Files.map((f) => f.id))
            .toArray(),
        ).pipe(
          mergeMap((savedFiles: ApexFile[]) => {
            inspection.data.Files = inspection.data.Files.filter((file) => !savedFiles.find((f) => f.id === file.id));

            return from(
              this.dexie.Files.where('id')
                .anyOf(savedFiles.map((f) => f.id))
                .delete(),
            ).pipe(map(() => files));
          }),
        ),
      ),
      mergeMap((files: ApexFile[]) => of(inspection.data.Files.concat(files))),
    );
  }

  private parseTagsForCaseSave(postCase: Case): void {
    const tagsWithNoId =
      postCase.Tags?.filter((tag) => !tag.id?.match(uuidRegEx)).map(({ name }) => ({ id: null, name })) ?? [];
    const tagsWithId = postCase.Tags?.filter((tag) => tag.id?.match(uuidRegEx));

    postCase.Tags = [...tagsWithNoId, ...tagsWithId];
  }
}
