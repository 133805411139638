import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyConsentReminderDialogData } from './company-consent-reminder-dialog.data';

@Component({
  templateUrl: './company-consent-reminder-dialog.component.html',
})
export class CompanyConsentReminderDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: CompanyConsentReminderDialogData) {}
}
