import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute } from '@angular/router';
import { ChecklistGroupEntity } from 'projects/apex/src/app/models/checklist-group';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'apex-checklist-group-list',
  templateUrl: './list.component.html',
})
export class ChecklistGroupListComponent {
  @Input() groups: ChecklistGroupEntity[] = [];
  @Input() hasCheckboxes = false;

  @Output() selectedChange: EventEmitter<ChecklistGroupEntity[]> = new EventEmitter<ChecklistGroupEntity[]>();

  searchInput$: Observable<string>;

  constructor(private route: ActivatedRoute) {
    this.searchInput$ = this.route.queryParams.pipe(map((params) => params.checklistGroupSearch ?? ''));
  }

  toggleChecklist(group: ChecklistGroupEntity, event: MatCheckboxChange): void {
    group.selected = event.checked;
    this.selectedChange.emit(this.groups.filter((c) => c.selected));
  }
}
