import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { orderBy } from 'lodash-es';
import { ChecklistGroupService } from 'projects/apex/src/app/features/checklist-group/checklist-group.service';
import { ChecklistGroupChecklistComponent } from 'projects/apex/src/app/features/checklist-group/checklist/checklist.component';
import { ChecklistGroupChecklistDialogComponent } from 'projects/apex/src/app/features/checklist-group/checklist/dialog.component';
import { Checklist } from 'projects/apex/src/app/features/checklist/checklist.model';
import { ChecklistGroup } from 'projects/apex/src/app/models/checklist-group';
import { User } from 'projects/apex/src/app/models/user';
import { UserService } from 'projects/apex/src/app/services/user/user.service';

@Component({
  selector: 'apex-checklist-group-tree',
  templateUrl: './tree.component.html',
})
export class ChecklistGroupTreeComponent implements OnInit, OnChanges {
  @Input() group: ChecklistGroup;
  @Input() step = 0;
  @Input() hideHeader: boolean;
  @Input() showItems: boolean;
  @Input() expanded = true;

  @Output() deleteComplete = new EventEmitter<{ group: ChecklistGroup; status: string }>();
  @Output() countChange = new EventEmitter();

  @ViewChildren(ChecklistGroupChecklistComponent) checklistComponents: QueryList<ChecklistGroupChecklistComponent>;
  // ForwardRef here because it refers to itself.
  @ViewChildren(forwardRef(() => ChecklistGroupTreeComponent))
  childrenComponents: QueryList<ChecklistGroupTreeComponent>;

  viewGroups: ChecklistGroup[] = [];
  isAdmin: boolean;

  constructor(
    private dialog: MatDialog,
    private us: UserService,
    private cgs: ChecklistGroupService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.us.filteredProfile$.subscribe({
      next: (user: User) => {
        this.isAdmin = this.us.isAdmin(user);
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.group) {
      this.sort();
    }
  }

  openChecklist(checklist: Checklist): void {
    this.dialog
      .open(ChecklistGroupChecklistDialogComponent, { data: { checklist } })
      .afterClosed()
      .subscribe({
        next: () => {
          this.updateCount();
        },
      });
  }

  updateCount(): void {
    this.group.caseCount = this.caseCount;
    this.group.casesCompleted = this.casesCompleted;
    this.countChange.emit();
  }

  sort(): void {
    if (this.group) {
      this.group.Children = orderBy(
        this.group.Children,
        [(c): number => c.sortOrder, (c): number => c.ChecklistGroupTemplateId],
        ['asc', 'desc'],
      );
      this.group.Checklists = orderBy(
        this.group.Checklists,
        [(c): number => c.ChecklistGroupChecklist?.sortOrder, (c): number => c.ChecklistGroupChecklist?.TemplateId],
        ['asc', 'desc'],
      );
    }
  }

  get caseCount(): number {
    let count = 0;

    if (this.group?.Checklists?.length) {
      count += this.group.Checklists.map((c) => c.caseCount).reduce((a, b) => a + b);
    }

    if (this.childrenComponents?.length) {
      count += this.childrenComponents.map((c) => c.caseCount).reduce((a, b) => a + b);
    }

    return count;
  }

  get casesCompleted(): number {
    let count = 0;

    if (this.group?.Checklists?.length) {
      count += this.group.Checklists.map((c) => c.casesCompleted).reduce((a, b) => a + b);
    }

    if (this.childrenComponents?.length) {
      count += this.childrenComponents.map((c) => c.casesCompleted).reduce((a, b) => a + b);
    }

    return count;
  }

  expandAll(): void {
    this.childrenComponents.forEach((c) => {
      c.expanded = true;

      c.expandAll();
    });
  }

  collapseAll(): void {
    this.childrenComponents.forEach((c) => {
      c.expanded = false;

      c.collapseAll();
    });
  }
}
