<ng-container *ngIf="!cleared && uploadService?.list?.length">
  <header
    class="df r ac"
    [ngClass]="{ ongoing: hasOngoingUploads }">
    <span class="f1">
      <T
        str="Uploaded {completed} of {count} files"
        [vars]="{
          completed: successfulUploads,
          count: uploadService?.list?.length,
        }">
      </T>
    </span>
    <button
      mat-icon-button
      (click)="expanded = !expanded">
      <mat-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="cleared = true">
      <mat-icon>clear</mat-icon>
    </button>
  </header>
  <main *ngIf="expanded">
    <div
      *ngFor="let d of uploadService.list"
      class="row df">
      <div class="f1 df ac oh">
        <mat-icon class="amrs">{{ d.type === 'folder' ? 'folder' : 'insert_drive_file' }}</mat-icon>
        <span class="truncate">{{ d.name }}</span>
      </div>
      <div class="status df ac jc ams">
        <mat-icon *ngIf="d.status === UploadStatus.Pending">pending</mat-icon>
        <mat-spinner
          *ngIf="d.status === UploadStatus.Ongoing"
          diameter="24"></mat-spinner>
        <mat-icon
          *ngIf="d.status === UploadStatus.Success"
          color="primary"
          >check_circle
        </mat-icon>
        <mat-icon
          *ngIf="d.status === UploadStatus.Error"
          color="warn"
          >error
        </mat-icon>
      </div>
      <div class="action df ac jc">
        <button
          *ngIf="d.status === UploadStatus.Ongoing || d.status === UploadStatus.Pending"
          mat-icon-button
          (click)="uploadService.remove(d)">
          <mat-icon>cancel</mat-icon>
        </button>
        <button
          *ngIf="d.status === UploadStatus.Error"
          mat-icon-button
          (click)="uploadService.retry(d)">
          <mat-icon>refresh</mat-icon>
        </button>
        <button
          *ngIf="d.status === UploadStatus.Success"
          mat-icon-button
          [routerLink]="['folder', d.FolderId ? d.FolderId : 'upload']">
          <mat-icon>folder_open</mat-icon>
        </button>
      </div>
    </div>
  </main>
</ng-container>
