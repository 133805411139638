import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'isDateOverdue',
  standalone: true,
})
export default class IsDateOverduePipe implements PipeTransform {
  transform(value?: Date | number | string): boolean {
    if (!value) {
      return false;
    }

    return moment().isAfter(moment(new Date(value)));
  }
}
