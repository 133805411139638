import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'apex-star',
  template: '',
})
export default class DoubleStarComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const startpage = this.route?.snapshot?.data?.profile?.startpage ?? null;
    const url = startpage ?? '/case';
    const urlTree = this.router.parseUrl(url);

    void this.router.navigateByUrl(urlTree, { replaceUrl: true });
  }
}
