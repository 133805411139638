import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { ObjectInvolvedUser, ProjectInvolvedUser } from '../../../../../../../../generated/apex-rest';
import { t } from '../../../../components/translate/translate.function';
import { TranslateModule } from '../../../../components/translate/translate.module';
import { ProjectInvolvedService } from '../../project-involved.service';
import { ProjectInvolvedUserRole } from '../project-involved-user-role.type';
import { TranslateProjectInvolvedUserRolePipe } from '../translate-project-involved-user-role.pipe';
import { FilterOption } from './types/filter-option.type';
import { UsersForRolePipe } from './users-for-role.pipe';

const translateRoles: Record<ProjectInvolvedUserRole, () => string> = {
  [ProjectInvolvedUser.ProjectManager]: () => t('Project managers'),
  [ProjectInvolvedUser.Contractor]: () => t('Contractors'),
  [ProjectInvolvedUser.AddonManager]: () => t('Addon managers'),
  [ProjectInvolvedUser.CaseManager]: () => t('Case managers'),
  [ProjectInvolvedUser.UnwantedIncidentManager]: () => t('Unwanted incident managers'),
  [ObjectInvolvedUser.AgreementInvolved]: () => t('Agreements'),
  [ObjectInvolvedUser.Contact]: () => t('Contacts'),
  [ObjectInvolvedUser.Custodian]: () => t('Custodians'),
  favorite: () => t('Favorites'),
};

@Component({
  selector: 'apex-project-involved-filters',
  templateUrl: './project-involved-filters.component.html',
  standalone: true,
  imports: [MatChipsModule, CommonModule, TranslateModule, UsersForRolePipe, TranslateProjectInvolvedUserRolePipe],
})
export class ProjectInvolvedFiltersComponent implements OnInit {
  filterOptions: FilterOption[] = [];

  constructor(protected readonly projectInvolvedService: ProjectInvolvedService) {}

  ngOnInit(): void {
    this.filterOptions = Object.keys(translateRoles).map((role: ProjectInvolvedUserRole) => ({
      role,
      translated: translateRoles[role](),
    }));
  }
}
