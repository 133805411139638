import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChecklistSectionModule } from '../../../components/checklist-section/checklist-section.module';
import { ChecklistGroupChecklistComponent } from './checklist.component';
import { ChecklistGroupChecklistDialogComponent } from './dialog.component';

@NgModule({
  declarations: [ChecklistGroupChecklistComponent, ChecklistGroupChecklistDialogComponent],
  exports: [ChecklistGroupChecklistComponent, ChecklistGroupChecklistDialogComponent],
  imports: [
    CommonModule,
    ChecklistSectionModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class ChecklistGroupChecklistModule {}
