import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileUsageService } from 'projects/apex/src/app/components/file-usage/file-usage.service';
import { AddonApartment } from 'projects/apex/src/app/models/addon-apartment';
import { Apartment } from 'projects/apex/src/app/models/apartment';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { Marking } from 'projects/apex/src/app/models/marking';
import { HttpService } from 'projects/apex/src/app/services/http/http.service';
import { MarkingService } from 'projects/apex/src/app/services/marking/marking.service';
import { Observable } from 'rxjs';
import { HttpExtra } from '../../services/http/http-extra';
import { CollectionResponse } from '../../utils/types';

@Injectable()
export class ApartmentService extends HttpService<Apartment> {
  public route = 'apartment';

  constructor(
    protected http: HttpClient,
    private markingService: MarkingService,
    private fileUsageService: FileUsageService,
  ) {
    super(http);
  }

  queryClient(): Observable<Apartment[]> {
    return this.http.get<Apartment[]>(`${this.apiUrl}/client/${this.route}`, this.options);
  }

  getClient(ApartmentId: number): Observable<Apartment> {
    return this.http.get<Apartment>(`${this.apiUrl}/client/${this.route}/${ApartmentId}`, this.options);
  }

  getFloorplanImage(ApartmentId: number): Observable<FileUsage[]> {
    return this.fileUsageService.all(this.route, ApartmentId, 'floorplans');
  }

  getFloorplanAddonApartmentMarkings(addonApartment: AddonApartment, fileUsageId: number): Observable<Marking[]> {
    return this.markingService.getMarkings(
      'apartment',
      addonApartment.ApartmentId,
      'floorplans',
      'addon-apartment',
      addonApartment.id,
      fileUsageId,
    );
  }

  getAllFloorplanAddonApartmentMarkings(addonApartment: AddonApartment): Observable<Marking[]> {
    return this.markingService.getAllMarkings(
      'apartment',
      addonApartment.ApartmentId,
      'floorplans',
      'addon-apartment',
      addonApartment.id,
    );
  }

  queryAsClient(extra?: HttpExtra): Observable<CollectionResponse<Apartment>> {
    const options = this.options;

    if (extra?.params) {
      options.params = extra.params;
    }

    return this.http.get<CollectionResponse<Apartment>>(`${this.apiUrl}/apartment/as-client`, options);
  }
}
