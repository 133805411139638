import { NgModule } from '@angular/core';

import { PDFViewerComponent } from './pdf-viewer.component';

@NgModule({
  imports: [],
  exports: [PDFViewerComponent],
  declarations: [PDFViewerComponent],
  providers: [],
})
export class PDFViewerModule {}
