import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FileUsageService } from 'projects/apex/src/app/components/file-usage/file-usage.service';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { Subscription, fromEvent, noop } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { sortFileUsages } from '../../utils/functions';
import { ImageFit } from '../file-preview/file-preview.utils';

// import { sortFileUsages } from '../../utils/functions';

interface LazyFileUsage extends FileUsage {
  lazy?: boolean;
}

@Component({
  selector: 'apex-file-usage-preview',
  templateUrl: './file-usage-preview.component.html',
})
export class FileUsagePreviewComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() fileUsages: LazyFileUsage[];
  @Input() displayedImages = 1;
  @Input() fit: ImageFit = 'cover';
  @Input() viewIndex = 0;
  @Input() selectedIndex = 0;
  @Input() navigation = true;
  @Input() showCaption = false;

  @Input() self: string;
  @Input() selfId: number;
  @Input() name: string;
  @Output() viewIndexChanged = new EventEmitter<number>();
  @Output() selectedIndexChanged = new EventEmitter<number>();

  @ViewChild('fileUsageList') fileUsageList: ElementRef;

  swiping = false;
  inView = false;

  size: { width: number; height: number };

  private subscription: Subscription;

  constructor(private fileUsageService: FileUsageService) {}

  ngAfterViewInit(): void {
    if (this.fileUsageList) {
      if (this.navigation) {
        this.subscription = fromEvent(this.fileUsageList.nativeElement, 'mouseover')
          .pipe(
            mergeMap(() =>
              fromEvent(this.fileUsageList.nativeElement, 'wheel').pipe(
                map((e: WheelEvent) => {
                  const isScrollingDown = e.deltaY > 0;

                  if (
                    (isScrollingDown && this.selectedIndex !== (this.fileUsages?.length ?? 0) - 1) ||
                    (!isScrollingDown && this.selectedIndex !== 0 && this.navigation)
                  ) {
                    e.stopPropagation();
                    e.preventDefault();
                  }

                  return isScrollingDown;
                }),
                takeUntil(fromEvent(this.fileUsageList.nativeElement, 'mouseout')),
              ),
            ),
          )
          .subscribe((value: boolean) => {
            this.setSelectedIndex(this.selectedIndex + (value ? 1 : -1));
          });
      }

      setTimeout(() => {
        this.resize();
      }, 50);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.swiping) {
      this.swiping = false;
    }

    if (changes.fileUsages) {
      this.fileUsages = sortFileUsages(this.fileUsages);

      if (changes.fileUsages.previousValue) {
        changes.fileUsages.currentValue.forEach((ful: LazyFileUsage) => {
          const oldFileUsage = changes.fileUsages.previousValue.find((fu: LazyFileUsage) => fu.id === ful.id);

          if (oldFileUsage) {
            ful.lazy = oldFileUsage.lazy;
          }
        });
      } else {
        this.fileUsages?.forEach((f) => {
          f.lazy = true;
        });
        this.setLazyImages();
      }
    }

    if (changes.self || changes.selfId || changes.name) {
      if (this.inView) {
        this.getFileUsages();
      }
    }
  }

  onInView(isInView: boolean): void {
    if (isInView && !this.inView) {
      this.inView = true;

      this.getFileUsages();
    }
  }

  getFileUsages(): void {
    if (this.self && this.selfId && this.name) {
      this.fileUsageService.all(this.self, this.selfId, this.name).subscribe({
        next: (fileUsages: FileUsage[]) => {
          this.fileUsages = fileUsages;
        },
      });
    }
  }

  resize(): void {
    this.size = {
      width: this.fileUsageList.nativeElement.offsetWidth / this.displayedImages,
      height: this.fileUsageList.nativeElement.offsetHeight,
    };
  }

  setSelectedIndex(index: number): void {
    if (!this.fileUsages?.length) {
      return;
    }

    const newIndex = index < 0 ? 0 : index >= this.fileUsages.length ? this.fileUsages.length - 1 : index;

    if (this.selectedIndex !== newIndex) {
      this.selectedIndex = newIndex;
      this.selectedIndexChanged.emit(this.selectedIndex);

      if (this.selectedIndex >= this.viewIndex + this.displayedImages) {
        this.setViewIndex(this.selectedIndex);
      } else if (this.selectedIndex < this.viewIndex) {
        this.setViewIndex(this.selectedIndex - this.displayedImages);
      }
    }
  }

  setViewIndex(index: number): void {
    if (this.navigation && this.viewIndex !== this.getValidViewIndex(index)) {
      this.viewIndex = this.getValidViewIndex(index);
      this.viewIndexChanged.emit(this.viewIndex);

      if (this.displayedImages === 1) {
        this.setSelectedIndex(this.viewIndex);
      }

      this.setLazyImages();

      this.swiping = false;
    }
  }

  getValidViewIndex(index: number): number {
    if (index < 0) {
      return 0;
    }

    if (index > this.fileUsages.length - this.displayedImages) {
      if (this.fileUsages.length - this.displayedImages < 0) {
        return 0;
      }

      return this.fileUsages.length - this.displayedImages;
    }

    return index;
  }

  setLazyImages(): void {
    if (this.fileUsages?.length) {
      this.fileUsages[this.viewIndex].lazy = false;

      for (let i = 1; i < this.displayedImages * 2 + 1; i++) {
        if (this.viewIndex + i < this.fileUsages.length) {
          this.fileUsages[this.viewIndex + i].lazy = false;
        }

        if (this.viewIndex - i >= 0) {
          this.fileUsages[this.viewIndex - i].lazy = false;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription ? this.subscription.unsubscribe() : noop();
  }
}
