import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  transform(text: string, search: string): string {
    let pattern = search && search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

    pattern =
      pattern &&
      pattern
        .split(' ')
        .filter((t) => t.length > 0)
        .join('|');

    const regex = new RegExp(pattern, 'gi');

    return search && text ? text.replace(regex, (match) => `<mark class="highlight">${match}</mark>`) : text;
  }
}
