/// <reference types="hammerjs" />

/**
 * Collection of commonly used Types.
 */

/**
 * Base API Response
 */
export interface APIResponse {
  status: string;
  message: string;
  data?: Record<string, string | number | boolean | null | Array<string | number>>;
}

/**
 * A response that is returned when a delete is done in the API.
 */
export interface DeleteResponse extends APIResponse {
  delete: boolean;
}

/**
 * A response that is returned when a query is done in the API.
 */
export interface CollectionResponse<T> extends APIResponse {
  count: number;
  limit: number;
  page: number;
  next: string;
  prev: string;
  Collection: T[];
}

/**
 * A response that is returned when a query is done in the API.
 */
export interface EntityResponse<T> extends APIResponse {
  Entity: T;
}

export enum ModelName {
  Project = 'project',
  Object = 'object',
}

export interface APEXEnvironment {
  staging: boolean;
  production: boolean;
  test: boolean;
  assetsUrl: string;

  api: string;
  restApi: string;
  appUrl: string;

  apexUrl: string;
  inspectionUrl: string;
  portalUrl: string;
  reportingUrl: string;
  bucket: string;
  printUrl: string;
  requestsUrl: string;

  bucketUrl: string;
  pdfViewerUrl: string;

  pdfWorkerSrc: string;
  logoutUrl: string;

  oAuthCallbackUrl: string;
  transifexToken: string;

  pusherInstanceId: string;
}

export interface ObjectWithCustomerId {
  CustomerId: number;
}

export interface ObjectWithId {
  id: number;
}

export interface ObjectWithUUID {
  id: string;
}

export interface ObjectWithDataAndParent {
  data: unknown;
  parent: ObjectWithDataAndParent;
}

export const HammerTimeInputType = (event: Event | HammerInput): HammerInput => event as unknown as HammerInput;
