import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { uniqBy } from 'lodash-es';
import { AutocompleteModule } from '../../../../components/autocomplete/autocomplete.module';
import { TranslateModule } from '../../../../components/translate/translate.module';
import { UserService } from '../../../../services/user/user.service';
import { ProjectInvolvedService } from '../../project-involved.service';
import { ProjectInvolvedUserType } from '../project-involved-user.type';
import { ProjectInvolvedComponent } from '../project-involved.component';
import ProjectInvolvedUsersDialogInputType from './project-involved-users-dialog-input-type';
import ProjectInvolvedUsersDialogOutputType from './project-involved-users-dialog-ouput.type';

@Component({
  selector: 'apex-project-involved-users-dialog',
  templateUrl: './project-involved-users-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle,
    ProjectInvolvedComponent,
    MatButtonModule,
    MatDialogClose,
    MatCheckboxModule,
    CommonModule,
    TranslateModule,
    MatDialogActions,
    AutocompleteModule,
    FormsModule,
    MatIconModule,
    MatTooltipModule,
  ],
  providers: [ProjectInvolvedService],
})
export class ProjectInvolvedUsersDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProjectInvolvedUsersDialogInputType,
    protected readonly involvedService: ProjectInvolvedService,
    protected readonly userService: UserService,
    public dialogRef: MatDialogRef<ProjectInvolvedUsersDialogComponent, ProjectInvolvedUsersDialogOutputType>,
  ) {}

  selectedSingleUser: Pick<ProjectInvolvedUserType, 'id' | 'name'> = {
    id: 0,
    name: '',
  };

  notifyOnAddSms: boolean;
  sendEmail: boolean;

  async ngOnInit(): Promise<void> {
    this.notifyOnAddSms = this.involvedService.notifyOnAddSms;
    this.sendEmail = this.involvedService.sendEmail;

    this.involvedService.initialSelectedUserIds = this.data.excludedUserIds;

    this.involvedService.initialSelectedCount = 0;
  }

  selectedContractor($event: number): void {
    if ($event) {
      this.involvedService.contractorCount.set(1);
    } else {
      this.involvedService.contractorCount.set(0);
    }
  }

  save(): void {
    const selectedUsers = this.involvedService.selectedUsers().map((user) => ({
      id: user.id,
      name: user.name,
    }));

    if (this.selectedSingleUser.id) {
      selectedUsers.push(this.selectedSingleUser);
    }

    const uniqueSelectedUsers = uniqBy(selectedUsers, (user) => user.id);

    this.dialogRef.close({
      selectedUsers: uniqueSelectedUsers,
      notifyOnAddSms: this.involvedService.notifyOnAddSms,
      sendEmail: this.involvedService.sendEmail,
    });
  }
}
