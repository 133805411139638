import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { SidebarService } from '../../components/sidebar/services/sidebar.service';
import { t } from '../../components/translate/translate.function';
import { snack, snackErr } from '../../modules/snack.module';
import { nameCompare } from '../../utils/functions';
import { Bookmark, BookmarkFolder } from './bookmark.model';
import { BookmarkService } from './bookmark.service';

interface TreeFolder extends BookmarkFolder {
  children: TreeFolder[];
}

@Component({
  selector: 'apex-bookmark-manage',
  templateUrl: './manage.component.html',
})
export class BookmarkManageComponent implements OnInit {
  get title(): string {
    return t('Manage Bookmarks');
  }

  folderId: number;

  treeData: TreeFolder[] = [];

  folders: BookmarkFolder[] = [];
  bookmarks: Bookmark[] = [];

  treeControl = new NestedTreeControl<TreeFolder>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<TreeFolder>();

  private subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: BookmarkService,
    protected readonly sidebarService: SidebarService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.service.data$.subscribe({
        next: (_) => {
          this.treeData = [];

          const p = {
            id: null,
            ParentId: null,
            name: t('Bookmarks'),
            children: [],
          };

          this.treeData.push(p);
          this.attachChildrenToNode(p);

          this.dataSource.data = this.treeData;

          this.folders = this.service.folders.filter((f) => f.ParentId === this.folderId).sort(nameCompare);
          this.bookmarks = this.service.bookmarks.filter((b) => b.FolderId === this.folderId).sort(nameCompare);
        },
      }),
    );

    this.subscriptions.add(
      this.route.queryParams.pipe(map((qp) => qp.folderId)).subscribe({
        next: (folderId) => {
          if (folderId) {
            this.folderId = parseInt(folderId, 10);
          }

          if (!folderId) {
            this.folderId = null;
          }

          this.folders = this.service.folders.filter((f) => f.ParentId === this.folderId).sort(nameCompare);
          this.bookmarks = this.service.bookmarks.filter((b) => b.FolderId === this.folderId).sort(nameCompare);
        },
      }),
    );
  }

  hasChildren(_: number, node: TreeFolder): boolean {
    return node?.children?.length > 0;
  }

  setFolder(folderId: number): Promise<boolean> {
    return void this.router.navigate([], {
      queryParams: {
        folderId,
      },
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }

  save(item: Bookmark | BookmarkFolder, form: NgForm): void {
    if (item.model === 'Bookmark') {
      this.subscriptions.add(
        this.service
          .saveBookmark(item as Bookmark)
          .pipe(tap((_) => form?.form.markAsPristine()))
          .subscribe({
            next: (_) => snack(t('Saved')),
            error: (err) => snackErr(t('Could not save'), err),
          }),
      );

      return;
    }

    if (item.model === 'BookmarkFolder') {
      this.subscriptions.add(
        this.service
          .saveFolder(item)
          .pipe(tap((_) => form?.form.markAsPristine()))
          .subscribe({
            next: (_) => snack(t('Saved')),
            error: (err) => snackErr(t('Could not save'), err),
          }),
      );

      return;
    }
  }
  delete(item: Bookmark | BookmarkFolder): void {
    if (item.model === 'Bookmark') {
      this.subscriptions.add(
        this.service.deleteBookmark(item as Bookmark).subscribe({
          next: async (_) => {
            await this.sidebarService.delete(String(item.id));
            snack(t('Deleted'));
          },
          error: (err) => snackErr(t('Could not delete'), err),
        }),
      );

      return;
    }

    if (item.model === 'BookmarkFolder') {
      this.subscriptions.add(
        this.service.deleteFolder(item as BookmarkFolder).subscribe({
          next: (_) => snack(t('Deleted')),
          error: (err) => snackErr(t('Could not delete'), err),
        }),
      );
    }
  }

  navigateToBookmark(bookmark: Bookmark): void {
    void this.router.navigateByUrl(bookmark?.url);
  }

  private attachChildrenToNode(parent: TreeFolder): void {
    parent.children = this.service.folders.filter((f) => f.ParentId === parent.id).map((f) => f as TreeFolder);
    parent.children.forEach((c) => this.attachChildrenToNode(c));
  }
}
