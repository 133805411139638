<ng-template #itemsTemplate>
  <section
    class="item"
    *ngFor="let item of items; last as last">
    <div class="item-wrapper df ac">
      <button
        type="button"
        mat-icon-button
        class="complete-item-button"
        [disabled]="true">
        <mat-icon>panorama_fish_eye</mat-icon>
      </button>
      <div class="df c jc f1 apls amvs truncate">
        <div class="truncate amrs">{{ item.name }}</div>
        <div
          class="mat-caption amrs truncate"
          name="description">
          {{ item.description }}
        </div>
      </div>
    </div>
    <mat-divider *ngIf="!last"></mat-divider>
  </section>
</ng-template>

<div
  class="df c"
  [ngClass]="{
    'mat-elevation-z2': section?.id && !section?.ParentId,
    amb: section?.id && !section?.ParentId,
  }">
  <div
    *ngIf="section?.id"
    class="checklist-section df ac">
    <div class="aml f1 mat-body-2">{{ step }} {{ section.name }}</div>
    <button
      mat-icon-button
      [disabled]="!items.length && !sections.length && !section?.description ? true : false"
      (click)="expanded = !expanded">
      <mat-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="expanded">
    <div *ngIf="section?.id && section?.description">
      <div class="amh amvs mat-caption">{{ section.description }}</div>
      <mat-divider></mat-divider>
    </div>
    <ng-container *ngIf="section?.id && items?.length">
      <ng-container *ngTemplateOutlet="itemsTemplate"></ng-container>
    </ng-container>
    <apex-checklist-section-preview
      *ngFor="let section of sections; let i = index"
      [checklistTemplate]="checklistTemplate"
      [checklistTemplateItems]="checklistTemplateItems"
      [checklistTemplateSections]="checklistTemplateSections"
      [section]="section"
      [step]="step ? step + '.' + (i + items?.length + 1) : '' + (i + 1)">
    </apex-checklist-section-preview>
    <ng-container *ngIf="!section?.id && items?.length">
      <ng-container *ngTemplateOutlet="itemsTemplate"></ng-container>
    </ng-container>
  </ng-container>
</div>
