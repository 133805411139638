import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '../translate/translate.module';
import { MapDrawingComponent } from './map-drawing.component';
import { MapPointsComponent } from './map-points.component';
import { MapComponent } from './map.component';

@NgModule({
  declarations: [MapComponent, MapPointsComponent, MapDrawingComponent],
  exports: [MapComponent, MapPointsComponent, MapDrawingComponent],
  imports: [MatButtonModule, MatIconModule, TranslateModule, MatDividerModule, CommonModule],
  providers: [],
})
export class MapModule {}
