<form
  novalidate
  (submit)="confirm()"
  #rejectForm="ngForm">
  <div mat-dialog-title>
    <div class="df f1 jsb ac">
      <ng-container *ngIf="data?.case?.id; else bulkDeleteTitle">
        <h1 *ngIf="type === 'decline'">
          <T
            str="Decline #{id}"
            [vars]="{ id: data.case.id }">
          </T>
        </h1>
        <h1 *ngIf="type === 'complete'">
          <T
            str="Complete #{id}"
            [vars]="{ id: data.case.id }">
          </T>
        </h1>
      </ng-container>
      <ng-template #bulkDeleteTitle>
        <h1 *ngIf="type === 'decline'">
          <T str="Decline"></T>
        </h1>
        <h1 *ngIf="type === 'complete'">
          <T str="Complete"></T>
        </h1>
      </ng-template>
      <button
        mat-icon-button
        type="button"
        (click)="close()"
        tabindex="-1">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <div
    mat-dialog-content
    class="df c">
    <T
      *ngIf="type === 'decline'"
      str="Please provide a message for why you decline the case">
    </T>
    <T
      *ngIf="type === 'complete'"
      str="Please provide a message for why you complete the case">
    </T>

    <mat-form-field class="f1">
      <label>
        <T str="Message"></T>
      </label>
      <input
        matInput
        name="message"
        [(ngModel)]="message"
        required />
    </mat-form-field>

    <mat-checkbox
      [(ngModel)]="visibleForClient"
      name="visibleForClient">
      <T str="Message visible to customer"></T>
    </mat-checkbox>
  </div>
  <div mat-dialog-actions>
    <div></div>
    <div>
      <button
        class="amr"
        mat-button
        type="button"
        (click)="close()">
        <T str="Cancel"></T>
      </button>
      <button
        mat-raised-button
        type="submit"
        [color]="type === 'decline' ? 'warn' : 'primary'"
        [disabled]="rejectForm.invalid"
        (click)="confirm()">
        <T
          *ngIf="type === 'decline'"
          str="Decline"></T>
        <T
          *ngIf="type === 'complete'"
          str="Complete"></T>
      </button>
    </div>
  </div>
</form>
