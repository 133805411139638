import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { FileUsage } from '../../models/file-usage';
import { HttpService } from '../../services/http/http.service';
import { createApiUrl } from '../../utils/functions';

@Injectable()
export class FileUsageService extends HttpService<FileUsage> {
  route = 'fileusage';

  // Can't override the juicy base `query` so we do a new name.
  all(self: string, selfId: string | number, name: string): Observable<FileUsage[]> {
    return this.http.get<FileUsage[]>(`${this.apiUrl}/${this.route}/${self}/${selfId}/${name}`, this.options);
  }

  save(fileUsage: FileUsage): Observable<FileUsage> {
    return this.http.post<FileUsage>(this.url(fileUsage), fileUsage, this.options);
  }

  delete(fileUsage: FileUsage, deleteFile = false): Observable<FileUsage> {
    const options = Object.assign(this.options, { params: { deleteFile } });

    return this.http.delete<null>(this.url(fileUsage), options);
  }

  sort(
    self: string,
    selfId: string | number,
    name: string,
    sorts: {
      FileId: number;
      fileSort: number;
    }[],
  ): Observable<Record<string, unknown>> {
    return this.http.post<Record<string, unknown>>(
      `${this.url({
        self,
        selfId,
        name,
      } as FileUsage)}/sort`,
      sorts,
      this.options,
    );
  }

  count(self: string, selfId: string | number, name: string): Observable<{ count: number }> {
    return this.http.get<{ count: number }>(
      `${this.url({
        self,
        selfId,
        name,
      } as FileUsage)}/count`,
      this.options,
    );
  }

  async swap(fileUsage: FileUsage, fileId: number): Promise<FileUsage> {
    const url = createApiUrl('fileusage', fileUsage.id);

    return firstValueFrom(this.http.post<FileUsage>(url, { FileId: fileId }, this.options));
  }

  protected url(fileUsage: FileUsage): string {
    return fileUsage.id
      ? `${this.apiUrl}/${this.route}/${fileUsage.self}/${fileUsage.selfId}/${fileUsage.name}/${fileUsage.FileId}`
      : `${this.apiUrl}/${this.route}/${fileUsage.self}/${fileUsage.selfId}/${fileUsage.name}`;
  }
}
