import { Params } from '@angular/router';
import { Customer } from '../../models/customer';

export const parseEntityRegResponse = (response: Params): Customer =>
  new Customer({
    name: response?.navn,
    zip: response?.forretningsadresse?.postnummer,
    address: response?.forretningsadresse?.adresse[0],
    city: response?.forretningsadresse?.poststed,
    country: response?.forretningsadresse?.land,
  });
