<apex-box
  class="am no-padding"
  icon="credit_card"
  [loading]="loading"
  [expandable]="caseView">
  <ng-container title>
    <ng-container *ngIf="!failedToLoad; else failedToLoadTemplate">
      <T
        str="Entry card for {tenancyName}"
        [vars]="{ tenancyName: tenancy?.name ?? '' }">
      </T>
    </ng-container>

    <ng-template #failedToLoadTemplate>
      <T str="Could not load entry card"></T>
    </ng-template>
  </ng-container>

  <form
    #entryCardForm="ngForm"
    *ngIf="!loading && !failedToLoad"
    class="df c apl apt mat-elevation-z2"
    (ngSubmit)="saveCard()">
    <div class="df w">
      <apex-autocomplete-text
        name="name"
        class="f1 amr"
        [disabled]="caseView"
        [(ngModel)]="entryCard.name"
        (ngModelChange)="setUser()"
        (modelChange)="setUserId($event?.id)"
        type="User"
        [label]="'Name' | translate"
        required>
      </apex-autocomplete-text>

      <mat-form-field class="f1 amr">
        <input
          matInput
          type="email"
          name="mail"
          [disabled]="caseView"
          [(ngModel)]="entryCard.mail"
          [placeholder]="'Email' | translate" />
      </mat-form-field>

      <mat-form-field class="f1 amr">
        <input
          matInput
          type="number"
          name="mobile"
          [disabled]="caseView"
          [(ngModel)]="entryCard.mobile"
          [placeholder]="'Phone' | translate" />
      </mat-form-field>
    </div>

    <div class="amr amt divider-wrapper">
      <mat-divider></mat-divider>
    </div>

    <div class="df w ac">
      <mat-form-field class="f1 amr">
        <input
          matInput
          type="text"
          name="serialNumber"
          [placeholder]="'Serial number' | translate"
          [disabled]="viewOnly"
          [(ngModel)]="entryCard.serialNumber"
          required />
      </mat-form-field>
      <mat-form-field
        class="f1 amr"
        *ngIf="!viewOnly">
        <input
          matInput
          type="text"
          name="pin"
          [placeholder]="'Pin' | translate"
          [(ngModel)]="entryCard.pin"
          required />
      </mat-form-field>
    </div>

    <div class="df w f1 afe">
      <mat-form-field
        class="f1 amr"
        *ngIf="!viewOnly">
        <textarea
          matInput
          name="description"
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="10"
          [placeholder]="'Description' | translate"
          [(ngModel)]="entryCard.description">
        </textarea>
      </mat-form-field>

      <mat-form-field class="f1 amr">
        <mat-chip-grid
          #chipList
          [disabled]="viewOnly">
          <mat-chip-row
            *ngFor="let group of entryCard.EntryGroups"
            [removable]="true"
            (removed)="removeGroup(group)"
            [matTooltip]="group.description">
            {{ group.name }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-row>
          <input
            name="entryGroupFilterSearch"
            [(ngModel)]="entryGroupFilterSearch"
            [placeholder]="'Entry groups' | translate"
            #entryGroupInput
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            (ngModelChange)="onChange()" />
        </mat-chip-grid>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="addGroup($event?.option?.value)">
          <mat-option
            *ngIf="!viewEntryGroups.length"
            disabled>
            <T str="No matches found"></T>
          </mat-option>
          <mat-option
            *ngFor="let group of viewEntryGroups"
            [value]="group">
            <span [innerHTML]="group.name | highlight: entryGroupFilterSearch"> </span>
            <span
              class="mat-caption"
              *ngIf="group.description">
              - {{ group.description }}
            </span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <mat-checkbox
      class="amr asfe"
      name="active"
      [ngClass]="{ amb: !viewOnly }"
      [disabled]="viewOnly"
      [(ngModel)]="entryCard.active">
      <T str="Active"></T>
    </mat-checkbox>

    <div class="df f1 ac jsb w">
      <div class="df ac amr amb p-f1">
        <div class="amr">
          <button
            mat-raised-button
            type="button"
            color="warn"
            class="p-f1"
            *ngIf="!viewOnly"
            [disabled]="!entryCard.id"
            (click)="deleteCard()">
            <T str="Delete"></T>
          </button>
        </div>
        <button
          mat-raised-button
          type="button"
          color="primary"
          *ngIf="!caseView"
          class="p-f1"
          [disabled]="!entryCardForm.valid"
          (click)="saveCardAndContinue(); entryCardForm.resetForm()">
          <T str="Save and continue"></T>
        </button>
      </div>
      <div class="df ac p-f1">
        <div class="amr amb p-df p-f1">
          <button
            mat-raised-button
            type="button"
            *ngIf="!caseView"
            class="p-f1"
            apexHistoryBack>
            <T str="Back"></T>
          </button>
        </div>
        <div class="amr amb p-df p-f1">
          <button
            mat-raised-button
            type="submit"
            color="primary"
            *ngIf="!viewOnly"
            class="p-f1"
            [disabled]="!entryCardForm.valid">
            <T str="Save"></T>
          </button>
        </div>
      </div>
    </div>
  </form>
</apex-box>
