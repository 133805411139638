<ng-container *ngIf="!loading">
  <ng-container *ngFor="let notification of notifications">
    <a
      [title]="notification.translatedText"
      [routerLink]="['/', 'case']"
      (click)="markAsRead(notification)"
      [fragment]="'list-' + notification.data?.modelId"
      class="df ac ap">
      <div class="amr">
        <mat-icon
          *ngIf="!notification.read"
          color="primary">
          circle
        </mat-icon>
      </div>

      <div class="df c oh">
        <div class="truncate">
          {{ notification.translatedText }}
        </div>
        <div class="mat-caption truncate">
          {{ notification.createdAt | date: 'short' }}
          -
          {{ notification.data?.modelTitle ?? '' }}
        </div>
      </div>
    </a>
    <mat-divider></mat-divider>
  </ng-container>

  <ng-container *ngIf="!notifications.length">
    <div>
      <T str="No notifications"></T>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="loading">
  <div class="df ac jc f1">
    <mat-spinner></mat-spinner>
  </div>
</ng-container>
