import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { t } from '../components/translate/translate.function';
import { snackErr } from '../modules/snack.module';
import { UserService } from '../services/user/user.service';
import { constants } from '../utils/constants';
import { hostMatchesApp } from '../utils/functions';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const shouldIgnoreSnackForUrl = req.url.includes('autocomplete') || req.url.includes('filter');

        if (error.status >= 400 && error.status < 500 && shouldIgnoreSnackForUrl) {
          return throwError(() => error);
        }

        if (error.status === 401) {
          this.userService?.clearProfile();

          const shouldSnack = !req.url.includes('profile');

          if (shouldSnack) {
            snackErr(t('Unauthorized, please sign in to use APEX'), null);
          }

          const authPaths = ['/auth/sign-in', '/auth/sign-out', '/auth/redirect'];

          if (!authPaths.includes(window.location.pathname)) {
            localStorage.setItem(constants.redirectUrlKey, window.location.href);
          }

          if (hostMatchesApp) {
            void this.router.navigate(['/auth/sign-in']);

            return throwError(() => error);
          }

          // @todo This should be moved to actual login/logout
          setTimeout(() => {
            location.href = `${environment.logoutUrl}?error=Unauthorized`;
          }, 1500);

          return throwError(() => error);
        }

        if (error.status !== 404) {
          snackErr(t('Something went wrong'), error);
        }

        // void this.router.navigate(['/error', error.status], {
        //   state: {
        //     error: {
        //       status: error.status,
        //       message: error.message,
        //     },
        //   },
        // });

        return throwError(() => error);
      }),
    );
  }
}
