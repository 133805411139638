import { Injectable, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Observable } from 'rxjs';

import { take } from 'rxjs/operators';
import { BookmarkService } from './bookmark.service';
import { BookmarkManageComponent } from './manage.component';

@Injectable()
export class BookmarkServiceDataLoadedResolver {
  constructor(private service: BookmarkService) {}

  resolve(): Observable<boolean> {
    return this.service.data$.pipe(take(1));
  }
}

const routes: Routes = [
  {
    path: '',
    component: BookmarkManageComponent,
    resolve: {
      loaded: BookmarkServiceDataLoadedResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [BookmarkServiceDataLoadedResolver],
})
export class BookmarkRoutingModule {}
