<mat-menu #addMenu="matMenu">
  <button
    mat-menu-item
    (click)="addItem()">
    <T str="Item"></T>
  </button>
  <button
    mat-menu-item
    (click)="editSection()">
    <T str="Section"></T>
  </button>
</mat-menu>

<div
  class="f1 df c"
  [ngClass]="{
    'mat-elevation-z2': checklistTemplateSection?.id && !checklistTemplateSection?.ParentId,
  }">
  <div
    *ngIf="checklistTemplateSection?.id"
    class="checklist-template-section df ac">
    <ng-content></ng-content>
    <span class="f1">{{ step }} {{ checklistTemplateSection.name }}</span>
    <button
      mat-icon-button
      [matMenuTriggerFor]="addMenu">
      <mat-icon>add</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="editSection(checklistTemplateSection)">
      <mat-icon>edit</mat-icon>
    </button>
    <button
      mat-icon-button
      [disabled]="!!(items.length || sections.length)"
      (click)="remove()">
      <mat-icon>delete</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="expanded = !expanded">
      <mat-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</mat-icon>
    </button>
  </div>
  <div [ngClass]="{ dn: !expanded && checklistTemplateSection?.id }">
    <div
      *ngIf="checklistTemplateSection?.id && checklistTemplateSection.description"
      class="df c">
      <div class="ams mat-caption">{{ checklistTemplateSection.description }}</div>
      <mat-divider></mat-divider>
    </div>
    <div
      *ngIf="checklistTemplateSection?.id"
      cdkDropList
      [id]="checklistTemplateSection?.id.toString() + '-items'"
      [cdkDropListData]="items"
      [attr.data-section-id]="checklistTemplateSection?.id"
      (cdkDropListDropped)="dropItem($event)"
      [cdkDropListConnectedTo]="dropListService.getConnectedDropLists()">
      <ng-container *ngFor="let item of items">
        <apex-checklist-template-item-form
          [checklistTemplate]="checklistTemplate"
          [checklistTemplateItem]="item"
          [checklistTemplateItems]="checklistTemplateItems"
          (listChange)="filter()">
        </apex-checklist-template-item-form>
        <mat-divider></mat-divider>
      </ng-container>
    </div>
    <div
      cdkDropList
      [id]="checklistTemplateSection?.id.toString()"
      [cdkDropListData]="sections"
      (cdkDropListDropped)="dropSection($event)"
      class="df c"
      [ngClass]="{ ams: !checklistTemplateSection?.id }"
      [cdkDropListConnectedTo]="dropListService.getConnectedDropLists()">
      <div
        *ngFor="let section of sections; let i = index"
        class="f1 df"
        [ngClass]="{ ams: !checklistTemplateSection?.id }">
        <apex-checklist-template-section-view
          class="f1"
          [cdkDragDisabled]="!section?.id || disabled"
          cdkDrag
          [checklistTemplate]="checklistTemplate"
          [checklistTemplateItems]="checklistTemplateItems"
          [checklistTemplateSection]="section"
          [checklistTemplateSections]="checklistTemplateSections"
          [step]="step ? step + '.' + (i + 1) : '' + (i + 1)"
          (listChange)="filter()">
          <mat-icon
            class="aps clickable"
            cdkDragHandle
            >drag_handle
          </mat-icon>
        </apex-checklist-template-section-view>
      </div>
    </div>

    <div
      *ngIf="!checklistTemplateSection?.id"
      cdkDropList
      [cdkDropListData]="items"
      (cdkDropListDropped)="dropItem($event)"
      class="mat-elevation-z2 amh amb">
      <ng-container *ngFor="let item of items">
        <apex-checklist-template-item-form
          [checklistTemplate]="checklistTemplate"
          [checklistTemplateItem]="item"
          [checklistTemplateItems]="checklistTemplateItems"
          (listChange)="filter()">
        </apex-checklist-template-item-form>
        <mat-divider></mat-divider>
      </ng-container>
    </div>
  </div>
</div>
