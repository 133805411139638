<ng-template
  #folderTemplate
  let-f="folder"
  let-d="depth">
  <div
    mat-ripple
    class="df r ac new steps {{ 'steps-' + d }}"
    [ngClass]="{ selected: f.id === folder?.id }"
    (click)="$event.stopPropagation(); folder = f; folderChange.emit(f)">
    <div
      class="content f1 df r ac oh"
      apexDrop
      [apexDropDisabled]="f.id === 'share' || f.modelName === 'upload'"
      (apexDrop)="$event ? uploadFromDragEvent($event, f) : folderDrop.emit(f)">
      <button
        mat-icon-button
        [style.visibility]="f.hasChildren ? 'visible' : 'hidden'"
        [disabled]="!f.hasChildren"
        (click)="$event.stopPropagation(); toggleExpanded(f)"
        (mousedown)="$event.stopPropagation()">
        <mat-icon>{{ f.expanded ? 'arrow_drop_down' : 'arrow_right' }}</mat-icon>
      </button>
      <div
        *ngIf="f.id === loadingFolderId"
        class="ams">
        <mat-spinner diameter="24"></mat-spinner>
      </div>
      <mat-icon
        [class.empty]="f.modelName !== 'share' && !f.hasChildren && !f.hasFiles"
        *ngIf="f.id !== loadingFolderId"
        class="ams"
        color="primary">
        {{ folderIcon(f) }}
      </mat-icon>
      <div class="f1 mat-body-2 truncate">
        {{ folderName(f) }}
      </div>
    </div>
  </div>
  <ng-container *ngIf="f.expanded">
    <ng-container *ngFor="let c of f.Children">
      <ng-container *ngTemplateOutlet="folderTemplate; context: { folder: c, depth: d + 1 }"></ng-container>
    </ng-container>
    <button
      mat-button
      (apexInView)="$event && loadMoreChildren(f)"
      *ngIf="f.Children?.length && f.childrenCount > f.Children.length"
      (click)="loadMoreChildren(f)">
      <T str="Load more"></T>
    </button>
  </ng-container>
</ng-template>

<ng-container *ngFor="let f of homeFolders">
  <ng-container *ngTemplateOutlet="folderTemplate; context: { folder: f, depth: 1 }"></ng-container>
</ng-container>
<div
  *ngIf="loading"
  class="f1 df ac jc">
  <mat-spinner></mat-spinner>
</div>
<div
  *ngIf="!folders?.length && !loading"
  class="f1 df ac jc">
  <T str="No folders found"></T>
</div>
