<ng-container *ngIf="file">
  <apex-image-viewer
    *ngIf="type === FileType.Image"
    [file]="file"
    [fileUsage]="fileUsage"
    [markings]="markings"
    [edit]="edit"
    [highlightedMarkingIds]="highlightedMarkingIds"
    [highlightedMarkingModelIds]="highlightedMarkingModelIds"
    (markingCreate)="markingCreate.emit($event)"
    (markingChange)="markingChange.emit($event)"
    (markingClicked)="markingClicked.emit($event)"
    (markingEnter)="markingEnter.emit($event)"
    (markingLeave)="markingLeave.emit($event)">
  </apex-image-viewer>
  <apex-pdf-viewer
    *ngIf="type === FileType.PDF"
    [pdfSrc]="file.signed.url | urlCache">
  </apex-pdf-viewer>
  <div
    class="video"
    *ngIf="type === FileType.Video">
    <video
      [title]="file.name ?? ''"
      controls
      preload="auto">
      <source [src]="file.signed.url | urlCache" />
    </video>
  </div>
  <div
    class="df f1 google-viewer"
    *ngIf="type === FileType.Google && (extendedViewer$ | async) === true">
    <iframe
      [title]="'Extended viewer for {fileName}' | translate: { fileName: file.name ?? '' }"
      class="f1"
      frameBorder="0"
      [src]="extendedViewerUrl">
    </iframe>
  </div>
  <div
    *ngIf="type === FileType.Google && (extendedViewer$ | async) !== true"
    class="f1 df c ac jc">
    <mat-icon>insert_drive_file</mat-icon>

    <div
      class="df c ac jc"
      *ngIf="profile?.role !== 1">
      <T
        class="amt"
        str="To view this file and many more in APEX you need to opt-in on your profile!">
      </T>

      <a
        class="amt"
        mat-raised-button
        (click)="dialog.closeAll()"
        [routerLink]="['user', 'profile']">
        <T str="Profile"></T>
      </a>
    </div>
  </div>
  <div
    *ngIf="type === FileType.Other"
    class="f1 df ac jc">
    <mat-icon>insert_drive_file</mat-icon>
  </div>
  <div
    class="df f1 scrollable-vertical"
    *ngIf="type === FileType.Mail">
    <apex-file-mail-viewer
      class="df c f1"
      [file]="file"></apex-file-mail-viewer>
  </div>
</ng-container>
<div
  *ngIf="!file"
  class="placeholder-image w-100 h-100"></div>
