import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { ChecklistGroupService } from 'projects/apex/src/app/features/checklist-group/checklist-group.service';
import { ChecklistGroup, ChecklistGroupEntity } from 'projects/apex/src/app/models/checklist-group';
import { ChecklistGroupTemplate } from 'projects/apex/src/app/models/checklist-group-template';
import { snack, snackErr } from 'projects/apex/src/app/modules/snack.module';
import { Observable, Subscription } from 'rxjs';
import { map, mergeMap, skipWhile } from 'rxjs/operators';
import { ChecklistBulkDeleteDialogComponent } from '../../../components/checklist-bulk-delete/checklist-bulk-delete-dialog.component';
import { CollectionResponse } from '../../../utils/types';

interface ResolverResponse {
  groups: CollectionResponse<ChecklistGroupEntity>;
  templates: ChecklistGroupTemplate[];
}

@Component({
  selector: 'apex-checklist-group-list-page',
  templateUrl: './list.component.html',
})
export class ChecklistGroupListPageComponent implements OnDestroy {
  get title(): string {
    return t('Checklist Groups');
  }

  groups: ChecklistGroup[] = [];

  selectedGroups: ChecklistGroupEntity[] = [];

  viewGroups$: Observable<ChecklistGroupEntity[]>;

  page: number;
  limit: number;
  count: number;

  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cgs: ChecklistGroupService,
    private dialog: MatDialog,
  ) {
    this.viewGroups$ = this.route.queryParams.pipe(
      map((params) => {
        this.page = params.checklistGroupPage ?? 0;
      }),
      mergeMap(() => this.route.data as Observable<ResolverResponse>),
      map(({ groups }) => {
        this.page = Number(groups.page);
        this.limit = Number(groups.limit);
        this.count = Number(groups.count);

        this.groups = groups.Collection;

        return this.groups;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onChangePage(e: PageEvent): void {
    void this.router.navigate([], {
      queryParams: {
        checklistGroupPage: e.pageIndex,
        checklistGroupLimit: e.pageSize,
      },
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }

  updateSelected(updatedSelected: ChecklistGroupEntity[]): void {
    this.selectedGroups = updatedSelected;
  }

  selectAllChecklistGroups(): void {
    if (!!this.selectedGroups?.length && this.selectedGroups.length === this.groups.length) {
      this.groups.forEach((c: ChecklistGroupEntity) => {
        c.selected = false;
      });
    } else {
      this.groups.forEach((c: ChecklistGroupEntity) => {
        c.selected = true;
      });
    }

    this.selectedGroups = this.groups.filter((c: ChecklistGroupEntity) => c.selected);
  }

  bulkDeleteChecklistGroup(): void {
    this.dialog
      .open(ChecklistBulkDeleteDialogComponent, {
        data: {
          type: 'checklistGroup',
          selectedChecklists: this.selectedGroups,
        },
      })
      .afterClosed()
      .pipe(
        skipWhile((res: { del: boolean; completeAndArchiveCheck: boolean }) => !res?.del),
        mergeMap((res: { del: boolean; completeAndArchiveCheck: boolean }) =>
          this.cgs.bulkDelete(this.selectedGroups, res.completeAndArchiveCheck),
        ),
      )
      .subscribe({
        next: () => {
          snack(t('Deleted'));

          this.selectedGroups = [];

          void this.router.navigate(['/checklist-group']);
        },
        error: (err) => {
          snackErr(t('Could not delete'), err);
        },
      });
  }
}
