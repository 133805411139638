<mat-chip-listbox multiple>
  <ng-container *ngFor="let filter of filterOptions">
    <mat-chip-option
      *ngIf="filter.role | usersForRole: this.projectInvolvedService.responseProjectInvolvedUserDtos()"
      [value]="filter"
      (selectionChange)="projectInvolvedService.updateSelectedRoles(filter.role)">
      {{ filter.translated }}
    </mat-chip-option>
  </ng-container>
</mat-chip-listbox>
