import { t } from '../../components/translate/translate.function';
import { AddonOrderStatus } from '../../models/addon-order';

export const ADDON_ORDER_CLIENT_STATUSES = [
  {
    status: AddonOrderStatus.New,
    icon: 'schedule',
    color: 'info-color',
    text: t('Order is under review'),
  },
  {
    status: AddonOrderStatus.Denied,
    icon: 'error',
    color: 'error-color',
    text: t('Denied'),
  },
  {
    status: AddonOrderStatus.Approved,
    icon: 'check_circle',
    color: 'success-color',
    text: t('Accepted'),
  },
  {
    status: AddonOrderStatus.Canceled,
    icon: 'error',
    color: 'error-color',
    text: t('Canceled'),
  },
];
