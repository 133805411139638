import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { t } from 'projects/apex/src/app/components/translate/translate.function';

@Component({
  selector: 'apex-case-print-page',
  templateUrl: './print.component.html',
})
export class CasePrintPageComponent implements OnInit {
  get title(): string {
    return t('Cases', { _comment: 'Uses on print page' });
  }

  id: number;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.id = this.route?.snapshot?.params?.id;
  }
}
