import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TextEditModule } from 'projects/apex/src/app/components/text-edit/text-edit.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { TextService } from '../../features/text/text.service';
import { TextListComponent } from './text-list.component';

@NgModule({
  imports: [
    CommonModule,
    MatSidenavModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatRippleModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    TextEditModule,
    MatListModule,
  ],
  providers: [TextService],
  declarations: [TextListComponent],
  exports: [TextListComponent],
})
export class TextListModule {}
