import { Injectable } from '@angular/core';

type SizeCallback = (entry: ResizeObserverEntry) => void;

@Injectable()
export class SizeService {
  private resizeObserver: ResizeObserver;
  private map = new Map<Element, SizeCallback>();

  private supportsResizeObserver: boolean;

  constructor() {
    if (!ResizeObserver) {
      this.supportsResizeObserver = false;
    } else {
      this.supportsResizeObserver = true;
      this.resizeObserver = new ResizeObserver((entries) => this.observerCallback(entries));
    }
  }

  public register(element: Element, func: SizeCallback): boolean {
    this.map.set(element, func);

    if (!this.supportsResizeObserver) {
      this.callback(element, {
        borderBoxSize: [],
        contentBoxSize: [],
        contentRect: element.getBoundingClientRect(),
        target: element,
        devicePixelContentBoxSize: [],
      });

      return false;
    }

    this.resizeObserver.observe(element);

    return true;
  }

  public unregister(element: Element): boolean {
    this.map.delete(element);

    if (!this.supportsResizeObserver) {
      return false;
    }

    this.resizeObserver.unobserve(element);

    return true;
  }

  protected callback(element: Element, entry: ResizeObserverEntry): void {
    const mappedElementFunction = this.map.get(element);

    if (mappedElementFunction) {
      mappedElementFunction(entry);
    }
  }

  private observerCallback(entries: Array<ResizeObserverEntry>): void {
    for (const entry of entries) {
      this.callback(entry.target, entry);
    }
  }
}
