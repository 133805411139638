import { Injectable } from '@angular/core';
import { FileUsageService } from 'projects/apex/src/app/components/file-usage/file-usage.service';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { Marking } from 'projects/apex/src/app/models/marking';
import { Observable } from 'rxjs';

@Injectable()
export class MarkingService extends FileUsageService {
  public markingRoute = 'marking';

  getMarkings(
    self: string,
    selfId: number | string,
    name: string,
    model: string,
    modelId: number,
    FileUsageId: number,
  ): Observable<Marking[]> {
    return this.http.get<Marking[]>(
      this.markingUrl(
        {
          self,
          selfId,
          name,
        } as FileUsage,
        {
          model,
          modelId,
          FileUsageId,
        } as Marking,
      ),
      this.options,
    );
  }

  getAllMarkings(
    self: string,
    selfId: string | number,
    name: string,
    model: string,
    modelId: number,
  ): Observable<Marking[]> {
    return this.http.get<Marking[]>(
      this.markingUrl(
        {
          self,
          selfId,
          name,
        } as FileUsage,
        {
          model,
          modelId,
        } as Marking,
      ),
      this.options,
    );
  }

  saveMarking(fileUsage: FileUsage, marking: Marking): Observable<Marking> {
    const saveURL = this.markingUrl(fileUsage, marking);

    return this.http.post<Marking>(saveURL, marking, this.options);
  }

  deleteMarking(fileUsage: FileUsage, marking: Marking): Observable<Marking> {
    const deleteURL = this.markingUrl(fileUsage, marking);

    return this.http.delete<Marking>(deleteURL, this.options);
  }

  protected markingUrl(fileUsage: FileUsage, marking: Marking): string {
    let url = this.url({
      self: fileUsage.self,
      selfId: fileUsage.selfId,
      name: fileUsage.name,
    } as FileUsage);

    url += `/${this.markingRoute}/${marking.model}/${marking.modelId}`;

    if (marking.FileUsageId || fileUsage.id) {
      url += `/${marking.FileUsageId || fileUsage.id}`;

      if (marking.id) {
        url += `/${marking.id}`;
      }
    }

    return url;
  }
}
