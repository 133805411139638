import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { ChecklistGroupService } from 'projects/apex/src/app/features/checklist-group/checklist-group.service';
import { ChecklistGroup } from 'projects/apex/src/app/models/checklist-group';
import { Observable, Subscription, of } from 'rxjs';
import { map, mergeMap, skipWhile, take, tap } from 'rxjs/operators';
import { Autocomplete } from '../../../models/autocomplete';
import { Field } from '../../../models/field';
import { ObjectField } from '../../../models/object-field';
import { snack } from '../../../modules/snack.module';

@Component({
  selector: 'apex-checklist-group-form',
  templateUrl: './form.component.html',
})
export class ChecklistGroupFormComponent implements OnInit, OnDestroy {
  @Output() saveComplete = new EventEmitter<ChecklistGroup>();

  @Input() group: ChecklistGroup = new ChecklistGroup();
  @Input() disabled: boolean;

  @ViewChild('form') form: NgForm;

  subscription = new Subscription();

  fields: Field[] = [];
  objectFields: ObjectField[] = [];

  constructor(private gs: ChecklistGroupService) {}

  ngOnInit(): void {
    if (this.group?.ObjectId) {
      this.getObjectFields(this.group.ObjectId);
    }

    this.group.ObjectField = new ObjectField(this.group?.ObjectField);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get save$(): Observable<ChecklistGroup> {
    return of(null).pipe(
      skipWhile(() => this.invalid),
      tap(() => {
        this.group.FieldId = this.group.FieldId || null;
      }),
      tap(() => {
        this.group.ObjectFieldId = this.group.ObjectFieldId || null;
      }),
      mergeMap(() => this.gs.save(this.group)),
      map((res) => res.Entity),
      tap((group) => {
        this.group = group;
        snack(t('Saved'));

        this.saveComplete.emit(this.group);
      }),
    );
  }

  save(): void {
    this.save$.subscribe();
  }

  get invalid(): boolean {
    return this.form ? this.form.invalid : false;
  }

  get valid(): boolean {
    return !this.invalid;
  }

  getFields(project: Autocomplete): void {
    if (!project) {
      this.fields = [];

      return;
    }

    const sub = this.gs
      .getFields(this.group.id, project.id)
      .pipe(take(1))
      .subscribe({
        next: (fields) => {
          this.fields = fields.map((f) => new Field(f));
        },
      });

    this.subscription.add(sub);
  }

  getObjectFields(objectId: number): void {
    if (!objectId) {
      this.fields = [];

      return;
    }

    const sub = this.gs
      .getObjectFields(this.group.id, objectId)
      .pipe(take(1))
      .subscribe({
        next: (objectFields) => {
          this.objectFields = objectFields.map((obf) => new ObjectField(obf));
        },
      });

    this.subscription.add(sub);
  }

  setObjectField(change: MatSelectChange): void {
    this.group.ObjectFieldId = change.value;
    this.group.FieldId = null;
  }

  setField(change: MatSelectChange): void {
    this.group.FieldId = change.value;
    this.group.ObjectFieldId = null;
  }
}
