import { NgModule } from '@angular/core';

import { DaterPipe } from './dater.pipe';

@NgModule({
  declarations: [DaterPipe],
  exports: [DaterPipe],
  imports: [],
  providers: [],
})
export class DaterModule {}
