import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject, Subscription, of } from 'rxjs';
import { debounceTime, map, mergeMap, take, tap } from 'rxjs/operators';
import { Autocomplete } from '../../models/autocomplete';
import { Tag } from '../../models/tag';
import { constants } from '../../utils/constants';
import { AutocompleteService } from '../autocomplete/autocomplete.service';
import { AutocompleteTypes } from '../autocomplete/autocomplete.types';
import { TagParams } from './tag-chips.types';

interface Taggable {
  id: number | string;
  Tags?: Tag[];
}

@Component({
  selector: 'apex-tag-chips',
  templateUrl: './tag-chips.component.html',
})
export class TagChipsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() params: TagParams = {};
  @Input() model: Taggable;
  @Input() disabled = false;

  selectedTags: Tag[] = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags$ = new BehaviorSubject<Partial<Autocomplete>[]>([]);
  searchSubject = new Subject<string>();

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  subscription = new Subscription();

  loading = false;

  constructor(
    private route: ActivatedRoute,
    private autocompleteService: AutocompleteService,
  ) {}

  ngOnInit(): void {
    this.refreshSelected();

    const searchSubscription = this.searchSubject
      .pipe(
        tap(() => {
          this.loading = true;
        }),
        debounceTime(constants.inputDebounceTime),
        mergeMap((searchString) => {
          const newAc = { name: searchString };

          if (searchString.length === 0) {
            return of([] as Partial<Autocomplete>[]);
          }

          if (searchString.length < 3 && searchString !== '*') {
            return of([newAc] as Partial<Autocomplete>[]);
          }

          return this.autocompleteService.queryString(AutocompleteTypes.Tag, this.getParams(searchString)).pipe(
            take(1),
            map((autocompletes) => {
              if (searchString !== '*') {
                return [newAc, ...autocompletes] as Partial<Autocomplete>[];
              }

              return autocompletes;
            }),
          );
        }),
        map((autocompletes) =>
          autocompletes.filter(
            (autocomplete) =>
              !this.selectedTags.find(
                (selectedTag) => !!selectedTag.id && String(selectedTag.id) === String(autocomplete.id),
              ),
          ),
        ),
      )
      .subscribe({
        next: (autocompletes) => {
          this.filteredTags$.next(autocompletes);

          this.loading = false;
        },
      });

    this.subscription.add(searchSubscription);
  }

  ngOnChanges(): void {
    this.refreshSelected();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  add(event: MatChipInputEvent): void {
    if (this.disabled) {
      return;
    }

    const value = (event.value || '').trim();

    this.innerAdd(value);

    event.chipInput?.clear();

    this.refreshSelected();
    this.clearFiltered();
  }

  remove(tagToRemove: Partial<Tag>): void {
    if (this.disabled) {
      return;
    }

    const existingIdx = this.model?.Tags?.findIndex((tag) => tag.id === tagToRemove.id) ?? -1;

    this.innerRemove(existingIdx, this.model.Tags);

    this.refreshSelected();
    this.clearFiltered();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.disabled) {
      return;
    }

    const { value: selectedTag } = event.option;

    const exists = this.model?.Tags?.find(
      (tag) => (!!tag.id && tag.id === selectedTag.id) || tag.name === selectedTag.name,
    );

    if (!selectedTag.id && !exists) {
      this.innerAdd(selectedTag.name);

      this.clearFiltered();
      this.tagInput.nativeElement.value = '';
      this.refreshSelected();

      return;
    }

    if (exists) {
      return;
    }

    if (!this.model?.Tags) {
      this.model.Tags = [];
    }

    this.model.Tags.push({
      id: selectedTag.id,
      name: selectedTag.name,
    });

    this.clearFiltered();
    this.tagInput.nativeElement.value = '';
    this.refreshSelected();
  }

  getViewValue(tag: Partial<Autocomplete>): string {
    return tag.name;
  }

  handleKey(event: KeyboardEvent): void {
    const isSeparator = this.separatorKeysCodes.includes(event.keyCode);

    if (isSeparator) {
      return;
    }

    const searchString = this.tagInput.nativeElement.value.trim();

    if (searchString.length === 0) {
      this.tagInput.nativeElement.value = '';
    }

    this.searchSubject.next(searchString);
  }

  clearFiltered(): void {
    this.filteredTags$.next([]);
  }

  private getParams(value: string): HttpParams {
    let params = new HttpParams();

    const { Project: ProjectId, Object: ObjectId } = this.params;

    if (ProjectId) {
      params = params.append('Project', ProjectId);
    }

    if (ObjectId) {
      params = params.append('Project', ObjectId);
    }

    params = params.append('q', value);

    return params;
  }

  private innerAdd(name?: string): void {
    const existing = this.model?.Tags?.find((tag) => tag.name === name);

    if (existing) {
      return;
    }

    if (!!name) {
      if (!this.model?.Tags) {
        this.model.Tags = [];
      }

      this.model.Tags?.push({ id: null, name });
    }
  }

  private refreshSelected(): void {
    this.selectedTags = this.model?.Tags ?? [];
  }

  private innerRemove(idx: number, collection: Tag[]): void {
    if (idx < 0) {
      return;
    }

    collection.splice(idx, 1);
  }
}
