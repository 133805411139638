import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { TranslateModule } from '../translate/translate.module';
import { AutocompletePickerDialogComponent } from './dialog.component';
import { AutocompletePickerComponent } from './picker.component';

@NgModule({
  declarations: [AutocompletePickerComponent, AutocompletePickerDialogComponent],
  exports: [AutocompletePickerComponent, AutocompletePickerDialogComponent],
  imports: [
    CommonModule,

    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,

    AutocompleteModule,
    TranslateModule,
  ],
  providers: [],
})
export class AutocompletePickerModule {}
