<!-- pinned sidebarItems from responseSidebarItemPinnedDto -->
<ng-container *ngIf="sidebarService.sidebarItemsPinned?.length > 0">
  <div
    cdkDropList
    (cdkDropListDropped)="drop($event)">
    <apex-sidebar-item
      cdkDrag
      [sidebarItem]="sidebarItem"
      [pinned]="true"
      [responseSidebarItemPinned]="sidebarService.sidebarItemsPinned"
      *ngFor="
        let sidebarItem of staticSidebarItems
          | sidebarItemAccess: profile
          | filterSidebarItems: sidebarService.sidebarItemsPinned
          | sortSidebarItems: sidebarService.sidebarItemsPinned
      "></apex-sidebar-item>
  </div>
</ng-container>

<!-- static sidebarItems -->
<mat-expansion-panel
  [expanded]="sidebarExpansionPanelState"
  *ngIf="sidebarService.sidebarItemsPinned.length > 0; else sidebarItemsTemplate">
  <mat-expansion-panel-header (click)="toggleSidebarExpansionPanel()">
    <mat-panel-title>
      {{ (sidebarExpansionPanelState ? 'Show less' : 'Show more') | translate }}
    </mat-panel-title>
  </mat-expansion-panel-header>

  <ng-container *ngTemplateOutlet="sidebarItemsTemplate"></ng-container>
</mat-expansion-panel>

<ng-template #sidebarItemsTemplate>
  <apex-sidebar-item
    *ngFor="
      let sidebarItem of staticSidebarItems
        | sidebarItemAccess: profile
        | filterSidebarItems: sidebarService.sidebarItemsPinned : false
    "
    [sidebarItem]="sidebarItem"
    [pinned]="false"
    [responseSidebarItemPinned]="sidebarService.sidebarItemsPinned"></apex-sidebar-item>

  <section>
    <a href="https://help.apexapp.io">
      <mat-icon>help</mat-icon>
      <T
        str="Help"
        context="sidebar"
        _context="sidebar"></T>
    </a>
  </section>

  <section>
    <a
      [href]="signOutUrl"
      (click)="signOut()"
      routerLinkActive="active">
      <mat-icon>logout</mat-icon>
      <T
        str="Sign out"
        context="sidebar"
        _context="sidebar"></T>
    </a>
  </section>
</ng-template>
