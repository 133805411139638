import { t } from '../translate/translate.function';
import { KnowModelRolesModels, KnowModelRolesRoles, ModelRoleRole } from './model-role.types';

export const translateKnownModelRoleRoles: KnowModelRolesRoles = {
  contractor: t('Contractor'),
  'addon-manager': t('Addon Manager'),
  tenant: t('Tenant'),
  collaborator: t('Collaborator'),
  'folder-edit': t('Edit'),
  administrator: t('Administrator'),
  'file-view': t('View'),
  involved: t('Involved'),
  'folder-view': t('View'),
  owner: t('Owner'),
  'case-manager': t('Case Manager'),
  contact: t('Contact'),
  custodian: t('Custodian'),
  'project-manager': t('Project Manager'),
  realtor: t('Realtor'),
  buyer: t('Buyer'),
  'unwanted-incident-manager': t('Unwanted Incident manager'),
};

export const translateKnownModelRoleModels: KnowModelRolesModels = {
  'checklist-group-template': t('Checklist Group Template'),
  'addon-category-project': t('Addon Category Project'),
  'checklist-template': t('Checklist Template'),
  checklist: t('Checklist'),
  file: t('File'),
  agreement: t('Agreement'),
  project: t('Project'),
  tenancy: t('Tenancy'),
  'checklist-group': t('Checklist Group'),
  folder: t('Folder'),
  object: t('Object'),
  'object-ownership': t('Ownership'),
  'object-sale': t('Sale'),
};

export const ModelRoleRoles: ModelRoleRole[] = [
  {
    title: translateKnownModelRoleRoles.contractor,
    value: 'contractor',
  },
  {
    title: translateKnownModelRoleRoles['addon-manager'],
    value: 'addon-manager',
  },
  {
    title: translateKnownModelRoleRoles.tenant,
    value: 'tenant',
  },
  {
    title: translateKnownModelRoleRoles.collaborator,
    value: 'collaborator',
  },
  {
    title: translateKnownModelRoleRoles.administrator,
    value: 'administrator',
  },
  {
    title: translateKnownModelRoleRoles.involved,
    value: 'involved',
  },
  {
    title: translateKnownModelRoleRoles['folder-edit'],
    value: 'folder-edit',
  },
  {
    title: translateKnownModelRoleRoles['folder-view'],
    value: 'folder-view',
  },
  {
    title: translateKnownModelRoleRoles['file-view'],
    value: 'file-view',
  },
  {
    title: translateKnownModelRoleRoles.owner,
    value: 'owner',
  },
  {
    title: translateKnownModelRoleRoles['case-manager'],
    value: 'case-manager',
  },
  {
    title: translateKnownModelRoleRoles.custodian,
    value: 'custodian',
  },
  {
    title: translateKnownModelRoleRoles.contact,
    value: 'contact',
  },
  {
    title: translateKnownModelRoleRoles['project-manager'],
    value: 'project-manager',
  },
  {
    title: translateKnownModelRoleRoles['unwanted-incident-manager'],
    value: 'unwanted-incident-manager',
  },
];
