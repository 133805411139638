<div *ngIf="assumeUsers$ | async as assumeUsers">
  <div
    class="add-margin-top f1 df jc"
    *ngIf="assumeUsers?.length > 1">
    <button
      mat-flat-button
      [matMenuTriggerFor]="menu">
      <T
        str="Assume user"
        context="assume-user"
        _context="assume-user"></T>
    </button>
    <mat-menu #menu="matMenu">
      @for (user of assumeUsers; track user) {
        <button
          (click)="assumeUser(user.id)"
          mat-menu-item
          [disabled]="user.id === userId">
          <div class="df c">
            <span>{{ user.name }}</span>
            <span
              *ngIf="user.Company?.name"
              class="mat-caption">
              {{ user.Company.name }}
            </span>
          </div>
        </button>
      }
    </mat-menu>
  </div>
</div>
