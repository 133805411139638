import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { Case, Contractor } from '../../models/case';
import { CaseLog } from '../../models/case-log';
import { Field } from '../../models/field';
import { ObjectField } from '../../models/object-field';
import { Project } from '../../models/project';
import { HttpExtra } from '../../services/http/http-extra';
import { APIService, HttpService } from '../../services/http/http.service';
import { createApiUrl } from '../../utils/functions';
import { CollectionResponse, EntityResponse, ModelName } from '../../utils/types';
import { Obj } from '../object/object.model';
import { Checklist, ChecklistItem } from './checklist.model';

type Model = 'object' | 'project' | 'apartment';
type Collection = CollectionResponse<Checklist>;
@Injectable()
export class ChecklistService extends APIService<Checklist> {
  route = 'checklist';

  $countCompleted = new Subject<boolean>();

  createChecklistFromTemplate(
    templateId: number,
    model?: string,
    modelId?: number,
    extra?: HttpExtra,
  ): Observable<EntityResponse<Checklist>> {
    if (model && modelId) {
      return this.http.post<EntityResponse<Checklist>>(
        `${this.apiUrl}/${model}/${modelId}/${this.route}/from-template/${templateId}`,
        null,
        this.options(extra),
      );
    }

    return this.http.post<EntityResponse<Checklist>>(
      `${this.apiUrl}/${this.route}/from-template/${templateId}`,
      null,
      this.options(extra),
    );
  }

  queryByModel(model: 'object' | 'project' | 'apartment', modelId: number, extra?: HttpExtra): Observable<Collection> {
    if (extra && !extra?.params['open']) {
      extra.params['open'] = 'true';
    }

    return this.http.get<Collection>(`${this.apiUrl}/${model}/${modelId}/${this.route}`, this.options(extra));
  }

  getByModel(model: Model, modelId: number, id: number): Observable<EntityResponse<Checklist>> {
    return this.http.get<EntityResponse<Checklist>>(
      `${this.apiUrl}/${model}/${modelId}/${this.route}/${id}`,
      this.options(),
    );
  }

  getProjectsWithFieldAccess(
    extra?: HttpExtra,
  ): Observable<CollectionResponse<{ id: number; name: string; type: string }>> {
    return this.http.get<CollectionResponse<{ id: number; name: string; type: string }>>(
      `${this.apiUrl}/checklist/field-access`,
      {
        ...this.options(extra),
        ...extra,
      },
    );
  }

  getProjectWithFieldAccess(model: ModelName, modelId: number): Observable<Project | Obj> {
    return this.http.get<Project | Obj>(`${this.apiUrl}/checklist/${model}/${modelId}/`, this.options());
  }

  getFields(checklistId: number, projectId: number): Observable<Field[]> {
    return this.http.get<Field[]>(`${this.apiUrl}/project/${projectId}/checklist/${checklistId}/field`, this.options());
  }

  getObjectFields(checklistId: number, objectId: number): Observable<ObjectField[]> {
    return this.http.get<ObjectField[]>(
      `${this.apiUrl}/object/${objectId}/checklist/${checklistId}/object-field`,
      this.options(),
    );
  }

  bulkDelete(
    checklists: Checklist[],
    completeAndArchiveCheck: boolean,
  ): Observable<CollectionResponse<[{ id: number; success: boolean }]>> {
    const options = {
      ...this.options(),
      params: {
        archiveCases: completeAndArchiveCheck,
      },
      body: checklists,
    };

    return this.http.delete<CollectionResponse<[{ id: number; success: boolean }]>>(
      `${this.apiUrl}/checklist/bulk`,
      options,
    );
  }

  createPdf(checklistId: number): Observable<string> {
    if (!checklistId) {
      throw new Error('Checklist id is required');
    }

    return this.http.post<string>(createApiUrl(this.route, checklistId, 'pdf'), {}, this.options());
  }

  async getContractorsForField(checklistId: number, fieldId: number): Promise<Contractor[]> {
    return firstValueFrom(
      this.http.get<Contractor[]>(
        `${this.apiUrl}/checklist/${checklistId}/field/${fieldId}/contractors`,
        this.options(),
      ),
    );
  }

  async getContractorsForObjectField(checklistId: number, objectFieldId: number): Promise<Contractor[]> {
    return firstValueFrom(
      this.http.get<Contractor[]>(
        `${this.apiUrl}/checklist/${checklistId}/object-field/${objectFieldId}/contractors`,
        this.options(),
      ),
    );
  }
}

@Injectable()
export class ChecklistItemService extends HttpService<ChecklistItem> {
  route = 'checklist';
  caseRoute = 'case';

  getItems(checklistId: number): Observable<ChecklistItem[]> {
    return this.http.get<ChecklistItem[]>(`${this.apiUrl}/${this.route}/${checklistId}/item`, this.options);
  }

  getConnectedCase(checklistId: number, itemId: number, caseId: number): Observable<EntityResponse<Case>> {
    return this.http.get<EntityResponse<Case>>(
      `${this.apiUrl}/checklist/${checklistId}/item/${itemId}/deviation/${caseId}`,
      this.options,
    );
  }

  saveCase(model: Case): Observable<Case> {
    return model.id
      ? this.http.post<Case>(`${this.apiUrl}/${this.caseRoute}/${model.id}`, model, this.options)
      : this.http.post<Case>(`${this.apiUrl}/${this.caseRoute}`, model, this.options);
  }

  completeCase(checklistId: number, itemId: number): Observable<Case> {
    return this.http.get<Case>(`${this.apiUrl}/${this.route}/${checklistId}/item/${itemId}/complete`, this.options);
  }

  finishCase(id: number): Observable<Case> {
    return this.http.post<Case>(`${this.apiUrl}/${this.caseRoute}/${id}/finish`, null, this.options);
  }

  reopenCase(checklistId: number, itemId: number): Observable<Case> {
    return this.http.get<Case>(`${this.apiUrl}/${this.route}/${checklistId}/item/${itemId}/reopen`, this.options);
  }

  archiveCase(id: number): Observable<Case> {
    return this.http.get<Case>(`${this.apiUrl}/${this.caseRoute}/${id}/archive`, this.options);
  }

  addContractor(caseId: number, userId: number, notifyContractor: boolean): Observable<Case> {
    const o = this.options;

    o.params = {
      ...this.options.params,
      ContractorId: String(userId),
      notifyContractor: String(notifyContractor),
    };

    return this.http.post<Case>(`${this.apiUrl}/${this.caseRoute}/${caseId}/addContractor`, null, o);
  }

  removeContractor(caseId: number, userId: number): Observable<Case> {
    const o = this.options;

    o.params = {
      ...this.options.params,
      ContractorId: String(userId),
    };

    return this.http.post<Case>(`${this.apiUrl}/${this.caseRoute}/${caseId}/removeContractor`, null, this.options);
  }

  attachFiles(model: Case): Observable<Case> {
    return this.http.post<Case>(`${this.apiUrl}/${this.caseRoute}/${model.id}/attachFiles`, model, this.options);
  }

  removeFiles(model: Case): Observable<Case> {
    return this.http.post<Case>(`${this.apiUrl}/${this.caseRoute}/${model.id}/removeFiles`, model, this.options);
  }

  saveItem(checklistId: number, model: ChecklistItem): Observable<ChecklistItem> {
    return model.id
      ? this.http.post<ChecklistItem>(
          `${this.apiUrl}/${this.route}/${checklistId}/item/${model.id}`,
          model,
          this.options,
        )
      : this.http.post<ChecklistItem>(`${this.apiUrl}/${this.route}/${checklistId}/item`, model, this.options);
  }

  deleteItem(checklistId: number, model: ChecklistItem, archiveCase: boolean): Observable<ChecklistItem> {
    const options = {
      ...this.options,
      params: {
        ...this.options.params,
        archiveCase,
      },
    };

    return this.http.delete<ChecklistItem>(`${this.apiUrl}/${this.route}/${checklistId}/item/${model.id}`, options);
  }

  addCase(checklistItem: ChecklistItem, caseId: number): Observable<boolean> {
    const url = `${this.apiUrl}/${this.route}/${checklistItem.ChecklistId}/item/${checklistItem.id}/add-case/${caseId}`;

    return this.http.get<boolean>(url, this.options);
  }

  removeDeviation(checklistItem: ChecklistItem, deviationId: number): Observable<boolean> {
    const url = createApiUrl(
      'checklist',
      checklistItem.ChecklistId,
      'item',
      checklistItem.id,
      'remove-case',
      deviationId,
    );

    return this.http.get<boolean>(url, this.options);
  }

  saveDeviation(item: ChecklistItem, deviationCase: Case): Observable<EntityResponse<Case>> {
    const url = deviationCase.id
      ? `${this.apiUrl}/checklist/${item.ChecklistId}/item/${item.id}/deviation/${deviationCase.id}`
      : `${this.apiUrl}/checklist/${item.ChecklistId}/item/${item.id}/deviation`;

    return this.http.post<EntityResponse<Case>>(url, deviationCase, this.options);
  }

  getDeviations(item: ChecklistItem, extra?: HttpExtra): Observable<CollectionResponse<Case>> {
    const options = this.options;

    options.params = {
      ...options.params,
      ...extra.params,
    };

    return this.http.get<CollectionResponse<Case>>(
      `${this.apiUrl}/checklist/${item.ChecklistId}/item/${item.id}/deviation`,
      options,
    );
  }

  getDeviation(item: ChecklistItem, id: number): Observable<EntityResponse<Case>> {
    return this.http.get<EntityResponse<Case>>(
      `${this.apiUrl}/checklist/${item.ChecklistId}/item/${item.id}/deviation/${id}`,
      this.options,
    );
  }

  completeDeviation(item: ChecklistItem, id: number): Observable<EntityResponse<Case>> {
    return this.http.get<EntityResponse<Case>>(
      `${this.apiUrl}/checklist/${item.ChecklistId}/item/${item.id}/deviation/${id}/complete`,
      this.options,
    );
  }

  reopenDeviation(item: ChecklistItem, id: number): Observable<EntityResponse<Case>> {
    return this.http.get<EntityResponse<Case>>(
      `${this.apiUrl}/checklist/${item.ChecklistId}/item/${item.id}/deviation/${id}/reopen`,
      this.options,
    );
  }

  postCaseLog(item: ChecklistItem, id: number, log: CaseLog): Observable<EntityResponse<CaseLog>> {
    return this.http.post<EntityResponse<CaseLog>>(
      `${this.apiUrl}/checklist/${item.ChecklistId}/item/${item.id}/deviation/${id}/log`,
      log,
      this.options,
    );
  }

  toggleLog(
    item: ChecklistItem,
    deviationId: number,
    logId: number,
    which: 'contractor' | 'client',
  ): Observable<{ value: number }> {
    return this.http.get<{ value: number }>(
      createApiUrl(
        'checklist',
        item.ChecklistId,
        'item',
        item.id,
        'deviation',
        deviationId,
        'log',
        logId,
        'toggle',
        which,
      ),
      this.options,
    );
  }

  addDeviationContractor(
    item: ChecklistItem,
    deviationId: number,
    contractor: Contractor,
  ): Observable<EntityResponse<Case>> {
    return this.http.post<EntityResponse<Case>>(
      `${this.apiUrl}/checklist/${item.ChecklistId}/item/${item.id}/deviation/${deviationId}/contractor`,
      contractor,
      this.options,
    );
  }

  removeDeviationContractor(item: ChecklistItem, deviationId: number, contractorId): Observable<EntityResponse<Case>> {
    return this.http.delete<EntityResponse<Case>>(
      createApiUrl(
        'checklist',
        item.ChecklistId,
        'item',
        item.id,
        'deviation',
        deviationId,
        'contractor',
        contractorId,
      ),
      this.options,
    );
  }
}
