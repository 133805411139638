import { Params } from '@angular/router';

export class CaseParams implements Params {
  Category?: number | number[];
  Client?: number | number[];
  ContractorId?: number | number[];
  Field?: number | number[];
  Project?: number | number[];
  Apartment?: number | number[];
  apartmentFieldNumberSearch?: string;
  deadline?: boolean;
  onlyUnread?: boolean;
  showArchived?: boolean;
  showCaseManagers?: boolean;
  showCompleted?: boolean;
  showOpen?: boolean;
  sort?: string;
  sortOrder?: string;
  textSearch?: string;
  page?: number;
  CaseStatus?: string[];
  deadlineFrom?: number;
  deadlineTo?: number;
  CaseManager?: number | number[];
  Object?: number | number[];
  ObjectField?: number | number[];
  from?: string;
  to?: string;
  Tenancy?: number | number[];
  Agreement?: number | number[];
  Checklist?: number | number[];
  hideWithChecklist?: boolean;
  showChecklistItems?: boolean;
  calendar?: boolean;
  Ownership?: string | string[];

  Contractor?: number | number[];

  showNoCaseManager?: boolean;
  showNoContractor?: boolean;
  showNoField?: boolean;
  hideFromProjectMom?: boolean;

  overdue?: boolean;
  Meter?: number | number[];

  Tag?: string | string[];
}
