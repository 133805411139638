import { Directive, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SizeService } from './size.service';
import { SizeBreakpoint, SizeBreakpoints } from './size.types';

@Directive({
  selector: '[apexSize]',
})
export class SizeDirective implements OnInit, OnDestroy {
  @Input() apexSizePrefixClass = 'apex-size';
  @Input() apexSizeBreakPoint: SizeBreakpoint = 'disabled';

  @Output() apexSize: EventEmitter<ResizeObserverEntry> = new EventEmitter<ResizeObserverEntry>();

  @HostBinding('class')
  get class(): string {
    let isBreak = `${this.apexSizePrefixClass}-break-disabled`;

    if (this.apexSizeBreakPoint) {
      if (this.currentWidth > SizeBreakpoints[this.apexSizeBreakPoint]) {
        isBreak = `${this.apexSizePrefixClass}-above-break`;
      } else if (this.currentWidth < SizeBreakpoints[this.apexSizeBreakPoint]) {
        isBreak = `${this.apexSizePrefixClass}-below-break`;
      } else {
        isBreak = `${this.apexSizePrefixClass}-equal-break`;
      }
    }

    return isBreak;
  }

  private currentWidth = 0;

  constructor(
    private elementRef: ElementRef<Element>,
    private service: SizeService,
  ) {}

  ngOnInit(): void {
    this.register();
  }

  ngOnDestroy(): void {
    this.unregister();
  }

  private register(): void {
    this.service.register(this.elementRef.nativeElement, (entry) => this.callback(entry));
  }

  private unregister(): void {
    this.service.unregister(this.elementRef.nativeElement);
  }

  private callback(entry: ResizeObserverEntry): void {
    this.currentWidth = entry.contentRect.width;

    this.apexSize.emit(entry);
  }
}
