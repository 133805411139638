import { Checklist } from '../features/checklist/checklist.model';
import { Obj } from '../features/object/object.model';
import { Apartment } from './apartment';
import { ChecklistGroupTemplate } from './checklist-group-template';
import { Customer } from './customer';
import { Field } from './field';
import { Model } from './model';
import { ObjectField } from './object-field';
import { Project } from './project';
import { User } from './user';

export class ChecklistGroup extends Model<ChecklistGroup> {
  CreatorId: number;
  Creator?: User;

  CustomerId: number;
  Customer?: Customer;

  ParentId: number;
  Parent: ChecklistGroup;

  ChecklistGroupTemplateId: number;
  ChecklistGroupTemplate: ChecklistGroupTemplate;

  ObjectId: number;
  Object: Obj;

  ProjectId: number;
  Project: Project;

  FieldId?: number;
  Field?: Field;

  ObjectFieldId?: number;
  ObjectField?: ObjectField;

  ApartmentId: number;
  Apartment: Apartment;

  CaseManagerId: number;
  CaseManager: User;

  name: string;
  description: string;
  sortOrder: number;

  Children?: ChecklistGroup[];
  Checklists?: Checklist[];

  caseCount?: number;
  casesCompleted?: number;
}

export class ChecklistGroupEntity extends ChecklistGroup {
  selected?: boolean;
}
