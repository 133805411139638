import { Pipe, PipeTransform } from '@angular/core';
import { ResponseSidebarItemPinnedDto, SidebarModel } from '../../../../../../../generated/apex-rest';
import { SidebarItem } from '../sidebar-items.types';

@Pipe({
  name: 'filterSidebarItems',
  standalone: true,
})
export class FilterSidebarItemsPipe implements PipeTransform {
  transform(
    sidebarItems: SidebarItem[],
    responseSidebarItemPinnedDtos: ResponseSidebarItemPinnedDto[],
    pinned = true,
  ): SidebarItem[] {
    if (!responseSidebarItemPinnedDtos?.length && !sidebarItems?.length) {
      return null;
    }

    // Creating a set of model IDs of the items which are pinned in the responseSidebarItemsCollection
    const sidebarItemPinnedIds: Set<string> = new Set(
      responseSidebarItemPinnedDtos.map(
        (responseSidebarItemPinnedDto: ResponseSidebarItemPinnedDto) => responseSidebarItemPinnedDto.modelId,
      ),
    );

    // Creating an array of SidebarItems for each bookmark
    const sidebarItemsForBookmarks: SidebarItem[] = responseSidebarItemPinnedDtos
      .filter((item: ResponseSidebarItemPinnedDto): boolean => item.model === SidebarModel.Bookmark)
      .map(
        (responseSidebarItemPinnedDto: ResponseSidebarItemPinnedDto): SidebarItem => ({
          routerLink: responseSidebarItemPinnedDto.url,
          icon: 'bookmark',
          translation: responseSidebarItemPinnedDto.name,
          modelId: responseSidebarItemPinnedDto.modelId,
          sortOrder: responseSidebarItemPinnedDto.sortOrder,
        }),
      );

    return sidebarItems
      .filter((sidebarItem: SidebarItem): boolean =>
        pinned ? sidebarItemPinnedIds.has(sidebarItem.routerLink) : !sidebarItemPinnedIds.has(sidebarItem.routerLink),
      )
      .concat(pinned ? sidebarItemsForBookmarks : []);
  }
}
