import { Injectable } from '@angular/core';
import {
  CreateHelpUrlDto,
  HelpURLApi,
  ResponseHelpUrlDto,
  UpdateHelpUrlDto,
} from '../../../../../../../generated/apex-rest';
import { restAxiosConfig } from '../../../utils/rest-axios-config';

@Injectable()
export default class HelpURLService {
  private helpURLApi: HelpURLApi;

  constructor() {
    this.helpURLApi = new HelpURLApi(restAxiosConfig());
  }

  async readCollection(): Promise<ResponseHelpUrlDto[]> {
    const res = await this.helpURLApi.helpUrlControllerReadCollection();

    return res.data.Collection;
  }

  async readCollectionByLanguage(language: string): Promise<ResponseHelpUrlDto[]> {
    const res = await this.helpURLApi.helpUrlControllerReadCollectionByLanguage(language);

    return res.data.Collection;
  }

  async create(createDto: CreateHelpUrlDto): Promise<ResponseHelpUrlDto> {
    const res = await this.helpURLApi.helpUrlControllerCreate(createDto);

    return res.data.Entity;
  }

  async delete(helpURL: ResponseHelpUrlDto): Promise<boolean> {
    const res = await this.helpURLApi.helpUrlControllerDelete(helpURL.id);

    return res.data.deleted;
  }

  async update(helpURL: ResponseHelpUrlDto, updateDto: UpdateHelpUrlDto): Promise<ResponseHelpUrlDto> {
    const res = await this.helpURLApi.helpUrlControllerUpdate(helpURL.id, updateDto);

    return res.data.Entity;
  }
}
