import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import moment from 'moment';

@Directive({
  selector: '[apexDateTime]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidateDateTimeInputDirective,
      multi: true,
    },
  ],
})
export class ValidateDateTimeInputDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    if (control?.value === null) {
      return { invalid: true };
    }

    return null;
  }
}

@Directive({
  selector: '[apexValidateTime]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidateTimeDirective,
      multi: true,
    },
  ],
})
export class ValidateTimeDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    if (control?.value) {
      const timeVal = moment(control.value, 'HH:mm');

      return { invalidTimeInput: !timeVal.isValid() };
    }

    return null;
  }
}
