import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { QrCodeDialogData } from '../qr-code-dialog/qr-code-dialog.data';
import QrCodeImageComponent from '../qr-code-image/qr-code-image.component';
import { TranslateModule } from '../translate/translate.module';

@Component({
  selector: 'apex-qr-code-image-container',
  templateUrl: './qr-code-image-container.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    QrCodeImageComponent,
    TranslateModule,
  ],
})
export default class QrCodeImageContainerComponent {
  @Input() data: QrCodeDialogData;
  @ViewChild(QrCodeImageComponent) qrCodeImageComponent?: QrCodeImageComponent;

  async download(): Promise<void> {
    await this.qrCodeImageComponent?.download();
  }

  async copyToClipboard(): Promise<void> {
    await this.qrCodeImageComponent?.copyToClipboard();
  }
}
