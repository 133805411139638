import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { HistoryBackModule } from 'projects/apex/src/app/directives/history-back/history-back.module';
import { HighlightPipeModule } from 'projects/apex/src/app/pipes/highlight/highlight-pipe.module';
import { EntryGroupService } from './entry-group.service';
import { EntryGroupComponent } from './form.component';
import { EntryGroupsComponent } from './list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    RouterModule,
    MatDividerModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatRippleModule,

    TranslateModule,
    HistoryBackModule,
    HighlightPipeModule,
  ],
  exports: [EntryGroupComponent, EntryGroupsComponent],
  declarations: [EntryGroupComponent, EntryGroupsComponent],
  providers: [EntryGroupService],
})
export class EntryGroupModule {}
