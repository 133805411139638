import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';
import { t } from '../../components/translate/translate.function';

const valueOrFallback = (value: unknown, fallback: string): string => {
  const stringValue = String(value);

  if (isNil(value) || stringValue === '') {
    return fallback;
  }

  return stringValue;
};

const valueFromObjOrFallback = <T>(obj: T, property: keyof T, fallback: string): string => {
  const valueFromObj = obj?.[property];

  return valueOrFallback(valueFromObj, fallback);
};

@Pipe({
  name: 'propertyOrNone',
})
export class PropertyOrNonePipe implements PipeTransform {
  /**
   * Extract a property and returns it as string or uses fallback which by default is 'None'.
   *
   * @param obj
   * @param property
   * @param fallback
   */
  transform<T>(obj: T, property: keyof T, fallback = ''): string {
    const fallbackEvaluated = fallback || t('None');

    return valueFromObjOrFallback(obj, property, fallbackEvaluated);
  }
}

@Pipe({
  name: 'nameOrNone',
})
export class NameOrNonePipe extends PropertyOrNonePipe implements PipeTransform {
  transform(obj?: { name?: string }): string {
    return super.transform(obj, 'name');
  }
}

@Pipe({
  name: 'valueOrNone',
})
export class ValueOrNonePipe implements PipeTransform {
  transform(value: unknown, fallback = ''): string {
    const fallbackEvaluated = fallback || t('None');

    return valueOrFallback(value, fallbackEvaluated);
  }
}

@Pipe({
  name: 'propertyOrNotSet',
})
export class PropertyOrNotSetPipe implements PipeTransform {
  /**
   * Extract a property and returns it as string or uses fallback which by default is 'None'.
   *
   * @param obj
   * @param property
   * @param fallback
   */
  transform<T>(obj: T, property: keyof T, fallback = ''): string {
    const fallbackEvaluated = fallback || t('Not set');

    return valueFromObjOrFallback(obj, property, fallbackEvaluated);
  }
}
