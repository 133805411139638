import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DeleteResponse } from 'projects/apex/src/app/utils/types';

import { BaseService } from '../../../services/http/http.service';

@Injectable()
export class ApartmentActionsService extends BaseService<null> {
  public deleteSale(projectId: number, apartmentId: number): Observable<DeleteResponse> {
    return this.http.delete<DeleteResponse>(`${this.url(projectId, apartmentId)}/delete-sale`, this.options);
  }

  public deleteTakeover(projectId: number, apartmentId: number): Observable<DeleteResponse> {
    return this.http.delete<DeleteResponse>(`${this.url(projectId, apartmentId)}/delete-takeover`, this.options);
  }

  private url(projectId: number, apartmentId: number): string {
    return `${this.apiUrl}/project/${projectId}/apartment/${apartmentId}`;
  }
}
