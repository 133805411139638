<div mat-dialog-title>
  <div class="df jsb ac f1">
    <h1>
      {{ deviation?.name }}
      <ng-container *ngIf="!deviation?.name">
        <T str="New case"></T>
      </ng-container>
    </h1>
    <button
      mat-icon-button
      (click)="dialogRef.close()">
      <mat-icon> close</mat-icon>
    </button>
  </div>
</div>
<div
  mat-dialog-content
  class="df c jsb">
  <form
    (submit)="save()"
    #form="ngForm">
    <ng-container *ngIf="isCase; else formFields">
      <apex-title-item class="amb">
        <T
          title
          str="Name"></T>

        {{ deviation.name }}
      </apex-title-item>

      <ng-container *ngIf="deviation.description">
        <apex-title-item>
          <T
            title
            str="Description"></T>

          {{ deviation.description }}
        </apex-title-item>
      </ng-container>
    </ng-container>
    <ng-template #formFields>
      <div class="df">
        <mat-form-field class="f1">
          <mat-label>
            <T str="Name"></T>
          </mat-label>
          <input
            matInput
            cdkFocusInitial
            required
            [disabled]="saving"
            type="text"
            name="name"
            [(ngModel)]="deviation.name" />
        </mat-form-field>
      </div>
      <div class="df">
        <mat-form-field class="f1">
          <mat-label>
            <T str="Description"></T>
          </mat-label>
          <input
            matInput
            [disabled]="saving"
            type="text"
            name="description"
            [(ngModel)]="deviation.description" />
        </mat-form-field>
      </div>
    </ng-template>
    <div class="df c">
      <ng-container *ngIf="!!data.CaseManagerId && isNew && !isCase">
        <div class="df jfs ambs">
          <mat-checkbox
            name="setCaseManager"
            [(ngModel)]="setCaseManager">
            <div class="df">
              <T str="Set case manager"></T>
              <apex-autocomplete-viewer
                class="amls"
                type="User"
                [id]="data.CaseManagerId">
              </apex-autocomplete-viewer>
            </div>
          </mat-checkbox>
        </div>
      </ng-container>
      <div class="df r amts">
        <mat-form-field class="f1 amr">
          <mat-label>
            <T str="Deadline"></T>
          </mat-label>
          <input
            required
            matInput
            name="deadlineAt"
            [disabled]="!isNew"
            [matDatepicker]="picker"
            [(ngModel)]="deadlineAt" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker">
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <div class="f1">
          <apex-autocomplete
            *ngIf="!!data?.deviationCase?.ProjectId"
            type="Field"
            name="FieldId"
            [(ngModel)]="deviation.FieldId"
            (ngModelChange)="fetchFieldContractors(deviation.FieldId)"
            [label]="'Field' | translate"
            [params]="{
              ProjectId: data?.deviationCase?.ProjectId ?? null,
            }">
          </apex-autocomplete>

          <apex-autocomplete
            *ngIf="!!data?.deviationCase?.ObjectId"
            type="ObjectField"
            name="ObjectFieldId"
            [(ngModel)]="deviation.ObjectFieldId"
            (ngModelChange)="fetchObjectFieldContractors(deviation.ObjectFieldId)"
            [label]="'Field' | translate"
            [params]="{
              ObjectId: data?.deviationCase?.ObjectId ?? null
            }">
          </apex-autocomplete>
        </div>
      </div>
    </div>
    <ng-container *ngIf="floorPlans?.length">
      <div class="amv">
        <apex-embedded-markings-viewer
          #marking
          [fileUsages]="floorPlans"
          [model]="MarkingModelType.Case"
          [modelId]="deviation.id"
          class="amb"
          (markingsChanged)="updateMarkings($event)">
        </apex-embedded-markings-viewer>
      </div>
    </ng-container>
    <div class="amv">
      <apex-case-contractors
        [case]="deviation"
        [disabled]="saving"
        (addContractor)="addContractor($event)"
        (removeContractor)="removeContractor($event)">
      </apex-case-contractors>
    </div>
    <apex-file-usage
      #fileusage
      [self]="self"
      name="files"
      [title]="'Files' | translate"
      [selfId]="deviation.id ?? 0"
      (fileUsagesChange)="deviation.FileUsages = $event">
    </apex-file-usage>
    <div class="amv">
      <T
        *ngIf="deviation.CaseCategoryId | categoryIsPublicForClient"
        str="Note: Files are visible to customer and contractor"></T>
      <T
        *ngIf="!(deviation.CaseCategoryId | categoryIsPublicForClient)"
        str="Note: Files are visible contractor"></T>
    </div>
  </form>
  <ng-container *ngIf="!isNew">
    <div class="ap mat-elevation-z2 amv new-message">
      <apex-case-new-message
        #messages
        [cases]="[ deviation ]"
        [disabled]="saving">
      </apex-case-new-message>
      <div class="df jfe">
        <button
          mat-raised-button
          type="button"
          (click)="saveCaseLog()"
          [disabled]="logIsDisabled"
          color="primary">
          <T str="Save"></T>
        </button>
      </div>
    </div>

    <apex-case-logs
      [disableToggleActions]="true"
      [disableMessageThumbnails]="true"
      [caseLogs]="deviation?.CaseLogs ?? []">
    </apex-case-logs>
  </ng-container>
</div>
<div
  mat-dialog-actions
  class="df jsb ap">
  <button
    mat-raised-button
    [disabled]="saving"
    (click)="dialogRef.close({ deviationCase: null })">
    <T str="Close"></T>
  </button>
  <button
    mat-raised-button
    (click)="save()"
    [disabled]="form.pristine || saving || form.invalid"
    color="primary">
    <T str="Save"></T>
  </button>
</div>
