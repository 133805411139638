import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { Observable, from, map, mergeMap, of } from 'rxjs';
import { AuthApi, ResponseAssumeUserDto, UserApi } from '../../../../../../generated/apex-rest';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/user/user.service';
import { restAxiosConfig } from '../../utils/rest-axios-config';
import { TranslateModule } from '../translate/translate.module';

@Component({
  selector: 'apex-assume-user',
  templateUrl: './assume-user.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatMenuModule, MatButtonModule],
})
export class AssumeUserComponent implements OnInit {
  assumeUsers$: Observable<Array<ResponseAssumeUserDto>>;
  userId: number;

  private readonly authApi = new AuthApi(restAxiosConfig());
  private readonly userApi = new UserApi(restAxiosConfig());

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userId = this.userService.profile?.id;

    this.assumeUsers$ = from(this.userService.profile$).pipe(
      mergeMap((profile) => {
        if (!profile?.id) {
          return of({ data: { Collection: [] } });
        }

        return this.userApi.userControllerGetAssumeUsers(profile.id);
      }),
      map((res) => res?.data?.Collection ?? []),
    );
  }

  async assumeUser(userId: number): Promise<void> {
    const restApiUrl = environment.restApi;
    const assumeUserUrl = `${restApiUrl}/auth/assume/${userId}`;

    window.location.href = assumeUserUrl;
  }
}
