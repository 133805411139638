import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Contractor } from '../../models/case';
import { booleanFromBooleanOrString, getStatusIcon, getStatusMessage } from '../../utils/functions';
import UserCardOverlayComponent from './user-card-overlay.component';
import { CardData } from './user-card.types';
import { UserWithCustomerOrCompany } from './user-with-customer-or-company.type';

@Directive({
  selector: '[apexUserCard]',
})
export class UserCardDirective implements OnInit, OnChanges, OnDestroy {
  @Input('apexUserCard') user: UserWithCustomerOrCompany;
  @Input() subtitle = '';
  @Input() subtitleIcon = '';

  @Input() get client(): boolean | string {
    return this.innerClient;
  }

  set client(value: boolean | string) {
    this.innerClient = booleanFromBooleanOrString(value);
  }

  @Input() get clientUrl(): (string | number)[] | string | null | undefined {
    return this.innerClientUrl;
  }

  set clientUrl(value: (string | number)[] | string | null | undefined) {
    this.innerClientUrl = value;
  }

  @HostBinding('class.clickable') isClickable = true;
  @HostBinding('class.apex-user-card') isUserCard = true;

  @HostBinding('attr.aria-haspopup') hostAriaHaspopup = true;

  @HostBinding('attr.aria-label') get hostAriaLabel(): string {
    return this.user?.name ?? '';
  }

  private subscriptions = new Subscription();

  private overlayRef: OverlayRef;
  private positionStrategy = this.overlay
    .position()
    .flexibleConnectedTo(this.elementRef)
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetX: -12,
        offsetY: 12,
        panelClass: 'is-under',
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetX: -12,
        offsetY: -12,
        panelClass: 'is-over',
      },
    ]);

  private innerClient = false;
  private innerClientUrl: (string | number)[] | string | null | undefined;

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private injector: Injector,
    private router: Router,
  ) {}

  @HostListener('click', ['$event'])
  hostClick($event: Event): void {
    $event?.stopPropagation();
    $event?.preventDefault();

    if (!this.overlayRef?.hasAttached()) {
      this.createOverlay();
    } else {
      this.overlayRef?.detach();
    }
  }

  ngOnInit(): void {
    this.subscriptions.add(this.router.events.subscribe((_) => this.overlayRef?.detach()));
  }

  ngOnChanges(simpleChange: SimpleChanges): void {
    if (simpleChange.user) {
      const contractorStatus = (this.user as Contractor)?.CaseContractor?.status;

      this.subtitle = this.subtitle ?? getStatusMessage(contractorStatus);
      this.subtitleIcon = this.subtitleIcon ?? getStatusIcon(contractorStatus);
    }
  }

  ngOnDestroy(): void {
    this.overlayRef?.detach();
    this.overlayRef?.dispose();
    this.overlayRef = null;

    this.subscriptions.unsubscribe();
  }

  get cardData(): CardData {
    return {
      user: this.user,
      isClient: this.innerClient,
      clientUrl: this.innerClientUrl,
      subtitle: this.subtitle,
      subtitleIcon: this.subtitleIcon,
    };
  }

  private createOverlay(): void {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        disposeOnNavigation: true,
        positionStrategy: this.positionStrategy,
        panelClass: ['overlay-apex-user-card'],
      });
    }

    if (!this.overlayRef.hasAttached()) {
      const userCardPortal = new ComponentPortal(
        UserCardOverlayComponent,
        null,
        Injector.create({
          parent: this.injector,
          providers: [
            {
              provide: OverlayRef,
              useValue: this.overlayRef,
            },
            {
              provide: CardData,
              useValue: this.cardData,
            },
          ],
        }),
      );

      this.overlayRef.attach(userCardPortal);
    }
  }
}
