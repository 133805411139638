<mat-expansion-panel
  class="expansion-box"
  #expansionPanel
  [expanded]="expanded"
  [hideToggle]="!expandable || disabled"
  [disabled]="!expandable || disabled">
  <mat-expansion-panel-header
    class="df ac"
    [ngClass]="{
      'bg-color-main': !disabled,
      'bg-color-disabled': disabled,
      'bg-color-warn': invalid,
    }">
    <mat-panel-title class="df ac oh f1">
      <ng-container *ngIf="disabled; else showIcon">
        <mat-icon class="amrs">lock</mat-icon>
      </ng-container>

      <ng-template #showIcon>
        <span
          class="df ac amrs"
          #iconRef
          [class.hide]="!iconRef?.childNodes?.length">
          <ng-content
            select="[icon]"
            #iconRef>
          </ng-content>
        </span>

        <ng-container *ngIf="icon && !iconRef?.childNodes?.length && !invalid">
          <mat-icon class="amrs"> {{ icon }} </mat-icon>
        </ng-container>

        <ng-container *ngIf="invalid && errorIcon && !iconRef?.childNodes?.length">
          <mat-icon class="amrs">{{ errorIcon }}</mat-icon>
        </ng-container>
      </ng-template>
      <section class="df c oh f1">
        <span
          class="truncate"
          #titleRef>
          <ng-content select="[title]"></ng-content>
        </span>
        <span class="truncate mat-caption">
          <ng-content select="[description]"></ng-content>
        </span>
      </section>
    </mat-panel-title>

    <span
      [class.amrs]="!expandable && ref?.childNodes?.length"
      #ref>
      <ng-content
        select="[right]"
        #ref></ng-content>
    </span>

    <div
      [ngClass]="{
        amr: expandable,
      }">
      <ng-content select="[right-buttons]"></ng-content>
    </div>
  </mat-expansion-panel-header>

  <div
    class="position-absolute top-0 left-0 w-100"
    [class.opacity-1]="loading"
    [class.opacity-0]="!loading">
    <mat-progress-bar
      [mode]="loadingMode"
      [value]="loadingProgress">
    </mat-progress-bar>
  </div>

  <ng-content></ng-content>
</mat-expansion-panel>
