import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { of } from 'rxjs';
import { EntryCard } from './entry-card.model';
import { EntryCardService } from './entry-card.service';

export const EntryCardResolver: ResolveFn<EntryCard> = (route: ActivatedRouteSnapshot) => {
  const service = inject(EntryCardService);

  const tid = Number(route.paramMap.get('tid'));
  const eid = Number(route.paramMap.get('eid'));

  service.route = `tenancy/${tid}/entry-card`;

  return tid ? (eid ? service.get(eid) : of(new EntryCard())) : of(new EntryCard());
};

export const EntryCardsResolver: ResolveFn<EntryCard[]> = (route: ActivatedRouteSnapshot) => {
  const service = inject(EntryCardService);

  const tid = Number(route.paramMap.get('tid'));

  service.route = `tenancy/${tid}/entry-card`;

  return tid ? service.query() : of([]);
};
