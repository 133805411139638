import { inject } from '@angular/core';

import { ResolveFn } from '@angular/router';
import { filter, firstValueFrom } from 'rxjs';
import { ResponseHelpUrlDto } from '../../../../../../../generated/apex-rest';
import { UserService } from '../../../services/user/user.service';
import HelpURLService from '../services/help-url.service';

export const HelpURLCollectionByLanguageResolver: ResolveFn<ResponseHelpUrlDto[]> = async () => {
  const helpURLService = inject(HelpURLService);
  const userService = inject(UserService);

  const user = await firstValueFrom(userService.profile$.pipe(filter((p) => !!p)));

  return helpURLService.readCollectionByLanguage(user.locale ?? 'en');
};
