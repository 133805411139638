<div mat-dialog-title>
  <div class="df f1 jsb ac">
    <h1>
      <ng-container *ngIf="case?.id || case?.RepeatableCase?.id">
        {{ case.name }}
      </ng-container>
      <ng-container *ngIf="!(case?.id || case?.RepeatableCase?.id)">
        <T
          str="New repeatable case"
          *ngIf="formComponent?.createRepeatableCase"></T>
        <T
          str="New case"
          *ngIf="!formComponent?.createRepeatableCase"></T>
      </ng-container>
    </h1>
    <div class="df ac">
      <ng-container *ngIf="!case?.fromRequest">
        <apex-qr-code *ngIf="!case.id"></apex-qr-code>
      </ng-container>
      <ng-container *ngIf="case?.fromRequest">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            (click)="navigateAndViewMom()">
            <ng-container>
              <a><T str="View MOM"></T> </a>
            </ng-container>
          </button>
          <button
            mat-menu-item
            (click)="navigateAndViewCases()">
            <ng-container>
              <a><T str="View cases"></T> </a>
            </ng-container>
          </button>
          <button
            mat-menu-item
            (click)="navigateAndViewRequests()">
            <ng-container>
              <a><T str="View requests"></T> </a>
            </ng-container>
          </button>
          <button
            mat-menu-item
            class="jc">
            <apex-qr-code *ngIf="!case.id"></apex-qr-code>
          </button>
        </mat-menu>
      </ng-container>

      <button
        mat-icon-button
        (click)="dialogRef.close()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <ng-container *ngIf="info">
    <div class="yellow-info-card ap ams display-grid">
      <ng-container *ngFor="let entry of info | keyvalue">
        <div class="df c">
          <span class="mat-caption">{{ entry.key }}</span>
          {{ entry.value }}
        </div>
      </ng-container>
    </div>
  </ng-container>
  <apex-case-form
    #formComponent
    [case]="case"
    [createRepeatableCase]="data?.createRepeatableCase">
  </apex-case-form>
</div>
<div mat-dialog-actions>
  <div
    class="df ac f1"
    *ngIf="formComponent">
    <div class="f1">
      <button
        class="btn-save-and-continue"
        mat-button
        color="primary"
        [disabled]="formComponent?.loading || formComponent?.invalid || !showSaveAndContinue"
        (click)="saveAndContinue()">
        <T str="Save and continue"></T>
      </button>
    </div>
    <div class="df ac">
      <mat-slide-toggle
        name="notifySMS"
        class="amrs"
        [matTooltip]="'Send SMS to contractors and customers' | translate"
        *ngIf="!case.id"
        [(ngModel)]="case.sendSMS">
        <T str="SMS"></T>
      </mat-slide-toggle>
      <button
        class="btn-cancel"
        mat-button
        type="button"
        (click)="dialogRef.close(case)">
        <T str="Cancel"></T>
      </button>

      <div class="df ac">
        <button
          *ngIf="case?.ProjectId || case?.ObjectId"
          [disabled]="formComponent?.loading || formComponent?.invalid"
          (click)="saveAndComplete()"
          mat-raised-button>
          <T str="Save and complete"></T>
        </button>
        <button
          (click)="save()"
          [disabled]="formComponent.invalid || formComponent.loading"
          mat-raised-button
          color="primary">
          <T str="Save"></T>
        </button>
      </div>

      <!-- <button
        class="btn-save"
        mat-raised-button
        type="button"
        color="primary"
        (click)="save()"
        [disabled]="formComponent?.loading || formComponent?.invalid">
        <T
          *ngIf="!formComponent.loading"
          str="Save"></T>
        <div
          *ngIf="formComponent.loading"
          class="df ac jc">
          <mat-spinner [diameter]="24"></mat-spinner>
        </div>
      </button>-->
    </div>
  </div>
</div>
