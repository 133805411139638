import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResponseFileUsageDto } from '../../../../../../generated/apex-rest';
import { FileUsageViewerDialogComponent } from '../../components/file-usage-viewer/file-usage-viewer-dialog.component';
import { FileUsageViewerData, FileUsageViewerMode } from '../../components/file-usage-viewer/file-usage-viewer.types';
import { FileUsage } from '../../models/file-usage';

@Directive({
  selector: '[apexFileUsageViewer]',
})
export class FileUsageViewerDirective {
  @HostBinding('class.disabled')
  get disabled(): boolean {
    return !this.fileUsage;
  }

  @HostListener('click')
  click(): void {
    if (!this.disabled) {
      this.matDialog.open<FileUsageViewerDialogComponent, FileUsageViewerData>(FileUsageViewerDialogComponent, {
        data: {
          fileUsages: [this.getFileUsage()],
          mode: FileUsageViewerMode.View,
          editable: false,
          client: false,
        },
      });
    }
  }

  @Input('apexFileUsageViewer') fileUsage: FileUsage | ResponseFileUsageDto | null = null;

  constructor(private matDialog: MatDialog) {}

  private getFileUsage(): FileUsage {
    return {
      ...this.fileUsage,
      createdAt: this.fileUsage?.createdAt ? new Date(this.fileUsage.createdAt) : null,
      updatedAt: this.fileUsage?.updatedAt ? new Date(this.fileUsage.updatedAt) : null,
      deletedAt: this.fileUsage?.deletedAt ? new Date(this.fileUsage.deletedAt) : null,
      User: null,
      Markings: [],

      File: {
        ...this.fileUsage?.File,
        createdAt: this.fileUsage?.File?.createdAt ? new Date(this.fileUsage.File.createdAt) : null,
        updatedAt: this.fileUsage?.File?.updatedAt ? new Date(this.fileUsage.File.updatedAt) : null,
        deletedAt: this.fileUsage?.File?.deletedAt ? new Date(this.fileUsage.File.deletedAt) : null,

        Creator: null,
      },
    };
  }
}
