import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChecklistGroupFormPageComponent } from 'projects/apex/src/app/features/checklist-group/pages/form.component';
import { ChecklistGroupListPageComponent } from 'projects/apex/src/app/features/checklist-group/pages/list.component';
import { EmptyComponent } from '../../components/empty/empty.component';
import {
  ChecklistGroupResolver,
  ChecklistGroupsResolver,
  FromChecklistGroupTemplateResolver,
} from './checklist-group.resolver';

export const routes: Routes = [
  {
    path: '',
    component: ChecklistGroupListPageComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      groups: ChecklistGroupsResolver,
    },
  },
  {
    path: 'from-template/:tid',
    component: EmptyComponent,
    resolve: {
      fromTemplate: FromChecklistGroupTemplateResolver,
    },
  },
  {
    path: ':id',
    component: ChecklistGroupFormPageComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      group: ChecklistGroupResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ChecklistGroupRoutingModule {}
