import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgreementResolver, AgreementsResolver } from './agreement.resolver';
import { AgreementComponent } from './form.component';
import { AgreementsComponent } from './list.component';

export const routes: Routes = [
  {
    path: '',
    component: AgreementsComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      agreements: AgreementsResolver,
    },
  },
  {
    path: 'new',
    component: AgreementComponent,
    resolve: {
      agreement: AgreementResolver,
    },
  },
  {
    path: ':aid',
    component: AgreementComponent,
    resolve: {
      agreement: AgreementResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AgreementRoutingModule {}
