import { Pipe, PipeTransform } from '@angular/core';
import { interval } from 'rxjs';

import { getExpiredFromURL } from '../../utils/functions';

type URLCacheKey = string;

type URLCacheData = {
  url: string;
  expires: number;
};

class URLCache {
  private cache = new Map<URLCacheKey, URLCacheData>();

  constructor() {
    interval(1000 * 60).subscribe((_) => this.evict());
  }

  get(url: string): string {
    if (!url) {
      return '';
    }

    const u = new URL(url);
    const k = this.key(u);

    const f = this.cache.get(k);

    if (!f) {
      this.cache.set(k, {
        url,
        expires: getExpiredFromURL(url),
      });

      return url;
    }

    const expiresIn = f.expires - Math.floor(Date.now() / 1000);

    if (expiresIn < 60) {
      this.cache.set(k, {
        url,
        expires: getExpiredFromURL(url),
      });

      return url;
    }

    return f.url;
  }

  key(url: URL): URLCacheKey {
    return `${url.hostname}${url.pathname}`;
  }

  get size(): number {
    return this.cache.size;
  }

  del(key: URLCacheKey): boolean {
    return this.cache.delete(key);
  }

  clear(): void {
    return this.cache.clear();
  }

  evict(ttl = 60): number {
    let count = 0;

    for (const [key, value] of this.cache) {
      const expiresIn = value.expires - Math.floor(Date.now() / 1000);

      if (expiresIn <= ttl) {
        this.cache.delete(key);
        count++;
      }
    }

    return count;
  }
}

/**
 * The actual cache.
 */
export const urlCache = new URLCache();

/**
 * Helper function.
 *
 * @param url URL to cache.
 * @returns URL to use.
 */
export const getURLFromCache = (url: string): string => urlCache.get(url);

@Pipe({
  name: 'urlCache',
})
export class URLCachePipe implements PipeTransform {
  transform(url: string): string {
    return getURLFromCache(url);
  }
}
