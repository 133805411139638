import { Injectable } from '@angular/core';
import { HttpService } from 'projects/apex/src/app/services/http/http.service';
import { Observable } from 'rxjs';
import { SearchResult } from './search.model';

@Injectable()
export class SearchService extends HttpService<{ id: number }> {
  route = 'search';

  search(query: string): Observable<SearchResult[]> {
    const params = { params: { q: query } };

    return this.http.get<SearchResult[]>(`${this.apiUrl}/${this.route}`, Object.assign(this.options, params));
  }
}
