import { environment } from '../../environments/environment';
import { hasUserCustomerRight } from '../utils/functions';
import { CustomerRight } from './customer-right';
import { User } from './user';
import { UserFavorite } from './user-favorite';

interface UserPreferencesUnread {
  systemMessage: boolean;
  contractorMessage: boolean;
  caseManagerMessage: boolean;
  clientMessage: boolean;
  statusChangeAccepted: boolean;
  statusChangeDenied: boolean;
  statusChangeCompleted: boolean;
  statusChangeFinished: boolean;
  statusChangeReopened: boolean;
  caseChanged: boolean;
}

export class Profile extends User {
  /**
   * Represents the user's "secret" part of ical URL.
   */
  ical: string;

  /**
   * User's Favorties.
   */
  UserFavorites: UserFavorite[] = [];

  /**
   * User's preferences for unread.
   */
  UserPreferencesUnread?: UserPreferencesUnread;

  /**
   * How many tenancies where user is 'administrator'.
   */
  tenancyCount?: number;
  /**
   * How many agreements where user is 'involved'.
   */
  agreementsCount?: number;
  /**
   * How many tenancies where user is 'administrator'.
   */
  salesCount?: number;

  /**
   * How many categories the contractor has to manage addons on.
   */
  addonContractorCount?: number;

  role?: 0 | 1;

  constructor(model?: Partial<Profile>) {
    super(model);

    Object.assign(this, model);
  }

  get isPayed(): boolean {
    return this?.paymentStatus === 1;
  }

  hasCustomerRight(right: keyof CustomerRight): boolean {
    if (!this?.paymentStatus) {
      return true;
    }

    return hasUserCustomerRight(this.Customer, right);
  }

  hasAddonWork(): boolean {
    return (this.addonContractorCount ?? 0) > 0;
  }

  hasPortal(): boolean {
    return this.tenancyCount > 0 || this.salesCount > 0 || this.agreementsCount > 0;
  }

  isAdmin(): boolean {
    return this?.Customer?.Admins?.map((a) => a.id).includes(this.id) ?? false;
  }

  get isAPEX(): boolean {
    return !environment.production || this?.Customer?.id === 101;
  }
}
