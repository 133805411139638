import { ITranslateParams, t as transifexT } from '@transifex/native';
import { CommercialType, MetaType } from '../../features/object/object.model';
import { MeterType } from '../../features/object/project/meter/meter.model';
import { SafetyType } from '../../features/object/safety/safety.model';
import { AddonOrderStatus } from '../../models/addon-order';
import { InspectionSignatureType } from '../../models/inspection';

export type TranslateParams = ITranslateParams & Record<string, unknown>;

/**
 * Translates a ICU string to current language/locale.
 *
 * @param sourceString ICU string.
 * @param params All the params.
 * @returns Translated string.
 */
export const t = (sourceString: string, params?: TranslateParams): string => transifexT(sourceString, params);

export type TranslateTypes =
  | MeterType
  | CommercialType
  | MetaType
  | InspectionSignatureType
  | SafetyType
  | AddonOrderStatus;

// Note: At the time these are run in t('') they will not yield translated content.
export const typesTranslated = {
  // MetaTypes
  commercial: t('Commercial'),
  safety: t('Safety'),

  // CommercialTypes
  project: t('Project'),
  building: t('Building'),
  floor: t('Floor'),
  unit: t('Unit'),
  room: t('Room'),
  residence: t('Residence'),
  elevator: t('Elevator'),

  // MeterType
  'cold-water': t('Cold water'),
  'hot-water': t('Hot water'),
  'district-heating': t('District heating'),
  power: t('Power', { _context: 'meter' }),
  gas: t('Gas'),
  temperature: t('Temperature'),
  moisture: t('Moisture', { _context: 'meter' }),
  'sprinkler-pressure': t('Sprinkler pressure'),
  other: t('Other'),

  // SafetyTypes
  control: t('Control'),
  product: t('Product'),
  'product-type': t('Product Type'),
  sale: t('Sale'),

  // SignatureTypes
  none: t('None'),
  handwritten: t('Handwritten'),
  bankid: t('BankID'),

  // AddonOrderStatus {
  new: t('New'),
  denied: t('Denied'),
  accepted: t('Accepted'),
  canceled: t('Canceled'),
  signed: t('Signed'),

  // Nothing
  unknown: t('Unknown'),
};

export const tType = (type: TranslateTypes): string => {
  const found = typesTranslated[type];

  return t(found ?? typesTranslated['unknown']);
};
