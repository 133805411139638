import { Pipe, PipeTransform } from '@angular/core';
import { ResponseSidebarItemPinnedDto } from '../../../../../../../generated/apex-rest';
import { Bookmark } from '../bookmark.model';

@Pipe({
  name: 'bookmarkPinnedOrNot',
  standalone: true,
})
export class BookmarkPinnedOrNotPipe implements PipeTransform {
  transform(bookmark: Bookmark, responsePinnedBookmarks: ResponseSidebarItemPinnedDto[]): boolean {
    return !!responsePinnedBookmarks?.find((response) => response.modelId === String(bookmark.id));
  }
}
