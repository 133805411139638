import { Injectable } from '@angular/core';
import { DeleteResponseDto, InspectionApi } from '../../../../../../generated/apex-rest';
import { restAxiosConfig } from '../../utils/rest-axios-config';

@Injectable()
export class InspectionNestService {
  protected readonly inspectionApi = new InspectionApi(restAxiosConfig());

  async delete(inspectionId: number): Promise<DeleteResponseDto> {
    const response = await this.inspectionApi.inspectionControllerDelete(inspectionId);

    return response.data;
  }
}
