import { inject, NgModule } from '@angular/core';
import { CanActivateFn, Router, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '../../components/page-not-found/page-not-found.component';
import { CasesPageComponent } from './pages/cases/cases.component';
import { CasePrintPageComponent } from './pages/print/print.component';

import { ActivatedRouteSnapshot } from '@angular/router';

export const CanActivateCaseIdGuard: CanActivateFn = (route: ActivatedRouteSnapshot): boolean | Promise<boolean> => {
  const router = inject(Router);

  const id = Number(route?.params?.id);

  if (id > 0) {
    return void router.navigate(['/case'], {
      preserveFragment: false,
      queryParamsHandling: 'preserve',
      fragment: `list-${id}`,
    });
  }

  return false;
};

const routes: Routes = [
  {
    path: '',
    component: CasesPageComponent,
  },
  {
    path: 'calendar',
    redirectTo: '/case#calendar',
  },
  {
    path: 'requests',
    component: PageNotFoundComponent, // @TODO insert requests page later
  },
  {
    path: ':id/print',
    component: CasePrintPageComponent,
  },
  {
    path: ':id',
    canActivate: [CanActivateCaseIdGuard],
    children: [],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [],
  exports: [RouterModule],
})
export class CaseRoutingModule {}
