import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileUsageService } from 'projects/apex/src/app/components/file-usage/file-usage.service';
import { FileService } from 'projects/apex/src/app/components/file-usage/file.service';
import { SplitDialogComponent } from 'projects/apex/src/app/components/upload/split-dialog/split-dialog.component';
import { File as ApexFile } from 'projects/apex/src/app/models/file';
import { Folder } from 'projects/apex/src/app/models/folder';
import { from, merge, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, skipWhile, toArray } from 'rxjs/operators';
import { FolderService } from '../../services/folder/folder.service';

import { createImagesFromPDF$ } from '../../utils/pdf';
import { UploadData, UploadStatus } from './upload.types';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  listChange = new Subject<UploadData[]>();
  complete = new Subject<UploadData>();

  workerCount = 0;
  workerCountMax = 4;
  pdfViewScale = 4;

  listValue: UploadData[] = [];
  get list(): UploadData[] {
    return this.listValue;
  }
  set list(list: UploadData[]) {
    this.listValue = list;

    this.listChange.next(this.list);
  }

  queueTrigger = new Subject<UploadData>();
  queueSub = this.queueTrigger
    .pipe(
      skipWhile((data) => data.canceled),
      mergeMap((data: UploadData) => {
        if (data.file) {
          return this.fileService.sign(data.file).pipe(
            mergeMap((d) => this.fileService.upload(d.signedData, d.file)),
            mergeMap((f) => this.fileService.save(Object.assign(f, { FolderId: data.FolderId }))),
            map((file: ApexFile) => {
              data.File = file;
              data.status = UploadStatus.Success;

              return data;
            }),
            catchError((err) => {
              data.status = UploadStatus.Error;

              return of(err);
            }),
          );
        } else {
          return this.folderSerivce
            .save(
              new Folder({
                name: data.name,
                ParentId: data.FolderId,
              }),
            )
            .pipe(
              map((e) => e.Entity),
              map((folder: Folder) => {
                data.Folder = folder;
                data.status = UploadStatus.Success;

                const children = this.list.filter((l) => {
                  const name = data.folder ? `${data.folder}/${data.name}` : data.name;

                  return l.folder === name;
                });

                children.forEach((c) => {
                  c.FolderId = folder.id;
                  c.ready = true;
                });

                return data;
              }),
              catchError((err) => {
                data.status = UploadStatus.Error;

                return of(err);
              }),
            );
        }
      }),
    )
    .subscribe({
      next: (data: UploadData) => {
        this.complete.next(data);
        this.workerCount--;
        this.startWorker();
      },
    });

  constructor(
    private fuService: FileUsageService,
    private fileService: FileService,
    private folderSerivce: FolderService,
    private dialog: MatDialog,
  ) {}

  startWorker(): void {
    if (this.workerCount < this.workerCountMax) {
      const data = this.list.find((l) => l.status === UploadStatus.Pending && l.ready && !l.canceled);

      if (data) {
        data.status = UploadStatus.Ongoing;
        this.workerCount++;
        this.queueTrigger.next(data);
        this.startWorker();
      }
    }
  }

  retry(data: UploadData): void {
    if (data) {
      data.status = UploadStatus.Pending;

      const folder = !data.FolderId
        ? this.list.find((l: UploadData) => (data.folder === l.folder ? `${l.folder}/${l.name}` : l.name))
        : null;

      if (folder) {
        this.retry(folder);
      } else {
        this.startWorker();
      }
    }
  }

  getFileFromEntry(fe: FileSystemFileEntry): Promise<File> {
    return new Promise((resolve, reject) => fe.file(resolve, reject));
  }

  getFileUploadData(file: File, target?: Folder, path?: string): UploadData {
    return {
      name: file.name,
      folder: path,
      type: file.type,
      size: file.size,
      file,
      status: UploadStatus.Pending,
      target,
      FolderId: !path && target ? target?.id : null,
      ready: !!(!path || !target),
    };
  }

  getUploadDataFromEntry$(
    fe: FileSystemEntry,
    target?: Folder,
    path?: string,
    splitPdf?: boolean,
  ): Observable<UploadData> {
    if (fe.isFile) {
      return from(this.getFileFromEntry(fe as FileSystemFileEntry)).pipe(
        mergeMap((file) => {
          if (splitPdf && file.type.includes('application/pdf')) {
            return createImagesFromPDF$(file).pipe(
              mergeMap((files: File[]) => merge(...files.map((f) => of(this.getFileUploadData(f, target, path))))),
            );
          }

          return of(this.getFileUploadData(file, target, path));
        }),
      );
    } else {
      if (fe.isDirectory) {
      }

      return of({
        name: fe.name,
        folder: path,
        type: 'folder',
        size: 0,
        status: UploadStatus.Pending,
        target: target ?? null,
        FolderId: !path ? (target?.id ? target.id : 'upload') : null,
        ready: !path,
      } as UploadData);
    }
  }

  getEntriesFromDirectory(entry: FileSystemDirectoryEntry): Promise<FileSystemEntry[]> {
    return new Promise((resolve) => {
      if (entry.isDirectory) {
        const dirReader = entry.createReader();

        dirReader.readEntries((e) => {
          const entries = Array.from(e);

          resolve(entries);
        });
      } else {
        resolve([]);
      }
    });
  }

  getEntriesFromDirectory$(entry: FileSystemDirectoryEntry): Observable<FileSystemEntry[]> {
    return from(this.getEntriesFromDirectory(entry));
  }

  getFileTree(entries: FileSystemEntry[], target?: Folder, path?: string, splitPdf?: boolean): Observable<UploadData> {
    path = (target && path) || '';
    entries = Array.from(entries);

    return merge(
      ...entries.map((entry) => {
        if (entry?.isFile) {
          return this.getUploadDataFromEntry$(entry, target, path, splitPdf);
        } else if (entry?.isDirectory) {
          return this.getEntriesFromDirectory$(entry as FileSystemDirectoryEntry).pipe(
            mergeMap((entriesFromDirectory) => {
              if (target) {
                return merge(
                  this.getUploadDataFromEntry$(entry, target, path),
                  this.getFileTree(entriesFromDirectory, target, path ? `${path}/${entry.name}` : entry.name),
                );
              } else {
                return this.getFileTree(entriesFromDirectory, target, '');
              }
            }),
          );
        }

        return of(null); // This should not happen.
      }),
    );
  }

  getConfirmation(splitPdf?: 'ask' | 'never' | 'always'): Observable<boolean> {
    return splitPdf === 'ask'
      ? this.dialog.open(SplitDialogComponent).afterClosed()
      : splitPdf === 'always'
        ? of(true)
        : of(false);
  }

  uploadFiles(fileList: FileList | File[], target?: Folder, splitPdf?: 'ask' | 'never' | 'always'): void {
    if (fileList?.length) {
      let files = [];

      for (let i = 0, j = fileList.length; i < j; i++) {
        files.push(fileList[i]);
      }

      if (files.map((f) => f.type).includes('application/pdf')) {
        this.getConfirmation(splitPdf)
          .pipe(
            skipWhile((c) => !c),
            mergeMap(() => {
              const pdfs = files.filter((f) => f.type.includes('application/pdf'));

              files = files.filter((f) => !f.type.includes('application/pdf'));

              return merge(...pdfs.map((pdf) => createImagesFromPDF$(pdf)));
            }),
          )
          .subscribe({
            next: (imagesFromPdf) => {
              if (imagesFromPdf?.length) {
                files = files.concat(imagesFromPdf);
              }
            },
            complete: () => {
              this.list = this.list.concat(
                files.map((f) => ({
                  name: f.name,
                  folder: '',
                  type: f.type,
                  size: f.size,
                  status: UploadStatus.Pending,
                  file: f,
                  target: target ?? null,
                  FolderId: target?.id,
                  ready: true,
                })),
              );
              this.startWorker();
            },
          });
      } else {
        this.list = this.list.concat(
          files.map((f) => ({
            name: f.name,
            folder: '',
            type: f.type,
            size: f.size,
            status: UploadStatus.Pending,
            file: f,
            target: target ?? null,
            FolderId: target?.id,
            ready: true,
          })),
        );
        this.startWorker();
      }
    }
  }

  dragEventHasPdf(entries: FileSystemEntry[]): Observable<boolean> {
    return this.getFileTree(entries).pipe(
      toArray(),
      map((datas: UploadData[]) => {
        if (datas.find((d) => d.type === 'application/pdf')) {
          return true;
        }

        return false;
      }),
    );
  }

  async uploadFromDragEvent(
    entries: FileSystemEntry[],
    folder?: Folder,
    splitPdf?: 'ask' | 'never' | 'always',
  ): Promise<number> {
    let obs$: Observable<UploadData> = this.getFileTree(entries, folder, '', splitPdf === 'always');

    const obs = await this.getFileTree(entries, folder, '', splitPdf === 'always')
      .pipe(toArray())
      .toPromise();

    if (splitPdf === 'ask') {
      obs$ = this.dragEventHasPdf(entries).pipe(
        mergeMap((hasPdf: boolean) => {
          if (hasPdf) {
            return this.dialog.open(SplitDialogComponent).afterClosed();
          }

          return of(false);
        }),
        mergeMap((shouldSplit: boolean) =>
          typeof shouldSplit === 'undefined' ? of(null) : this.getFileTree(entries, folder, '', shouldSplit),
        ),
      );
    }

    obs$.subscribe({
      next: (data: UploadData) => {
        if (data) {
          this.list.push(data);
          this.list = this.list ?? [];
        }
      },
      complete: () => {
        this.startWorker();
      },
    });

    return obs.length;
  }

  remove(item: UploadData): void {
    let list = [item];
    const newList = [].concat(this.list);

    if (item.type === 'folder') {
      const files = newList.filter((d) => d.folder === (item.folder ? `${item.folder}/${item.name}` : item.name));

      list = list.concat(files);
    }

    list.forEach((d: UploadData) => {
      const data = newList.find((l) => l.name === d.name && l.folder === d.folder);

      if (data) {
        const idx = newList.indexOf(data);

        if (idx !== -1) {
          newList.splice(idx, 1);
        }
      }
    });
    this.list = newList;
  }

  static getEntriesFromDragEvent(event: DragEvent): FileSystemEntry[] {
    const items = Array.from(event.dataTransfer.items);

    return items.map((i) => i.webkitGetAsEntry());
  }
}
