/// <reference types="google.visualization" />

import { Component, ElementRef, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import moment from 'moment';

import { t } from '../translate/translate.function';

@Component({
  selector: 'apex-meter-chart',
  templateUrl: './meter-chart.component.html',
})
export class MeterChartComponent implements OnChanges, OnDestroy {
  @Input() height: 200;
  @Input() values: { createdAt?: Date; value: number }[] = [];

  @ViewChild('chart') chart: ElementRef<HTMLDivElement>;
  gchart: google.visualization.LineChart;

  constructor() {
    google.charts.setOnLoadCallback(() => {
      this.createChart();
    });
  }

  ngOnChanges(): void {
    if (this.gchart) {
      this.updateChart();
    }
  }

  ngOnDestroy(): void {
    this.gchart?.clearChart();
  }

  private createChart(): void {
    if (!this.chart) {
      console.error('Can not find inner div#chart');

      return;
    }

    this.gchart = new google.visualization.LineChart(this.chart.nativeElement);

    this.updateChart();
  }

  private updateChart(): void {
    if (!this.gchart) {
      console.error('Can not find gchart');

      return;
    }

    const data = new google.visualization.DataTable();

    data.addColumn('date', t('Date'));
    data.addColumn('number', t('Value'));

    data.addRows([...this.values.map((v) => [moment(v.createdAt).toDate(), v.value])]);

    this.gchart.draw(data, {
      height: this.height,
    });
  }
}
