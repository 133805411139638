import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EmbeddedMarkingsViewerComponent } from './embedded-markings-viewer.component';

@NgModule({
  imports: [CommonModule, MatRippleModule, MatDialogModule, MatProgressBarModule],
  exports: [EmbeddedMarkingsViewerComponent],
  declarations: [EmbeddedMarkingsViewerComponent],
  providers: [],
})
export class EmbeddedMarkingsViewerModule {}
