export const SizeBreakpoints = {
  disabled: 0,

  xsmall: 600,
  small: 960,
  medium: 1280,
  large: 1920,
  xlarge: 3840,
};

export type SizeBreakpoint = keyof typeof SizeBreakpoints;
