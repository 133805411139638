import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '../translate/translate.module';

@Component({
  selector: 'apex-user-portal-reminder',
  templateUrl: './user-portal-reminder.dialog.html',
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatCheckboxModule, TranslateModule, MatDialogModule],
})
export default class UserPortalReminderDialogComponent {
  alwaysRedirectToPortal = false;
  hidePortalReminder = false;

  constructor(private dialogRef: MatDialogRef<UserPortalReminderDialogComponent>) {
    const alwaysRedirectToPortal = localStorage.getItem('alwaysRedirectToPortal');

    this.alwaysRedirectToPortal = alwaysRedirectToPortal === 'true';

    const hidePortalReminder = localStorage.getItem('hidePortalReminder');

    this.hidePortalReminder = hidePortalReminder === 'true';
  }

  onClick(go = false): void {
    localStorage.setItem('alwaysRedirectToPortal', this.alwaysRedirectToPortal.toString());
    localStorage.setItem('hidePortalReminder', this.hidePortalReminder.toString());

    this.dialogRef.close(go);
  }
}
