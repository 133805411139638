import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listArray',
})
export class ListArrayPipe implements PipeTransform {
  transform<T>(collection: Array<T>, separator = ',', index: string = null): string {
    if (!collection?.length) {
      return '';
    }

    if (index) {
      collection = collection.map((c) => c[index]);
    }

    return collection.filter((i) => i).join(separator ?? ',');
  }
}
