import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { ChecklistSectionService } from 'projects/apex/src/app/services/checklist-section/checklist-section.service';
import { Subscription } from 'rxjs';
import { t } from '../../../components/translate/translate.function';
import { snackErr } from '../../../modules/snack.module';
import { Checklist, ChecklistItem, ChecklistSection } from '../../checklist/checklist.model';
import { ChecklistItemService, ChecklistService } from '../../checklist/checklist.service';
import { sortChecklistItems, sortChecklistSections } from '../../checklist/checklist.types';

@Component({
  selector: 'apex-case-checklist-items',
  templateUrl: './case-checklist-items.component.html',
})
export class CaseChecklistItemsComponent implements OnChanges, OnDestroy {
  @Input() checklistId: number;
  @Input() checklist: Checklist;

  @Output() navigate = new EventEmitter<{ commands: (string | number)[]; extras?: NavigationExtras }>();

  checklistItems: ChecklistItem[] = [];
  checklistSections: ChecklistSection[] = [];

  expand = false;
  loading = false;

  completedCases: number;
  completedOfTotal: number;

  private subscription = new Subscription();

  constructor(
    private checklistService: ChecklistService,
    private checklistItemService: ChecklistItemService,
    private checklistSectionService: ChecklistSectionService,
  ) {}

  ngOnChanges(change: SimpleChanges): void {
    if (change.checklistId) {
      this.loading = true;

      const sub = this.checklistItemService.getItems(this.checklistId).subscribe({
        next: (items) => {
          this.checklistItems = sortChecklistItems(items || []);

          this.completedCases = this.getCompletedCasesCount();
          this.completedOfTotal = this.completedCases / this.checklistItems.length;

          this.loading = false;
        },
        error: (err) => {
          snackErr(t('Failed to get checklist'), err);
          this.loading = false;
        },
      });

      const sub1 = this.checklistService.$countCompleted.subscribe({
        next: (_) => {
          this.completedCases = this.getCompletedCasesCount();
          this.completedOfTotal = this.completedCases / this.checklistItems.length;
        },
      });

      const sub2 = this.checklistSectionService.getSections(this.checklistId).subscribe({
        next: (sections: ChecklistSection[]) => {
          this.checklistSections = sortChecklistSections(sections || []);
        },
      });

      [sub, sub1, sub2].forEach((s) => this.subscription.add(s));
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  navigateToChecklist(event: Event): void {
    event?.stopPropagation();

    this.navigate.emit({
      commands: ['/checklist', this.checklistId],
    });
  }

  private getCompletedCasesCount(): number {
    return this.checklistItems.reduce((acc, curr) => {
      acc += curr.Case.completed ? 1 : 0;

      return acc;
    }, 0);
  }
}
