import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DragItem } from './drag-item';
import { DragService } from './drag.service';

@Directive({
  selector: '[apexDrop]',
})
export class DropDirective {
  @Input() apexDropDisabled: boolean;
  @Input() dropDisabled: boolean;

  @Input() selected: boolean;

  @Output() apexDrop = new EventEmitter<DragEvent>();
  @Output() apexDropEnter = new EventEmitter();
  @Output() apexDropLeave = new EventEmitter();

  ready: boolean;
  item: DragItem;
  delayCount = 0;

  layer = 0;

  constructor(
    private el: ElementRef,
    private ds: DragService,
  ) {}

  @HostListener('dragover', ['$event'])
  dragover(e: DragEvent): void {
    if (!this.dropDisabled) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  @HostListener('dragenter', ['$event'])
  dragenter(e: DragEvent): void {
    if (!this.dropDisabled) {
      e.stopPropagation();

      if (this.layer === 0) {
        this.el.nativeElement.classList.add('apex-drag-over');
        this.apexDropEnter.emit();
      }

      this.layer = this.layer + 1;
    }
  }

  @HostListener('dragleave', ['$event'])
  dragleave(e: DragEvent): void {
    if (!this.dropDisabled) {
      e.stopPropagation();
      this.layer = this.layer - 1;

      if (this.layer === 0) {
        this.el.nativeElement.classList.remove('apex-drag-over');
        this.apexDropLeave.emit();
      }
    }
  }

  @HostListener('drop', ['$event'])
  drop(e: DragEvent): void {
    if (!this.dropDisabled) {
      this.apexDrop.emit(e);
      this.el?.nativeElement?.classList?.remove('apex-drag-over');

      this.layer = this.layer - 1;
    }
  }

  @HostListener('mouseenter', ['$event'])
  mouseEnter(): void {
    if (!this.apexDropDisabled && !this.selected) {
      this.item = this.ds.dragItem;

      if (this.ds.dragging) {
        this.el.nativeElement.classList.add('apex-drag-over');
        this.apexDropEnter.emit();
      }
    }
  }

  @HostListener('mouseleave', ['$event'])
  mouseLeave(): void {
    if (!this.apexDropDisabled && !this.selected) {
      this.el.nativeElement.classList.remove('apex-drag-over');
      this.item = null;

      if (this.ds.dragging) {
        this.apexDropLeave.emit();
      }
    }
  }

  @HostListener('mouseup', ['$event'])
  mouseUp(): void {
    if (!this.apexDropDisabled && !this.selected && this.item) {
      this.ds.dragItems
        .filter((i) => i.selected)
        .forEach((i) => {
          i.hasTarget = true;
          i.readyX = false;
          i.readyY = false;

          const rect = this.el.nativeElement.getBoundingClientRect();

          i.currentX = rect.left;
          i.currentY = rect.top;
          i.width = this.el.nativeElement.offsetWidth;
        });
      this.el.nativeElement.classList.remove('apex-drag-over');
      this.apexDrop.emit();
      this.item = null;
    }
  }
}
