<ng-container *ngIf="showBell">
  <button
    (click)="isOpen = !isOpen; newUnread = false"
    type="button"
    mat-icon-button
    [matTooltip]="'Notifications' | translate"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin">
    <mat-icon *ngIf="!newUnread">notifications</mat-icon>
    <mat-icon *ngIf="newUnread">notifications_active</mat-icon>
  </button>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    (overlayOutsideClick)="isOpen = false">
    <apex-notification-list (click)="isOpen = false"></apex-notification-list>
  </ng-template>
</ng-container>
