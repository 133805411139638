<ng-container *ngIf="bookmark | bookmarkPinnedOrNot: pinnedBookmarks; else notPinned">
  <button
    (click)="removePinSidebarItem(bookmark); $event.stopPropagation(); $event.preventDefault()"
    mat-icon-button
    type="button"
    [matTooltip]="'Remove pin' | translate">
    <mat-icon class="rotate-45">push_pin</mat-icon>
  </button>
</ng-container>

<ng-template #notPinned>
  <button
    (click)="addPinSidebarItem(bookmark); $event.stopPropagation(); $event.preventDefault()"
    mat-icon-button
    type="button"
    [matTooltip]="'Add pin' | translate">
    <mat-icon>push_pin</mat-icon>
  </button>
</ng-template>
