import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { AutocompleteModule } from 'projects/apex/src/app/components/autocomplete/autocomplete.module';
import { ChecklistSectionModule } from 'projects/apex/src/app/components/checklist-section/checklist-section.module';
import { ModelRoleModule } from 'projects/apex/src/app/components/model-role/model-role.module';
import { PageInfoModule } from 'projects/apex/src/app/components/page-info/page-info.module';
import { HistoryBackModule } from 'projects/apex/src/app/directives/history-back/history-back.module';
import { CaseModule } from 'projects/apex/src/app/features/case/case.module';
import { ChecklistGroupTemplateService } from 'projects/apex/src/app/features/checklist-group-template/checklist-group-template.service';
import { ObjectSelectorModule } from 'projects/apex/src/app/features/object/components/selector/object-selector.module';
import { AutocompletePickerModule } from '../../components/autocomplete-picker/autocomplete-picker.module';
import { BoxModule } from '../../components/box/box.module';
import { ButtonModule } from '../../components/button/button.module';
import { ChecklistBulkDeleteModule } from '../../components/checklist-bulk-delete/checklist-bulk-delete.module';
import { ChecklistDialogModule } from '../../components/checklist-dialog/checklist-dialog.module';
import { ProgressModule } from '../../components/progress/progress.module';
import { TranslateModule } from '../../components/translate/translate.module';
import { AutoFocusDirectiveModule } from '../../directives/auto-focus/auto-focus..directive.module';
import { EditorInitPipeModule } from '../../pipes/editor-init/editor-init.pipe.module';
import { HighlightPipeModule } from '../../pipes/highlight/highlight-pipe.module';
import { ReduceHtmlToStringModule } from '../../pipes/reduct-html-to-string/reduce-html-to-string.module';
import { ChecklistGroupRoutingModule } from './checklist-group-routing.module';
import { ChecklistGroupService } from './checklist-group.service';
import { ChecklistGroupChecklistModule } from './checklist/checklist-group-checklist.module';
import { ChecklistGroupFilterComponent } from './filter/filter.component';
import { ChecklistGroupFormComponent } from './form/form.component';
import { ChecklistGroupsModule } from './groups/groups.module';
import { ChecklistGroupListModule } from './list/list.module';
import { ChecklistGroupFormPageComponent } from './pages/form.component';
import { ChecklistGroupListPageComponent } from './pages/list.component';
import { ChecklistGroupTreeModule } from './tree/tree.module';

const components = [
  ChecklistGroupListPageComponent,
  ChecklistGroupFormPageComponent,
  ChecklistGroupFormComponent,
  ChecklistGroupFilterComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ChecklistGroupRoutingModule,
    FormsModule,

    TranslateModule,
    PageInfoModule,
    ChecklistSectionModule,
    AutocompleteModule,
    ProgressModule,
    ObjectSelectorModule,
    ModelRoleModule,
    HistoryBackModule,
    CaseModule,
    HighlightPipeModule,
    ReduceHtmlToStringModule,

    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatRippleModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatDividerModule,
    MatSelectModule,

    AutocompletePickerModule,
    AutoFocusDirectiveModule,

    ChecklistGroupsModule,
    ChecklistGroupTreeModule,
    ChecklistGroupChecklistModule,
    ChecklistGroupListModule,
    BoxModule,
    ButtonModule,

    ChecklistBulkDeleteModule,
    ChecklistDialogModule,
    EditorComponent,
    EditorInitPipeModule,
  ],
  providers: [ChecklistGroupService, ChecklistGroupTemplateService],
  declarations: components,
  exports: [
    ...components,
    ChecklistGroupsModule,
    ChecklistGroupTreeModule,
    ChecklistGroupChecklistModule,
    ChecklistGroupListModule,
  ],
})
export class ChecklistGroupModule {}
