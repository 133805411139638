<h1 mat-dialog-title>
  <T
    str="Add contractor"
    *ngIf="!(data?.edit && data?.contractor?.id)"></T>
  <ng-container *ngIf="data?.edit && data?.contractor?.id">
    <T str="Set planned time"></T>
  </ng-container>
</h1>
<div mat-dialog-content>
  <form
    #contractorForm="ngForm"
    class="df c">
    <apex-autocomplete
      class="f1"
      name="ContractorId"
      type="User"
      [label]="'Contractor' | translate"
      [(ngModel)]="contractor.id"
      (modelChange)="$event ? (contractor.name = $event.name) : null"
      [disabled]="!!data?.contractor?.id"
      required>
    </apex-autocomplete>

    <div
      class="df c"
      *ngIf="data?.edit && data?.view; else notfications">
      <div class="from-wrapper df f1 ac p-minw-50">
        <apex-date-time
          class="df f1"
          name="startTime"
          #startTime="ngModel"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [dateTitle]="'Start date' | translate"
          [timeTitle]="'Start time' | translate"
          [(ngModel)]="contractor.CaseContractor.from"
          (ngModelChange)="checkFromBeforeTo()">
        </apex-date-time>
        <ng-container *ngIf="!fromBeforeTo && !startTime.invalid">
          <T
            class="from-before-to-error mat-error mat-caption"
            str="'Start' time must be before 'End' time"></T>
        </ng-container>
      </div>
      <apex-date-time
        class="df f1 p-minw-50"
        name="endTime"
        #endTime="ngModel"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [dateTitle]="'End date' | translate"
        [timeTitle]="'End time' | translate"
        [reverseViewOrder]="false"
        [(ngModel)]="contractor.CaseContractor.to"
        (ngModelChange)="checkFromBeforeTo()">
      </apex-date-time>
    </div>
    <ng-template #notfications>
      <section>
        <p><T str="Notify"></T></p>
        <mat-checkbox
          [(ngModel)]="contractor.CaseContractor.notifyOnAddSms"
          lass="example-margin"
          name="notifyOnAddSms"
          ><T str="SMS"></T
        ></mat-checkbox>
        <mat-checkbox
          [(ngModel)]="contractor.CaseContractor.notifyOnAddEmail"
          class="example-margin"
          name="notifyOnAddEmail"
          ><T str="Mail"></T
        ></mat-checkbox>
      </section>
    </ng-template>
  </form>
</div>

<div
  mat-dialog-actions
  class="df">
  <button
    mat-button
    mat-dialog-close>
    <T str="Cancel"></T>
  </button>
  <button
    mat-raised-button
    (click)="save()"
    color="primary"
    [disabled]="!contractorForm.valid">
    <T str="Save"></T>
  </button>
</div>
