import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FabMenuComponent } from './menu/menu.component';
import { FabSpeedDialComponent } from './speed-dial/speed-dial.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSelectModule,
    MatRippleModule,
    MatMenuModule,
  ],
  exports: [FabSpeedDialComponent, FabMenuComponent],
  declarations: [FabSpeedDialComponent, FabMenuComponent],
})
export class FabModule {}
