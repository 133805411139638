<span class="truncate mat-caption green-background">
  {{ currentBookmark }}
</span>

<button
  type="button"
  mat-icon-button
  [matTooltip]="'Bookmarks' | translate"
  [matMenuTriggerFor]="menu.folderMenu">
  <mat-icon>bookmarks</mat-icon>
</button>

<apex-bookmark-folder
  #menu
  [id]="null">
</apex-bookmark-folder>
