<h1 mat-dialog-title>
  <T str="Split"></T>
</h1>

<div mat-dialog-content>
  <p>
    <T str="Do you want to split your PDF files into large images?"></T>
  </p>
  <p>
    <T str="Tip: Floorplans and images used for marking should not be in PDF."></T>
  </p>
</div>

<div mat-dialog-actions>
  <div>
    <button
      type="button"
      mat-button
      [mat-dialog-close]="">
      <T str="Cancel"></T>
    </button>
  </div>
  <div>
    <button
      type="button"
      mat-button
      [mat-dialog-close]="false">
      <T str="No"></T>
    </button>
    <button
      type="button"
      mat-button
      color="primary"
      [mat-dialog-close]="true"
      cdkFocusInitial>
      <T str="Yes"></T>
    </button>
  </div>
</div>
