import { Injectable } from '@angular/core';
import { Client, TokenProvider } from '@pusher/push-notifications-web';
import { skipWhile } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserService } from '../user/user.service';

@Injectable()
export class PusherService {
  private client: Client;

  constructor(private readonly userService: UserService) {}

  public async startPusherClient(userId: number): Promise<void> {
    const beamsTokenProvider = new TokenProvider({
      url: `${environment.restApi}/system/notifications/generate-token`,
      credentials: 'include',
    });

    try {
      await this.client.start();
      await this.client.setUserId(String(userId), beamsTokenProvider);
    } catch (e) {
      console.error('Could not authenticate with Beams:', e);
    }
  }

  public async stopPusherClient(): Promise<void> {
    try {
      await this.client.stop();
    } catch (data) {
      return console.error(data);
    }
  }

  init(): void {
    try {
      this.client = new Client({
        instanceId: environment.pusherInstanceId,
      });
      this.userService.profile$
        .pipe(
          skipWhile((profile) => !profile),
          take(1),
        )
        .subscribe(async (profile) => {
          if (!this.userService.profile?.pushNotifications) {
            return;
          }

          await this.startPusherClient(profile.id);
        });
    } catch (e) {
      console.error('Error instantiating Pusher Client', e);
    }
  }
}
