import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dater',
})
export class DaterPipe implements PipeTransform {
  transform(value: null | Date | moment.Moment | string): Date {
    if (!value) {
      return null;
    }

    const m = moment(value);

    if (!m.isValid()) {
      return null;
    }

    return m.toDate();
  }
}
