import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { FileUsageComponent } from '../file-usage/file-usage.component';
import { FileUsageDialogData } from './file-usage-dialog.types';

@Component({
  selector: 'apex-file-usage-dialog',
  templateUrl: './file-usage-dialog.component.html',
})
export class FileUsageDialogComponent implements OnInit, OnDestroy {
  @ViewChild('fileUsage') fileUsage: FileUsageComponent;

  headerText = '';

  self: string;
  selfId: string | number;
  name: string;
  title: string;
  splitPdf: 'ask' | 'never' | 'always';

  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: FileUsageDialogData,
    public dialogRef: MatDialogRef<FileUsageDialogComponent>,
  ) {}

  ngOnInit(): void {
    const sub = this.dialogRef?.backdropClick()?.subscribe({
      next: (_) => this.close(),
    });

    this.subscription.add(sub);

    this.dialogRef?.addPanelClass(['apex-fullscreen-dialog', 'phone']);
    this.self = this.data.self;
    this.selfId = this.data.selfId;
    this.name = this.data.name;
    this.title = this.data.title;
    this.splitPdf = this.data.splitPdf;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  confirm(): void {
    this.close();
  }

  cancel(): void {
    this.close();
  }

  close(): void {
    this.dialogRef.close(this.fileUsage.fileUsages);
  }
}
