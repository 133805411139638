import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ProgressModule } from '../../../components/progress/progress.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { HighlightPipeModule } from '../../../pipes/highlight/highlight-pipe.module';
import { ReduceHtmlToStringModule } from '../../../pipes/reduct-html-to-string/reduce-html-to-string.module';
import { ChecklistGroupListComponent } from './list.component';

@NgModule({
  declarations: [ChecklistGroupListComponent],
  exports: [ChecklistGroupListComponent],
  imports: [
    CommonModule,
    MatListModule,
    RouterModule,
    MatTooltipModule,
    MatIconModule,
    TranslateModule,
    HighlightPipeModule,
    ReduceHtmlToStringModule,
    ProgressModule,
    MatCheckboxModule,
  ],
})
export class ChecklistGroupListModule {}
