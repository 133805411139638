import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MimeType } from '../../../../../../../generated/apex-rest';
import { File } from '../../../models/file';
import { FileIcons } from '../file-viewer-utils';

type ViewFile = {
  file: File;
  icon?: FileIcons;
};

@Component({
  selector: 'apex-file-viewer-preview',
  templateUrl: './file-viewer-preview.component.html',
})
export class FileViewerPreviewComponent implements OnInit {
  @Input() files: File[] = [];
  @Input() showFileName = true;
  @Input() selectable = true;

  @Output() fileChanged = new EventEmitter<File>();

  viewFiles: ViewFile[] = [];
  selectedFile: ViewFile;

  ngOnInit(): void {
    if (this.files?.length) {
      this.viewFiles = this.files.map(
        (file) =>
          ({
            file,
            icon: this.fileIcon(file),
          }) as ViewFile,
      );

      if (this.selectable) {
        this.selectedFile = this.viewFiles[0];
      }
    }
  }

  private fileIcon(file: File): string {
    if (file.type === MimeType.ApplicationPdf) {
      return FileIcons.PdfIcon;
    } else if (file.type.startsWith('video/')) {
      return FileIcons.VideoIcon;
    } else if (file.type.startsWith('image/')) {
      return null;
    }

    return FileIcons.DefaultIcon;
  }

  fileClicked(viewFile: ViewFile): void {
    this.selectedFile = viewFile;
    this.fileChanged.emit(this.selectedFile.file);
  }
}
