import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { File as ApexFile } from 'projects/apex/src/app/models/file';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Marking } from '../../models/marking';
import { Profile } from '../../models/profile';
import { UserService } from '../../services/user/user.service';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';

export enum FileType {
  Image = 'image',
  PDF = 'pdf',
  Video = 'video',
  Other = 'other',
  Google = 'google',
  Mail = 'mail',
}

const googleViewerFileTypes = [
  'CSV',
  'JPEG',
  'PNG',
  'GIF',
  'TIFF',
  'BMP',
  'WebM',
  'MPEG4',
  '3GPP',
  'MOV',
  'AVI',
  'MPEG',
  'WMV',
  'FLV',
  'TXT',
  'CSS',
  'HTML',
  'PHP',
  'C',
  'CPP',
  'H',
  'HPP',
  'JS',
  'DOC',
  'DOCX',
  'XLS',
  'XLSX',
  'PPT',
  'PPTX',
  'PAGES',
  'AI',
  'PSD',
  'DXF',
  'EPS',
  'PS',
  'XPS',
  'ZIP',
  'RAR',
].map((s) => s.trim().toLowerCase());

@Component({
  selector: 'apex-file-viewer',
  templateUrl: './file-viewer.component.html',
})
export class FileViewerComponent implements OnChanges {
  @Input() file: ApexFile;
  @Input() fileUsage: FileUsage;
  @Input() markings: Marking[] = [];
  @Input() edit: boolean;
  @Input() highlightedMarkingIds: number[] = [];
  @Input() highlightedMarkingModelIds: number[] = [];
  @Input() showFolderAction = false;

  @Output() fileLoaded = new EventEmitter<HTMLImageElement>();
  @Output() fileError = new EventEmitter();

  @Output() markingCreate = new EventEmitter<Marking>();
  @Output() markingChange = new EventEmitter<Marking>();
  @Output() markingEnter = new EventEmitter<{ marking: Marking; target: HTMLElement }>();
  @Output() markingLeave = new EventEmitter<{ marking: Marking; target: HTMLElement }>();
  @Output() markingClicked = new EventEmitter<Marking>();

  @ViewChild(ImageViewerComponent) imageViewer: ImageViewerComponent;

  type: FileType;
  FileType = FileType;

  profile: Profile;
  profile$: Observable<Profile>;

  missingExtendedViewerConsent$: Observable<boolean>;
  extendedViewer$: Observable<boolean>;

  extendedViewerUrl: SafeResourceUrl = '';

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private sanitizer: DomSanitizer,
    private userService: UserService,
  ) {
    this.profile = this.userService.profile;
    this.profile$ = this.userService.profile$;

    this.missingExtendedViewerConsent$ = this.profile$.pipe(map((profile) => profile?.useExtendedFileViewer === null));

    this.extendedViewer$ = this.missingExtendedViewerConsent$.pipe(
      mergeMap((missingExtendedViewerConsent) =>
        this.profile$.pipe(map((profile) => !!profile?.useExtendedFileViewer && !missingExtendedViewerConsent)),
      ),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file && this.file) {
      this.extendedViewerUrl = this.getExtendedViewerUrl(this.file.signed.url);

      const key = Object.keys(FileType).find((k) => this.file.type?.includes(FileType[k]));

      if (key) {
        this.type = FileType[key];

        return;
      }

      const fileEnding = this.file.name.split('.');
      const fileExt = fileEnding.pop();

      if (fileExt && googleViewerFileTypes.includes(fileExt)) {
        this.type = FileType.Google;

        return;
      }

      if (this.file.type === 'application/vnd.ms-outlook' || this.file.type === 'message/rfc822') {
        this.type = FileType.Mail;

        return;
      }

      this.type = FileType.Other;
    }
  }

  getExtendedViewerUrl(url: string): SafeResourceUrl {
    const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(url)}&embedded=true`;

    return this.sanitizer.bypassSecurityTrustResourceUrl(viewerUrl);
  }

  resize(): void {
    void this.imageViewer?.resize();
  }
}
