import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../translation.service';

@Pipe({
  name: 'translate',
  pure: false,
})
// TODO clean up coding
export class TranslatePipe implements PipeTransform {
  constructor(private translationService: TranslationService) {}

  transform(str: string, translateParams?: Record<string, unknown>): string {
    const params = translateParams ?? {};

    return this.translationService.translate(str, params);
  }
}
