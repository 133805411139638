import { Pipe, PipeTransform } from '@angular/core';
import { ResponseCaseLogDto } from '../../../../../../../../generated/apex-rest';
import { CaseLog } from '../../../../models/case-log';
import { User } from '../../../../models/user';

type BasicUser = Pick<User, 'id' | 'name'>;

@Pipe({
  name: 'caseLogUser',
  standalone: true,
})
export class CaseLogUserPipe implements PipeTransform {
  transform(value: CaseLog | ResponseCaseLogDto): BasicUser | null {
    if ('User' in value) {
      return value.User;
    }

    if ('Creator' in value) {
      return value.Creator;
    }

    return null;
  }
}
