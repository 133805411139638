/**
 * The object that holds our Matomo Array.
 */
const analyticsObject = '_paq';

/**
 * Figures out if Google Analytics is on the window objects.
 *
 * @returns True is it is loaded.
 */
const hasAnalytics = (): boolean => !!window[analyticsObject];

const getAnalyticsObject = (): { push(...params: Array<unknown>): void } => window[analyticsObject];

/**
 * Tracks a pageview.
 *
 * @param url If provided will track that urL if not provided will track  current.
 */
export const trackPage = (title: string, location: string, _path?: string): void => {
  if (hasAnalytics()) {
    if (location) {
      getAnalyticsObject().push(['setCustomUrl', location]);
    }

    if (title) {
      getAnalyticsObject().push(['setDocumentTitle', document.title]);
    }

    getAnalyticsObject().push(['trackPageView']);
  }
};

/**
 * Tracks an event.
 *
 * @param eventCategory
 * @param eventAction
 * @param eventLabel
 * @param eventValue
 */
export const trackEvent = (
  eventCategory: string,
  eventAction: string,
  eventName?: string,
  eventValue?: number,
): void => {
  if (hasAnalytics()) {
    getAnalyticsObject().push(['trackEvent', eventCategory, eventAction, eventName, eventValue]);
  }
};
