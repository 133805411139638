import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'lodash-es';
import { isWeak } from '../../utils/functions';

import { colors } from './colors';

@Component({
  selector: 'apex-color-picker',
  templateUrl: './color-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
  ],
})
export class ColorPickerComponent implements ControlValueAccessor {
  color: string;
  colors = colors;

  disabled = false;

  isWeak = isWeak;

  onChange: (color: string) => void = noop;
  onTouched: () => void = noop;

  change(): void {
    this.onChange(this.color);
  }

  writeValue(value: string): void {
    this.color = value;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
