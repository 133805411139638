import { Injectable } from '@angular/core';
import { interval } from 'rxjs';

// OMGOGOMG
const DEFAULT_TTL = 120;

type MemCacheKey = string;

type MemCacheData = {
  value: unknown;
  expires: number;
};

@Injectable({ providedIn: 'root' })
export class MemCacheService {
  private cache = new Map<MemCacheKey, MemCacheData>();

  constructor() {
    // This will go on forever.
    interval(1000 * 60).subscribe((_) => this.evict());
  }

  get<T>(key: string): T | null {
    const data = this.cache.get(key);

    if (data) {
      return data.value as T;
    }

    return null;
  }

  set<T>(key: string, value: T, expires: number = DEFAULT_TTL): void {
    this.cache.set(key, {
      value,
      expires,
    });
  }

  has(key: string): boolean {
    return this.cache.has(key);
  }

  delete(key: string): void {
    this.cache.delete(key);
  }

  clear(): void {
    this.cache.clear();
  }

  evict(ttl = DEFAULT_TTL): number {
    let count = 0;

    for (const [key, value] of this.cache) {
      const expiresIn = value.expires - Math.floor(Date.now() / 1000);

      if (expiresIn <= ttl) {
        this.cache.delete(key);
        count++;
      }
    }

    return count;
  }

  async cachedOrFetch<T>(key: string, fetch: () => Promise<T>, expires: number = DEFAULT_TTL): Promise<T> {
    const cached = this.get<T>(key);

    if (cached) {
      return Promise.resolve(cached);
    }

    const value = await fetch();

    this.set(key, value, expires);

    return value;
  }

  get size(): number {
    return this.cache.size;
  }
}
