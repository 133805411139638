import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { FileUsagePreviewModule } from '../file-usage-preview/file-usage-preview.module';
import { FileViewerModule } from '../file-viewer/file-viewer.module';
import { TextareaModule } from '../textarea/textarea.module';
import { TranslateModule } from '../translate/translate.module';

import { PortalModule } from '@angular/cdk/portal';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PDFViewerModule } from '../pdf-viewer/pdf-viewer.module';
import { FileUsageViewerDialogComponent } from './file-usage-viewer-dialog.component';
import { FileUsageViewerComponent } from './file-usage-viewer.component';

@NgModule({
  imports: [
    CommonModule,

    MatIconModule,
    DragDropModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatProgressBarModule,
    MatDialogModule,
    PortalModule,

    TranslateModule,
    AutocompleteModule,
    FileUsagePreviewModule,
    TextareaModule,
    PDFViewerModule,
    FileViewerModule,
    MatTooltipModule,
  ],
  exports: [FileUsageViewerComponent, FileUsageViewerDialogComponent],
  declarations: [FileUsageViewerComponent, FileUsageViewerDialogComponent],
  providers: [],
})
export class FileUsageViewerModule {}
