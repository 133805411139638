<div
  class="case h-100"
  [ngClass]="{ highlighted: highlighted }">
  <div
    *ngIf="loading"
    class="loading df ac jc">
    <mat-spinner></mat-spinner>
  </div>
  <div
    *ngIf="!loading && case"
    class="case-card h-100 w-100"
    [ngClass]="{
      offline: !case.createdAt,
      completed: case?.completed > 0,
      overdue: case.deadline * 1000 | isDateOverdue,
      declined: case.Contractors | isCaseDeclined,
      pending: case.Contractors | isCasePending,
      other: profile ? profile.CustomerId !== case.CustomerId : false,
    }">
    <mat-tab-group
      #matTabGroup
      animationDuration="0ms"
      class="h-100"
      (selectedIndexChange)="selectedTabIndexChange.emit($event)">
      <mat-tab [label]="'' + case.id">
        <div
          #scroll
          class="oa h-100 bsbb">
          <div class="actions df jsb afs">
            <button
              mat-icon-button
              [apexAnalyticsClick]="{
                eventCategory: 'case',
                eventAction: 'open-prev',
              }"
              (click)="back.next(null)">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>

            <div class="df oh ac p-c">
              <mat-menu #more="matMenu">
                <button
                  class="print"
                  mat-menu-item
                  [routerLink]="['/', 'case', case.id, 'print']"
                  (click)="printClicked.emit()">
                  <div class="df f1 ac">
                    <mat-icon>print</mat-icon>
                    <T str="Print"></T>
                  </div>
                </button>
                <button
                  mat-menu-item
                  *ngIf="userCanEdit && case.completed && !case.archivedAt"
                  (click)="archive()">
                  <mat-icon>archive</mat-icon>
                  <T str="Archive"></T>
                </button>

                <a
                  href="mailto:case+{{ case.id }}@inbound.apexapp.io"
                  mat-menu-item
                  [apexAnalyticsClick]="{
                    eventCategory: 'case',
                    eventAction: 'send-mail-to-case',
                    eventLabel: '' + case.id,
                  }">
                  <div class="df oh f1 ac">
                    <div>
                      <mat-icon>mail</mat-icon>
                    </div>
                    <T
                      class="truncate"
                      str="case+{caseId}@inbound.apexapp.io"
                      [vars]="{ caseId: case.id }"></T>
                  </div>
                </a>

                <ng-container *ngFor="let fileUsage of case.Floorplans">
                  <button
                    type="button"
                    mat-menu-item
                    [matTooltip]="'Draw on floorplan' | translate"
                    [apexAnalyticsClick]="{
                      eventCategory: 'case',
                      eventAction: 'draw-on-floorplan',
                      eventLabel: '' + case.id,
                    }"
                    (click)="drawOnFloorPlan(fileUsage)">
                    <div class="df oh f1 ac">
                      <div>
                        <mat-icon>draw</mat-icon>
                      </div>
                      <span class="truncate">
                        {{ fileUsage.fileName }}
                      </span>
                    </div>
                  </button>
                </ng-container>
              </mat-menu>

              <div class="df f1 ac p-amts p-ambs">
                <ng-container
                  *ngIf="userIsContractor && !case.completed && (userCanAcceptOrDecline || userCanComplete)">
                  <ng-container *ngIf="userCanAcceptOrDecline">
                    <apex-button
                      direction="vertical"
                      color="primary"
                      icon="thumb_up"
                      [text]="'Accept' | translate"
                      (click)="accept()">
                    </apex-button>
                    <apex-button
                      direction="vertical"
                      color="warn"
                      icon="thumb_down"
                      [text]="'Decline' | translate"
                      (click)="decline()">
                    </apex-button>
                  </ng-container>
                  <ng-container *ngIf="userCanComplete">
                    <apex-button
                      direction="vertical"
                      color="primary"
                      icon="task_alt"
                      [text]="'Done' | translate"
                      (click)="complete()">
                    </apex-button>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="userCanEdit">
                  <apex-button
                    direction="vertical"
                    [color]="case.completed ? 'primary' : undefined"
                    icon="task_alt"
                    [text]="
                      '{completed, select, 0 {Finalize} other {Reopen}}'
                        | translate: { completed: case?.completed ?? false }
                    "
                    (click)="case.completed ? reopen() : finish()">
                  </apex-button>
                </ng-container>
              </div>

              <div class="df f2 ac">
                <apex-button
                  [autofocus]="true"
                  direction="vertical"
                  [color]="case.CaseViews?.length ? 'primary' : undefined"
                  icon="priority_high"
                  [text]="
                    '{viewed, select, true {Mark as unread} other {Mark as read}}'
                      | translate: { viewed: case.CaseViews?.length > 0 }
                  "
                  (click)="case.CaseViews?.length ? markAsUnread() : markAsRead()">
                </apex-button>

                <ng-container *ngIf="case?.Floorplans?.length">
                  <apex-button
                    direction="vertical"
                    color="primary"
                    svgIcon="floorplan"
                    [text]="'Marking' | translate"
                    [apexAnalyticsClick]="{
                      eventCategory: 'case',
                      eventAction: 'open-marking',
                      eventLabel: '' + case.id,
                    }"
                    (click)="openMarking()">
                  </apex-button>
                </ng-container>

                <button
                  mat-icon-button
                  [matMenuTriggerFor]="more"
                  class="more">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
            </div>

            <button
              mat-icon-button
              (click)="next.emit()"
              [apexAnalyticsClick]="{
                eventCategory: 'case',
                eventAction: 'open-next',
              }">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
          <div></div>

          <div [ngClass]="{ vh: loading }">
            <div class="amh df c">
              <apex-translator class="amvs">
                <ng-template #normalTitle>
                  <h2 class="mat-headline-6 rm">
                    {{ case.name }}
                  </h2>
                </ng-template>

                <ng-container *ngIf="case.EntryCardId || case.fromProjectMom; else normalTitle">
                  <ng-container *ngIf="case.EntryCardId">
                    <ng-container *ngIf="entryCardComponent?.entryCard?.name">
                      <h2 class="mat-title rm">
                        <T
                          [str]="SystemLogMessages[case.name]"
                          [vars]="{ entryCardName: entryCardComponent?.entryCard?.name ?? '' }">
                        </T>
                      </h2>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="case.fromProjectMom">
                    <h2 class="mat-title rm">
                      <T
                        str="MOM for {fieldName}"
                        [vars]="{ fieldName: case.Field?.name ?? '' }"></T>
                    </h2>
                  </ng-container>
                </ng-container>
              </apex-translator>

              <apex-translator
                class="ambs"
                *ngIf="case.description && !case.fromProjectMom">
                <p class="rm">
                  {{ case.description }}
                </p>
              </apex-translator>
            </div>
            <mat-divider></mat-divider>
            <ng-container *ngIf="case?.Markings?.length">
              <apex-embedded-markings-viewer
                #marking
                [fileUsages]="caseFloorplans"
                [model]="MarkingModelType.Case"
                [modelId]="case.id"
                [disabled]="markingIsDisabled"
                (markingsChanged)="$event && updateMarkings($event)">
              </apex-embedded-markings-viewer>
              <mat-divider></mat-divider>
            </ng-container>
            <div
              *ngIf="case.AddonOrder?.id"
              class="am">
              <apex-case-addon-order [case]="case"></apex-case-addon-order>
            </div>
            <div
              *ngIf="case.ObjectId"
              class="am">
              <apex-case-object-info [objects]="case.Objects"></apex-case-object-info>
            </div>
            <div *ngIf="case.TenancyId && case.EntryCardId">
              <apex-entry-card
                #entryCard
                [caseView]="true"
                [caseAccess]="case.access"
                [entryCardId]="case.EntryCardId"
                [tenancyId]="case.TenancyId">
              </apex-entry-card>
            </div>
            <div
              *ngIf="case.AgreementId"
              class="am">
              <apex-case-agreement-info [agreement]="case.Agreement"></apex-case-agreement-info>
            </div>
            <div
              *ngIf="case.TenancyId"
              class="am">
              <apex-case-tenancy-info [tenancyId]="case.TenancyId"></apex-case-tenancy-info>
            </div>
            <div
              *ngIf="case.MeterId"
              class="am">
              <apex-case-meter-info
                [caseId]="case.id"
                [meter]="case.Meter">
              </apex-case-meter-info>
            </div>
            <div
              *ngIf="case.ChecklistId && case.Checklist"
              class="am">
              <apex-case-checklist-items
                [checklist]="case.Checklist"
                (navigate)="navigateOnClose.emit($event)"
                [checklistId]="case.ChecklistId">
              </apex-case-checklist-items>
            </div>
            <div class="am display-grid-cards card-width-200">
              <ng-container *ngIf="case.Project">
                <div class="df ac">
                  <apex-title-item class="f1">
                    <T
                      title
                      str="Project"></T>

                    <a
                      [routerLink]="[]"
                      [queryParams]="{ Project: case.Project.id }"
                      queryParamsHandling="merge"
                      (click)="closeDialogEvent.emit()">
                      {{ case.Project.name }}
                    </a>
                  </apex-title-item>
                  <button
                    [routerLink]="['/', 'project', case.Project.id]"
                    mat-icon-button
                    [matTooltip]="'Go to project' | translate">
                    <mat-icon>link</mat-icon>
                  </button>
                </div>
              </ng-container>

              <ng-container *ngIf="case.Apartment">
                <div class="df ac">
                  <apex-title-item class="f1">
                    <T
                      title
                      str="Unit"></T>

                    <a
                      [routerLink]="[]"
                      [queryParams]="{ Apartment: case.Apartment.id, Project: case.Project?.id }"
                      queryParamsHandling="merge"
                      [preserveFragment]="false"
                      (click)="closeDialogEvent.emit()">
                      {{ case.Apartment.name }}
                    </a>
                  </apex-title-item>
                  <button
                    [routerLink]="['/', 'project', case.Project.id, 'apartment', case.Apartment.id]"
                    mat-icon-button
                    [matTooltip]="'Go to unit' | translate">
                    <mat-icon>link</mat-icon>
                  </button>
                </div>
              </ng-container>

              <apex-title-item>
                <T
                  title
                  str="Category"></T>

                {{ case.CaseCategory?.name | translate }}
              </apex-title-item>

              <apex-title-item>
                <T
                  title
                  str="Discipline"></T>

                <ng-container *ngIf="case.Field || case.ObjectField">
                  <div class="df ac">
                    <div class="amr">
                      <ng-container *ngIf="case.Field">
                        {{ case.Field.name }}
                      </ng-container>
                      <ng-container *ngIf="case.ObjectField">
                        {{ case.ObjectField.fullName }}
                      </ng-container>
                    </div>
                    <apex-color-picker
                      class="amrxs"
                      disabled
                      [(ngModel)]="(case.Field || case.ObjectField).color">
                    </apex-color-picker>
                  </div>
                </ng-container>
              </apex-title-item>

              <ng-container *ngIf="case | clientsFromCase as clients">
                <ng-container *ngIf="clients.length">
                  <apex-title-item>
                    <T
                      title
                      str="{count, plural, =0 {Customer} =1 {Customer} other {Customers}} "
                      [vars]="{ count: clients.length }">
                    </T>

                    <div *ngFor="let client of clients">
                      <a
                        class="clickable md-body-1 df ac"
                        [ngClass]="{ strong: client.id === case.ClientId }"
                        [apexUserCard]="client"
                        client>
                        {{ client.name }}
                        <mat-icon
                          class="amls"
                          inline="true"
                          [matTooltip]="'User has snoozed notifications on email' | translate"
                          *ngIf="!client.mailNewCase || !client.mailNewMessage"
                          >notifications_paused
                        </mat-icon>
                      </a>
                    </div>
                  </apex-title-item>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="case.location">
                <apex-title-item>
                  <T
                    title
                    str="Location"></T>

                  {{ case.location }}
                </apex-title-item>
              </ng-container>

              <ng-container *ngIf="case.CaseManager">
                <apex-title-item>
                  <T
                    title
                    str="Case Manager"></T>

                  <apex-avatar
                    left
                    class="amrs"
                    size="small"
                    [user]="case.CaseManager">
                  </apex-avatar>

                  <span
                    class="df ac"
                    [apexUserCard]="case.CaseManager">
                    {{ case.CaseManager.name }}
                    <mat-icon
                      class="amls"
                      inline="true"
                      [matTooltip]="'User has snoozed notifications on email' | translate"
                      *ngIf="!case.CaseManager.mailNewCase || !case.CaseManager.mailNewMessage">
                      notifications_paused
                    </mat-icon>
                  </span>
                </apex-title-item>
              </ng-container>

              <apex-title-item icon="alarm">
                <T
                  title
                  str="Deadline"></T>

                <ng-container *ngIf="case.deadline; else noDeadline">
                  <span [class.warning-color]="datePassed(dateAble(case.deadline))">
                    {{ case.deadline * 1000 | date: 'shortDate' }}
                  </span>
                </ng-container>

                <ng-template #noDeadline>
                  <T str="No deadline set"></T>
                </ng-template>
              </apex-title-item>

              <ng-container *ngIf="case?.Apartment?.Takeovers?.length">
                <apex-title-item icon="timer">
                  <T
                    title
                    str="Remediation until"></T>

                  <ng-container *ngFor="let Takeover of case?.Apartment?.Takeovers; last as isLast">
                    <ng-container *ngIf="isLast">
                      <ng-container
                        *ngIf="Takeover.remediationDate && Takeover.remediationTime; else problemWithRemediation">
                        <span
                          [class.warning-color]="
                            datePassed(dateAble(Takeover.remediationDate + ' ' + Takeover.remediationTime))
                          ">
                          {{ dateAble(Takeover.remediationDate + ' ' + Takeover.remediationTime) | date: 'medium' }}
                        </span>
                      </ng-container>
                      <ng-template #problemWithRemediation>
                        <T str="Date not provided"></T>
                      </ng-template>
                    </ng-container>
                  </ng-container>
                </apex-title-item>
              </ng-container>

              <ng-container *ngIf="case?.Apartment?.ProjectApartmentTakeovers?.length">
                <apex-title-item icon="timer">
                  <T
                    title
                    str="Remediation until"></T>

                  <ng-container *ngFor="let Takeover of case?.Apartment?.ProjectApartmentTakeovers; last as isLast">
                    <ng-container *ngIf="isLast">
                      <ng-container *ngIf="Takeover.data.remediation; else problemWithRemediation">
                        <span [class.warning-color]="datePassed(Takeover.data.remediation | dater)">
                          {{ Takeover.data.remediation | dater | date: 'medium' }}
                        </span>
                      </ng-container>
                      <ng-template #problemWithRemediation>
                        <T str="Date not provided"></T>
                      </ng-template>
                    </ng-container>
                  </ng-container>
                </apex-title-item>
              </ng-container>

              <!--
              <ng-container *ngIf="case?.User">
                <apex-title-item icon="info_outline">
                  <span title>
                    <T str="Case created by"></T>
                    <a [apexUserCard]="case.User">
                      {{ case.User.name }}
                    </a>
                  </span>

                  {{ case.createdAt | date: 'short' }}
                </apex-title-item>
              </ng-container>
              -->

              <ng-container *ngIf="case?.fromRequest">
                <apex-title-item>
                  <T
                    title
                    str="Request created"></T>

                  {{ case.fromRequestTime | dater | date: 'short' }}
                </apex-title-item>
              </ng-container>

              <ng-container *ngIf="case.ChecklistItem?.id">
                <apex-title-item>
                  <T
                    title
                    str="Checklist"></T>

                  <a
                    [routerLink]="['/', 'checklist', case.ChecklistItem.Checklist.id]"
                    (click)="navigateOnClose.emit({ commands: ['/checklist', case.ChecklistItem.Checklist.id] })">
                    {{ case.ChecklistItem.Checklist?.name }}
                  </a>
                </apex-title-item>
              </ng-container>

              <ng-container *ngIf="case.deviationChecklist?.name">
                <apex-title-item>
                  <T
                    title
                    str="Deviation on"></T>

                  <a
                    [routerLink]="['/', 'checklist', case.deviationChecklist.id]"
                    (click)="navigateOnClose.emit({ commands: ['/checklist', case.deviationChecklist.id] })">
                    {{ case.deviationChecklist.name }}
                  </a>
                </apex-title-item>
              </ng-container>

              <ng-container *ngIf="case.Customer?.name">
                <apex-title-item icon="corporate_fare">
                  <T
                    title
                    str="Customer"
                    context="case-company"
                    _context="case-company"></T>
                  {{ case.Customer?.name }}
                </apex-title-item>
              </ng-container>

              <ng-container *ngIf="case.ProjectId && case.ApartmentId">
                <div class="df ac">
                  <a
                    target="_blank"
                    [routerLink]="['/', 'mom-viewer', case?.ProjectId, case?.ApartmentId]"
                    [matTooltip]="'View MOM' | translate">
                    <T str="View MOM"></T>
                  </a>
                </div>
              </ng-container>
            </div>

            <ng-container *ngIf="case.Tags?.length">
              <apex-title-item class="add-margin-horizontal add-margin-bottom-small">
                <T
                  title
                  str="Tags"></T>

                <mat-chip-set>
                  <mat-chip *ngFor="let tag of case.Tags">{{ tag.name }}</mat-chip>
                </mat-chip-set>
              </apex-title-item>
            </ng-container>

            <div class="contractors-wrapper amh">
              <apex-case-contractors
                #caseContractors
                [case]="case"
                [view]="true"
                (addContractor)="addContractor($event)"
                (removeContractor)="removeContractor($event)"
                (editContractor)="editContractor($event)">
              </apex-case-contractors>
            </div>

            <div class="am">
              <apex-file-usage
                #caseFiles
                self="case"
                name="files"
                [selfId]="case.id"
                [title]="'Files' | translate"
                [allowEdit]="case?.access?.caseManager || case?.access?.admin">
              </apex-file-usage>
            </div>

            <div class="files-are-visible-reminder am">
              <T
                *ngIf="case.CaseCategoryId | categoryIsPublicForClient"
                str="Note: Files are visible to customer and contractor"></T>
              <T
                *ngIf="!(case.CaseCategoryId | categoryIsPublicForClient)"
                str="Note: Files are visible contractor"></T>
            </div>

            <div class="new-message mat-elevation-z2 am ap">
              <apex-case-new-message
                #newMessage
                [cases]="[case]"
                (new)="addNewMessage($event)">
              </apex-case-new-message>
              <div class="df jfe">
                <button
                  mat-raised-button
                  color="primary"
                  (click)="newMessage.save()"
                  [disabled]="!!newMessage?.newMessageForm?.invalid">
                  <T str="Save"></T>
                </button>
              </div>
            </div>
            <apex-case-logs
              class="am"
              [caseId]="case.id"
              [caseLogs]="case.CaseLogs"></apex-case-logs>
          </div>
        </div>
      </mat-tab>
      <mat-tab
        [label]="'Edit' | translate"
        [disabled]="!case?.access?.caseManager && !case?.access?.admin">
        <ng-template matTabContent>
          <div
            *ngIf="case?.access?.caseManager || case?.access.admin"
            class="oa h-100 bsbb"
            [ngClass]="{ vh: loading }">
            <div class="ams">
              <apex-case-form
                #formComponent
                [case]="formCase"></apex-case-form>
            </div>
            <div class="am df jfe save-btn">
              <button
                mat-raised-button
                [disabled]="formComponent.invalid"
                (click)="save()"
                color="primary">
                <T str="Save"></T>
              </button>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
