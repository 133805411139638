import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { floor } from 'lodash-es';
import { interval } from 'rxjs';
import { ConfirmDialogData } from './confirm-dialog.types';

@Component({
  selector: 'apex-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent implements OnInit {
  readonly timerInterval = 250;
  timeProgress = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = !!this.data?.disableClose;

    if (this.data?.timer) {
      const timer = interval(this.timerInterval).subscribe(() => {
        const next = this.timeProgress + (this.timerInterval / this.data.timer.dur) * 100;

        if (next >= 100) {
          this.data.timer.val ? this.confirm() : this.cancel();
          timer.unsubscribe();
        } else {
          this.timeProgress = next;
        }
      });
    }
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  getSecondsRemaining(): number {
    return floor((this.data.timer.dur - (this.timeProgress * this.data.timer.dur) / 100) / 1000);
  }
}
