<div class="df jfs f1">
  <ng-container *ngIf="icon">
    <mat-icon
      class="amrs"
      [fontSet]="fontSet">
      {{ icon }}
    </mat-icon>
  </ng-container>

  <div class="df c f1 oh">
    <ng-content></ng-content>

    <span class="mat-caption amtxs">
      <ng-content select="[subtitle]"></ng-content>
    </span>
  </div>
</div>
