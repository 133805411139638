/// <reference types="googlemaps" />

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'apex-map',
  templateUrl: 'map.component.html',
})
export class MapComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() lat: number;
  @Input() lng: number;

  @Input() address: string;
  @Input() zipcode: number;

  @Input() zoom = 14;

  @Input() pin = false;

  @Input() geocode = false;

  @Output() geocodingResult = new EventEmitter<google.maps.LatLng>();

  @ViewChild('map') map: ElementRef<HTMLDivElement>;

  private googleMap: google.maps.Map;
  private marker: google.maps.Marker;

  private geocodingSearch = new Subject<string>();
  private geocodingSearch$$ = this.geocodingSearch.pipe(debounceTime(350)).subscribe({
    next: (address) => {
      if (this.geocode) {
        this.geocoder.geocode({ address }, (res, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            this.geocodingResult.next(res[0].geometry.location);
          }
        });
      }
    },
  });
  private geocoder = new google.maps.Geocoder();

  @HostListener('mouseenter') mouseEnter(): void {
    this.googleMap?.setOptions({
      disableDefaultUI: false,
    });
  }

  @HostListener('mouseleave') mouseLeave(): void {
    this.googleMap?.setOptions({
      disableDefaultUI: true,
    });
  }

  ngOnDestroy(): void {
    this.geocodingSearch$$.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.googleMap = new google.maps.Map(this.map.nativeElement, {
      disableDefaultUI: true,
    });

    this.setView();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.address || changes.zipcode) {
      if (this.address && this.zipcode) {
        const s = `${this.address}, ${this.zipcode}`;

        this.geocodingSearch.next(s);
      }
    }

    this.setView();
  }

  setView(): void {
    if (!this.googleMap) {
      return;
    }

    if (this.lat && this.lng) {
      this.googleMap.setCenter(this.getPosition());
    }

    if (this.zoom) {
      this.googleMap.setZoom(this.zoom);
    }

    if (this.pin && !this.marker) {
      const position = this.getPosition();

      this.marker = new google.maps.Marker({
        map: this.googleMap,
        position,
        title: `${position.lat()}, ${position.lng()}`,
      });
    } else if (this.pin) {
      this.marker.setPosition(this.getPosition());
    }
  }

  private getPosition(): google.maps.LatLng {
    return new google.maps.LatLng(this.lat, this.lng);
  }
}
