<div mat-dialog-title>
  <div class="df jsb ac f1 oh">
    <h1 class="truncate">{{ data?.checklist?.name }}</h1>
    <button
      mat-icon-button
      (click)="ref.close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content>
  <div
    *ngIf="data?.checklist?.description"
    class="amb"
    [innerHtml]="data.checklist.description"></div>
  <apex-checklist-group-checklist [checklist]="data?.checklist"></apex-checklist-group-checklist>
</div>
