import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'queryParamAsArray',
  standalone: true,
})
export class QueryParamAsArrayPipe implements PipeTransform {
  transform(value: string | string[]): string[] {
    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  }
}
