import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QrCodeDialogComponent } from '../qr-code-dialog/qr-code-dialog.component';
import QrCodeImageComponent from '../qr-code-image/qr-code-image.component';
import { TranslateModule } from '../translate/translate.module';
import { QrCodeComponent } from './qr-code.component';

@NgModule({
  declarations: [QrCodeComponent],
  exports: [QrCodeComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    TranslateModule,
    FormsModule,
    MatInputModule,
    ClipboardModule,
    MatDividerModule,
    MatDividerModule,
    QrCodeImageComponent,
    QrCodeDialogComponent,
  ],
  providers: [],
})
export class QrCodeModule {}
