import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { PDFViewerModule } from 'projects/apex/src/app/components/pdf-viewer/pdf-viewer.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { FolderService } from 'projects/apex/src/app/services/folder/folder.service';
import { AutoFocusDirectiveModule } from '../../directives/auto-focus/auto-focus..directive.module';
import { URLCacheModule } from '../../pipes/url-cache/url-cache.module';
import { FileMailViewerComponent } from '../file-mail-viewer/file-mail-viewer.component';
import { ImageViewerModule } from '../image-viewer/image-viewer.module';
import { FileViewerDialogComponent } from './dialog.component';
import { FileViewerComponent } from './file-viewer.component';
import { FileViewerPreviewModule } from './preview/file-viewer-preview.module';

const components = [FileViewerComponent, FileViewerDialogComponent];

@NgModule({
  imports: [
    CommonModule,
    PDFViewerModule,
    DragDropModule,
    ImageViewerModule,
    RouterModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    FileMailViewerComponent,
    TranslateModule,
    URLCacheModule,
    MatTooltipModule,
    AutoFocusDirectiveModule,
    FileViewerPreviewModule,
  ],
  providers: [FolderService],
  declarations: components,
  exports: components,
})
export class FileViewerModule {}
