<h1 mat-dialog-title>
  <T
    *ngIf="type === 'checklist'"
    str="Delete selected {count, plural, one {checklist} other {checklists}}"
    [vars]="{ count: selectedChecklists?.length ?? 0 }"></T>
  <T
    *ngIf="type === 'checklistGroup'"
    str="Delete selected checklist {count, plural, one {group} other {groups}}"
    [vars]="{ count: selectedChecklists?.length ?? 0 }"></T>
</h1>

<mat-dialog-content>
  <mat-list
    role="list"
    *ngFor="let checklist of selectedChecklists"
    dense>
    <mat-list-item
      lines="1"
      role="listitem">
      <p matListItemLine>{{ checklist.name }}</p>
    </mat-list-item>
    <mat-divider></mat-divider>
  </mat-list>

  <p class="add-padding-top">
    <T
      *ngIf="type === 'checklist'"
      str="Are you sure you want to delete {count, plural, one {this checklist} other {these checklists}} and all {count, plural, one {it's} other {their}} items? Completed items will be archived."
      [vars]="{ count: selectedChecklists?.length ?? 0 }"></T>
    <T
      *ngIf="type === 'checklistGroup'"
      str="Are you sure you want to delete {count, plural, one {this checklist group} other {these checklist groups}} and all {count, plural, one {it's} other {their}} items? Completed items will be archived."
      [vars]="{ count: selectedChecklists?.length ?? 0 }"></T>
  </p>
  <p class="add-padding-top">
    <mat-checkbox [(ngModel)]="completeAndArchiveCheck">
      <T str="Also complete and archive all connected cases?"></T>
    </mat-checkbox>
  </p>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    type="button"
    mat-button
    [mat-dialog-close]="{ del: false, completeAndArchiveCheck: completeAndArchiveCheck }">
    <T str="No"></T>
  </button>
  <button
    type="button"
    mat-raised-button
    color="warn"
    [mat-dialog-close]="{
      del: true,
      completeAndArchiveCheck: completeAndArchiveCheck,
    }">
    <T str="Delete"></T>
  </button>
</mat-dialog-actions>
