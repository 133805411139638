import { Pipe, PipeTransform } from '@angular/core';

import { Obj } from '../../features/object/object.model';

const defaultIcon = 'donut_large';
const objectIcons = {
  safety: {
    control: 'policy',
    product: 'security',
    sale: 'payment',
  },
  commercial: {
    project: 'domain',
    building: 'apartment',
    floor: 'layers',
    unit: 'donut_large',
    room: 'meeting_room',
    tenancy: 'home_work',
    residence: 'home',
    elevator: 'elevator',
  },
};

export const iconForObject = (obj: Obj): string => {
  const f: string =
    objectIcons[obj.meta] && objectIcons[obj.meta][obj.type] ? objectIcons[obj.meta][obj.type] : defaultIcon;

  return f || defaultIcon;
};

/**
 * @description
 *
 * Returns the correct icon for any Obj.
 *
 * Default icon is accessible_forward.
 */
@Pipe({
  name: 'icon',
})
export class IconPipe implements PipeTransform {
  /**
   *
   * @param input The obj to determine icon for.
   */
  transform(input: Obj): string {
    if (!input) {
      return defaultIcon;
    }

    if (input?.meta && input?.type) {
      // Part of Object
      return iconForObject(input);
    }

    return defaultIcon;
  }
}
