<mat-form-field>
  <mat-label><T str="Language"></T></mat-label>
  <mat-select
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    [disabled]="innerDisabled"
    [required]="innerRequired"
    name="locale">
    <ng-container *ngFor="let language of languages$ | async">
      <mat-option [value]="language.code">{{ language.name }}</mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
