<form
  #form="ngForm"
  (submit)="ref.close(section)">
  <div mat-dialog-title>
    <div class="df jsb ac f1">
      <h1>
        <T
          str="New section"
          *ngIf="!data?.section?.id"></T>
        <ng-container *ngIf="data?.section?.id">
          {{ data.section.name }}
        </ng-container>
      </h1>
      <button
        mat-icon-button
        type="button"
        (click)="ref.close()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content class="df c">
    <mat-form-field>
      <input
        matInput
        type="text"
        name="name"
        required
        apexAutoFocus
        [(ngModel)]="section.name"
        [placeholder]="'Title' | translate" />
    </mat-form-field>
    <mat-form-field>
      <textarea
        matInput
        type="text"
        name="description"
        [(ngModel)]="section.description"
        [placeholder]="'Description' | translate">
      </textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div></div>
    <button
      mat-raised-button
      type="submit"
      color="primary"
      [disabled]="form?.invalid">
      <T str="Save"></T>
    </button>
  </mat-dialog-actions>
</form>
