<section class="df ac jsb apvs navigation-bar mat-elevation-z1">
  <div class="df ac">
    <button
      type="button"
      mat-icon-button
      [matTooltip]="'Previous {view}' | translate: { view }"
      (click)="changeCurrent('backwards')">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button
      type="button"
      mat-icon-button
      [matTooltip]="'Next {view}' | translate: { view }"
      (click)="changeCurrent('forwards')">
      <mat-icon>chevron_right</mat-icon>
    </button>

    <h3 class="rm amr">{{ monthName }} {{ year }}</h3>
  </div>

  <div>
    <h3
      class="rm amr"
      *ngIf="view === calenderView.Week">
      <T
        str="Week {week}"
        [vars]="{ week }">
      </T>
    </h3>

    <h3
      class="rm amr"
      *ngIf="view === calenderView.Day">
      {{ dateDay }} {{ date }}
    </h3>
  </div>

  <div class="df ac">
    <button
      type="button"
      class="aml"
      [matTooltip]="todayTooltip"
      mat-raised-button
      (click)="today()">
      <T str="Today"></T>
    </button>

    <button
      type="button"
      class="aprxs aml"
      mat-raised-button
      [matMenuTriggerFor]="viewMenu">
      <T
        str="{view, select, day {Day} week {Week} month {Month} other {Unknown}}"
        [vars]="{ view }">
      </T>
    </button>

    <button
      type="button"
      class="aprxs aml amr"
      mat-raised-button
      [matTooltip]="'Copy URL for iCal' | translate"
      [cdkCopyToClipboard]="icalUrl"
      (cdkCopyToClipboardCopied)="snack(t('Copied'))">
      iCal
      <mat-icon class="amls">content_copy</mat-icon>
    </button>
  </div>
</section>

<section
  class="calendar df c f1"
  [class.loading-screen]="loading">
  <apex-calendar-day-view
    *ngIf="view === calenderView.Day"
    [cases]="cases"
    [repeatableCases]="repeatableCases"
    [futureRepeatableCases]="futureRepeatableCases"
    [loading]="loading"
    (viewCase)="viewCase($event)"
    (createCase)="createCase($event)"
    (createCaseFromRepeatableCase)="createCaseFromRepeatableCase($event)"
    (editRepeatableCase)="editRepeatableCase($event)"
    (deleteRepeatableCase)="deleteRepeatableCase($event)">
  </apex-calendar-day-view>

  <apex-calendar-week-view
    *ngIf="view === calenderView.Week"
    [cases]="cases"
    [repeatableCases]="repeatableCases"
    [futureRepeatableCases]="futureRepeatableCases"
    [today]="todaym"
    [loading]="loading"
    (viewCase)="viewCase($event)"
    (createCase)="createCase($event)"
    (createCaseFromRepeatableCase)="createCaseFromRepeatableCase($event)"
    (editRepeatableCase)="editRepeatableCase($event)"
    (deleteRepeatableCase)="deleteRepeatableCase($event)"
    (goToView)="goToView(calenderView.Day, $event.slot)">
  </apex-calendar-week-view>

  <ng-container *ngIf="view === calenderView.Month">
    <div
      *ngFor="let row of calendarRows; index as i"
      class="df f1 row">
      <div class="row-slots-wrapper df f1">
        <div
          *ngFor="let slot of row.slots"
          class="df"
          [ngClass]="{ f0: slot.weekNumber, f1: slot.date }">
          <h3
            *ngIf="slot.weekNumber"
            matRipple
            (click)="goToView(calenderView.Week, slot)"
            class="df jc rm weekslot">
            {{ slot.weekNumber }}
          </h3>
          <div
            *ngIf="slot.date"
            class="df c f1 ac dateslot oh"
            mat-ripple
            (click)="createCase(slot)">
            <h3
              class="rm df ac jc w-100"
              [ngClass]="{ today: slot.moment.isSame(todaym, 'd') }">
              <div
                class="date-wrapper df ac"
                matRipple
                (click)="goToView(calenderView.Day, slot); $event.stopPropagation()">
                <div
                  class="df ac jc date aph"
                  [ngClass]="{ 'other-month': slot.moment.month() !== month }">
                  <div *ngIf="slot.day">
                    {{ slot.day }}
                  </div>
                  <div
                    *ngIf="slot.date === 1"
                    [class.month-name]="slot.date === 1"
                    class="amls">
                    {{ monthsShort[slot.moment.month()] }}
                  </div>
                  <div>
                    {{ slot.date }}
                  </div>
                </div>
              </div>
            </h3>
          </div>
        </div>
      </div>
      <section class="row-case-wrapper df">
        <div
          *ngFor="let slot of row.slots"
          class="df"
          mat-ripple
          [ngClass]="{ f0: slot.weekNumber, f1: slot.date }"
          (click)="createCase(slot)"></div>
        <ng-container *ngFor="let caseData of rowsCases[i]?.cases">
          <div
            class="case-wrapper aprs"
            (click)="!caseData.id ? rcMenuTrigger.openMenu() : viewCase(caseData, rcMenuTrigger)"
            #rcMenuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="rcMenu"
            [matMenuTriggerData]="{ case: caseData }"
            [ngStyle]="{
              'top.px': caseData?.sortOrder * 28,
              'left.%': caseData?.startDay * (100 / 7),
              'width.%': caseData?.length * (100 / 7),
              'max-width.%': caseData?.length * (100 / 7),
            }">
            <div
              class="case df ac"
              mat-ripple
              [ngClass]="{
                'mat-elevation-z2 new': !isSameDay(caseData?.from, caseData?.to),
                rc: !caseData?.id,
              }"
              [matTooltip]="caseData.name">
              <span
                class="amrs dot"
                *ngIf="isSameDay(caseData?.from, caseData.to)">
              </span>
              <div class="case-data">
                <span
                  class="amrs"
                  *ngIf="caseData.from">
                  {{ caseData.from | dater | date: 'HH:mm' }}
                </span>
                #{{ caseData.id || caseData.RepeatableCase?.id }} - {{ caseData.name }}
              </div>
            </div>
          </div>
        </ng-container>
      </section>
    </div>
  </ng-container>
</section>

<button
  mat-fab
  type="button"
  class="fab-button"
  (click)="createCase()">
  <mat-icon> add</mat-icon>
</button>

<mat-menu #rcMenu="matMenu">
  <ng-template
    matMenuContent
    let-caseData="case"
    let-slot="slot">
    <button
      mat-menu-item
      (click)="createCaseFromRepeatableCase(caseData)">
      <T str="Create case from repeatable case"></T>
    </button>
    <button
      mat-menu-item
      (click)="editRepeatableCase(caseData)">
      <T str="Edit repeatable case"></T>
    </button>
    <button
      mat-menu-item
      (click)="deleteRepeatableCase(caseData)">
      <T str="Delete repeatable case series"></T>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #viewMenu="matMenu">
  <ng-template matMenuContent>
    <button
      mat-menu-item
      (click)="goToView(calenderView.Day)">
      <mat-icon>calendar_view_day</mat-icon>
      <T str="Day"></T>
    </button>
    <button
      mat-menu-item
      (click)="goToView(calenderView.Week)">
      <mat-icon>calendar_view_week</mat-icon>
      <T str="Week"></T>
    </button>
    <button
      mat-menu-item
      (click)="goToView(calenderView.Month)">
      <mat-icon>calendar_view_month</mat-icon>
      <T str="Month"></T>
    </button>
  </ng-template>
</mat-menu>
