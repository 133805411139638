import { ActivatedRouteSnapshot } from '@angular/router';
import { ObjectWithId } from '../../utils/types';
import { Obj, ObjectWithDepth } from './object.model';

export const createStepsSortFn =
  (c: ObjectWithId) =>
  (a: Obj, b: Obj): number =>
    c[a.id] > c[b.id] ? 1 : c[a.id] < c[b.id] ? -1 : 0;

export const calculateStepsForObjects = (o: Obj, s: number, c: Obj[]): number => {
  if (!s) {
    s = 0;
  }

  s++;

  if (o.ParentId) {
    const parent = c.find((fo) => fo.id === o.ParentId);

    if (parent) {
      return calculateStepsForObjects(parent, s, c);
    }
  }

  return s;
};

/**
 * Get object id from route.
 * Falls back to top level project id if no object id is found.
 */
export const getObjectId = (route: ActivatedRouteSnapshot): number => {
  const oid = Number(route.paramMap.get('oid')) ?? 0;
  const pid = Number(route.paramMap.get('pid')) ?? 0;

  const objectId = oid ? oid : pid;

  if (!objectId) {
    throw new Error('objectId can not be found');
  }

  return objectId;
};

export const sortObjectsByDepth = (parent: Obj, allObjects: Obj[]): ObjectWithDepth[] => {
  const stack = [parent];
  const list: ObjectWithDepth[] = [];

  const sortedObjects = allObjects.sort((a, b) => b.id - a.id);

  do {
    const current = stack.pop();

    list.push(current as ObjectWithDepth);

    const children = sortedObjects.filter((obj) => obj.ParentId === current.id);

    stack.push(...children);
  } while (stack.length > 0);

  list.forEach((item) => {
    const parentObject = list.find((o) => o.id === item.ParentId);

    item.depth = (parentObject?.depth ?? 0) + 1;
  });

  return list;
};
