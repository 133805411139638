import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ChecklistItemModule } from 'projects/apex/src/app/features/checklist/item/checklist-item.module';
import { ChecklistItemService, ChecklistService } from '../../features/checklist/checklist.service';
import { ChecklistSectionService } from '../../services/checklist-section/checklist-section.service';
import { FileUsageModule } from '../file-usage/file-usage.module';
import { ChecklistSectionComponent } from './checklist-section.component';

const components = [ChecklistSectionComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, FileUsageModule, ChecklistItemModule, MatButtonModule, MatIconModule, MatDividerModule],
  providers: [ChecklistService, ChecklistItemService, ChecklistSectionService],
  exports: components,
})
export class ChecklistSectionModule {}
