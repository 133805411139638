import { NgModule } from '@angular/core';

import { IconPipe } from './icon.pipe';

@NgModule({
  imports: [],
  exports: [IconPipe],
  declarations: [IconPipe],
  providers: [],
})
export class IconPipeModule {}
