import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ChecklistSectionComponent } from 'projects/apex/src/app/components/checklist-section/checklist-section.component';
import { Checklist, ChecklistItem } from 'projects/apex/src/app/features/checklist/checklist.model';
import { ChecklistItemService, ChecklistService } from 'projects/apex/src/app/features/checklist/checklist.service';
import { ChecklistSectionService } from 'projects/apex/src/app/services/checklist-section/checklist-section.service';
import { Subscription, forkJoin } from 'rxjs';
import { ChecklistSection } from '../../checklist/checklist.model';
import { sortChecklistItems, sortChecklistSections } from '../../checklist/checklist.types';

@Component({
  selector: 'apex-checklist-group-checklist',
  templateUrl: './checklist.component.html',
})
export class ChecklistGroupChecklistComponent implements OnInit, OnChanges, OnDestroy {
  @Input() checklist: Checklist;

  @Input() checklistItems: ChecklistItem[] = [];
  @Input() checklistSections: ChecklistSection[] = [];

  @Output() caseCompleted = new EventEmitter();
  @Output() caseReopened = new EventEmitter();
  @Output() caseDeleted = new EventEmitter();
  @Output() countChange = new EventEmitter();

  @ViewChild(ChecklistSectionComponent) checklistSectionComponent: ChecklistSectionComponent;

  loading: boolean;
  sub: Subscription;

  constructor(
    private cs: ChecklistService,
    private cis: ChecklistItemService,
    private css: ChecklistSectionService,
  ) {}

  ngOnInit(): void {
    this.sub = this.cs.$countCompleted.subscribe({
      next: (value) => {
        if (value) {
          const completed = this.checklistSectionComponent?.checklistItemComponents.filter(
            (cic) => cic.checklistItem.Case?.completed,
          );

          this.checklist.casesCompleted = completed?.length ?? 0;
          this.countChange.emit();
        }
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.checklist) {
      this.loading = true;
      forkJoin({
        checklistItems: this.cis.getItems(this.checklist.id),
        checklistSections: this.css.getSections(this.checklist.id),
      }).subscribe({
        next: (res) => {
          this.checklistItems = sortChecklistItems(res.checklistItems || []);
          this.checklistSections = sortChecklistSections(res.checklistSections || []);
        },
        complete: () => {
          this.loading = false;
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
