import { Pipe, PipeTransform } from '@angular/core';
import { ResponseCaseLogDto } from '../../../../../../../../generated/apex-rest';
import { CaseLog } from '../../../../models/case-log';
import { LogMail } from '../../../../models/case-log-mail';

type BasicEmail = Pick<LogMail, 'fromMail' | 'fromName' | 'subject' | 'bodyPlain' | 'bodyHTML'>;

@Pipe({
  name: 'caseLogEmail',
  standalone: true,
})
export class CaseLogEmailPipe implements PipeTransform {
  transform(value: CaseLog | ResponseCaseLogDto): BasicEmail | null {
    if ('LogMail' in value) {
      return value.LogMail;
    }

    if ('Email' in value) {
      return value.Email;
    }

    return null;
  }
}
