import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { TextListModule } from 'projects/apex/src/app/components/text-list/text-list.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { TextService } from 'projects/apex/src/app/features/text/text.service';
import { UsageExplorerComponent } from './usage-explorer.component';

@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    TextListModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    MatDividerModule,
    MatListModule,
  ],
  providers: [TextService],
  declarations: [UsageExplorerComponent],
})
export class UsageExplorerModule {}
