import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { snack, snackErr } from 'projects/apex/src/app/modules/snack.module';
import { environment } from 'projects/apex/src/environments/environment';
import { Observable } from 'rxjs';
import { finalize, switchMap, take } from 'rxjs/operators';
import { getUserGeolocation } from '../../../../utils/functions';
import { ObjectPosition } from '../../../case/case.types';

@Component({
  selector: 'apex-object-gps-search-button',
  templateUrl: './gps-search-button.component.html',
})
export class ObjectGPSSearchButtonComponent {
  @Output() found: EventEmitter<ObjectPosition> = new EventEmitter<ObjectPosition>();

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  public loading = false;
  public results: ObjectPosition[] = [];

  constructor(private http: HttpClient) {}

  searchGPS(): void {
    this.loading = true;

    snack(t('Searching for nearby objects'));

    this.getLocation()
      .pipe(
        take(1),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: (positions) => {
          if (!positions?.length) {
            return snack(t('No objects found nearby'));
          }

          if (positions.length === 1) {
            return this.found.emit(positions.shift());
          }

          this.results = positions;
          this.trigger.openMenu();
        },

        error: (err) => {
          snackErr(t('Failed to get GPS position'), err);
        },
      });
  }

  select(op: ObjectPosition): void {
    this.found.emit(op);
  }

  getLocation(): Observable<ObjectPosition[]> {
    return getUserGeolocation().pipe(
      switchMap((pos) =>
        this.http.post<ObjectPosition[]>(
          `${environment.api}/object/gps`,
          {
            lng: pos.coords.longitude,
            lat: pos.coords.latitude,
          },
          {
            withCredentials: true,
          },
        ),
      ),
      take(1),
    );
  }
}
