<mat-expansion-panel
  class="contractors"
  [expanded]="case.Contractors?.length > 0 && expanded">
  <mat-expansion-panel-header
    class="df ac apls new"
    [ngClass]="{
      'bg-color-main': case.Contractors?.length,
      'bg-color-empty': !case.Contractors?.length && case.CaseCategoryId !== 14,
      'bg-color-warn': !case.Contractors?.length && case.CaseCategoryId === 14,
    }">
    <mat-panel-title class="df ac oh">
      <div class="df ac f1 oh">
        <mat-icon
          class="amrs"
          [matTooltip]="'Contractors' | translate"
          svgIcon="contractor">
        </mat-icon>
        <span class="truncate">
          <T
            str="Contractors ({count})"
            [vars]="{ count: case?.Contractors?.length ?? 0 }">
          </T>
        </span>
      </div>

      <button
        mat-icon-button
        type="button"
        [disabled]="disabled"
        [matTooltip]="'Add involved user' | translate"
        (click)="addInvolvedUser(); $event.stopPropagation()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <main class="df c contractors">
    <ng-container *ngIf="!case.Contractors?.length">
      <div class="ams">
        <T str="No contractors"></T>
      </div>
    </ng-container>
    <ng-container *ngFor="let contractor of case.Contractors; let isFirst = first">
      <div
        class="df ac amls amvs contractor-container"
        [ngClass]="{ 'nested-contractor': (contractor | stepsFromCreator: case.Contractors) > 0 }"
        [style.paddingLeft.px]="(contractor | stepsFromCreator: case.Contractors) * 30">
        <div
          *ngIf="!isFirst"
          class="line-container"
          [style.marginLeft.px]="(contractor | stepsFromCreator: case.Contractors) * 30 - 10">
          <div
            class="vertical-line"
            [ngClass]="{
              'vertical-line-adjustment': (contractor | stepsFromCreator: case.Contractors) > 0,
            }"
            [style.height.px]="1000"
            *ngIf="case.Contractors?.length > 0 && (contractor | stepsFromCreator: case.Contractors) > 0"></div>
          <div
            class="horizontal-line"
            *ngIf="(contractor | stepsFromCreator: case.Contractors) > 0"
            [style.width.px]="15"></div>
        </div>
        <div class="df ac f1 contractor amrs line-content">
          <apex-avatar
            class="amr"
            [user]="contractor"
            [tooltip]="contractor | statusMessageForContractor"
            [borderFillColor]="contractor | statusColorForContractor">
          </apex-avatar>
          <div class="df c">
            <div class="df ac mat-h4 rm">
              <span
                class="tooltip-wrapper"
                [apexUserCard]="contractor"
                [matTooltip]="contractor | statusMessageForContractor"
                [subtitle]="contractor | statusMessageForContractor"
                [subtitleIcon]="contractor | statusIconForContractor">
                {{ contractor.name }}
              </span>
              <mat-icon
                class="amhs"
                inline="true"
                color="primary"
                [matTooltip]="'User has snoozed notifications on email' | translate"
                *ngIf="!contractor.mailNewCase || !contractor.mailNewMessage">
                notifications_paused</mat-icon
              >
            </div>
            <div class="df c">
              <div class="mat-caption rm">{{ contractor.Customer?.name }}</div>
            </div>
          </div>
        </div>

        <div class="df ac">
          <button
            type="button"
            *ngIf="view"
            [matTooltip]="'Set your planned time' | translate"
            [disabled]="!isCurrentUser(contractor)"
            mat-button
            color="primary"
            (click)="editContractorTime(contractor)">
            <ng-container
              *ngIf="
                !contractor.CaseContractor?.from && !contractor.CaseContractor?.to && isCurrentUser(contractor);
                else showFromTo
              ">
              <T str="From - To"></T>
            </ng-container>
            <ng-template #showFromTo>
              <div>
                <span
                  [matTooltip]="'From' | translate"
                  [class.warning-color]="!fromWithin(contractor)">
                  {{ contractor.CaseContractor?.from | dater | date: 'shortDate' }} -
                </span>
                <span
                  class="wsbs"
                  [matTooltip]="'To' | translate"
                  [class.warning-color]="!toWithin(contractor)">
                  {{ contractor.CaseContractor?.to | dater | date: 'shortDate' }}
                </span>
              </div>
            </ng-template>
          </button>

          <button
            mat-icon-button
            [disabled]="disabled"
            type="button"
            color="warn"
            [matTooltip]="'Remove contractor' | translate"
            (click)="remove(contractor)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </main>
</mat-expansion-panel>
