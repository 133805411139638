import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutocompleteModule } from 'projects/apex/src/app/components/autocomplete/autocomplete.module';
import { FileUsageService } from 'projects/apex/src/app/components/file-usage/file-usage.service';
import { FileService } from 'projects/apex/src/app/components/file-usage/file.service';
import { FolderShareComponent } from 'projects/apex/src/app/components/folder/share/share.component';
import { ModelRoleService } from 'projects/apex/src/app/components/model-role/model-role.service';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { UploadModule } from 'projects/apex/src/app/components/upload/upload.module';
import { InViewDirectiveModule } from 'projects/apex/src/app/directives/in-view/in-view.directive.module';
import { ObjectService } from 'projects/apex/src/app/features/object/object.service';
import { DaterModule } from 'projects/apex/src/app/pipes/dater/dater.module';
import { FolderService } from 'projects/apex/src/app/services/folder/folder.service';
import { CustomerService } from '../../features/customer/customer.service';
import { FileSizePipeModule } from '../../pipes/file-size/file-size.module';
import { ConfirmDialogModule } from '../confirm-dialog/confirm-dialog.module';
import { DragModule } from '../drag/drag.module';
import { FilePreviewModule } from '../file-preview/file-preview.module';
import { InputDialogModule } from '../input-dialog/input-dialog.module';
import { SearchModule } from '../search/search.module';
import { FolderDialogComponent } from './dialog.component';
import { FolderComponent } from './folder.component';
import { FolderTreeDialogComponent } from './tree/dialog.component';
import { FolderTreeComponent } from './tree/tree.component';

const components = [
  FolderComponent,
  FolderDialogComponent,
  FolderTreeComponent,
  FolderTreeDialogComponent,
  FolderShareComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    TranslateModule,
    FilePreviewModule,
    InputDialogModule,
    FileSizePipeModule,
    ConfirmDialogModule,
    InViewDirectiveModule,
    UploadModule,
    AutocompleteModule,
    SearchModule,
    DaterModule,

    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatRippleModule,
    MatMenuModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTabsModule,
    MatPaginatorModule,
    MatTooltipModule,
    DragDropModule,
    DragModule,
    MatListModule,
    MatSelectModule,
    MatDatepickerModule,
  ],
  providers: [FileUsageService, FileService, FolderService, ObjectService, ModelRoleService, CustomerService],
  declarations: components,
  exports: components,
})
export class FolderModule {}
