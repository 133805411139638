<div
  class="df c mat-elevation-z2 set-max-height"
  style="--max-height: 360px">
  <div class="bg-color-main df ac jsb header">
    <div class="df ac">
      <mat-icon class="amh">groups</mat-icon>
      <h2>
        <T str="Entry groups"></T>
      </h2>
    </div>
    <div class="entry-group-actions amrs df ac">
      <button
        mat-icon-button
        type="button"
        [matTooltip]="'Add entry group' | translate"
        [routerLink]="['entry-group', 'new']"
        routerLinkActive="router-link-active">
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        type="button"
        [matTooltip]="'Search' | translate"
        [color]="search ? 'primary' : ''"
        (click)="toggleSearch()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <div
      class="entry-group-search apl aprs df ac f1 mat-elevation-z2"
      [ngClass]="{ 'op-0 no-touch': !showSearch, 'op-1': showSearch }">
      <mat-form-field class="f1 amrs">
        <input
          matInput
          type="text"
          name="searchInput"
          #searchInput="matInput"
          [placeholder]="'Search' | translate"
          [(ngModel)]="search"
          (ngModelChange)="onChangeSearch()" />
      </mat-form-field>
      <button
        mat-icon-button
        type="button"
        (click)="toggleSearch()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <mat-progress-bar
      *ngIf="loading"
      class="loading"
      mode="indeterminate"></mat-progress-bar>
  </div>

  <div
    class="group-card-list df aps scrollable-horizontal"
    *ngIf="viewEntryGroups.length; else emptyState">
    <ng-container *ngFor="let group of viewEntryGroups; last as isLast">
      <div
        class="group-card new ams apbs apls oh mat-elevation-z2 p-minw-80 disable-focus"
        matRipple
        [routerLink]="['entry-group', group.id]">
        <div class="df ac aps">
          <mat-icon [matTooltip]="'Group' | translate"> groups</mat-icon>
          <div class="f1 amh truncate">
            <span [innerHTML]="group.name | highlight: search"> </span>
          </div>
          <button
            mat-icon-button
            type="button"
            [color]="selectedEntryGroups[group.id] ? 'primary' : ''"
            (click)="filterByEntryGroup($event, group.id)"
            [matTooltip]="'Filter entry cards by group' | translate">
            <mat-icon> filter_list</mat-icon>
          </button>
        </div>
        <div class="df ac aps">
          <mat-icon
            class="amr"
            [matTooltip]="'Description' | translate">
            description
          </mat-icon>
          <div class="truncate">{{ group.description }}</div>
        </div>
      </div>

      <div
        class="nasty-overflowhack aprs"
        *ngIf="isLast"></div>
    </ng-container>
  </div>
</div>

<ng-template #emptyState>
  <div class="df ac jc am ap">
    <T str="No groups to show"></T>
  </div>
</ng-template>
