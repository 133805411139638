import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { UsageExplorerComponent } from 'projects/apex/src/app/components/usage-explorer/usage-explorer.component';
import { TextUsageService } from 'projects/apex/src/app/services/text-usage/text-usage.service';
import { Observable, forkJoin, merge, of } from 'rxjs';
import { catchError, map, mergeMap, skipWhile } from 'rxjs/operators';
import { Text } from '../../models/text';
import { TextUsage } from '../../models/text-usage';

@Component({
  selector: 'apex-text-usage',
  templateUrl: './text-usage.component.html',
})
export class TextUsageComponent implements OnChanges {
  @Input() label: string = t('Texts');
  @Input() model: string;
  @Input() modelId: number;
  @Input() name: string;

  @Output() textUsagesChange = new EventEmitter<TextUsage[]>();

  @ViewChild('textUsagesList') textUsagesList: MatSelectionList;

  textUsages: TextUsage[] = [];

  constructor(
    private dialog: MatDialog,
    private service: TextUsageService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.model || changes.modelId || changes.name) && this.model && this.modelId && this.name) {
      this.service
        .all(this.model, this.modelId, this.name)
        .pipe(catchError(() => of([])))
        .subscribe({
          next: (textUsages: TextUsage[]) => {
            this.textUsages = textUsages;
            this.textUsagesChange.emit(this.textUsages);
          },
        });
    }
  }

  add(): void {
    this.dialog
      .open(UsageExplorerComponent, { data: { selectedTexts: this.textUsages.map((tu) => tu.Text) } })
      .afterClosed()
      .pipe(
        skipWhile((res) => !res),
        mergeMap((res: { texts: Text[] }) => {
          const tud = this.textUsages?.filter((tu) => !res.texts.find((text) => text.id === tu.TextId));

          return tud?.length
            ? forkJoin(tud.map((tu) => this.service.delete(tu))).pipe(
                map((tus: TextUsage[]) => {
                  tus.forEach((tu) => {
                    const idx = this.textUsages.map((text) => text.id).indexOf(tu.id);

                    if (idx !== -1) {
                      this.textUsages.splice(idx, 1);
                    }
                  });

                  return res;
                }),
              )
            : of(res);
        }),
        mergeMap((res: { texts: Text[] }) =>
          merge(
            ...res.texts
              .filter((text) => !this.textUsages.find((tu) => tu.TextId === text.id))
              .map((text) =>
                this.modelId ? this.service.save(this.newTextUsageFromText(text)) : of(this.newTextUsageFromText(text)),
              ),
          ),
        ),
      )
      .subscribe({
        next: (tu) => {
          this.textUsages.push(tu);
          this.textUsagesChange.emit(this.textUsages);
        },
      });
  }

  delete(): void {
    const textUsagesToDelete = this.textUsagesList?.selectedOptions?.selected?.map((s) => s.value);

    if (textUsagesToDelete?.length) {
      merge(...textUsagesToDelete.map((tu) => (tu.id ? this.service.delete(tu) : of(tu)))).subscribe({
        next: (textUsage) => {
          const deletedTextUsage = this.textUsages?.find((tu) => tu.TextId === textUsage.TextId);

          if (deletedTextUsage) {
            const idx = this.textUsages.indexOf(deletedTextUsage);

            if (idx !== -1) {
              this.textUsages.splice(idx, 1);
              this.textUsagesChange.emit(this.textUsages);
            }
          }
        },
      });
    }
  }

  saveAll(): Observable<TextUsage[]> {
    if (this.modelId) {
      return forkJoin(
        this.textUsages.map((tu) => {
          tu.modelId = this.modelId;

          return this.service.save(tu);
        }),
      );
    }

    return of([]);
  }

  newTextUsageFromText(text: Text): TextUsage {
    return new TextUsage({
      model: this.model,
      modelId: this.modelId,
      name: this.name,
      TextId: text.id,
      textName: text.name,
    });
  }
}
