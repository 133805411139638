/* eslint-disable @typescript-eslint/naming-convention */
import { ProjectApartmentTakeoverDataDto, ResponseAddressDto } from '../../../../../generated/apex-rest';
import { AddonApartment } from './addon-apartment';
import { AddonCart } from './addon-cart';
import { AddonOrder } from './addon-order';
import { FileUsage } from './file-usage';
import { Model } from './model';
import { Project } from './project';
import { Sale } from './sale';
import { ProjectTakeover } from './takeover';
import { User } from './user';

export class Apartment extends Model<Apartment> {
  ProjectId: number;
  Project?: Project;

  name: string | null;
  seksjonsnr?: string | null;
  bolignr?: string | null;
  fil1?: string | null;
  fil2?: string | null;
  new_date?: number;
  type_modell?: string | null;
  typeModell?: string | null;
  felt?: string | null;
  kommune?: string | null;
  gaardsnummer?: string | null;
  bruksnummer?: string | null;
  kvm_primaerrom?: number;
  kvm_brutto?: number;
  kvm_tomt?: number;
  ferdigdato?: number;
  overtakelsesdato?: number;
  kontrahert_overtakelsesdato?: number;
  kontrahert_varslingsdato?: number;
  etasje?: number;
  antall_soverom?: number;
  antall_bad?: number;
  balkong_kvm?: number;
  innglasset_balkong?: number;
  terrasse_kvm?: number;
  heis?: number;
  type_parkering?: string;
  antall_p_plasser?: number;
  kvm_bod?: number;
  kvm_sportsbod?: number;
  andel_fellesgjeld?: number;
  felleskostnader?: number;
  felleskostnader_drift?: number;
  felleskostnader_kapital?: number;
  kjoepsomkostninger?: number;
  husbankfinansiert_80?: number;
  totalpris?: number;
  antall_rom?: number;
  midlertidig_brukstillatelse?: string | null;
  ferdigattest?: string | null;
  fdv?: string | null;
  balkong?: number;
  kommentar?: string | null;
  boligtype?: string | null;
  salg?: number;
  finansbevis?: string | null;
  kontrakt?: string | null;
  felt_nr?: string | null;
  apartment_image?: string | null;
  kvm_primaerrom_m_disp?: number;
  antall_terasser?: number;
  antall_boder?: number;
  antall_sportsboder?: number;
  antall_innglasset_balkonger?: number;
  antall_etager?: number;
  pris?: number;
  kjopsomkostninger?: number;
  kvm_terrasse_balkong?: string | null;
  in_sales_reporting?: number;
  finansbevis_checkbox?: number;
  kontrakt_checkbox?: number;
  ferdigbefaring_dato?: number;
  parkerngsplassnr?: string | null;
  bodnr?: string | null;

  usableArea?: number | null;
  usableAreaInternal?: number | null;
  usableAreaExternal?: number | null;
  usableAreaBalcony?: number | null;
  usageAreaLowCeiling?: number | null;
  usageAreaFloor?: number | null;
  usageAreaOpen?: number | null;

  energyClassification?: string | null;

  maaler_fjernvarme?: number;
  maaler_stroem?: number;
  maaler_varmtvann?: number;
  maaler_kaldt_vann?: number;
  meterGasValue?: number;
  maaler_fjernvarme_nummer?: string | null;
  maaler_stroem_nummer?: string | null;
  maaler_varmtvann_nummer?: string | null;
  maaler_kaldt_vann_nummer?: string | null;
  meterGasNumber?: string | null;
  ignoreMeterPower?: boolean;
  ignoreMeterColdWater?: boolean;
  ignoreMeterHotWater?: boolean;
  ignoreMeterDistrictHeating?: boolean;
  ignoreMeterGas?: boolean;

  endringsavtale?: string | null;
  handed_over?: number;
  reserved?: number;
  agreement_type?: number;
  apartment_type_id?: number;
  floorplan?: string | null;
  powerMeasuringPointId?: string | null;
  disableRequests?: boolean;

  bannerImage?: string;

  floorplans?: FileUsage[];
  AddonCart?: AddonCart;
  AddonApartment?: AddonApartment;
  AddonApartments?: AddonApartment[];
  AddonOrders?: AddonOrder[];
  Clients?: User[];
  Takeovers?: ProjectTakeover[];
  ProjectApartmentTakeovers?: {
    data?: ProjectApartmentTakeoverDataDto;
  }[];
  Contracts?: FileUsage[];
  FinancialCertificates?: FileUsage[];
  TakeoverProtocols?: FileUsage[];
  InspectionProtocols?: FileUsage[];
  Sales?: Sale[];

  Addresses?: ResponseAddressDto[];

  checked?: boolean;
}
