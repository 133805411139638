import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from '../../services/http/http.service';
import { RepeatableCase } from './repeatable-case.model';

@Injectable()
export class RepeatableCaseService extends HttpService<RepeatableCase> {
  route = 'repeatable-case';

  getRepeatableCases(params: Params): Observable<RepeatableCase[]> {
    const o = this.options;

    o.params = {
      ...this.options.params,
      ...params,
    };

    return this.http.get<RepeatableCase[]>(`${this.apiUrl}/${this.route}`, o);
  }

  addRepeatableCaseContractor(rcId: number, ContractorId: number): Observable<RepeatableCase> {
    const o = this.options;

    o.params = {
      ...this.options.params,
      ContractorId: String(ContractorId),
    };

    return this.http.post<RepeatableCase>(`${this.apiUrl}/${this.route}/${rcId}/addContractor`, {}, o);
  }

  removeRepeatableCaseContractor(rcId: number, ContractorId: number): Observable<RepeatableCase> {
    const o = this.options;

    o.params = {
      ...this.options.params,
      ContractorId: String(ContractorId),
    };

    return this.http.post<RepeatableCase>(`${this.apiUrl}/${this.route}/${rcId}/removeContractor`, {}, o);
  }
}
