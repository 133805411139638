import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Rectangle, isRectInRect } from 'projects/apex/src/app/utils/functions';
import { Subscription, interval } from 'rxjs';
import { DragItem } from './drag-item';
import { DragService } from './drag.service';

const moveElement = (item: DragItem, x: number, y: number): void => {
  const xdif = x - item.currentX;
  const nextStepX = xdif * 0.2 + Math.sign(xdif) * 4;

  if (item.readyX || Math.abs(xdif + nextStepX) < 8) {
    item.currentX = x;
    item.readyX = true;
  } else {
    item.currentX = item.currentX + nextStepX;
  }

  const ydif = item.currentY - y;
  const nextStepY = ydif * 0.2 + Math.sign(ydif) * 4;

  if (item.readyY || Math.abs(ydif - nextStepY) < 8) {
    item.currentY = y;
    item.readyY = true;
  } else {
    item.currentY = item.currentY - nextStepY;
  }
};

@Directive({
  selector: '[apexDrag]',
})
export class DragDirective implements AfterViewInit, OnDestroy {
  @Input()
  get selected(): boolean {
    return this.selectedValue;
  }

  set selected(selected: boolean) {
    this.selectedValue = selected;

    if (this.item && !this.apexDragDisabled) {
      this.item.selected = this.selected;
      this.item.readyX = false;
      this.item.readyY = false;

      const rect = this.el?.nativeElement?.getBoundingClientRect();

      if (rect) {
        this.item.currentX = rect.left;
        this.item.currentY = rect.top;
      }
    }

    this.selectedChange.emit(this.selected);
  }

  @Input() model: string;
  @Input() modelId: number | string;

  @Input() name: string;
  @Input() dragIcon: string;
  @Input() dragIconClass: string;

  @Input() apexDragDisabled: boolean;

  @Output() apexDragStart = new EventEmitter();
  @Output() apexDragEnd = new EventEmitter();
  @Output() selectedChange = new EventEmitter<boolean>();

  item: DragItem;

  offsetX = 0;
  offsetY = 0;

  interval: Subscription;

  msx: number;
  msy: number;

  selectedValue: boolean;

  sub: Subscription = new Subscription();

  constructor(
    private el: ElementRef,
    private ds: DragService,
  ) {}

  @HostListener('dragstart', ['$event'])
  dragstart(e: DragEvent): void {
    e.preventDefault();
  }

  @HostListener('panstart', ['$event'])
  panStart(e: { center: { x: number; y: number }; deltaX: number; deltaY: number }): void {
    if (this.ds.isWeb && !this.apexDragDisabled) {
      this.msx = e.center.x - e.deltaX;
      this.msy = e.center.y - e.deltaY;
      this.ds.startDragPosX = e.center.x - e.deltaX;
      this.ds.startDragPosY = e.center.y - e.deltaY;

      this.selected = true;
      this.ds.dragging = true;
      this.ds.dragItem = this.item;
      this.ds.dragStart.next(null);
      this.interval = interval(20).subscribe(() => {
        this.ds.dragItems
          ?.filter((f) => f.selected)
          .forEach((i) => {
            moveElement(i, this.offsetX - 50, this.offsetY - 21);
          });
      });
    }
  }

  @HostListener('panmove', ['$event'])
  panMove(e: { center: { x: number; y: number }; deltaX: number; deltaY: number }): void {
    if (this.ds.isWeb && !this.apexDragDisabled) {
      this.ds.dragPosX = this.ds.startDragPosX + e.deltaX;
      this.ds.dragPosY = this.ds.startDragPosY + e.deltaY;
      this.offsetX = this.msx + e.deltaX;
      this.offsetY = this.msy + e.deltaY;
    }
  }

  @HostListener('panend', ['$event'])
  panEnd(): void {
    if (this.ds.isWeb && !this.apexDragDisabled) {
      setTimeout(() => {
        this.interval.unsubscribe();
        this.ds.dragItem = null;
        this.ds.dragEnd.next(null);
        setTimeout(() => {
          this.ds.dragging = false;
          this.item.hasTarget = false;
        }, 250);
      });
    }
  }

  ngAfterViewInit(): void {
    this.sub.add(
      this.ds.dragStart.subscribe({
        next: () => {
          if (this.selected && !this.apexDragDisabled) {
            this.apexDragStart.emit();
            this.el?.nativeElement?.classList?.add('apex-drag-placeholder');
          }
        },
      }),
    );
    this.sub.add(
      this.ds.dragEnd.subscribe({
        next: () => {
          this.apexDragEnd.emit();
          this.el?.nativeElement?.classList?.remove('apex-drag-placeholder');

          if (!this.selected) {
            this.item.selected = false;
          }

          this.item.readyX = false;
          this.item.readyY = false;

          if (!this.item.hasTarget) {
            const rect = this.el?.nativeElement?.getBoundingClientRect();

            if (rect) {
              this.item.currentX = rect.left;
              this.item.currentY = rect.top;
            }
          }
        },
      }),
    );
    this.sub.add(
      this.ds.selectorChange.subscribe({
        next: (rect: Rectangle) => {
          if (!this.ds.dragging) {
            if (rect) {
              this.selected = isRectInRect(this.el.nativeElement.getBoundingClientRect(), rect);
            } else {
              this.selected = false;
            }
          }
        },
      }),
    );

    setTimeout(() => {
      let item = this.ds.dragItems.find((d) => !d.deleted && d.model === this.model && d.modelId === this.modelId);

      if (!item) {
        const width = this.el.nativeElement.offsetWidth;
        const height = this.el.nativeElement.offsetHeight;
        const rect = this.el.nativeElement.getBoundingClientRect();
        const currentX = rect.left;
        const currentY = rect.top;

        item = {
          selected: this.selected,
          model: this.model,
          modelId: this.modelId,
          name: this.name,
          dragIcon: this.dragIcon,
          dragIconClass: this.dragIconClass,
          currentX,
          currentY,
          width,
          height,
          readyX: false,
          readyY: false,
        };
        this.ds.dragItems.push(item);
      }

      this.item = item;
    });
  }

  ngOnDestroy(): void {
    const item = this.ds.dragItems.find((d) => d.model === this.model && d.modelId === this.modelId);

    if (item) {
      item.deleted = true;
      setTimeout(() => {
        const idx = this.ds.dragItems.indexOf(item);

        if (idx !== -1) {
          this.ds.dragItems.splice(idx, 1);
        }
      });
    }

    this.sub?.unsubscribe();
  }
}
