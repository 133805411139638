import { Injectable } from '@angular/core';
import { APIService } from 'projects/apex/src/app/services/http/http.service';
import { firstValueFrom, Observable } from 'rxjs';

import { Customer, CustomerAdmin, CustomerToken, Webhook } from 'projects/apex/src/app/models/customer';
import { DeleteResponse } from 'projects/apex/src/app/utils/types';
import { map, take } from 'rxjs/operators';
import { createApiUrl } from '../../utils/functions';
import { parseEntityRegResponse } from './customer.functions';

@Injectable()
export class CustomerService extends APIService<Customer> {
  public route = 'customer';

  private unitRegistryUrl = 'https://data.brreg.no/enhetsregisteret/api/enheter';

  getAllAvailableCustomers(): Observable<Customer[]> {
    return this.http.get<Customer[]>(`${this.apiUrl}/${this.route}/external`, this.options());
  }

  getAdministrators(customerId: number): Observable<CustomerAdmin[]> {
    const url = this.administratorURL(customerId);

    return this.http.get<CustomerAdmin[]>(url, this.options());
  }

  postAdministrators(customerId: number, adminId: number): Observable<CustomerAdmin> {
    const url = this.administratorURL(customerId);

    return this.http.post<CustomerAdmin>(url, { UserId: adminId }, this.options());
  }

  deleteAdministrator(customerId: number, adminId: number): Observable<DeleteResponse> {
    const url = this.administratorURL(customerId, adminId);

    return this.http.delete<DeleteResponse>(url, this.options());
  }

  getTokens(customerId: number): Observable<CustomerToken[]> {
    const url = this.tokenURL(customerId);

    return this.http.get<CustomerToken[]>(url, this.options());
  }

  postTokens(customerId: number, customerToken: Partial<CustomerToken>): Observable<CustomerToken> {
    const url = this.tokenURL(customerId);

    return this.http.post<CustomerToken>(url, customerToken, this.options());
  }

  postToken(customerId: number, tokenId: string, customerToken: CustomerToken): Observable<CustomerToken> {
    const url = this.tokenURL(customerId, tokenId);

    return this.http.post<CustomerToken>(url, customerToken, this.options());
  }

  getWebhooks(customerId: number): Observable<Webhook[]> {
    const url = this.webhookURL(customerId);

    return this.http.get<Webhook[]>(url, this.options());
  }

  postWebhooks(customerId: number, webhook: Partial<Webhook>): Observable<Webhook> {
    const url = this.webhookURL(customerId);

    return this.http.post<Webhook>(url, webhook, this.options());
  }

  getWebhook(customerId: number, webhookId: string): Observable<Webhook> {
    const url = this.webhookURL(customerId, webhookId);

    return this.http.get<Webhook>(url, this.options());
  }

  postWebhook(customerId: number, webhookId: string, partialWebhook: Partial<Webhook>): Observable<Webhook> {
    const url = this.webhookURL(customerId, webhookId);

    return this.http.post<Webhook>(url, partialWebhook, this.options());
  }

  deleteWebhook(customerId: number, webhookId: string): Observable<DeleteResponse> {
    const url = this.webhookURL(customerId, webhookId);

    return this.http.delete<DeleteResponse>(url, this.options());
  }

  fetchFromUnitRegistry(orgnr: number): Observable<Customer> {
    const url = `${this.unitRegistryUrl}/${orgnr}`;
    const options = {
      withCredentials: false,
    };

    return this.http.get(url, options).pipe(take(1), map(parseEntityRegResponse));
  }

  async setProjectOrEntityTemplateFolder(
    customerId: number,
    folderId: string,
    which: 'project-template-folder' | 'project-entity-template-folder',
  ): Promise<boolean> {
    if (!customerId || !folderId) {
      return false;
    }

    const url = createApiUrl('customer', customerId, which);

    return firstValueFrom(this.http.post<boolean>(url, { folderId }, this.options()));
  }

  async unsetProjectOrEntityTemplateFolder(
    customerId: number,
    which: 'project-template-folder' | 'project-entity-template-folder',
  ): Promise<boolean> {
    if (!customerId) {
      return false;
    }

    const url = createApiUrl('customer', customerId, which);

    return firstValueFrom(this.http.delete<boolean>(url, this.options()));
  }
  private administratorURL(customerId: number, adminId?: number): string {
    let url = `${this.apiUrl}/customer/${customerId}/administrator`;

    if (adminId) {
      url += `/${adminId}`;
    }

    return url;
  }

  private tokenURL(customerId: number, tokenId?: string): string {
    let url = `${this.apiUrl}/customer/${customerId}/token`;

    if (tokenId) {
      url += `/${tokenId}`;
    }

    return url;
  }

  private webhookURL(customerId: number, webhookId?: string): string {
    let url = `${this.apiUrl}/customer/${customerId}/webhook`;

    if (webhookId) {
      url += `/${webhookId}`;
    }

    return url;
  }
}
