import { NgModule } from '@angular/core';

import { NameOrNonePipe, PropertyOrNonePipe, PropertyOrNotSetPipe, ValueOrNonePipe } from './name-or-none.pipe';

@NgModule({
  imports: [],
  exports: [NameOrNonePipe, PropertyOrNonePipe, ValueOrNonePipe, PropertyOrNotSetPipe],
  declarations: [NameOrNonePipe, PropertyOrNonePipe, ValueOrNonePipe, PropertyOrNotSetPipe],
  providers: [],
})
export class NameOrNoneModule {}
