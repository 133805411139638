import { OverlayRef } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { CardData } from './user-card.types';
import { UserWithCustomerOrCompany } from './user-with-customer-or-company.type';

@Component({
  selector: 'apex-user-card-overlay',
  templateUrl: './user-card-overlay.component.html',
})
export default class UserCardOverlayComponent implements OnInit {
  user: UserWithCustomerOrCompany;
  client = false;
  clientUrl: (string | number)[] | string | null | undefined;
  subtitle = '';
  subtitleIcon = '';

  constructor(
    public data: CardData,
    private overlayRef: OverlayRef,
  ) {}

  ngOnInit(): void {
    this.user = this.data.user;
    this.client = this.data.isClient;
    this.clientUrl = this.data.clientUrl;
    this.subtitle = this.data.subtitle;
    this.subtitleIcon = this.data.subtitleIcon;
  }

  close(): void {
    this.overlayRef?.detach();
  }

  makeClose(): () => void {
    return () => this.close();
  }
}
