<ng-container *ngIf="caseLog">
  <div class="df c ac amr left">
    <apex-avatar [user]="caseLog | caseLogUser"> </apex-avatar>

    <div class="df r jsb">
      <ng-container *ngIf="!disableToggleActions">
        <mat-icon
          class="pointer"
          svgIcon="contractor"
          [matTooltip]="forContractorMessage"
          [ngClass]="{ 'gray-color': !caseLog.forContractor, contractor: caseLog.forContractor }"
          (click)="toggleLog('contractor')">
        </mat-icon>
        <mat-icon
          class="pointer"
          svgIcon="client"
          [matTooltip]="forClientMessage"
          [ngClass]="{ 'gray-color': !caseLog.forClient, client: caseLog.forClient }"
          (click)="toggleLog('client')">
        </mat-icon>
      </ng-container>
      <ng-container *ngIf="disableToggleActions">
        <mat-icon
          svgIcon="contractor"
          [matTooltip]="forContractorDisabledMessage"
          [ngClass]="{ 'gray-color': !caseLog.forContractor, contractor: caseLog.forContractor }">
        </mat-icon>
        <mat-icon
          svgIcon="client"
          [matTooltip]="forClientDisabledMessage"
          [ngClass]="{ 'gray-color': !caseLog.forClient, client: caseLog.forClient }">
        </mat-icon>
      </ng-container>
    </div>
  </div>
  <div class="df c f1 right mat-elevation-z2 scrollable-horizontal">
    <div class="meta">
      <apex-subtitle-item class="aps">
        <span subtitle> {{ (caseLog | caseLogUserCompany)?.name }} </span>

        <span class="df jsb">
          <span
            *ngIf="showUserCard"
            class="amr"
            [apexUserCard]="caseLog | caseLogUser">
            {{ (caseLog | caseLogUser)?.name }}
          </span>

          <span
            *ngIf="!showUserCard"
            class="amr">
            {{ (caseLog | caseLogUser)?.name }}
          </span>

          <span
            class="mat-caption"
            [matTooltip]="caseLog.createdAt | date: 'short'">
            {{ caseLog.createdAt | date: 'short' }}
          </span>
        </span>
      </apex-subtitle-item>

      <ng-container *ngIf="caseLog | caseLogEmail">
        <T
          class="db aps font-size-mat-caption"
          str="Message created from email">
        </T>
      </ng-container>
    </div>

    <div class="aps scrollable-horizontal">
      <div class="no-wrap markdown">
        <apex-translator>
          <div [innerHTML]="caseLog | formatCaseLogMessage | marked"></div>
        </apex-translator>

        <ng-container
          *ngIf="
            caseLog?.translateVars?.fileUsageId &&
            caseLog.translateVars?.type === 'create' &&
            !disabledMessageThumbnails
          ">
          <apex-file-preview-loader
            #afpl
            class="mat-elevation-z2"
            self="case"
            [selfId]="caseLog.CaseId"
            [matTooltip]="'View file' | translate"
            [matTooltipDisabled]="!afpl.fileUsage"
            matRipple
            [matRippleDisabled]="!afpl.fileUsage"
            [apexFileUsageViewer]="afpl.fileUsage"
            [fileUsageId]="fileUsageId">
          </apex-file-preview-loader>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
