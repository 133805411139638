<div class="df c">
  <div class="df ac amv amh">
    <mat-icon fontSet="material-icons-sharp">home_work</mat-icon>
    <div class="aml">
      <ng-container *ngIf="topLevelObjId; else onlyName">
        <a [routerLink]="['/object', 'project', topLevelObjId, 'tenancy', tenancy?.id]">
          {{ tenancy?.name }}
        </a>
      </ng-container>
      <ng-template #onlyName>
        {{ tenancy?.name }}
      </ng-template>
    </div>
  </div>

  <div class="df ac amb amh">
    <mat-icon [matTooltip]="'Rooms' | translate"> meeting_room </mat-icon>
    <mat-chip-listbox
      *ngIf="tenancy?.Objects && tenancy?.Objects.length"
      class="aml">
      <mat-chip-option *ngFor="let Object of tenancy?.Objects">{{ Object.name }}</mat-chip-option>
    </mat-chip-listbox>
    <p
      *ngIf="!tenancy?.Objects || !tenancy?.Objects.length"
      class="aml">
      <T str="No rooms"></T>
    </p>
  </div>

  <div
    *ngIf="tenancy?.TenantCustomerId"
    class="df ac amb amh">
    <mat-icon
      fontSet="material-icons-sharp"
      [matTooltip]="'Tenant' | translate">
      business
    </mat-icon>
    <div class="aml">
      {{ tenancy?.TenantCustomer?.name }}
    </div>
  </div>

  <div class="df ac amb amh">
    <mat-icon
      fontSet="material-icons-sharp"
      [matTooltip]="'Period' | translate">
      date_range
    </mat-icon>
    <div class="aml">{{ tenancy?.from | date: 'shortDate' }} - {{ tenancy?.to | date: 'shortDate' }}</div>
  </div>

  <div class="df ac amb amh">
    <mat-icon [matTooltip]="'Information' | translate"> menu </mat-icon>
    <div [innerHTML]="tenancy?.informationExternal"></div>
  </div>

  <div class="df ac jfe amb amh">
    {{ payoffLine }}
  </div>
</div>
