import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { Rectangle } from 'projects/apex/src/app/utils/functions';
import { Subject } from 'rxjs';
import { DragItem } from './drag-item';

@Injectable()
export class DragService {
  dragging: boolean;
  dragItems: DragItem[] = [];
  dragItem: DragItem;

  startDragPosX: number;
  startDragPosY: number;
  dragPosX: number;
  dragPosY: number;

  dragStart = new Subject();
  dragEnd = new Subject();

  selectorChange = new Subject<Rectangle>();

  isWeb: boolean;

  constructor(
    private platform: Platform,
    private bpObserver: BreakpointObserver,
  ) {
    this.isWeb =
      this.bpObserver.isMatched([Breakpoints.Web, Breakpoints.WebLandscape, Breakpoints.WebPortrait]) &&
      !this.platform.ANDROID &&
      !this.platform.IOS;
  }
}
