import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { BookmarkModule } from '../../features/bookmark/bookmark.module';
import { NotificationButtonComponent } from '../../features/notification/button/notification-button.component';
import { RequestModule } from '../../features/request/request.module';
import { QrCodeModule } from '../qr-code/qr-code.module';
import { TranslateModule } from '../translate/translate.module';
import UtilsButtonModule from '../utils-button/utils-button.module';
import { TopbarComponent } from './topbar.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    BookmarkModule,
    NotificationButtonComponent,
    QrCodeModule,
    RequestModule,
    TranslateModule,
    UtilsButtonModule,
    MatSlideToggleModule,
    FormsModule,
  ],
  exports: [TopbarComponent],
  declarations: [TopbarComponent],
  providers: [],
})
export class TopbarModule {}
