import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostBinding, Injector, OnDestroy, OnInit } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'projects/apex/src/environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgendaButtonComponent } from './components/agenda-button/agenda-button.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { AvatarsComponent } from './components/avatar/avatars.component';
import { FileUsageComponent } from './components/file-usage/file-usage.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { t } from './components/translate/translate.function';
import { TranslationService } from './components/translate/translation.service';
import UtilsButtonComponent from './components/utils-button/utils-button.component';
import { BookmarkButtonComponent } from './features/bookmark/button.component';
import { ChecklistGroupsComponent } from './features/checklist-group/groups/groups.component';
import { InspectionBoxComponent } from './features/inspection/box/inspection-box.component';
import { ApartmentDeleteSaleButtonComponent } from './features/project/apartment-actions/delete-sale-button.component';
import { ApartmentDeleteTakeoverButtonComponent } from './features/project/apartment-actions/delete-takeover-button.component';
import { RequestsButtonComponent } from './features/request/requests-button.component';
import { snack, snackErr } from './modules/snack.module';
import { NotificationService } from './services/notification/notification.service';
import { PusherService } from './services/notification/pusher.service';
import { hostMatchesApp, locale } from './utils/functions';

export const hideApexRoot: Subject<boolean> = new Subject<boolean>();

class MissingTranslationPolicy {
  handle(str: string, _locale: string): string {
    if (!str?.length) {
      return '';
    }

    // @todo Create a system for logging MissingTranslations in Production

    return str;
  }
}

@Component({
  selector: 'apex-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class.hide') hideApexRoot = true;

  title = 'APEX';
  isApp = false;

  mode$: Observable<'side' | 'over'>;
  private subscription = new Subscription();

  constructor(
    injector: Injector,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translationService: TranslationService,
    private breakpointObserver: BreakpointObserver,
    private notificationService: NotificationService,
    private pusherService: PusherService,
  ) {
    this.mode$ = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(
      map((res) => {
        const xsmall = res.breakpoints[Breakpoints.XSmall];

        return xsmall ? 'over' : 'side';
      }),
    );

    void this.translationService.init({
      token: environment.transifexToken,
      missingPolicy: new MissingTranslationPolicy(),
    });

    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.assetsUrl}/icons.svg`),
    );

    hideApexRoot.subscribe({
      next: (b) => {
        this.hideApexRoot = b;
      },
    });

    const body = document.getElementsByTagName('body')[0];

    if (body) {
      body.classList.add('apex-angular');
    }

    const isStaging = environment.staging;
    const apexRoot = document.getElementsByTagName('apex-root')[0];

    if (apexRoot && isStaging) {
      apexRoot.classList.add('apex-staging');
    }

    if (hostMatchesApp) {
      hideApexRoot.next(false); // Do not hide APEX while we are in APP
      this.isApp = true;

      if (body) {
        body.classList.add('apex-app');
      }
    }

    /* customElements */
    [
      {
        n: 'angular-qr-code',
        c: QrCodeComponent,
      },
      {
        n: 'angular-agenda-button',
        c: AgendaButtonComponent,
      },
      {
        n: 'angular-bookmark-button',
        c: BookmarkButtonComponent,
      },
      {
        n: 'angular-requests-button',
        c: RequestsButtonComponent,
      },
      {
        n: 'angular-avatars',
        c: AvatarsComponent,
      },
      {
        n: 'angular-avatar',
        c: AvatarComponent,
      },
      {
        n: 'angular-utils-button',
        c: UtilsButtonComponent,
      },
      // Next ones should be removed.
      {
        n: 'angular-checklist-groups',
        c: ChecklistGroupsComponent,
      },
      {
        n: 'angular-apartment-delete-takeover-button',
        c: ApartmentDeleteTakeoverButtonComponent,
      },
      {
        n: 'angular-apartment-delete-sale-button',
        c: ApartmentDeleteSaleButtonComponent,
      },
      {
        n: 'angular-inspection-box',
        c: InspectionBoxComponent,
      },
      {
        n: 'angular-file-usage',
        c: FileUsageComponent,
      },
    ].forEach((v) => {
      const f = customElements.get(v.n);

      if (!f) {
        const e = createCustomElement(v.c, { injector });

        customElements.define(v.n, e);
      }
    });

    const notification$$ = this.notificationService.notification$.subscribe((notification) =>
      snack(notification.data.payload.notification.body),
    );

    this.subscription.add(notification$$);
    this.pusherService.init();
  }

  async ngOnInit(): Promise<void> {
    try {
      const l = locale();

      await this.translationService.setCurrentLocale(l);
    } catch (err) {
      snackErr(t('Failed to get translations for {locale}', { locale: locale() }), err);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
