<apex-page-info [title]="title"></apex-page-info>

<mat-tab-group
  #tabs
  [selectedIndex]="selectedIndex">
  <mat-tab [label]="'Info' | translate">
    <form
      #checklistTemplateForm="ngForm"
      class="meta-container df am apts apbs apl w mat-elevation-z2">
      <div class="df p-c ambs d-f1 p-w-100 t-w-100">
        <div class="df c jsb f1 amr">
          <div class="df c f1 p">
            <mat-form-field class="w-100">
              <input
                matInput
                type="text"
                name="name"
                required
                apexAutoFocus
                [disabled]="!isAdmin"
                [(ngModel)]="checklistTemplate.name"
                [placeholder]="'Title' | translate" />
            </mat-form-field>
            <apex-autocomplete
              name="category"
              [disabled]="!isAdmin"
              type="CaseCategory"
              [(ngModel)]="checklistTemplate.CategoryId"
              (modelChange)="updateSelectedCategory()"
              [label]="'Category' | translate">
            </apex-autocomplete>
          </div>
          <mat-checkbox
            name="customerAccess"
            [disabled]="!isAdmin"
            [(ngModel)]="checklistTemplate.customerAccess"
            [matTooltip]="'Grant read access to everyone in the company' | translate">
            <T str="Company access"></T>
          </mat-checkbox>
        </div>

        <apex-model-role
          class="f1 amts amr"
          #modelRole
          *ngIf="checklistTemplate.id"
          [disabled]="!isAdmin"
          model="checklist-template"
          [modelId]="checklistTemplate.id"
          role="collaborator"
          [label]="'Collaborator' | translate"
          [title]="'Collaborators' | translate">
        </apex-model-role>
      </div>

      <div class="df f2 w p-w-100">
        <div class="df f1 amr">
          <apex-box
            expanded
            class="f1 amvs no-padding">
            <T
              title
              str="Description"></T>

            <editor
              [init]="{ placeholder: 'Description' | translate } | editorInit"
              name="description"
              [(ngModel)]="checklistTemplate.description"
              [disabled]="isAdmin()">
            </editor>
          </apex-box>
        </div>

        <apex-file-usage
          #fileUsage
          class="df fdot5 p-f1 amr ambs amts p-w-100"
          [disabled]="!isAdmin"
          view="List"
          [title]="'Files' | translate"
          self="checklist-template"
          [selfId]="checklistTemplate.id || 0"
          name="files">
        </apex-file-usage>
      </div>
    </form>
  </mat-tab>
  <mat-tab
    [label]="'List' | translate"
    [disabled]="!checklistTemplate.id">
    <apex-checklist-template-section-view
      [checklistTemplate]="checklistTemplate"
      [checklistTemplateItems]="checklistTemplateItems"
      [checklistTemplateSections]="checklistTemplateSections">
    </apex-checklist-template-section-view>
  </mat-tab>
</mat-tab-group>

<apex-fab-menu
  *ngIf="checklistTemplate?.id"
  [buttons]="buttons"
  (click)="tabs.selectedIndex = 1">
</apex-fab-menu>

<section class="df jsb ac amh amb">
  <button
    *ngIf="checklistTemplate.id"
    [disabled]="!isAdmin"
    mat-button
    type="button"
    color="warn"
    (click)="delete()">
    <T str="Delete"></T>
  </button>
  <button
    mat-button
    apexHistoryBack
    type="button">
    <T str="Back"></T>
  </button>
</section>
