<div
  *ngIf="fileUsage"
  class="df r"
  class="wrap"
  (click)="openFileUsageViewer()"
  mat-ripple>
  <div
    #imageWrap
    *ngIf="!loading"
    class="image-wrap df afs"
    [style.transform]="
      transform('translateY(calc(' + (embeddedMarkings[0] ? -embeddedMarkings[0].offsetY : -50) + '% + 75px))')
    ">
    <img
      *ngIf="!error"
      class="load-image-smooth"
      [src]="img.src" />
    <ng-container *ngFor="let marking of embeddedMarkings">
      <div
        *ngIf="marking && marking.offsetX && marking.offsetY"
        class="marking {{ marking.model }} {{ marking.Case ? getUserRelationForCase(profile, marking.Case) : null }}"
        [ngClass]="{
          completed: !!marking?.Case?.completed,
          'has-color': validColor(marking?.Case?.Field?.color || marking?.Case?.ObjectField?.color),
        }"
        [ngStyle]="{
          left: 'calc(' + marking.offsetX + '% - 10px)',
          top: 'calc(' + marking.offsetY + '% - 10px)',
        }"
        [style.--field-color]="marking?.Case?.Field?.color || marking?.Case?.ObjectField?.color"></div>
    </ng-container>
  </div>
  <div
    *ngIf="error"
    class="placeholder-image load-image-smooth w-100"></div>
  <mat-progress-bar
    *ngIf="loading"
    mode="indeterminate"></mat-progress-bar>
</div>
