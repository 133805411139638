<div mat-dialog-title>
  <div class="df f1 jsb ac">
    <h2>
      {{ data.title }}
    </h2>
    <button
      mat-icon-button
      (click)="dialogRef.close()"
      type="button">
      <mat-icon> clear</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="display-flex column">
  <apex-autocomplete
    class="f1"
    [type]="data.template"
    name="TemplateId"
    [label]="templateLabel"
    [(ngModel)]="checklistTemplateId"
    (ngModelChange)="setTemplateName($event)"
    [disabled]="disableTemplateIdSelect"
    required>
  </apex-autocomplete>

  <mat-form-field class="w-100">
    <input
      matInput
      type="text"
      name="name"
      [placeholder]="'Name' | translate"
      [(ngModel)]="name" />
  </mat-form-field>

  <ng-container *ngIf="!disableProjectAutocomplete">
    <apex-object-selector
      name="objectId"
      [multiple]="false"
      [(ngModel)]="objectId"
      [disabled]="!!projectId || disableObjectAutocomplete"
      [hint]="'Both Project and Object can not be selected at the same time' | translate"
      [showHint]="!!projectId"
      [ngClass]="!!projectId ? 'hint-visible' : ''"></apex-object-selector>

    <div *ngIf="objectId && !projectId">
      <apex-autocomplete
        name="objectField"
        type="ObjectField"
        [label]="'Discipline' | translate"
        [(ngModel)]="objectFieldId"
        [params]="{ ObjectId: objectId }">
      </apex-autocomplete>
    </div>
  </ng-container>

  <ng-container *ngIf="!disableObjectAutocomplete">
    <apex-autocomplete
      name="projectId"
      type="Project"
      [label]="'Project' | translate"
      [(ngModel)]="projectId"
      [disabled]="!!objectId || disableProjectAutocomplete || disableApartmentAutocomplete"
      [hint]="'Both Project and Object can not be selected at the same time' | translate"
      [showHint]="!!objectId"
      [ngClass]="!!objectId ? 'hint-visible' : ''">
    </apex-autocomplete>

    <div *ngIf="projectId && !objectId">
      <apex-autocomplete
        name="apartmentId"
        type="Apartment"
        [label]="'Unit' | translate"
        [(ngModel)]="apartmentId"
        [disabled]="disableApartmentAutocomplete"
        [params]="{ ProjectId: projectId }">
      </apex-autocomplete>

      <apex-autocomplete
        name="projectField"
        type="Field"
        [label]="'Discipline' | translate"
        [(ngModel)]="fieldId"
        [params]="{ ProjectId: projectId }">
      </apex-autocomplete>
    </div>
  </ng-container>

  <div *ngIf="projectId || objectId">
    <apex-autocomplete
      name="caseManagerId"
      type="User"
      [label]="'Case manager' | translate"
      [(ngModel)]="caseManagerId"
      [params]="{
        subscribed: true,
        ObjectId: objectId,
        ProjectId: projectId,
      }">
    </apex-autocomplete>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="display-flex f1 justify-flex-end align-center">
    <button
      mat-raised-button
      type="button"
      (click)="dialogRef.close()">
      <T str="Cancel"></T>
    </button>
    <button
      mat-raised-button
      type="button"
      color="primary"
      (click)="save()"
      [disabled]="!checklistTemplateId">
      <T str="Save"></T>
    </button>
  </div>
</mat-dialog-actions>
