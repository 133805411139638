import { OverlayRef } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { nameCompare } from '../../../../utils/functions';

import { CommercialType, MetaType, Obj } from '../../object.model';
import { ObjectService, QueryParams } from '../../object.service';
import { AgreementService } from '../../project/agreement/agreement.service';
import { TenancyService } from '../../project/tenancy/tenancy.service';
import { SafetyType } from '../../safety/safety.model';

export class ObjectSelectorConfig {
  multiple: boolean;
  meta: MetaType;
  type: SafetyType | CommercialType;
  current: number | number[];

  queryParams: QueryParams;
  filterFunc?: (o: Obj) => boolean;

  tenancyId: number;
  agreementId: number;
}

@Component({
  selector: 'apex-object-selector-dialog',
  templateUrl: './dialog.component.html',
})
export class ObjectSelectorDialogComponent implements OnInit, OnDestroy {
  @Output() closed = new EventEmitter<number[]>();

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  loading = true;
  objects: Obj[] = [];
  filteredObjects: Obj[] = [];
  selectedObjects = {};
  selectedParent: Obj = null;

  filterSearch = '';
  filterSelected = false;

  private keySubscription: Subscription;
  private mouseSubscription: Subscription;

  constructor(
    private overlayRef: OverlayRef,
    private config: ObjectSelectorConfig,
    private service: ObjectService,
    private zone: NgZone,
    private tenancyService: TenancyService,
    private agreementService: AgreementService,
  ) {}

  @HostListener('click', ['$event']) hostClick($event: Event): void {
    $event.stopPropagation();
  }

  ngOnInit(): void {
    let query = this.service.query<Obj>(this.config.meta, this.config.type, this.config.queryParams);

    if (this.config.tenancyId) {
      query = this.tenancyService.tree(this.config.tenancyId).pipe(map((res) => res.Collection));
    }

    if (this.config.agreementId && !this.config.tenancyId) {
      query = this.agreementService.tree(this.config.agreementId);
    }

    query.pipe(take(1)).subscribe({
      next: (objects) => {
        this.objects = objects;
        this.loading = false;

        this.mouseSubscription = fromEvent(window, 'click').subscribe({
          next: () => {
            this.close();
          },
        });

        this.searchInput?.nativeElement.focus();

        this.initialSelected();

        return this.filter();
      },
      error: () => {
        this.loading = false;
      },
    });

    this.keySubscription = fromEvent(window, 'keyup').subscribe({
      next: (e: KeyboardEvent) => {
        if (e.key === 'Escape' || e.key === 'Esc') {
          this.close();
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.keySubscription?.unsubscribe();
    this.mouseSubscription?.unsubscribe();
  }

  filter(): void {
    this.zone.runOutsideAngular(() => {
      this.filteredObjects = this.objects
        .filter((o) => {
          if (this.filterSelected) {
            return this.selectedObjects[o.id] === true;
          }

          if (this.filterSearch) {
            return true;
          } else {
            return this.selectedParent
              ? o.ParentId === this.selectedParent.id
              : !this.objects.find((p) => o.ParentId === p.id);
          }
        })
        .filter((o) => {
          if (this.filterSearch) {
            return o.name.toLowerCase().indexOf(this.filterSearch.toLowerCase()) >= 0;
          }

          return true;
        })
        .filter((o) => {
          if (this.config.filterFunc) {
            return this.config.filterFunc(o);
          }

          return true;
        })
        .sort(nameCompare);
    });
  }

  canMove(obj: Obj): boolean {
    return this.objects.filter((o) => o.ParentId === obj.id).length > 0;
  }

  setParent(o: Obj): void {
    this.filterSearch = '';
    this.selectedParent = o;

    return this.filter();
  }

  back(id: number): void {
    this.setParent(this.objects.find((o) => o.id === id));
  }

  selected(): number[] {
    return Object.keys(this.selectedObjects)
      .filter((k) => this.selectedObjects[k])
      .map((k) => parseInt(k, 10));
  }

  selectedCount(): number {
    return this.selected().length;
  }

  select(): void {
    this.close(this.selected());
  }

  initialSelected(): void {
    this.selectedObjects = {};

    if (this.config.current instanceof Array) {
      this.config.current.forEach((k) => {
        this.selectedObjects[k] = true;
      });

      if (this.config.current.length) {
        this.filterSelected = true;
      }
    } else {
      if (this.config.current) {
        this.selectedObjects[this.config.current] = true;

        const current = this.objects.find((o) => o.id === this.config.current);

        if (current?.ParentId) {
          const parent = this.objects.find((o) => o.id === current.ParentId);

          if (parent) {
            this.selectedParent = parent;
          }
        }
      }
    }
  }

  toggleSelected(obj: Obj): void {
    if (this.config.type) {
      if (obj.type !== this.config.type) {
        return;
      }
    }

    this.selectedObjects[obj.id] = !this.selectedObjects[obj.id];

    if (!this.config.multiple) {
      if (this.selectedObjects[obj.id]) {
        return this.close([obj.id]);
      }

      Object.keys(this.selectedObjects)
        .filter((k) => k !== `${obj.id}`)
        .forEach((k) => {
          this.selectedObjects[k] = false;
        });
    }

    this.filter();
  }

  selectAll(): void {
    if (Object.values(this.selectedObjects).some((k) => k)) {
      this.selectedObjects = {};
      this.filter();

      return;
    }

    this.filteredObjects.forEach((o) => {
      this.selectedObjects[o.id] = true;
    });
  }

  close(ids?: number[]): void {
    const ret = this.config.current instanceof Array ? this.config.current : [this.config.current];

    this.closed.emit(ids ? ids : ret);
    this.overlayRef.detach();
  }

  getPath(parentId: number, path: string[] = []): string {
    const parent = this.objects.find((o) => o.id === parentId);

    if (parent) {
      path = [parent?.name, ...path];

      if (parent.ParentId) {
        return this.getPath(parent.ParentId, path);
      }
    }

    return path.join(' / ');
  }
}
