import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '../../../components/translate/translate.module';
import { CompanyConsentReminderDialogComponent } from './company-consent-reminder-dialog.component';

@NgModule({
  declarations: [CompanyConsentReminderDialogComponent],
  imports: [MatDialogModule, TranslateModule, MatButtonModule, CommonModule, RouterModule, MatRippleModule],
  providers: [],
  exports: [],
})
export class CompanyConsentReminderDialogModule {}
