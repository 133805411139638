<apex-box
  class="no-padding"
  icon="home_work">
  <ng-container title>
    {{ tenancy?.name }}
  </ng-container>

  <apex-tenancy-info
    [tenancy]="tenancy"
    [topLevelObjId]="topLevelObjectId">
  </apex-tenancy-info>
</apex-box>
