<apex-page-info [title]="title"></apex-page-info>

<div class="df w afs">
  <div class="f1 am mat-elevation-z2 mw-300 p-mw-100">
    <h2 class="mat-h2 rm aps">
      <T str="Folders"></T>
    </h2>

    <mat-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodeToggle>
        <li
          class="mat-tree-node df clickable"
          (click)="setFolder(node.id)"
          [class.selected]="node.id === folderId">
          <!-- use a disabled button to provide padding for tree leaf -->
          <button
            mat-icon-button
            disabled></button>
          <mat-icon class="amrs">folder</mat-icon>
          {{ node.name }}
        </li>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChildren">
        <li>
          <div
            class="mat-tree-node new clickable"
            (click)="setFolder(node.id)"
            [class.selected]="node.id === folderId">
            <button
              mat-icon-button
              matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
              </mat-icon>
            </button>
            <mat-icon class="amrs">folder</mat-icon>
            {{ node.name }}
          </div>
          <ul [class.invisible2]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
  <div class="f2 am ap mat-elevation-z2">
    <div
      class="df"
      *ngFor="let folder of folders">
      <form
        #juicyForm="ngForm"
        class="df f1">
        <button
          mat-icon-button
          [color]="'primary'"
          (click)="setFolder(folder.id)"
          [matTooltip]="'Go to folder' | translate">
          <mat-icon>folder</mat-icon>
        </button>
        <mat-form-field class="f1 amb">
          <mat-label>
            <T str="Name"></T>
          </mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="folder.name"
            name="name" />
        </mat-form-field>
        <button
          mat-icon-button
          [disabled]="!juicyForm.dirty"
          (click)="save(folder, juicyForm)">
          <mat-icon>check</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          (click)="delete(folder)">
          <mat-icon>delete</mat-icon>
        </button>
      </form>
    </div>
    <div
      class="df ac"
      *ngFor="let bookmark of bookmarks">
      <form
        #juicyForm="ngForm"
        class="df f1">
        <a
          mat-icon-button
          [color]="'primary'"
          (click)="navigateToBookmark(bookmark)"
          [matTooltip]="'Navigate to URL' | translate">
          <mat-icon>bookmark</mat-icon>
        </a>
        <mat-form-field class="f1 amb">
          <mat-label>
            <T str="Name"></T>
          </mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="bookmark.name"
            name="name" />
          <mat-hint class="truncate">{{ bookmark.url }}</mat-hint>
        </mat-form-field>
        <apex-qr-code [path]="bookmark.url"></apex-qr-code>
        <button
          mat-icon-button
          [disabled]="!juicyForm.dirty"
          (click)="save(bookmark, juicyForm)">
          <mat-icon>check</mat-icon>
        </button>

        <apex-bookmark-pin-buttons
          [bookmark]="bookmark"
          [pinnedBookmarks]="sidebarService.sidebarItemsPinned">
        </apex-bookmark-pin-buttons>
        <button
          mat-icon-button
          color="warn"
          (click)="delete(bookmark)">
          <mat-icon>delete</mat-icon>
        </button>
      </form>
    </div>
    <div
      class="df ac"
      *ngIf="!bookmarks.length && !folders.length">
      <T str="No folders or bookmarks"></T>
    </div>
  </div>
</div>
