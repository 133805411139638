import { Injectable } from '@angular/core';
import { ModelRole } from 'projects/apex/src/app/models/model-role';
import { HttpService } from 'projects/apex/src/app/services/http/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CollectionResponse } from '../../utils/types';

@Injectable()
export class ModelRoleService extends HttpService<ModelRole> {
  public route = 'model-role';

  queryRoleForModel(model: string, modelId: number | string, role: string): Observable<ModelRole[]> {
    return this.http.get<ModelRole[]>(`${this.apiUrl}/${this.route}/${model}/${modelId}/${role}`, this.options);
  }

  queryRolesByModel(model: string, modelId: number | string): Observable<ModelRole[]> {
    return this.http.get<ModelRole[]>(`${this.apiUrl}/${this.route}/${model}/${modelId}`, this.options);
  }

  saveRoleForModel(mr: ModelRole): Observable<ModelRole> {
    return this.http.post<ModelRole>(
      `${this.apiUrl}/${this.route}/${mr.model}/${mr.modelId}/${mr.role}`,
      {
        CRMUserId: mr.CRMUserId,
        UserId: mr.UserId,
        from: mr.from,
        to: mr.to,
      },
      this.options,
    );
  }

  count(model: string, modelId: number, role: string): Observable<{ count: number }> {
    return this.http.get<{ count: number }>(
      `${this.apiUrl}/${this.route}/${model}/${modelId}/${role}/count`,
      this.options,
    );
  }

  getRolesForCRMUser(id: string): Observable<ModelRole[]> {
    return this.http.get<CollectionResponse<ModelRole>>(`${this.apiUrl}/crm-user/${id}/role`, this.options).pipe(
      map((roles) => roles.Collection),
      map((roles) => roles.map((role) => new ModelRole(role))),
    );
  }
}
