<div mat-dialog-title>
  <div class="df ac jsb f1">
    <h1>
      <T
        str="Editing {count, plural, =1 {# case} other {# cases}}"
        [vars]="{ count: cases?.length ?? 0 }"></T>
    </h1>
    <button
      mat-icon-button
      (click)="dialogRef.close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content>
  <ng-container *ngIf="isCaseManager">
    <div class="df r ac">
      <apex-autocomplete
        class="amr"
        [(ngModel)]="ProjectId"
        (ngModelChange)="
          ApartmentId = null;
          ClientId = null;
          ProjectId && removeProject && (removeProject = false);
          ProjectId && (removeApartment = true);
          ProjectId && (removeClient = true)
        "
        type="Project"
        [label]="'Project' | translate">
      </apex-autocomplete>
      <mat-checkbox
        [checked]="removeProject"
        [(ngModel)]="removeProject"
        (change)="pacRemoveChange('project', $event.checked)">
        <T str="Remove"></T>
      </mat-checkbox>
    </div>
    <div class="df r ac">
      <apex-autocomplete
        class="amr"
        [(ngModel)]="ApartmentId"
        (ngModelChange)="
          ClientId = null;
          ApartmentId && removeApartment && (removeApartment = false);
          ApartmentId && (removeClient = true)
        "
        type="Apartment"
        [label]="'Unit' | translate"
        [params]="{
          ProjectId,
        }">
      </apex-autocomplete>
      <mat-checkbox
        [checked]="removeApartment"
        [(ngModel)]="removeApartment"
        (change)="pacRemoveChange('apartment', $event.checked)">
        <T str="Remove"></T>
      </mat-checkbox>
    </div>
    <div class="df r ac">
      <apex-autocomplete
        class="amr"
        [(ngModel)]="ClientId"
        (ngModelChange)="ClientId && removeClient && (removeClient = false)"
        type="Client"
        [label]="'Customer' | translate"
        [params]="{
          ApartmentId,
        }">
      </apex-autocomplete>
      <mat-checkbox
        [checked]="removeClient"
        [(ngModel)]="removeClient">
        <T str="Remove"></T>
      </mat-checkbox>
    </div>
    <div class="df r ac">
      <apex-autocomplete
        class="amr"
        type="User"
        [label]="'Case Manager' | translate"
        [(ngModel)]="CaseManagerId"
        [params]="{
          subscribed: true,
          extended: true,
        }">
      </apex-autocomplete>
      <mat-checkbox
        [checked]="removeCaseManager"
        (change)="removeCaseManager = $event.checked">
        <T str="Remove"></T>
      </mat-checkbox>
    </div>
    <div class="df r ac">
      <apex-autocomplete
        class="amr"
        type="Field"
        [label]="'Discipline' | translate"
        [(ngModel)]="FieldId"
        [params]="{
          ProjectId,
        }">
      </apex-autocomplete>
      <mat-checkbox
        [checked]="removeField"
        (change)="removeField = $event.checked">
        <T str="Remove"></T>
      </mat-checkbox>
    </div>
    <div class="df r phone-column">
      <apex-autocomplete
        class="amr phone-rm"
        [(ngModel)]="CaseCategoryId"
        type="CaseCategory"
        [label]="'Category' | translate">
      </apex-autocomplete>
      <mat-form-field>
        <mat-label>
          <T str="Deadline"></T>
        </mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [(ngModel)]="deadline" />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </ng-container>
  <div>
    <div class="mat-elevation-z2 ap">
      <apex-case-new-message
        #newMessage
        [cases]="cases"></apex-case-new-message>
    </div>
  </div>
  <div class="amt">
    <h1 class="mat-subtitle-1 rm">
      <T
        str="{count, plural, =0 {No contractors} =1 {One contractor} other {# contractors}} "
        [vars]="{ count: Contractors?.length }">
      </T>
    </h1>
    <div
      *ngFor="let Contractor of Contractors"
      class="df jsb">
      <p>{{ Contractor.name }}</p>
      <button
        mat-icon-button
        color="primary"
        (click)="removeContractor(Contractor)">
        <mat-icon> clear</mat-icon>
      </button>
    </div>
    <div>
      <apex-autocomplete
        type="User"
        [label]="'Add contractor' | translate"
        [(ngModel)]="currentContractorId"
        (modelChange)="addContractor($event)">
      </apex-autocomplete>
    </div>
    <div>
      <mat-checkbox
        [checked]="removeOtherContractors"
        (change)="removeOtherContractors = $event.checked">
        <T str="Remove other contractors"></T>
      </mat-checkbox>
    </div>
  </div>
</div>
<div
  mat-dialog-actions
  class="df r jfe">
  <button
    mat-button
    [disabled]="loading"
    (click)="dialogRef.close()">
    <T str="Close"></T>
  </button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="loading || !cases?.length"
    (click)="save()">
    <T str="Save"></T>
  </button>
</div>
<mat-progress-bar
  *ngIf="loading"
  mode="determinate"
  [value]="loadingProgress"></mat-progress-bar>
