<ng-template #content>
  <ng-container *ngIf="tenancies?.length; else emptyState">
    <ng-container *ngTemplateOutlet="view === 'list' ? list : cards"></ng-container>
  </ng-container>
</ng-template>

<ng-template #emptyState>
  <div class="df f1 am ac jc">
    <T str="There are no tenancies to show"></T>
  </div>
</ng-template>

<ng-template #pagination>
  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [length]="count"
    [pageIndex]="page"
    [pageSize]="limit"
    (page)="changePage($event)">
  </mat-paginator>
</ng-template>

<ng-template #list>
  <div class="am mat-elevation-z2">
    <mat-nav-list class="list">
      <ng-container *ngFor="let tenancy of tenancies">
        <mat-list-item
          lines="3"
          [routerLink]="[tenancy.id]">
          <span matListItemTitle>
            {{ tenancy.name }}
          </span>

          <div
            matListItemLine
            *ngIf="tenancy.Objects">
            <span [matTooltip]="'Tenant' | translate">
              <span *ngFor="let Object of tenancy.Objects; last as isLast">
                {{ Object.name }}<span *ngIf="!isLast">, </span>
              </span>
            </span>
          </div>

          <div matListItemLine>
            <span [matTooltip]="'Period' | translate">
              {{ tenancy.from | date: 'shortDate' }} - {{ tenancy.to | date: 'shortDate' }}
            </span>
          </div>

          <div matListItemMeta>
            <mat-checkbox
              class="ped"
              [(ngModel)]="tenancy.active"
              ><T str="Active"></T
            ></mat-checkbox>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>
      <ng-container *ngTemplateOutlet="pagination"></ng-container>
    </mat-nav-list>
  </div>
</ng-template>

<ng-template #cards>
  <div class="cards amt amh display-grid-cards card-width-250">
    <div
      *ngFor="let tenancy of tenancies"
      mat-ripple
      [routerLink]="['tenancy', tenancy.id]"
      class="tenancy card new df c mat-elevation-z2 d-f1 t-f1 disable-focus">
      <div class="df ac amv amh">
        <mat-icon fontSet="material-icons-sharp">home_work</mat-icon>
        <div class="aml">
          {{ tenancy.name }}
        </div>
      </div>
      <ng-container *ngIf="!portal">
        <div class="df ac amb amh">
          <mat-icon fontSet="material-icons-sharp">date_range</mat-icon>
          <div
            class="aml"
            [matTooltip]="'Period' | translate">
            {{ tenancy.from | date: 'shortDate' }} - {{ tenancy.to | date: 'shortDate' }}
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="portal">
        <div class="df ac amb amh">
          <mat-icon fontSet="material-icons-sharp">construction</mat-icon>
          <div
            class="aml"
            [matTooltip]="'Project' | translate">
            {{ tenancy.topObject.name }}
          </div>
        </div>
      </ng-container>
      <div class="df ac amb amh">
        <mat-icon fontSet="material-icons-sharp">business</mat-icon>
        <div class="aml">
          {{ tenancy.Customer?.name }}
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="pagination"></ng-container>
</ng-template>

<ng-container *ngTemplateOutlet="content"></ng-container>

<button
  mat-fab
  class="fab-button"
  [matTooltip]="'New tenancy' | translate"
  [routerLink]="['./', 'new']">
  <mat-icon> add</mat-icon>
</button>
