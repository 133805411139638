export enum Color {
  'AT50' = '#def9f6',
  'AT100' = '#beeae9',
  'AT200' = '#9fdbdd',
  'AT300' = '#7fccd0',
  'AT400' = '#5fbec3',
  'AT500' = '#3fafb6',
  'AT600' = '#20a0aa',
  'AT700' = '#00919d',
  'AT800' = '#007a86',
  'AT900' = '#00636f',

  'AK50' = '#f9f9f9',
  'AK100' = '#ededed',
  'AK200' = '#d6d6d7',
  'AK300' = '#adadaf',
  'AK400' = '#98989b',
  'AK500' = '#7a7a7d',
  'AK600' = '#656569',
  'AK700' = '#515155',
  'AK800' = '#46464b',
  'AK900' = '#323237',

  'AR100' = '#d5888c',
  'AR200' = '#c15b62',
  'AR300' = '#c53440',
  'AR500' = '#c61226',

  'AB100' = '#abb3db',
  'AB200' = '#8b95cc',
  'AB300' = '#727cc0',

  'AY100' = '#ebdf9f',
  'AY200' = '#ddca5f',
  'AY300' = '#cfb706',

  'AG100' = '#90c08c',
  'AG200' = '#7bb375',
  'AG300' = '#68a65e',
}

export const colorSeries = [
  Color.AT700,
  Color.AK700,
  Color.AR500,
  Color.AT500,
  Color.AK500,
  Color.AR300,
  Color.AT300,
  Color.AK300,
  Color.AR100,
] as const;
