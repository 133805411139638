import { NgModule } from '@angular/core';

import { FormatCaseLogMessagePipe } from './format-case-log-message.pipe';

@NgModule({
  declarations: [FormatCaseLogMessagePipe],
  exports: [FormatCaseLogMessagePipe],
  imports: [],
  providers: [],
})
export class FormatCaseLogMessagePipeModule {}
