<h2 class="am">
  <T
    str="Entry group for {groupName}"
    [vars]="{ groupName: entryGroup?.name }">
  </T>
</h2>

<form
  #entryGroupForm="ngForm"
  (ngSubmit)="saveGroup()"
  class="df c am apl apt mat-elevation-z2">
  <mat-form-field class="amr">
    <input
      matInput
      type="text"
      name="name"
      [placeholder]="'Name' | translate"
      [(ngModel)]="entryGroup.name"
      required />
  </mat-form-field>

  <mat-form-field class="amr">
    <textarea
      matInput
      name="description"
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="10"
      [placeholder]="'Description' | translate"
      [(ngModel)]="entryGroup.description">
    </textarea>
  </mat-form-field>

  <div class="df f1 ac jsb w">
    <div class="df ac amr amb p-f1">
      <div class="amr">
        <button
          mat-raised-button
          type="button"
          color="warn"
          class="p-f1"
          [disabled]="!entryGroup.id"
          (click)="deleteGroup()">
          <T str="Delete"></T>
        </button>
      </div>
      <button
        mat-raised-button
        type="button"
        color="primary"
        class="p-f1"
        [disabled]="!entryGroupForm.valid"
        (click)="saveGroupAndContinue(); entryGroupForm.resetForm()">
        <T str="Save and continue"></T>
      </button>
    </div>
    <div class="df ac p-f1">
      <div class="amr amb p-df p-f1">
        <button
          mat-raised-button
          type="button"
          class="p-f1"
          apexHistoryBack>
          <T str="Back"></T>
        </button>
      </div>
      <div class="amr amb p-df p-f1">
        <button
          mat-raised-button
          type="submit"
          color="primary"
          class="p-f1"
          [disabled]="!entryGroupForm.valid">
          <T str="Save"></T>
        </button>
      </div>
    </div>
  </div>
</form>
