export const constants = {
  pattern: {
    hexColor: /^#[0-9A-F]{6}$/i,
  },
  maxStringLength: 1024,
  requestDebounceTime: 66,
  inputDebounceTime: 333,
  autoSaveDebounceTime: 1000,
  requestInFlightDebounceTime: 1000 + 125,
  breakpoints: {
    phoneMax: 599,
    tabletMin: 600,
    tabletMax: 1199,
    desktopMin: 1200,
  },
  favoritesAvailable: ['object', 'project', 'user'],
  redirectUrlKey: 'redirect-url',
  pagination: {
    listPageSizeOptions: [25, 50, 100],
    boxListPageSizeOptions: [5, 15, 25],
  },
};
