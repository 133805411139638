<div
  #page
  class="df c f1">
  <div class="df jsb ac">
    <div class="chart-title-container df f1 aps jsa ac">
      <h2
        #titleBox
        contenteditable
        (keydown)="updateTitle($event)">
        {{ title }}
      </h2>
    </div>
    <div class="ams">
      <button
        mat-icon-button
        (click)="savePng()"
        disableRipple="true">
        <mat-icon> save_alt </mat-icon>
      </button>
    </div>
  </div>
  <div class="df c w">
    <div class="df f1">
      <div
        #chart
        class="f1 overflow-horizontal"
        id="chart"></div>
    </div>
  </div>
</div>
