import { Folder as Foldy } from 'projects/apex/src/app/models/folder';
import { t } from '../translate/translate.function';

export interface FolderTreeDialogData {
  folder: Foldy;
  folders: Foldy[];
}

export enum DragoverType {
  Folder = 'folder',
  File = 'file',
  upload = 'upload',
  Self = 'self',
}

export class FolderExt extends Foldy {
  expanded?: boolean;
  isParent?: boolean;
  depth?: number;
  hasChildren?: boolean;
  childrenCount?: number = 0;
  childrenPage?: number = 0;
  Children: FolderExt[];
  Parents: FolderExt[];
}

export const folderTranslations = {
  shared: t('Shared with me'),
  cases: t('Cases'),
  addons: t('Addons'),
  objects: t('Objects'),
};
