import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { ConfirmDialogComponent } from 'projects/apex/src/app/components/confirm-dialog/confirm-dialog.component';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, TranslateModule, MatListModule],
  declarations: [ConfirmDialogComponent],
  exports: [ConfirmDialogComponent],
})
export class ConfirmDialogModule {}
