import { File } from './file';
import { UUIDModel } from './model';

export class Folder extends UUIDModel<Folder> {
  name: string;
  CreatorId: number;
  description: string;
  customerAccess: boolean;

  modelId: number;
  modelName: string;

  ParentId: string;
  Parent: Folder;

  Files?: File[];
  Children: Folder[];
  Parents: Folder[];

  hasChildren?: boolean;
  hasFiles?: boolean;

  selected?: boolean;
  shared?: boolean;
  access?: { view: boolean; collaborator: boolean; edit: boolean; owner: boolean };
}
