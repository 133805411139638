import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import HelpIconComponent from '../help-icon/help-icon.component';
import { TranslateModule } from '../translate/translate.module';
import { PageInfoComponent } from './page-info.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatTooltipModule,
    RouterLink,
    TranslateModule,
    HelpIconComponent,
  ],
  exports: [PageInfoComponent],
  declarations: [PageInfoComponent],
})
export class PageInfoModule {}
