import { File } from '../../../models/file';
import { Folder } from '../../../models/folder';
import { User } from '../../../models/user';

export interface FolderShareData {
  folders: Folder[];
  files: File[];
}

export enum FolderShareUserRole {
  Viewer = 'viewer',
  Editor = 'editor',
  Varies = 'varies',
}

export class FolderShareUser extends User {
  shareRole: FolderShareUserRole;
  from: Date;
  to: Date;
}
