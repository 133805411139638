import { Pipe, PipeTransform } from '@angular/core';
import sanitizeHtml from 'sanitize-html';
import { ResponseCaseLogDto } from '../../../../../../generated/apex-rest';
import { t } from '../../components/translate/translate.function';
import { CaseLogEmailPipe } from '../../features/case/logs/pipes/case-log-email.pipe';
import { CaseLog } from '../../models/case-log';
import { SystemLogMessages, translateSystemLogParams } from './system-log-messages';

@Pipe({
  name: 'formatCaseLogMessage',
})
export class FormatCaseLogMessagePipe implements PipeTransform {
  caseLogEmailPipe = new CaseLogEmailPipe();

  transform(caseLog: CaseLog | ResponseCaseLogDto): string {
    if (caseLog.translate) {
      return t(SystemLogMessages[caseLog.message], {
        ...translateSystemLogParams(caseLog.translateVars),
        _context: 'system-log-message',
      });
    }

    if (caseLog.message) {
      return caseLog.message;
    }

    const logEmail = this.caseLogEmailPipe.transform(caseLog);

    if (logEmail) {
      if (logEmail.bodyPlain) {
        return logEmail.bodyPlain;
      }

      if (logEmail.bodyHTML) {
        return sanitizeHtml(logEmail.bodyHTML ?? '', { allowedTags: ['br', 'p'], selfClosing: ['br'] });
      }
    }

    return '';
  }
}
