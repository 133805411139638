import { NgModule } from '@angular/core';
import { AutoFocusDirective } from './auto-focus.directive';

@NgModule({
  imports: [],
  exports: [AutoFocusDirective],
  declarations: [AutoFocusDirective],
  providers: [],
})
export class AutoFocusDirectiveModule {}
