import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '../translate/translate.module';
import { ChecklistBulkDeleteDialogComponent } from './checklist-bulk-delete-dialog.component';

@NgModule({
  declarations: [ChecklistBulkDeleteDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatListModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    MatButtonModule,
    TranslateModule,
    MatTooltipModule,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
  exports: [ChecklistBulkDeleteDialogComponent],
})
export class ChecklistBulkDeleteModule {}
