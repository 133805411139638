import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InViewDirective } from './in-view.directive';
import { IntersectionService } from './intersection.service';

@NgModule({
  declarations: [InViewDirective],
  exports: [InViewDirective],
  imports: [CommonModule],
  providers: [IntersectionService],
})
export class InViewDirectiveModule {}
