<div class="case-list-container">
  <mat-menu #editMenu="matMenu">
    <button
      mat-menu-item
      [apexAnalyticsClick]="{
        eventCategory: 'case',
        eventAction: 'mass-edit',
        eventLabel: 'edit',
      }"
      (click)="openMassEdit()">
      <mat-icon>edit</mat-icon>
      <T str="Edit"></T>
    </button>
    <button
      mat-menu-item
      [apexAnalyticsClick]="{
        eventCategory: 'case',
        eventAction: 'mass-edit',
        eventLabel: 'message',
      }"
      (click)="openNewMessage()">
      <mat-icon> chat</mat-icon>
      <T str="New message"></T>
    </button>
    <mat-divider></mat-divider>
    <button
      mat-menu-item
      [apexAnalyticsClick]="{
        eventCategory: 'case',
        eventAction: 'mass-edit',
        eventLabel: 'finalize',
      }"
      (click)="finish()">
      <T str="Finalize"></T>
    </button>
    <button
      mat-menu-item
      [apexAnalyticsClick]="{
        eventCategory: 'case',
        eventAction: 'mass-edit',
        eventLabel: 'reopen',
      }"
      (click)="reopen()">
      <T str="Reopen"></T>
    </button>
    <mat-divider></mat-divider>
    <span
      [matTooltip]="'You must be contractor on cases you want to accept, decline and complete' | translate"
      [matTooltipDisabled]="isContractorOnAnySelected">
      <button
        [disabled]="!isContractorOnAnySelected"
        mat-menu-item
        [apexAnalyticsClick]="{
          eventCategory: 'case',
          eventAction: 'mass-edit',
          eventLabel: 'accept',
        }"
        (click)="accept()">
        <mat-icon [color]="isContractorOnAnySelected ? 'primary' : undefined">thumb_up</mat-icon>
        <T str="Accept"></T>
      </button>

      <button
        [disabled]="!isContractorOnAnySelected"
        mat-menu-item
        [apexAnalyticsClick]="{
          eventCategory: 'case',
          eventAction: 'mass-edit',
          eventLabel: 'decline',
        }"
        (click)="decline()">
        <mat-icon [color]="isContractorOnAnySelected ? 'warn' : undefined">thumb_down</mat-icon>
        <T str="Decline"></T>
      </button>

      <button
        [disabled]="!isContractorOnAnySelected"
        mat-menu-item
        [apexAnalyticsClick]="{
          eventCategory: 'case',
          eventAction: 'mass-edit',
          eventLabel: 'complete',
        }"
        (click)="complete()">
        <mat-icon [color]="isContractorOnAnySelected ? 'primary' : undefined">task_alt</mat-icon>
        <T str="Done"></T>
      </button>
    </span>
    <mat-divider></mat-divider>
    <button
      mat-menu-item
      [apexAnalyticsClick]="{
        eventCategory: 'case',
        eventAction: 'mass-edit',
        eventLabel: 'mark-as-read',
      }"
      (click)="markAsRead()">
      <T str="Mark as read"></T>
    </button>
    <button
      mat-menu-item
      [apexAnalyticsClick]="{
        eventCategory: 'case',
        eventAction: 'mass-edit',
        eventLabel: 'mark-as-unread',
      }"
      (click)="markAsUnread()">
      <T str="Mark as unread"></T>
    </button>
  </mat-menu>

  <div class="cases">
    <div
      class="case-list-header"
      *ngIf="!!profile?.paymentStatus">
      <div class="df r jsb ac">
        <mat-checkbox
          [disabled]="cases?.length <= 0"
          [checked]="allSelected"
          [indeterminate]="anySelected"
          (change)="selectAll()">
          <T str="Select all"></T>
        </mat-checkbox>
        <div class="df r ac">
          <button
            mat-icon-button
            [matTooltip]="'Edit selected' | translate"
            [matMenuTriggerFor]="editMenu"
            [disabled]="!selected?.length || editLoading">
            <mat-icon *ngIf="!editLoading">edit</mat-icon>
            <mat-spinner
              *ngIf="editLoading"
              diameter="24"></mat-spinner>
          </button>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
    <div
      *ngFor="let case of cases"
      class="df case list-item mat-elevation-z2"
      mat-ripple
      [ngClass]="{
        complete: case.completed > 0,
        new: case.completed === 0,
        selected: case.id === id,
        archived: case.archivedAt,
        highlighted: isCaseHighlighted(case),
        offline: !case.createdAt,
        overdue: case.deadline * 1000 | isDateOverdue,
        declined: case.Contractors | isCaseDeclined,
        pending: case.Contractors | isCasePending,
      }"
      [apexAnalyticsClick]="{
        eventCategory: 'case',
        eventAction: 'list',
        eventLabel: 'open',
        eventValue: case.id,
      }"
      (click)="case.archivedAt ? $event.stopPropagation() : caseClicked(case)"
      [matRippleDisabled]="!!case.archivedAt">
      <div class="df aps c ac jsb left">
        <div class="rm mat-headline-6">
          {{ case.id }}
        </div>
        <mat-checkbox
          class="amvs"
          [class.visibility-hidden]="!profile?.paymentStatus"
          [checked]="case.selected"
          (change)="toggle(case, $event)"
          (click)="$event.stopPropagation()">
        </mat-checkbox>
        <div class="mat-caption">
          <span>{{ case.createdAt | date: 'shortDate' }}</span>
          <ng-container *ngIf="case.deadline > 0">
            <br />
            <span [ngClass]="{ 'warn-color': datePassed(dateAble(case.deadline * 1000)) }">
              {{ case.deadline * 1000 | date: 'shortDate' }}
            </span>
          </ng-container>
        </div>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="oh aps df c f1 right">
        <div class="df ac jsb">
          <ng-container *ngIf="case.EntryCardId || case.fromProjectMom; else normalTitle">
            <h2 class="truncate rm ambs mat-title">
              <T
                *ngIf="case.EntryCardId"
                str="Entry card request"></T>
              <T
                *ngIf="case.fromProjectMom"
                str="MOM for {fieldName}"
                [vars]="{ fieldName: case.Field?.name ?? '' }"></T>
            </h2>
          </ng-container>
          <ng-template #normalTitle>
            <h2 class="truncate rm ambs mat-headline-6 df ac">
              <ng-container *ngIf="!case.completed && (case.deadline * 1000 | isDateOverdue)">
                <mat-icon
                  class="amrxs"
                  color="warn"
                  [matTooltip]="'Case is overdue' | translate">
                  assignment_late
                </mat-icon>
              </ng-container>
              {{ case.name }}
            </h2>
          </ng-template>
          <div>
            <mat-icon
              *ngIf="!case.CaseViews?.length"
              [matTooltip]="'Unread' | translate">
              priority_high
            </mat-icon>
            <mat-icon
              *ngIf="case.ChecklistItem?.id"
              [matTooltip]="'Checklist item' | translate">
              horizontal_rule
            </mat-icon>
            <mat-icon
              *ngIf="case.ChecklistId && case.Checklist"
              [matTooltip]="'Checklist' | translate">
              list_alt
            </mat-icon>
            <mat-icon
              class="f1"
              *ngIf="profile ? profile.CustomerId !== case.CustomerId : false"
              [matTooltip]="'Customer: {companyName}' | translate: { companyName: case?.Customer?.name ?? '' }">
              corporate_fare
            </mat-icon>
          </div>
        </div>
        <div class="df d-ac d-r oh p-c">
          <ng-container *ngIf="case.ObjectId && case.ObjectPath?.length">
            <span class="df ac">
              <mat-icon
                class="add-margin-right-extra-small overflow-visible"
                [matTooltip]="'Object' | translate">
                {{ case.Object | icon }}
              </mat-icon>
              <span class="truncate aprs fe">{{ case.ObjectPath | listArray: ' / ' : 'name' }}&nbsp;</span>
            </span>
          </ng-container>
          <ng-container *ngIf="case.AgreementId && case.Agreement">
            <span class="df ac">
              <mat-icon
                class="add-margin-right-extra-small overflow-visible"
                [matTooltip]="'Agreement' | translate">
                description</mat-icon
              >
              <span class="truncate aprs">{{ case.Agreement.name }}&nbsp;</span>
            </span>
          </ng-container>
          <ng-container *ngIf="case.TenancyId && case.Tenancy">
            <span class="df ac">
              <mat-icon
                class="add-margin-right-extra-small overflow-visible"
                [matTooltip]="'Tenancy' | translate">
                home_work</mat-icon
              >
              <span class="truncate aprs">{{ case.Tenancy.name }}</span>
            </span>
          </ng-container>
          <ng-container *ngIf="case.MeterId && case.Meter">
            <span class="df ac">
              <mat-icon
                class="add-margin-right-extra-small overflow-visible"
                [matTooltip]="'Meter' | translate">
                show_chart</mat-icon
              >
              <span class="truncate aprs">{{ case.Meter.name }}</span>
            </span>
          </ng-container>
          <ng-container *ngIf="case.ProjectId && case.Project">
            <span class="df ac">
              <mat-icon
                class="add-margin-right-extra-small overflow-visible"
                [matTooltip]="'Project' | translate"
                svgIcon="project"></mat-icon>
              <span class="truncate aprs">{{ case.Project.name }}</span>
            </span>
          </ng-container>
          <ng-container *ngIf="case.ApartmentId && case.Apartment">
            <span class="df ac">
              <mat-icon
                class="add-margin-right-extra-small overflow-visible"
                [matTooltip]="'Unit' | translate"
                svgIcon="apartment"></mat-icon>
              <span class="truncate aprs">{{ case.Apartment.name }}</span>
            </span>
          </ng-container>
        </div>

        <ng-container *ngIf="case | clientsFromCase as clients">
          <div
            class="client df ac"
            *ngIf="clients?.length">
            <mat-icon [matTooltip]="'Customers' | translate"> person</mat-icon>
            <div class="truncate">
              <ng-container *ngFor="let client of clients; last as last">
                {{ client?.name }}
                <ng-container *ngIf="!last">,</ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <div class="df afe jsb afe f1">
          <div class="truncate df ac">
            <ng-container *ngIf="case.Contractors | contractorsFromCase as contractors">
              <ng-container *ngFor="let contractor of contractors">
                <apex-avatar
                  class="amrxs"
                  [user]="contractor"
                  size="small"
                  [borderFillColor]="contractor | statusColorForContractor"
                  [apexUserCard]="contractor"
                  [subtitle]="contractor | statusMessageForContractor"
                  [subtitleIcon]="contractor | statusIconForContractor"
                  [tooltip]="contractor | statusMessageForContractor"
                  (click)="$event.stopPropagation()">
                </apex-avatar>
              </ng-container>
              <div
                class="amlss"
                *ngIf="case?.Contractors?.length > 5">
                +{{ case.Contractors.length - 5 }}
              </div>
            </ng-container>
          </div>
          <div class="df mat-caption">
            <ng-container *ngIf="case.CaseCategory?.name">
              {{ case.CaseCategory.name | translate }}
            </ng-container>
            <ng-container *ngIf="case.Field?.name"> / {{ case.Field.name }} </ng-container>
            <ng-container *ngIf="case.ObjectField"> / {{ case.ObjectField.fullName }} </ng-container>
          </div>
        </div>

        <div
          class="df jfe afe f1"
          *ngIf="case.archivedAt">
          <div class="ams">
            <button
              mat-raised-button
              color="primary"
              (click)="unarchiveCase(case)">
              <T str="Unarchive"></T>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
