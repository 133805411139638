<form
  #form="ngForm"
  class="am apvs apl mat-elevation-z2 df ac w"
  (ngSubmit)="onChangeFilter()">
  <div class="df f1 apr">
    <mat-form-field class="f1">
      <mat-label>
        <T str="Search"></T>
      </mat-label>
      <input
        matInput
        name="search"
        [(ngModel)]="search"
        (ngModelChange)="onChangeFilter()"
        autofocus />
    </mat-form-field>
  </div>
  <apex-autocomplete
    class="f1 apr"
    name="ProjectId"
    *ngIf="hasProject"
    type="Project"
    [(ngModel)]="ProjectId"
    (ngModelChange)="onChangeFilter()"
    [label]="'Project' | translate">
  </apex-autocomplete>
  <apex-autocomplete
    class="f1 apr"
    name="ApartmentId"
    *ngIf="hasProject"
    [params]="{
      ProjectId,
    }"
    type="Apartment"
    [(ngModel)]="ApartmentId"
    (ngModelChange)="onChangeFilter()"
    [label]="'Unit' | translate">
  </apex-autocomplete>
  <apex-autocomplete
    class="f1 apr"
    name="FieldId"
    *ngIf="hasProject"
    [params]="{
      ProjectId,
    }"
    type="Field"
    [(ngModel)]="FieldId"
    [disabled]="!ProjectId"
    (ngModelChange)="onChangeFilter()"
    [label]="'Discipline' | translate">
  </apex-autocomplete>
  <apex-object-selector
    name="ObjectId"
    class="f1 apr"
    *ngIf="hasCommercial"
    [multiple]="false"
    [(ngModel)]="ObjectId"
    (ngModelChange)="onChangeFilter()"
    [label]="'Object' | translate">
  </apex-object-selector>
  <apex-autocomplete
    class="f1 apr"
    name="ObjectFieldId"
    *ngIf="hasProject"
    [params]="{
      ObjectId: ObjectId,
    }"
    type="ObjectField"
    [(ngModel)]="ObjectFieldId"
    [disabled]="!ObjectId"
    (ngModelChange)="onChangeFilter()"
    [label]="'Discipline' | translate">
  </apex-autocomplete>

  <mat-checkbox
    class="apr"
    name="open"
    [(ngModel)]="open"
    (ngModelChange)="onChangeFilter()">
    <T str="Open"></T>
  </mat-checkbox>
  <mat-checkbox
    class="apr"
    name="completed"
    [(ngModel)]="completed"
    (ngModelChange)="onChangeFilter()">
    <T str="Completed"></T>
  </mat-checkbox>
</form>
