<mat-menu #addMenu="matMenu">
  <ng-template matMenuContent>
    <button
      mat-menu-item
      (click)="addGroup()"
      type="button">
      <mat-icon>group_work</mat-icon>
      <T str="Group"></T>
    </button>
    <button
      mat-menu-item
      (click)="addChecklist()"
      type="button">
      <mat-icon>list_alt</mat-icon>
      <T str="Checklist"></T>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #moreMenu="matMenu">
  <ng-template matMenuContent>
    <a
      mat-menu-item
      (click)="$event.stopPropagation()"
      [routerLink]="[]"
      [queryParams]="{ showCompletedChecklists: !showCompleted }">
      <mat-icon
        fontSet="material-icons-outlined"
        [color]="showCompleted ? 'primary' : undefined">
        check_circle
      </mat-icon>
      <ng-container *ngIf="showCompleted">
        <T
          class="mat-body-2"
          str="Hide completed">
        </T>
      </ng-container>
      <ng-container *ngIf="!showCompleted">
        <T
          class="mat-body-2"
          str="Show completed">
        </T>
      </ng-container>
    </a>

    <mat-divider></mat-divider>

    <a
      mat-menu-item
      [routerLink]="['/checklist']"
      [queryParams]="{
        object: model === 'object' ? modelId : null,
        project: model === 'project' ? modelId : null,
        apartment: model === 'apartment' ? modelId : null,
      }">
      <mat-icon>list_alt</mat-icon>
      <T
        class="mat-body-2"
        str="Go to checklists"></T>
    </a>
    <a
      mat-menu-item
      [routerLink]="['/checklist-group']"
      [queryParams]="{
        checklistGroupObject: model === 'object' ? modelId : null,
        checklistGroupProject: model === 'project' ? modelId : null,
        checklistGroupApartment: model === 'apartment' ? modelId : null,
      }">
      <mat-icon>group_work</mat-icon>
      <T
        class="mat-body-2"
        str="Go to checklist groups"></T>
    </a>
    <button
      mat-menu-item
      [matMenuTriggerFor]="addMenu">
      <mat-icon>add</mat-icon>
      <T
        class="mat-body-2"
        str="Add"></T>
    </button>
  </ng-template>
</mat-menu>

<ng-template #checklistTemplate>
  <mat-nav-list *ngIf="checklists?.length">
    <ng-container *ngFor="let checklist of checklists; let l = last">
      <a
        mat-list-item
        lines="3"
        [routerLink]="['/checklist', checklist.id]">
        <mat-icon
          matListItemIcon
          [matTooltip]="'Checklist groups' | translate">
          list_alt
        </mat-icon>

        <h3 matListItemTitle>
          {{ checklist.name }}
        </h3>

        <span
          matListItemLine
          *ngIf="checklist.createdAt">
          {{ checklist.createdAt | date: 'shortDate' }}
        </span>

        <span matListItemLine>
          <ng-container *ngIf="checklist.ObjectId">
            {{ checklist.Object?.name }}
          </ng-container>
          <ng-container *ngIf="checklist.ProjectId && !checklist.ApartmentId">
            {{ checklist.Project?.name }}
          </ng-container>
          <ng-container *ngIf="checklist.ApartmentId">
            {{ checklist.Apartment?.name }} ({{ checklist.Project?.name }})
          </ng-container>
        </span>

        <apex-progress
          matListItemMeta
          [count]="checklist.caseCount"
          [completed]="checklist.casesCompleted">
        </apex-progress>
      </a>
      <mat-divider *ngIf="!l"></mat-divider>
    </ng-container>
  </mat-nav-list>
</ng-template>

<ng-template #checklistEmbedTemplate>
  <mat-expansion-panel
    *ngFor="let c of checklists || []"
    [expanded]="checklistsExpanded !== null ? checklistsExpanded : expanded">
    <mat-expansion-panel-header>
      <div class="f1 df ac oh">
        <mat-icon>list_alt</mat-icon>
        <apex-progress
          [count]="c.caseCount"
          [completed]="c.casesCompleted"
          [small]="true"></apex-progress>
        <div class="amhs f1 df c oh">
          <div class="mat-body-2 truncate">{{ c.name }}</div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <apex-checklist-group-checklist
        [checklist]="c"
        (countChange)="updateCounts()"></apex-checklist-group-checklist>
    </ng-template>
  </mat-expansion-panel>
</ng-template>

<ng-template #checklistGroupEmbedTemplate>
  <mat-expansion-panel
    *ngFor="let g of groups || []"
    [expanded]="checklistsExpanded !== null ? checklistsExpanded : expanded">
    <mat-expansion-panel-header>
      <div class="f1 df ac oh">
        <mat-icon>group_work</mat-icon>
        <apex-progress
          [count]="g.caseCount"
          [completed]="g.casesCompleted"
          [small]="true"></apex-progress>
        <div class="amhs f1 df c oh">
          <div class="mat-body-2 truncate">{{ g.name }}</div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <apex-checklist-group-tree
        [group]="g"
        [showItems]="true"
        [hideHeader]="true"></apex-checklist-group-tree>
    </ng-template>
  </mat-expansion-panel>
</ng-template>

<ng-template #contentWithHeader>
  <apex-box
    #boxComponent
    icon="list_alt"
    class="no-padding"
    [expanded]="expanded">
    <T
      title
      str="Checklists {count, plural, =0 {} other {(#)}}"
      [vars]="{ count: (groups?.length ?? 0) + (checklists?.length ?? 0) }"></T>

    <div
      class="df ac"
      right>
      <apex-progress
        class="bg-color-main"
        [count]="caseCount"
        [completed]="casesCompleted"
        [small]="true">
      </apex-progress>
      <button
        mat-icon-button
        (click)="$event.stopPropagation()"
        [matMenuTriggerFor]="moreMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>

    <ng-container *ngTemplateOutlet="content"></ng-container>
  </apex-box>
</ng-template>

<ng-template #content>
  <main class="df c oa">
    <ng-container *ngIf="!loading">
      <mat-accordion *ngIf="embedded">
        <ng-container *ngTemplateOutlet="checklistGroupEmbedTemplate"></ng-container>
        <ng-container *ngTemplateOutlet="checklistEmbedTemplate"></ng-container>
      </mat-accordion>
      <ng-container *ngIf="!embedded">
        <apex-checklist-group-list
          *ngIf="groups?.length"
          [groups]="groups"></apex-checklist-group-list>
        <mat-divider *ngIf="groups?.length && checklists?.length"></mat-divider>
        <ng-container *ngTemplateOutlet="checklistTemplate"></ng-container>
      </ng-container>
      <div
        *ngIf="!groups?.length && !checklists?.length"
        class="f1 df ac jc am">
        <T str="No checklists"></T>
      </div>
    </ng-container>
    <div
      *ngIf="loading"
      class="f1 df ac jc">
      <mat-spinner></mat-spinner>
    </div>
  </main>
</ng-template>

<ng-container *ngTemplateOutlet="box ? contentWithHeader : content"></ng-container>
