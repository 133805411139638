import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Addon } from 'projects/apex/src/app/models/addon';
import { AddonApartment } from 'projects/apex/src/app/models/addon-apartment';
import { AddonCart } from 'projects/apex/src/app/models/addon-cart';
import { AddonCategory } from 'projects/apex/src/app/models/addon-category';
import { AddonCategoryProject } from 'projects/apex/src/app/models/addon-category-project';
import { AddonOrder, AddonOrderStatus } from 'projects/apex/src/app/models/addon-order';
import { Apartment } from 'projects/apex/src/app/models/apartment';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { Marking } from 'projects/apex/src/app/models/marking';
import { Project } from 'projects/apex/src/app/models/project';
import { environment } from 'projects/apex/src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { createApiUrl } from '../../utils/functions';
import { ObjectWithId } from '../../utils/types';
import { IAddonOrderStatus } from '../addon/services/addon-order/addon-order.service.types';
import { ADDON_ORDER_CLIENT_STATUSES } from './client-page.service.types';

@Injectable()
export class ClientPageService {
  categoryInView = new Subject<{ inView: boolean; id: number }>();

  protected readonly apiUrl = environment.api;
  protected readonly route = 'client';
  protected readonly addonRoute = 'addon';
  protected readonly addonCategoryRoute = 'addon-category';
  protected readonly addonCategoryProjectRoute = 'addon-category-project';
  protected readonly addonOrderRoute = 'addon-order';
  protected readonly addonCartRoute = 'addon-cart';
  protected readonly addonApartmentRoute = 'addon-apartment';
  protected readonly apartmentRoute = 'apartment';
  protected readonly markingRoute = 'marking';
  protected readonly fileUsageRoute = 'fileusage';

  protected headers: HttpHeaders = new HttpHeaders({
    'content-type': 'application/json',
  });

  protected options = {
    headers: this.headers,
    withCredentials: true,
  };

  constructor(protected http: HttpClient) {}

  // AddonOrder

  getAddonOrder(AddonOrderId: number): Observable<AddonOrder> {
    return this.http.get<AddonOrder>(
      `${this.apiUrl}/${this.route}/${this.addonOrderRoute}/${AddonOrderId}`,
      this.options,
    );
  }

  queryAddonOrder(): Observable<AddonOrder[]> {
    return this.http.get<AddonOrder[]>(`${this.apiUrl}/${this.route}/${this.addonOrderRoute}`, this.options);
  }

  approveAddonOrder(addonOrder: AddonOrder): Observable<AddonOrder> {
    return this.http.get<AddonOrder>(
      `${this.apiUrl}/${this.route}/${this.addonOrderRoute}/${addonOrder.id}/approve`,
      this.options,
    );
  }

  denyAddonOrder(addonOrder: AddonOrder): Observable<AddonOrder> {
    return this.http.get<AddonOrder>(
      `${this.apiUrl}/${this.route}/${this.addonOrderRoute}/${addonOrder.id}/decline`,
      this.options,
    );
  }

  public getStatusInterface(status: AddonOrderStatus): IAddonOrderStatus {
    return ADDON_ORDER_CLIENT_STATUSES.find((aos) => aos.status === status);
  }

  getStatusInterfaceList(): { status: AddonOrderStatus; icon: string; color: string; text: string }[] {
    return ADDON_ORDER_CLIENT_STATUSES;
  }

  // AddonCart

  getAddonCart(ApartmentId: number): Observable<AddonCart> {
    return this.http.get<AddonCart>(
      `${this.apiUrl}/${this.route}/${this.apartmentRoute}/${ApartmentId}/${this.addonCartRoute}`,
      this.options,
    );
  }

  orderAddonCart(ApartmentId: number): Observable<{ signing: boolean; url: string }> {
    return this.http.get<{ signing: boolean; url: string }>(
      `${this.apiUrl}/${this.route}/${this.apartmentRoute}/${ApartmentId}/${this.addonCartRoute}/order`,
      this.options,
    );
  }

  addProductInCart(ApartmentId: number, AddonId: number, ClientComment?: string): Observable<AddonCart> {
    return this.http.post<AddonCart>(
      `${this.apiUrl}/${this.route}/${this.addonCartRoute}/add`,
      {
        ApartmentId,
        AddonId,
        ClientComment,
      },
      this.options,
    );
  }

  removeProductInCart(ApartmentId: number, AddonId: number): Observable<AddonCart> {
    return this.http.post<AddonCart>(
      `${this.apiUrl}/${this.route}/${this.addonCartRoute}/remove`,
      {
        ApartmentId,
        AddonId,
      },
      this.options,
    );
  }

  cartSigningCompleted(CartId: number): Observable<{ completed: boolean }> {
    const url = `${this.apiUrl}/${this.route}/addon-cart/${CartId}/signing-completed`;

    return this.http.get<{ completed: boolean }>(url, this.options);
  }

  getConfirmationText(ApartmentId: number): Observable<{ content: string; title: string }> {
    const url = `${this.apiUrl}/${this.route}/default-text/addon-confirmation/parsed/${ApartmentId}/html`;

    return this.http.get<{ content: string; title: string }>(url, this.options);
  }

  getProjectText(ApartmentId: number): Observable<{ content: string; title: string }> {
    const url = `${this.apiUrl}/${this.route}/default-text/addon-project-text/parsed/${ApartmentId}/html`;

    return this.http.get<{ content: string; title: string }>(url, this.options);
  }

  // Addon

  getAddonImages(addon: ObjectWithId): Observable<FileUsage[]> {
    return this.http.get<FileUsage[]>(
      `${this.apiUrl}/${this.route}/fileusage/${this.addonRoute}/${addon.id}/images`,
      this.options,
    );
  }

  // AddonCategory

  queryAddonCategoryByApartment(ApartmentId: number, onlyAvalable: boolean): Observable<AddonCategory[]> {
    return this.http.get<AddonCategory[]>(
      `${this.apiUrl}/${this.route}/${this.apartmentRoute}/${ApartmentId}/${this.addonCategoryRoute}${
        onlyAvalable ? '' : '/all'
      }`,
      this.options,
    );
  }

  queryAllAddonCategoryByApartment(ApartmentId: number): Observable<AddonCategory[]> {
    return this.http.get<AddonCategory[]>(
      `${this.apiUrl}/${this.route}/${this.apartmentRoute}/${ApartmentId}/${this.addonCategoryRoute}`,
      this.options,
    );
  }

  getAddonCategoryByAddonApartment(AddonApartmentId: number): Observable<AddonCategory> {
    return this.getAddonApartment(AddonApartmentId).pipe(
      mergeMap((addonApartment: AddonApartment) =>
        this.http.get<AddonCategory>(
          `${this.apiUrl}/${this.route}` +
            `/${this.apartmentRoute}/${addonApartment.ApartmentId}` +
            `/${this.addonCategoryRoute}/${addonApartment.Addon.AddonCategoryId}`,
          this.options,
        ),
      ),
    );
  }

  // AddonCategoryProject

  getAddonCategoryProjectImage(addonCategoryProject: AddonCategoryProject): Observable<FileUsage[]> {
    return this.http.get<FileUsage[]>(
      `${this.apiUrl}/${this.route}/${this.fileUsageRoute}/${this.addonCategoryProjectRoute}/${addonCategoryProject.id}/images`,
      this.options,
    );
  }

  // AddonApartment

  getAddonApartment(AddonApartmentId: number): Observable<AddonApartment> {
    return this.http.get<AddonApartment>(
      createApiUrl(this.route, this.addonApartmentRoute, AddonApartmentId),
      this.options,
    );
  }

  // Markings

  getMarkings(
    self: string,
    selfId: number | string,
    name: string,
    model: string,
    modelId: number,
    FileUsageId: number,
  ): Observable<Marking[]> {
    return this.http.get<Marking[]>(
      this.markingUrl(
        {
          self,
          selfId,
          name,
        } as FileUsage,
        {
          model,
          modelId,
          FileUsageId,
        } as Marking,
      ),
      this.options,
    );
  }

  getAllMarkings(
    self: string,
    selfId: string | number,
    name: string,
    model: string,
    modelId: number,
  ): Observable<Marking[]> {
    return this.http.get<Marking[]>(
      this.markingUrl(
        {
          self,
          selfId,
          name,
        } as FileUsage,
        {
          model,
          modelId,
        } as Marking,
      ),
      this.options,
    );
  }

  saveMarking(fileUsage: FileUsage, marking: Marking): Observable<Marking> {
    return this.http.post<Marking>(this.markingUrl(fileUsage, marking), marking, this.options);
  }

  deleteMarking(fileUsage: FileUsage, marking: Marking): Observable<Marking> {
    return this.http.delete<Marking>(this.markingUrl(fileUsage, marking), this.options);
  }

  getAddonApartmentMarkingsOnFloorplans(addonApartment: AddonApartment): Observable<Marking[]> {
    return this.getAllMarkings(
      'apartment',
      addonApartment.ApartmentId,
      'floorplans',
      'addon-apartment',
      addonApartment.id,
    );
  }

  // FileUsage

  getFileUsage(id: number): Observable<FileUsage> {
    return this.http.get<FileUsage>(`${this.apiUrl}/${this.route}/${this.fileUsageRoute}/${id}`, this.options);
  }

  queryFileUsage(self: string, selfId: string | number, name: string): Observable<FileUsage[]> {
    return this.http.get<FileUsage[]>(createApiUrl(this.route, this.fileUsageRoute, self, selfId, name), this.options);
  }

  fileUsageUrl(fileUsage: FileUsage): string {
    return createApiUrl(
      'client',
      this.fileUsageRoute,
      fileUsage.self,
      fileUsage.selfId,
      fileUsage.name,
      fileUsage.id ? fileUsage.FileId : undefined,
    );
  }

  // Apartment

  getApartment(ApartmentId: number): Observable<Apartment> {
    return this.http.get<Apartment>(`${this.apiUrl}/${this.route}/${this.apartmentRoute}/${ApartmentId}`, this.options);
  }

  queryApartment(): Observable<Apartment[]> {
    return this.http.get<Apartment[]>(`${this.apiUrl}/${this.route}/${this.apartmentRoute}`, this.options);
  }

  getApartmentWithAddons(ApartmentId: number): Observable<Apartment> {
    return this.http.get<Apartment>(createApiUrl(this.route, this.apartmentRoute, ApartmentId, 'addon'), this.options);
  }

  queryApartmentWithAddons(): Observable<Apartment[]> {
    return this.http.get<Apartment[]>(`${this.apiUrl}/${this.route}/${this.apartmentRoute}/addon`, this.options);
  }

  getFloorplanImage(addonApartment: AddonApartment): Observable<FileUsage[]> {
    return this.queryFileUsage(this.apartmentRoute, addonApartment.ApartmentId, 'floorplans').pipe(
      map((floorplans: FileUsage[]) =>
        floorplans.filter((fp) => {
          if (addonApartment?.filterFileUsage?.length && Array.isArray(addonApartment.filterFileUsage)) {
            return addonApartment.filterFileUsage.find((f) => f === fp.id);
          }

          return true;
        }),
      ),
    );
  }

  getFloorPlans(apartment: Apartment): Observable<FileUsage[]> {
    return this.queryFileUsage(this.apartmentRoute, apartment.id, 'floorplans');
  }

  getFloorplanAddonApartmentMarkings(addonApartment: AddonApartment, fileUsageId: number): Observable<Marking[]> {
    return this.getMarkings(
      'apartment',
      addonApartment.ApartmentId,
      'floorplans',
      'addon-apartment',
      addonApartment.id,
      fileUsageId,
    );
  }

  // Project

  getProjectsWithAddonOrders(): Observable<Project[]> {
    return this.queryAddonOrder().pipe(
      map((addonOrders: AddonOrder[]) => this.buildProjectTreeWithAddonOrder(addonOrders)),
    );
  }

  buildProjectTreeWithAddonOrder(addonOrders: AddonOrder[]): Project[] {
    let projects: Project[] = addonOrders.map((ao) => ao.Apartment.Project);

    projects = projects.filter((project, i, a) => a.findIndex((pp) => pp.id === project.id) === i);

    let apartments: Apartment[] = addonOrders.map((ao) => ao.Apartment);

    apartments = apartments.filter((apartment, i, a) => a.findIndex((aa) => aa.id === apartment.id) === i);

    return projects.map((p) => {
      p.Apartments = apartments
        .filter((a) => a.Project.id === p.id)
        .map((a) => {
          a.AddonOrders = addonOrders.filter((ao) => ao.ApartmentId === a.id);

          return a;
        });

      return p;
    });
  }

  getFdvs(addon: Addon): Observable<FileUsage[]> {
    return this.http.get<FileUsage[]>(
      createApiUrl(this.route, 'fileusage', this.addonRoute, addon.id, 'fdv'),
      this.options,
    );
  }

  protected markingUrl(fileUsage: FileUsage, marking: Marking): string {
    let url = this.fileUsageUrl({
      self: fileUsage.self,
      selfId: fileUsage.selfId,
      name: fileUsage.name,
    } as FileUsage);

    url += `/${this.markingRoute}/${marking.model}/${marking.modelId}`;

    if (marking.FileUsageId || fileUsage.id) {
      url += `/${marking.FileUsageId || fileUsage.id}`;

      if (marking.id) {
        url += `/${marking.id}`;
      }
    }

    return url;
  }
}
