import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpService } from 'projects/apex/src/app/services/http/http.service';
import { HttpOptions } from 'projects/apex/src/app/services/http/http.service.types';
import { Observable, Subject } from 'rxjs';
import { Filter } from '../../models/filter';
import { getCaseQueryParams } from '../../utils/functions';
import { FilterType } from './filter.service.types';

@Injectable()
export class FilterService extends HttpService<Filter> {
  public route = 'filter';
  public filterOptions: HttpOptions;
  public filterChanged: Subject<Params>;

  requestOptions(query: string): HttpOptions {
    const options = { ...this.filterOptions };

    options.params = {
      ...options.params,
    };

    if (query) {
      options.params.query = query;
    } else {
      options.params.query = '*';
    }

    return options;
  }

  constructor(
    protected http: HttpClient,
    private activatedRoute: ActivatedRoute,
  ) {
    super(http);
    this.filterChanged = new Subject();
    this.activatedRoute.queryParams.subscribe((params) => {
      const hp = getCaseQueryParams(params);

      this.filterOptions = {
        headers: this.options.headers,
        withCredentials: true,
        params: hp,
      };
      this.filterChanged.next(params);
    });
  }

  getFilter(type: FilterType, query: string): Observable<Filter[]> {
    return this.http.get<Filter[]>(`${this.apiUrl}/${this.route}/${type}`, this.requestOptions(query));
  }
}
