<mat-spinner
  *ngIf="!mail && !failedToParseMail"
  class="f1 ac align-self-center"></mat-spinner>
<div
  *ngIf="failedToParseMail"
  class="jc f1 df ac c">
  <mat-icon>insert_drive_file</mat-icon>
  <T str="Failed to show mail"></T>
</div>
<div
  *ngIf="mail"
  class="df c f1 overflow-y-auto">
  <div class="df bg-color-primary-700 add-padding">
    <h2 class="text-white remove-margin">
      <ng-container *ngIf="mail.subject">{{ mail.subject }}</ng-container>
      <T
        *ngIf="!mail.subject"
        str="No subject"></T>
    </h2>
  </div>
  <div
    class="add-padding"
    id="metadata">
    <div class="display-grid">
      <apex-title-item *ngIf="this.mail.from">
        <T
          str="From"
          title>
        </T>
        {{ this.mail.from }}
      </apex-title-item>
      <apex-title-item *ngIf="this.mail.messageDeliveryTime">
        <T
          str="Date"
          title>
        </T>
        {{ this.mail.messageDeliveryTime | date: 'short' }}
      </apex-title-item>
      <apex-title-item *ngIf="this.mail.recipients">
        <T
          str="To"
          title>
        </T>
        {{ this.mail.recipients | join }}
      </apex-title-item>
      <apex-title-item *ngIf="this.mail.cc.length > 0">
        <T
          str="CC"
          title>
        </T>
        {{ this.mail.cc | join }}
      </apex-title-item>
      <apex-title-item *ngIf="this.mail.bcc.length > 0">
        <T
          str="BCC"
          title>
        </T>
        {{ this.mail.bcc | join }}
      </apex-title-item>
    </div>
    <apex-title-item
      *ngIf="this.mail.attachments.length > 0"
      class="add-padding-top">
      <T
        str="Attachments"
        title>
      </T>
      <div class="df gap-small wrap">
        <button
          (click)="openAttachment(attachment)"
          *ngFor="let attachment of this.mail.attachments"
          [matTooltip]="'Open attachment' | translate"
          mat-stroked-button>
          {{ attachment.name }}
        </button>
      </div>
    </apex-title-item>
  </div>
  <mat-divider></mat-divider>
  <div class="df c f1 add-padding">
    <div
      *ngIf="this.mail.html"
      [innerHTML]="this.mail.html">
      INNER HTML
    </div>
    <div *ngIf="!this.mail.html && this.mail.body">{{ this.mail.body }}</div>
    <div *ngIf="!this.mail.html && !this.mail.body"><T str="This mail has no body"></T></div>
  </div>
</div>
