<table
  mat-table
  [dataSource]="dataSource"
  class="f1">
  <ng-container matColumnDef="user">
    <th
      mat-header-cell
      *matHeaderCellDef>
      <T str="User"></T>
    </th>
    <td
      mat-cell
      *matCellDef="let element">
      <div class="df ac">
        <apex-avatar
          class="amrs"
          [apexUserCard]="element.Creator"
          size="medium"
          [user]="element.Creator">
        </apex-avatar>
        {{ element.Creator?.name }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="value">
    <th
      mat-header-cell
      *matHeaderCellDef>
      <T str="Value"></T>
    </th>
    <td
      mat-cell
      *matCellDef="let element">
      {{ element.value }}
    </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th
      mat-header-cell
      *matHeaderCellDef>
      <T str="Date"></T>
    </th>
    <td
      mat-cell
      *matCellDef="let element">
      {{ element.createdAt | date: 'medium' }}
    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    [ngClass]="{
      'row-error': row.isError,
      'row-warning': row.isWarning && !row.isError,
    }"></tr>

  <tr
    class="mat-row"
    *matNoDataRow>
    <td
      colspan="4"
      class="apl">
      <T str="No value to show"></T>
    </td>
  </tr>
</table>
