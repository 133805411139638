import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FolderComponent } from 'projects/apex/src/app/components/folder/folder.component';
import { UploadService } from 'projects/apex/src/app/components/upload/upload.service';
import { UploadData } from 'projects/apex/src/app/components/upload/upload.types';
import { File as ApexFile } from 'projects/apex/src/app/models/file';
import { FolderService } from 'projects/apex/src/app/services/folder/folder.service';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import { sortNatural } from 'projects/apex/src/app/utils/functions';
import { CollectionResponse } from 'projects/apex/src/app/utils/types';
import { Subscription } from 'rxjs';
import { User } from '../../models/user';

@Component({
  selector: 'apex-folder-dialog',
  templateUrl: './dialog.component.html',
})
export class FolderDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(FolderComponent) folder: FolderComponent;
  @ViewChild('fileTable') fileTable: MatTable<ApexFile>;

  profile: User;

  uploadFolderFileCount = 0;
  uploadFolderFilePage = 0;
  uploadFolderFiles = new MatTableDataSource<ApexFile>();

  loading = true;
  error = false;
  dragoverUpload = false;
  isWeb: boolean;

  selectedIndex = 2;
  displayedColumns: string[] = ['selected', 'name', 'updatedAt', 'size', 'more'];
  sub = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { selectedIndex: number; splitPdf: 'ask' | 'never' | 'always' },
    public dialogRef: MatDialogRef<FolderDialogComponent>,
    public uService: UploadService,
    private folderService: FolderService,
    private userSerivce: UserService,
    private platform: Platform,
    private bpObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.isWeb =
      this.bpObserver.isMatched([Breakpoints.Web, Breakpoints.WebLandscape, Breakpoints.WebPortrait]) &&
      !this.platform.ANDROID &&
      !this.platform.IOS;

    if (this.isWeb) {
      this.displayedColumns = ['selected', 'name', 'more'];
    }

    if (this.data) {
      if (this.data.selectedIndex !== null) {
        this.selectedIndex = this.data.selectedIndex;
      }
    }

    this.dialogRef?.addPanelClass(['apex-fullscreen-dialog', 'phone']);
    this.sub.add(
      this.userSerivce.filteredProfile$.subscribe({
        next: (profile: User) => {
          this.profile = profile;

          if (this.selectedIndex === 0 && this.profile?.Customer?.payed) {
            this.selectedIndex = 2;
          }
        },
      }),
    );
    this.sub.add(
      this.uService.complete.subscribe({
        next: (data: UploadData) => {
          if (!data.FolderId) {
            if (data.File) {
              data.File.selected = true;
              this.uploadFolderFiles.data.unshift(data.File);
              this.uploadFolderFiles.data = sortNatural(this.uploadFolderFiles.data, 'createdAt').reverse();

              if (this.selectedIndex === 2) {
                this.selectedIndex = 1;
              }
            }
          }
        },
      }),
    );
  }

  ngAfterViewInit(): void {
    this.getUploadFolderFiles(0, 10);
  }

  getUploadFolderFiles(page: number, limit: number): void {
    this.folderService
      .files('upload', {
        params: {
          page: String(page),
          limit: String(limit),
          orderBy: 'createdAt',
          order: 'desc',
        },
      })
      .subscribe({
        next: (res: CollectionResponse<ApexFile>) => {
          this.uploadFolderFileCount = res.count;
          this.uploadFolderFiles.data = res.Collection;
        },
      });
  }

  confirm(): void {
    const folders = this.folder?.selection?.folders ?? [];
    const files = this.totalSelection;

    this.dialogRef.close({
      folders,
      files,
    });
  }

  uploadFromInput(event: Event): void {
    this.uService.uploadFiles((event?.target as HTMLInputElement)?.files, null, this.data.splitPdf);
  }

  uploadFromDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const entries = UploadService.getEntriesFromDragEvent(event);

    void this.uService.uploadFromDragEvent(entries, null, this.data.splitPdf);
  }

  get selection(): ApexFile[] {
    return this.uploadFolderFiles?.data ? this.uploadFolderFiles.data.filter((f) => f.selected) : [];
  }

  get totalSelection(): ApexFile[] {
    return [].concat(this.folder?.selection?.files ?? [], this.selection ?? []);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
