<ng-container *ngIf="!fileUsageViewerData?.hideHeader">
  <header class="df c">
    <ng-container *ngIf="!fileUsageViewerData?.hideHeaderTitle">
      <div class="df ac w-100 title">
        <h3 class="mat-h2 rm truncate f1">
          <T
            str="No file"
            *ngIf="!fileUsage?.fileName"></T>

          <ng-container *ngIf="fileUsage?.fileName">
            {{ fileUsage.fileName }}
          </ng-container>
        </h3>
        <div>
          <button
            type="button"
            [disabled]="downloading"
            mat-icon-button
            [matTooltip]="downloadTooltip"
            (click)="downloadFileUsage(fileUsage, fileViewer?.imageViewer?.image, fileViewer?.imageViewer?.box)">
            <mat-icon>download</mat-icon>
          </button>
          <button
            type="button"
            [matTooltip]="tooltipForEditLocked"
            *ngIf="fileUsageViewerData?.editable"
            mat-icon-button
            (click)="fileUsageViewerData.editLocked = !fileUsageViewerData.editLocked">
            <mat-icon>{{ fileUsageViewerData.editLocked ? 'lock' : 'lock_open' }}</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!fileUsageViewerData?.hideHeaderMeta">
      <div class="df w ac jsb w-100">
        <div class="mat-caption gray-color fileusage-info">
          <ng-container *ngIf="fileUsage">
            <!-- @todo fix this -->
            <T [str]="fileUsage.self | titlecase"></T>
            /
            <T [str]="fileUsage.name | titlecase"></T>
            <apex-autocomplete-viewer
              *ngIf="!fileUsageViewerData?.client && fileUsage.self && fileUsage.selfId"
              [type]="selfToAutocompleteType(fileUsage.self)"
              [id]="fileUsage.selfId">
            </apex-autocomplete-viewer>
          </ng-container>
          <span *ngIf="!fileUsage">
            <T str="No file"></T>
          </span>
        </div>
        <div
          *ngIf="fileUsageViewerData?.editable"
          class="df ac">
          <h5 class="mat-h4 marking-total rml d-apl t-apl amrs amvs">
            <T str="Markings"></T>
            : {{ getTotalMarkings() }}
          </h5>
          <h5
            class="mat-h4 marking-min amhs amvs"
            *ngIf="fileUsageViewerData.markingData && fileUsageViewerData.markingData.min"
            [ngClass]="fileUsageViewerData.markingData.min > getTotalMarkings() ? 'invalid' : 'valid'">
            <T str="Min"></T>
            :
            {{ fileUsageViewerData.markingData.min }}
          </h5>
          <h5
            class="mat-h4 marking-max amhs amvs"
            *ngIf="fileUsageViewerData.markingData && fileUsageViewerData.markingData.max">
            <T str="Max"></T>
            :
            {{ fileUsageViewerData.markingData.max || '&infin;' }}
          </h5>
        </div>
      </div>
    </ng-container>
  </header>
</ng-container>
<main class="df c f1 oh">
  <div
    *ngIf="loadingMarkings"
    class="f1 df ac jc">
    <mat-spinner></mat-spinner>
  </div>
  <apex-file-viewer
    #fileViewer
    *ngIf="!loadingMarkings"
    [file]="fileUsage?.File"
    [fileUsage]="fileUsage"
    [edit]="fileUsageViewerData.editable && !fileUsageViewerData.editLocked"
    [markings]="fileUsage?.Markings"
    [highlightedMarkingIds]="fileUsageViewerData?.markingData?.highlightedMarkingIds"
    [highlightedMarkingModelIds]="fileUsageViewerData?.markingData?.highlightedMarkingModelIds"
    (markingCreate)="createMarking($event)"
    (markingChange)="updateMarking($event)"
    (markingClicked)="deleteMarking($event)"
    (markingEnter)="markingEnter.emit($event)"
    (markingLeave)="markingLeave.emit($event)">
  </apex-file-viewer>
  <div
    *ngIf="
      !loadingMarkings && fileUsage && fileUsageViewerData?.fileUsages && fileUsageViewerData.fileUsages.length > 1
    "
    class="preview-list df c ac">
    <button
      mat-icon-button
      (click)="toggleFileUsagePreviews()">
      <mat-icon>
        {{ fileUsageViewerData.hidePreviews ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
      </mat-icon>
    </button>
    <apex-file-usage-preview
      class="w-100"
      [showCaption]="true"
      [ngClass]="{ hidden: fileUsageViewerData?.hidePreviews }"
      [fileUsages]="fileUsageViewerData?.fileUsages"
      [displayedImages]="screenWidth > 600 ? 5 : 3"
      fit="contain"
      [selectedIndex]="fileUsageViewerData.startingIndex ? fileUsageViewerData.startingIndex : 0"
      (selectedIndexChanged)="
        getFileUsageRequest$.next(fileUsageViewerData.fileUsages[$event]); selectedIndexChanged.emit($event)
      ">
    </apex-file-usage-preview>
  </div>
</main>
