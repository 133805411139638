import { Injectable } from '@angular/core';
import { HttpService } from 'projects/apex/src/app/services/http/http.service';
import { Observable } from 'rxjs';
import { TextUsage } from '../../models/text-usage';

@Injectable()
export class TextUsageService extends HttpService<TextUsage> {
  route = 'text-usage';

  all(model: string, modelId: number, name: string): Observable<TextUsage[]> {
    return this.http.get<TextUsage[]>(`${this.apiUrl}/${this.route}/${model}/${modelId}/${name}`, this.options);
  }

  save(tu: TextUsage): Observable<TextUsage> {
    return this.http.post<TextUsage>(this.url(tu), tu, this.options);
  }

  delete(tu: TextUsage, deleteText = false): Observable<TextUsage> {
    const options = Object.assign(this.options, { params: { deleteText } });

    return this.http.delete<null>(this.url(tu), options);
  }

  protected url(tu: TextUsage): string {
    return tu.id
      ? `${this.apiUrl}/${this.route}/${tu.model}/${tu.modelId}/${tu.name}/${tu.TextId}`
      : `${this.apiUrl}/${this.route}/${tu.model}/${tu.modelId}/${tu.name}`;
  }
}
