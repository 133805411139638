<mat-card
  appearance="raised"
  class="user-card remove-margin oh">
  <div class="position-absolute top-0 right-0 aps">
    <button
      *ngIf="close"
      color="primary"
      (click)="close()"
      type="button"
      mat-icon-button
      [matTooltip]="'Close' | translate">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-card-header>
    <div mat-card-avatar>
      <apex-avatar
        class="aprs"
        [user]="user"
        size="medium">
      </apex-avatar>
    </div>

    <mat-card-title
      title
      class="truncate">
      {{ user.name }}
    </mat-card-title>

    <mat-card-subtitle *ngIf="Company">
      <a
        [class.text-italic]="client"
        [routerLink]="['/company', Company.id]">
        {{ Company.name }}
      </a>
    </mat-card-subtitle>
  </mat-card-header>

  <div class="add-margin-horizontal">
    <mat-divider></mat-divider>
  </div>

  <mat-card-content class="f1">
    <div class="display-grid apt">
      <apex-title-item
        *ngIf="subtitle"
        [icon]="subtitleIcon">
        <T
          title
          str="Status"></T>

        <span class="mat-caption text-italic">
          {{ subtitle }}
        </span>
      </apex-title-item>

      <apex-title-item
        *ngIf="user?.address && user?.zip && user?.city"
        [showTitle]="showTitle"
        [iconTooltip]="'Address' | translate"
        icon="location_on">
        <T
          title
          str="Address"></T>
        {{ user.address }}, {{ user.zip }} {{ user.city }}
      </apex-title-item>

      <apex-title-item
        *ngIf="user?.mail"
        [showTitle]="showTitle"
        [iconTooltip]="'Send Email' | translate"
        icon="mail">
        <T
          title
          str="Email">
        </T>
        {{ user | propertyOrNone: 'mail' : ('Not set' | translate) }}
      </apex-title-item>

      <apex-title-item
        *ngIf="user?.mobile"
        [showTitle]="showTitle"
        [iconTooltip]="'Call' | translate"
        icon="phone">
        <T
          title
          str="Mobile">
        </T>
        {{ user | propertyOrNone: 'mobile' : ('Not set' | translate) }}
      </apex-title-item>

      <apex-title-item
        *ngIf="user?.dateOfBirth && !user?.organizationalNumber"
        [showTitle]="showTitle"
        [iconTooltip]="'Date of birth' | translate"
        icon="cake">
        <T
          title
          str="Date of birth">
        </T>
        {{ user.dateOfBirth | dater | date: 'shortDate' }}
      </apex-title-item>

      <apex-title-item
        *ngIf="user?.organizationalNumber"
        [showTitle]="showTitle"
        [iconTooltip]="'Organizational Number' | translate"
        icon="pin">
        <T
          title
          str="Organizational Number">
        </T>
        {{ user.organizationalNumber }}
      </apex-title-item>

      <apex-title-item
        *ngIf="user?.present"
        [showTitle]="showTitle"
        [iconTooltip]="'Present' | translate"
        icon="co_present ">
        <ng-container *ngIf="user.present">
          <T str="Yes"></T>
        </ng-container>
      </apex-title-item>
    </div>
  </mat-card-content>

  <mat-card-actions
    class="user-card-button"
    *ngIf="!hideButtons">
    <div>
      <a
        *ngIf="user?.mobile"
        mat-icon-button
        [href]="'tel:' + user.mobile"
        [matTooltip]="'Call' | translate"
        (click)="$event.stopPropagation()">
        <mat-icon>phone</mat-icon>
      </a>

      <a
        *ngIf="user?.mail"
        mat-icon-button
        [href]="'mailto:' + user.mail"
        [matTooltip]="'Send Email' | translate"
        (click)="$event.stopPropagation()">
        <mat-icon>mail</mat-icon>
      </a>
    </div>

    <div></div>

    <a
      class="no-client"
      mat-raised-button
      color="primary"
      *ngIf="!client && CompanyId && user?.id"
      [matTooltip]="'Go to user {name}' | translate: { name: user.name }"
      [routerLink]="['/company', CompanyId, 'user', user.id]">
      <T
        str="Open"
        _context="action"
        context="action"></T>
    </a>

    <a
      class="client"
      mat-raised-button
      color="primary"
      *ngIf="client && clientUrl && CompanyId"
      [matTooltip]="'Go to client {name}' | translate: { name: user.name }"
      [routerLink]="clientUrl">
      <T
        str="Open"
        _context="action"
        context="action"></T>
    </a>
  </mat-card-actions>
</mat-card>
