import { Pipe, PipeTransform } from '@angular/core';
import { Contractor } from '../../../models/case';
import { CaseContractorStatus } from '../../../models/case-contractor';

@Pipe({
  name: 'isCasePending',
  standalone: true,
})
export default class IsCasePendingPipe implements PipeTransform {
  transform(contractors?: Contractor[]): boolean {
    return contractors?.filter((cc) => cc?.CaseContractor?.status === CaseContractorStatus.New).length > 0;
  }
}
