import Dexie, { Transaction } from 'dexie';
import { Case } from '../../models/case';
import { CaseCategory } from '../../models/case-category';
import { ChecklistGroupTemplate } from '../../models/checklist-group-template';
import { Field } from '../../models/field';
import { File } from '../../models/file';
import { FileUsage } from '../../models/file-usage';
import { Inspection } from '../../models/inspection';
import { ObjectField } from '../../models/object-field';
import { Profile } from '../../models/profile';
import { Project } from '../../models/project';
import { Tag } from '../../models/tag';
import { User } from '../../models/user';
import {
  ChecklistTemplate,
  ChecklistTemplateItem,
  ChecklistTemplateSection,
} from '../checklist-template/checklist-template.model';
import { Obj } from '../object/object.model';

export class InspectionDexie extends Dexie {
  public CaseCategories: Dexie.Table<CaseCategory, number>; // id is number in this case
  public Projects: Dexie.Table<Project, number>; // id is number in this case
  public Objects: Dexie.Table<Obj, number>; // id is number in this case
  public Cases: Dexie.Table<Case, number>; // id is number in this case
  public Inspections: Dexie.Table<Inspection, number>; // id is number in this case
  public Files: Dexie.Table<File, number>; // id is number in this case
  public FileUsages: Dexie.Table<FileUsage, number>; // id is number in this case
  public Profile: Dexie.Table<Profile, number>; // id is number in this case
  public Users: Dexie.Table<User, number>; // id is number in this case
  public Clients: Dexie.Table<User, number>; // id is number in this case
  public Fields: Dexie.Table<Field, number>; // id is number in this case
  public ChecklistGroupTemplates: Dexie.Table<ChecklistGroupTemplate, number>; // id is number in this case
  public ChecklistTemplates: Dexie.Table<ChecklistTemplate, number>; // id is number in this case
  public ChecklistTemplateSections: Dexie.Table<ChecklistTemplateSection, number>; // id is number in this case
  public ChecklistTemplateItems: Dexie.Table<ChecklistTemplateItem, number>; // id is number in this case
  public ObjectFields: Dexie.Table<ObjectField, number>;
  public Tags: Dexie.Table<Tag, number>;

  /*  WARNING DO NOT EDIT WITHOUT UPDATING VERSION!! */

  public constructor() {
    super('Inspection');
    this.version(1).stores({
      CaseCategories: 'id,name',
      Projects: 'id,name',
      Objects: 'id,name',
      Cases: '++id, InspectionId',
      Inspections: '++id',
      Files: '++id, path',
      FileUsages: '++id, model, modelId, File',
      Profile: 'id',
    });

    this.version(2)
      .stores({
        CaseCategories: 'id,name',
        Projects: 'id,name',
        Objects: 'id,name',
        Cases: '++id, InspectionId',
        Inspections: '++id',
        Files: '++id, path',
        FileUsages: '++id, model, modelId, File',
        Profile: 'id',
        Users: 'id',
        Clients: 'id',
        Fields: 'id',
      })
      .upgrade((trans: Transaction) => {
        const inspections = trans.table('Inspections');

        return inspections.toCollection().modify((inspection: Inspection) => {
          if (!inspection.data.caseIds && inspection.data.Cases?.length) {
            inspection.data.caseIds = inspection.data.Cases.map((c) => c.id);
          }
        });
      });

    this.version(3)
      .stores({
        CaseCategories: 'id,name',
        Projects: 'id,name',
        Objects: 'id,name',
        Cases: '++id, InspectionId',
        Inspections: '++id',
        Files: '++id, path',
        FileUsages: '++id, self, selfId, name, File',
        Profile: 'id',
        Users: 'id',
        Clients: 'id',
        Fields: 'id',
      })
      .upgrade((trans: Transaction) => {
        const inspections = trans.table('Inspections');

        return inspections.toCollection().modify((inspection: Inspection) => {
          if (!inspection.data.caseIds && inspection.data.Cases?.length) {
            inspection.data.caseIds = inspection.data.Cases.map((c) => c.id);
          }
        });
      });

    this.version(4)
      .stores({
        CaseCategories: 'id,name',
        Projects: 'id,name',
        Objects: 'id,name',
        Cases: '++id, InspectionId',
        Inspections: '++id',
        Files: '++id, path',
        FileUsages: '++id, self, selfId, name, File',
        Profile: 'id',
        Users: 'id',
        Clients: 'id',
        Fields: 'id',
        ChecklistGroupTemplates: 'id',
        ChecklistTemplates: 'id',
        ChecklistTemplateSections: 'id',
        ChecklistTemplateItems: 'id',
      })
      .upgrade((trans: Transaction) => {
        const inspections = trans.table('Inspections');

        return inspections.toCollection().modify((inspection: Inspection) => {
          if (!inspection.data.caseIds && inspection.data.Cases?.length) {
            inspection.data.caseIds = inspection.data.Cases.map((c) => c.id);
          }
        });
      });

    this.version(5)
      .stores({
        CaseCategories: 'id,name',
        Projects: 'id,name',
        Objects: 'id,name',
        Cases: '++id, InspectionId',
        Inspections: '++id',
        Files: '++id, path',
        FileUsages: '++id, self, selfId, name, File',
        Profile: 'id',
        Users: 'id',
        Clients: 'id',
        Fields: 'id',
        ObjectFields: 'id',
        ChecklistGroupTemplates: 'id',
        ChecklistTemplates: 'id',
        ChecklistTemplateSections: 'id',
        ChecklistTemplateItems: 'id',
      })
      .upgrade((trans: Transaction) => {
        const inspections = trans.table('Inspections');

        return inspections.toCollection().modify((inspection: Inspection) => {
          if (!inspection.data.caseIds && inspection.data.Cases?.length) {
            inspection.data.caseIds = inspection.data.Cases.map((c) => c.id);
          }
        });
      });

    this.version(6)
      .stores({
        CaseCategories: 'id,name',
        Projects: 'id,name',
        Objects: 'id,name',
        Cases: '++id, InspectionId',
        Inspections: '++id',
        Files: '++id, path',
        FileUsages: '++id, self, selfId, name, File',
        Profile: 'id',
        Users: 'id',
        Clients: 'id',
        Fields: 'id',
        ObjectFields: 'id',
        ChecklistGroupTemplates: 'id',
        ChecklistTemplates: 'id',
        ChecklistTemplateSections: 'id',
        ChecklistTemplateItems: 'id',
        Tags: 'id',
      })
      .upgrade((trans: Transaction) => {
        const inspections = trans.table('Inspections');

        return inspections.toCollection().modify((inspection: Inspection) => {
          if (!inspection.data.caseIds && inspection.data.Cases?.length) {
            inspection.data.caseIds = inspection.data.Cases.map((c) => c.id);
          }
        });
      });

    /*  WARNING DO NOT EDIT WITHOUT UPDATING VERSION!! */

    this.CaseCategories = this.table('CaseCategories');
    this.Projects = this.table('Projects');
    this.Objects = this.table('Objects');
    this.Cases = this.table('Cases');
    this.Inspections = this.table('Inspections');
    this.Files = this.table('Files');
    this.FileUsages = this.table('FileUsages');
    this.Profile = this.table('Profile');
    this.Users = this.table('Users');
    this.Clients = this.table('Clients');
    this.Fields = this.table('Fields');
    this.ChecklistGroupTemplates = this.table('ChecklistGroupTemplates');
    this.ChecklistTemplates = this.table('ChecklistTemplates');
    this.ChecklistTemplateSections = this.table('ChecklistTemplateSections');
    this.ChecklistTemplateItems = this.table('ChecklistTemplateItems');
    this.ObjectFields = this.table('ObjectFields');
    this.Tags = this.table('Tags');
  }
}
