import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { MeterService, MeterValueService } from './meter.service';
import { MeterValueFormComponent } from './value-form.component';

@NgModule({
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, TranslateModule],
  exports: [MeterValueFormComponent],
  declarations: [MeterValueFormComponent],
  providers: [MeterService, MeterValueService],
})
export class MeterValueModule {}
