<div mat-dialog-title>
  <div class="df ac jsb f1">
    <div class="df ac">
      <T
        class="amrs"
        str="Folders"></T>
    </div>
    <div>
      <button
        mat-icon-button
        (click)="ref.close()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
</div>
<mat-dialog-content class="df c">
  <apex-folder-tree></apex-folder-tree>
</mat-dialog-content>
<mat-dialog-actions>
  <div></div>
  <div>
    <button
      mat-button
      (click)="ref.close()">
      <T str="Cancel"></T>
    </button>
    <button
      mat-raised-button
      [disabled]="disabled"
      (click)="confirm()"
      color="primary">
      <T str="Move"></T>
    </button>
  </div>
</mat-dialog-actions>
