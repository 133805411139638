import { Directive, HostListener, Input } from '@angular/core';
import { trackEvent } from '../../utils/analytics';
import { AnalyticsClickData } from './analytics-click.directive.type';

/**
 * Tracks click on the host element.
 */
@Directive({
  selector: '[apexAnalyticsClick]',
  standalone: true,
})
export class AnalyticsClickDirective {
  @Input()
  apexAnalyticsClick: AnalyticsClickData;

  @HostListener('click')
  onHostClick(): void {
    if (!this.apexAnalyticsClick) {
      return;
    }

    trackEvent(
      this.apexAnalyticsClick.eventCategory,
      this.apexAnalyticsClick.eventAction,
      this.apexAnalyticsClick.eventLabel,
      this.apexAnalyticsClick.eventValue,
    );
  }
}
