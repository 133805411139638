import { Pipe, PipeTransform } from '@angular/core';
import { Contractor } from '../../../models/case';
import { CaseContractorStatus } from '../../../models/case-contractor';

@Pipe({
  name: 'isCaseDeclined',
  standalone: true,
})
export default class IsCaseDeclinedPipe implements PipeTransform {
  transform(contractors?: Contractor[]): boolean {
    return contractors?.filter((cc) => cc?.CaseContractor.status === CaseContractorStatus.Declined).length > 0;
  }
}
