import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { AutoFocusDirectiveModule } from '../../directives/auto-focus/auto-focus..directive.module';
import { ChecklistGroupTemplateService } from '../../features/checklist-group-template/checklist-group-template.service';
import { ChecklistGroupService } from '../../features/checklist-group/checklist-group.service';
import { ChecklistTemplateService } from '../../features/checklist-template/checklist-template.service';
import { ChecklistService } from '../../features/checklist/checklist.service';
import { ObjectSelectorModule } from '../../features/object/components/selector/object-selector.module';
import { AutocompletePickerModule } from '../autocomplete-picker/autocomplete-picker.module';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { ChecklistDialogComponent } from './checklist-dialog.component';
import { ChecklistDialogDirective, ChecklistGroupDialogDirective } from './checklist-dialog.directive';

@NgModule({
  declarations: [ChecklistDialogComponent, ChecklistDialogDirective, ChecklistGroupDialogDirective],
  imports: [
    CommonModule,
    FormsModule,
    AutocompletePickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    AutocompleteModule,
    MatInputModule,
    ObjectSelectorModule,
    MatSelectModule,
    AutoFocusDirectiveModule,
  ],
  providers: [
    ChecklistTemplateService,
    ChecklistGroupTemplateService,
    ChecklistService,
    ChecklistGroupService,
    ChecklistDialogDirective,
    ChecklistGroupDialogDirective,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
  exports: [ChecklistDialogComponent, ChecklistDialogDirective, ChecklistGroupDialogDirective],
})
export class ChecklistDialogModule {}
