import { Component, Input, OnChanges } from '@angular/core';
import { orderBy } from 'lodash-es';
import {
  ChecklistTemplate,
  ChecklistTemplateItem,
  ChecklistTemplateSection,
} from 'projects/apex/src/app/features/checklist-template/checklist-template.model';

@Component({
  selector: 'apex-checklist-section-preview',
  templateUrl: './checklist-section-preview.component.html',
})
export class ChecklistSectionPreviewComponent implements OnChanges {
  @Input() checklistTemplate: ChecklistTemplate;
  @Input() checklistTemplateItems: ChecklistTemplateItem[] = [];
  @Input() checklistTemplateSections: ChecklistTemplateSection[] = [];

  @Input() section: ChecklistTemplateSection;
  @Input() step: string;

  items: ChecklistTemplateItem[] = [];
  sections: ChecklistTemplateSection[] = [];

  expanded = true;

  ngOnChanges(): void {
    this.filter();
  }

  filter(): void {
    this.items = orderBy(
      this.checklistTemplateItems.filter((i) => i.SectionId === (this.section?.id || null)),
      [(c): number => c.sortOrder, (c): number => c.id],
      ['asc', 'desc'],
    );
    this.sections = orderBy(
      this.checklistTemplateSections.filter((s) => s.ParentId === (this.section?.id || null)),
      [(c): number => c.sortOrder, (c): number => c.id],
      ['asc', 'desc'],
    );
  }
}
