import { HttpErrorResponse } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar, MatSnackBarDismiss, MatSnackBarModule } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { t } from '../components/translate/translate.function';

export interface SnackData {
  msg?: string;
  err?: Error;
  dur?: number;
  act?: { msg: string; fn: () => void };
}

export const snack = (msg: string, ext?: SnackData): void => {
  ext = ext ? ext : ({} as SnackData);
  ext.msg = msg;
  snackSubject.next(ext);
};

export const snackAct = (msg: string, act: { msg: string; fn: () => void }, ext?: SnackData): void => {
  ext = ext ? ext : ({} as SnackData);
  ext.act = act;
  snack(msg, ext);
};

export const snackErr = (msg: string, err: Error, ext?: SnackData): void => {
  ext = ext ? ext : ({} as SnackData);
  ext.err = err;
  snack(msg, ext);
};

const snackSubject = new Subject<SnackData>();

@NgModule({
  imports: [MatSnackBarModule, MatButtonModule],
})
export class SnackModule {
  constructor(private s: MatSnackBar) {
    snackSubject.pipe(concatMap((d) => this.openSnack(d))).subscribe();
  }

  openSnack(data: SnackData): Observable<MatSnackBarDismiss> {
    let errMsg = '';

    if (data.err && data.err instanceof HttpErrorResponse) {
      errMsg = data.err.error?.message ?? '';
    } else if (data.err && data.err instanceof Error) {
      errMsg = data.err.message ?? '';
    }

    const msg = errMsg ? `${data.msg}${errMsg ? ':' : ''} ${errMsg ?? ''}` : data.msg;

    const ref = this.s.open(msg, data?.act?.msg ? data.act.msg : t('Close'), {
      duration: data?.dur ? data.dur : 2000,
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
    });

    ref
      .onAction()
      .pipe(take(1))
      .subscribe({
        next: () => {
          data?.act?.fn();
        },
      });

    return ref.afterDismissed();
  }
}
