import { registerLocaleData } from '@angular/common';
import da from '@angular/common/locales/da';
import de from '@angular/common/locales/de';
import el from '@angular/common/locales/el';
import en from '@angular/common/locales/en';
import et from '@angular/common/locales/et';
import lt from '@angular/common/locales/lt';
import lv from '@angular/common/locales/lv';
import nb from '@angular/common/locales/nb';
import pl from '@angular/common/locales/pl';
import pt from '@angular/common/locales/pt';
import sq from '@angular/common/locales/sq';
import sv from '@angular/common/locales/sv';
import moment from 'moment';
import { localePreLoader } from '../components/translate/locale-pre-loader';
import { fallbackLocale } from './fallback-language';

export const supportedLocales = {
  nb,
  en,
  lt,
  pl,
  lv,
  pt,
  sv,
  da,
  de,
  el,
  et,
  sq,
};

// @todo Use this once we can get rid of locale in localStorage
export type SupportedLocale = keyof typeof supportedLocales;

export const localeInitializer = async (localeId: string): Promise<void> => {
  const supported = supportedLocales[localeId];

  if (!supported) {
    console.error(`Unsupported locale "${localeId}"`);

    localeId = fallbackLocale;
  }

  // Register locale with Angular
  registerLocaleData(supportedLocales[localeId]);

  // Register locale with Moment
  moment.locale(localeId);

  // Optimistic loading of locale from Transifex
  await localePreLoader(localeId);
};

export const setAngularLocaleData = (localeId: string): void => {
  const supported = supportedLocales[localeId];

  if (!supported) {
    console.error(`Unsupported locale "${localeId}"`);

    localeId = fallbackLocale;
  }

  registerLocaleData(supportedLocales[localeId]);
};
