import { CdkDropList } from '@angular/cdk/drag-drop';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { ConfirmDialogComponent } from 'projects/apex/src/app/components/confirm-dialog/confirm-dialog.component';
import { FileService } from 'projects/apex/src/app/components/file-usage/file.service';
import { FileViewerDialogComponent } from 'projects/apex/src/app/components/file-viewer/dialog.component';
import { DragoverType, FolderTreeDialogData } from 'projects/apex/src/app/components/folder/folder.types';
import { FolderShareComponent } from 'projects/apex/src/app/components/folder/share/share.component';
import { FolderTreeDialogComponent } from 'projects/apex/src/app/components/folder/tree/dialog.component';
import { FolderTreeComponent } from 'projects/apex/src/app/components/folder/tree/tree.component';
import { InputDialogComponent } from 'projects/apex/src/app/components/input-dialog/input-dialog.component';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { UploadService } from 'projects/apex/src/app/components/upload/upload.service';
import { UploadData } from 'projects/apex/src/app/components/upload/upload.types';
import { File as ApexFile } from 'projects/apex/src/app/models/file';
import { Folder } from 'projects/apex/src/app/models/folder';
import { snack, snackErr } from 'projects/apex/src/app/modules/snack.module';
import { FolderService } from 'projects/apex/src/app/services/folder/folder.service';
import { HttpExtra } from 'projects/apex/src/app/services/http/http-extra';
import { sortNatural, urlExpired } from 'projects/apex/src/app/utils/functions';
import { Observable, Subject, Subscription, forkJoin, merge, of, timer } from 'rxjs';
import { catchError, finalize, map, mergeMap, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { CustomerService } from '../../features/customer/customer.service';
import { Profile } from '../../models/profile';
import { presentDownloadURL, unsafeAdd } from '../../utils/functions';
import { isTypeImage, openPinturaEditor } from '../../utils/pintura/pintura';
import { CollectionResponse } from '../../utils/types';
import { folderIcon } from './folder.utils';

@Component({
  selector: 'apex-folder',
  templateUrl: './folder.component.html',
})
export class FolderComponent implements OnInit, OnDestroy {
  @Input() view: 'list' | 'grid' = 'grid';
  @Input() selectedTabIndex: number;
  @Input() splitPdf?: 'ask' | 'never' | 'always';

  @Output() idChange = new EventEmitter<string>();
  @Output() folderChange = new EventEmitter<Folder>();

  @Input()
  get id(): string {
    return this.idValue;
  }

  set id(id: string) {
    id = id?.toString();

    if (!id || this.id !== id) {
      this.getFolderRequest$.next(id);
    }

    this.idValue = id;
    this.idChange.emit(this.id);
  }

  @ViewChild('fileUploadInput') fileUploadInput: ElementRef<HTMLInputElement>;
  @ViewChild('newImageUploadInput') newImageUploadInput: ElementRef<HTMLInputElement>;
  @ViewChild('folderTable') folderTable: MatTable<Folder>;
  @ViewChild('fileTable') fileTable: MatTable<File>;
  @ViewChild(FolderTreeComponent) tree: FolderTreeComponent;
  @ViewChild(MatSort) matSort: MatSort;
  @ViewChildren(CdkDropList) dropListElements: QueryList<CdkDropList>;

  DragoverType = DragoverType;

  idValue: string;

  folder: Folder;
  profile: Profile;

  displayedColumns: string[] = ['selected', 'name', 'updatedAt', 'size', 'more'];
  sort: Sort = {
    active: 'name',
    direction: 'asc',
  };

  loading: boolean;
  error: boolean;
  search: boolean;
  selectionMode: boolean;
  isWeb: boolean;

  loadingMoreFolders = false;
  loadingMoreFiles = false;

  childrenPage = 0;
  childrenCount = 0;

  filesPage = 0;
  filesCount = 0;

  getFolderLoadingSub: Subscription;
  getFolderRequest$ = new Subject<string>();
  getFolderResponse$ = this.getFolderRequest$.pipe(
    switchMap((id: string) => {
      this.error = false;

      if (this.folder) {
        this.getFolderLoadingSub = timer(500).subscribe(() => {
          if (
            this.folder &&
            (this.folder.id && this.folder.modelName !== 'home' ? this.folder.id.toString() : this.folder.modelName) !==
              id
          ) {
            this.loading = true;
          }
        });
      }

      return forkJoin({
        folder: this.folderService.get(id ? id : 'home').pipe(map((e) => e.Entity)),
        children: this.folderService.children(id ? id : 'home', { params: this.params }),
        files: this.folderService.files(id ? id : 'home', { params: this.params } as HttpExtra),
      }).pipe(catchError(() => of(null)));
    }),
    catchError(() => of(null)),
  );
  getFolderSub$ = this.getFolderResponse$.subscribe({
    next: (res: { folder: Folder; children: CollectionResponse<Folder>; files: CollectionResponse<ApexFile> }) => {
      this.loading = false;
      this.getFolderLoadingSub?.unsubscribe();

      if (res?.folder) {
        this.folder = res.folder;
        this.folderChange.emit(this.folder);

        if (res.children) {
          this.childrenCount = res.children.count ?? 0;
          this.childrenPage = 0;
          this.folder.Children = res.children.Collection ?? [];
        }

        if (res.files) {
          this.filesCount = res.files.count ?? 0;
          this.filesPage = 0;
          this.folder.Files = res.files.Collection ?? [];
        }

        this.sortData();
      } else {
        this.error = true;
      }
    },
  });

  private readonly localStorageViewName = 'folderView';

  constructor(
    public el: ElementRef,
    private uService: UploadService,
    private folderService: FolderService,
    private fileService: FileService,
    private dialog: MatDialog,
    private platform: Platform,
    private bpObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private customerService: CustomerService,
  ) {}

  folderName(folder: Folder): string {
    if (folder.modelName && !folder.modelId) {
      if (folder.modelName === 'mom') {
        if (folder.ParentId === this.folder.id && this.folder.modelName === 'project') {
          return t('Common MOM');
        }
      }

      return t(folder.name);
    }

    if (folder.modelName === 'system-copied') {
      return t('System Copied');
    }

    return folder.name;
  }

  folderIcon(folder: Folder): string {
    return folderIcon(folder, this.profile);
  }

  folderIconTooltip(folder: Folder): string {
    const icon = this.folderIcon(folder);

    if (icon === 'topic') {
      if (!folder.hasChildren && !folder.hasFiles) {
        return t('System folder - empty');
      }

      return t('System folder');
    }

    if (icon === 'folder_shared') {
      if (!folder.hasChildren && !folder.hasFiles) {
        return t('Shared folder - empty');
      }

      return t('Shared folder');
    }

    if (icon === 'snippet_folder') {
      if (this.profile?.Customer?.projectTemplateFolder === folder.id) {
        if (!folder.hasChildren && !folder.hasFiles) {
          return t('Project template folder - empty');
        }

        return t('Project template folder');
      }

      if (this.profile?.Customer?.projectEntityTemplateFolder === folder.id) {
        if (!folder.hasChildren && !folder.hasFiles) {
          return t('Project entity template folder - empty');
        }

        return t('Project entity template folder');
      }
    }

    if (!folder.hasChildren && !folder.hasFiles) {
      return t('Folder - empty');
    }

    return t('Folder');
  }

  ngOnInit(): void {
    this.profile = this.route?.snapshot?.data?.profile;

    const view = localStorage.getItem(this.localStorageViewName) as 'list' | 'grid';

    if (view) {
      this.view = view;
    }

    this.isWeb =
      this.bpObserver.isMatched([Breakpoints.Web, Breakpoints.WebLandscape, Breakpoints.WebPortrait]) &&
      !this.platform.ANDROID &&
      !this.platform.IOS;

    if (!this.isWeb) {
      this.displayedColumns = ['selected', 'name', 'more'];
    }

    this.uService.complete.subscribe({
      next: (data: UploadData) => {
        if (data.FolderId === this.folder?.id) {
          if (data.File) {
            if (!this.folder.Files.some((f) => f.id === data.File.id)) {
              this.filesCount = this.filesCount + 1;
              this.folder.Files.push(data.File);
            }
          }

          if (data.Folder) {
            if (!this.folder.Children.some((f) => f.id === data.Folder.id)) {
              this.childrenCount = this.childrenCount + 1;
              this.folder.Children.push(data.Folder);
            }
          }

          this.refreshList();
        }
      },
    });

    if (!this.id) {
      this.id = 'home';
    }
  }

  setNewFolderId(id: number | string): void {
    this.id = id.toString();
  }

  setView(view: 'grid' | 'list'): void {
    this.view = view;
    localStorage.setItem(this.localStorageViewName, view);
  }

  newFolder(folder?: Folder): void {
    if (folder || this.folder) {
      this.rename(this.getNewFolderName())
        .pipe(
          mergeMap((name: string) =>
            this.folderService.save(
              new Folder({
                name,
                ParentId: folder?.id ? folder.id : this.folder.id,
              }),
            ),
          ),
          map((e) => e.Entity),
        )
        .subscribe({
          next: (f: Folder) => {
            this.tree.updateFolder(f);

            if (f.ParentId === this.folder.id) {
              if (this.folder?.Children?.find((c) => c.name === f.name)) {
                snack(`Folder named ${f.name} already exists`);
              } else {
                this.childrenCount = this.childrenCount + 1;
                this.folder.Children.push(f);
                this.tree.addFolder(f);
                this.refreshList();
              }
            }
          },
        });
    }
  }

  getNewFolderName(loopCount?: number): string {
    const newFolderName = t('New folder');
    const name = `${newFolderName}${loopCount ? ` (${loopCount})` : ''}`;

    loopCount = loopCount ? loopCount : 0;

    const folder = this.folder.Children.find((f) => f.name === name);

    if (folder) {
      return this.getNewFolderName(loopCount + 1);
    }

    return name;
  }

  rename(value: string): Observable<string> {
    return this.dialog
      .open(InputDialogComponent, {
        data: {
          title: t('Rename'),
          value,
          invalidValues: this.folder.Children?.map((c) => c.name) ?? [],
        },
      })
      .afterClosed()
      .pipe(skipWhile((v) => !v));
  }

  renameFolder(folder: Folder): void {
    const renamedFolder = new Folder(folder);

    this.rename(renamedFolder.name)
      .pipe(
        mergeMap((value: string) => this.folderService.save(Object.assign(renamedFolder, { name: value }))),
        map((e) => e.Entity),
      )
      .subscribe({
        next: (f: Folder) => {
          this.tree.updateFolder(f);

          const child = this.folder.Children.find((c) => c.id === f.id);

          if (child) {
            child.name = f.name;
          } else if (this.folder.id === f.id) {
            this.folder.name = f.name;
          } else {
            snack(`Folder named ${f.name} already exists`);
          }
        },
      });
  }

  renameFile(file: ApexFile): void {
    this.rename(file.name)
      .pipe(mergeMap((value: string) => this.fileService.save(Object.assign(file, { name: value }))))
      .subscribe({
        next: (f: ApexFile) => {
          file = f;
        },
      });
  }

  deleteFolder(folder: Folder): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: t('Are you sure you want to delete this folder?'),
        },
      })
      .afterClosed()
      .pipe(
        skipWhile((v) => !v),
        mergeMap(() => this.folderService.delete(folder.id)),
        map(() => folder),
      )
      .subscribe({
        next: () => {
          this.tree.updateFolder(folder, true);

          if (this.folder.id === folder.id) {
            this.id = folder.ParentId.toString();
          } else {
            location.reload();
          }
        },
      });
  }

  deleteFile(file: ApexFile): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: t('Are you sure you want to delete this file?'),
        },
      })
      .afterClosed()
      .pipe(
        skipWhile((v) => !v),
        mergeMap(() => this.fileService.delete(file)),
      )
      .subscribe(() => location.reload());
  }

  downloadFile(file: ApexFile): void {
    if (!urlExpired(file.signed.url)) {
      presentDownloadURL(file.signed.url, file.name);
    } else {
      this.fileService
        .url(file.id)
        .pipe(take(1))
        .subscribe({
          next: (urls) => {
            if (urls.signed) {
              presentDownloadURL(urls.signed, file.name);
            }
          },
        });
    }
  }

  dropIntoFolder(target: Folder): void {
    if (target.modelName !== 'upload') {
      const folders = this.folder.Children.filter((c) => c.selected && this.canMoveFolderToFolder(c, target?.id));
      const files = this.folder.Files.filter((f) => f.selected && this.canMoveFileToFolder(f, target?.id));

      folders.forEach((f) => {
        f.ParentId = target.id;
      });
      files.forEach((f) => {
        f.FolderId = target.id;
      });

      let requests = [];

      requests = requests.concat(
        folders.map((selectedFolder) =>
          this.folderService.save(selectedFolder).pipe(
            map((e) => e.Entity),
            tap((f: Folder) => {
              if (f) {
                this.tree.updateFolder(f);

                if (f && f.ParentId !== this.folder.id) {
                  const folder = this.folder.Children.find((c) => c.id === f.id);

                  if (folder) {
                    this.childrenCount = this.childrenCount - 1;

                    const idx = this.folder.Children.indexOf(folder);

                    if (idx !== -1) {
                      this.folder.Children.splice(idx, 1);
                    }
                  }
                }

                this.refreshList();
              }
            }),
            catchError(() => null),
          ),
        ),
      );

      requests = requests.concat(
        files.map((selectedFile) =>
          this.fileService.save(selectedFile).pipe(
            tap((f: ApexFile) => {
              if (f && f.FolderId !== this.folder.id) {
                const file = this.folder.Files.find((fi) => fi.id === f.id);

                if (file) {
                  this.filesCount = this.filesCount - 1;

                  const idx = this.folder.Files.indexOf(file);

                  if (idx !== -1) {
                    this.folder.Files.splice(idx, 1);
                  }
                }
              }

              this.refreshList();
            }),
            catchError(() => null),
          ),
        ),
      );

      merge(...requests).subscribe();
    }
  }

  canMoveFolderToFolder(folder: Folder, id: string): boolean {
    return !!folder && !folder.modelName && id !== folder?.id && id !== folder.ParentId;
  }

  canMoveFileToFolder(file: ApexFile, id: string): boolean {
    return !!file && id !== file.FolderId;
  }

  refreshList(): void {
    if (this.view === 'list') {
      this.folderTable?.renderRows();
      this.fileTable?.renderRows();
    }
  }

  sortData(): void {
    const active = this.sort.direction ? this.sort.active : 'name';

    sortNatural(this.folder.Children ?? [], active);
    sortNatural(this.folder.Files ?? [], active);

    if (this.sort.direction === 'desc') {
      this.folder.Children?.reverse();
      this.folder.Files?.reverse();
    }

    this.refreshList();
  }

  openFile(file: ApexFile): void {
    this.dialog.open(FileViewerDialogComponent, { data: { file }, autoFocus: false });
  }

  toggleItem(f: { selected: boolean }): void {
    f.selected = !f.selected;

    if (!this.selection.folders.length && !this.selection.files.length) {
      this.selectionMode = false;
    }
  }

  resetSelection(): void {
    if (!this.isWeb) {
      this.folder.Files?.forEach((f) => {
        f.selected = false;
      });
      this.folder.Children?.forEach((f) => {
        f.selected = false;
      });
      this.selectionMode = false;
    }
  }

  setMatSort(name: string): void {
    if (this.sort.active === name) {
      switch (this.sort.direction) {
        case 'asc':
          this.sort.direction = 'desc';
          break;

        case 'desc':
          this.sort.direction = '';
          break;

        case '':
          this.sort.direction = 'asc';
          break;
      }
    } else {
      this.sort.active = name;
      this.sort.direction = 'asc';
    }

    (this.matSort?.sortables?.get(name) as MatSortHeader)?._setAnimationTransitionState({
      fromState: '',
      toState: 'active',
    });

    if (this.folder?.id) {
      this.getFolderRequest$.next(this.folder.id);
    }
  }

  uploadFromDragEvent(event: DragEvent, target: Folder): void {
    event.preventDefault();
    event.stopPropagation();

    if (target.modelName !== 'upload' && target.modelName !== 'home') {
      const entries = UploadService.getEntriesFromDragEvent(event);

      void this.uService.uploadFromDragEvent(entries, target);
    }
  }

  uploadFromInput(event: Event, target: Folder): void {
    if (target.modelName !== 'upload' && target.modelName !== 'home') {
      this.uService.uploadFiles((event?.target as HTMLInputElement)?.files, target, this.splitPdf);
    }
  }

  async uploadFromNewImageInput(event: Event, target: Folder): Promise<boolean> {
    if (target.modelName !== 'upload' && target.modelName !== 'home') {
      const files = (event.target as HTMLInputElement).files;
      const src = files.item(0);

      if (!src) {
        return false;
      }

      if (!isTypeImage(src)) {
        snackErr(t('Please select an image file'), new Error(`"${src.type}" is not a valid image`));

        return false;
      }

      const editedImage = await openPinturaEditor(src);

      if (editedImage) {
        this.uService.uploadFiles([editedImage], target, this.splitPdf);

        return true;
      }
    }

    return false;
  }

  shareFolder(folder: Folder): void {
    if (folder && !folder.modelName) {
      this.dialog.open(FolderShareComponent, {
        data: {
          folders: [folder],
          files: [],
        },
      });
    }
  }

  shareFile(file: ApexFile): void {
    this.dialog.open(FolderShareComponent, {
      data: {
        folders: [],
        files: [file],
      },
    });
  }

  moveFolder(folder: Folder): void {
    this.dialog
      .open(FolderTreeDialogComponent)
      .afterClosed()
      .subscribe({
        next: (result: FolderTreeDialogData) => {
          if (result?.folder) {
            if (this.canMoveFolderToFolder(folder, result.folder.id)) {
              const newFolder = Object.assign(folder, { ParentId: result.folder.id });

              this.folderService
                .save(newFolder)
                .pipe(map((e) => e.Entity))
                .subscribe({
                  next: (savedFolder: Folder) => {
                    if (savedFolder.id === this.folder.id) {
                      this.getFolderRequest$.next(this.folder.id);
                    } else if (savedFolder.ParentId !== this.folder.id) {
                      const idx = this.folder.Children.indexOf(folder);

                      if (idx !== -1) {
                        this.folder.Children.splice(idx, 1);
                      }
                    } else {
                      folder = savedFolder;
                    }
                  },
                });
            }
          }
        },
      });
  }

  moveFile(file: ApexFile): void {
    this.dialog
      .open(FolderTreeDialogComponent)
      .afterClosed()
      .subscribe({
        next: (result: FolderTreeDialogData) => {
          if (result?.folder) {
            if (this.canMoveFileToFolder(file, result.folder.id)) {
              const newFile = Object.assign(file, { FolderId: result.folder.id });

              this.fileService.save(newFile).subscribe({
                next: (savedFile: ApexFile) => {
                  if (savedFile.FolderId !== this.folder.id) {
                    const idx = this.folder.Files.indexOf(file);

                    if (idx !== -1) {
                      this.folder.Files.splice(idx, 1);
                    }
                  } else {
                    file = savedFile;
                  }
                },
              });
            }
          }
        },
      });
  }

  copyFile(file: ApexFile): void {
    if (file) {
      const newFile = {
        ...file,
        ...{ id: null },
      };

      this.fileService.save(newFile).subscribe({
        next: (f: ApexFile) => {
          if (f) {
            this.folder.Files.push(f);
            this.sortData();
            this.refreshList();
          }
        },
      });
    }
  }

  shareSelection(): void {
    this.dialog.open(FolderShareComponent, {
      data: {
        folders: this.selection.folders.filter((f) => !f?.modelName),
        files: this.selection.files,
      },
    });
  }

  moveSelection(): void {
    this.dialog
      .open(FolderTreeDialogComponent)
      .afterClosed()
      .subscribe({
        next: (result: FolderTreeDialogData) => {
          if (result?.folder) {
            this.dropIntoFolder(result.folder);
          }
        },
      });
  }

  deleteSelection(): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: t('Are you sure you want to delete {count} items?', {
            count: unsafeAdd(this.selection?.folders?.length, this.selection?.files?.length),
          }),
        },
      })
      .afterClosed()
      .pipe(
        skipWhile((v) => !v),
        mergeMap(() => {
          const folders = this.selection.folders
            .filter((f) => !f.modelName)
            .map((folder) =>
              this.folderService.delete(folder.id).pipe(
                tap(() => {
                  this.tree.updateFolder(folder, true);

                  if (this.folder.id === folder.id) {
                    this.id = folder.ParentId.toString();
                  } else {
                    const deletedFolder = this.folder.Children.find((ff) => ff.id === folder.id);

                    if (deletedFolder) {
                      this.childrenCount = this.childrenCount - 1;

                      const idx = this.folder.Children.indexOf(deletedFolder);

                      if (idx !== -1) {
                        this.folder.Children.splice(idx, 1);
                      }

                      this.refreshList();
                    }
                  }
                }),
              ),
            );
          const files = this.selection.files.map((file) =>
            this.fileService.delete(file).pipe(
              tap(() => {
                const deletedFile = this.folder?.Files?.find((ff) => ff.id === file.id);

                if (deletedFile) {
                  this.filesCount = this.filesCount - 1;

                  const idx = this.folder.Files.indexOf(deletedFile);

                  if (idx !== -1) {
                    this.folder.Files.splice(idx, 1);
                  }

                  this.refreshList();
                }
              }),
            ),
          );

          return merge(...[].concat(files, folders));
        }),
      )
      .subscribe(() => location.reload());
  }

  loadMoreChildren(): void {
    this.childrenPage = this.childrenPage + 1;

    if (this.folder) {
      this.loadingMoreFolders = true;

      this.folderService
        .children(this.folder.id, {
          params: {
            ...{ page: String(this.childrenPage) },
            ...this.params,
          },
        })
        .pipe(
          finalize(() => {
            this.loadingMoreFolders = false;
          }),
        )
        .subscribe({
          next: (res) => {
            this.folder.Children = this.folder.Children.concat(res.Collection);
          },
        });
    }
  }

  loadMoreFiles(): void {
    this.filesPage = this.filesPage + 1;

    if (this.folder) {
      this.loadingMoreFiles = true;

      this.folderService
        .files(this.folder.id, {
          params: {
            ...{ page: String(this.filesPage) },
            ...this.params,
          },
        })
        .pipe(
          finalize(() => {
            this.loadingMoreFiles = false;
          }),
        )
        .subscribe({
          next: (res) => {
            this.folder.Files = this.folder.Files.concat(res.Collection);
          },
        });
    }
  }

  async setProjectOrEntityTemplateFolder(
    folder: Folder,
    which: 'project-template-folder' | 'project-entity-template-folder',
  ): Promise<void> {
    if (!this.profile?.CustomerId) {
      return;
    }

    try {
      await this.customerService.setProjectOrEntityTemplateFolder(this.profile.CustomerId, folder.id, which);
      snack(t('Project template folder set to {folderName}', { folderName: folder.name }));
    } catch (err) {
      snackErr(t('Could not set project template folder'), err);
    }
  }

  get selection(): { folders: Folder[]; files: ApexFile[] } {
    if (this.folder) {
      const folders = this.folder.Children?.filter((f) => f.selected) ?? [];
      const files = this.folder.Files?.filter((f) => f.selected) ?? [];

      return {
        folders,
        files,
      };
    }

    return {
      folders: [],
      files: [],
    };
  }

  get selectionContainsElementWithViewAccess(): boolean {
    if (this.isSelectionEmpty) {
      return false;
    }

    if (!this.folder.access.collaborator && this.selection.files.length) {
      return true;
    }

    return this.selection.folders.some((f) => f.access && !f.access.collaborator);
  }

  get isSelectionEmpty(): boolean {
    return !(this.selection?.files?.length || this.selection?.folders?.length);
  }

  get params(): Params {
    return {
      limit: 25,
      orderBy: this.sort?.active ?? 'name',
      order: this.sort?.direction ?? 'asc',
    };
  }

  get displayShareSelection(): boolean {
    return (
      this.folder &&
      this.folder.modelName !== 'share' &&
      this.folder.access?.owner &&
      !this.isSelectionEmpty &&
      !this.selectionContainSystemFolder
    );
  }

  get selectionContainSystemFolder(): boolean {
    return this.selection?.folders?.some((f) => f.modelName);
  }

  get selectionIsOnlyOneFolder(): boolean {
    return this.selection.files.length === 0 && this.selection.folders.length === 1;
  }

  ngOnDestroy(): void {
    this.getFolderSub$?.unsubscribe();
  }
}
