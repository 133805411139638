import { KnownModelRoleModels, KnownModelRoleRoles } from '../components/model-role/model-role.types';
import { CRMUser } from './crm-user';
import { Model } from './model';
import { Tenancy } from './tenancy';
import { User } from './user';

export class ModelRole extends Model<ModelRole> {
  UserId?: number;
  User?: User;

  CRMUserId?: string;
  CRMUser?: CRMUser;

  CreatorId: number;
  Creator?: User;

  model: KnownModelRoleModels;
  modelId: string;
  role: KnownModelRoleRoles;
  from: Date;
  to: Date;

  Tenancy?: Tenancy;
}
