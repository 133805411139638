import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '../../../components/translate/translate.module';
import { CaseService } from '../case.service';

import { CaseNewMessageDialogComponent } from './new-message-dialog.component';
import { CaseNewMessageComponent } from './new-message.component';

@NgModule({
  declarations: [CaseNewMessageDialogComponent, CaseNewMessageComponent],
  imports: [
    CommonModule,
    FormsModule,

    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressBarModule,

    TranslateModule,
  ],
  providers: [CaseService],
  exports: [CaseNewMessageDialogComponent, CaseNewMessageComponent],
})
export class CaseNewMessageModule {}
