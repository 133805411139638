import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Cropper from 'cropperjs';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FileUsage } from '../../models/file-usage';
import { snack, snackErr } from '../../modules/snack.module';
import { UserService } from '../../services/user/user.service';
import { FileUsageComponent } from '../file-usage/file-usage.component';
import { FileUsageService } from '../file-usage/file-usage.service';
import { FileService } from '../file-usage/file.service';
import { ImageCropDialogComponent } from '../image-crop/image-crop-dialog.component';
import { CroppedImageResult, ImageCropDialogData } from '../image-crop/image-crop.types';
import { t } from '../translate/translate.function';

@Component({
  selector: 'apex-avatars',
  templateUrl: './avatars.component.html',
})
export class AvatarsComponent implements OnDestroy {
  @Input() userId: number;

  @ViewChild('fileUsage') fu: FileUsageComponent;

  name = 'avatars';
  self = 'user';
  pattern = 'image/';

  onlyOneSelected = false;
  selectedId: number;

  selectedFile: FileUsage;

  cropperOptions: Cropper.Options;
  private subscription = new Subscription();

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private fuService: FileUsageService,
    private fileService: FileService,
  ) {
    this.cropperOptions = {
      aspectRatio: 1,
      autoCrop: true,
      viewMode: 1,
      dragMode: 'move',
      toggleDragModeOnDblclick: false,
    };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectionChange(selected: Record<string, boolean>): void {
    const s = Object.entries(selected).filter(([_, v]) => v);

    if (s.length === 1) {
      [[this.selectedId]] = s as [[number, unknown], unknown];
      this.selectedFile = this.fu?.fileUsages?.find((fu) => Number(fu.id) === Number(this.selectedId));
      this.onlyOneSelected = true;
    } else {
      this.onlyOneSelected = false;
      this.selectedId = null;
      this.selectedFile = null;
    }
  }

  chooseAvatar(): void {
    if (this.onlyOneSelected && this.selectedId && this.selectedFile) {
      this.subscription.add(
        this.dialog
          .open<ImageCropDialogComponent, ImageCropDialogData, CroppedImageResult>(ImageCropDialogComponent, {
            data: {
              imageUrl: this.selectedFile?.File?.signed?.url,
              cropCircle: true,
              croppedImageSize: {
                width: 128,
                height: 128,
              },
              cropperOptions: this.cropperOptions,
              dialogTitle: t('Avatar'),
            },
            minWidth: '85vw',
            minHeight: '85vh',
            panelClass: 'crop',
          })
          .afterClosed()
          .subscribe({
            next: (res) => {
              if (res?.blob) {
                const name = `avatar-${this.userId}-${Date.now()}.png`;
                const file = new File([res.blob], name, {
                  lastModified: Date.now(),
                  type: 'image/png',
                });

                this.subscription.add(
                  this.fileService
                    .sign(file)
                    .pipe(
                      switchMap((d) => this.fileService.upload(d.signedData, d.file)),
                      switchMap((f) => this.fileService.save(f)),
                      switchMap((f) => this.userService.postAvatar(this.userId, f.id)),
                    )
                    .subscribe({
                      next: (_) => snack(t('Saved')),
                      error: (err) => snackErr(t('Could not save'), err),
                    }),
                );
              }
            },
          }),
      );
    }
  }

  deleteAvatar(): void {
    this.subscription.add(
      this.userService.deleteAvatar(this.userId).subscribe({
        next: (_) => snack(t('Deleted')),
        error: (err) => snackErr(t('Could not delete'), err),
      }),
    );
  }
}
