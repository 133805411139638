<div class="df jfe">
  <a
    *ngIf="showHelpButton"
    mat-icon-button
    target="_blank"
    [href]="helpUrl"
    [matTooltip]="helpButtonTooltip">
    <mat-icon>help</mat-icon>
  </a>
</div>
