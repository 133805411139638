import { Pipe, PipeTransform } from '@angular/core';
import { t } from '../translate.function';

const translations: Record<string, Record<string, string>> = {
  en: {},
  nb: {},
  lt: {},
  pl: {},
  lv: {},
  pt: {},
  sv: {},
  da: {},
};

const en = [
  t('File %s (%s) added'),
  t('File %s (%s) removed'),
  t('Contractor %s added'),
  t('Contractor %s removed'),
  t('Contractor did not accept case'),
  t('Contractor accepted case'),
  t('Contractor has finished'),
  t('Case completed'),
  t('Case reopened'),
  t('%s changed from %s to %s'),
  t('%s set to %s'),
  t('%s %s added'),
  t('%s %s removed'),
  t('%s changed'),
  t('Case created'),
];

en.forEach((k) => {
  translations.en[k] = k;
});

[
  'Fil %s (%s) lagt til',
  'Fil %s (%s) fjernet',
  'Leverandør %s lagt til',
  'Leverandør %s fjernet',
  'Leverandør aksepterte ikke saken',
  'Leverandør aksepterte saken',
  'Leverandør har fullført arbeidet',
  'Sak ferdigstilt',
  'Sak gjenåpnet',
  '%s endret fra %s til %s',
  '%s satt til %s',
  '%s %s lagt til',
  '%s %s fjernet',
  '%s endret',
].forEach((k, i) => {
  translations.nb[en[i]] = k;
});

['lt', 'pl', 'lv', 'pt', 'sv', 'da'].forEach((l) =>
  en.forEach((k) => {
    translations[l][k] = '';
  }),
);

interface Stupid {
  r: string;
  o: string;
  m: RegExpMatchArray;
}

export const translateRegexp = (text: string): string => {
  text = text?.trim();

  const candidate: Stupid = Object.values(translations.en)
    .filter((s) => s.includes('%s'))
    .map((s) => ({
      r: s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'),
      o: s,
      m: null,
    }))
    .map((d: Stupid) => ({
      r: d.r.replace(/%s/g, '(.*)'),
      o: d.o,
      m: null,
    }))
    .filter((d: Stupid) => {
      const r = new RegExp(d.r);

      const m = r.exec(text);

      if (m !== null) {
        d.m = text.match(r);
      }

      return m !== null;
    })
    .filter((d) => {
      let n = 0;
      const a = d.m.slice(1);
      const s = d.o.replace(/%s/g, () => a[n++]);

      return s === text;
    })
    .shift();

  if (candidate) {
    let n = 0;

    const a = candidate.m.slice(1);
    const tStr = t(candidate.o);

    return tStr.replace(/%s/g, () => t(a[n++]));
  }

  return t(text);
};

@Pipe({
  name: 'translateRegexp',
  pure: true,
})
export class TranslateRegexpPipe implements PipeTransform {
  transform(value: string): string {
    return translateRegexp(value);
  }
}
