import { ResponseCompanyTakeoverSettingsDto } from '../../../../../generated/apex-rest';
import { Obj } from '../features/object/object.model';
import { Case } from './case';
import { CRMUser } from './crm-user';
import { CustomerRight } from './customer-right';
import { Model, UUIDModel } from './model';
import { User } from './user';

export class Customer extends Model<Customer> {
  name: string;
  status?: number;
  address?: string;
  city?: string;
  municipality?: string;
  zip?: number;
  phone?: number;
  orgnr?: number;
  website?: string;
  mail?: string;
  logo?: string;
  country?: string;
  subdomain?: string;
  payed?: number;
  payedDate?: number;

  invoiceReference?: string;
  alphaNumericSenderId?: string | null;

  projectTemplateFolder?: string;
  projectEntityTemplateFolder?: string;

  UserId?: number;
  allowRequests?: boolean;
  Admins?: User[];
  Users?: User[];
  CustomerRight?: CustomerRight;

  CompanyTakeoverSettings?: ResponseCompanyTakeoverSettingsDto;
}

export class CustomerAdmin extends Model<CustomerAdmin> {
  UserId!: number;
  User?: User;
  CreatorId!: number | null;
  Creator?: User;
  CustomerId!: number;
  Customer?: Customer;
}

export class CustomerToken extends UUIDModel<CustomerToken> {
  hash!: string;
  token!: string;
  name!: string;

  expiresAt!: Date;
  active!: boolean;

  Customer?: Customer;
}

export class Webhook extends UUIDModel<Webhook> {
  id!: string;
  name!: string;
  url!: string;
  active!: boolean;

  events!: string[];

  CustomerId!: number;
  Customer?: Customer;

  WebhookEvents: WebhookEvent[];
}

export type WebhookCUD = 'Create' | 'Update' | 'Delete';
type WebhookDataType = Partial<Customer> | Partial<User> | Partial<Case> | Partial<CRMUser> | Partial<Obj>;

export class WebhookData extends UUIDModel<WebhookData> {
  id!: string;
  data!: WebhookDataType;

  type!: WebhookCUD;

  model!: string;
  modelId!: string;
}

export class WebhookEvent extends UUIDModel<WebhookEvent> {
  WebhookId: string;
  WebhookDataId: string;

  completedAt: Date | null;

  WebhookData: WebhookData;
  WebhookEventRuns: WebhookEventRun[];
}

export class WebhookEventRun extends UUIDModel<WebhookEventRun> {
  result: Record<string, unknown>;
}
