import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { FileSizePipeModule } from '../../pipes/file-size/file-size.module';
import { FileUsageService } from '../file-usage/file-usage.service';
import { FileService } from '../file-usage/file.service';
import { FilePreviewComponent } from './file-preview.component';

@NgModule({
  declarations: [FilePreviewComponent],
  exports: [FilePreviewComponent],
  imports: [CommonModule, MatIconModule, MatTooltipModule, FileSizePipeModule, TranslateModule],
  providers: [FileService, FileUsageService],
})
export class FilePreviewModule {}
