<div
  class="file-preview thumbnail f1 df jc ac"
  [title]="file?.name ?? ''">
  <img
    class="load-image-smooth"
    [alt]="file.description || file.name"
    [src]="thumbUrl"
    *ngIf="thumbUrl && !loadFailed"
    (load)="loadHandler()"
    (error)="errorHandler()" />

  <ng-container *ngIf="showPlaceholder">
    <div
      class="placeholder-image load-image-smooth f1 w-100 h-100"
      *ngIf="(!thumbUrl && !thumbIcon) || loadFailed"></div>
  </ng-container>

  <div
    class="icon"
    *ngIf="thumbIcon">
    <mat-icon>{{ thumbIcon }}</mat-icon>
  </div>

  <div
    class="file-size"
    *ngIf="showSize && file.size">
    {{ file.size | fileSize: true }}
  </div>

  <div
    class="file-name df ac oh"
    *ngIf="showName">
    <span class="apvs apls truncate f1">
      {{ file.name }}
    </span>

    <ng-content select="[bottomRight]"></ng-content>
  </div>

  <div
    class="file-date df c afe jfe mat-caption"
    *ngIf="showDates">
    <div
      class="df ac"
      [matTooltip]="'File created' | translate">
      {{ file.createdAt | date: 'shortDate' }}
      <mat-icon fontSet="material-icons-sharp">insert_drive_file</mat-icon>
    </div>
    <div
      class="df ac"
      [matTooltip]="'File added' | translate">
      {{ fileUsage.createdAt | date: 'shortDate' }}
      <mat-icon fontSet="material-icons-sharp">check_box</mat-icon>
    </div>
  </div>
</div>
