import { Injectable } from '@angular/core';
import { Field } from 'projects/apex/src/app/models/field';
import { BaseService } from 'projects/apex/src/app/services/http/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObjectField } from '../../../../models/object-field';
import { Project } from '../project.model';

@Injectable()
export class ObjectFieldService extends BaseService<ObjectField> {
  query(objectId: number): Observable<ObjectField[]> {
    const url = this.url(objectId);

    return this.http.get<ObjectField[]>(url, this.options).pipe(map((fields) => fields.map((f) => new ObjectField(f))));
  }

  get(objectId: number, fieldId: number): Observable<ObjectField> {
    const url = this.url(objectId, fieldId);

    return this.http.get<ObjectField>(url, this.options).pipe(map((objectField) => new ObjectField(objectField)));
  }

  save(field: ObjectField): Observable<ObjectField> {
    const url = this.url(field.ObjectId, field.id);

    return this.http.post<ObjectField>(url, field, this.options);
  }

  delete(field: ObjectField): Observable<void> {
    const url = this.url(field.ObjectId, field.id);

    return this.http.delete<void>(url, this.options);
  }

  getTopLevelObject(fieldId: number): Observable<Project> {
    const url = `${this.apiUrl}/field/${fieldId}`;

    return this.http.get<Project>(url, this.options);
  }

  getBaseFields(objectId: number): Observable<Field[]> {
    const url = `${this.apiUrl}/object/${objectId}/base-field`;

    return this.http.get<Field[]>(url, this.options);
  }

  private url(objectId: number, fieldId?: number): string {
    return fieldId ? `${this.apiUrl}/object/${objectId}/field/${fieldId}` : `${this.apiUrl}/object/${objectId}/field`;
  }
}
