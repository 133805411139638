import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { environment } from 'projects/apex/src/environments/environment';
import { Subscription, fromEvent, fromEventPattern } from 'rxjs';
import { take } from 'rxjs/operators';
import { locale } from '../../utils/functions';

const localeMap = {
  en: 'en-US',
  nb: 'nb-NO',
  pt: 'pt-PT',
  sv: 'sv-SE',
};

interface PdfJSViewerEventData {
  detail: {
    source: {
      PDFViewerApplicationOptions: Record<string, unknown>;
      PDFViewerApplication: Record<string, unknown>;
    };
  };
}

@Component({
  selector: 'apex-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
})
export class PDFViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('pdfIframe') pdfIframe: ElementRef;

  @Output() pdfLoaded: EventEmitter<{
    loaded: boolean;
    pageCount: number;
  }> = new EventEmitter<{ loaded: boolean; pageCount: number }>();
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  pdfViewerOptions = null;
  pdfViewerApp = null;
  eventBus = null;

  private src: string;
  private subscription = new Subscription();

  @Input()
  public set pdfSrc(src: string) {
    this.src = src;

    if (this.pdfIframe?.nativeElement) {
      this.loadPdf();
    }
  }

  public get pdfSrc(): string {
    return this.src;
  }

  ngOnInit(): void {
    if (!this.pdfSrc) {
      throw new Error('provide a source for the pdf');
    }
  }

  ngAfterViewInit(): void {
    if (this.pdfIframe?.nativeElement) {
      this.loadPdf();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadPdf(): void {
    const sub = fromEvent<PdfJSViewerEventData>(window.document, 'webviewerloaded')
      .pipe(take(1))
      .subscribe({
        next: (e) => {
          const pdfjsSource = e.detail.source;

          this.pdfViewerOptions = pdfjsSource.PDFViewerApplicationOptions;
          this.pdfViewerApp = pdfjsSource.PDFViewerApplication;

          this.pdfViewerOptions.set('locale', localeMap[locale()] ?? locale());

          this.pdfViewerApp.initializedPromise.then(() => {
            this.eventBus = this.pdfViewerApp.eventBus;

            // disable opening pdf from local source
            const config = this.pdfViewerApp.appConfig;

            config.toolbar.openFile.style = 'display: none';
            config.secondaryToolbar.openFileButton.style = 'display: none';

            this.addHandlers();
          });
        },
      });

    this.subscription.add(sub);

    this.pdfIframe.nativeElement.src = [
      environment.pdfViewerUrl,
      'web',
      `viewer.html?file=${encodeURIComponent(this.pdfSrc)}`,
    ].join('/');
  }

  addHandlers(): void {
    const events = ['pagesloaded', 'pagechanging'];

    events.forEach((e) => {
      const sub = fromEventPattern<{
        pagesCount?: number;
        pageNumber?: number;
      }>(
        (handler) => this.eventBus.on(e, handler),
        (_, listener) => this.eventBus.off(e, listener),
      ).subscribe({
        next: (ev) => {
          if (e === 'pagesloaded') {
            this.pdfLoaded.emit({
              loaded: true,
              pageCount: ev.pagesCount,
            });
          } else if (e === 'pagechanging') {
            this.pageChange.emit(ev.pageNumber);
          }
        },
      });

      this.subscription.add(sub);
    });
  }
}
