import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { pathToRegexp } from 'path-to-regexp';
import { ResponseHelpUrlDto } from '../../../../../../generated/apex-rest';
import { t } from '../translate/translate.function';
import { TranslateModule } from '../translate/translate.module';

@Component({
  selector: 'apex-help-icon',
  templateUrl: './help-icon.component.html',
  styleUrls: ['help-icon.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTooltipModule, MatIconModule, MatButtonModule, TranslateModule],
})
export default class HelpIconComponent implements OnInit {
  @Input() showHelpButton = true;

  helpUrl: string = 'https://help.apexapp.io/';

  url: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  get helpButtonTooltip(): string {
    return t('Help', {
      _context: 'page-info',
    });
  }

  ngOnInit(): void {
    this.url = 'https://help.apexapp.io';

    // This can be moved to OnParamsChanged/OnRouteChanged
    const routerUrl = this.router.url;
    const url = new URL(routerUrl, 'https://app.apexapp.io');
    const helpUrls: ResponseHelpUrlDto[] = this.route.snapshot?.data?.helpUrls ?? [];

    helpUrls.forEach((h) => {
      try {
        const ptr = pathToRegexp(h.path);

        if (ptr.exec(url.pathname)) {
          this.url = h.url;
        }
      } catch (err) {
        throw new Error(`Trouble with pathToRegexp for ${h.path}`);
      }
    });
  }
}
