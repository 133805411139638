import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ITranslateParams } from '@transifex/native';
import { TranslationService } from '../translate/translation.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'T',
  templateUrl: './t.component.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export default class T implements OnInit, OnChanges {
  @Input() str: string;
  @Input() context: string;
  @Input() vars: Record<string, unknown>;
  @Input() sanitize?: boolean = false;
  @Input() escapeVars?: boolean = false;

  translated = '';

  constructor(private translationService: TranslationService) {}

  get translateParams(): ITranslateParams {
    return {
      _context: this.context,
      _escapeVars: this.escapeVars,
      sanitize: this.sanitize,
    };
  }

  ngOnInit(): void {
    this.translate();
  }

  ngOnChanges(): void {
    this.translate();
  }

  translate(): void {
    this.translated = this.translationService.translate(this.str, { ...this.translateParams, ...this.vars });
  }
}
