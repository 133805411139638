import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ChecklistGroupTemplateService } from '../../features/checklist-group-template/checklist-group-template.service';
import { ChecklistTemplateService } from '../../features/checklist-template/checklist-template.service';
import { UserService } from '../../services/user/user.service';
import { t } from '../translate/translate.function';
import { ChecklistDialogData, ChecklistDialogReturnData } from './checklist-dialog.types';

@Component({
  selector: 'apex-checklist-dialog',
  templateUrl: './checklist-dialog.component.html',
})
export class ChecklistDialogComponent implements OnInit, OnDestroy {
  dialogData: ChecklistDialogData;

  checklistTemplateId: number;
  disableTemplateIdSelect = false;

  name: string;

  objectId: number = null;
  objectFieldId: number;
  disableObjectAutocomplete = false;

  projectId: number = null;
  apartmentId: number = null;
  fieldId: number;
  disableProjectAutocomplete = false;
  disableApartmentAutocomplete = false;

  caseManagerId: number;

  templateLabel: string;

  private subscription: Subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChecklistDialogData,
    public dialogRef: MatDialogRef<ChecklistDialogComponent>,
    private userService: UserService,
    private checklistTemplateService: ChecklistTemplateService,
    private checklistGroupTemplateService: ChecklistGroupTemplateService,
  ) {}

  ngOnInit(): void {
    this.dialogData = {
      ...this.data,
    };

    if (!this.dialogData?.title) {
      throw new Error('Title is required for NewChecklistDialogComponent');
    }

    if (!this.dialogData?.template) {
      throw new Error('Template is required for NewChecklistDialogComponent');
    } else {
      this.templateLabel =
        this.dialogData?.template === 'ChecklistTemplate' ? t('Checklist template') : t('Checklist group template');
    }

    if (!!this.dialogData?.templateId) {
      this.disableTemplateIdSelect = true;
      this.checklistTemplateId = this.dialogData.templateId;
      this.setTemplateName(this.checklistTemplateId);
    }

    if (!!this.dialogData?.model && !!this.dialogData?.modelId) {
      if (this.dialogData?.model === 'object') {
        this.objectId = this.dialogData.modelId;
        this.disableObjectAutocomplete = true;
      }

      if (this.dialogData.model === 'project') {
        this.projectId = this.dialogData.modelId;
        this.disableProjectAutocomplete = true;
      }

      if (this.dialogData.model === 'apartment') {
        this.apartmentId = this.dialogData.modelId;
        this.projectId = this.dialogData.projectId;
        this.disableApartmentAutocomplete = true;
        this.disableProjectAutocomplete = true;
      }
    }

    this.subscription.add(
      this.userService.profile$.subscribe({
        next: (user) => {
          if (user?.id) {
            this.caseManagerId = user.id;
          }
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setTemplateName(templateId: number): void {
    if (!templateId) {
      return;
    }

    if (this.dialogData?.template === 'ChecklistTemplate') {
      this.subscription.add(
        this.checklistTemplateService.get(templateId).subscribe((checklist) => {
          if (checklist?.Entity?.name) {
            this.name = checklist.Entity.name;
          }
        }),
      );
    } else if (this.dialogData?.template === 'ChecklistGroupTemplate') {
      this.subscription.add(
        this.checklistGroupTemplateService.get(templateId).subscribe((checklist) => {
          if (checklist?.Entity?.name) {
            this.name = checklist.Entity.name;
          }
        }),
      );
    }
  }

  save(): void {
    const result: ChecklistDialogReturnData = {
      templateId: this.checklistTemplateId,
      name: this.name,
      objectId: this.objectId,
      objectFieldId: this.objectFieldId,
      projectId: this.projectId,
      apartmentId: this.apartmentId,
      fieldId: this.fieldId,
      caseManagerId: this.caseManagerId,
    };

    this.dialogRef.close(result);
  }
}
