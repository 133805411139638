import { Injectable } from '@angular/core';
import { APIService } from 'projects/apex/src/app/services/http/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChecklistGroupTemplate } from '../../models/checklist-group-template';
import { ChecklistGroupTemplateChecklist } from '../../models/checklist-group-template-checklist';
import { ObjectField } from '../../models/object-field';
import { ProjectToFieldTemplate } from '../../models/project-to-field-template';
import { HttpExtra } from '../../services/http/http-extra';
import { CollectionResponse, DeleteResponse, EntityResponse } from '../../utils/types';

type ModelName = 'object' | 'project';
type Collection = CollectionResponse<ChecklistGroupTemplate>;
type Entity = EntityResponse<ChecklistGroupTemplate>;

@Injectable()
export class ChecklistGroupTemplateService extends APIService<ChecklistGroupTemplate> {
  route = 'checklist-group-template';

  dragGroup: ChecklistGroupTemplate;
  dragGroupParent: ChecklistGroupTemplate;

  addChecklist(GroupId: number, ChecklistId: number, sortOrder: number): Observable<ChecklistGroupTemplateChecklist> {
    return this.http.get<ChecklistGroupTemplateChecklist>(
      `${this.apiUrl}/${this.route}/${GroupId}/checklist/${ChecklistId}/${sortOrder}`,
      this.options(),
    );
  }

  removeChecklist(GroupId: number, ChecklistId: number): Observable<ChecklistGroupTemplateChecklist> {
    return this.http.delete<ChecklistGroupTemplateChecklist>(
      `${this.apiUrl}/${this.route}/${GroupId}/checklist/${ChecklistId}`,
      this.options(),
    );
  }

  queryModel(modelName: ModelName, modelId: number, extra?: HttpExtra): Observable<Collection> {
    return this.http.get<Collection>(`${this.apiUrl}/${modelName}/${modelId}/${this.route}`, this.options(extra));
  }

  getByModel(modelName: ModelName, modelId: number, templateId: number, extra?: HttpExtra): Observable<Entity> {
    return this.http.get<Entity>(
      `${this.apiUrl}/${modelName}/${modelId}/${this.route}/${templateId}`,
      this.options(extra),
    );
  }

  public addToModel(association: ProjectToFieldTemplate): Observable<ProjectToFieldTemplate> {
    const { model, modelId, ChecklistGroupTemplateId } = association;

    return this.http.post<ProjectToFieldTemplate>(
      `${this.apiUrl}/${model}/${modelId}/checklist-group-template/${ChecklistGroupTemplateId}/add`,
      association,
      this.options(),
    );
  }

  public removeFromModel(modelName: ModelName, modelId: number, groupTemplateId: number): Observable<DeleteResponse> {
    return this.http.get<DeleteResponse>(
      `${this.apiUrl}/${modelName}/${modelId}/checklist-group-template/${groupTemplateId}/remove`,
      this.options(),
    );
  }

  public getObjectFields(groupTemplateId: number): Observable<ObjectField[]> {
    return this.http
      .get<ObjectField[]>(`${this.apiUrl}/${this.route}/${groupTemplateId}/object-field`, this.options())
      .pipe(map((objectFields) => objectFields.map((objectField) => new ObjectField(objectField))));
  }
}
