import { HttpParams } from '@angular/common/http';

export type AutocompleteParams = Record<string, string | number | boolean>;

export class AutocompleteBase {
  getParams(params: AutocompleteParams, extraParams: AutocompleteParams): HttpParams {
    let httpParams = new HttpParams();

    Object.keys(extraParams).forEach((k: string) => {
      httpParams = httpParams.append(k, extraParams[k]);
    });

    if (params) {
      Object.keys(params).forEach((key) => {
        if (key && params[key] !== null) {
          httpParams = httpParams.append(key, params[key]);
        }
      });
    }

    return httpParams;
  }
}
