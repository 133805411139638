<!-- [color]="queryParams?.showOpen && 'primary'"-->
<mat-button-toggle-group
  multiple
  hideSingleSelectionIndicator="true"
  hideMultipleSelectionIndicator="true"
  class="mat-tab-group">
  <mat-button-toggle
    class="ph"
    [checked]="queryParams?.showOpen"
    [matTooltip]="'Open' | translate"
    (click)="updateQueryParams('showOpen', !queryParams?.showOpen)">
    <mat-icon>panorama_fish_eye</mat-icon>
  </mat-button-toggle>

  <mat-button-toggle
    class="ph"
    [checked]="queryParams?.showCompleted"
    [matTooltip]="'Done' | translate"
    (click)="updateQueryParams('showCompleted', !queryParams?.showCompleted)">
    <mat-icon>check_circle_outline</mat-icon>
  </mat-button-toggle>

  <mat-button-toggle
    class="ph"
    [checked]="queryParams?.showCaseManagers"
    [matTooltip]="'All case managers' | translate"
    (click)="updateQueryParams('showCaseManagers', !queryParams?.showCaseManagers)">
    <mat-icon svgIcon="casemanager"></mat-icon>
  </mat-button-toggle>

  <mat-button-toggle
    class="ph"
    [checked]="queryParams?.onlyUnread"
    [matTooltip]="'Only unread' | translate"
    (click)="updateQueryParams('onlyUnread', !queryParams?.onlyUnread)">
    <mat-icon>priority_high</mat-icon>
  </mat-button-toggle>

  <mat-button-toggle
    #filterButton
    [checked]="filtersSelected()"
    [matTooltip]="'More filters' | translate"
    (click)="openFilters()">
    <mat-icon>filter_alt</mat-icon>
  </mat-button-toggle>

  <mat-button-toggle
    [checked]="gpsSearch.loading"
    class="ph"
    *ngIf="commercial">
    <apex-object-gps-search-button
      #gpsSearch
      class="line-height-1"
      (found)="foundGPS($event.id)">
    </apex-object-gps-search-button>
  </mat-button-toggle>

  <mat-button-toggle
    [checked]="refreshing"
    [matTooltip]="'Refresh' | translate"
    (click)="refresh.emit()">
    <mat-icon>refresh</mat-icon>
  </mat-button-toggle>
</mat-button-toggle-group>
