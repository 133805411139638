import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InViewDirectiveModule } from '../../directives/in-view/in-view.directive.module';
import { DaterModule } from '../../pipes/dater/dater.module';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { BoxModule } from '../box/box.module';
import { TranslateModule } from '../translate/translate.module';
import { ModelRoleButtonComponent } from './button.component';
import { ModelRoleDialogComponent } from './form-dialog.component';
import { ModelRoleComponent } from './model-role.component';
import { ModelRoleService } from './model-role.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OverlayModule,

    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatDialogModule,
    MatListModule,
    MatTooltipModule,
    MatBadgeModule,

    TranslateModule,
    AutocompleteModule,
    DaterModule,
    BoxModule,
    InViewDirectiveModule,
    MatSelectModule,
  ],
  exports: [ModelRoleComponent, ModelRoleDialogComponent, ModelRoleButtonComponent],
  declarations: [ModelRoleComponent, ModelRoleDialogComponent, ModelRoleButtonComponent],
  providers: [ModelRoleService],
})
export class ModelRoleModule {}
