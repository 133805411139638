export enum FilterType {
  CaseCategory = 'casecategory',
  Checklist = 'checklist',

  Project = 'project',
  Apartment = 'apartment',
  Client = 'client',
  Field = 'field',

  Object = 'object',
  ObjectField = 'object-field',
  Tenancy = 'tenancy',
  Agreement = 'agreement',
  Meter = 'meter',

  AddonCategory = 'addon-category',

  Tag = 'tag',
}
