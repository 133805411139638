<div
  mat-dialog-title
  class="df w ac jsb">
  <h2 class="f1">
    <T str="Markings"></T>
  </h2>
  <button
    type="button"
    [matTooltip]="'Close' | translate"
    mat-icon-button
    [mat-dialog-close]="null">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <apex-case-marking
    class="h-100"
    [cases]="cases$ | async"
    [floorplans]="data.floorPlans"
    [highlightedCaseIds]="data.highlightedCaseIds"
    [loading]="data.floorplansLoading"
    (fileUsageClick)="data.newCaseFromMarking($event)"
    (caseChange)="data.updateCase($event); $event && data.id === $event.id ? data.view?.setCase($event.id) : null">
  </apex-case-marking>
</div>
