import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { LanguagePickerComponent } from '../language-picker/language-picker.component';
import T from '../t/t.component';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { TranslateRegexpPipe } from './pipe/translate-regexp.pipe';
import { TranslateTypePipe } from './pipe/translate-type.pipe';
import { TranslatePipe } from './pipe/translate.pipe';
import { TranslateRegexpComponent } from './translate-regexp.component';
import { TranslationService } from './translation.service';

const common = [
  TranslateRegexpComponent,
  TranslateRegexpPipe,
  TranslateTypePipe,
  T,
  TranslatePipe,
  LanguagePickerComponent,
  SafeHtmlPipe,
];

@NgModule({
  imports: [CommonModule, MatInputModule, MatSelectModule, FormsModule],
  exports: [...common],
  declarations: [...common],
  providers: [TranslationService],
})
export class TranslateModule {}
