import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { noop, uniqBy } from 'lodash-es';
import { Autocomplete } from 'projects/apex/src/app/models/autocomplete';
import { Observable, of, Subject, Subscription, timer } from 'rxjs';
import { catchError, debounce, map, switchMap, take, tap } from 'rxjs/operators';
import {
  getCaseCategoryAsAutocomplete$,
  projectClientCategories,
  queryCaseCategoriesAsAutocomplete$,
} from '../../models/case-category.data';
import { UserFavorite } from '../../models/user-favorite';
import { UserService } from '../../services/user/user.service';
import { constants } from '../../utils/constants';
import { favoriteAvailable, firstCharIsLowerCase, sortAutocompleteFn } from '../../utils/functions';
import { AutocompleteBase } from './autocomplete-base';
import { AutocompleteService } from './autocomplete.service';
import { AutocompleteString, AutocompleteTypes } from './autocomplete.types';

export const addParentToModelName = (name: string, model: Autocomplete): string => {
  if (model.Parent) {
    name += ` (${model.Parent.name})`;

    return addParentToModelName(name, model.Parent);
  }

  return name;
};

export class AutocompleteErrorStateMatcher implements ErrorStateMatcher {
  formControl: UntypedFormControl;

  constructor(formControl: UntypedFormControl) {
    this.formControl = formControl;
  }

  isErrorState(): boolean {
    return !!this.formControl?.invalid;
  }
}

@Component({
  selector: 'apex-autocomplete',
  templateUrl: './autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent
  extends AutocompleteBase
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
  @Input() label: string;
  @Input() placeholder: string;

  @Input()
  get type(): AutocompleteTypes | AutocompleteString {
    return this.innerType;
  }

  set type(t: AutocompleteTypes | AutocompleteString) {
    if (firstCharIsLowerCase(t)) {
      this.innerType = t as AutocompleteTypes;

      return;
    }

    this.innerType = AutocompleteTypes[t];
  }

  @Input()
  get required(): boolean {
    return this.innerRequired;
  }

  set required(value: boolean | string) {
    this.innerRequired = coerceBooleanProperty(value);
  }

  @Input() id: number;
  @Input() formControl: UntypedFormControl;
  @Input() params: Record<string, string | number | boolean>;
  @Input() hint: string;
  @Input() showHint = true;
  @Input() disabled = false;
  @Input() hideIds: number[] = [];
  @Input() excludeIds: number[] = [];

  @Output() modelChange = new EventEmitter<Autocomplete>();

  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild('autocomplete') autocomplete: MatAutocompleteTrigger;

  model: Autocomplete;
  models: Autocomplete[] = [];

  matcher: AutocompleteErrorStateMatcher;
  isLoading = false;
  noValuesFound = false;
  destroyed = false;
  innerFormControl: UntypedFormControl;
  favoriteAvailable: boolean;

  hasCommercial = false;
  hasProject = false;

  projectClientCategories = projectClientCategories;

  queryRequest$ = new Subject<string | Autocomplete>();
  queryResponse$: Observable<Autocomplete[]> = this.queryRequest$.pipe(
    tap(() => {
      this.models = [];
      this.isLoading = true;
    }),
    debounce((q) => {
      if (!q && this.favoriteAvailable) {
        return of(null);
      }

      return timer(constants.inputDebounceTime);
    }),
    switchMap((q) => {
      if (!q) {
        if (this.favoriteAvailable) {
          return this.userService.favorites$(this.type).pipe(
            map((favs: UserFavorite[]) =>
              favs.map((f) => ({
                id: f.modelId,
                name: f.name,
                Parent: null,
              })),
            ),
          );
        }

        q = '*';
      }

      if (typeof q !== 'string') {
        q = q.name;
      }

      if (this.innerType === AutocompleteTypes.CaseCategory) {
        return queryCaseCategoriesAsAutocomplete$(q);
      }

      if (q.length > 2 || q === '*') {
        return this.autocompleteService.queryString(this.innerType, this.getParams(this.params, { q })).pipe(
          catchError(() => of([])),
          take(1),
        );
      }

      return of([]);
    }),
  );

  onChange: (v: number) => void = noop;
  onTouched: (v: number) => void = noop;

  private innerType: AutocompleteTypes;
  private innerRequired = false;

  private subscription = new Subscription();

  constructor(
    private autocompleteService: AutocompleteService,
    private userService: UserService,
    private ref: ChangeDetectorRef,
  ) {
    super();
  }

  @Input() filterFunc: (value: Autocomplete, index: number, array: Autocomplete[]) => boolean = () => true;

  ngOnInit(): void {
    this.favoriteAvailable = favoriteAvailable(this.type);

    if (this.value) {
      this.getModelById();
    }

    if (this.formControl) {
      this.required = this.isRequired(this.formControl);
    }

    this.innerFormControl = new UntypedFormControl();
    this.matcher = new AutocompleteErrorStateMatcher(this.formControl || this.innerFormControl);
    this.setDisabledState(this.disabled);

    this.subscription.add(
      this.innerFormControl.valueChanges.subscribe((value) => {
        this.model = value;

        if (value) {
          value.id ? this.changeModel() : this.queryRequest$.next(value);
        } else {
          if (this.value) {
            this.value = undefined;
          }

          if (this.innerFormControl.dirty) {
            this.queryRequest$.next(null);
          }
        }
      }),
    );

    this.subscription.add(
      this.queryResponse$.subscribe({
        next: (res) => {
          if (res?.length) {
            let favorites = [];

            if (typeof this.model === 'string') {
              favorites =
                this.userService
                  .favorites(this.type)
                  ?.map((f) => ({
                    name: f.name,
                    id: f.modelId,
                    ParentId: null,
                    Parent: null,
                  }))
                  .filter((f) => f?.name?.toLowerCase().includes(String(this.model)))
                  .sort(sortAutocompleteFn) ?? [];
            }

            this.models = uniqBy(
              [...favorites, ...res.filter(this.filterFunc).sort((a, b) => sortAutocompleteFn(a, b))],
              (a) => a.id,
            );
            this.models = this.models.filter((ac) => !this.excludeIds?.includes(ac.id));
          } else {
            this.models = [];
            this.noValuesFound = true;
          }

          this.isLoading = false;
        },
        error: () => {
          this.models = [];
          this.isLoading = false;
        },
      }),
    );

    this.hasCommercial = this.userService.hasCustomerRight('Commercial');
    this.hasProject = this.userService.hasCustomerRight('Project');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.disabled) {
      this.setDisabledState(this.disabled);
    }
  }

  changeModel(): void {
    if (this.value !== this.model.id) {
      this.value = this.model.id;
    }
  }

  displayModel(model?: Autocomplete): string | undefined {
    return model ? this.getModelName(model) : '';
  }

  get value(): number {
    return this.id;
  }

  set value(val: number) {
    this.id = val;

    if (this.value) {
      this.getModelById();
    } else {
      this.model = undefined;
      this.modelChange.emit(this.model);
    }

    this.onChange(val);
    this.onTouched(val);
  }

  writeValue(value: number): void {
    this.id = value;

    if (value === null) {
      this.innerFormControl.reset();
      this.innerFormControl.updateValueAndValidity();
    } else {
      if (value) {
        if (!this.model || this.model.id !== value) {
          this.getModelById();
        }
      } else {
        this.model = undefined;
        this.innerFormControl.setValue(undefined, { emitEvent: false });
        this.modelChange.emit(this.model);
      }
    }

    if (!this.destroyed) {
      this.ref.detectChanges();
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (!this.destroyed && this.innerFormControl) {
      if (isDisabled) {
        this.innerFormControl.disable({ emitEvent: false });
      } else {
        this.innerFormControl.enable({ emitEvent: false });
      }

      this.ref.detectChanges();
    }
  }

  getModelById(): void {
    if (this.value) {
      let service = this.autocompleteService.queryId(this.innerType, this.getParams(this.params, { id: this.value }));

      if (this.innerType === AutocompleteTypes.CaseCategory) {
        service = getCaseCategoryAsAutocomplete$(this.value);
      }

      this.subscription.add(
        service.pipe(catchError(() => of(undefined))).subscribe((model) => {
          this.model = model;
          this.innerFormControl.setValue(model, { emitEvent: false });
          this.modelChange.emit(this.model);

          if (!this.destroyed) {
            this.ref.detectChanges();
          }
        }),
      );
    }
  }

  getModelName(model: Autocomplete): string {
    return this.addParentToModelName(model.name, model);
  }

  addParentToModelName(name: string, model: Autocomplete): string {
    return addParentToModelName(name, model);
  }

  isRequired(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);

      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  focus(): void {
    this.input.nativeElement.focus();
  }

  blur(): void {
    this.input?.nativeElement?.blur();
  }

  ngOnDestroy(): void {
    this.destroyed = true;

    this.subscription.unsubscribe();
  }
}
