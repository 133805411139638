import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, combineLatest, filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { Profile } from '../../models/profile';
import { UserService } from '../../services/user/user.service';
import { SidebarService } from './services/sidebar.service';

@Component({
  selector: 'apex-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChildren('case, request') sidebarItemElements: ElementRef<HTMLElement>[] = undefined;

  @Input() drawer: MatDrawer | null;

  profile$: Observable<Profile>;
  menuState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  subscription$$: Subscription = new Subscription();

  constructor(
    private userService: UserService,
    private sidebarService: SidebarService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.profile$ = this.userService.profile$;

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => this.closeDrawer());

    this.subscription$$.add(
      combineLatest([this.profile$, this.sidebarService.menuState$])
        .pipe(
          filter(([profile]) => !!profile?.id),
          map(([_, menuState]) => menuState),
        )
        .subscribe(this.menuState$),
    );
  }

  closeDrawer(): void {
    if (this.menuState$.getValue()) {
      void this.drawer?.open();
    } else {
      void this.drawer?.close();
    }
  }

  toggleMenu(): void {
    this.sidebarService.setMenuState(!this.menuState$.getValue());
  }

  ngOnDestroy(): void {
    this.subscription$$.unsubscribe();
  }
}
