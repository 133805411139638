<div
  class="color-box pointer"
  [matMenuTriggerFor]="colorMenu"
  [ngClass]="{
    ped: disabled,
    'is-weak': isWeak(color),
  }"
  [ngStyle]="{ 'background-color': color }">
  <mat-icon *ngIf="!color && !disabled">add_circle_outline</mat-icon>
  <mat-icon *ngIf="!color && disabled">panorama_fisheye</mat-icon>
</div>
<input
  type="color"
  class="vh"
  name="colorpicker"
  #colorPicker
  [(ngModel)]="color"
  (ngModelChange)="change()" />
<mat-menu #colorMenu="matMenu">
  <div class="color-circles df r w aph amts">
    <div
      class="color-circle pointer"
      [ngClass]="{
        'is-weak': isWeak(item.color),
      }"
      *ngFor="let item of colors"
      [matTooltip]="item.name | titlecase"
      [ngStyle]="{ 'background-color': item.color }"
      (click)="color = item.color; change()"></div>
  </div>
  <div class="aphs">
    <button
      mat-icon-button
      [matTooltip]="'Custom' | translate"
      (click)="colorPicker?.click()">
      <mat-icon>color_lens</mat-icon>
    </button>
    <button
      mat-icon-button
      class="amls"
      [matTooltip]="'Reset' | translate"
      (click)="color = null; change()">
      <mat-icon>format_color_reset</mat-icon>
    </button>
  </div>
</mat-menu>
