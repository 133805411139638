import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Autocomplete } from '../../models/autocomplete';
import { CRMUser } from '../../models/crm-user';
import { ModelRole } from '../../models/model-role';
import { User } from '../../models/user';
import { snack, snackErr } from '../../modules/snack.module';
import { t } from '../translate/translate.function';
import { ModelRoleService } from './model-role.service';
import { ModelRoleDialogData } from './model-role.types';

@Component({
  selector: 'apex-model-role-dialog',
  templateUrl: './form-dialog.component.html',
})
export class ModelRoleDialogComponent implements OnDestroy {
  modelRole = new ModelRole();

  private subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<ModelRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModelRoleDialogData,
    private service: ModelRoleService,
  ) {
    this.modelRole.model = data.model;
    this.modelRole.modelId = data?.modelId ? String(data.modelId) : null;
    this.modelRole.role = data.role;
    this.modelRole.from = new Date();

    if (data.CRM) {
      this.modelRole.CRMUser = new CRMUser();
    } else {
      this.modelRole.User = new User();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  save(): void {
    if ((this.modelRole.UserId || this.modelRole.CRMUserId) && this.modelRole.role && this.modelRole.from) {
      if (this.modelRole.modelId) {
        const sub = this.service.saveRoleForModel(Object.assign({}, this.modelRole)).subscribe({
          next: (mr) => {
            snack(t('Saved'));

            if (this.dialogRef) {
              this.dialogRef.close({
                modelRole: mr,
              });
            }
          },
          error: (err) => {
            snackErr(t('Could not save'), err);

            this.modelRole.UserId = undefined;
            this.modelRole.CRMUserId = undefined;
          },
        });

        this.subscription.add(sub);
      } else {
        const idx =
          this.data?.existingRoles?.findIndex(
            (r) =>
              r.role === this.modelRole.role &&
              ((this.data.CRM && r.CRMUserId === this.modelRole.CRMUserId) ||
                (!this.data.CRM && r.UserId === this.modelRole.UserId)),
          ) ?? -1;

        if (idx !== -1) {
          snack(t('User already added'));
        } else {
          this.dialogRef.close({
            modelRole: this.modelRole,
          });
        }
      }
    }
  }

  setUserName(autocomplete: Autocomplete): void {
    if (this.data.CRM) {
      this.modelRole.CRMUser.name = autocomplete?.name;
    } else {
      this.modelRole.User.name = autocomplete?.name;
    }
  }
}
