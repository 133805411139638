<apex-box
  class="no-padding"
  [icon]="headerIcon ?? 'group'"
  [expanded]="expanded"
  [expandable]="expandable">
  <T
    title
    [str]="title"></T>

  <button
    right
    mat-icon-button
    type="button"
    (click)="add(); $event.stopPropagation()"
    *ngIf="!viewMode"
    [disabled]="disabled">
    <mat-icon>add</mat-icon>
  </button>

  <div
    *ngIf="!loading; else loadingTemp"
    class="df c apl apt aprs">
    <div
      *ngFor="let mr of modelRoles"
      class="df ac amb">
      <mat-icon class="amr">person</mat-icon>
      <div class="df c f1 amrs">
        <div class="mat-h3 rm">{{ CRM ? mr.CRMUser?.name : mr.User?.name }} - {{ mr.translatedRole }}</div>
        <div
          class="mat-caption rm df ac"
          [ngClass]="{ 'error-color': hasExpired(mr), 'success-color': isActive(mr) }">
          <span [matTooltip]="periodToolTip(mr)">
            {{ mr?.from | dater | date: 'shortDate' }} - {{ mr?.to | dater | date: 'shortDate' }}
          </span>
        </div>
      </div>
      <button
        mat-icon-button
        *ngIf="!viewMode"
        color="warn"
        (click)="remove(mr)"
        [disabled]="disabled">
        <mat-icon>delete</mat-icon>
      </button>
    </div>

    <ng-container *ngIf="!rolesCount">
      <div class="amb">{{ emptyStateText }}</div>
    </ng-container>
  </div>
</apex-box>

<ng-template #loadingTemp>
  <div class="df ac jc ams">
    <mat-spinner [diameter]="32"></mat-spinner>
  </div>
</ng-template>
