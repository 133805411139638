import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { TextService } from 'projects/apex/src/app/features/text/text.service';
import { Text } from 'projects/apex/src/app/models/text';
import { constants } from 'projects/apex/src/app/utils/constants';

@Component({
  selector: 'apex-text-list',
  templateUrl: './text-list.component.html',
})
export class TextListComponent implements OnInit {
  @Input() texts: Text[];
  @Input() multiple: boolean;

  @Output() idChange = new EventEmitter<number>();
  @Output() selectionChange = new EventEmitter<Text[]>();

  @ViewChild('selectedTextsList') selectedTextsList: MatSelectionList;

  text: Text = new Text();

  mobile = false;
  opened = true;

  idValue: number;
  @Input()
  get id(): number {
    return this.idValue;
  }
  set id(id: number) {
    this.idValue = id;
    this.idChange.emit(this.id);
    this.setText();
  }

  selectionValue: Text[];
  @Input()
  get selection(): Text[] {
    return this.selectionValue;
  }
  set selection(texts: Text[]) {
    this.selectionValue = texts;
    this.selectionChange.emit(this.selection);
    this.setText();
  }

  constructor(private textSerivce: TextService) {}

  @Input() filterFn: (text: Text) => boolean = () => true;

  compareFunction = (t1: Text, t2: Text): boolean => t1.id === t2.id;

  ngOnInit(): void {
    this.mobile = window.innerWidth < constants.breakpoints.tabletMin;

    if (!this.texts) {
      this.texts = [];
      this.textSerivce.query().subscribe({
        next: (texts: Text[]) => {
          this.texts = texts;
          this.setText();
        },
      });
    }
  }

  setText(): void {
    const text = this.texts?.find((t) => t.id === this.id);

    this.text = text;
  }

  textSaved(text: Text): void {
    const oldText = this.texts.find((t) => t.id === text.id);

    if (oldText) {
      Object.assign(oldText, text);
    } else {
      this.texts = [text].concat(this.texts);
    }

    setTimeout(() => {
      const option = this.selectedTextsList.options.find((o) => o.value.id === text.id);

      this.selectedTextsList.selectedOptions.select(option);
      this.selectionChange.emit(this.getSelectedTexts());
    });
  }

  textDeleted(text: Text): void {
    const oldText = this.texts.find((t) => t.id === text.id);

    if (oldText) {
      const idx = this.texts.indexOf(oldText);

      if (idx !== -1) {
        this.texts.splice(idx, 1);
        this.newText();
        this.id = null;
        this.opened = true;
      }
    }
  }

  getSelectedTexts(): Text[] {
    return this.selectedTextsList?.selectedOptions?.selected?.filter((s) => s).map((s) => s.value) ?? [];
  }

  filteredTexts(texts: Text[]): Text[] {
    return texts?.filter((text) => this.filterFn(text)) ?? [];
  }

  newText(): void {
    this.text = new Text();

    if (this.mobile) {
      this.opened = false;
    }
  }
}
