import { Component } from '@angular/core';
import { t } from 'projects/apex/src/app/components/translate/translate.function';

@Component({
  selector: 'apex-cases-page',
  templateUrl: './cases.component.html',
})
export class CasesPageComponent {
  get title(): string {
    return t('Cases');
  }
}
