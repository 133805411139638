import { Model } from './model';

export enum CaseContractorStatus {
  New = 'new',
  Accepted = 'accepted',
  Declined = 'declined',
  Completed = 'completed',
}

export class CaseContractor extends Model<CaseContractor> {
  CaseId: number;
  CreatorId: number;
  status: CaseContractorStatus;
  message: string;
  UserId: number;
  from: Date;
  to: Date;
  notifyOnAddSms?: boolean;
  notifyOnAddEmail?: boolean;

  constructor(caseContractor?: CaseContractor) {
    super(caseContractor);

    this.status = CaseContractorStatus.New;
  }
}
