<ng-container *ngIf="ds.dragging">
  <div
    class="item df ac truncate"
    *ngFor="let i of itemsSelected(ds.dragItems)"
    [ngClass]="{
      'mat-elevation-z2': !isReady(i) || isDragItem(i),
      drop: !ds.dragItem,
      'drag-item': isDragItem(i),
    }"
    [ngStyle]="{
      left: i.currentX + 'px',
      top: i.currentY + 'px',
      width: singleMode ? 'auto' : (ds.dragItem ? defaultWidth : i.width) + 'px',
      height: singleMode ? 'auto' : '42px',
    }"
    [matBadge]="itemsSelected(ds.dragItems).length"
    [matBadgeHidden]="itemsSelected(ds.dragItems).length < 2 || !isDragItem(i) || singleMode">
    <mat-icon
      *ngIf="i.dragIcon"
      class="ams"
      [ngClass]="i.dragIconClass">
      {{ i.dragIcon }}
    </mat-icon>
    <span
      *ngIf="i.name"
      class="mat-body-2 truncate amrs"
      >{{ i.name }}</span
    >
    <ng-content></ng-content>
  </div>
</ng-container>
<div
  *ngIf="!ds.dragging && displaySelector && !disableSelector"
  class="drag-selector"
  [ngStyle]="{
    left: (mousePos.x < startPos.x ? mousePos.x : startPos.x) + 'px',
    top: (mousePos.y < startPos.y ? mousePos.y : startPos.y) + 'px',
    width: width + 'px',
    height: height + 'px',
  }"></div>
