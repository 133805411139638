import { Injectable } from '@angular/core';
import { File as ApexFile } from 'projects/apex/src/app/models/file';
import { Folder } from 'projects/apex/src/app/models/folder';
import { HttpExtra } from 'projects/apex/src/app/services/http/http-extra';
import { APIService } from 'projects/apex/src/app/services/http/http.service';
import { Observable } from 'rxjs';
import { CollectionResponse, EntityResponse } from '../../utils/types';

@Injectable()
export class FolderService extends APIService<Folder> {
  route = 'folder';

  home(extra?: HttpExtra): Observable<EntityResponse<Folder>> {
    return this.get('home', extra);
  }

  upload(extra?: HttpExtra): Observable<EntityResponse<Folder>> {
    return this.get('upload', extra);
  }

  children(id: string, extra?: HttpExtra): Observable<CollectionResponse<Folder>> {
    return this.http.get<CollectionResponse<Folder>>(
      `${this.apiUrl}/${this.route}/${id}/children`,
      this.options(extra),
    );
  }

  files(id: string, extra?: HttpExtra): Observable<CollectionResponse<ApexFile>> {
    return this.http.get<CollectionResponse<ApexFile>>(`${this.apiUrl}/${this.route}/${id}/file`, this.options(extra));
  }
}
