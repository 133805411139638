import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'projects/apex/src/app/components/page-not-found/page-not-found.component';
import { TenanciesComponent } from 'projects/apex/src/app/features/object/project/tenancy/tenancies.component';
import { TenancyComponent } from 'projects/apex/src/app/features/object/project/tenancy/tenancy.component';
import { TenancyResolver } from 'projects/apex/src/app/features/object/project/tenancy/tenancy.resolver';
import { EntryCardResolver } from './entry-card/entry-card.resolver';
import { EntryCardComponent } from './entry-card/form.component';
import { EntryGroupResolver, EntryGroupsResolver } from './entry-group/entry-group.resolver';
import { EntryGroupComponent } from './entry-group/form.component';

const routes: Routes = [
  {
    path: '',
    component: TenanciesComponent,
  },
  {
    path: 'new',
    component: TenancyComponent,
    resolve: {
      tenancy: TenancyResolver,
    },
  },
  {
    path: ':tid/edit',
    component: TenancyComponent,
    resolve: {
      tenancy: TenancyResolver,
    },
  },
  {
    path: ':tid',
    resolve: {
      tenancy: TenancyResolver,
    },
    children: [
      {
        path: '',
        component: TenancyComponent,
      },
      {
        path: 'entry-card/new',
        component: EntryCardComponent,
        resolve: {
          tenancy: TenancyResolver,
          entryCard: EntryCardResolver,
          entryGroups: EntryGroupsResolver,
        },
      },
      {
        path: 'entry-card/:eid',
        component: EntryCardComponent,
        resolve: {
          tenancy: TenancyResolver,
          entryCard: EntryCardResolver,
          entryGroups: EntryGroupsResolver,
        },
      },
      {
        path: 'entry-group/new',
        component: EntryGroupComponent,
        resolve: {
          tenancy: TenancyResolver,
          entryGroup: EntryGroupResolver,
        },
      },
      {
        path: 'entry-group/:egid',
        component: EntryGroupComponent,
        resolve: {
          tenancy: TenancyResolver,
          entryGroup: EntryGroupResolver,
        },
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class TenancyRoutingModule {}
