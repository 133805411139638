<div class="df r afs">
  <div class="wrapper f1 white-space-pre-line">
    <div
      class="original"
      #originalElement
      [class.hide]="translated">
      <ng-content></ng-content>
    </div>

    <div
      class="translated"
      #translatedElement
      [class.hide]="!translated"></div>
  </div>

  <div class="amls">
    <mat-icon
      mat-ripple
      style="font-size: 16px"
      [color]="translated ? 'primary' : ''"
      [class.animate-rotate]="loading"
      [inline]="true"
      (click)="!loading && translate()">
      translate
    </mat-icon>
  </div>
</div>

<!--
<div class="mat-caption df r ac">
  <mat-icon mat-ripple
    [color]="translated ? 'primary' : ''"
    [inline]="true"
    [class.animate-rotate]="loading"
    (click)="!loading && translate()">
    translate
  </mat-icon>

  <span *ngIf="translated && !elementRef" class="translated">
    {{ translation }}
  </span>
</div>
-->
