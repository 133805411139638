import { Case } from './case';
import { LogMail } from './case-log-mail';
import { Model } from './model';
import { User } from './user';

export class CaseLog extends Model<CaseLog> {
  CaseId: number;

  message: string;
  status: number;
  kt?: number;
  creatorRole: number;
  LogMailId: number;
  UserId: number;

  migratedFor: number;
  error: boolean;
  externalId: string;

  forClient: 0 | 1;
  forContractor: 0 | 1;

  sendNotifications?: boolean;
  sendSMS?: boolean;

  system: boolean;

  Case?: Case;
  User?: User;
  LogMail?: LogMail;

  translate: boolean;
  translateVars: Record<string, string | number | boolean | null>;
}
