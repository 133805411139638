import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { t } from '../../../components/translate/translate.function';

import { getTopInCollection } from '../../../utils/functions';

import { Obj } from '../../object/object.model';
import { CommercialObject, Project } from '../../object/project/project.model';

@Component({
  selector: 'apex-case-object-info',
  templateUrl: './case-object-info.component.html',
})
export class CaseObjectInfoComponent implements OnChanges {
  @Input() objects: CommercialObject[] | Project[] | Obj[] = [];

  path: string;
  selectedObject: Obj;
  showMore = false;

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.objects && this.objects) {
      this.path = [...this.objects].map((o) => o.name).join(' / ');
      this.selectedObject = [...this.objects].pop();
    }
  }

  hasMoreInformation(o: Project | Obj): boolean {
    return !!o.description || !!o.data?.informationExternalHtml || !!o.data?.invoiceHtml;
  }

  routerLink(obj: Obj): string[] | null {
    if (!obj) {
      // Disable navigation
      return null;
    }

    if (obj.ParentId) {
      const top = getTopInCollection(obj.ParentId, this.objects);

      if (!top) {
        throw new Error('Top not found');
      }

      return ['/object', 'project', String(top.id), 'object', String(obj.id)];
    }

    return ['/object', 'project', String(obj.id)];
  }

  get selectedObjectTooltip(): string {
    return t('Go to {name}', {
      name: this.selectedObject?.name ?? '',
    });
  }
}
