import { Pipe, PipeTransform } from '@angular/core';
import { orderBy, uniqBy } from 'lodash-es';
import { CasePrintDto, ResponseUserCardDto } from '../../../../../../../generated/apex-rest';
import { Case } from '../../../models/case';
import { User } from '../../../models/user';

@Pipe({
  name: 'clientsFromCase',
  standalone: true,
})
export class ClientsFromCasePipe implements PipeTransform {
  transform(c: Case): User[] {
    if (!c) {
      return [];
    }

    const clientsFromApartment = c?.Apartment?.Clients ?? [];
    const clientFromCase = c?.Client ? [c?.Client] : [];

    return orderBy(
      uniqBy([...clientsFromApartment, ...clientFromCase], (u) => u.id),
      'id',
    );
  }
}

@Pipe({
  name: 'clientsCardFromCase',
  standalone: true,
})
export class ClientsCardFromCase implements PipeTransform {
  transform(c: CasePrintDto): ResponseUserCardDto[] {
    if (!c) {
      return [];
    }

    const clientsFromApartment = c?.Apartment?.Clients ?? [];
    const clientFromCase = c?.Client ? [c?.Client] : [];

    return orderBy(
      uniqBy([...clientsFromApartment, ...clientFromCase], (u) => u.id),
      'id',
    );
  }
}
