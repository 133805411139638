import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ProgressModule } from '../../../components/progress/progress.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { ReduceHtmlToStringModule } from '../../../pipes/reduct-html-to-string/reduce-html-to-string.module';
import { ChecklistGroupService } from '../checklist-group.service';
import { ChecklistGroupChecklistModule } from '../checklist/checklist-group-checklist.module';
import { ChecklistGroupTreeComponent } from './tree.component';

@NgModule({
  declarations: [ChecklistGroupTreeComponent],
  exports: [ChecklistGroupTreeComponent],
  imports: [
    CommonModule,
    ReduceHtmlToStringModule,
    ProgressModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatTooltipModule,
    RouterModule,
    TranslateModule,
    ChecklistGroupChecklistModule,
  ],
  providers: [ChecklistGroupService],
})
export class ChecklistGroupTreeModule {}
