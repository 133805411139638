import {
  ImageSource,
  MarkupEditorShapeStyleControlDefaults,
  MarkupEditorToolStyleDefaults,
  openDefaultEditor,
  PinturaDefaultImageWriterResult,
  PinturaEditorDefaultOptions,
} from '@pqina/pintura';
import pinturaDefaultOptions from './pintura-default-options';

type MarkupControls = 'path' | 'rectangle' | 'ellipse' | 'line' | 'text';

const savePinturaSettings = (defaults: MarkupEditorToolStyleDefaults): void => {
  localStorage.setItem('apexPinturaSetting', JSON.stringify(defaults));
};

const getPinturaSettings = (): MarkupEditorToolStyleDefaults | undefined => {
  const settingsLS = localStorage.getItem('apexPinturaSetting');

  if (!settingsLS) {
    return undefined;
  }

  const settings = JSON.parse(settingsLS);
  const userSettingsLS = localStorage.getItem('apexUserPinturaSettings');

  if (userSettingsLS) {
    const userSettings = JSON.parse(userSettingsLS);

    Object.keys(userSettings).forEach((key) => {
      const userObject = userSettings[key];

      settings[key][0] = { ...settings[key][0], ...userObject };
    });
  }

  return settings;
};

export const openPinturaEditor = async (
  src: ImageSource,
  options: PinturaEditorDefaultOptions = {},
  fileName?: string,
): Promise<File | null> =>
  new Promise((resolve, reject) => {
    let annotateActiveTool: MarkupControls =
      JSON.parse(localStorage.getItem('apexPinturaAnnotateActiveTool')) ?? 'rectangle';

    const editorOptions = {
      src,
      ...pinturaDefaultOptions(fileName),
      ...options,
      annotateActiveTool,
    };

    const userSettings = getPinturaSettings();

    if (userSettings) {
      editorOptions.markupEditorToolStyles = userSettings;
    }

    const editor = openDefaultEditor(editorOptions);

    editor.on('error', (err) => reject(err));
    editor.on('process', (imageState: PinturaDefaultImageWriterResult) => {
      savePinturaSettings(editor.markupEditorToolStyles);

      return resolve(imageState.dest);
    });

    editor.on('close', () => {
      savePinturaSettings(editor.markupEditorToolStyles);

      return resolve(null);
    });

    editor.on('selectcontrol', (selectTool: MarkupEditorToolStyleDefaults) => {
      annotateActiveTool = String(selectTool) as MarkupControls;

      localStorage.setItem('apexPinturaAnnotateActiveTool', JSON.stringify(selectTool));
    });

    editor.on('selectstyle', (selectStyle: MarkupEditorShapeStyleControlDefaults) => {
      const userSettingsLS = localStorage.getItem('apexUserPinturaSettings');

      let userSetting = {};

      if (userSettingsLS) {
        userSetting = JSON.parse(userSettingsLS);
      }

      if (!userSetting[annotateActiveTool]) {
        userSetting[annotateActiveTool] = {};
      }

      userSetting[annotateActiveTool] = { ...userSetting[annotateActiveTool], ...selectStyle };

      localStorage.setItem('apexUserPinturaSettings', JSON.stringify(userSetting));
    });
  });

export const isTypeImage = (what: { type: string }): boolean => String(what?.type).startsWith('image') ?? false;
