import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounce, filter, map } from 'rxjs/operators';
import { Bookmark } from './bookmark.model';
import { BookmarkService } from './bookmark.service';

@Component({
  selector: 'apex-bookmark-button',
  templateUrl: './button.component.html',
})
export class BookmarkButtonComponent implements OnInit, OnDestroy {
  public currentBookmark = '';
  private bookmarks: Bookmark[] = [];

  private subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private bookmarkService: BookmarkService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.bookmarkService.data$.subscribe((_) => {
        this.bookmarks = this.bookmarkService.bookmarks;
      }),
    );

    this.subscription.add(
      this.router.events
        .pipe(
          filter((e) => e instanceof NavigationEnd),
          debounce(() => this.bookmarkService.data$),
          map((e: NavigationEnd) => this.bookmarks.find((b) => b.url === e.url)),
        )
        .subscribe({
          next: (found) => {
            this.currentBookmark = found?.name ?? '';
          },
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
