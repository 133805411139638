import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { CheckFavoritesPipe } from './pipes/check-favorites.pipe';
import { UserFavoriteButtonComponent } from './user-favorite-button.component';

@NgModule({
  declarations: [UserFavoriteButtonComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, CheckFavoritesPipe],
  exports: [UserFavoriteButtonComponent],
  providers: [],
})
export class UserFavoriteButtonModule {}
