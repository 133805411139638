<input
  #fileUploadInput
  style="display: none"
  type="file"
  multiple
  (change)="uploadFromInput($event)" />
<mat-menu #fileMore="matMenu">
  <ng-template
    matMenuContent
    let-moreFile="moreFile">
    <button
      mat-menu-item
      (click)="folder.renameFile(moreFile)">
      <mat-icon>edit</mat-icon>
      <span>
        <T str="Rename"></T>
      </span>
    </button>
    <button
      mat-menu-item
      (click)="folder.downloadFile(moreFile)">
      <mat-icon>cloud_download</mat-icon>
      <span>
        <T str="Download"></T>
      </span>
    </button>
    <button
      mat-menu-item
      (click)="folder.deleteFile(moreFile)">
      <mat-icon>delete</mat-icon>
      <span>
        <T str="Delete"></T>
      </span>
    </button>
  </ng-template>
</mat-menu>

<ng-template #uploadFilesListTemplate>
  <div class="f1 df oa">
    <table
      #fileTable
      mat-table
      [dataSource]="uploadFolderFiles"
      class="files w-100">
      <ng-container matColumnDef="selected">
        <th
          mat-header-cell
          *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let f">
          <mat-checkbox
            [(ngModel)]="f.selected"
            (click)="$event.stopPropagation()">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef>
          <T str="Name"></T>
        </th>
        <td
          mat-cell
          *matCellDef="let f">
          <div class="df ac">
            <div class="file-preview amrs">
              <apex-file-preview
                *ngIf="f.show && f?.name"
                [file]="f">
              </apex-file-preview>
            </div>
            <span
              class="truncate"
              [matTooltip]="f.name">
              {{ f.name }}
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="updatedAt">
        <th
          mat-header-cell
          *matHeaderCellDef>
          <T str="Last modified"></T>
        </th>
        <td
          mat-cell
          *matCellDef="let f">
          {{ f.updatedAt ? (f.updatedAt | date: 'shortDate') : '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th
          mat-header-cell
          *matHeaderCellDef>
          <T str="Size"></T>
        </th>
        <td
          mat-cell
          *matCellDef="let f">
          {{ f.size ? (f.size | fileSize: true) : '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="more">
        <th
          mat-header-cell
          *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let f">
          <button
            mat-icon-button
            [matMenuTriggerFor]="fileMore"
            [matMenuTriggerData]="{ moreFile: f }"
            (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"></tr>
      <tr
        #row
        mat-row
        *matRowDef="let f; columns: displayedColumns"
        class="clickable"
        (click)="!isWeb && folder.openFile(f)"
        (dblclick)="folder.openFile(f)"
        [ngClass]="{ selected: f.selected }"
        (apexInView)="$event && (f.show = true)"
        inViewOnlyOnce></tr>
    </table>
  </div>

  <mat-paginator
    *ngIf="uploadFolderFiles?.data?.length"
    [length]="uploadFolderFileCount"
    [pageSizeOptions]="[10, 25, 50]"
    showFirstLastButtons
    (page)="getUploadFolderFiles($event.pageIndex, $event.pageSize)">
  </mat-paginator>
</ng-template>

<div mat-dialog-title>
  <div class="df f1 jsb ac">
    <h1>
      <T str="Explorer"></T>
    </h1>
    <button
      mat-icon-button
      (click)="dialogRef.close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content class="df">
  <mat-tab-group
    [(selectedIndex)]="selectedIndex"
    animationDuration="0"
    class="w-100 df c">
    <mat-tab [disabled]="!profile?.Customer?.payed">
      <ng-template mat-tab-label>
        <T str="Folders"></T>
      </ng-template>
      <apex-folder [splitPdf]="data.splitPdf"></apex-folder>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <T str="My uploads"></T>
      </ng-template>
      <div
        *ngIf="uploadFolderFiles"
        class="list f1 df c oh">
        <ng-container *ngTemplateOutlet="uploadFilesListTemplate"></ng-container>
      </div>
      <div
        *ngIf="!uploadFolderFiles"
        class="f1 df ac jc">
        <mat-spinner *ngIf="loading"></mat-spinner>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <T str="Upload"></T>
      </ng-template>
      <div
        #upload
        class="upload f1 df c ac jc"
        [ngClass]="{ dragover: dragoverUpload }"
        mat-ripple
        (click)="fileUploadInput.click()"
        (dragover)="$event.preventDefault(); dragoverUpload = true"
        (dragleave)="dragoverUpload = false"
        (drop)="dragoverUpload = false; uploadFromDrop($event)">
        <mat-icon>cloud_upload</mat-icon>
        <T str="Click or drag files here to add"></T>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions>
  <div>
    <T
      str="{count} files are selected"
      [vars]="{ count: totalSelection?.length }">
    </T>
  </div>
  <div>
    <button
      mat-button
      (click)="dialogRef.close()">
      <T str="Cancel"></T>
    </button>
    <button
      mat-raised-button
      [disabled]="!totalSelection?.length"
      (click)="confirm()"
      color="primary">
      <T str="Confirm"></T>
    </button>
  </div>
</mat-dialog-actions>
