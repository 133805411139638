import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { omit } from 'lodash-es';
import moment, { Locale, Moment } from 'moment';
import { environment } from 'projects/apex/src/environments/environment';
import { Subject, Subscription, firstValueFrom, forkJoin } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { FileUsageService } from '../../../components/file-usage/file-usage.service';
import { t } from '../../../components/translate/translate.function';
import { Case, Contractor } from '../../../models/case';
import { CaseParams } from '../../../models/case-params';
import { File } from '../../../models/file';
import { snack, snackErr } from '../../../modules/snack.module';
import { UserService } from '../../../services/user/user.service';
import { constants } from '../../../utils/constants';
import { objectToQuery, transformQueryParams } from '../../../utils/functions';
import { CaseService } from '../case.service';
import { CaseFormDialogComponent } from '../form/dialog.component';
import { RepeatableCase } from '../repeatable-case.model';
import { RepeatableCaseService } from '../repeatable-case.service';
import { CaseViewDialogComponent } from '../view/dialog.component';

interface CalendarRow {
  slots: CalenderSlot[];
}

interface CalenderSlot {
  day?: string;
  date?: number;
  weekNumber?: number;
  moment?: Moment;
}

interface CaseData extends Case {
  RepeatableCase?: RepeatableCase;
  deadLineMoment?: Moment;
  createdAtMoment?: Moment;
  sortOrder?: number;
  startDay?: number;
  length?: number;
}

interface RowCases {
  cases: CaseData[];
}

enum CalendarView {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

@Component({
  selector: 'apex-case-calendar',
  templateUrl: './calendar.component.html',
})
export class CaseCalendarComponent implements OnInit, OnDestroy {
  @Input() queryParams: Params;

  caseDatas: CaseData[] = [];
  rowsCases: RowCases[] = [];

  cases: Case[] = [];
  repeatableCases: RepeatableCase[] = [];

  futureRepeatableCases: RepeatableCase[] = [];

  monthName: string;
  date: number;
  week: number;
  month: number;
  year: number;

  firstSlotInMonth: CalenderSlot;
  lastSlotInMonth: CalenderSlot;

  todayTooltip: string;
  todaym: Moment;

  dateDay: string;

  localeData: Locale;

  weekDaysMin: string[];
  weekDays: string[];

  monthsShort: string[];
  months: string[];

  weekDaysMinFlip: string[];
  weekDaysFlip: string[];

  calendarRows: CalendarRow[] = [];

  numRows: number;

  caseParams: CaseParams;

  view: CalendarView;
  calenderView = CalendarView;

  loading = true;

  snack = snack;
  t = t;

  fetchCases: Subject<void> = new Subject<void>();
  fetchSubscription: Subscription = this.fetchCases.pipe(debounceTime(constants.inputDebounceTime)).subscribe({
    next: () => {
      this.fetchData();
    },
  });

  private subscription: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private caseService: CaseService,
    private repeatableCaseService: RepeatableCaseService,
    private fileUsageService: FileUsageService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.localeData = moment().localeData();

    this.weekDaysMin = [...this.localeData.weekdaysMin()];
    this.weekDays = [...this.localeData.weekdays()];

    this.weekDaysMinFlip = [...this.localeData.weekdaysMin()];
    this.weekDaysFlip = [...this.localeData.weekdays()];

    this.months = [...this.localeData.months()];
    this.monthsShort = [...this.localeData.monthsShort()];

    // flip weekdays so that monday comes first
    const sunday = this.weekDaysFlip.shift();
    const sundayMin = this.weekDaysMinFlip.shift();

    this.weekDaysFlip.push(sunday);
    this.weekDaysMinFlip.push(sundayMin);

    const snapShotParams = this.route.snapshot.queryParams;

    if (
      !snapShotParams.date ||
      !snapShotParams.Week ||
      !snapShotParams.month ||
      !snapShotParams.year ||
      !snapShotParams.view
    ) {
      this.setDefaults(snapShotParams);
    }

    const sub = this.route.queryParams.subscribe({
      next: (params) => {
        this.setDefaults(params);

        this.dateDay = moment()
          .set({
            date: this.date,
            month: this.month,
            year: this.year,
          })
          .format('ddd');

        this.queryParams = {
          ...this.queryParams,
          ...params,
          month: this.month,
          calendar: true,
        };
        this.updateView();
      },
    });

    this.subscription.add(sub);
  }

  setDefaults(params: Params): void {
    this.date = params.date ? Number(params.date) : moment().date();
    this.week = params.week ? Number(params.week) : moment().isoWeek();
    this.month = params.month ? Number(params.month) - 1 : moment().month();
    this.year = params.year ? Number(params.year) : moment().year();

    this.view = params.view || CalendarView.Month;
  }

  ngOnDestroy(): void {
    this.fetchSubscription?.unsubscribe();
    this.subscription.unsubscribe();
  }

  createCase(slot?: CalenderSlot): void {
    const newCase = this.caseService.newCaseFromParams(this.caseParams);

    newCase.from = (slot ? moment(slot.moment) : moment()).toDate();
    newCase.to = (
      slot
        ? moment(slot.moment).set({
            hour: slot.moment.get('hour') + 1,
          })
        : moment().set({
            hour: moment().get('hour') + 1,
          })
    ).toDate();

    newCase.deadline = moment(newCase.from).add(7, 'd').unix();
    newCase.RepeatableCase = new RepeatableCase();

    this.openCaseFormDialog(newCase, false);
  }

  async createCaseFromRepeatableCase(data: CaseData): Promise<void> {
    data.RepeatableCase.interval = null;
    data.RepeatableCase.createCaseTime = null;
    data.RepeatableCase.endDate = null;
    data.RepeatableCase.ChecklistTemplateId = null;

    const newCase = new Case(data);

    const repeatableCaseFileUsages = await firstValueFrom(
      this.fileUsageService.all('repeatable-case', data.RepeatableCase.id, 'files').pipe(take(1)),
    );
    const filesToCopy = repeatableCaseFileUsages.map((fileUsage) => fileUsage.File);

    this.openCaseFormDialog(newCase, false, filesToCopy);
  }

  openCaseFormDialog(c: Case | CaseData, createRepeatableCase?: boolean, files: File[] = []): void {
    const ref = this.dialog.open(CaseFormDialogComponent, {
      data: {
        case: c,
        files,
      },
    });

    // @TODO nasty hack, do more bettery
    setTimeout(() => {
      ref.componentInstance.formComponent.createRepeatableCase = createRepeatableCase;
    });

    const sub = ref.afterClosed().subscribe((res: Case) => {
      if (res?.id || res?.RepeatableCase?.id) {
        this.fetchData();
      }
    });

    this.subscription.add(sub);
  }

  viewCase(c: CaseData, menuTrigger?: MatMenuTrigger): void {
    if (menuTrigger) {
      menuTrigger.closeMenu();
    }

    this.subscription.add(
      this.dialog
        .open(CaseViewDialogComponent, {
          data: {
            caseId: c.id,
            case: c,
          },
        })
        .afterClosed()
        .subscribe({
          next: (res) => {
            if (res?.case) {
              c = {
                ...c,
                ...res.case,
              };
            }

            if (res?.navigate) {
              void this.router.navigate(res.navigate.commands, res.navigate.extras);
            }
          },
        }),
    );
  }

  editRepeatableCase(data: CaseData): void {
    const origRc = this.repeatableCases.find((rc) => rc.id === data.RepeatableCase?.id);

    origRc.case.Contractors = origRc?.RepeatableCaseContractors?.map((rcc) => new Contractor(rcc));

    const origRcData: Case = {
      ...origRc.case,
      RepeatableCase: origRc,
    };

    this.openCaseFormDialog(origRcData, true);
  }

  deleteRepeatableCase(data: CaseData): void {
    this.subscription.add(
      this.repeatableCaseService.delete(data.RepeatableCase).subscribe({
        next: () => {
          snack(t('Deleted'));
          this.fetchData();
        },
        error: (err) => {
          snackErr(t('Could not delete'), err);
        },
      }),
    );
  }

  changeCurrent(direction: 'backwards' | 'forwards'): void {
    const tempM = moment().set({
      date: this.date,
      month: this.month,
      year: this.year,
    });

    direction === 'backwards' ? tempM.subtract(1, this.view) : tempM.add(1, this.view);

    this.date = tempM.date();
    this.week = tempM.isoWeek();
    this.month = tempM.month();
    this.year = tempM.year();

    this.updateQueryparams();
  }

  today(): void {
    this.date = moment().date();
    this.week = moment().isoWeek();
    this.month = moment().month();
    this.year = moment().year();

    this.updateQueryparams();
  }

  goToView(view: CalendarView, slot?: CalenderSlot): void {
    if (slot?.moment) {
      if (view === this.calenderView.Day) {
        this.date = slot.moment.date();
        this.week = slot.moment.isoWeek();
        this.month = slot.moment.month();
        this.year = slot.moment.year();
      }

      if (view === this.calenderView.Week) {
        this.date = slot.moment.date();
        this.week = slot.weekNumber;
        this.month = slot.moment.month();
        this.year = slot.moment.year();
      }
    }

    this.view = view;

    this.updateQueryparams();
  }

  updateView(): void {
    this.loading = true;

    const month = moment().month(this.month);

    this.calendarRows = [];
    this.rowsCases = [];
    this.monthName = moment.isMoment(month) ? month.format('MMMM') : null;
    this.todayTooltip = moment().format('dddd, Do MMMM');
    this.todaym = moment();
    this.calendarRows = [...this.buildCalendarMonthView()];
    this.firstSlotInMonth = this.calendarRows[0]?.slots[1];
    this.lastSlotInMonth = this.calendarRows[this.numRows - 1]?.slots[7];

    this.fetchCases.next();
  }

  buildCalendarMonthView(): CalendarRow[] {
    const m = moment().month(this.month).year(this.year);

    if (moment.isMoment(m)) {
      const rows: CalendarRow[] = [];

      const daysInMonth = m.clone().daysInMonth();
      const startOfMonthDay = m.clone().startOf('month').day();
      const endOfMonthDay = m.clone().endOf('month').day();

      const prevMonth = moment(m).month(m.month() - 1);
      const nextMonth = moment(m).month(m.month() + 1);

      this.numRows = 5;

      // month ends on sunday and daysInMonth is 28 (february) -> exactly 4 rows
      if (endOfMonthDay === 0 && daysInMonth === 28) {
        this.numRows = 4;
      }

      // in some cases there will be 6 rows instead of 5/4
      // happens if startOfMonthDay is sunday "0" and daysInMonth is greater than 29
      // or startOfMonthDay is saturday "6" and daysInMonth is greater than 30
      if ((startOfMonthDay === 0 && daysInMonth > 29) || (startOfMonthDay === 6 && daysInMonth > 30)) {
        this.numRows = 6;
      }

      for (let i = 0; i < this.numRows; i++) {
        const row: CalendarRow = { slots: [] };

        for (let k = 0; k < 7; k++) {
          // first row
          // calendar must include dates from previoes month -> day 1 is monday
          if (i === 0 && (startOfMonthDay > 1 || startOfMonthDay === 0) && moment.isMoment(prevMonth)) {
            const daysFromPrev = startOfMonthDay === 0 ? 6 : startOfMonthDay - 1;
            const daysInPrevMonth = prevMonth.clone().daysInMonth();

            if (k < daysFromPrev) {
              const date = daysInPrevMonth - k;

              row.slots.unshift({
                date,
                moment: moment(prevMonth.date(date)),
              });
            } else {
              const date = 1 + (k - daysFromPrev);

              row.slots.push({
                date,
                moment: moment(m.date(date)),
              });
            }
          } else if (i === 0 && startOfMonthDay === 1) {
            const date = k + 1;

            row.slots.push({
              date,
              moment: moment(m.date(date)),
            });
          }

          // fill all rows in between first and last row
          if (i > 0 && i < this.numRows - 1) {
            const date = rows[i - 1]?.slots[7]?.date + (k + 1);

            row.slots.push({
              date,
              moment: moment(m.date(date)),
            });
          }

          // last row
          // calendar must include dates from next month -> day 0 is sunday
          if (i === this.numRows - 1 && endOfMonthDay > 0 && moment.isMoment(nextMonth)) {
            const daysFromNext = 7 - endOfMonthDay;

            if (k >= daysFromNext) {
              const date = daysInMonth - (k - daysFromNext);

              row.slots.unshift({
                date,
                moment: moment(m.date(date)),
              });
            } else {
              const date = k + 1;

              row.slots.push({
                date,
                moment: moment(nextMonth.date(date)),
              });
            }
          } else if (i === this.numRows - 1 && endOfMonthDay === 0) {
            const date = rows[i - 1]?.slots[7]?.date + (k + 1);

            row.slots.push({
              date,
              moment: moment(m.date(date)),
            });
          }

          // add weeknumber slot to row
          if (k === 6 && row.slots.length) {
            const firstDayOfWeek = row.slots[0].date;
            const currentMonth = row.slots[0].moment.month();
            const currentYear = row.slots[0].moment.year();

            const mm = moment().set({
              date: firstDayOfWeek,
              month: currentMonth,
              year: currentYear,
            });
            const week = mm.isoWeek();

            row.slots.unshift({
              weekNumber: week,
              moment: mm,
            });
          }
        }

        if (row.slots.length) {
          rows.push(row);
        }

        // add weekdays to first row
        if (i === 0 && rows.length && rows[0].slots?.length) {
          for (let k = 0; k < 7; k++) {
            const slot = rows[0].slots[k + 1];

            slot.day = moment(slot.moment).format('ddd');
          }
        }
      }

      return rows;
    }

    return [];
  }

  fetchData(): void {
    let from = this.firstSlotInMonth?.moment?.toISOString();
    let to = this.lastSlotInMonth?.moment?.toISOString();

    if (this.view === this.calenderView.Day) {
      const tempM = moment().set({
        hour: 1,
        date: this.date,
        month: this.month,
        year: this.year,
      });

      from = tempM.toISOString();
      to = tempM.toISOString();
    }

    if (this.view === this.calenderView.Week) {
      let week = this.week;

      if (this.month === 0 && (this.week === 52 || this.week === 53)) {
        week = 0;
      }

      const tempM = moment().set({
        isoWeek: week,
        month: this.month,
        year: this.year,
      });

      from = tempM.startOf('isoWeek').toISOString();
      to = tempM.endOf('isoWeek').toISOString();
    }

    const params: CaseParams = {
      ...(this.queryParams as CaseParams),
      from,
      to,
      calendar: true,
    };

    this.caseParams = transformQueryParams(params) as CaseParams;

    if (!this.caseParams.hasOwnProperty('showOpen')) {
      this.caseParams.showOpen = true;
    }

    const sub = forkJoin([
      this.caseService.getCases(this.caseParams),
      this.repeatableCaseService.getRepeatableCases(this.caseParams),
    ]).subscribe({
      next: ([cases, repeatableCases]) => {
        this.cases = cases || [];
        this.repeatableCases = (repeatableCases || []).map((rc) => new RepeatableCase(rc));
        this.futureRepeatableCases = [].concat(
          ...this.repeatableCases.map((rc) => RepeatableCase.createOccurrences(rc, this.lastSlotInMonth?.moment, 'd')),
        );

        this.rowsCases = [...this.buildRowsCases()];
        this.loading = false;
      },
      error: (err) => {
        snackErr(t('Failed to get cases'), err);
        this.loading = false;
      },
    });

    this.subscription.add(sub);
  }

  // private createFutureRepeatableCase(rc: RepeatableCase, i = 0, values = []): RepeatableCase[] {
  //   const newRc = cloneDeep(rc);
  //   const interval = {
  //     daily: 'day',
  //     weekly: 'week',
  //     monthly: 'month',
  //     quarterly: 'quarter',
  //     yearly: 'year',
  //   };

  //   newRc.case.from = newRc.case.from ? moment(newRc.case.from) : moment(newRc.case.createdAt);
  //   newRc.case.to = newRc.case.to ? moment(newRc.case.to) : moment(moment.unix(newRc.case.deadline));
  //   newRc.endDate = moment(newRc.endDate);

  //   if (newRc.interval) {
  //     const intervalValue = interval[newRc.interval] as unitOfTime.DurationConstructor;

  //     newRc.case.from.add(1, intervalValue);
  //     newRc.case.to.add(1, intervalValue);

  //     if (
  //       (
  //         !newRc.case.from.isSameOrBefore(this.lastSlotInMonth?.moment, 'd')
  //       ) ||
  //       (
  //         newRc.endDate.isValid() && !newRc.case.from.isSameOrBefore(newRc.endDate, 'd')
  //       )
  //     ) {
  //       return values;
  //     } else {
  //       values.push(newRc);

  //       return this.createFutureRepeatableCase(newRc, ++i, values);
  //     }
  //   }

  //   return values;
  // }

  buildRowsCases(): RowCases[] {
    const rowsCases: RowCases[] = [];

    this.caseDatas = [...this.repeatableCases, ...this.futureRepeatableCases, ...this.cases].map(
      (d: Case | RepeatableCase) => {
        if (d instanceof RepeatableCase) {
          d.case.from = d.case.from ?? d.case.createdAt;
          d.case.to = d.case.to ?? moment.unix(d.case.deadline).toDate();

          return {
            ...d.case,
            RepeatableCase: d,
            createdAtMoment: moment(d.createdAt),
            deadLineMoment: moment(moment.unix(d.case.deadline)),
          };
        } else {
          d.from = d.from ?? d.createdAt;
          d.to = d.to ?? moment.unix(d.deadline).toDate();

          return {
            ...d,
            createdAtMoment: moment(d.createdAt),
            deadLineMoment: moment(moment.unix(d.deadline)),
          };
        }
      },
    );

    for (let i = 0; i < this.numRows; i++) {
      const rowCases: RowCases = { cases: [] };
      const row = this.calendarRows[i];
      const startOfWeekm = row?.slots[1]?.moment; // monday current row
      const endOfWeekm = row?.slots[7]?.moment; // sunday current row

      this.caseDatas
        .sort((a, b) => {
          if (a.from < b.from) {
            return -1;
          }

          if (a.from > b.from) {
            return 1;
          }

          return 0;
        })
        .filter(
          (vc) =>
            ((moment(vc.from).isSameOrAfter(startOfWeekm, 'd') && moment(vc.from).isSameOrBefore(endOfWeekm, 'd')) || // from this week
              (moment(vc.to).isSameOrAfter(startOfWeekm, 'd') && moment(vc.to).isSameOrBefore(endOfWeekm, 'd')) || // to this week
              (moment(vc.from).isBefore(startOfWeekm, 'd') && moment(vc.to).isAfter(endOfWeekm, 'd'))) && // from before to after this week
            moment(vc.from).isBefore(vc.to), // from must be before to -> should not be possible to set from after to
        )
        .map((vc, j) => {
          const rc = Object.assign({}, vc);

          rc.sortOrder = j;

          // starts this week -> rc.created inbetween start/end of week
          if (moment(rc.from).isSameOrAfter(startOfWeekm, 'd') && moment(rc.from).isSameOrBefore(endOfWeekm, 'd')) {
            const createdAtDay = moment(rc.from).day();

            rc.startDay = !createdAtDay ? 6 : createdAtDay - 1;
          }

          // ends this week -> rc.deadline inbetween start/end of week
          if (moment(rc.to).isSameOrAfter(startOfWeekm, 'd') && moment(rc.to).isSameOrBefore(endOfWeekm, 'd')) {
            rc.length = (moment(rc.to).day() || 7) - (rc.startDay || 0);
            rc.startDay = rc.startDay || 0;
          } else {
            rc.length = (endOfWeekm?.day() || 7) - (rc.startDay || 0);
          }

          // started before this week and ends after this week
          if (moment(rc.from).isBefore(startOfWeekm, 'd') && moment(rc.to).isAfter(endOfWeekm, 'd')) {
            rc.startDay = 0;
            rc.length = 7;
          }

          // starts and ends same day
          if (moment(rc.from).isSame(rc.to, 'd')) {
            rc.length = 1;
          }

          rowCases.cases.push(rc);

          // @TODO reorder cases so that they use available space in calendar
        });

      rowsCases.push(rowCases);
    }

    return rowsCases;
  }

  bogusSort(): number {
    return 0;
  }

  updateQueryparams(replaceUrl = false): void {
    const queryParams: Params = {
      date: this.date,
      week: this.week,
      month: this.month + 1,
      year: this.year,
      view: this.view,
      calendar: true,
    };

    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl,
      state: { skipScroll: true },
      preserveFragment: true,
    });
  }

  isSameDay(one: Date, two: Date): boolean {
    return moment(one).isSame(two, 'd');
  }

  get icalUrl(): string {
    const query = objectToQuery(omit(this.caseParams, ['date', 'week', 'month', 'year', 'from', 'to', 'view']));

    const userId = this.userService?.profile?.id ?? 0;

    if (!(userId > 0)) {
      return '';
    }

    const api = environment.production ? 'https://ical.apexapp.io' : 'http://app.heimdaltest.no:3002';

    return `${api}/${this.userService.profile.id}/${this.userService.profile.ical}?${query}`;
  }
}
