import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual } from 'lodash-es';
import { Tenancy } from 'projects/apex/src/app/models/tenancy';
import { HttpExtra } from 'projects/apex/src/app/services/http/http-extra';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { t } from '../../../../components/translate/translate.function';
import { TenancyService } from './tenancy.service';

@Component({
  selector: 'apex-tenancies',
  templateUrl: './tenancies.component.html',
})
export class TenanciesComponent implements OnInit, OnDestroy {
  @Input() objectId: number;
  @Input() tenancies: Tenancy[];
  @Input() box: boolean;
  @Input() fab: boolean;
  @Input() expanded: boolean;
  @Input() view: 'list' | 'cards' = 'list';
  @Input() portal: boolean;

  loading = false;

  count = 0;
  page = 0;
  limit: number;
  pageSizeOptions: number[];

  req$ = new Subject<{ page: number; limit: number }>();
  sub = this.req$
    .pipe(
      switchMap((d) => {
        this.loading = true;

        const extra: HttpExtra = {
          params: {
            page: String(d.page ?? this.page),
            limit: String(d.limit ?? this.limit),
          },
        };

        if (this.portal) {
          return this.service.queryAsClient(extra);
        }

        return this.objectId ? this.service.queryWithObject(this.objectId, extra) : this.service.query(extra);
      }),
    )
    .subscribe({
      next: (res) => {
        if (res) {
          this.count = res.count;
          this.page = res.page;
          this.limit = res.limit;

          this.tenancies = res.Collection;
        }

        this.loading = false;
      },
    });

  get title(): string {
    return t('Tenancies ({count})', { count: this.tenancies?.length ?? 0 });
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: TenancyService,
  ) {}

  ngOnInit(): void {
    this.limit = this.box ? 5 : 25;
    this.pageSizeOptions = this.box ? [5, 10, 25, 50] : [25, 50, 100];
    this.objectId = this.objectId ?? Number(this.route?.snapshot?.params?.pid);
    this.sub.add(
      this.route.queryParams
        .pipe(
          map((params) => ({
            tenancyPage: Number(params.tenancyPage) || 0,
            tenancyLimit: Number(params.tenancyLimit) || this.limit,
          })),
          distinctUntilChanged((x, y) => isEqual(x, y)),
        )
        .subscribe({
          next: (qp) => {
            this.req$.next({
              page: qp.tenancyPage ?? this.page,
              limit: qp.tenancyLimit ?? this.limit,
            });
          },
        }),
    );
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  getTopObjectName(tenancy: Tenancy): string {
    return tenancy.Objects?.find((o) => o.ParentId === null)?.name ?? '';
  }

  changePage(e: PageEvent): void {
    void this.router.navigate([], {
      queryParams: {
        tenancyPage: e.pageIndex,
        tenancyLimit: e.pageSize,
      },
      queryParamsHandling: 'merge',
    });
  }

  get showPaginator(): boolean {
    return this.count && this.count > this.limit;
  }
}
