import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import { cloneDeep } from 'lodash-es';
import { FileViewerComponent } from 'projects/apex/src/app/components/file-viewer/file-viewer.component';
import { ClientPageService } from 'projects/apex/src/app/features/client-page/client-page.service';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { Marking } from 'projects/apex/src/app/models/marking';
import { MarkingService } from 'projects/apex/src/app/services/marking/marking.service';
import { Observable, Subject, Subscription, forkJoin, from, of, timer } from 'rxjs';
import { concatMap, debounce, switchMap } from 'rxjs/operators';
import { User } from '../../models/user';
import { snack } from '../../modules/snack.module';
import { UserService } from '../../services/user/user.service';
import { constants } from '../../utils/constants';
import { fileUsageExpired, getUserRelationForCase, presentDownload, sortFileUsages } from '../../utils/functions';
import { AutocompleteTypes } from '../autocomplete/autocomplete.types';
import { FileUsageService } from '../file-usage/file-usage.service';
import { t } from '../translate/translate.function';
import { FileUsageViewerData, FileUsageViewerMode } from './file-usage-viewer.types';

@Component({
  selector: 'apex-file-usage-viewer',
  templateUrl: './file-usage-viewer.component.html',
})
export class FileUsageViewerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fileUsageViewerData?: FileUsageViewerData;

  @Output() selectedIndexChanged = new EventEmitter<number>();

  @Output() markingsChange = new EventEmitter<Marking[]>();
  @Output() markingChange = new EventEmitter<{ marking: Marking; fileUsage: FileUsage }>();
  @Output() markingClicked = new EventEmitter<Marking>();
  @Output() markingEnter = new EventEmitter<{ marking: Marking; target: HTMLElement }>();
  @Output() markingLeave = new EventEmitter<{ marking: Marking; target: HTMLElement }>();

  @ViewChild(FileViewerComponent) fileViewer: FileViewerComponent;
  @ViewChild('fileContainer') fileContainer: ElementRef;

  fileUsage: FileUsage;

  FileUsageViewerModes = FileUsageViewerMode;

  profile: User;

  screenWidth: number;
  loadingMarkings: boolean;
  downloading: boolean;

  deleteAllSub: Subscription;
  canvasEventsSubscription: Subscription;

  getUserRelationForCase = getUserRelationForCase;

  subs: Subscription[];

  getFileUsageRequest$ = new Subject<FileUsage>();
  getFileUsageResponse$: Observable<FileUsage> = this.getFileUsageRequest$.pipe(
    debounce((fu) => (this.fileUsage ? timer(constants.requestDebounceTime) : of(fu))),
    switchMap((f: FileUsage) => {
      if (fileUsageExpired(f)) {
        return this.getFileUsage(f.id);
      }

      return of(f);
    }),
  );

  get downloadTooltip(): string {
    return t('Download {fileName}', { fileName: this.fileUsage?.fileName ?? '' });
  }

  get tooltipForEditLocked(): string {
    if (this.fileUsageViewerData.editLocked) {
      return t('Unlock');
    }

    return t('Lock');
  }

  constructor(
    private fileUsageService: FileUsageService,
    private userService: UserService,
    @Optional() private markingService: MarkingService,
    @Optional() private clientService: ClientPageService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: FileUsageViewerData,
    @Optional() public dialogRef: MatDialogRef<FileUsageViewerComponent>,
  ) {}

  ngOnInit(): void {
    this.subs = [
      this.getFileUsageResponse$.subscribe({
        next: (fileUsage: FileUsage) => {
          const fu = this.fileUsageViewerData?.fileUsages?.find((f: FileUsage) => f.id === fileUsage.id);

          if (fu) {
            Object.assign(fu, fileUsage);
          }

          this.setFileUsage(fu);
        },
      }),
    ];

    if (!this.fileUsageViewerData?.client) {
      this.userService.filteredProfile$.subscribe({
        next: (user: User) => {
          this.profile = user;
        },
      });
    }

    this.screenWidth = window.innerWidth;
    this.ngOnChanges();
  }

  ngOnChanges(): void {
    if (this.subs) {
      if (this.fileUsageViewerData?.fileUsages?.length) {
        const origSelected = this.fileUsageViewerData.fileUsages[this.fileUsageViewerData.startingIndex || 0];

        this.fileUsageViewerData.fileUsages = sortFileUsages(this.fileUsageViewerData.fileUsages);

        const idx = this.fileUsageViewerData.fileUsages.findIndex((f) => f.id === origSelected?.id);

        let fileUsage = this.fileUsageViewerData.fileUsages[0];

        if (idx > -1 && this.fileUsageViewerData.startingIndex > -1) {
          fileUsage = this.fileUsageViewerData.fileUsages[idx];
          this.fileUsageViewerData.startingIndex = idx;
        }

        if (fileUsage) {
          this.getFileUsageRequest$.next(fileUsage);
        }

        this.getMarkings();
      } else {
        this.fileUsage = undefined;
      }
    }
  }

  createMarking(marking: Marking): void {
    if (this.fileUsageViewerData.disabled) {
      return;
    }

    const max = this.fileUsageViewerData?.markingData?.max;

    if ((this.fileUsageViewerData?.modelData?.model && !max) || this.getMarkingsCount() < max) {
      marking.model = this.fileUsageViewerData.modelData.model;
      marking.modelId = this.fileUsageViewerData.modelData.modelId;
      marking.FileUsageId = this.fileUsage.id;
      this.fileUsage.Markings = this.fileUsage?.Markings ? this.fileUsage.Markings : [];
      this.fileUsage.Markings.push(marking);

      if (this.fileUsageViewerData.modelData.modelId && !this.fileUsageViewerData.saveDisabled) {
        this.save(marking).subscribe({
          next: (savedMarking: Marking) => {
            Object.assign(marking, savedMarking);
            this.markingsChange.emit(this.getAllMarkings());
            this.markingChange.emit({
              marking: savedMarking,
              fileUsage: this.fileUsage,
            });
          },
          error: () => {
            const idx = this.fileUsage.Markings.indexOf(marking);

            if (idx !== -1) {
              this.fileUsage.Markings.splice(idx, 1);
            }
          },
        });
      } else {
        this.markingsChange.emit(this.getAllMarkings());
        this.markingChange.emit({
          marking,
          fileUsage: this.fileUsage,
        });
      }
    }
  }

  updateMarking(marking: Marking): void {
    if (this.fileUsageViewerData.disabled) {
      return;
    }

    if (marking && this.fileUsageViewerData?.editable && !this.fileUsageViewerData.saveDisabled) {
      if (marking.id) {
        const m = cloneDeep(marking);

        delete m.FileUsage;
        delete m.Case;
        this.save(m).subscribe((savedMarking: Marking) => {
          marking = savedMarking;
          this.markingsChange.emit(this.getAllMarkings());
          this.markingChange.emit({
            marking,
            fileUsage: this.fileUsage,
          });
        });
      } else {
        this.markingsChange.emit(this.getAllMarkings());
        this.markingChange.emit({
          marking,
          fileUsage: this.fileUsage,
        });
      }
    }
  }

  deleteMarking(marking: Marking): void {
    this.markingClicked.emit(marking);

    if (
      this.fileUsageViewerData?.editable &&
      !this.fileUsageViewerData.editLocked &&
      !this.fileUsageViewerData.deleteDisabled
    ) {
      if (marking.id && !this.fileUsageViewerData.saveDisabled) {
        this.delete(marking).subscribe(() => {
          const idx = this.fileUsage.Markings.indexOf(marking);

          if (idx !== -1) {
            this.fileUsage.Markings.splice(idx, 1);
            this.markingsChange.emit(this.getAllMarkings());
          }
        });
      } else {
        const idx = this.fileUsage.Markings.indexOf(marking);

        if (idx !== -1) {
          this.fileUsage.Markings.splice(idx, 1);
          this.markingsChange.emit(this.getAllMarkings());
        }
      }
    }
  }

  getMarkings(): void {
    if (this.fileUsageViewerData?.fileUsages && this.fileUsageViewerData.modelData) {
      const fileUsageWithoutMarkings = this.fileUsageViewerData.fileUsages.filter(
        (f) => !!(!f.Markings || !f.Markings.length),
      );

      this.loadingMarkings = true;

      from(fileUsageWithoutMarkings)
        .pipe(
          concatMap((fileUsage: FileUsage) =>
            forkJoin([
              of(fileUsage),
              this.fileUsageViewerData.modelData.modelId
                ? this.getMarkingsCall(
                    fileUsage.self,
                    fileUsage.selfId,
                    fileUsage.name,
                    this.fileUsageViewerData.modelData.model,
                    this.fileUsageViewerData.modelData.modelId,
                    fileUsage.id,
                  )
                : of(undefined),
            ]),
          ),
        )
        .subscribe({
          next: ([fileUsage, markings]) => {
            this.fileUsageViewerData.fileUsages.find((fu) => fu.id === fileUsage.id).Markings = markings
              ? markings
              : [];
            fileUsage.Markings = markings ? markings : [];
          },
          complete: () => {
            this.markingsChange.emit(this.getAllMarkings());
            this.loadingMarkings = false;
            this.fileUsageViewerData.fileUsages = sortFileUsages(this.fileUsageViewerData.fileUsages);

            const fileUsage =
              this.fileUsageViewerData?.fileUsages[
                this.fileUsageViewerData?.startingIndex ? this.fileUsageViewerData.startingIndex : 0
              ];

            if (fileUsage) {
              this.getFileUsageRequest$.next(fileUsage);
            }
          },
        });
    }
  }

  delete(marking: Marking): Observable<Marking> {
    if (this.fileUsageViewerData?.client) {
      return this.clientService.deleteMarking(this.fileUsage, marking);
    } else {
      return this.markingService.deleteMarking(this.fileUsage, marking);
    }
  }

  save(marking: Marking): Observable<Marking> {
    if (this.fileUsageViewerData?.client) {
      return this.clientService.saveMarking(this.fileUsage, marking);
    } else {
      return this.markingService.saveMarking(this.fileUsage, marking);
    }
  }

  getFileUsage(id: number): Observable<FileUsage> {
    if (this.fileUsageViewerData?.client) {
      return this.clientService.getFileUsage(id);
    } else {
      return this.fileUsageService.get(id);
    }
  }

  getMarkingsCall(
    self: string,
    selfId: number | string,
    name: string,
    model: string,
    modelId: number,
    FileUsageId: number,
  ): Observable<Marking[]> {
    if (this.fileUsageViewerData?.client) {
      return this.clientService.getMarkings(self, selfId, name, model, modelId, FileUsageId);
    } else {
      return this.markingService.getMarkings(self, selfId, name, model, modelId, FileUsageId);
    }
  }

  getMarkingsCount(): number {
    let count = 0;

    if (this.fileUsageViewerData?.fileUsages) {
      this.fileUsageViewerData.fileUsages.forEach((fileUsage) => {
        count += fileUsage.Markings ? fileUsage.Markings.length : 0;
      });
    }

    return count;
  }

  setFileUsage(f: FileUsage): void {
    this.fileUsage = f;
    this.fileViewer?.resize();
  }

  getTotalMarkings(): number {
    let totalMarkings = 0;

    if (this.fileUsageViewerData?.fileUsages) {
      this.fileUsageViewerData.fileUsages.forEach((f) => {
        totalMarkings += f.Markings ? f.Markings.length : 0;
      });
    }

    return totalMarkings;
  }

  getAllMarkings(): Marking[] {
    return this.fileUsageViewerData?.fileUsages?.reduce<Marking[]>((p, c) => [...p, ...c.Markings], []);
  }

  toggleFileUsagePreviews(): void {
    this.fileUsageViewerData.hidePreviews = !this.fileUsageViewerData?.hidePreviews;
    setTimeout(() => {
      // resize file
    });
  }

  isDeleteAllowed(): boolean {
    return !(
      this.fileUsageViewerData?.mode !== 'mark' ||
      this.fileUsageViewerData?.deleteDisabled ||
      this.deleteAllSub ||
      this.fileUsageViewerData?.editLocked ||
      !(this.fileUsage && this.fileUsage.Markings && this.fileUsage.Markings.length) ||
      !this.fileUsageViewerData?.editable
    );
  }

  ngOnDestroy(): void {
    this.subs?.forEach((s) => s?.unsubscribe());
  }

  selfToAutocompleteType(self: string): AutocompleteTypes {
    return self as AutocompleteTypes;
  }

  downloadFileUsage(
    fileUsage: FileUsage,
    image?: ElementRef<HTMLImageElement>,
    box?: ElementRef<HTMLDivElement>,
  ): void {
    this.downloading = true;

    if (!html2canvas) {
      snack(t('html2canvas not loaded, please refresh'));

      this.downloading = false;

      return;
    } else {
      if (!this.fileUsage?.id) {
        snack(t('Please choose a valid fileUsage to to download'));

        this.downloading = false;

        return;
      }

      if (fileUsage?.File.type?.startsWith('image/') && fileUsage.Markings?.length) {
        if (!image || !box) {
          snack(t('Problem with FileUsage setup, error will be reported to APEX'));

          this.downloading = false;

          throw new Error('image or box missing when trying to downloadFileUsage with markings');
        }

        this.fileUsageService.get(this.fileUsage.id).subscribe({
          next: (fu: FileUsage) => {
            const img = new Image();

            img.crossOrigin = 'Anonymous';

            img.onload = (): void => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');

              canvas.width = img.width;
              canvas.height = img.height;
              ctx.drawImage(img, 0, 0);
              image.nativeElement.src = canvas.toDataURL();

              // setTimeout to wait for one Angular cycle
              setTimeout((): void => {
                void html2canvas(box.nativeElement).then((canvasWithMarkings: HTMLCanvasElement) => {
                  this.downloading = false;
                  canvasWithMarkings.toBlob((blob) => presentDownload(this.fileUsage.File.name, blob, 'image/png'));
                });
              });
            };

            img.src = fu.File.signed.url;
          },
        });

        return;
      }

      if (!fileUsage?.File?.signed?.withDispositionUrl) {
        snack(t('Trouble with your fileUsage, error will be reported to APEX'));

        this.downloading = false;

        throw new Error('downloadImage ran without fileUsage');
      }

      this.downloading = false;

      window.open(fileUsage.File.signed.withDispositionUrl, '_blank');
    }
  }
}
