import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { BoxModule } from '../../../components/box/box.module';
import { ChecklistDialogModule } from '../../../components/checklist-dialog/checklist-dialog.module';
import { ProgressModule } from '../../../components/progress/progress.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { HighlightPipeModule } from '../../../pipes/highlight/highlight-pipe.module';
import { ReduceHtmlToStringModule } from '../../../pipes/reduct-html-to-string/reduce-html-to-string.module';
import { ChecklistService } from '../../checklist/checklist.service';
import { ChecklistGroupService } from '../checklist-group.service';
import { ChecklistGroupChecklistModule } from '../checklist/checklist-group-checklist.module';
import { ChecklistGroupListModule } from '../list/list.module';
import { ChecklistGroupTreeModule } from '../tree/tree.module';
import { ChecklistGroupsComponent } from './groups.component';

@NgModule({
  declarations: [ChecklistGroupsComponent],
  exports: [ChecklistGroupsComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule,
    MatListModule,
    MatTooltipModule,
    ProgressModule,
    MatExpansionModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    ChecklistGroupListModule,
    ChecklistGroupTreeModule,
    ChecklistGroupChecklistModule,
    BoxModule,
    ChecklistDialogModule,
    ReduceHtmlToStringModule,
    HighlightPipeModule,
  ],
  providers: [ChecklistGroupService, ChecklistService],
})
export class ChecklistGroupsModule {}
