import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { pick } from 'lodash-es';
import { CollectionResponse, EntityResponse } from 'projects/apex/src/app/utils/types';
import { Observable } from 'rxjs';
import { map, take, timeoutWith } from 'rxjs/operators';
import { t } from '../../components/translate/translate.function';
import { Case, Contractor } from '../../models/case';
import { CaseContractor } from '../../models/case-contractor';
import { CaseLog } from '../../models/case-log';
import { CaseParams } from '../../models/case-params';
import { snack } from '../../modules/snack.module';
import { HttpExtra } from '../../services/http/http-extra';
import { HttpService } from '../../services/http/http.service';
import { transformQueryParams } from '../../utils/functions';
import { MeterValue } from '../object/project/meter/meter.model';
import { caseParamModelKeys, caseParamSettingKeys } from './case.types';
import { CaseCollectionName, CaseCollectionRole } from './collection/case-collection.types';

const timeoutMS = 55 * 1000; // 55 seconds
const timeoutMessage = t('Fetching took too long to finish, please change your filters and try again');

const observeValue = <T>(value: T): Observable<T> =>
  new Observable((subscriber) => {
    snack(timeoutMessage);

    subscriber.next(value);
    subscriber.complete();
  });

type ToggleReturn = {
  value: 'client' | 'contractor';
};

@Injectable()
export class CaseService extends HttpService<Case> {
  public route = 'case';
  public logRoute = 'log';

  constructor(protected http: HttpClient) {
    super(http);
  }

  getCases(p: Params): Observable<Case[]> {
    const cp = this.removeOtherParams(p);

    return this.http
      .get<Case[]>(`${this.apiUrl}/case`, {
        headers: this.options.headers,
        withCredentials: this.options.withCredentials,
        params: cp,
      })
      .pipe(timeoutWith(timeoutMS, observeValue([])), take(1));
  }

  get(id: number): Observable<Case> {
    return this.http.get<Case>(`${this.apiUrl}/${this.route}/${id}`, this.options);
  }

  getCaseCount(p: Params): Observable<{ count: number }> {
    const cp = this.removeOtherParams(p);

    return this.http
      .get<{ count: number }>(`${this.apiUrl}/casecount`, {
        headers: this.options.headers,
        withCredentials: this.options.withCredentials,
        params: cp,
      })
      .pipe(timeoutWith(timeoutMS, observeValue({ count: 0 })), take(1));
  }

  addContractor(
    CaseId: number,
    ContractorId: number,
    notifyContractorMail = false,
    notifyContractorSms = false,
  ): Observable<Case> {
    return this.http.post<Case>(
      `${this.apiUrl}/${this.route}/${CaseId}/addContractor`,
      {},
      Object.assign({}, this.options, {
        params: { ContractorId, notifyContractor: notifyContractorMail, notifyContractorSms },
      }),
    );
  }

  removeContractor(CaseId: number, ContractorId: number): Observable<Case> {
    return this.http.post<Case>(
      `${this.apiUrl}/${this.route}/${CaseId}/removeContractor`,
      {},
      Object.assign({}, this.options, { params: { ContractorId } }),
    );
  }

  attachFiles(c: Case): Observable<Case> {
    return this.http.post<Case>(
      `${this.apiUrl}/${this.route}/${c.id}/attachFiles`,
      {
        id: c.id,
        files: c.files,
      },
      this.options,
    );
  }

  removeFiles(c: Case): Observable<Case> {
    return this.http.post<Case>(
      `${this.apiUrl}/${this.route}/${c.id}/removeFiles`,
      {
        id: c.id,
        files: c.files,
      },
      this.options,
    );
  }

  deny(c: Case, message: string, visibleForClient = false): Observable<Case> {
    return this.http.post<Case>(
      `${this.apiUrl}/${this.route}/${c.id}/deny`,
      {
        message,
        visibleForClient,
      },
      this.options,
    );
  }

  accept(c: Case): Observable<Case> {
    const cleanedCase = pick(c, ['id']);

    return this.http.post<Case>(`${this.apiUrl}/${this.route}/${c.id}/accept`, cleanedCase, this.options);
  }

  complete(c: Case, message?: string, visibleForClient?: boolean): Observable<Case> {
    return this.http.post<Case>(
      `${this.apiUrl}/${this.route}/${c.id}/complete`,
      {
        message,
        visibleForClient,
      },
      this.options,
    );
  }

  finish(c: Case): Observable<Case> {
    const cleanedCase = pick(c, ['id']);

    return this.http.post<Case>(`${this.apiUrl}/${this.route}/${c.id}/finish`, cleanedCase, this.options);
  }

  reopen(c: Case): Observable<Case> {
    const cleanedCase = pick(c, ['id']);

    return this.http.post<Case>(`${this.apiUrl}/${this.route}/${c.id}/reopen`, cleanedCase, this.options);
  }

  markCasesAsRead(params: CaseParams): Observable<number> {
    return this.http
      .get<{ count: number }>(`${this.apiUrl}/${this.route}/markasread`, Object.assign({}, this.options, { params }))
      .pipe(map((res) => res.count));
  }

  markAsUnread(c: Case): Observable<Case> {
    return this.http.get<Case>(`${this.apiUrl}/${this.route}/${c.id}/markasunread`, this.options);
  }

  markAsRead(c: Case): Observable<Case> {
    return this.http.get<Case>(`${this.apiUrl}/${this.route}/${c.id}/markasread`, this.options);
  }

  archive(c: Case): Observable<Case> {
    return this.http.get<Case>(`${this.apiUrl}/${this.route}/${c.id}/archive`, this.options);
  }

  unarchive(c: Case): Observable<Case> {
    return this.http.get<Case>(`${this.apiUrl}/${this.route}/${c.id}/unarchive`, this.options);
  }

  export(p: { template: 'full' | 'minimal' } & CaseParams): Observable<Case> {
    const cp = this.removeOtherParams(p);

    cp['template'] = p.template;

    return this.http.get<Case>(`${this.apiUrl}/${this.route}/export`, Object.assign({}, this.options, { params: cp }));
  }

  newCaseFromParams(params: Params): Case {
    const cp = transformQueryParams(params) as CaseParams;
    const c = new Case();

    // Unset to prevent ContractorId
    cp.ContractorId = null;

    Object.keys(caseParamModelKeys)
      .filter((k) => cp[k])
      .forEach((k) => {
        c[caseParamModelKeys[k]] = Array.isArray(cp[k]) ? cp[k][0] : cp[k];
      });

    return c;
  }

  removeOtherParams(p: Params): Params {
    const cp = {};

    []
      .concat(Object.keys(caseParamModelKeys), caseParamSettingKeys)
      .filter((k) => p[k])
      .forEach((k) => {
        cp[k] = p[k];
      });

    return cp;
  }

  // CaseLog

  postCaseLog(CaseId: number, caseLog: CaseLog): Observable<CaseLog> {
    return caseLog.id
      ? this.http.post<CaseLog>(
          `${this.apiUrl}/${this.route}/${CaseId}/${this.logRoute}/${caseLog.id}`,
          caseLog,
          this.options,
        )
      : this.http.post<CaseLog>(`${this.apiUrl}/${this.route}/${CaseId}/${this.logRoute}`, caseLog, this.options);
  }

  toggleCaseLog(CaseId: number, caseLogId: number, type: 'client' | 'contractor'): Observable<ToggleReturn> {
    return this.http.get<ToggleReturn>(
      `${this.apiUrl}/${this.route}/${CaseId}/${this.logRoute}/${caseLogId}/toggle/${type}`,
      this.options,
    );
  }

  postContractor(CaseId: number, contractor: Contractor): Observable<CaseContractor> {
    return this.http.post<CaseContractor>(
      `${this.apiUrl}/${this.route}/${CaseId}/contractor/${contractor.id}`,
      contractor,
      this.options,
    );
  }

  postMeterValues(CaseId: number, meterValue: MeterValue): Observable<EntityResponse<MeterValue>> {
    return this.http.post<EntityResponse<MeterValue>>(
      `${this.apiUrl}/${this.route}/${CaseId}/meter/${meterValue.MeterId}/value`,
      meterValue,
      this.options,
    );
  }

  queryModelCase(
    model: CaseCollectionName,
    modelId: number,
    role: CaseCollectionRole,
    extra?: HttpExtra,
  ): Observable<CollectionResponse<Case>> {
    const options = this.options;

    if (extra) {
      options.params = extra.params;
    }

    return this.http.get<CollectionResponse<Case>>(`${this.apiUrl}/${model}/${modelId}/case/${role}`, options);
  }
}
