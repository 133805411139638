import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import * as rg4js from 'raygun4js';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { UserService } from '../services/user/user.service';

rg4js('apiKey', 'SpjAcsA00PmkjLUK87u4A');

if (environment.production) {
  rg4js('enableCrashReporting', true);
}
// rg4js('options', {
//   debugMode: true,
// });

export const rg4jsFunc = rg4js;

@Injectable()
export class RaygunErrorHandler implements ErrorHandler {
  constructor(private userService: UserService) {
    this.userService.profile$.subscribe({
      next: (user: User) => {
        if (user) {
          rg4js('setUser', {
            identifier: user.id,
            isAnonymous: false,
            fullName: user.name,
          });
        }
      },
    });
  }

  handleError(error: Error): void {
    console.error(error);

    if (error instanceof HttpErrorResponse) {
      return;
    }

    if (environment.production) {
      rg4js('send', {
        error,
      });
    }
  }
}
