import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { flatten } from 'lodash-es';
import { take } from 'rxjs/operators';
import { FileUsage } from '../../models/file-usage';
import { Marking, MarkingModelType } from '../../models/marking';
import { User } from '../../models/user';
import { URLCachePipe } from '../../pipes/url-cache/url-cache.pipe';
import { UserService } from '../../services/user/user.service';
import { constants } from '../../utils/constants';
import { getUserRelationForCase, sortFileUsages } from '../../utils/functions';
import { FileUsageViewerDialogComponent } from '../file-usage-viewer/file-usage-viewer-dialog.component';
import { FileUsageViewerData, FileUsageViewerMode } from '../file-usage-viewer/file-usage-viewer.types';

export class EmbeddedMarking extends Marking {
  offsetX: number;
  offsetY: number;
}

@Component({
  selector: 'apex-embedded-markings-viewer',
  templateUrl: './embedded-markings-viewer.component.html',
})
export class EmbeddedMarkingsViewerComponent implements OnInit, OnChanges {
  @Input() fileUsages: FileUsage[] = [];
  @Input() model: MarkingModelType;
  @Input() modelId: number;
  @Input() min = 0;
  @Input() max;
  @Input() disabled = false;

  @Output() markingsChanged = new EventEmitter<Marking[]>();

  getUserRelationForCase = getUserRelationForCase;

  profile: User;

  fileUsage: FileUsage;
  embeddedMarkings: EmbeddedMarking[] = [];

  thumbIcon: string;
  thumbUrl: string;

  img: HTMLImageElement;

  maxWidth: number;
  maxHeight: number;

  loading = true;
  error = false;

  constructor(
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userService.filteredProfile$.pipe(take(1)).subscribe({
      next: (profile: User) => {
        this.profile = profile;
      },
    });
    this.getFirstMarkingAndPreview();
  }

  ngOnChanges(): void {
    this.fileUsage = null;

    if (this.fileUsages) {
      this.fileUsages = sortFileUsages(this.fileUsages);
    }

    this.getFirstMarkingAndPreview();
  }

  getFirstMarkingAndPreview(): void {
    let fileUsage: FileUsage;
    const fileUsages = this.fileUsages.filter((f) => f?.File?.type?.includes('image'));

    if (fileUsages?.length) {
      this.fileUsages.forEach((f) => {
        if (!fileUsage && f.Markings && f.Markings.length) {
          fileUsage = f;
        }
      });

      if (fileUsage) {
        this.getFileUsage(fileUsage);
      } else {
        fileUsage = fileUsages[fileUsages.length - 1];
        this.embeddedMarkings = [];
        this.getFileUsage(fileUsage);
      }
    }
  }

  openFileUsageViewer(): void {
    if (this.disabled) {
      return;
    }

    const data: FileUsageViewerData = {
      fileUsages: this.fileUsages,
      mode: FileUsageViewerMode.Mark,
      editable: true,
      client: false,
      startingIndex: this.fileUsages.map((f) => f.id).indexOf(this.fileUsage.id),
      modelData: {
        model: this.model,
        modelId: this.modelId,
      },
      markingData: {
        min: this.min,
        max: this.max,
      },
    };

    this.dialog
      .open(FileUsageViewerDialogComponent, { data })
      .afterClosed()
      .subscribe({
        next: () => {
          this.fileUsage = null;

          const markings = this.fileUsages
            .filter((fileUsage) => !!fileUsage.Markings)
            .map((fileUsage) => fileUsage.Markings);

          this.markingsChanged.emit(flatten(markings));
          this.getFirstMarkingAndPreview();
        },
      });
  }

  transform(value: string): SafeStyle {
    return this.domSanitizer.bypassSecurityTrustStyle(value);
  }

  validColor(color: string): boolean {
    return constants.pattern.hexColor.test(String(color));
  }

  private getFileUsage(fileUsage: FileUsage): void {
    if (!this.fileUsage || this.fileUsage.id !== fileUsage.id) {
      this.loading = true;
      this.fileUsage = fileUsage;
      this.img = new Image();

      this.img.onload = (): void => {
        this.error = false;
        this.maxWidth = this.img.width;
        this.maxHeight = this.img.height;

        this.loading = false;
        this.embeddedMarkings = this.fileUsage?.Markings?.length
          ? this.fileUsage.Markings.map((m: Marking) => this.setOffset(m))
          : [];
      };

      this.img.onerror = (): void => {
        this.error = true;
        this.loading = false;
      };

      const cachePipe = new URLCachePipe();

      this.img.src = cachePipe.transform(this.fileUsage.File.signed.url);
    } else {
      this.embeddedMarkings = this.fileUsage?.Markings?.length
        ? this.fileUsage.Markings.map((m: Marking) => this.setOffset(m))
        : [];
    }
  }

  private setOffset(marking: Marking): EmbeddedMarking {
    const embeddedMarking = new EmbeddedMarking(marking);

    embeddedMarking.offsetX = Math.round((marking.geometry.coordinates[0] / this.maxWidth) * 100 * 100) / 100;
    embeddedMarking.offsetY = Math.round((marking.geometry.coordinates[1] / this.maxHeight) * 100 * 100) / 100;

    return embeddedMarking;
  }
}
