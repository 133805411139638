import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutocompleteModule } from '../../../components/autocomplete/autocomplete.module';
import { AvatarModule } from '../../../components/avatar/avatar.module';
import { DateTimeModule } from '../../../components/date-time/date-time.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { UserCardModule } from '../../../directives/user-card/user-card.module';
import { DaterModule } from '../../../pipes/dater/dater.module';
import {
  StatusColorForContractorPipe,
  StatusIconForContractorPipe,
  StatusMessageForContractorPipe,
} from '../pipes/status-for-contractor.pipe';
import { CaseContractorsComponent } from './list.component';
import { StepsFromCreatorPipe } from './steps-from-creator.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    MatDialogModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatTooltipModule,

    AvatarModule,
    UserCardModule,
    TranslateModule,
    AutocompleteModule,
    DaterModule,
    DateTimeModule,
    StatusColorForContractorPipe,
    StatusMessageForContractorPipe,
    StatusIconForContractorPipe,

    StepsFromCreatorPipe,
  ],
  exports: [CaseContractorsComponent],
  declarations: [CaseContractorsComponent],
  providers: [],
})
export class CaseContractorsModule {}
