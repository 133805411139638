import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';
import { TranslateModule } from '../../../components/translate/translate.module';
import { NotificationService } from '../../../services/notification/notification.service';
import { NotificationServiceModule } from '../../../services/notification/notification.service.module';
import { UserService } from '../../../services/user/user.service';
import { NotificationListComponent } from '../list/notification-list.component';

@Component({
  selector: 'apex-notification-button',
  templateUrl: './notification-button.component.html',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    CommonModule,
    MatProgressSpinnerModule,
    OverlayModule,
    MatListModule,

    NotificationListComponent,
    NotificationServiceModule,
  ],
})
export class NotificationButtonComponent implements OnInit, OnDestroy {
  isOpen = false;
  newUnread = false;
  showBell = false;

  private subscription = new Subscription();

  constructor(
    private readonly notificationService: NotificationService,
    private readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.notificationService.notification$.subscribe(() => {
        this.newUnread = true;
      }),
    );

    this.subscription.add(
      this.userService.profile$.subscribe((profile) => {
        this.showBell = !!profile?.pushNotifications;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
