import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileUsageModule } from '../file-usage/file-usage.module';
import { ImageCropModule } from '../image-crop/image-crop.module';
import { TranslateModule } from '../translate/translate.module';
import { AvatarsComponent } from './avatars.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatTooltipModule, FileUsageModule, TranslateModule, ImageCropModule],
  exports: [AvatarsComponent],
  declarations: [AvatarsComponent],
  providers: [],
})
export class AvatarsModule {}
