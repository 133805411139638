<mat-form-field class="w-100">
  <mat-label>
    {{ label }}
  </mat-label>
  <input
    #autocomplete="matAutocompleteTrigger"
    matInput
    [(ngModel)]="text"
    [matAutocomplete]="auto"
    [disabled]="disabled"
    [required]="required"
    (focus)="focus = true; queryRequest$.next(text)"
    (blur)="focus = false" />
  <mat-hint *ngIf="hint">
    {{ hint }}
  </mat-hint>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      *ngIf="loading"
      disabled>
      <mat-spinner diameter="24"></mat-spinner>
    </mat-option>
    <mat-option
      *ngIf="!loading && !models?.length"
      disabled>
      <T
        str="No matches found"
        *ngIf="text || !favoriteAvailable"></T>
      <T
        str="No favorites saved"
        *ngIf="!(text || !favoriteAvailable)"></T>
    </mat-option>
    <ng-container *ngIf="!loading">
      <mat-option
        *ngFor="let model of models"
        [value]="model.name"
        [title]="getModelName(model)"
        (onSelectionChange)="modelChange.emit(model)"
        class="df autocomplete-option">
        <div class="w-100 h-100 df ac">
          <div class="f1 truncate">
            {{ model?.name }}
            <div
              class="mat-caption"
              *ngIf="model?.Parent">
              {{ model.Parent.name }}
              <ng-container *ngIf="model?.Parent?.Parent"> / {{ model.Parent.Parent.name }} </ng-container>
            </div>
          </div>
          <apex-user-favorite-button
            class="position-absolute right-0"
            *ngIf="favoriteAvailable"
            [modelName]="type"
            [modelId]="model.id">
          </apex-user-favorite-button>
        </div>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
