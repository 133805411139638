import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { RouterModule } from '@angular/router';
import { TranslateModule } from '../../components/translate/translate.module';
import { RequestsButtonComponent } from './requests-button.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    MatButtonModule,
    MatTooltipModule,
    MatBadgeModule,
    MatIconModule,

    TranslateModule,
  ],
  exports: [RequestsButtonComponent],
  declarations: [RequestsButtonComponent],
  providers: [],
})
export class RequestModule {}
