import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChecklistSection } from '../../features/checklist/checklist.model';
import { HttpService } from '../http/http.service';

@Injectable()
export class ChecklistSectionService extends HttpService<ChecklistSection> {
  route = 'checklist';

  getSections(checklistId: number): Observable<ChecklistSection[]> {
    return this.http.get<ChecklistSection[]>(`${this.apiUrl}/${this.route}/${checklistId}/section`, this.options);
  }

  saveSection(templateId: number, model: ChecklistSection): Observable<ChecklistSection> {
    return model.id
      ? this.http.post<ChecklistSection>(
          `${this.apiUrl}/${this.route}/${templateId}/section/${model.id}`,
          model,
          this.options,
        )
      : this.http.post<ChecklistSection>(`${this.apiUrl}/${this.route}/${templateId}/section`, model, this.options);
  }

  deleteSection(templateId: number, model: ChecklistSection): Observable<ChecklistSection> {
    return this.http.delete<ChecklistSection>(
      `${this.apiUrl}/${this.route}/${templateId}/section/${model.id}`,
      this.options,
    );
  }
}
