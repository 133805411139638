<h1 mat-dialog-title>
  <T str="Delete"></T>
</h1>

<div mat-dialog-content>
  <p>
    <T str="Are you sure you want to remove these files?"></T>
  </p>
  <p>
    <mat-checkbox [(ngModel)]="checky">
      <T str="Also delete the files from storage (this is permanent)?"></T>
    </mat-checkbox>
  </p>
</div>

<div mat-dialog-actions>
  <button
    type="button"
    mat-raised-button
    [mat-dialog-close]="{ delete: false, deleteFiles: checky }">
    <T str="Cancel"></T>
  </button>
  <button
    type="button"
    mat-raised-button
    color="warn"
    [mat-dialog-close]="{ delete: true, deleteFiles: checky }"
    cdkFocusInitial>
    <T str="Delete"></T>
  </button>
</div>
