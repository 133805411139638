import { Component, Input } from '@angular/core';
import { take } from 'rxjs/operators';
import { ResponseCaseLogDto } from '../../../../../../../generated/apex-rest';
import { t } from '../../../components/translate/translate.function';
import { CaseLog } from '../../../models/case-log';
import { IDN } from '../../reporting/project-cases-by-day/project-cases-by-day.types';
import { CaseService } from '../case.service';
import { CaseLogUserPipe } from './pipes/case-log-user.pipe';

@Component({
  selector: 'apex-case-log-user-message',
  templateUrl: './user-message.component.html',
})
export class CaseLogUserMessageComponent {
  @Input() caseLog: CaseLog | ResponseCaseLogDto;

  @Input() disableToggleActions = false;
  @Input() disabledMessageThumbnails = false;

  @Input() showUserCard = true;

  caseLogUserPipe = new CaseLogUserPipe();

  constructor(private caseService: CaseService) {}

  get user(): IDN | null {
    return this.caseLogUserPipe.transform(this.caseLog);
  }

  get forContractorMessage(): string {
    return t('{forContractor, select, 1 {Message visible to contractor} other {Make message visible to contractor}}', {
      forContractor: this.caseLog.forContractor,
    });
  }

  get forClientMessage(): string {
    return t('{forClient, select, 1 {Message visible to customer} other {Make message visible to customer}}', {
      forClient: this.caseLog.forClient,
    });
  }

  get forContractorDisabledMessage(): string {
    return t(
      '{forContractor, select, 1 {Message visible to contractor} other {Message is not visible to contractor}}',
      {
        forContractor: this.caseLog.forContractor,
      },
    );
  }

  get forClientDisabledMessage(): string {
    return t('{forClient, select, 1 {Message visible to customer} other {Message is not visible to customer}}', {
      forClient: this.caseLog.forClient,
    });
  }

  get fileUsageId(): number {
    return this.caseLog.translateVars?.fileUsageId ? Number(this.caseLog.translateVars?.fileUsageId) : 0;
  }

  toggleLog(forWho: 'client' | 'contractor'): void {
    this.caseService
      .toggleCaseLog(this.caseLog.CaseId, this.caseLog.id, forWho)
      .pipe(take(1))
      .subscribe(() => {
        if (forWho === 'contractor') {
          this.caseLog.forContractor = this.caseLog.forContractor === 0 ? 1 : 0;
        }

        if (forWho === 'client') {
          this.caseLog.forClient = this.caseLog.forClient === 0 ? 1 : 0;
        }
      });
  }
}
