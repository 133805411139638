import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ChartsModule } from '../../../components/charts/charts.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { IconPipeModule } from '../../../pipes/icon/icon-pipe.module';
import { MeterValueModule } from '../../object/project/meter/value-form.module';
import { MeterValuesModule } from '../../object/project/meter/value-list.module';

import { BoxModule } from '../../../components/box/box.module';
import { CaseMeterInfoComponent } from './case-meter-info.component';

@NgModule({
  imports: [
    CommonModule,

    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule,

    TranslateModule,
    MeterValueModule,
    ChartsModule,
    IconPipeModule,
    MeterValuesModule,
    BoxModule,
  ],
  exports: [CaseMeterInfoComponent],
  declarations: [CaseMeterInfoComponent],
  providers: [],
})
export class CaseMeterInfoModule {}
