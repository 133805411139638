<apex-box
  class="no-padding"
  [icon]="selectedObject | icon">
  <span title>{{ path }}</span>

  <a
    right
    mat-icon-button
    [routerLink]="routerLink(selectedObject)"
    (click)="$event.stopPropagation()">
    <mat-icon [matTooltip]="selectedObjectTooltip"> launch</mat-icon>
  </a>

  <section
    *ngFor="let object of objects"
    class="info-card">
    <div class="df c f1">
      <ng-container *ngIf="object.data?.lat && object.data?.lng">
        <apex-map
          [lat]="object.data.lat"
          [lng]="object.data.lng"
          [pin]="true"></apex-map>
      </ng-container>
      <div class="ams df jsb ac">
        <div class="df ac">
          <mat-icon
            class="amr"
            [matTooltip]="object.type | translateType"
            >{{ object | icon }}</mat-icon
          >
          <a
            class="mat-h3 rm"
            [routerLink]="routerLink(object)">
            {{ object.name }}
          </a>
        </div>
      </div>
      <mat-divider *ngIf="hasMoreInformation(object)"></mat-divider>
      <div
        class="df ac ams"
        *ngIf="object.description">
        <mat-icon
          class="amr"
          [matTooltip]="'Description' | translate">
          notes
        </mat-icon>
        {{ object.description }}
      </div>
      <div
        class="df ac ams"
        *ngIf="object.data?.informationExternalHtml">
        <mat-icon
          class="amr"
          [matTooltip]="'External Information' | translate">
          short_text
        </mat-icon>
        <div [innerHTML]="object.data.informationExternalHtml"></div>
      </div>

      <div
        class="df ac ams"
        *ngIf="object.data?.invoiceHtml">
        <mat-icon
          class="amr"
          [matTooltip]="'Invoice information' | translate">
          receipt
        </mat-icon>
        <div [innerHTML]="object.data?.invoiceHtml"></div>
      </div>
      <div
        class="df ac ams"
        *ngIf="object.note">
        <mat-icon
          [matTooltip]="'Note' | translate"
          class="amr"
          >note</mat-icon
        >
        <apex-sanitize-html
          class="f1"
          [textString]="object.note"></apex-sanitize-html>
      </div>
    </div>
  </section>
</apex-box>
