import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { orderBy } from 'lodash-es';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { snack, snackErr } from 'projects/apex/src/app/modules/snack.module';
import { constants } from 'projects/apex/src/app/utils/constants';
import { nameCompare } from 'projects/apex/src/app/utils/functions';
import { Subject, Subscription, forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EntryGroup } from '../entry-group/entry-group.model';
import { EntryGroupService } from '../entry-group/entry-group.service';
import { EntryCard } from './entry-card.model';
import { EntryCardService } from './entry-card.service';

@Component({
  selector: 'apex-entry-cards',
  templateUrl: './list.component.html',
})
export class EntryCardsComponent implements OnInit, OnDestroy {
  @Input() entryCards: EntryCard[] = [];
  @Input() tenancyId: number;

  @Input() viewMode: boolean;

  @Output() newEntryCardClick: EventEmitter<void> = new EventEmitter();
  @Output() deleteEntryCard = new EventEmitter<EntryCard>();
  @Output() editEntryCard = new EventEmitter<EntryCard>();

  @ViewChild('searchInput') searchInput: MatInput;
  @ViewChild('entryGroupInput') entryGroupInput: ElementRef<HTMLInputElement>;

  viewEntryCards: EntryCard[] = [];

  showSearch = false;
  search = '';
  searchGroup: number[] = [];
  searchActive = false;

  selectedGroups: EntryGroup[] = [];
  entryGroups: EntryGroup[] = [];
  viewEntryGroups: EntryGroup[] = [];
  entryGroupFilterSearch = '';

  loading: boolean;

  private subscription = new Subscription();
  private searchSubject: Subject<string> = new Subject<string>();

  constructor(
    private service: EntryCardService,
    private entryGroupService: EntryGroupService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.service.route = `tenancy/${this.tenancyId}/entry-card`;
    this.entryGroupService.route = `tenancy/${this.tenancyId}/entry-group`;

    if (!this.entryCards.length) {
      this.loading = true;

      const sub = forkJoin({
        entryCards: this.service.query(),
        entryGroups: this.entryGroupService.query(),
      }).subscribe({
        next: ({ entryCards, entryGroups }) => {
          this.entryCards = entryCards;
          this.viewEntryCards = orderBy([...this.entryCards], ['active', 'name'], ['desc', 'asc']);

          this.entryGroups = (entryGroups || []).sort(nameCompare);
          this.viewEntryGroups = [...this.entryGroups];

          this.selectedGroups = this.entryGroups.filter((eg) => this.searchGroup?.find((sg) => Number(sg) === eg.id));

          this.loading = false;

          this.searchFunc();
        },
        error: () => {
          this.loading = false;
        },
      });

      this.subscription.add(sub);
    }

    const sub1 = this.route.queryParams.subscribe({
      next: (params) => {
        this.search = params.entryCardSearch || '';
        this.searchActive = params.entryCardSearchActive === 'true';
        this.searchGroup = !Array.isArray(params.entryCardGroupSearch)
          ? [params.entryCardGroupSearch]
          : params.entryCardGroupSearch;

        this.selectedGroups = this.entryGroups.filter((eg) => this.searchGroup?.find((sg) => Number(sg) === eg.id));

        this.searchFunc();
      },
    });

    const sub2 = this.searchSubject
      .pipe(debounceTime(constants.inputDebounceTime))
      .subscribe((_) => this.updateQueryparams());

    [sub1, sub2].forEach((s) => this.subscription.add(s));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changeCardState(card: EntryCard): void {
    this.service.route = `tenancy/${this.tenancyId}/entry-card`;

    const sub = this.service.save(card).subscribe({
      next: (_) => {
        snack(t('Saved'));
      },
      error: (err) => snackErr(t('Could not save'), err),
    });

    this.subscription.add(sub);
  }

  toggleSearch(): void {
    this.showSearch = !this.showSearch;

    if (this.showSearch) {
      this.searchInput?.focus();
    }
  }

  onChangeSearch(): void {
    this.searchSubject.next(null);
  }

  searchFunc(): void {
    this.viewEntryCards = this.entryCards?.filter((ec) => {
      const searchText = this.search.toLowerCase().trim();
      const searchProp = searchText.substring(3);

      const nameFilter = ec.name.toLowerCase().includes(searchText);
      const serialNumberFilter = ec.serialNumber.includes(this.search.trim());

      const propFilter = searchText.includes('is:');

      const groupFilter = ec.EntryGroups.filter((eg) => this.selectedGroups?.find((sg) => sg.id === eg.id));

      return (
        (searchText ? nameFilter || serialNumberFilter || (propFilter && ec[searchProp]) : true) &&
        (this.searchActive ? ec.active : true) &&
        (this.selectedGroups?.length ? groupFilter.length : true)
      );
    });

    this.viewEntryCards = orderBy(this.viewEntryCards, ['active', 'name'], ['desc', 'asc']);
  }

  newCard(): void {
    this.newEntryCardClick.emit();
  }

  addGroup(group: EntryGroup): void {
    if (group?.id && !this.selectedGroups.find((g) => g.id === group.id)) {
      this.selectedGroups.push(group);
      this.entryGroupFilterSearch = '';
      this.entryGroupInput.nativeElement.value = '';
    }
  }

  removeGroup(group: EntryGroup): void {
    this.selectedGroups = this.selectedGroups.filter((sg) => sg.id !== group.id);

    this.searchSubject.next(null);
  }

  onChange(): void {
    this.viewEntryGroups = this.entryGroups
      .filter((eg) => {
        if (typeof this.entryGroupFilterSearch === 'string') {
          const searchInput = this.entryGroupFilterSearch.trim().toLowerCase();

          return eg.name.toLowerCase().includes(searchInput);
        } else {
          this.entryGroupFilterSearch = '';
        }

        return true;
      })
      .sort(nameCompare);

    this.searchSubject.next(null);
  }

  updateQueryparams(): void {
    const queryParams: Params = {
      entryCardSearch: this.search,
      entryCardSearchActive: this.searchActive,
      entryCardGroupSearch: this.selectedGroups.map((eg) => eg.id),
    };

    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }
}
