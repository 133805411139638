/**
 * Figures out if a value is a % increase from the previous value.
 *
 * @param current Current value
 * @param prev Previous value
 * @param percent % to check
 * @returns If it is an % increase
 */
export const isPercentIncrease = (current: number, prev: number, percent: number): boolean => {
  if (Number.isFinite(current) && Number.isFinite(prev) && Number.isFinite(percent)) {
    const change = Math.abs(((current - prev) / prev) * 100);

    if (change > percent) {
      return true;
    }
  }

  return false;
};
