import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, filter, fromEvent, throttleTime } from 'rxjs';

@Injectable()
export default class StatusService {
  public readonly requestStatusInflight$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly requestStatusInflightThrottled$ = this.requestStatusInflight$.pipe(
    throttleTime(1000, undefined, {
      leading: true,
      trailing: true,
    }),
    debounceTime(25),
  );

  private innerCurrent = 0;
  private post = 0;

  constructor() {
    fromEvent(window, 'beforeunload')
      .pipe(filter((_) => this.post > 0))
      .subscribe((e: BeforeUnloadEvent) => {
        e.preventDefault();

        e.returnValue = 'reload';
      });
  }

  get current(): number {
    return this.innerCurrent;
  }

  set current(value: number) {
    this.innerCurrent = value;

    this.requestStatusInflight$.next(this.innerCurrent !== 0);
  }

  requestStarted(post = false): void {
    this.current++;

    if (post) {
      this.post++;
    }
  }

  requestFinished(post = false): void {
    if (post) {
      this.post--;
    }

    this.current--;
  }
}
