import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { NotificationData } from './types/notification-data.type';

@Injectable()
export class NotificationService {
  private channel: BroadcastChannel;

  notification$: Observable<MessageEvent<NotificationData>>;
  constructor() {
    this.channel = new BroadcastChannel('notifications');

    this.notification$ = fromEvent<MessageEvent<NotificationData>>(this.channel, 'message');
  }
}
