import { Case } from './case';
import { Model } from './model';
import { User } from './user';

export class CaseView extends Model<CaseView> {
  CaseId: number;
  Case?: Case;

  UserId: number;
  User?: User;
}
