import { NgModule } from '@angular/core';

import { SizeDirective } from './size.directive';
import { SizeService } from './size.service';

@NgModule({
  declarations: [SizeDirective],
  exports: [SizeDirective],
  imports: [],
  providers: [SizeService],
})
export class SizeDirectiveModule {}
