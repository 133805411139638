<mat-progress-bar
  color="accent"
  *ngIf="inflight || progressValue !== 100"
  [mode]="inflight ? 'indeterminate' : 'determinate'"
  [value]="progressValue">
</mat-progress-bar>
<div
  class="content df ac jsb bg-color-primary-700"
  [ngClass]="{
    'apl aprs': !ref?.childNodes?.length,
    aphs: ref?.childNodes?.length,
  }">
  <div class="df r ac f2 oh">
    <span
      #ref
      [class.amrs]="ref?.childNodes?.length">
      <ng-content
        select="[left]"
        #ref>
      </ng-content>
    </span>

    <a
      *ngIf="returnDestination?.length"
      mat-icon-button
      [routerLink]="returnDestination"
      [matTooltip]="'Back' | translate">
      <mat-icon>arrow_back</mat-icon>
    </a>

    <mat-icon
      *ngIf="icon"
      class="amr"
      [fontSet]="fontSet">
      {{ icon }}
    </mat-icon>

    <ng-content select="[prefix]"></ng-content>

    <div class="df c oh">
      <h1 class="mat-headline-6 rmi">
        {{ title }}
        <span #titleRef>
          <ng-content select="[title]"></ng-content>
        </span>
      </h1>
      <span
        *ngIf="subtitle"
        class="text-white">
        {{ subtitle }}
      </span>
    </div>
  </div>
  <ng-content></ng-content>
  <apex-help-icon [showHelpButton]="showHelpButton"></apex-help-icon>
</div>
