import { NgModule } from '@angular/core';
import { AxiosConfigService } from './axios-config.service';
import StatusService from './status.service';

@NgModule({
  imports: [],
  exports: [],
  providers: [AxiosConfigService, StatusService],
})
export default class StatusServiceModule {
  // Injecting our own services here ensures they are initialized.
  constructor(
    private axiosConfigService: AxiosConfigService,
    private statusService: StatusService,
  ) {}
}
