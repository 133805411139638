import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import { padStart } from 'lodash-es';
import moment, { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { Case } from '../../../models/case';
import { RepeatableCase } from '../repeatable-case.model';

interface DayColumn {
  dayMoment: Moment;
  weekDay: string;
}

interface RangeCaseViewData extends Case {
  RepeatableCase?: RepeatableCase;
  start: number;
  length: number;
  fromWeek: boolean;
  toWeek: boolean;
}

@Component({
  selector: 'apex-calendar-week-view',
  templateUrl: './week.component.html',
})
export class CalendarWeekViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() cases: Case[] = [];
  @Input() repeatableCases: RepeatableCase[] = [];
  @Input() futureRepeatableCases: RepeatableCase[] = [];

  @Input() today: Moment;

  @Input() loading = false;

  @Output() viewCase: EventEmitter<Case> = new EventEmitter<Case>();
  @Output() createCase: EventEmitter<{ moment: Moment }> = new EventEmitter<{ moment: Moment }>();
  @Output() editRepeatableCase: EventEmitter<Case> = new EventEmitter<Case>();
  @Output() createCaseFromRepeatableCase: EventEmitter<Case> = new EventEmitter<Case>();
  @Output() deleteRepeatableCase: EventEmitter<Case> = new EventEmitter<Case>();
  @Output() goToView: EventEmitter<{ slot: { moment: Moment } }> = new EventEmitter<{ slot: { moment: Moment } }>();

  d: number;
  w: number;
  m: number;
  y: number;

  startOfWeekMoment: Moment;
  endOfWeekMoment: Moment;

  currentMoment: Moment;
  timeValues: string[] = [];

  casesCombined: Array<Case | RepeatableCase> = [];
  dayColumns: DayColumn[] = [];
  rangeCases: RangeCaseViewData[] = [];

  showAll = false;

  private subscription = new Subscription();

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.timeValues = this.buildTimesArray();

    const sub = this.route.queryParams.subscribe({
      next: (params) => {
        this.d = Number(params.date);
        this.m = Number(params.month) - 1;
        this.y = Number(params.year);

        this.currentMoment = moment().set({
          date: this.d,
          month: this.m,
          year: this.y,
        });

        this.dayColumns = this.buildCalendarWeekView();
      },
    });

    this.subscription.add(sub);
  }

  ngOnChanges(): void {
    this.casesCombined = [...this.cases, ...this.repeatableCases, ...this.futureRepeatableCases];

    this.rangeCases = this.createRangeCases();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openCase(c: Case, menuTrigger?: MatMenuTrigger): void {
    if (menuTrigger) {
      menuTrigger.closeMenu();
    }

    this.viewCase.emit(c);
  }

  makeCase(time: string): void {
    const tempTime = moment(time, 'HH:mm');
    const currentDayTime = moment(this.currentMoment).startOf('isoWeek').set({
      second: 0,
      minute: tempTime.minute(),
      hour: tempTime.hour(),
    });
    const slot = { moment: currentDayTime };

    this.createCase.emit(slot);
  }

  buildCalendarWeekView(): DayColumn[] {
    const dayColumn: DayColumn[] = [];

    if (this.currentMoment.isValid()) {
      this.startOfWeekMoment = moment(this.currentMoment).startOf('isoWeek');
      this.endOfWeekMoment = moment(this.currentMoment).endOf('isoWeek');

      Array(7)
        .fill('')
        .forEach((_, i) => {
          const dm = moment(this.startOfWeekMoment).add(i, 'day');

          dayColumn.push({
            dayMoment: dm,
            weekDay: moment(dm).format('ddd'),
          });
        });
    }

    return dayColumn;
  }

  createRangeCases(): RangeCaseViewData[] {
    const rangeCases: RangeCaseViewData[] = [];

    this.casesCombined
      .sort((a, b) => {
        [a, b] = [a instanceof RepeatableCase ? a.case : a, b instanceof RepeatableCase ? b.case : b];

        if (a.from < b.from) {
          return -1;
        }

        if (a.from > b.from) {
          return 1;
        }

        return 0;
      })
      .filter((cc) => {
        const c = cc instanceof RepeatableCase ? cc.case : cc;

        return (
          ((moment(c.from).isSameOrAfter(this.startOfWeekMoment, 'd') &&
            moment(c.from).isSameOrBefore(this.endOfWeekMoment, 'd')) || // from this week
            (moment(c.to).isSameOrAfter(this.startOfWeekMoment, 'd') &&
              moment(c.to).isSameOrBefore(this.endOfWeekMoment, 'd')) || // to this week
            (moment(c.from).isBefore(this.startOfWeekMoment, 'd') &&
              moment(c.to).isAfter(this.endOfWeekMoment, 'd'))) && // from before to after this week
          moment(c.from).isBefore(moment(c.to)) // from must be before to -> should not be possible to set from after to
        );
      })
      .forEach((cc) => {
        const c = cc instanceof RepeatableCase ? cc.case : cc;

        const width = 100 / 7;

        let caseStart = 0;
        let caseLength = 0;

        if (!moment(c.from).isSame(moment(c.to), 'd')) {
          if (moment(c.from).isBefore(this.startOfWeekMoment)) {
            caseStart = 0;
            caseLength = moment(c.to).isAfter(this.startOfWeekMoment, 'isoWeek')
              ? width * (7 - caseStart)
              : width * moment(c.to).day();
          } else if (moment(c.from).isSame(this.startOfWeekMoment, 'isoWeek')) {
            caseStart = width * (moment(c.from).day() - 1);
            caseLength = moment(c.to).isSame(this.startOfWeekMoment, 'isoWeek')
              ? width * moment(c.to).day() - caseStart
              : width * 7 - caseStart;
          }

          rangeCases.push({
            ...c,
            RepeatableCase: cc instanceof RepeatableCase ? cc : cc.RepeatableCase,
            start: caseStart,
            length: caseLength,
            fromWeek: moment(c.from).isSame(this.startOfWeekMoment, 'isoWeek'),
            toWeek: moment(c.to).isSame(this.startOfWeekMoment, 'isoWeek'),
          });
        }
      });

    return rangeCases;
  }

  private buildTimesArray(): string[] {
    const times: string[] = [];

    for (let i = 0; i < 24; i++) {
      const time = `${padStart(i.toString(), 2, '0')}:00`;

      times.push(time);
    }

    return times;
  }
}
