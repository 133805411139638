import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { FileUsageService } from 'projects/apex/src/app/components/file-usage/file-usage.service';
import { FileService } from 'projects/apex/src/app/components/file-usage/file.service';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { SplitDialogComponent } from 'projects/apex/src/app/components/upload/split-dialog/split-dialog.component';
import { InViewDirectiveModule } from 'projects/apex/src/app/directives/in-view/in-view.directive.module';
import { ObjectService } from 'projects/apex/src/app/features/object/object.service';
import { FolderService } from 'projects/apex/src/app/services/folder/folder.service';
import { FileSizePipeModule } from '../../pipes/file-size/file-size.module';
import { ConfirmDialogModule } from '../confirm-dialog/confirm-dialog.module';
import { FabModule } from '../fab/fab.module';
import { FilePreviewModule } from '../file-preview/file-preview.module';
import { InputDialogModule } from '../input-dialog/input-dialog.module';
import { PageInfoModule } from '../page-info/page-info.module';
import { UploadComponent } from './upload.component';

const components = [UploadComponent, SplitDialogComponent];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    PageInfoModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    FilePreviewModule,
    FabModule,
    MatDividerModule,
    MatRippleModule,
    MatMenuModule,
    MatDialogModule,
    InputDialogModule,
    FileSizePipeModule,
    ConfirmDialogModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    InViewDirectiveModule,
    MatTabsModule,
  ],
  providers: [FileUsageService, FileService, FolderService, ObjectService],
  declarations: components,
  exports: components,
})
export class UploadModule {}
