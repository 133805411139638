import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FabItem } from 'projects/apex/src/app/components/fab/fab.types';
import { ChecklistTemplateSectionViewComponent } from 'projects/apex/src/app/features/checklist-template/section/view.component';
import { Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { FileUsageComponent } from '../../../components/file-usage/file-usage.component';
import { t } from '../../../components/translate/translate.function';
import { User } from '../../../models/user';
import { snack, snackErr } from '../../../modules/snack.module';
import { UserService } from '../../../services/user/user.service';
import { constants } from '../../../utils/constants';
import { nameCompare } from '../../../utils/functions';
import { ChecklistTemplate, ChecklistTemplateItem, ChecklistTemplateSection } from '../checklist-template.model';
import { ChecklistTemplateItemService, ChecklistTemplateService } from '../checklist-template.service';
import { DropListService } from '../services/droplist.service';

export const checklistTemplateSectionsSortFunc = (a: ChecklistTemplateSection, b: ChecklistTemplateSection): number => {
  const sortA: number = a.sortOrder;
  const sortB: number = b.sortOrder;

  if (sortA === sortB) {
    return nameCompare(a, b);
  }

  return sortA > sortB ? 1 : sortB > sortA ? -1 : 0;
};

export const checklistTemplateItemsSortFunc = (a: ChecklistTemplateItem, b: ChecklistTemplateItem): number => {
  const sortA: number = a.sortOrder;
  const sortB: number = b.sortOrder;

  if (sortA === sortB) {
    return nameCompare(a, b);
  }

  return sortA > sortB ? 1 : sortB > sortA ? -1 : 0;
};

@Component({
  selector: 'apex-checklist-template-form',
  templateUrl: './form.component.html',
  providers: [DropListService],
})
export class ChecklistTemplateFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('checklistTemplateForm') checklistTemplateForm: NgForm;
  @ViewChild('fileUsage') fileUsage: FileUsageComponent;
  @ViewChild(ChecklistTemplateSectionViewComponent) sectionComponent: ChecklistTemplateSectionViewComponent;

  get title(): string {
    return this.checklistTemplate.id ? this.checklistTemplate.name : t('New Checklist Template');
  }

  buttons: FabItem[] = [
    {
      text: t('Item'),
      click: (): void => {
        this.checklistTemplateItems.push(
          new ChecklistTemplateItem({
            sortOrder: this.checklistTemplateItems?.length ?? 0,
            SectionId: null,
          }),
        );

        this.sectionComponent.filter();
      },
    },
    {
      text: t('Section'),
      click: (): void => {
        this.sectionComponent.editSection();
      },
    },
  ];

  checklistTemplate: ChecklistTemplate;
  checklistTemplateItems: ChecklistTemplateItem[] = [];
  checklistTemplateSections: ChecklistTemplateSection[] = [];

  prevTemplateValues: ChecklistTemplate;
  user: User;

  selectedCategory: number;
  selectedField: number;

  selectedIndex = 0;
  changeIndex = true;

  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: ChecklistTemplateService,
    private itemService: ChecklistTemplateItemService,
    private userService: UserService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    const sub1 = this.route.data.subscribe({
      next: (d) => {
        this.checklistTemplate = d.checklistTemplate;
        this.prevTemplateValues = new ChecklistTemplate(this.checklistTemplate);
        this.checklistTemplateItems = (d.checklistTemplateItems || []).sort(checklistTemplateItemsSortFunc);
        this.checklistTemplateSections = (d.checklistTemplateSections || []).sort(checklistTemplateSectionsSortFunc);

        this.selectedIndex = 0;

        if (this.checklistTemplate?.id && this.changeIndex && this.checklistTemplateItems?.length) {
          this.selectedIndex = 1;
        }
      },
    });

    const sub2 = this.userService.filteredProfile$.subscribe({
      next: (user) => {
        this.user = user;
      },
    });

    this.subscriptions.push(sub1, sub2);
  }

  isAdmin(): boolean {
    return this.userService.isAdmin(this.user);
  }

  ngAfterViewInit(): void {
    const sub = this.checklistTemplateForm?.valueChanges
      .pipe(
        debounceTime(constants.autoSaveDebounceTime),
        filter((v) => !!v.name),
      )
      .subscribe({
        next: (_) => {
          if (this.checklistTemplateForm.dirty && this.isDifferent(this.checklistTemplate, this.prevTemplateValues)) {
            this.service.save(this.checklistTemplate).subscribe({
              next: (response) => {
                this.fileUsage.selfId = response?.Entity?.id;
                this.prevTemplateValues = new ChecklistTemplate(this.checklistTemplate);

                return this.fileUsage.saveAll().subscribe({
                  next: () => {
                    snack(t('Saved'));
                    this.changeIndex = false;
                    void this.router.navigate(['checklist-template', response?.Entity?.id]);
                  },
                });
              },
              error: (err) => {
                snackErr(t('Could not save'), err);
              },
            });
          }
        },
      });

    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s?.unsubscribe());
  }

  delete(): void {
    this.subscriptions.push(
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            text: t(`Are you sure you want to delete "{name}" and all it's items?`, {
              name: this.checklistTemplate?.name,
            }),
            del: true,
          },
        })
        .afterClosed()
        .subscribe({
          next: (del) => {
            if (del) {
              this.subscriptions.push(
                this.service.delete(this.checklistTemplate.id).subscribe({
                  next: (_) => {
                    snack(t('Deleted'));
                    void this.router.navigate(['checklist-template']);
                  },
                  error: (err) => {
                    snackErr(t('Could not delete'), err);
                  },
                }),
              );
            }
          },
        }),
    );
  }

  drop(event: CdkDragDrop<ChecklistTemplateItem[]>): void {
    moveItemInArray(this.checklistTemplateItems, event.previousIndex, event.currentIndex);

    this.checklistTemplateItems
      .map((cti, i) => {
        cti.sortOrder = i;

        this.itemService
          .saveItem(this.checklistTemplate.id, cti)
          .pipe(debounceTime(constants.requestDebounceTime))
          .subscribe({
            next: (item) => {
              this.checklistTemplateItems.find((ti) => ti.id === item.id).sortOrder = item.sortOrder;
            },
          });

        return cti;
      })
      .sort(checklistTemplateItemsSortFunc);
  }

  isDifferent(a: ChecklistTemplate, b: ChecklistTemplate): boolean {
    return Object.keys(a).some((k) => a[k] !== b[k]);
  }

  updateSelectedCategory(): void {
    this.selectedCategory = this.checklistTemplate.CategoryId;
  }
}
