import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { FileUsage } from '../../models/file-usage';
import { createApiUrl } from '../../utils/functions';
import { CommercialType, MetaType, Obj, ObjectLog } from './object.model';
import { SafetyType } from './safety/safety.model';

export class QueryParams {
  ParentId?: number;
  RootId?: number;
  children?: boolean;
  includeParent?: boolean;
  includeRelated?: boolean;
}

export class GetParams {
  children?: boolean;
  includeParent?: boolean;
  includeRelated?: boolean;
}

@Injectable()
export class ObjectService {
  public readonly url = `${environment.api}/object`;

  public readonly headers: HttpHeaders = new HttpHeaders({
    'content-type': 'application/json',
  });

  public readonly options = {
    headers: this.headers,
    withCredentials: true,
    limit: 25,
    page: 0,
  };

  constructor(protected http: HttpClient) {}

  public get<T>(id: number, params?: GetParams): Observable<T> {
    const options = this.figureOutParams(params);

    return this.http.get<T>(`${this.url}/${id}`, options);
  }

  public save<T>(obj: Obj): Observable<T> {
    const u = obj.id ? `${this.url}/${obj.id}` : this.url;

    return this.http.post<T>(u, obj, this.options);
  }

  public delete(obj: Obj): Observable<null> {
    const u = `${this.url}/${obj.id}`;

    return this.http.delete<null>(u, this.options);
  }

  public query<T>(meta: MetaType, type: CommercialType | SafetyType, params?: QueryParams): Observable<T[]> {
    const options = this.figureOutParams(params, {
      meta,
      type,
    });

    return this.http.get<T[]>(this.url, options);
  }

  public getFloorplansForParentsAndChildren(id: number): Observable<FileUsage[]> {
    return this.http.get<FileUsage[]>(`${this.url}/${id}/floorplans`, this.options);
  }

  public postProductPdf(id: number, data: { saleId: number; controlId: number }): Observable<null> {
    return this.http.post<null>(`${this.url}/${id}/pdf`, data, this.options);
  }

  public getTakeoverFileUsages$(id: number): Observable<FileUsage[]> {
    if (!id) {
      return of([]);
    }

    const url = createApiUrl('object', id, 'takeover-pdf');

    return this.http.get<FileUsage[]>(url, this.options);
  }

  private figureOutParams(params?: GetParams | QueryParams, include?: Record<string, string>): Record<string, unknown> {
    const p = Object.assign({}, params ? params : {}, include ? include : {});

    Object.keys(p)
      .filter((k) => {
        const v = p[k];
        const t = typeof v;

        if (v === undefined) {
          return true;
        } else if (t === 'string') {
          return v === 'undefined' || v === 'null';
        }

        return false;
      })
      .forEach((k) => delete p[k]);

    return Object.assign({}, this.options, { params: p });
  }
}

@Injectable()
export class ObjectLogService {
  public readonly url = `${environment.api}/object`;

  public readonly headers: HttpHeaders = new HttpHeaders({
    'content-type': 'application/json',
  });

  public readonly options = {
    headers: this.headers,
    withCredentials: true,
  };

  constructor(protected http: HttpClient) {}

  public get(objectId: number): Observable<ObjectLog[]> {
    return this.http.get<ObjectLog[]>(`${this.url}/${objectId}/log`, this.options);
  }

  public save(objectLog: ObjectLog): Observable<ObjectLog> {
    return this.http.post<ObjectLog>(`${this.url}/${objectLog.ObjectId}/log`, objectLog, this.options);
  }

  public delete(objectLog: ObjectLog): Observable<null> {
    return this.http.delete<null>(`${this.url}/${objectLog.ObjectId}/log/${objectLog.id}`, this.options);
  }
}
