import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { TextListModule } from 'projects/apex/src/app/components/text-list/text-list.module';
import { TextUsageService } from '../../services/text-usage/text-usage.service';
import { UsageExplorerModule } from '../usage-explorer/usage-explorer.module';
import { TextUsageComponent } from './text-usage.component';

@NgModule({
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatDialogModule,
    TextListModule,
    UsageExplorerModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
  ],
  providers: [TextUsageService],
  declarations: [TextUsageComponent],
  exports: [TextUsageComponent],
})
export class TextUsageModule {}
