import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Bluebird from 'bluebird';
import { cloneDeep, flatten, orderBy } from 'lodash-es';
import moment, { Moment } from 'moment';
import { FileUsageComponent } from 'projects/apex/src/app/components/file-usage/file-usage.component';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { Case, Contractor } from 'projects/apex/src/app/models/case';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { snack, snackErr } from 'projects/apex/src/app/modules/snack.module';
import { Observable, Subscription, combineLatest, forkJoin, of } from 'rxjs';
import { map, mergeMap, take, tap, toArray } from 'rxjs/operators';
import { EmbeddedMarkingsViewerComponent } from '../../../../components/embedded-markings-viewer/embedded-markings-viewer.component';
import { FileUsageService } from '../../../../components/file-usage/file-usage.service';
import { Marking, MarkingModelType } from '../../../../models/marking';
import { sortFileUsages } from '../../../../utils/functions';
import { CaseService } from '../../../case/case.service';
import { CaseNewMessageComponent } from '../../../case/new-message/new-message.component';
import { ObjectService } from '../../../object/object.service';
import { ChecklistItemService, ChecklistService } from '../../checklist.service';
import { DeviationDialogInput } from './types';

@Component({
  selector: 'apex-deviation-dialog',
  templateUrl: 'component.html',
})
export class DeviationDialogComponent implements OnDestroy {
  @ViewChild('fileusage') fileusage: FileUsageComponent;
  @ViewChild('form') form: NgForm;
  @ViewChild('messages') messages: CaseNewMessageComponent;
  @ViewChild('marking') marking: EmbeddedMarkingsViewerComponent;

  MarkingModelType = MarkingModelType;

  deadlineAt: Moment = moment().add(7, 'days');

  get isCase(): boolean {
    return !!this.data.itemCase;
  }

  get deviation(): Case | null {
    if (this.data.itemCase) {
      return this.data.itemCase;
    }

    if (this.caseIndex !== -1) {
      return this.data?.cases[this.caseIndex];
    }

    return this.data.deviationCase;
  }

  set deviation(deviation: Case) {
    if (this.data.itemCase) {
      this.data.itemCase = deviation;

      return;
    }

    if (this.caseIndex !== -1) {
      this.data.cases[this.caseIndex] = deviation;

      return;
    }

    this.data.cases.push(new Case({}));
  }

  get floorPlans(): FileUsage[] {
    return this.deviation.Floorplans;
  }

  set floorPlans(floorPlans: FileUsage[]) {
    this.deviation.Floorplans = floorPlans;
  }

  set fileUsages(fileUsages: FileUsage[]) {
    if (this.isCase) {
      this.data.itemCase.FileUsages = fileUsages;

      return;
    }

    this.deviation.FileUsages = fileUsages;
  }

  get caseId(): number {
    if (this.isCase) {
      return this.data.itemCase.id;
    }

    return 0;
  }

  get id(): number {
    if (this.caseIndex !== -1) {
      return this.data.cases[this.caseIndex].id;
    }

    return 0;
  }

  showSystemMessages = false;
  setCaseManager = true;

  subscription = new Subscription();

  saving = false;

  get isNew(): boolean {
    return !this.deviation.id;
  }

  get caseIndex(): number {
    return this.data.cases?.findIndex((c) => c.id === this.data.deviationCase.id) ?? -1;
  }

  get self(): string {
    return !!this.data.itemCase ? 'case' : 'deviation';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeviationDialogInput,
    public dialogRef: MatDialogRef<DeviationDialogComponent>,
    private service: ChecklistItemService,
    private caseService: CaseService,
    private objectService: ObjectService,
    private fileUsageService: FileUsageService,
    private checklistService: ChecklistService,
  ) {
    this.deviation.CaseLogs = !!this.deviation?.CaseLogs
      ? orderBy(this.deviation.CaseLogs, ['createdAt'], ['desc'])
      : [];

    this.deviation.createdAt = this.deviation?.createdAt ?? new Date();

    if (this.deviation.deadline) {
      this.deadlineAt = moment.unix(this.deviation.deadline);
    }

    this.fetchFloorPlans();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  fetchFloorPlans(): void {
    const { ProjectId, ApartmentId, ObjectId } = this.deviation;

    const floorPlanRequests: Observable<FileUsage[]>[] = [];

    if (ProjectId) {
      floorPlanRequests.push(this.fileUsageService.all('project', ProjectId, 'floorplans'));
    }

    if (ApartmentId) {
      floorPlanRequests.push(this.fileUsageService.all('apartment', ApartmentId, 'floorplans'));
    }

    if (ObjectId) {
      floorPlanRequests.push(this.objectService.getFloorplansForParentsAndChildren(ObjectId));
    }

    const floorPlans$ = forkJoin(floorPlanRequests).pipe(
      take(1),
      map((fileUsages) => flatten(fileUsages)),
      map((fileUsages) => fileUsages.map((fileUsage) => ({ ...fileUsage, Markings: fileUsage.Markings ?? [] }))),
    );

    floorPlans$.subscribe({
      next: (floorPlans) => {
        this.setCaseFloorplans(floorPlans);
      },
      error: (err) => snackErr(t('Could not load floor plans'), err),
    });
  }

  get save$(): Observable<Case> {
    this.saving = true;

    this.deviation.deadline = Math.floor(this.deadlineAt.unix() * 1000);

    const data = {
      ...this.deviation,
      setCaseManager: this.setCaseManager,
    };

    return this.service.saveDeviation(this.data.item, data).pipe(
      map((response) => response.Entity),
      mergeMap((deviation) => {
        this.fileusage.selfId = deviation.id;

        return combineLatest([
          this.fileusage.saveAll().pipe(
            toArray(),
            map(() => null),
          ),
          of(deviation),
        ]);
      }),
      map(([, deviation]) => deviation),
      tap(() => {
        this.saving = false;
      }),
    );
  }

  save(): void {
    const sub = this.save$.subscribe({
      next: (deviation) => {
        snack(t('Saved'));

        if (this.caseIndex === -1) {
          this.data.cases?.push(deviation);
          this.data.item.Cases?.push(deviation);
        }

        if (this.caseIndex !== -1) {
          this.deviation = deviation;
        }

        this.dialogRef.close();
      },
      error: (err) => snackErr(t('Failed to save'), err),
    });

    this.subscription.add(sub);
  }

  addContractor(contractor: Contractor): void {
    const contractors = this.deviation?.Contractors ?? [];
    const exists = !!contractors.find((cc) => cc.id === contractor.id);

    if (exists) {
      return;
    }

    if (this.isNew) {
      this.deviation.Contractors = [...contractors, contractor];

      return;
    }

    const sub = this.service
      .addDeviationContractor(this.data.item, this.deviation.id, contractor)
      .pipe(
        take(1),
        map((response) => response.Entity),
      )
      .subscribe({
        next: (updatedCase) => {
          const { Contractors, CaseLogs } = updatedCase;

          this.deviation.Contractors = Contractors;
          this.deviation.CaseLogs = CaseLogs;

          snack(t('Contractor added'));
        },
        error: (err) => snackErr(t('Could not add contractor'), err),
      });

    this.subscription.add(sub);
  }

  removeContractor(contractor: Contractor): void {
    const contractors = this.deviation?.Contractors ?? [];
    const contractorIndex = contractors.findIndex((cc) => cc.id === contractor.id);
    const exists = contractorIndex !== -1;

    if (!exists) {
      return;
    }

    if (this.isNew) {
      this.deviation?.Contractors.splice(contractorIndex, 1);
    }

    const sub = this.service
      .removeDeviationContractor(this.data.item, this.deviation?.id, contractor.id)
      .pipe(
        take(1),
        map((response) => response.Entity),
      )
      .subscribe({
        next: (updatedDeviation) => {
          const { Contractors, CaseLogs } = updatedDeviation;

          this.deviation.Contractors = Contractors;
          this.deviation.CaseLogs = CaseLogs;

          snack(t('Contractor removed'));
        },
        error: (err) => snackErr(t('Could not remove contractor'), err),
      });

    this.subscription.add(sub);
  }

  async fetchFieldContractors(fieldId: number): Promise<void> {
    if (!fieldId) {
      return;
    }

    const fetchedContractors = await this.checklistService.getContractorsForField(this.data.item.ChecklistId, fieldId);

    await Bluebird.map(fetchedContractors, (fc) => this.addContractor(fc));
  }

  async fetchObjectFieldContractors(objectFieldId: number): Promise<void> {
    if (!objectFieldId) {
      return;
    }

    const fetchedContractors = await this.checklistService.getContractorsForObjectField(
      this.data.item.ChecklistId,
      objectFieldId,
    );

    await Bluebird.map(fetchedContractors, (fc) => this.addContractor(fc));
  }

  get logIsDisabled(): boolean {
    return !!this.messages?.newMessageForm?.invalid || !!this.saving;
  }

  saveCaseLogForCase(): void {
    if (!this.data.itemCase) {
      return;
    }

    const save$ = this.caseService.postCaseLog(this.data.itemCase.id, this.messages.caseLog).pipe(take(1));

    const sub = save$.subscribe((log) => {
      if (!!this.deviation) {
        this.deviation.CaseLogs = [log, ...this.deviation.CaseLogs];
      }
    });

    this.subscription.add(sub);
  }

  saveCaseLog(): void {
    const id = this.isCase ? this.caseId : this.id;

    const save$ = this.service.postCaseLog(this.data.item, id, this.messages.caseLog).pipe(
      take(1),
      map((response) => response.Entity),
    );

    const sub = save$.subscribe((log) => {
      if (!!this.deviation) {
        this.deviation.CaseLogs = [log, ...this.deviation.CaseLogs];
      }
    });

    this.subscription.add(sub);
  }

  setCaseFloorplans(floorPlans): void {
    const caseFloorplans = [];

    this.deviation.Markings?.forEach((m: Marking) => {
      if (m.FileUsage) {
        const deviation = cloneDeep(this.deviation);
        const marking = cloneDeep(m);

        delete deviation.Markings;

        marking.Case = this.deviation;

        delete marking.FileUsage;

        const existingFloorplan = caseFloorplans.find((cf) => Number(cf.id) === Number(m.FileUsageId));

        if (existingFloorplan) {
          if (!existingFloorplan.Markings?.length) {
            existingFloorplan.Markings = [];
          }

          existingFloorplan.Markings = existingFloorplan.Markings.concat([marking]);
        } else {
          m.FileUsage.Markings = [marking];
          caseFloorplans.push(m.FileUsage);
        }
      }
    });

    floorPlans.forEach((f: FileUsage) => {
      const existingFloorplan = caseFloorplans.find((cf) => Number(cf.id) === Number(f.id));

      if (!existingFloorplan) {
        caseFloorplans.push(cloneDeep(f));
      }
    });

    this.floorPlans = sortFileUsages(caseFloorplans);
  }

  updateMarkings(markings?: Marking[]): void {
    if (this.deviation) {
      if (markings) {
        this.deviation.Markings = markings.map((m) => {
          const fileUsage = cloneDeep(this.floorPlans.find((f) => f.id === m.FileUsageId));

          delete fileUsage.Markings;
          m.FileUsage = fileUsage;

          return m;
        });

        this.floorPlans = this.floorPlans.map((cf: FileUsage) => {
          cf.Markings = markings.filter(
            (m) => m.FileUsageId === cf.id && m.model === 'case' && m.modelId === this.deviation.id,
          );

          return cf;
        });
      } else {
        this.deviation.Markings = [].concat(
          ...this.floorPlans.map((cf) =>
            cf.Markings?.map((m) => {
              m.FileUsage = cf;

              return m;
            }),
          ),
        );
        this.marking.getFirstMarkingAndPreview();
      }
    }
  }
}
