import { TextFieldModule } from '@angular/cdk/text-field';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { TextareaComponent } from './textarea.component';

@NgModule({
  imports: [FormsModule, MatFormFieldModule, MatInputModule, TextFieldModule],
  exports: [TextareaComponent],
  declarations: [TextareaComponent],
  providers: [],
})
export class TextareaModule {}
