import { RepeatableCase } from '../features/case/repeatable-case.model';
import { Checklist, ChecklistItem } from '../features/checklist/checklist.model';
import { Obj } from '../features/object/object.model';
import { Agreement } from '../features/object/project/agreement/agreement.model';
import { Meter } from '../features/object/project/meter/meter.model';
import { EntryCard } from '../features/object/project/tenancy/entry-card/entry-card.model';
import { IDN } from '../features/reporting/project-cases-by-day/project-cases-by-day.types';
import { AddonOrder } from './addon-order';
import { Apartment } from './apartment';
import { CaseCategory } from './case-category';
import { CaseContractor } from './case-contractor';
import { CaseLog } from './case-log';
import { CaseView } from './case-view';
import { Customer } from './customer';
import { Field } from './field';
import { File } from './file';
import { FileUsage } from './file-usage';
import { Marking } from './marking';
import { Model } from './model';
import { ObjectField } from './object-field';
import { ObjectOwnership } from './object-ownership';
import { Project } from './project';
import { Tag } from './tag';
import { ProjectTakeover } from './takeover';
import { Tenancy } from './tenancy';
import { User } from './user';

export class NewAddon {
  name: string;
  description: string;
  AddonCategoryId: number;
  priceIn: number;
  priceOut: number;
  VAT: number;

  constructor() {
    this.description = '';
  }
}

export class SimplePath {
  id: number;
  ParentId: number;
  name: string;
  depth: number;
}

export interface CaseAccess {
  contractor: boolean;
  caseManager: boolean;
  admin: boolean;
}

export class Case extends Model<Case> {
  name: string;
  description?: string;
  location: string;

  files: number[];
  Files?: File[];

  deadline: number;
  completed: number;

  CustomerId: number;
  Customer?: Customer;

  ProjectId: number;
  Project?: Project;

  ApartmentId: number;
  Apartment?: Apartment;

  ClientId: number;
  Client?: User;

  UserId: number;
  User?: User;

  CaseManagerId: number;
  CaseManager?: User;

  CaseStatusId: number;

  CaseCategoryId: number;
  CaseCategory?: CaseCategory;

  FieldId: number;
  Field?: Field;

  ObjectFieldId: number;
  ObjectField?: ObjectField;

  ContractorId: number;
  Contractor?: Contractor;
  Contractors?: Contractor[];

  warranty: number;
  cost: number;

  fromRequest: boolean;
  fromRequestTime: Date;

  kundetouch: number;

  FileUsages: FileUsage[];
  Floorplans: FileUsage[];

  Markings: Marking[];
  CaseLogs: CaseLog[];
  notifyClient: boolean;
  notifyContractor: boolean;
  Takeovers?: ProjectTakeover[];
  sendSMS: boolean;
  access?: CaseAccess;
  AddonOrder?: AddonOrder;

  CaseViews?: CaseView[];

  archivedAt?: Date;

  ObjectId: number;
  Object?: Obj;
  Objects?: Obj[];
  ObjectPath?: SimplePath[];

  NewAddon?: NewAddon;

  from?: Date;
  to?: Date;
  RepeatableCase?: RepeatableCase;

  RepeatableCaseId: number;

  TenancyId?: number;
  Tenancy?: Tenancy;

  EntryCardId?: number;
  EntryCard?: EntryCard;

  AgreementId?: number;
  Agreement?: Agreement;

  ChecklistItem?: ChecklistItem;
  ChecklistId?: number;
  Checklist?: Checklist;

  ChecklistTemplateId?: number;

  deviationChecklist?: IDN;

  fromProjectMom?: boolean;

  MeterId?: number;
  Meter?: Meter;

  Tags?: Tag[];

  ObjectOwnershipId?: number;
  ObjectOwnership?: ObjectOwnership;
}

export class Contractor extends User {
  CaseContractor: CaseContractor;

  constructor(contractor?: Partial<Contractor>) {
    super(contractor);

    this.CaseContractor = new CaseContractor(contractor?.CaseContractor);
  }
}
