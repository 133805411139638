<mat-form-field>
  <mat-label>
    {{ label }}
  </mat-label>
  <textarea
    matInput
    [readonly]="readonly"
    [ngModel]="value"
    (ngModelChange)="textChange.emit($event); valueChanged.next($event)"
    [disabled]="isDisabled"
    cdkTextareaAutosize
    (change)="$event.stopPropagation()">
  </textarea>
</mat-form-field>
