import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'apex-checklist-bulk-delete-dialog',
  templateUrl: './checklist-bulk-delete-dialog.component.html',
})
export class ChecklistBulkDeleteDialogComponent {
  completeAndArchiveCheck = false;
  type: 'checklist' | 'checklistGroup';
  selectedChecklists: [{ name: string }];

  constructor(
    public dialogRef: MatDialogRef<ChecklistBulkDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: 'checklist' | 'checklistGroup';
      selectedChecklists: [{ name: string }];
    },
  ) {
    this.type = data.type;
    this.selectedChecklists = data.selectedChecklists;
  }
}
