import { Pipe, PipeTransform } from '@angular/core';
import { Contractor } from '../../../models/case';

@Pipe({
  name: 'contractorsFromCase',
  standalone: true,
})
export class ContractorsFromCasePipe implements PipeTransform {
  transform(contractors?: Contractor[], count = 5): Contractor[] {
    if (!contractors) {
      return [];
    }

    return contractors.slice(0, count);
  }
}
