import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep, compact, orderBy, uniq } from 'lodash-es';
import moment, { Moment, isMoment } from 'moment';
import { EMPTY, Observable, Subscription, forkJoin, from, of } from 'rxjs';
import { expand, map, mergeMap, take, toArray } from 'rxjs/operators';
import { ApartmentApi } from '../../../../../../../generated/apex-rest';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { FilterChipsComponent } from '../../../components/filter-chips/filter-chips.component';
import { t } from '../../../components/translate/translate.function';
import { Apartment } from '../../../models/apartment';
import { Autocomplete } from '../../../models/autocomplete';
import { Case } from '../../../models/case';
import { CaseParams } from '../../../models/case-params';
import { Filter } from '../../../models/filter';
import { User } from '../../../models/user';
import { snack, snackErr } from '../../../modules/snack.module';
import { FilterType } from '../../../services/filter/filter.service.types';
import { UserService } from '../../../services/user/user.service';
import { XLSXJSType, presentDownload, presentDownloadExcel, transformQueryParams } from '../../../utils/functions';
import { restAxiosConfig } from '../../../utils/rest-axios-config';
import { CaseService } from '../case.service';

const clientsForCase = (c: Case): User[] => compact([c.Client, ...(c.Apartment?.Clients ?? [])]);

const createCasesExportData = (cases: Case[]): XLSXJSType[][] => {
  const numberOfClients = cases.reduce((max, c) => Math.max(max, clientsForCase(c).length), 0);

  return [
    [
      'SaksId',
      'Opprettet',
      'Lukket',
      'SaksNavn',
      'SaksBeskrivelse',
      'ProsjektNavn',
      'BoligNavn',
      'BoligAdresse',
      'Lokasjon',
      'Fagområde',
      'Leverandør(er)',

      ...Array.from({ length: numberOfClients })
        .map((_, i) => [`Kunde${i + 1}Navn`, `Kunde${i + 1}Mobil`, `Kunde${i + 1}Mail`])
        .flat(),

      'TilvalgOrdreId',
      'TilvalgOrdreNavn',
      'TilvalgOrdreBeskrivelse',
      'TilvagsOrdreKategori',
      'TilvalgOrdreAntall',
      'TilvalgOrdreKundeKommentar',
      'TilvalgOrdreKommentar',
    ],
    ...cases.map((c) => {
      const addresses = c.Apartment?.Addresses ?? [];
      const firstVisitationAddress = orderBy(addresses, ['createdAt'], ['desc'])[0];

      return [
        c.id,
        c.createdAt,
        c.completed
          ? moment(c.completed * 1000)
              .utc()
              .format()
          : '',
        c.name,
        c.description,
        c.Project?.name ?? '',
        c.Apartment?.name ?? '',
        [firstVisitationAddress?.line1, firstVisitationAddress?.postal, firstVisitationAddress?.line2]
          .filter((i) => i)
          .join(', '),
        c.location,
        c.Field?.name ?? '',
        c.Contractors.concat(c.Contractor)
          .filter((co) => co)
          .map((co) => co.name)
          .join(', '),

        ...clientsForCase(c)
          .map((client) => [client.name ?? '', client.mobile ?? '', client.mail ?? ''])
          .flat(),

        c.AddonOrder?.id ?? '',
        c.AddonOrder?.Addon?.name ?? '',
        c.AddonOrder?.Addon?.description ?? '',
        c.AddonOrder?.Addon?.AddonCategory?.name ?? '',
        c.AddonOrder?.quantity ?? '',
        c.AddonOrder?.ClientComment ?? '',
        c.AddonOrder?.ManagerComment ?? '',
      ];
    }),
  ];
};

@Component({
  selector: 'apex-case-filter',
  templateUrl: './filter.component.html',
})
export class CaseFilterComponent implements OnInit, OnDestroy {
  private readonly apartmentApi = new ApartmentApi(restAxiosConfig());

  @Input() standardParams: CaseParams;
  @Input() count = 0;
  @Output() refresh = new EventEmitter();

  @ViewChild('project') project: FilterChipsComponent;
  @ViewChild('apartment') apartment: FilterChipsComponent;
  // @ViewChild('client') client: FilterChipsComponent;

  @ViewChild('addonCategory') addonCategory: FilterChipsComponent;

  moment = moment;
  FilterType = FilterType;

  apartmentFieldNumberSearch = '';

  public queryParams: CaseParams = {};
  public hasFilters = false;

  selectedProject = this.route.queryParamMap.pipe(map((qp) => !!qp.get('Project')));
  noObject = this.route.queryParamMap.pipe(map((qp) => !qp.get('Object')));

  commercial: boolean;

  doingCSV = false;
  doingExcel = false;

  exportProgress = 0;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private userService: UserService,
    private caseService: CaseService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.userService.profile$.pipe(take(1)).subscribe((user: User) => {
      this.commercial = !!user?.Customer?.CustomerRight?.Commercial;
    });
    this.subscriptions.add(
      this.route.queryParams.subscribe({
        next: (queryParams) => {
          this.apartmentFieldNumberSearch = queryParams.apartmentFieldNumberSearch ?? '';

          this.queryParams = transformQueryParams(
            Object.assign(cloneDeep(this.standardParams), cloneDeep(queryParams)),
          ) as CaseParams;
          this.refresh.emit();
        },
      }),
    );
  }

  export(full = true): void {
    this.caseService
      .export({ ...this.queryParams, template: full ? 'full' : 'minimal' })
      .pipe(take(1))
      .subscribe({
        next: () => {
          snack(t('Export created and will be sent to you shortly'));
        },
      });
  }

  getExportData$(): Observable<Case[]> {
    this.exportProgress = 0;

    const params = { ...this.queryParams };

    params.page = 0;

    let pages = 1;

    return forkJoin([this.caseService.getCaseCount(params), this.caseService.getCases(params)]).pipe(
      mergeMap(([{ count: casesCount }, cases]) => {
        pages = Math.floor(casesCount / 100);

        return of(cases);
      }),
      expand((cases) => {
        params.page++;

        this.exportProgress = Math.floor((params.page / pages) * 100);

        if (cases?.length !== 100) {
          return EMPTY;
        }

        return this.caseService.getCases(params);
      }),
      mergeMap((cases) => from(cases).pipe(map((item) => item))),
      toArray(),
    );
  }

  exportCSV(): void {
    this.doingCSV = true;

    this.subscriptions.add(
      this.getExportData$()
        .pipe(
          take(1),
          // mergeMap((cases) => {
          //   const projectApartmentIds = compact(cases.map((c) => c.ApartmentId));

          //   const addresses$ = from(projectApartmentIds).pipe(
          //     map(String),
          //     mergeMap((apartmentId) => from(this.apartmentApi.addressControllerApartmentReadCollection(apartmentId))),
          //     take(1),
          //     map((response) => response.data.Collection.filter((address) => address.type === AddressType.Visit)),
          //     toArray(),
          //     map((apartmentsAddresses) => {
          //       const addressMap = new Map<string, ResponseAddressDto[]>();

          //       for (const apartmentAddresses of apartmentsAddresses) {
          //         const modelId = apartmentAddresses[0]?.modelId;

          //         if (!modelId) {
          //           continue;
          //         }

          //         addressMap.set(modelId, apartmentAddresses);
          //       }

          //       return addressMap;
          //     }),
          //   );

          //   return forkJoin([of(cases), addresses$]);
          // }),
          // map(([cases, addressMap]) =>
          //   cases.map((c) => {
          //     if (!!c.Apartment && !!c.ApartmentId) {
          //       c.Apartment.Addresses = addressMap.get(String(c.ApartmentId));
          //     }

          //     return c;
          //   }),
          // ),
        )
        .subscribe({
          next: (cases) => {
            const data = createCasesExportData(cases);
            const csv = data.map((l) => l.map((c) => `"${c}"`).join(';')).join('\n');

            presentDownload(`cases-export-${Date.now()}.csv`, csv, 'text/csv');

            this.doingCSV = false;
          },
          error: (err) => snackErr(t('Failed creating export'), err),
          complete: () => {
            this.doingCSV = false;
          },
        }),
    );
  }

  exportExcel(): void {
    this.doingExcel = true;

    this.subscriptions.add(
      this.getExportData$()
        .pipe(take(1))
        .subscribe({
          next: (cases) => {
            const data = createCasesExportData(cases);

            presentDownloadExcel(`cases-export-${Date.now()}.xlsx`, data, t('Cases'));

            this.doingExcel = false;
          },
          error: (err) => snackErr(t('Failed creating export'), err),
          complete: () => {
            this.doingExcel = false;
          },
        }),
    );
  }

  datePassed(date: Date): boolean {
    return moment().isAfter(moment(date));
  }

  updateQueryParams(key: string, values: number | number[] | boolean | Moment | string): void {
    if (isMoment(values)) {
      values = values.valueOf() / 1000;
    }

    void this.router.navigate([], {
      queryParams: { [key]: values },
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }

  projectChange(): void {
    this.resetSelected('apartment');
    this.resetSelected('client');

    this.resetSelected('addonCategory');

    setTimeout(() => {
      void this.router.navigate([], {
        queryParams: {
          Object: null,
          Apartment: null,
          Client: null,
          AddonCategory: null,
          apartmentFieldNumberSearch: null,
        },
        queryParamsHandling: 'merge',
        state: { skipScroll: true },
      });
    });
  }

  apartmentChange(): void {
    this.resetSelected('client');

    setTimeout(() => {
      void this.router.navigate([], {
        queryParams: { Client: null },
        queryParamsHandling: 'merge',
        state: { skipScroll: true },
      });
    });
  }

  clientChange(ac: Autocomplete): void {
    if (ac) {
      this.subscriptions.add(
        this.userService
          .getClient(ac.id)
          .pipe(take(1))
          .subscribe((response) => {
            const client = response.Entity;

            if (client?.Apartments?.length) {
              if (client.Apartments.length > 0) {
                const apartments = client.Apartments;

                const queryParams = {
                  Project: [...uniq(apartments.map((a) => a.Project.id))],
                  Apartment: [...uniq(apartments.map((a) => a.id))],
                };

                void this.router.navigate([], {
                  queryParams,
                  queryParamsHandling: 'merge',
                  state: { skipScroll: true },
                });
              }
            }
          }),
      );
    }
  }

  categoryChange(filter: Filter[]): void {
    const mapped = filter.map((f) => f.id);

    if (!mapped.includes(14)) {
      this.resetSelected('addonCategory');

      setTimeout(() => {
        void this.router.navigate([], {
          queryParams: {
            AddonCategory: null,
          },
          queryParamsHandling: 'merge',
          state: { skipScroll: true },
        });
      });
    }
  }

  tagChange(): void {
    setTimeout(() => {
      void this.router.navigate([], {
        queryParams: {},
        queryParamsHandling: 'merge',
        state: { skipScroll: true },
      });
    });
  }

  /**
   *
   * @param apartment idn for an apartment, nested project idn.
   */
  apartmentClick(apartment: Apartment): void {
    if (!apartment.id || !apartment?.Project?.id) {
      return;
    }

    const queryParams = {
      Project: [apartment.Project.id],
      Apartment: [apartment.id],
    };

    void this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }

  setObject(ids: number | number[]): void {
    const queryParams = { Object: ids };

    if (Array.isArray(ids) ? ids?.length : ids) {
      this.resetSelected('project');
      this.resetSelected('apartment');
      this.resetSelected('client');

      Object.assign(queryParams, {
        Project: null,
        Apartment: null,
        Client: null,
      });
    }

    void this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }

  markCasesAsRead(): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: t('Are you sure you want to mark all found cases as read?'),
          description: t('Edits all cases that the filter hits.'),
        },
      })
      .afterClosed()
      .subscribe({
        next: (confirm: boolean) => {
          if (confirm) {
            this.caseService.markCasesAsRead(this.queryParams).subscribe({
              next: (count: number) => {
                snack(t('{count} cases marked read', { count }));
              },
            });
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get hasCommercial(): boolean {
    return this.userService.hasCustomerRight('Commercial');
  }

  get hasProject(): boolean {
    return this.userService.hasCustomerRight('Project');
  }

  hasPayment(): boolean {
    return this.userService.hasPayment();
  }

  clearFilters(): void {
    void this.router.navigate([], {
      queryParams: {},
      state: { skipScroll: true },
    });
  }

  foundGPS(objectId: number): void {
    void this.router.navigate([], {
      queryParams: {
        Project: null,
        Apartment: null,
        Client: null,
        Object: objectId,
      },
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }

  checklistChange(): void {
    setTimeout(() => {
      if (this.queryParams?.Checklist) {
        this.queryParams.showChecklistItems = true;
        this.updateQueryParams('showChecklistItems', true);
      }
    }, 25);
  }

  private resetSelected(name: string): void {
    if (this[name]) {
      this[name].selectedModels = [];
    }
  }
}
