<section
  class="avatars-wrapper df c"
  *ngIf="userId">
  <apex-file-usage
    #fileUsage
    [self]="self"
    [selfId]="userId"
    [name]="name"
    [pattern]="pattern"
    [title]="'Avatars' | translate"
    (selectionChanged)="selectionChange($event)"
    view="Grid">
  </apex-file-usage>

  <div class="df c afe amts">
    <p class="amb">
      <T str="Select one image to set as avatar"></T>
    </p>
    <div class="df">
      <div class="amr">
        <button
          type="button"
          mat-raised-button
          color="warn"
          (click)="deleteAvatar()">
          <T str="Delete avatar"></T>
        </button>
      </div>
      <button
        type="button"
        [matTooltip]="'Set image as avatar' | translate"
        [disabled]="!onlyOneSelected"
        mat-raised-button
        color="primary"
        (click)="chooseAvatar()">
        <T str="Set as avatar"></T>
      </button>
    </div>
  </div>
</section>
