import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'apex-text-clamp',
  templateUrl: './text-clamp.component.html',
  styleUrls: ['./text-clamp.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class TextClampComponent {
  @Input() lines: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' = '1';
}
