import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModelRole } from 'projects/apex/src/app/models/model-role';
import { Observable, Subscription, merge, of } from 'rxjs';
import { tap, toArray } from 'rxjs/operators';
import { snack, snackErr } from '../../modules/snack.module';
import { hasDateExpired, isDateActive, periodDateToolTip } from '../../utils/functions';
import { t } from '../translate/translate.function';
import { ModelRoleChange } from './change';
import { ModelRoleDialogComponent } from './form-dialog.component';
import { ModelRoleService } from './model-role.service';
import { ModelRoleRoles, translateKnownModelRoleRoles } from './model-role.translated';
import { KnownModelRoleModels, KnownModelRoleRoles, ModelRoleDialogData } from './model-role.types';

class ModelRolesExtended extends ModelRole {
  translatedRole?: string;
}

@Component({
  selector: 'apex-model-role',
  templateUrl: './model-role.component.html',
})
export class ModelRoleComponent implements OnInit, OnDestroy {
  @Input() model: KnownModelRoleModels;
  @Input() modelId: number | string;
  @Input() role: KnownModelRoleRoles;
  @Input() label = t('User');
  @Input() title = t('Involved');
  @Input() params: Record<string, string | number | boolean>;
  @Input() disabled: boolean;
  @Input() viewMode = false;
  @Input() emptyStateText = t('No roles added');
  @Input() headerIcon: string;
  @Input() CRM: boolean;
  @Input() allowedRoles: KnownModelRoleRoles[] = [];

  @Input() expanded = true;
  @Input() expandable = true;

  @Output() modelChange = new EventEmitter<ModelRoleChange>();
  @Output() modelRolesChange = new EventEmitter<ModelRole[]>();

  modelRoles: ModelRolesExtended[] = [];
  loading: boolean;
  adding: boolean;

  private subscription = new Subscription();

  constructor(
    private modelRoleService: ModelRoleService,
    private dialog: MatDialog,
  ) {}

  get roles(): ModelRole[] {
    return this.modelRoles;
  }

  get rolesCount(): number {
    return this.modelRoles.length;
  }

  get isNew(): boolean {
    return this.modelId === 0;
  }

  ngOnInit(): void {
    if (!this.role && !this.allowedRoles?.length) {
      this.title = t('Error');
    }

    if (this.isNew && this.model) {
      // Dealing with something new here, can't really fetch much
      return;
    }

    if (!this.model || !this.modelId) {
      this.title = t('Error');
    }

    if (this.modelId) {
      this.loading = true;

      let query: Observable<ModelRole[]>;

      if (this.role) {
        query = this.modelRoleService.queryRoleForModel(this.model, this.modelId, this.role);
      } else {
        query = this.modelRoleService.queryRolesByModel(this.model, this.modelId);
      }

      this.subscription.add(
        query.subscribe({
          next: (modelRoles) => {
            this.loading = false;

            this.modelRoles = (modelRoles || []).map((modelRole) => {
              const extendedModelRole: ModelRolesExtended = new ModelRolesExtended(modelRole);

              extendedModelRole.translatedRole = t(translateKnownModelRoleRoles[modelRole.role]);

              return extendedModelRole;
            });
          },
          error: (err) => {
            this.loading = false;
            this.modelRoles = [];
            snackErr(t('Failed to get roles'), err);
          },
          complete: () => {
            this.modelRolesChange.emit(this.modelRoles);
          },
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  add(): void {
    this.adding = true;

    this.dialog
      .open<ModelRoleDialogComponent, ModelRoleDialogData>(ModelRoleDialogComponent, {
        data: {
          CRM: this.CRM,
          params: this.params,
          label: this.label,
          model: this.model,
          modelId: this.modelId,
          role: this.role,
          allowedRoles: ModelRoleRoles.filter(
            (mrr) => !!this.allowedRoles?.find((allowedRole) => allowedRole === mrr.value),
          ),
          existingRoles: this.modelRoles,
        },
      })
      .afterClosed()
      .subscribe({
        next: (res) => {
          if (res?.modelRole) {
            const extendedModelRole: ModelRolesExtended = new ModelRolesExtended(res.modelRole);

            extendedModelRole.translatedRole = t(translateKnownModelRoleRoles[extendedModelRole.role]);

            this.modelRoles.push(extendedModelRole);

            this.modelChange.emit({
              modelRole: res.modelRole,
              operation: 'save',
            });
          }
        },
        complete: () => {
          this.adding = false;
        },
      });
  }

  remove(modelRole: ModelRole): void {
    let error = null;
    let sub = null;

    if (modelRole.id) {
      sub = this.modelRoleService.delete(modelRole).subscribe({
        next: () => {
          const idx = this.modelRoles.indexOf(modelRole);

          if (idx !== -1) {
            this.modelRoles.splice(idx, 1);
            this.modelChange.emit({
              modelRole,
              operation: 'remove',
            });
          }
        },
        error: (err) => {
          error = err;
        },
      });
    } else {
      const idx = this.modelRoles.indexOf(modelRole);

      if (idx !== -1) {
        this.modelRoles.splice(idx, 1);
      }
    }

    error ? snackErr(t('Could not delete'), error) : snack(t('Deleted'));

    this.subscription.add(sub);
  }

  isActive(mr: ModelRole): boolean {
    return isDateActive(mr.from, mr.to);
  }

  hasExpired(mr: ModelRole): boolean {
    return hasDateExpired(mr.to);
  }

  periodToolTip(mr: ModelRole): string {
    return periodDateToolTip(mr.from, mr.to);
  }

  saveAll(): Observable<ModelRole[]> {
    if (this.modelId && this.model) {
      const requests = this.modelRoles.map((mr) => {
        mr.modelId = String(this.modelId);

        return this.modelRoleService.saveRoleForModel(mr);
      });

      return merge(...requests).pipe(
        toArray(),
        tap((modelRoles) => {
          this.modelRoles = modelRoles;
        }),
      );
    }

    return of([]);
  }
}
