import { Pipe, PipeTransform } from '@angular/core';
import { projectClientCategories } from '../../../models/case-category.data';

@Pipe({
  name: 'categoryIsPublicForClient',
  standalone: true,
})
export class CategoryIsPublicForClientPipe implements PipeTransform {
  transform(caseCategoryId: number): boolean {
    return projectClientCategories.includes(caseCategoryId);
  }
}
