import { CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ChecklistTemplateSectionFormComponent } from 'projects/apex/src/app/features/checklist-template/section/form.component';
import { nameCompare } from 'projects/apex/src/app/utils/functions';
import { Subscription, concat } from 'rxjs';
import { debounceTime, mergeMap, skipWhile } from 'rxjs/operators';
import { t } from '../../../components/translate/translate.function';
import { FileUsage } from '../../../models/file-usage';
import { snack, snackErr } from '../../../modules/snack.module';
import { constants } from '../../../utils/constants';
import { ChecklistTemplate, ChecklistTemplateItem, ChecklistTemplateSection } from '../checklist-template.model';
import { ChecklistTemplateItemService, ChecklistTemplateSectionService } from '../checklist-template.service';
import { DropListService } from '../services/droplist.service';

@Component({
  selector: 'apex-checklist-template-section-view',
  templateUrl: './view.component.html',
})
export class ChecklistTemplateSectionViewComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() checklistTemplate: ChecklistTemplate;
  @Input() checklistTemplateItems: ChecklistTemplateItem[] = [];
  @Input() checklistTemplateSection: ChecklistTemplateSection;
  @Input() checklistTemplateSections: ChecklistTemplateSection[] = [];
  @Input() disabled: boolean;
  @Input() templateCategory: number;
  @Input() step: string;
  @Output() listChange = new EventEmitter();

  @ViewChild('checklistTemplateSectionForm') checklistTemplateSectionForm: NgForm;
  @ViewChildren(CdkDropList) dropLists: QueryList<CdkDropList>;

  items: ChecklistTemplateItem[] = [];
  sections: ChecklistTemplateSection[] = [];

  fileUsages: FileUsage[] = [];

  expanded = true;

  private subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private service: ChecklistTemplateSectionService,
    private itemService: ChecklistTemplateItemService,
    protected dropListService: DropListService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(change: SimpleChanges): void {
    if (change.checklistTemplate || change.checklistTemplateItems || change.checklistTemplateSections) {
      this.filter();
    }
  }

  ngAfterViewInit(): void {
    this.dropLists.forEach((dropList) => this.dropListService.addDropList(dropList));
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.dropLists.forEach((dropList) => this.dropListService.removeDropList(dropList));

    this.subscription.unsubscribe();
  }

  remove(): void {
    const sub = this.service.deleteSection(this.checklistTemplate.id, this.checklistTemplateSection).subscribe({
      next: (item) => {
        const index = this.checklistTemplateSections.map((cti) => cti.id).indexOf(item.id);

        if (index !== -1) {
          this.checklistTemplateSections.splice(index, 1);
          this.listChange.emit();
          snack(t('Deleted'));
        }
      },
    });

    this.subscription.add(sub);
  }

  save(): void {
    if (this.checklistTemplate.id) {
      const sub = this.service
        .saveSection(this.checklistTemplate.id, this.checklistTemplateSection)
        .pipe(debounceTime(constants.requestDebounceTime))
        .subscribe({
          next: () => {
            snack(t('Saved'));
          },
          error: (err) => {
            snackErr(t('Could not save'), err);
          },
        });

      this.subscription.add(sub);
    }
  }

  dropItem(event: CdkDragDrop<ChecklistTemplateItem[]>): void {
    const index = this.items.findIndex((i) => i?.id === event.item.data?.id);

    if (index > -1) {
      moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }

    const droppedSectionId = event.container.id.replace('-items', '');

    this.checklistTemplateItems = this.items
      .map((cti, i) => {
        cti.sortOrder = i;
        cti.SectionId = Number(droppedSectionId);

        this.itemService
          .saveItem(this.checklistTemplate.id, cti)
          .pipe(debounceTime(constants.requestDebounceTime))
          .subscribe({
            next: (updatedItem: ChecklistTemplateItem) => {
              const itemIndex = this.items.findIndex((item) => item.id === updatedItem.id);

              if (itemIndex !== -1) {
                this.items[itemIndex] = updatedItem;
              }
            },
          });

        return cti;
      })
      .sort(this.sortFunc);
  }

  editSection(section?: ChecklistTemplateSection): void {
    if (!section) {
      section = new ChecklistTemplateSection({
        ParentId: this?.checklistTemplateSection?.id || null,
        sortOrder: this.checklistTemplateSections?.length ?? 0,
      });

      this.expanded = true;
    }

    if (this.checklistTemplate?.id) {
      this.dialog
        .open(ChecklistTemplateSectionFormComponent, { data: { section } })
        .afterClosed()
        .pipe(
          skipWhile((s) => !s),
          mergeMap((s: ChecklistTemplateSection) => this.service.saveSection(this.checklistTemplate.id, s)),
        )
        .subscribe({
          next: (savedSection: ChecklistTemplateSection) => {
            if (savedSection?.id) {
              const oldSection = this.checklistTemplateSections.find((s) => s.id === savedSection.id);

              if (oldSection) {
                Object.assign(oldSection, savedSection);
              } else {
                this.checklistTemplateSections.push(savedSection);
                this.filter();
              }
            }
          },
        });
    }
  }

  addItem(): void {
    this.checklistTemplateItems.push(
      new ChecklistTemplateItem({
        SectionId: this?.checklistTemplateSection?.id || null,
        sortOrder: this.checklistTemplateItems?.length ?? 0,
      }),
    );

    this.filter();
    this.expanded = true;
  }

  dropSection(event: CdkDragDrop<ChecklistTemplateSection[]>): void {
    moveItemInArray(this.sections, event.previousIndex, event.currentIndex);

    concat(
      ...this.sections.map((section: ChecklistTemplateSection, i: number) => {
        section.sortOrder = i;

        return this.service.saveSection(this.checklistTemplate.id, section);
      }),
    ).subscribe({
      next: (s) => {
        const oldSection = this.checklistTemplateSections.find((ss) => ss.id === s.id);

        Object.assign(oldSection, s);
      },
      complete: () => {
        this.sections = this.sections.sort(this.sortFunc);
      },
    });
  }

  sortFunc(a: { sortOrder: number; name: string }, b: { sortOrder: number; name: string }): number {
    const sortA: number = a.sortOrder;
    const sortB: number = b.sortOrder;

    if (sortA === sortB) {
      return nameCompare(a, b);
    }

    return sortA > sortB ? 1 : sortB > sortA ? -1 : 0;
  }

  filter(): void {
    this.items = this.checklistTemplateItems
      .filter((i) => i.SectionId === (this.checklistTemplateSection?.id || null))
      .sort(this.sortFunc);
    this.sections = this.checklistTemplateSections
      .filter((s) => s.ParentId === (this.checklistTemplateSection?.id || null))
      .sort(this.sortFunc);
  }
}
