import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment, { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { t } from '../../../components/translate/translate.function';
import { Case, Contractor } from '../../../models/case';
import { User } from '../../../models/user';
import { snack } from '../../../modules/snack.module';
import { UserService } from '../../../services/user/user.service';

import { orderBy } from 'lodash-es';
import { map } from 'rxjs/operators';
import ProjectInvolvedUsersDialogInputType from '../../project/involved-users-dialog/dialog/project-involved-users-dialog-input-type';
import ProjectInvolvedUsersDialogOutputType from '../../project/involved-users-dialog/dialog/project-involved-users-dialog-ouput.type';
import { ProjectInvolvedUsersDialogComponent } from '../../project/involved-users-dialog/dialog/project-involved-users-dialog.component';
import { ContractorDialogComponent } from './form-dialog.component';
import { StepsFromCreatorPipe } from './steps-from-creator.pipe';

type InputContractor = {
  id: number;
  name: string;

  CaseContractor: {
    UserId: number;
    CreatorId: number;
  };
};

@Component({
  selector: 'apex-case-contractors',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [StepsFromCreatorPipe],
})
export class CaseContractorsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() case: Case;
  @Input() view = false;
  @Input() edit = false;
  @Input() disabled = false;
  @Input() expanded = true;

  @Output() addContractor = new EventEmitter<Contractor>();
  @Output() removeContractor = new EventEmitter<Contractor>();
  @Output() editContractor = new EventEmitter<Contractor>();

  @ViewChildren('.contractor-container', { read: ElementRef }) contractorContainers: QueryList<ElementRef>;

  get notificationTooltip(): string {
    return !this.case?.notifyContractor
      ? t('Send notification to contractors')
      : t('Do not send notification to contractors');
  }

  currentUser: User;

  private subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private stepsFromCreatorPipe: StepsFromCreatorPipe,
  ) {}

  ngOnInit(): void {
    if (!this.case.Contractors) {
      this.case.Contractors = [];
    }

    this.currentUser = this.userService.profile;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.case.Contractors = orderBy(this.case.Contractors, ['CaseContractor.createdAt']);

    if (changes.case?.currentValue?.id !== changes.case?.previousValue?.id) {
      if (!this.view) {
        return;
      }

      if (!this.case.from || !this.case.to) {
        return;
      }

      const me = this.case.Contractors.find((cc) => cc.id === this.currentUser?.id);

      if (!me?.CaseContractor) {
        return;
      }

      const parentContractor = this.parentContractor(me);

      let from = this.case.from;
      let to = this.case.to;

      if (parentContractor?.CaseContractor) {
        from = parentContractor.CaseContractor.from;
        to = parentContractor.CaseContractor.to;
      }

      if (me) {
        if (from && !to && from > me.CaseContractor.from) {
          this.snackIt(t('Your scheduled time for this case is too early'), me);

          return;
        }

        if (!from && to && to < me.CaseContractor.to) {
          this.snackIt(t('Your scheduled time for this case is too late'), me);

          return;
        }

        if (from && to) {
          if (
            me.CaseContractor.from &&
            me.CaseContractor.to &&
            (!moment(me.CaseContractor.from).isBetween(from, to, null, '[]') ||
              !moment(me.CaseContractor.to).isBetween(from, to, null, '[]'))
          ) {
            this.snackIt(t('Your scheduled time for this case is not within allotted time'), me);

            return;
          }

          if (!me.CaseContractor.from || !me.CaseContractor.to) {
            this.snackIt(t('You have not scheduled a time for this case'), me);

            return;
          }
        }
      }
    }
  }

  snackIt(msg: string, contractor: Contractor): void {
    snack(msg, {
      dur: 5000,
      act: {
        msg: t('Set time'),
        fn: () => this.editContractorTime(contractor),
      },
    });
  }

  addInvolvedUser(): void {
    this.subscription.add(
      this.dialog
        .open<
          ProjectInvolvedUsersDialogComponent,
          ProjectInvolvedUsersDialogInputType,
          ProjectInvolvedUsersDialogOutputType
        >(ProjectInvolvedUsersDialogComponent, {
          data: {
            id: this.case.Project?.id || this.case.Object?.id,
            type: this.case.Project?.id ? 'project' : 'object',
            excludedUserIds: this.case.Contractors.map((c) => c.id),
          },
          panelClass: ['apex-fullscreen-dialog', 'phone'],
        })
        .afterClosed()
        .pipe(
          map((res) =>
            res?.selectedUsers?.map((user) => {
              const { notifyOnAddSms, sendEmail } = res;

              const contractor = new Contractor({ id: user.id, name: user.name });

              contractor.CaseContractor.notifyOnAddSms = notifyOnAddSms;
              contractor.CaseContractor.notifyOnAddEmail = sendEmail;

              return contractor;
            }),
          ),
        )
        .subscribe({
          next: (res) => {
            if (!res) {
              return;
            }

            for (const contractor of res) {
              this.addContractor.emit(contractor);
            }
          },
        }),
    );
  }

  remove(contractor: Contractor): void {
    this.removeContractor.emit(contractor);
  }

  editContractorTime(contractor: Contractor): void {
    const controlId = contractor.id;

    this.subscription.add(
      this.dialog
        .open(ContractorDialogComponent, {
          data: {
            case: this.case,
            view: this.view,
            edit: true,
            contractor,
          },
        })
        .afterClosed()
        .subscribe({
          next: (res) => {
            if (res?.contractor) {
              if (res.contractor.id !== controlId) {
                snack(t('Not allowed to change contractor'));
              } else {
                this.editContractor.emit(res.contractor);
              }
            }
          },
        }),
    );
  }

  isCurrentUser(contractor: Contractor): boolean {
    if (this.currentUser?.id === contractor.id) {
      return true;
    }

    return false;
  }

  parentContractor(contractor: Contractor): Contractor {
    return this.case?.Contractors?.find(
      (c) => c.id === contractor.CaseContractor.CreatorId && contractor.CaseContractor.CreatorId !== contractor.id,
    );
  }

  getFromToForContractor(contractor: Contractor): [Moment, Moment] {
    let from = this.case.from;
    let to = this.case.to;

    const parentContractor = this.parentContractor(contractor);

    if (parentContractor?.CaseContractor) {
      from = parentContractor.CaseContractor.from;
      to = parentContractor.CaseContractor.to;
    }

    return [moment(from), moment(to)];
  }

  hasContractorFromTo(contractor: Contractor): boolean {
    if (!contractor?.CaseContractor) {
      return false;
    }

    if (!contractor?.CaseContractor.from) {
      return false;
    }

    if (!contractor?.CaseContractor.to) {
      return false;
    }

    return true;
  }

  fromWithin(contractor: Contractor): boolean {
    if (!this.hasContractorFromTo(contractor)) {
      return false;
    }

    const [from] = this.getFromToForContractor(contractor);

    return moment(contractor.CaseContractor.from).isSameOrAfter(from);
  }

  toWithin(contractor: Contractor): boolean {
    if (!this.hasContractorFromTo(contractor)) {
      return false;
    }

    const [, to] = this.getFromToForContractor(contractor);

    return moment(contractor.CaseContractor.to).isSameOrBefore(to);
  }

  hasChildren(contractor: InputContractor, contractors: InputContractor[]): boolean {
    return contractors.some((c) => c.CaseContractor.CreatorId === contractor.id);
  }
}
