import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CaseParams } from '../../../models/case-params';
import { CaseFormDialogComponent } from '../form/dialog.component';

@Component({
  selector: 'apex-case-filter-dialog',
  templateUrl: './filter-dialog.component.html',
})
export class CaseFilterDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { standardParams: CaseParams; count: number },
    public ref: MatDialogRef<CaseFormDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.ref?.addPanelClass(['apex-fullscreen-dialog', 'phone']);
  }
}
