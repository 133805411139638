<div mat-dialog-title>
  <div class="df f1 ac jsb">
    <h1>
      <T str="Share with other users"></T>
    </h1>
    <button
      mat-icon-button
      mat-dialog-close>
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>

<div mat-dialog-content>
  <div
    *ngIf="!loading"
    class="df c">
    <apex-autocomplete
      #user
      class="f1"
      type="User"
      [label]="'User' | translate"
      (modelChange)="newUser($event?.id); user.writeValue(null)">
    </apex-autocomplete>
    <div
      *ngFor="let user of users"
      class="df ac amb">
      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          (click)="viewAccess(user)">
          <T str="Viewer"></T>
        </button>
        <button
          *ngIf="!data?.files?.length"
          mat-menu-item
          (click)="editAccess(user)">
          <T str="Editor"></T>
        </button>
        <mat-divider></mat-divider>
        <button
          mat-menu-item
          (click)="removeAccess(user)">
          <T str="Remove"></T>
        </button>
      </mat-menu>
      <div class="f1 df c">
        <div class="mat-h3 ambxs">
          {{ user?.name }}
        </div>
        <div class="mat-caption dates rm df ac">
          <a
            *ngIf="user.from"
            (click)="picker.opened = true"
            [ngClass]="{ 'error-color': hasExpired(user), 'success-color': isActive(user) }"
            [matTooltip]="periodToolTip(user)">
            {{ user?.from | dater | date: 'shortDate' }} - {{ user?.to | dater | date: 'shortDate' }}
          </a>
          <a
            *ngIf="!user.from && !user.to"
            (click)="picker.opened = true">
            <T str="Varies"></T>
          </a>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              [(ngModel)]="user.from" />
            <input
              matEndDate
              [(ngModel)]="user.to" />
          </mat-date-range-input>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </div>
      </div>
      <button
        mat-button
        [matMenuTriggerFor]="menu">
        <T
          *ngIf="user.shareRole === FolderShareUserRole.Viewer"
          str="Viewer"></T>
        <T
          *ngIf="user.shareRole === FolderShareUserRole.Editor"
          str="Editor"></T>
        <T
          *ngIf="user.shareRole === FolderShareUserRole.Varies"
          str="Varies"></T>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
    </div>
  </div>
  <div
    *ngIf="loading"
    class="am df ac jc">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div mat-dialog-actions>
  <div>
    <T
      str="{count} files are selected"
      [vars]="{ count: count }">
    </T>
  </div>
  <div>
    <button
      mat-button
      (click)="dialogRef.close()">
      <T str="Cancel"></T>
    </button>
    <button
      mat-raised-button
      [disabled]="loading"
      (click)="confirm()"
      color="primary">
      <T str="Confirm"></T>
    </button>
  </div>
</div>
