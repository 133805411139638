import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HammerTimeDirective } from './hammer-time.directive';

@NgModule({
  declarations: [HammerTimeDirective],
  imports: [CommonModule],
  exports: [HammerTimeDirective],
})
export class HammerTimeModule {}
