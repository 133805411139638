import { Pipe, PipeTransform } from '@angular/core';
import { UserFavorite } from '../../../models/user-favorite';

@Pipe({
  name: 'checkFavorites',
  standalone: true,
})
export class CheckFavoritesPipe implements PipeTransform {
  transform(favorites: UserFavorite[], modelName: string, modelId: number): boolean {
    return favorites.some((f) => f.modelName === modelName && f.modelId === modelId);
  }
}
