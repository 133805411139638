<apex-checklist-section
  *ngIf="!loading"
  [checklist]="checklist"
  [checklistItems]="checklistItems"
  [checklistSections]="checklistSections">
</apex-checklist-section>
<div
  *ngIf="loading"
  class="f1 df ac jc">
  <mat-spinner></mat-spinner>
</div>
