import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment from 'moment';
import { Case } from 'projects/apex/src/app/models/case';
import { User } from 'projects/apex/src/app/models/user';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import { Observable, Subscription, from } from 'rxjs';
import { concatMap, filter, mergeMap, take, tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '../../../components/confirm-dialog/confirm-dialog.types';
import { t } from '../../../components/translate/translate.function';
import { CaseView } from '../../../models/case-view';
import { snack } from '../../../modules/snack.module';
import { CaseService } from '../case.service';
import { CaseListEntity } from '../case.types';
import { CaseNewMessageDialogComponent } from '../new-message/new-message-dialog.component';
import { CaseViewDialogComponent } from '../view/dialog.component';
import { CaseStatusMessageDialogComponent } from '../view/status-message-dialog.component';
import { CaseMassEditComponent } from './mass-edit.component';

@Component({
  selector: 'apex-case-list',
  templateUrl: './list.component.html',
})
export class CaseListComponent implements OnInit, OnDestroy {
  @Input() cases: CaseListEntity[] = [];
  @Input() highlightedCaseIds: number[];
  @Input() openCaseOnClick = true;
  @Input() dialogClass: string;

  @Output() idChange = new EventEmitter<number>();
  @Output() casesChange = new EventEmitter<Case[]>();
  @Output() caseChange = new EventEmitter<Case>();

  moment = moment;

  idValue: number;

  dialogRef: MatDialogRef<CaseViewDialogComponent>;

  changeStatusOnSelected = false;

  get selected(): CaseListEntity[] {
    return this.cases.filter((c) => c.selected);
  }

  @Input()
  get id(): number {
    return this.idValue;
  }

  set id(value: number) {
    this.idValue = value;

    const caseInList = this.cases?.find((c) => c.id === value);

    if (caseInList && (!caseInList.CaseViews || !caseInList.CaseViews.length)) {
      caseInList.CaseViews = [];
      caseInList.CaseViews.push(new CaseView());
    }

    if (this.id && this.openCaseOnClick) {
      if (this.dialogRef) {
        this.dialogRef.componentInstance.viewComponent.id = this.id;
      } else {
        this.caseService
          .get(this.id)
          .pipe(take(1))
          .subscribe({
            next: (c: Case) => {
              this.dialogRef = this.dialog.open(CaseViewDialogComponent, {
                data: {
                  case: c,
                  highlighted: !!this.highlightedCaseIds?.find((id) => id === c.id),
                  contentClass: this.dialogClass,
                },
              });

              const sub = new Subscription();

              if (this.dialogRef?.componentInstance?.next) {
                sub.add(this.dialogRef.componentInstance.next.subscribe({ next: () => this.forwardOneCase() }));
              }

              if (this.dialogRef?.componentInstance?.back) {
                sub.add(this.dialogRef.componentInstance.back.subscribe({ next: () => this.backOneCase() }));
              }

              if (this.dialogRef?.componentInstance?.caseChange) {
                sub.add(
                  this.dialogRef.componentInstance.caseChange.subscribe({
                    next: (changedCase: Case) => {
                      const oldCase = this.cases.find((cle) => cle?.id === changedCase?.id);

                      if (oldCase) {
                        Object.assign(oldCase, changedCase);
                        this.caseChange.emit(changedCase);
                      }
                    },
                  }),
                );
              }

              sub.add(
                this.dialogRef.afterClosed().subscribe({
                  next: (res) => {
                    sub?.unsubscribe();
                    this.dialogRef = null;

                    if (res?.case) {
                      const oldCase = this.cases.find((cle) => cle?.id === res.case.id);

                      if (oldCase) {
                        Object.assign(oldCase, res.case);
                        this.caseChange.emit(res.case);
                      }

                      this.id = null;
                    }

                    if (res?.navigate) {
                      void this.router.navigate(res.navigate.commands, res.navigate.extras);
                    }
                  },
                }),
              );
            },
          });
      }
    }

    this.idChange.emit(this.id);
  }

  page = 0;
  profile: User;
  loading: boolean;
  editLoading: boolean;

  subscription = new Subscription();

  get allSelected(): boolean {
    return this.selected.length !== 0 && this.selected.length === this.cases.length;
  }

  get anySelected(): boolean {
    return this.selected.length > 0 && this.selected.length !== this.cases.length;
  }

  get isContractorOnAnySelected(): boolean {
    return this.selected.some((c) => c.Contractors?.some((cc) => cc.id === this.profile?.id));
  }

  get selectedCasesContractorCanAndCannotChangeStatusOn(): [Case[], Case[]] {
    const canNotChangeStatusOn: Case[] = [];

    const canChangeStatusOn = this.selected.filter((c) => {
      const isContractorOnCase = c.Contractors?.some((cc) => cc.id === this.profile?.id);

      if (!isContractorOnCase) {
        canNotChangeStatusOn.push(c);
      }

      return isContractorOnCase;
    });

    return [canChangeStatusOn, canNotChangeStatusOn];
  }

  constructor(
    private caseService: CaseService,
    public userService: UserService,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.userService.profile$.subscribe((user: User) => {
        this.profile = user;
      }),
    );
  }

  caseClicked(clickedCase: Case): void {
    if (!clickedCase.CaseViews || !clickedCase.CaseViews.length) {
      clickedCase.CaseViews = [];
      clickedCase.CaseViews.push(new CaseView());
    }

    this.id = clickedCase.id;
  }

  forwardOneCase(fromIndex?: number): void {
    const currentIndex = (fromIndex !== undefined ? fromIndex : this.cases.map((c) => c.id).indexOf(this.id)) + 1;

    if (this.cases.length > currentIndex) {
      if (this.cases[currentIndex].archivedAt) {
        this.forwardOneCase(currentIndex);
      } else {
        this.id = this.cases[currentIndex]?.id;
      }
    }
  }

  backOneCase(fromIndex?: number): void {
    const currentIndex = (fromIndex !== undefined ? fromIndex : this.cases.map((c) => c.id).indexOf(this.id)) - 1;

    if (0 <= currentIndex) {
      if (this.cases[currentIndex].archivedAt) {
        this.backOneCase(currentIndex);
      } else {
        this.id = this.cases[currentIndex]?.id;
      }
    }
  }

  toggle(caseListEntity: CaseListEntity, event: MatCheckboxChange): void {
    caseListEntity.selected = event.checked;
  }

  selectAll(): void {
    if (this.selected.length !== this.cases.length) {
      this.cases.forEach((c) => {
        c.selected = true;
      });
    } else {
      this.cases.forEach((c) => {
        c.selected = false;
      });
    }
  }

  openNewMessage(): void {
    this.dialog.open(CaseNewMessageDialogComponent, {
      data: { cases: this.selected },
    });
  }

  unarchiveCase(caseToBeUnarchived: Case): void {
    this.caseService.unarchive(caseToBeUnarchived).subscribe((c) => {
      snack(t('Case unarchived'));
      Object.assign(caseToBeUnarchived, c);
    });
  }

  openMassEdit(): void {
    const ref: MatDialogRef<CaseMassEditComponent> = this.dialog.open(CaseMassEditComponent, {
      width: '80vw',
      data: { cases: this.selected },
    });
    const saveSub = ref?.componentInstance?.isSaving$.subscribe({
      next: (saving: boolean) => {
        if (!saving) {
          saveSub.unsubscribe();
        }

        this.editLoading = saving;
      },
    });

    ref.afterClosed().subscribe(() => {
      if (!this.editLoading) {
        saveSub.unsubscribe();
      }

      this.casesChange.emit(this.cases);

      if (this.selected.find((c) => c.id === this.id)) {
        this.caseChange.emit(this.cases.find((c) => c.id === this.id));
      }
    });
  }

  finish(): void {
    this.subscription.add(
      from(this.selected)
        .pipe(
          concatMap((c: Case) => this.caseService.finish(c).pipe(tap((updatedCase) => Object.assign(c, updatedCase)))),
        )
        .subscribe(),
    );
  }

  reopen(): void {
    this.subscription.add(
      from(this.selected)
        .pipe(
          concatMap((c: Case) => this.caseService.reopen(c).pipe(tap((updatedCase) => Object.assign(c, updatedCase)))),
        )
        .subscribe(),
    );
  }

  markAsUnread(): void {
    this.subscription.add(
      from(this.selected)
        .pipe(
          concatMap((c: Case) =>
            this.caseService.markAsUnread(c).pipe(tap((updatedCase) => Object.assign(c, updatedCase))),
          ),
        )
        .subscribe(),
    );
  }

  markAsRead(): void {
    this.subscription.add(
      from(this.selected)
        .pipe(
          concatMap((c: Case) =>
            this.caseService.markAsRead(c).pipe(tap((updatedCase) => Object.assign(c, updatedCase))),
          ),
        )
        .subscribe(),
    );
  }

  accept(): void {
    const [canBeAccepted, canNotBeAccepted] = this.selectedCasesContractorCanAndCannotChangeStatusOn;

    // non can be accepted (not contractor on any of the selected cases)
    if (!canBeAccepted.length) {
      snack(t('Only cases you are contractor on can be accepted'));

      return;
    }

    // all can be accepted
    if (!canNotBeAccepted.length && !!canBeAccepted.length) {
      this.subscription.add(
        this.acceptCases(canBeAccepted).subscribe({
          complete: () => snack(t('Saved')),
        }),
      );
    } else if (!!canBeAccepted.length && !!canNotBeAccepted.length) {
      this.changeStatusOnSelected = false;

      this.openChangeStatusDialog(canNotBeAccepted)
        .afterClosed()
        .pipe(
          filter((v) => {
            this.changeStatusOnSelected = !!v;

            return !!v;
          }),
          mergeMap(() => this.acceptCases(canBeAccepted)),
        )
        .subscribe({
          complete: () => {
            if (this.changeStatusOnSelected) {
              snack(t('Saved'));
            }
          },
        });
    }
  }

  decline(): void {
    const [canBeDeclined, canNotBeDeclined] = this.selectedCasesContractorCanAndCannotChangeStatusOn;

    // non can be declined (not contractor on any of the selected cases)
    if (!canBeDeclined.length) {
      snack(t('Only cases you are contractor on can be declined'));

      return;
    }

    this.changeStatusOnSelected = false;

    // all can be declined
    if (!canNotBeDeclined.length && !!canBeDeclined.length) {
      this.subscription.add(
        this.declineCases(canBeDeclined).subscribe({
          complete: () => {
            if (this.changeStatusOnSelected) {
              snack(t('Saved'));
            }
          },
        }),
      );
    } else if (!!canBeDeclined.length && !!canNotBeDeclined.length) {
      this.openChangeStatusDialog(canNotBeDeclined)
        .afterClosed()
        .pipe(
          filter((v) => {
            this.changeStatusOnSelected = !!v;

            return !!v;
          }),
          mergeMap(() => this.declineCases(canBeDeclined)),
        )
        .subscribe({
          complete: () => {
            if (this.changeStatusOnSelected) {
              snack(t('Saved'));
            }
          },
        });
    }
  }

  complete(): void {
    const [canBeCompleted, canNotBeCompleted] = this.selectedCasesContractorCanAndCannotChangeStatusOn;

    // non can be completed (not contractor on any of the selected cases)
    if (!canBeCompleted.length) {
      snack(t('Only cases you are contractor on can be completed'));

      return;
    }

    this.changeStatusOnSelected = false;

    // all can be completed
    if (!canNotBeCompleted.length && !!canBeCompleted.length) {
      this.subscription.add(
        this.completeCases(canBeCompleted).subscribe({
          complete: () => {
            if (this.changeStatusOnSelected) {
              snack(t('Saved'));
            }
          },
        }),
      );
    } else if (!!canBeCompleted.length && !!canNotBeCompleted.length) {
      this.openChangeStatusDialog(canNotBeCompleted)
        .afterClosed()
        .pipe(
          filter((v) => {
            this.changeStatusOnSelected = !!v;

            return !!v;
          }),
          mergeMap(() => this.completeCases(canBeCompleted)),
        )
        .subscribe({
          complete: () => {
            if (this.changeStatusOnSelected) {
              snack(t('Saved'));
            }
          },
        });
    }
  }

  datePassed(date: Date): boolean {
    return moment().isAfter(moment(date));
  }

  dateAble(input: number | string): Date {
    return new Date(input);
  }

  isCaseHighlighted(c: Case): boolean {
    return !!this.highlightedCaseIds?.find((id) => id === c.id);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private acceptCases(canBeAccepted: Case[]): Observable<Case> {
    return from(canBeAccepted).pipe(
      concatMap((c) => this.caseService.accept(c).pipe(tap((updatedCase) => Object.assign(c, updatedCase)))),
    );
  }

  private declineCases(canBeDeclined: Case[]): Observable<Case> {
    return this.dialog
      .open(CaseStatusMessageDialogComponent)
      .afterClosed()
      .pipe(
        filter((d: { message: string; visibleForClient: boolean }) => {
          this.changeStatusOnSelected = !!d?.message;

          return !!d?.message;
        }),
        mergeMap((d) =>
          from(canBeDeclined).pipe(
            concatMap((c) =>
              this.caseService
                .deny(c, d.message, d.visibleForClient)
                .pipe(tap((updatedCase) => Object.assign(c, updatedCase))),
            ),
          ),
        ),
      );
  }

  private completeCases(canBeCompleted: Case[]): Observable<Case> {
    const requireMessage = canBeCompleted.some((c) => c.Object?.data?.contractorCompleteMessage);

    if (requireMessage) {
      return this.dialog
        .open(CaseStatusMessageDialogComponent, {
          data: {
            type: 'complete',
          },
        })
        .afterClosed()
        .pipe(
          filter((d: { message: string; visibleForClient: boolean }) => {
            this.changeStatusOnSelected = !!d?.message;

            return !!d?.message;
          }),
          mergeMap((d) =>
            from(canBeCompleted).pipe(
              concatMap((c) =>
                this.caseService
                  .complete(c, d.message, d.visibleForClient)
                  .pipe(tap((updatedCase) => Object.assign(c, updatedCase))),
              ),
            ),
          ),
        );
    } else {
      return from(canBeCompleted).pipe(
        concatMap((c) => this.caseService.complete(c).pipe(tap((updatedCase) => Object.assign(c, updatedCase)))),
      );
    }
  }

  private openChangeStatusDialog(canNotBeChanged: Case[]): MatDialogRef<ConfirmDialogComponent, boolean> {
    return this.dialog.open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
      data: {
        text: t(
          'You are not contractor on all the cases you have selected. You can only change the status on cases you are contractor on. Selected cases that cannot be changed are listed below',
        ),
        list: canNotBeChanged.map((c) => `${c.id} - ${c.name}`),
        description: t('Do you want to change the status on the other cases you have selected?'),
      },
    });
  }
}
