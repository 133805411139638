import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { mergeMap, skipWhile } from 'rxjs/operators';
import { ChecklistGroupService } from '../../features/checklist-group/checklist-group.service';
import { Checklist } from '../../features/checklist/checklist.model';
import { ChecklistService } from '../../features/checklist/checklist.service';
import { ChecklistGroup } from '../../models/checklist-group';
import { snack, snackErr } from '../../modules/snack.module';
import { t } from '../translate/translate.function';
import { ChecklistDialogComponent } from './checklist-dialog.component';
import { setChecklistDialogResultValue } from './utils';

@Directive({
  selector: '[apexChecklistDialog]',
})
export class ChecklistDialogDirective {
  private template: 'ChecklistTemplate' | 'ChecklistGroupTemplate' = 'ChecklistTemplate';
  @Input() templateId: number = null;
  @Input() title = t('New checklist from template');
  @Input() model: string | null = null;
  @Input() modelId: number = null;

  @Input() projectId: number = null; // Needed if model is apartment

  @Input() navigateToId = true;

  @Output() checklistChange = new EventEmitter<Checklist>();

  constructor(
    private dialog: MatDialog,
    private checklistService: ChecklistService,
    private router: Router,
  ) {}

  @HostListener('click')
  createChecklistFromTemplate(
    model = this.model,
    modelId = this.modelId,
    navigateToId = this.navigateToId,
    templateId = this.templateId,
    projectId = this.projectId,
    title = this.title,
    template = this.template,
  ): void {
    this.dialog
      .open(ChecklistDialogComponent, {
        data: {
          title,
          template,
          templateId,
          model,
          modelId,
          projectId,
        },
      })
      .afterClosed()
      .pipe(
        skipWhile((res) => !res?.templateId),
        mergeMap((res) => {
          const result = setChecklistDialogResultValue(res);

          return this.checklistService.createChecklistFromTemplate(
            result.templateId,
            result.model,
            result.modelId,
            result.extra,
          );
        }),
      )
      .subscribe({
        next: (res) => {
          snack(t('Checklist created'));

          if (navigateToId) {
            void this.router.navigate(['checklist', res.Entity?.id]);
          }

          return this.checklistChange.emit(res.Entity);
        },
        error: (err) => snackErr(t('Could not create checklist'), err),
      });
  }
}

@Directive({
  selector: '[apexChecklistGroupDialog]',
})
export class ChecklistGroupDialogDirective {
  private template: 'ChecklistTemplate' | 'ChecklistGroupTemplate' = 'ChecklistGroupTemplate';
  @Input() templateId: number = null;
  @Input() title = t('New checklist group from template');
  @Input() model: 'object' | 'project' | 'apartment' | null = null;
  @Input() modelId: number = null;

  @Input() projectId: number = null; // Needed if model is apartment

  @Input() navigateToSingleId = true;

  @Output() groupsChange = new EventEmitter<ChecklistGroup>();

  constructor(
    private dialog: MatDialog,
    private checklistGroupService: ChecklistGroupService,
    private router: Router,
  ) {}

  @HostListener('click')
  createChecklistGroupFromTemplate(
    model = this.model,
    modelId = this.modelId,
    navigateToSingleId = this.navigateToSingleId,
    templateId = this.templateId,
    projectId = this.projectId,
    title = this.title,
    template = this.template,
  ): void {
    this.dialog
      .open(ChecklistDialogComponent, {
        data: {
          title,
          template,
          templateId,
          model,
          modelId,
          projectId,
        },
      })
      .afterClosed()
      .pipe(
        skipWhile((res) => !res?.templateId),
        mergeMap((res) => {
          const result = setChecklistDialogResultValue(res);

          if (result.model && result.modelId) {
            return this.checklistGroupService.createGroupFromTemplateOnModel(
              result.templateId,
              result.model,
              result.modelId,
              result.extra,
            );
          }

          return this.checklistGroupService.createGroupFromTemplate(result.templateId, result.extra);
        }),
      )
      .subscribe({
        next: (res) => {
          snack(t('Checklist group created'));

          if (navigateToSingleId) {
            void this.router.navigate(['checklist-group', res.Entity?.id]);
          }

          void this.groupsChange.emit(res.Entity);
        },
        error: (err) => snackErr(t('Could not create checklist group'), err),
      });
  }
}
