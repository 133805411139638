<h1 mat-dialog-title>
  <span *ngIf="data?.title">{{ data.title }}</span>
  <T
    str="Confirm"
    *ngIf="!data.title"></T>
</h1>
<mat-dialog-content>
  <p>{{ data.text }}</p>

  <ng-container *ngIf="data?.list?.length">
    <mat-list
      role="list"
      dense>
      <mat-list-item
        lines="1"
        role="listitem"
        *ngFor="let item of data.list">
        {{ item }}
      </mat-list-item>
    </mat-list>
  </ng-container>

  <p *ngIf="data.description">{{ data.description }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    (click)="cancel()">
    <T str="No"></T>
    {{ data?.timer && data.timer.val === false ? '(' + getSecondsRemaining() + ')' : '' }}
  </button>
  <button
    mat-raised-button
    (click)="confirm()"
    [color]="data.del ? 'warn' : 'primary'"
    cdkFocusInitial>
    <T
      str="Delete"
      *ngIf="data?.del"></T>
    <T
      str="Yes"
      *ngIf="!data?.del"></T>
    {{ data?.timer && data.timer.val === true ? '(' + getSecondsRemaining() + ')' : '' }}
  </button>
</mat-dialog-actions>
