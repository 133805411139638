<div
  class="day-view df c f1"
  [class.loading-screen]="loading">
  <div
    class="range-cases-wrapper mat-elevation-z2 df"
    *ngIf="showRange">
    <div class="left df c ac jsb">
      <h3
        class="date df tac rm aptxs"
        [matTooltip]="'Week {week}' | translate: { week: currentMoment.isoWeek() }">
        {{ currentMoment.isoWeek() }}
      </h3>
      <div class="date df tac mat-headline-6 rm">{{ dateDay }} {{ currentMoment?.date() }}</div>
      <button
        type="button"
        mat-icon-button
        *ngIf="rangeCases?.length > 2"
        (click)="showAll = !showAll">
        <mat-icon>{{ showAll ? 'expand_less' : 'expand_more' }}</mat-icon>
      </button>
    </div>

    <div class="range-cases-container f1">
      <ng-container *ngFor="let case of rangeCases; let i = index">
        <div
          class="case-wrapper"
          [ngClass]="{
            'from-before': !case.fromToday,
            'to-after': !case.toToday,
            rc: !case.id,
          }"
          *ngIf="showAll || i < 2">
          <div
            class="case mat-caption"
            [ngClass]="{
              rc: !case.id,
              'arrow-left': !case.fromToday,
              'arrow-rigth': !case.toToday,
            }"
            [matTooltip]="case.name"
            matRipple
            (click)="!case.id ? rcMenuTrigger.openMenu() : openCase(case, rcMenuTrigger)"
            #rcMenuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="rcMenu"
            [matMenuTriggerData]="{ case: case }">
            <div class="truncate">
              <span *ngIf="case.fromToday">
                {{ case?.from | dater | date: 'HH:mm' }}
              </span>
              {{ case.name }}
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!showAll && rangeCases?.length > 2">
        <div
          class="more mat-caption new"
          matRipple
          (click)="showAll = !showAll">
          <T
            str="{count} more"
            [vars]="{ count: rangeCases.length - 2 }">
          </T>
        </div>
      </ng-container>
      <ng-container *ngIf="showAll && rangeCases?.length > 2">
        <div
          class="more mat-caption new"
          matRipple
          (click)="showAll = !showAll">
          <T str="show less"></T>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="day-cases-container h-100 df c f1">
    <ng-container *ngFor="let time of timeValues; first as first">
      <div
        class="df timeslot f1 new"
        (click)="makeCase(time)">
        <div
          class="time-container"
          *ngIf="showTime">
          <span
            class="time mat-caption"
            [class.op-0]="first">
            {{ time }}
          </span>
        </div>
        <div class="slot-wrapper f1">
          <div
            class="slot"
            matRipple></div>
        </div>
      </div>
    </ng-container>

    <div
      class="cases-wrapper df c f1 h-100"
      [class.full-width]="!showTime">
      <ng-container *ngFor="let case of viewCases">
        <div
          class="mat-elevation-z2 case f1 mat-caption new"
          [ngStyle]="{
            'height.%': case.height,
            'top.%': case.top,
            'width.%': case.width,
            'left.%': case.left,
          }"
          [ngClass]="{
            rc: !case.id,
          }"
          [matTooltip]="case.name"
          matRipple
          (click)="!case.id ? rcMenuTrigger.openMenu() : openCase(case, rcMenuTrigger)"
          #rcMenuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="rcMenu"
          [matMenuTriggerData]="{ case: case }">
          <div class="truncate">
            {{ case.name }}, {{ case.from | dater | date: 'HH:mm' }} - {{ case.to | dater | date: 'HH:mm' }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<mat-menu #rcMenu="matMenu">
  <ng-template
    matMenuContent
    let-caseData="case"
    let-slot="slot">
    <button
      mat-menu-item
      (click)="createCaseFromRepeatableCase.emit(caseData)">
      <T str="Create case from repeatable case"></T>
    </button>
    <button
      mat-menu-item
      (click)="editRepeatableCase.emit(caseData)">
      <T str="Edit repeatable case"></T>
    </button>
    <button
      mat-menu-item
      (click)="deleteRepeatableCase.emit(caseData)">
      <T str="Delete repeatable case series"></T>
    </button>
  </ng-template>
</mat-menu>
