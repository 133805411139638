import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Case } from 'projects/apex/src/app/models/case';
import { CaseNewMessageComponent } from './new-message.component';

@Component({
  selector: 'apex-case-new-message-dialog',
  templateUrl: './new-message-dialog.component.html',
})
export class CaseNewMessageDialogComponent {
  @ViewChild(CaseNewMessageComponent) newMessage: CaseNewMessageComponent;

  savedCaseCount = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { cases: Case[] },
    public dialogRef: MatDialogRef<CaseNewMessageDialogComponent>,
  ) {}

  save(): void {
    this.dialogRef.disableClose = true;
    this.newMessage.save();
  }
}
