import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { constants } from 'projects/apex/src/app/utils/constants';
import { nameCompare } from 'projects/apex/src/app/utils/functions';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EntryGroup } from './entry-group.model';
import { EntryGroupService } from './entry-group.service';

@Component({
  selector: 'apex-entry-groups',
  templateUrl: './list.component.html',
})
export class EntryGroupsComponent implements OnInit, OnDestroy {
  @Input() entryGroups: EntryGroup[] = [];
  @Input() tenancyId: number;

  @ViewChild('searchInput') searchInput: MatInput;

  viewEntryGroups: EntryGroup[] = [];

  search = '';
  entryCardGroupSearch: number[] = [];
  showSearch = false;

  selectedEntryGroups = {};

  loading: boolean;

  private subscription = new Subscription();
  private searchSubject: Subject<string> = new Subject<string>();

  constructor(
    private service: EntryGroupService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.service.route = `tenancy/${this.tenancyId}/entry-group`;

    if (!this.entryGroups.length) {
      this.loading = true;

      const sub = this.service.query().subscribe({
        next: (entryGroups) => {
          this.entryGroups = entryGroups;
          this.viewEntryGroups = [...this.entryGroups].sort(nameCompare);

          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });

      this.subscription.add(sub);
    }

    const sub1 = this.route.queryParams.subscribe({
      next: (params) => {
        this.search = params.entryGroupSearch || '';

        const entryGroupId = params.entryCardGroupSearch;

        this.selectedEntryGroups = {};

        if (Array.isArray(entryGroupId)) {
          entryGroupId?.map((id: number) => {
            this.selectedEntryGroups[id] = true;
          });
        } else {
          this.selectedEntryGroups[Number(entryGroupId)] = true;
        }

        this.searchFunc();
      },
    });

    const sub2 = this.searchSubject
      .pipe(debounceTime(constants.inputDebounceTime))
      .subscribe((_) => this.updateQueryparams());

    [sub1, sub2].forEach((s) => this.subscription.add(s));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onChangeSearch(): void {
    this.searchSubject.next(null);
  }

  toggleSearch(): void {
    this.showSearch = !this.showSearch;

    if (this.showSearch) {
      this.searchInput?.focus();
    }
  }

  searchFunc(): void {
    this.viewEntryGroups = this.entryGroups
      .filter((eg) => {
        const searchText = this.search.toLowerCase().trim();
        const nameFilter = eg.name.toLowerCase().includes(searchText);

        return searchText ? nameFilter : true;
      })
      .sort(nameCompare);
  }

  filterByEntryGroup(event: Event, id: number): void {
    event?.stopPropagation();

    this.selectedEntryGroups[id] = !this.selectedEntryGroups[id];

    this.entryCardGroupSearch = this.entryGroups.filter((eg) => this.selectedEntryGroups[eg.id]).map((g) => g.id);

    this.searchSubject.next(null);
  }

  updateQueryparams(): void {
    const queryParams: Params = {
      entryGroupSearch: this.search,
      entryCardGroupSearch: this.entryCardGroupSearch,
    };

    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }
}
