import { Injectable } from '@angular/core';
import { Field } from 'projects/apex/src/app/models/field';
import { HttpService } from 'projects/apex/src/app/services/http/http.service';
import { Observable } from 'rxjs';

@Injectable()
export class FieldService extends HttpService<Field> {
  public route = 'field';

  getFieldsByCustomerId(): Observable<Field[]> {
    return this.http.get<Field[]>(`${this.apiUrl}/getFieldsByCustomerId`, this.options);
  }

  getProjectFields(ProjectId: number): Observable<Field[]> {
    const params = {
      ProjectId,
    };

    const options = {
      ...this.options,
      params,
    };

    return this.http.get<Field[]>(`${this.apiUrl}/${this.route}`, options);
  }
}
