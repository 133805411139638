import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { AvatarModule } from '../avatar/avatar.module';
import { TranslateModule } from '../translate/translate.module';

import { CdkDrag, CdkDragPlaceholder, CdkDropList } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AssumeUserComponent } from '../assume-user/assume-user.component';
import { SidebarItemsComponent } from '../sidebar-items/sidebar-items.component';
import { FilterSidebarItemsPipe } from './pipes/filter-sidebar-items.pipe';
import { SidebarItemAccessPipe } from './pipes/sidebar-item-access.pipe';
import { SidebarComponent } from './sidebar.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    MatIconModule,
    MatButtonModule,
    MatDividerModule,

    AvatarModule,
    TranslateModule,
    FilterSidebarItemsPipe,
    SidebarItemAccessPipe,
    MatTooltipModule,
    MatExpansionModule,
    MatSliderModule,
    MatSlideToggleModule,
    FormsModule,
    SidebarItemsComponent,
    CdkDrag,
    CdkDropList,
    CdkDragPlaceholder,
    AssumeUserComponent,
  ],
  exports: [SidebarComponent],
  declarations: [SidebarComponent],
  providers: [],
})
export class SidebarModule {}
