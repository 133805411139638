import { Pipe, PipeTransform } from '@angular/core';
import { ResponseCaseLogDto } from '../../../../../../../../generated/apex-rest';
import { CaseLog } from '../../../../models/case-log';
import { Customer } from '../../../../models/customer';

type BasicCompany = Pick<Customer, 'id' | 'name'>;

@Pipe({
  name: 'caseLogUserCompany',
  standalone: true,
})
export class CaseLogUserCompanyPipe implements PipeTransform {
  transform(value: CaseLog | ResponseCaseLogDto): BasicCompany | null {
    const user = 'User' in value ? value.User : 'Creator' in value ? value.Creator : null;

    if (user) {
      const company = 'Customer' in user ? user.Customer : 'Company' in user ? user.Company : null;

      if (company) {
        return {
          id: company.id,
          name: company.name,
        };
      }
    }

    return null;
  }
}
