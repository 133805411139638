import { Component, Inject, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Case } from '../../../../models/case';
import { CaseMarkingComponent } from '../marking.component';
import MarkingDialogData from './marking-dialog.types';

@Component({
  selector: 'apex-marking-dialog',
  templateUrl: 'marking-dialog.component.html',
  styleUrls: ['./marking-dialog.component.scss'],
})
export class MarkingDialogComponent implements OnInit, OnDestroy {
  cases$: BehaviorSubject<Case[]> = new BehaviorSubject<Case[]>([]);

  private subscriptions: Subscription = new Subscription();
  @ViewChild(CaseMarkingComponent)
  private caseMarkingComponent: CaseMarkingComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: MarkingDialogData) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.data.casesChange.subscribe((cases) => {
        this.cases$.next(cases);

        if (this.caseMarkingComponent) {
          const simpleChanges: SimpleChanges = {
            cases: {
              currentValue: cases,
              previousValue: this.caseMarkingComponent.cases,
              firstChange: false,
              isFirstChange: () => false,
            },
          };

          this.caseMarkingComponent.ngOnChanges(simpleChanges);
        }
      }),
    );

    this.cases$.next(this.data.cases);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
