<div class="mat-elevation-z2 df c">
  <div class="bg-color-main df ac jsb header">
    <div class="df ac">
      <mat-icon
        fontSet="material-icons-sharp"
        class="amh"
        >credit_card
      </mat-icon>
      <h2>
        <T str="Entry cards"></T>
      </h2>
    </div>
    <div class="entry-card-actions amrs df ac">
      <button
        mat-icon-button
        type="button"
        [matTooltip]="'Add entry card' | translate"
        (click)="newCard()"
        routerLinkActive="router-link-active">
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        type="button"
        [matTooltip]="'Search' | translate"
        [ngClass]="{ 'mat-mdc-raised-button': search || searchActive || selectedGroups?.length }"
        [color]="search || searchActive ? 'primary' : ''"
        (click)="toggleSearch()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <div
      class="entry-card-search apl aprs df ac f1 mat-elevation-z2"
      [ngClass]="{ 'op-0 no-touch': !showSearch, 'op-1': showSearch }">
      <div class="df ac w f1">
        <mat-form-field class="f1 amr">
          <input
            matInput
            type="text"
            name="searchInput"
            #searchInput="matInput"
            [placeholder]="'Search' | translate"
            [(ngModel)]="search"
            (ngModelChange)="onChangeSearch()" />
        </mat-form-field>
        <mat-form-field class="f1 amr">
          <mat-chip-grid #chipList>
            <mat-chip-row
              *ngFor="let group of selectedGroups"
              [removable]="true"
              (removed)="removeGroup(group)"
              [matTooltip]="group.description">
              {{ group.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-row>
            <input
              name="entryGroupFilterSearch"
              [(ngModel)]="entryGroupFilterSearch"
              [placeholder]="'Entry groups' | translate"
              #entryGroupInput
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              (ngModelChange)="onChange()" />
          </mat-chip-grid>
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="addGroup($event?.option?.value)">
            <mat-option
              *ngIf="!viewEntryGroups.length"
              disabled>
              <T str="No matches found"></T>
            </mat-option>
            <mat-option
              *ngFor="let group of viewEntryGroups"
              [value]="group">
              <span [innerHTML]="group.name | highlight: entryGroupFilterSearch"> </span>
              <span
                class="mat-caption"
                *ngIf="group.description">
                - {{ group.description }}
              </span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-checkbox
          class="p-apbs"
          [(ngModel)]="searchActive"
          (change)="onChangeSearch()">
          <T str="Show only active"></T>
        </mat-checkbox>
      </div>
      <button
        mat-icon-button
        type="button"
        (click)="toggleSearch()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <mat-progress-bar
      *ngIf="loading"
      class="loading"
      mode="indeterminate"></mat-progress-bar>
  </div>
  <section
    *ngIf="viewEntryCards?.length; else emptyState"
    class="scrollable-horizontal entry-cards-wrapper aps df">
    <ng-container *ngFor="let card of viewEntryCards">
      <div class="entry-card-wrapper df oh p-minw-90 aps">
        <div
          matRipple
          class="entry-card aps f1 p-minw-90 mat-elevation-z2 disable-focus new"
          [ngClass]="{ 'op-07': !card.active }"
          [routerLink]="!viewMode ? ['entry-card', card.id] : []"
          [state]="{ skipScroll: true }"
          routerLinkActive="router-link-active"
          (click)="$event.stopPropagation(); editEntryCard.emit(card)">
          <div
            class="df ac aps"
            *ngIf="card.name">
            <mat-icon
              fontSet="material-icons-sharp"
              [matTooltip]="'Name' | translate">
              person
            </mat-icon>
            <div class="amh f1 truncate">
              <span [innerHTML]="card.name | highlight: search"> </span>
            </div>
            <mat-slide-toggle
              *ngIf="!viewMode; else viewModeActive"
              [matTooltip]="
                '{active, select, true {Deactivate} other {Activate}}' | translate: { active: card?.active ?? false }
              "
              (click)="$event.stopPropagation()"
              [(ngModel)]="card.active"
              (ngModelChange)="changeCardState(card)">
            </mat-slide-toggle>
            <ng-template #viewModeActive>
              <div class="df ac">
                <button
                  type="button"
                  mat-icon-button
                  (click)="$event.stopPropagation(); deleteEntryCard.emit(card)"
                  [matTooltip]="'Delete' | translate"
                  color="warn">
                  <mat-icon>delete</mat-icon>
                </button>
                <mat-icon
                  *ngIf="card.active"
                  color="primary"
                  [matTooltip]="'Active' | translate">
                  check_circle
                </mat-icon>
              </div>
            </ng-template>
          </div>
          <div
            class="df ac aps"
            *ngIf="card.mail">
            <mat-icon
              fontSet="material-icons-sharp"
              [matTooltip]="'Email' | translate"
              >email
            </mat-icon>
            <div class="aml truncate">{{ card.mail }}</div>
          </div>
          <div
            class="df ac aps"
            *ngIf="card.mobile">
            <mat-icon
              fontSet="material-icons-sharp"
              [matTooltip]="'Phone' | translate"
              >phone
            </mat-icon>
            <div class="aml truncate">{{ card.mobile }}</div>
          </div>
          <div
            class="df ac aps"
            *ngIf="card.description">
            <mat-icon
              fontSet="material-icons-sharp"
              [matTooltip]="'Description' | translate"
              >description
            </mat-icon>
            <div
              class="aml truncate"
              [matTooltip]="card.description">
              {{ card.description }}
            </div>
          </div>
          <div
            class="df ac aps"
            *ngIf="card.EntryGroups?.length">
            <mat-icon
              fontSet="material-icons-sharp"
              [matTooltip]="'Entry groups' | translate">
              groups
            </mat-icon>
            <mat-chip-listbox class="aml">
              <mat-chip-option *ngFor="let group of card.EntryGroups">
                {{ group.name }}
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
        </div>
      </div>
    </ng-container>
  </section>
</div>

<ng-template #emptyState>
  <div class="df ac jc am ap">
    <T str="No cards to show"></T>
  </div>
</ng-template>
