import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import StatusService from './status.service';

@Injectable()
export class RequestStatusInterceptor implements HttpInterceptor {
  constructor(private statusService: StatusService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.statusService.requestStarted(req.method === 'POST');

    return next.handle(req).pipe(
      finalize(() => {
        this.statusService.requestFinished(req.method === 'POST');
      }),
    );
  }
}
