import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AvatarModule } from '../../../components/avatar/avatar.module';
import { BoxModule } from '../../../components/box/box.module';
import { FileUsagePreviewModule } from '../../../components/file-usage-preview/file-usage-preview.module';
import { ItemModule } from '../../../components/item/item.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { InViewDirectiveModule } from '../../../directives/in-view/in-view.directive.module';
import { ApexCurrencyPipeModule } from '../../../pipes/currency/currency.pipe.module';
import { ListArrayPipeModule } from '../../../pipes/list-array/list-array.pipe.module';
import { ClientPageService } from '../../client-page/client-page.service';
import { CaseAddonOrderComponent } from './case-addon-order.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    MatExpansionModule,
    MatTooltipModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatBadgeModule,
    MatDialogModule,

    TranslateModule,
    FileUsagePreviewModule,
    InViewDirectiveModule,
    AvatarModule,
    ApexCurrencyPipeModule,
    ListArrayPipeModule,
    ItemModule,
    BoxModule,
  ],
  exports: [CaseAddonOrderComponent],
  declarations: [CaseAddonOrderComponent],
  providers: [ClientPageService],
})
export class CaseAddonOrderModule {}
