import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUsageComponent } from 'projects/apex/src/app/components/file-usage/file-usage.component';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { snack, snackErr } from 'projects/apex/src/app/modules/snack.module';
import { Subscription, forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MetaType } from '../../object.model';
import { Agreement } from './agreement.model';
import { AgreementService } from './agreement.service';

@Component({
  selector: 'apex-agreement',
  templateUrl: './form.component.html',
})
export class AgreementComponent implements OnInit, OnDestroy {
  @ViewChild('fileUsage') fu: FileUsageComponent;
  @ViewChild('fileUsagePublic') fuPublic: FileUsageComponent;

  agreement: Agreement;

  MetaType = MetaType;

  navigateToId: number;
  projectId: number;

  private subscription = new Subscription();

  constructor(
    private service: AgreementService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.projectId = Number(this.route.snapshot.paramMap.get('pid'));
  }

  ngOnInit(): void {
    const sub = this.route.data.subscribe({
      next: (d) => {
        this.agreement = d.agreement;
      },
    });

    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  save(): void {
    const sub = this.service
      .saveByObject(this.agreement)
      .pipe(
        mergeMap((agreement) => {
          this.navigateToId = agreement.Entity?.id;
          this.fu.selfId = agreement.Entity?.id;
          this.fuPublic.selfId = agreement.Entity?.id;

          return forkJoin([this.fu.saveAll(), this.fuPublic.saveAll()]);
        }),
      )
      .subscribe({
        complete: () => {
          snack(t('Saved'));
          void this.router.navigate(this.whereTo());
        },
        error: (err) => snackErr(t('Could not save'), err),
      });

    this.subscription.add(sub);
  }

  delete(): void {
    if (this.agreement?.id) {
      const sub = this.service.deleteByObject(this.agreement).subscribe({
        next: (_) => {
          snack(t('Deleted'));
          void this.router.navigate(this.whereTo());
        },
        error: (err) => snackErr(t('Could not delete'), err),
      });

      this.subscription.add(sub);
    }
  }

  private whereTo(): string[] {
    let url = this.router.url;

    if (this.agreement?.id) {
      url = `object/project/${this.projectId}/agreement`;
    } else {
      url = url.replace('new', String(this.navigateToId));
    }

    return [url];
  }
}
