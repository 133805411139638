import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { environment } from 'projects/apex/src/environments/environment';
import { Subscription } from 'rxjs';
import { Md5 } from 'ts-md5';
import { Contractor } from '../../models/case';
import { getStatusColor, getStatusMessage, isWeak } from '../../utils/functions';
import { Color } from '../charts/charts.colors';

const avatarSizes = {
  small: 24,
  smallWithBorder: 20,
  default: 40,
  defaultWithBorder: 36,
  medium: 32,
  mediumWithBorder: 28,
  large: 128,
  largeWithBorder: 124,
};

type AvatarSize = keyof typeof avatarSizes;

@Component({
  selector: 'apex-avatar',
  templateUrl: './avatar.component.html',
})
export class AvatarComponent implements OnChanges, OnDestroy {
  @Input() user: { id?: number; name: string };
  @Input() size: AvatarSize = 'default';
  @Input() borderFillColor: Color | 'transparent';
  @Input() borderSpaceColor: Color | 'transparent';
  @Input() tooltip: string;

  pxSize: number = avatarSizes.default;

  @Input() newSize = 32;

  imageLoaded = false;

  private subscription = new Subscription();

  ngOnChanges(simpleChange: SimpleChanges): void {
    if (simpleChange.user) {
      const contractorStatus = (this.user as Contractor)?.CaseContractor?.status;

      this.borderFillColor = this.borderFillColor ?? getStatusColor(contractorStatus);
      this.tooltip = this.tooltip ?? getStatusMessage(contractorStatus);
    }

    this.pxSize = avatarSizes[this.size]; // Set pixel size

    if (this.borderFillColor && this.borderFillColor !== 'transparent') {
      if (this.size === 'default') {
        this.pxSize = avatarSizes.defaultWithBorder;
      }

      if (this.size === 'small') {
        this.pxSize = avatarSizes.smallWithBorder;
      }

      if (this.size === 'large') {
        this.pxSize = avatarSizes.largeWithBorder;
      }

      this.borderSpaceColor = this.borderSpaceColor || Color.AK50;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get bgColor(): string {
    const color = this.bgHex;

    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    const a = '.7';

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }

  get color(): string {
    return isWeak(this.bgHex, 186) ? '#000' : '#fff';
  }

  get initials(): string {
    const fullName = this.user?.name?.split(' ') ?? [];

    return (fullName?.shift()?.charAt(0)?.toUpperCase() ?? '') + (fullName?.pop()?.charAt(0)?.toUpperCase() ?? '');
  }

  get avatarUrl(): string {
    if (!environment.test && this.user?.id) {
      return `https://userfiles.apexapp.io/${this.user.id}.png`;
    }

    return '';
  }

  get wrapperSize(): number {
    let r = this.pxSize;

    switch (this.size) {
      case 'small':
        r = avatarSizes.small;
        break;
      case 'medium':
        r = avatarSizes.medium;
        break;
      case 'large':
        r = avatarSizes.large;
        break;
      case 'default':
        r = avatarSizes.default;
        break;
    }

    return r + 2;
  }

  private get bgHex(): string {
    if (this.user?.name) {
      const md5 = Md5.hashStr(this.user?.name);

      return md5.substring(0, 6).toUpperCase();
    }

    return Color.AT700;
  }

  onLoad(): void {
    this.imageLoaded = true;
  }

  onError(): void {
    this.imageLoaded = false;
  }
}
