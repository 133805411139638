/// <reference types="google.visualization" />

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { colorSeries } from 'projects/apex/src/app/components/charts/charts.colors';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { environment } from 'projects/apex/src/environments/environment';
import { Observable, Subscription, forkJoin, fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';
import { Color } from '../charts.colors';
import { Font, FontColor, FontSize } from '../charts.fonts';
import { AxisTicks, Column, Row } from './chart.types';

const dateFormat = {
  day: 'D.M.YY',
  week: 'W',
  month: 'MMMM',
  quarter: 'Q YY',
};

type Granularities = 'day' | 'week' | 'month' | 'quarter' | 'year';

type ChartOptions = google.visualization.ColumnChartOptions &
  google.visualization.PieChartOptions &
  google.visualization.LineChartOptions;

@Component({
  selector: 'apex-chart',
  templateUrl: './chart.component.html',
  styleUrls: [],
})
export class ChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('chart') chart: ElementRef<HTMLDivElement>;
  @ViewChild('page') page: ElementRef<HTMLDivElement>;
  @ViewChild('titleBox') titleBox: ElementRef<HTMLHeadingElement>;

  @Input() rows: Row[] = [
    [moment().toDate(), 2],
    [moment().toDate(), 3],
  ];

  @Input() ticks?: AxisTicks[];

  @Input() columns: Column[] = [
    {
      role: 'annotation',
      type: 'date',
      label: 'Date',
    },
    {
      role: 'data',
      type: 'number',
      label: 'Column 1',
    },
  ];

  @Input() granularity: Granularities = 'month';
  @Input() title = t('Cases');
  @Input() chartType = 'line';
  @Input() subtitles$?: Observable<string[]>;

  logoUrl = `${environment.assetsUrl}/favicon.svg`;

  gchart: google.visualization.LineChart | google.visualization.PieChart | google.visualization.ColumnChart;

  subs: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    google.charts.setOnLoadCallback(() => {
      this.fixChartSize();
      this.createChart();
    });
  }

  ngOnInit(): void {
    this.subs.add(fromEvent(window, 'resize').subscribe((_) => this.fixChartSize()));
  }

  ngAfterViewInit(): void {
    this.subs.add(fromEvent(this.titleBox.nativeElement, 'blur').subscribe(() => this.updateTitle()));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const rows = changes?.rows;
    const columns = changes?.columns;
    const chartType = changes?.chartType;
    const title = changes?.title;
    const ticks = changes?.ticks;

    const colChange = columns && !columns.firstChange;
    const rowChange = rows && !rows.firstChange;
    const chartChange = chartType && !chartType.firstChange;
    const titleChange = title && !title.firstChange;
    const ticksChange = ticks && !ticks.firstChange;

    if (titleChange) {
      this.title = title.currentValue;
    }

    if (colChange || rowChange || chartChange) {
      this.clearChart();
      this.createChart();
    }

    const granularity = changes?.granularity;

    if ((granularity && !granularity.firstChange) || ticksChange) {
      this.updateChart();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.clearChart();
  }

  savePng(): void {
    const link = document.createElement('a');
    const fileName = `${this.title.trim()}.png`;
    const canvas = document.createElement('canvas');
    const chart = this.getOffscreenChart();
    const ctx = canvas.getContext('2d');

    // TODO: Give an option to choose different sizes of exported png?
    canvas.setAttribute('width', '1920');
    canvas.setAttribute('height', '1080');

    const img = new Image();
    const logo = new Image();

    img.crossOrigin = 'Anonymous';
    logo.crossOrigin = 'Anonymous';

    img.src = chart.getImageURI();
    logo.src = this.logoUrl;

    const img$ = fromEvent(img, 'load').pipe(take(1));
    const logo$ = fromEvent(logo, 'load').pipe(take(1));
    const meta$ = this.subtitles$.pipe(take(1));

    const obs = forkJoin({
      chart: img$,
      logo: logo$,
      meta: meta$,
    }).pipe(take(1));

    this.subs.add(
      obs.subscribe({
        next: ({ meta }) => {
          const width = canvas.width;
          const height = canvas.height;

          ctx.fillStyle = Color.AK50;

          ctx.fillRect(0, 0, width, height);

          ctx.drawImage(img, 0, -95);

          ctx.font = `${FontSize.Headline} ${Font.Headline}`;
          ctx.fillStyle = FontColor.Headline;

          ctx.fillText(this.title, width / 2 - ctx.measureText(this.title).width / 2, 50);

          ctx.font = `${FontSize.Body} ${Font.Body}`;
          ctx.fillStyle = FontColor.Body;

          meta.forEach((subtitle, i) =>
            ctx.fillText(subtitle, width / 2 - ctx.measureText(subtitle).width / 2, height - (i + 1) * 22),
          );

          const logoX = width - 52 - 20;
          const logoY = height - 52 - 20;

          ctx.drawImage(logo, logoX, logoY, 50, 50);

          const dataUrl = canvas.toDataURL().replace(/^data:image\/[^;]*/, 'data:application/octet-stream');

          link.setAttribute('href', `data: ${dataUrl}`);
          link.setAttribute('target', '_blank');
          link.setAttribute('download', fileName);
          link.click();

          chart.clearChart();
          document.getElementById('offscreen-chart').remove();
        },
      }),
    );
  }

  fixChartSize(): void {
    const ratio = 6 / 8;
    const chartWidth = this.chart.nativeElement.getBoundingClientRect().width / 2;
    const chartHeight = ratio * chartWidth;

    this.chart.nativeElement.style.width = `${chartWidth}px`;
    this.chart.nativeElement.style.height = `${chartHeight}px`;

    if (this.gchart) {
      this.updateChart();
    }
  }

  clearChart(): void {
    this.gchart?.clearChart();
    this.gchart = null;
  }

  createChart(): void {
    if (!this.chart) {
      console.error('div #chart not found...');

      return;
    }

    switch (this.chartType) {
      case 'column':
        this.gchart = new google.visualization.ColumnChart(this.chart.nativeElement);
        break;
      case 'pie':
        this.gchart = new google.visualization.PieChart(this.chart.nativeElement);
        break;
      default:
        this.gchart = new google.visualization.LineChart(this.chart.nativeElement);
        break;
    }

    this.updateChart();
  }

  updateTitle($event?: KeyboardEvent): void {
    if ($event?.key === 'Enter' || !$event) {
      const titleBox = this.titleBox.nativeElement;
      const title = ($event?.target as HTMLHeadingElement)?.textContent?.trim() ?? '';

      titleBox.blur();

      this.title = title ? title : this.title;

      void this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          chartTitle: this.title.trim(),
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  getOptions(): ChartOptions {
    const format = dateFormat[this.granularity];

    const nRows = this.rows[0]?.length || 2;
    const colors = colorSeries.slice(0, Number(nRows) - 1);
    const options = {
      backgroundColor: Color.AK50,
      vAxis: {
        baselineColor: 'grey',
        minValue: 0,
        format: 'short',
        viewWindow: {
          min: 0,
          max: this.getMaxValue(),
        },
      },
      hAxis: {
        title: this.translatedGranularity(this.granularity),
        baselineColor: 'grey',
        format,
      },
      colors,
    };

    switch (this.chartType) {
      case 'line':
        Object.assign(options, {
          selectionMode: 'single',
          legend: {
            position: 'right',
          },
          explorer: {
            actions: ['dragToZoom', 'rightClickToReset'],
            axis: 'horizontal',
            keepInBounds: true,
            maxZoomIn: 100.0,
          },
          hAxis: {
            format: dateFormat[this.granularity],
            ticks: this.ticks?.length > 0 ? this.ticks : this.parseXTicks(),
            viewWindow: {
              min: this.getMinDate(),
              max: this.getMaxDate(),
            },
          },
        });

        break;
      case 'column':
        Object.assign(options, {
          vAxis: {
            baseLineColor: 'grey',
            minValue: 0,
            format: 'long',
            viewWindow: {
              min: 0,
              max: this.getMaxValue(),
            },
          },
          hAxis: {
            baselineColor: 'grey',
            format,
            viewWindow: {
              min: this.getMinDate(),
              max: this.getMaxDate(),
            },
          },
        });

        break;
    }

    return options;
  }

  updateChart(): void {
    if (!this.gchart) {
      console.error('Google charts not loaded...');

      return;
    }

    const data = new google.visualization.DataTable();
    const formatter = new google.visualization.DateFormat({ pattern: dateFormat[this.granularity] });

    this.parseColumns().forEach((c) => data.addColumn(c));
    data.addRows(this.parseRows());
    formatter.format(data, 0);
    this.gchart.draw(data, this.getOptions());
  }

  private getOffscreenChart(): google.visualization.LineChart | google.visualization.ColumnChart {
    const div = document.createElement('div');

    this.page.nativeElement.appendChild(div);
    div.setAttribute('id', 'offscreen-chart');

    div.style.width = '1920px';
    div.style.height = '1080px';
    div.style.position = 'absolute';
    div.style.top = '-1920px';

    const chart =
      this.chartType === 'line' ? new google.visualization.LineChart(div) : new google.visualization.ColumnChart(div);

    const data = new google.visualization.DataTable();
    const formatter = new google.visualization.DateFormat({ pattern: dateFormat[this.granularity] });

    this.parseColumns().forEach((c) => data.addColumn(c));
    data.addRows(this.parseRows());
    formatter.format(data, 0);
    chart.draw(data, this.getOptions());

    return chart;
  }

  private parseXTicks(): AxisTicks[] {
    let rTicks: number;

    if (this.ticks) {
      if (this.chartType !== 'line') {
        return [];
      }

      const width = this.chart.nativeElement.getBoundingClientRect().width;
      const threshold = Math.sqrt(width);

      rTicks = Math.floor(this.ticks.length / threshold);

      this.ticks = this.ticks.filter((_, i) => i % rTicks === 0);
    }

    const nRows = this.rows.length;
    const nTicksFraction = Math.log(window.innerWidth);
    const nTicks = Math.floor(this.rows.length / nTicksFraction);

    rTicks = nRows > 10 ? (nTicks > 2 ? nTicks : 2) : 1;

    return this.rows
      .filter((r, i) => i % rTicks === 0 && moment(r[0]).isSameOrAfter(moment(this.rows[0][0])))
      .map((r) => ({
        v: r[0],
        f: moment(r[0])?.format(dateFormat[this.granularity]),
      }));
  }

  private getMaxValue(): number {
    const values = this.rows
      .map((r) => r.filter((e) => typeof e === 'number') as number[])
      .reduce((p, c) => (p.push(...c), p), []);
    const max = Math.max(...values);
    const rounded = Math.ceil(max / 10) * 10;
    const axisHeight = max % 10 === 0 ? rounded + 10 : rounded;

    return axisHeight;
  }

  private getMaxDate(): Date {
    const moments = this.rows
      .map((r) => r.filter((e) => typeof (e as Date).getMonth === 'function'))
      .reduce((p, c) => (p.push(...c), c), [])
      .map((d: Date) => moment(d));

    const max = moment.max(moments);

    return max.toDate();
  }

  private getMinDate(): Date {
    const moments = this.rows
      .map((r) => r.filter((e) => typeof (e as Date).getMonth === 'function'))
      .reduce((p, c) => (p.push(...c), p), [])
      .map((d: Date) => moment(d));

    const min = moment.min(moments);

    return min.toDate();
  }

  private parseColumns(): Column[] {
    const columns = this.columns;

    if (columns) {
      switch (this.chartType) {
        case 'column':
          columns[0].type = 'string';
          break;
        default:
          columns[0].type = 'date';
          break;
      }
    }

    return columns;
  }

  private parseRows(): Row[] {
    const rows = this.rows;

    switch (this.chartType) {
      case 'column':
        if (this.granularity === 'quarter') {
          return rows.map((r: Row) => [`${moment(r[0]).quarter()}\n${moment(r[0]).year()}`, ...r.slice(1)]);
        }

        return rows.map((r: Row) => [moment(r[0]).format(dateFormat[this.granularity]), ...r.slice(1)]);
      default:
        return rows.map((r: Row) => [moment.isMoment(r[0]) ? r[0] : moment(r[0]).toDate(), ...r.slice(1)]);
    }
  }

  private translatedGranularity = (granularity: Granularities): string => {
    switch (granularity) {
      case 'day':
        return t('Day', { _context: 'granularity' });
      case 'week':
        return t('Week', { _context: 'granularity' });
      case 'month':
        return t('Month', { _context: 'granularity' });
      case 'quarter':
        return t('Quarter', { _context: 'granularity' });
      case 'year':
        return t('Year', { _context: 'granularity' });
      default:
        return t('Unknown', { _context: 'granularity' });
    }
  };
}
