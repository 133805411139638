import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogModule } from '../../../components/confirm-dialog/confirm-dialog.module';

import { ApartmentActionsService } from './apartment-actions.service';

import { ApartmentDeleteSaleButtonComponent } from './delete-sale-button.component';
import { ApartmentDeleteTakeoverButtonComponent } from './delete-takeover-button.component';

const mine = [ApartmentDeleteSaleButtonComponent, ApartmentDeleteTakeoverButtonComponent];

@NgModule({
  declarations: mine,
  exports: mine,
  imports: [MatButtonModule, MatDialogModule, ConfirmDialogModule],
  providers: [ApartmentActionsService],
})
export class ApartmentActionsModule {}
