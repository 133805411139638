import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserService } from '../../services/user/user.service';
import { TranslateModule } from '../translate/translate.module';
import { ChartControlsComponent } from './chart-controls/chart-controls.component';
import { ChartComponent } from './chart/chart.component';
import { MeterChartComponent } from './meter-chart.component';
import { SparklineComponent } from './sparkline.component';

const packages = ['corechart'];

const mine = [SparklineComponent, MeterChartComponent, ChartComponent, ChartControlsComponent];

const imports = [
  MatRadioModule,
  TranslateModule,
  FormsModule,
  CommonModule,
  MatFormFieldModule,
  MatSelectModule,
  MatIconModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  MatSliderModule,
  MatDividerModule,
  MatTooltipModule,
  MatInputModule,
];

@NgModule({
  declarations: mine,
  exports: mine,
  imports,
  providers: [],
})
export class ChartsModule {
  constructor(private us: UserService) {
    void google.charts.load('current', {
      packages,
      language: this.us.profile?.locale ?? 'en',
    });
  }
}
