import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { Case } from 'projects/apex/src/app/models/case';
import { snackAct } from 'projects/apex/src/app/modules/snack.module';
import { isPercentIncrease } from 'projects/apex/src/app/utils/math';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CaseService } from '../../../case/case.service';
import { CaseFormDialogComponent } from '../../../case/form/dialog.component';
import { Meter, MeterValue } from './meter.model';
import { MeterValueService } from './meter.service';

@Component({
  selector: 'apex-meter-value-form',
  templateUrl: './value-form.component.html',
})
export class MeterValueFormComponent {
  @Input() meter: Meter;
  @Input() caseId: number;

  @Output() valueSaved = new EventEmitter<Meter>();

  value: number;

  constructor(
    private valueService: MeterValueService,
    private dialog: MatDialog,
    private caseService: CaseService,
  ) {}

  onSubmitValue(): void {
    if (!this.meter.id) {
      return;
    }

    const nv = new MeterValue();

    nv.MeterId = this.meter.id;
    nv.value = this.value;

    let value = new Observable<MeterValue>();

    if (this.caseId) {
      value = this.caseService.postMeterValues(this.caseId, nv).pipe(
        take(1),
        map((res) => res.Entity),
      );
    } else {
      value = this.valueService.saveByObject(this.meter, nv).pipe(
        take(1),
        map((res) => res.Entity),
      );
    }

    value.subscribe({
      next: (mv) => {
        const isError = this.isError(mv.value);
        const isWarning = this.isWarning(mv.value);

        if ((isWarning || isError) && !this.caseId) {
          snackAct(
            isError
              ? t('Error on meter {name}', { name: this.meter.name })
              : t('Warning on meter {name}', { name: this.meter.name }),
            {
              msg: t('Create case', {
                _context: 'action',
              }),
              fn: () => {
                this.newCase(isError);
              },
            },
          );
        }

        this.meter.Values = [mv, ...this.meter.Values];
        this.valueSaved.emit(this.meter);
      },
    });
  }

  isWarning(value: number): boolean {
    if (!this.meter?.warning) {
      return false;
    }

    return isPercentIncrease(value, this.meter?.Values[0]?.value, this.meter?.warning);
  }

  isError(value: number): boolean {
    if (!this.meter?.error) {
      return false;
    }

    return isPercentIncrease(value, this.meter?.Values[0]?.value, this.meter?.error);
  }

  newCase(isError: boolean): void {
    const caseName = isError
      ? `${t('Error')} - ${this.meter.name}, ${t(this.meter.type[0]?.toUpperCase() + this.meter.type.slice(1))}`
      : `${t('Warning')} - ${this.meter.name}, ${t(this.meter.type[0]?.toUpperCase() + this.meter.type.slice(1))}`;

    const c = new Case({
      name: caseName,
      ObjectId: this.meter.ObjectId,
      MeterId: this.meter.id,
      CaseCategoryId: 19,
    });

    this.dialog
      .open(CaseFormDialogComponent, {
        data: {
          case: c,
        },
      })
      .afterClosed()
      .subscribe();
  }
}
