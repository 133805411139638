import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { DragComponent } from './drag.component';
import { DragDirective } from './drag.directive';
import { DragService } from './drag.service';
import { DropDirective } from './drop.directive';

const components = [DragComponent, DragDirective, DropDirective];

@NgModule({
  declarations: components,
  exports: components,
  imports: [CommonModule, MatIconModule, MatBadgeModule],
  providers: [DragService],
})
export class DragModule {}
