import { CdkDropList } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';

@Injectable()
export class DropListService {
  dropLists: CdkDropList[] = [];

  addDropList(dropList: CdkDropList): void {
    if (!this.dropLists.includes(dropList)) {
      this.dropLists.push(dropList);
    }
  }

  removeDropList(dropList: CdkDropList): void {
    const index = this.dropLists.indexOf(dropList);

    if (index > -1) {
      this.dropLists.splice(index, 1);
    }
  }

  getConnectedDropLists(): string[] {
    return this.dropLists.map((dl) => dl.id);
  }
}
