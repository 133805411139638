import { Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  template: '',
})
export abstract class BaseItemComponent {
  /** Icons to be used */
  @Input() icon = '';
  /** font-set to pick the icon from */
  @Input() fontSet = '';
  /** Color to be used for the icon */
  @Input() iconColor: ThemePalette = null;
  /** Show title */
  @Input() showTitle = true;
  /** Tooltip to be used for the icon */
  @Input() iconTooltip: string;
}
