import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { FileUsageService } from 'projects/apex/src/app/components/file-usage/file-usage.service';
import { InViewDirectiveModule } from '../../directives/in-view/in-view.directive.module';
import { FilePreviewModule } from '../file-preview/file-preview.module';

import { FileUsagePreviewComponent } from './file-usage-preview.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatBadgeModule,
    FilePreviewModule,
    InViewDirectiveModule,
  ],
  exports: [FileUsagePreviewComponent],
  declarations: [FileUsagePreviewComponent],
  providers: [FileUsageService],
})
export class FileUsagePreviewModule {}
