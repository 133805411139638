import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { booleanFromBooleanOrString } from '../../utils/functions';
import { IntersectionService } from './intersection.service';

@Directive({
  selector: '[apexInView]',
})
export class InViewDirective implements OnInit, OnDestroy {
  @Input() get inViewOnlyOnce(): boolean | string {
    return this.onlyOnce;
  }

  set inViewOnlyOnce(value: boolean | string) {
    this.onlyOnce = booleanFromBooleanOrString(value);
  }

  @Output() apexInView: EventEmitter<boolean> = new EventEmitter<boolean>();

  private onlyOnce = false;

  constructor(
    private elementRef: ElementRef<Element>,
    private service: IntersectionService,
  ) {}

  ngOnInit(): void {
    this.register();
  }

  ngOnDestroy(): void {
    this.unregister();
  }

  private register(): void {
    this.service.register(this.elementRef.nativeElement, (iv) => this.inView(iv));
  }

  private unregister(): void {
    this.service.unregister(this.elementRef.nativeElement);
  }

  private inView(inView: boolean): void {
    if (this.onlyOnce && inView === true) {
      this.unregister();
    }

    this.apexInView.emit(inView);
  }
}
