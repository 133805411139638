import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import { constants } from 'projects/apex/src/app/utils/constants';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'apex-checklist-group-filter',
  templateUrl: './filter.component.html',
})
export class ChecklistGroupFilterComponent implements OnInit, OnDestroy {
  filter$ = new Subject();
  filterSub = this.filter$.pipe(debounceTime(constants.inputDebounceTime)).subscribe({
    next: () => {
      this.setQueryParams();
    },
  });

  search: string;

  ProjectId: number;
  ApartmentId: number;

  ObjectId: number;

  FieldId: number;
  ObjectFieldId: number;

  open = true;
  completed = false;

  page = 0;

  private subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.queryParams.subscribe({
        next: (qp) => {
          this.search = this.search || qp.checklistGroupSearch || '';
          this.ObjectId = qp.checklistGroupObject ?? null;
          this.ProjectId = qp.checklistGroupProject ?? null;
          this.ApartmentId = qp.checklistGroupApartment ?? null;
          this.open = qp.checklistGroupShowOpen === 'true';
          this.completed = qp.checklistGroupShowCompleted === 'true';

          this.FieldId = Number(qp.checklistGroupField) || null;
          this.ObjectFieldId = Number(qp.checklistGroupObjectField) || null;

          if (!qp.checklistGroupShowOpen) {
            this.open = true;
          }
        },
      }),
    );
  }

  setQueryParams(replaceUrl = false): void {
    const queryParams: Params = {
      checklistGroupPage: this.page,
      checklistGroupSearch: this.search,
      checklistGroupObject: this.ObjectId,
      checklistGroupProject: this.ProjectId,
      checklistGroupApartment: this.ApartmentId,
      checklistGroupShowOpen: this.open,
      checklistGroupShowCompleted: this.completed,

      checklistGroupField: this.FieldId,
      checklistGroupObjectField: this.ObjectFieldId,
    };

    void this.router.navigate([], {
      queryParams,
      replaceUrl,
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }

  onChangeFilter(): void {
    this.page = 0;

    this.filter$.next(null);
  }

  get hasCommercial(): boolean {
    return this.userService.hasCustomerRight('Commercial');
  }

  get hasProject(): boolean {
    return this.userService.hasCustomerRight('Project');
  }

  ngOnDestroy(): void {
    this.subscription.add(this.filterSub);

    this.subscription.unsubscribe();
  }
}
