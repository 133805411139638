import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[apexAutoFocus]',
})
export class AutoFocusDirective implements AfterContentInit {
  @Input() public apexAutoFocus: boolean | string = true;

  constructor(private el: ElementRef) {}

  public ngAfterContentInit(): void {
    if (this.apexAutoFocus || this.apexAutoFocus === '') {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, 200);
    }
  }
}
