import { environment } from 'projects/apex/src/environments/environment';

import { tx } from '@transifex/native';

export const localePreLoader = async (locale: string): Promise<boolean> => {
  try {
    tx.init({
      token: environment.transifexToken,
    });
    await tx.setCurrentLocale(locale);

    return true;
  } catch (err) {
    console.error(err);

    return false;
  }
};
