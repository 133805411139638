import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { Checklist, ChecklistItem, ChecklistSection } from 'projects/apex/src/app/features/checklist/checklist.model';
import { ChecklistService } from 'projects/apex/src/app/features/checklist/checklist.service';
import { DeleteChecklistDialogComponent } from 'projects/apex/src/app/features/checklist/form/delete-dialog.component';
import { ChecklistItemComponent } from 'projects/apex/src/app/features/checklist/item/item.component';
import { ChecklistItemListComponent } from 'projects/apex/src/app/features/checklist/item/list.component';
import { Case } from 'projects/apex/src/app/models/case';
import { snack, snackErr } from 'projects/apex/src/app/modules/snack.module';
import { concat, Observable, of } from 'rxjs';
import { map, mergeMap, skipWhile, toArray } from 'rxjs/operators';

@Component({
  selector: 'apex-checklist-section',
  templateUrl: './checklist-section.component.html',
})
export class ChecklistSectionComponent implements OnChanges {
  @Input() checklist: Checklist;
  @Input() checklistItems: ChecklistItem[] = [];
  @Input() checklistSections: ChecklistSection[] = [];

  @Input() checklistSection: ChecklistSection;
  @Input() step: string;

  @Output() deleteComplete = new EventEmitter();

  @ViewChild(ChecklistItemListComponent) checklistItemListComponent: ChecklistItemListComponent;
  // ForwardRef here because it refers to itself.
  @ViewChildren(forwardRef(() => ChecklistSectionComponent))
  checklistSectionComponents: QueryList<ChecklistSectionComponent>;

  items: ChecklistItem[] = [];
  sections: ChecklistSection[] = [];

  expanded = true;

  constructor(
    private service: ChecklistService,
    private dialog: MatDialog,
  ) {}

  ngOnChanges(): void {
    this.filter();
  }

  filter(): void {
    this.items = this.checklistSection?.id
      ? this.checklistItems.filter((i) => i.SectionId === this.checklistSection?.id)
      : this.checklistItems.filter((i) => !i.SectionId);

    this.sections = this.checklistSection?.id
      ? this.checklistSections.filter((s) => s.ParentId === this.checklistSection?.id)
      : this.checklistSections.filter((s) => !s.ParentId);
  }

  deleteItems$(completeAndArchiveCheck: boolean): Observable<Case[]> {
    return completeAndArchiveCheck
      ? concat(...this.checklistItemComponents.map((cic) => cic.archiveCase())).pipe(toArray())
      : of([]);
  }

  deleteChecklist$(): Observable<boolean> {
    return this.service.delete(this.checklist?.id).pipe(map((r) => r.delete));
  }

  delete(): void {
    this.dialog
      .open(DeleteChecklistDialogComponent)
      .afterClosed()
      .pipe(
        skipWhile((res: { del: boolean; completeAndArchiveCheck: boolean }) => !res?.del),
        mergeMap((res: { del: boolean; completeAndArchiveCheck: boolean }) =>
          this.deleteItems$(res.completeAndArchiveCheck),
        ),
        mergeMap(() => this.deleteChecklist$()),
      )
      .subscribe({
        next: (deleted) => {
          if (deleted) {
            snack(t('Deleted'));
            this.deleteComplete.emit();
          }
        },
        error: (err) => {
          snackErr(t('Could not delete'), err);
        },
      });
  }

  get checklistItemComponents(): ChecklistItemComponent[] {
    const items = this.checklistItemListComponent?.checklistItemComponents?.toArray() ?? [];
    const sectionItems = this.checklistSectionComponents?.toArray().map((s) => s.checklistItemComponents);

    return sectionItems ? items.concat(...sectionItems) : items;
  }
}
