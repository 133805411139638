<div
  class="wrapper"
  class="df jc ac"
  [style.width.px]="wrapperSize"
  [style.height.px]="wrapperSize">
  <div
    class="avatar"
    [style.--size.px]="pxSize"
    [style.--border-fill-color]="borderFillColor"
    [style.--border-space-color]="borderSpaceColor"
    [style.--background-color]="bgColor"
    [style.--color]="color"
    [matTooltip]="tooltip | apexAvatarPipe: user">
    <div
      class="initials df ac jc"
      [ngClass]="{
        'mat-title': size === 'default' || size === 'defaultWithBorder',
        'mat-caption': size === 'small' || size === 'smallWithBorder',
        'mat-h3': size === 'medium' || size === 'mediumWithBorder',
        'mat-h1': size === 'large' || size === 'largeWithBorder',
      }">
      <span>
        {{ initials }}
      </span>
    </div>

    <img
      class="avatar-img"
      style="overflow: hidden"
      [ngClass]="{ 'op-0': !imageLoaded, 'op-1': imageLoaded }"
      [style.width.px]="pxSize"
      [style.heigth.px]="pxSize"
      [alt]="user?.name"
      [src]="avatarUrl"
      loading="lazy"
      (load)="onLoad()"
      (error)="onError()" />
  </div>
</div>
