<div class="checklist-item-wrapper">
  <form
    (apexInView)="$event && inView()"
    inViewOnlyOnce
    #checklistItemForm="ngForm"
    #ciForm
    class="checklist-item df c"
    [ngClass]="{
      complete: checklistItem.Case?.completed,
      new: !checklistItem.Case?.completed,
      transition: addTransitionClass,
    }"
    (panright)="!isWeb && pan($event)"
    (panleft)="!isWeb && pan($event)"
    (panend)="!isWeb && panEnd()"
    (panstart)="!isWeb && panStart()"
    (click)="openDeviationDialog(checklistItem.Case, true)"
    [ngStyle]="{ transform: 'translateX(' + distance + 'px)' }"
    matRipple>
    <div class="df d-r item-wrapper p-c t-c">
      <div class="df ac oh f2">
        <div class="df c ac jc apls">
          <button
            type="button"
            mat-icon-button
            class="complete-item-button"
            name="complete"
            [matTooltip]="
              '{completed, select, true {Reopen item} other {Complete item}}'
                | translate: { completed: checklistItem?.Case?.completed > 0 }
            "
            matTooltipPosition="after"
            (click)="completeReopenCase(checklistItem.Case); $event.stopPropagation()">
            <mat-icon *ngIf="!checklistItem.Case?.completed">panorama_fish_eye</mat-icon>
            <mat-icon
              color="primary"
              *ngIf="checklistItem.Case?.completed">
              check_circle_outline
            </mat-icon>
          </button>
        </div>

        <div class="df c jc f2 apls amvs truncate">
          <div class="truncate amrs">
            {{ sectionName ? sectionName + ' ' + checklistItem.Case?.name : checklistItem.Case?.name }}
          </div>
          <div
            class="mat-caption amrs truncate"
            name="description">
            {{ checklistItem.Case?.description }}
          </div>
          <div
            *ngIf="checklistItem.note"
            class="mat-caption amrs truncate note-line"
            name="note">
            <T
              str="<strong>Note:</strong> {note}"
              [sanitize]="true"
              [vars]="{ note: checklistItem?.note ?? '' }">
            </T>
          </div>
        </div>
      </div>

      <div class="df ac">
        <div>
          <mat-icon
            class="file-on-case amr aml"
            *ngIf="checklistItem.Case?.FileUsages?.length"
            [matBadge]="checklistItem.Case?.FileUsages?.length"
            [matTooltip]="'Case has attachments' | translate">
            attach_file
          </mat-icon>
        </div>

        <section
          class="df ac note t-f1"
          (click)="$event.stopPropagation()">
          <div
            class="df ac amrs transition f1"
            [class.show-note-field]="showNoteField">
            <mat-form-field
              subscriptSizing="dynamic"
              class="ac f1 amvxs"
              [class.showSaved]="showSaved">
              <mat-label>
                <T str="Note"></T>
                <mat-icon
                  class="success-color amlxs transition"
                  [ngClass]="{
                    'op-1': showSaved,
                    'op-0': !showSaved,
                  }">
                  check_circle
                </mat-icon>
              </mat-label>

              <textarea
                class=""
                name="note"
                matInput
                rows="1"
                #textArea
                [(ngModel)]="checklistItem.note"
                (ngModelChange)="saveNote$.next()"></textarea>

              <ng-container *ngIf="saveNote$$ | async"></ng-container>
            </mat-form-field>
          </div>

          <div class="transition">
            <button
              mat-icon-button
              [matTooltip]="tooltip"
              (click)="$event.stopPropagation(); showNoteField = !showNoteField">
              <mat-icon>
                <ng-container *ngIf="showNoteField">cancel</ng-container>
                <ng-container *ngIf="!showNoteField">edit_note</ng-container>
              </mat-icon>
            </button>
          </div>
        </section>

        <div class="df ac print-display-none">
          <button
            mat-icon-button
            [matTooltip]="'Not Applicable' | translate"
            (click)="$event.stopPropagation(); toggleNotApplicable()">
            <mat-icon>mode_standby</mat-icon>
          </button>

          <button
            class="asc"
            mat-icon-button
            [matTooltip]="'New case' | translate"
            (click)="$event.stopPropagation(); openDeviationDialog()">
            <mat-icon>add</mat-icon>
          </button>

          <button
            class="asc"
            type="button"
            mat-icon-button
            color="warn"
            [matTooltip]="'Remove item' | translate"
            (click)="remove($event)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div
      class="df c association-wrapper amls p-apts amvxs"
      *ngIf="checklistItem.Case?.Meter as meter">
      <span class="mat-h3 rmb p-amb amhs">
        <T str="Fill in values for the meter listed below"></T>
      </span>

      <div class="df ac w">
        <div class="df afs w f2">
          <div class="df c f1 amhs p-minw-40 p-ambs">
            <span class="mat-caption">
              <T str="Meter"></T>
            </span>
            {{ meter.name }}
          </div>

          <div class="df c f1 amhs p-minw-40 p-ambs">
            <span class="mat-caption">
              <T str="Type"></T>
            </span>
            {{ meter.type | translateType }}
          </div>

          <div class="df c f1 amhs">
            <span class="mat-caption">
              <T str="External number"></T>
            </span>
            {{ meter.externalNumber }}
          </div>

          <div class="df ac f1 amhs">
            <div class="df c amrs">
              <span
                class="mat-caption"
                [matTooltip]="
                  'Last updated {date}'
                    | translate
                      : {
                          date: meter.Values[0]?.updatedAt | date: 'shortDate',
                        }
                ">
                <T str="Previous value"></T>
              </span>
              {{ meter.Values[0]?.value }}
            </div>

            <mat-icon
              [ngClass]="meterIconClass"
              [matTooltip]="meterTooltip">
              {{ meterState }}
            </mat-icon>
          </div>
        </div>

        <apex-meter-value-form
          class="f1 amhs"
          (click)="$event.stopPropagation()"
          [meter]="meter"
          (valueSaved)="updateCase()">
        </apex-meter-value-form>
      </div>
    </div>
  </form>
  <div
    class="delete-item"
    [ngClass]="{ delete: deleteItem }">
    <button
      mat-icon-button
      color="warn">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
<ng-container *ngIf="checklistItem?.Cases?.length > 0">
  <div>
    <mat-progress-bar
      mode="determinate"
      [value]="percentCompleted">
    </mat-progress-bar>
    <ng-container *ngFor="let case of Cases">
      <div
        class="df r ac jsb"
        [ngClass]="{ complete: case.completed, warning: !case.completed }"
        (click)="openDeviationDialog(case)">
        <div class="apl">
          <button
            type="button"
            mat-icon-button
            class="complete-item-button"
            (click)="completeReopenDeviation($event, case)"
            name="complete">
            <mat-icon *ngIf="!case?.completed"> panorama_fish_eye</mat-icon>
            <mat-icon
              *ngIf="case?.completed"
              color="primary">
              check_circle_outline
            </mat-icon>
          </button>
        </div>
        <div class="f1">
          <div>
            {{ case.name }}
          </div>
          <div class="mat-caption">
            {{ case.description }}
          </div>
        </div>
        <span class="print-display-none">
          <button
            mat-icon-button
            [matTooltip]="'Open case' | translate"
            (click)="$event.stopPropagation(); openDeviationDialog(case)">
            <mat-icon>open_in_full</mat-icon>
          </button>
          <button
            mat-icon-button
            [matTooltip]="'Remove case from item' | translate"
            (click)="$event.stopPropagation(); removeDeviation(case)">
            <mat-icon>link_off</mat-icon>
          </button>
        </span>
      </div>
    </ng-container>
  </div>
</ng-container>
