import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SidebarService } from '../../components/sidebar/services/sidebar.service';
import { t } from '../../components/translate/translate.function';
import { snack, snackErr } from '../../modules/snack.module';
import { nameCompare } from '../../utils/functions';
import { Bookmark, BookmarkFolder } from './bookmark.model';
import { BookmarkService } from './bookmark.service';

@Component({
  selector: 'apex-bookmark-folder',
  templateUrl: './bookmark-folder.component.html',
})
export class BookmarkFolderComponent implements OnInit, OnDestroy {
  @Input() id: number;
  @ViewChild('folderMenu', { static: true }) public folderMenu: MatMenu;

  bookmarks: Bookmark[] = [];
  folders: BookmarkFolder[] = [];

  subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private service: BookmarkService,
    protected readonly sidebarService: SidebarService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.service.data$.subscribe({ next: () => this.createLists() }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  createLists(): void {
    this.folders = this.service.folders.filter((f) => f.ParentId === this.id).sort(nameCompare);
    this.bookmarks = this.service.bookmarks.filter((b) => b.FolderId === this.id).sort(nameCompare);
  }

  click(b: Bookmark): Promise<boolean> {
    return void this.router.navigateByUrl(b.url);
  }

  add(name: string, type: 'b' | 'f'): boolean {
    if (!name) {
      return false;
    }

    const url = location.href.replace(location.origin, '');

    if (type === 'b') {
      this.subscriptions.add(
        this.service
          .saveBookmark({
            id: null,
            name,
            FolderId: this.id,
            url,
          })
          .pipe(take(1))
          .subscribe({
            next: (_) => snack(t('Bookmark added')),
            error: (err) => snackErr(t('Could not save'), err),
          }),
      );

      return true;
    }

    if (type === 'f') {
      this.subscriptions.add(
        this.service
          .saveFolder({
            id: null,
            name,
            ParentId: this.id,
          })
          .pipe(take(1))
          .subscribe({
            next: (_) => snack(t('Folder added')),
            error: (err) => snackErr(t('Could not save'), err),
          }),
      );

      return true;
    }

    return false;
  }
}
