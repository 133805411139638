import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AutocompleteModule } from 'projects/apex/src/app/components/autocomplete/autocomplete.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { HistoryBackModule } from 'projects/apex/src/app/directives/history-back/history-back.module';
import { HighlightPipeModule } from 'projects/apex/src/app/pipes/highlight/highlight-pipe.module';
import { BoxModule } from '../../../../../components/box/box.module';
import { EntryGroupModule } from '../entry-group/entry-group.module';
import { EntryCardService } from './entry-card.service';
import { EntryCardComponent } from './form.component';
import { EntryCardsComponent } from './list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatRippleModule,
    RouterModule,
    ScrollingModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,

    AutocompleteModule,
    TranslateModule,
    HistoryBackModule,
    HighlightPipeModule,
    EntryGroupModule,
    BoxModule,
  ],
  exports: [EntryCardComponent, EntryCardsComponent],
  declarations: [EntryCardComponent, EntryCardsComponent],
  providers: [EntryCardService],
})
export class EntryCardModule {}
