<input
  #fileUploadInput
  class="dn"
  type="file"
  multiple
  (change)="uploadFromInput($event, folder)" />

<input
  #newImageUploadInput
  class="dn"
  type="file"
  accept="image/*"
  (change)="uploadFromNewImageInput($event, folder)" />

<mat-menu
  #empty="matMenu"
  class="dn"></mat-menu>
<apex-drag [parent]="el"></apex-drag>

<mat-menu #sortMenu="matMenu">
  <ng-template matMenuContent>
    <button
      mat-menu-item
      (click)="setMatSort('name')">
      <span class="df ac">
        <mat-icon
          [ngClass]="{ vh: !sort.direction || sort.active !== 'name' }"
          inline>
          {{ sort.direction === 'asc' ? 'north' : 'south' }}
        </mat-icon>
        <T str="Name"></T>
      </span>
    </button>
    <button
      mat-menu-item
      (click)="setMatSort('updatedAt')">
      <span>
        <mat-icon
          [ngClass]="{ vh: !sort.direction || sort.active !== 'updatedAt' }"
          inline>
          {{ sort.direction === 'asc' ? 'north' : 'south' }}
        </mat-icon>
        <T str="Last modified"></T>
      </span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #headerActionsMenu="matMenu">
  <ng-template matMenuContent>
    <button
      *ngIf="folder.modelName !== 'home'"
      mat-menu-item
      (click)="id = 'home'">
      <mat-icon>home</mat-icon>
      <T str="Home"></T>
    </button>
    <button
      mat-menu-item
      [matMenuTriggerFor]="sortMenu">
      <mat-icon>sort_by_alpha</mat-icon>
      <T str="Sort"></T>
    </button>
    <button
      mat-menu-item
      [disabled]="!displayShareSelection"
      (click)="$event.stopPropagation(); shareSelection()">
      <mat-icon>person_add</mat-icon>
      <T str="Share selection"></T>
    </button>
    <button
      mat-menu-item
      [disabled]="isSelectionEmpty"
      (click)="$event.stopPropagation(); moveSelection()">
      <mat-icon>drive_file_move</mat-icon>
      <T str="Move selection"></T>
    </button>
    <button
      mat-menu-item
      [disabled]="isSelectionEmpty"
      (click)="$event.stopPropagation(); deleteSelection()">
      <mat-icon>delete</mat-icon>
      <T str="Delete selection"></T>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #breadcrumbMenu="matMenu">
  <ng-template matMenuContent>
    <button
      mat-menu-item
      *ngFor="let f of folder.Parents; let i = index"
      apexDrop
      class="{{ 'folder-' + f.id }}"
      [class.empty]="!f.hasChildren && !f.hasFiles"
      [ngClass]="{ dn: i === 0 || i === folder.Parents.length - 1 }"
      (click)="setNewFolderId(f.id)"
      (apexDrop)="$event ? uploadFromDragEvent($event, f) : dropIntoFolder(f)">
      <span class="df ac">
        <mat-icon color="primary">
          {{ folderIcon(f) }}
        </mat-icon>
        <span>
          {{ folderName(f) }}
        </span>
      </span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #folderMore="matMenu">
  <ng-template
    matMenuContent
    let-moreFolder="moreFolder">
    <button
      mat-menu-item
      [disabled]="!moreFolder?.access?.collaborator"
      (click)="newFolder(moreFolder)">
      <mat-icon>create_new_folder</mat-icon>
      <span>
        <T str="Create folder"></T>
      </span>
    </button>
    <button
      mat-menu-item
      [disabled]="!moreFolder?.access?.collaborator"
      (click)="fileUploadInput.click()">
      <mat-icon>cloud_upload</mat-icon>
      <span>
        <T str="Upload file"></T>
      </span>
    </button>
    <button
      mat-menu-item
      [disabled]="!moreFolder?.id || moreFolder.modelName || !moreFolder?.access?.owner"
      (click)="shareFolder(moreFolder)">
      <mat-icon>person_add</mat-icon>
      <span>
        <T str="Share"></T>
      </span>
    </button>
    <button
      mat-menu-item
      [disabled]="!moreFolder?.id || moreFolder?.modelName || !moreFolder.access?.edit"
      (click)="moveFolder(moreFolder)">
      <mat-icon>drive_file_move</mat-icon>
      <span>
        <T str="Move"></T>
      </span>
    </button>
    <button
      mat-menu-item
      [disabled]="!moreFolder?.id || moreFolder?.modelName || !moreFolder.access?.edit"
      (click)="renameFolder(moreFolder)">
      <mat-icon>edit</mat-icon>
      <span>
        <T str="Rename"></T>
      </span>
    </button>
    <button
      mat-menu-item
      [disabled]="!moreFolder?.id || moreFolder?.modelName || !moreFolder.access?.edit"
      (click)="deleteFolder(moreFolder)">
      <mat-icon>delete</mat-icon>
      <span>
        <T str="Delete"></T>
      </span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #fileMore="matMenu">
  <ng-template
    matMenuContent
    let-moreFile="moreFile">
    <button
      mat-menu-item
      [disabled]="!folder?.access?.owner"
      (click)="shareFile(moreFile)">
      <mat-icon>person_add</mat-icon>
      <span>
        <T str="Share"></T>
      </span>
    </button>
    <button
      mat-menu-item
      [disabled]="!folder?.access?.collaborator"
      (click)="moveFile(moreFile)">
      <mat-icon>drive_file_move</mat-icon>
      <span>
        <T str="Move"></T>
      </span>
    </button>
    <button
      mat-menu-item
      [disabled]="!folder?.access?.collaborator"
      (click)="copyFile(moreFile)">
      <mat-icon>content_copy</mat-icon>
      <span>
        <T str="Make a copy"></T>
      </span>
    </button>
    <button
      mat-menu-item
      [disabled]="!folder?.access?.collaborator"
      (click)="renameFile(moreFile)">
      <mat-icon>edit</mat-icon>
      <span>
        <T str="Rename"></T>
      </span>
    </button>
    <button
      mat-menu-item
      [disabled]="!folder?.access?.view"
      (click)="downloadFile(moreFile)">
      <mat-icon>cloud_download</mat-icon>
      <span>
        <T str="Download"></T>
      </span>
    </button>
    <button
      mat-menu-item
      [disabled]="!folder?.access?.collaborator"
      (click)="deleteFile(moreFile)">
      <mat-icon>delete</mat-icon>
      <span>
        <T str="Delete"></T>
      </span>
    </button>
  </ng-template>
</mat-menu>

<ng-template
  #breadcrumbItemTemplate
  let-f="folder">
  <button
    #headerParents
    [ngClass]="'folder-' + f.id"
    mat-button
    color="primary"
    apexDrop
    (click)="setNewFolderId(f.id)"
    (apexDrop)="$event ? uploadFromDragEvent($event, f) : dropIntoFolder(f)">
    <span>
      {{ folderName(f) }}
    </span>
  </button>
  <mat-icon>keyboard_arrow_right</mat-icon>
</ng-template>

<ng-template #breadcrumbTemplate>
  <div class="f1 df ac breadcrumb oh">
    <div class="ds">
      <div class="df ac amls">
        <button
          mat-icon-button
          (click)="setNewFolderId('home')">
          <mat-icon>home</mat-icon>
        </button>
        <mat-icon *ngIf="folder.modelName !== 'home'">keyboard_arrow_right</mat-icon>
      </div>
    </div>
    <div
      *ngIf="folder.modelName !== 'home'"
      class="dh">
      <button
        #headerParent
        [ngClass]="'folder-' + folder.ParentId"
        mat-icon-button
        (click)="setNewFolderId(folder.ParentId ? folder.ParentId : 'home')"
        apexDrop
        [matTooltip]="'Back' | translate"
        [apexDropDisabled]="!folder.Parent"
        [dropDisabled]="!folder.Parent"
        (apexDrop)="$event ? uploadFromDragEvent($event, folder.Parent) : dropIntoFolder(folder.Parent)">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div class="ds">
      <div
        *ngIf="folder?.Parents?.length"
        class="df ac">
        <ng-container
          *ngTemplateOutlet="
            breadcrumbItemTemplate;
            context: {
              folder: folder.Parents[0],
            }
          ">
        </ng-container>
        <ng-container *ngIf="folder.Parents.length === 3">
          <ng-container
            *ngTemplateOutlet="
              breadcrumbItemTemplate;
              context: {
                folder: folder.Parents[1],
              }
            ">
          </ng-container>
        </ng-container>
        <ng-container *ngIf="folder.Parents.length >= 4">
          <button
            #breadcrumbMenuTrigger="matMenuTrigger"
            mat-icon-button
            apexDrop
            [matMenuTriggerFor]="breadcrumbMenu"
            (dragover)="breadcrumbMenuTrigger.openMenu()"
            (apexDropEnter)="breadcrumbMenuTrigger.openMenu()">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-icon>keyboard_arrow_right</mat-icon>
        </ng-container>
        <ng-container *ngIf="folder.Parents.length > 1">
          <ng-container
            *ngTemplateOutlet="
              breadcrumbItemTemplate;
              context: {
                folder: folder.Parents[folder.Parents.length - 1],
              }
            ">
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div
      *ngIf="folder.modelName !== 'home'"
      class="df ac oh">
      <button
        mat-button
        color="primary"
        [disabled]="folder.modelName === 'upload' || folder.modelName === 'share'"
        [matMenuTriggerFor]="folderMore"
        [matMenuTriggerData]="{ moreFolder: folder }">
        <span class="truncate">
          {{ folderName(folder) }}
        </span>
        <mat-icon *ngIf="folder.modelName !== 'upload' && folder.modelName !== 'share'"> arrow_drop_down</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #headerActionsTemplate>
  <div class="df ac">
    <button
      class="ph"
      *ngIf="displayShareSelection"
      mat-icon-button
      [matTooltip]="'Share selection' | translate"
      (click)="$event.stopPropagation(); shareSelection()">
      <mat-icon>person_add</mat-icon>
    </button>
    <button
      class="ph"
      *ngIf="!isSelectionEmpty && !selectionContainSystemFolder && !selectionContainsElementWithViewAccess"
      mat-icon-button
      [matTooltip]="'Move selection' | translate"
      (click)="$event.stopPropagation(); moveSelection()">
      <mat-icon>drive_file_move</mat-icon>
    </button>
    <button
      class="ph"
      *ngIf="!isSelectionEmpty && !selectionContainSystemFolder && !selectionContainsElementWithViewAccess"
      mat-icon-button
      [matTooltip]="'Delete selection' | translate"
      (click)="$event.stopPropagation(); deleteSelection()">
      <mat-icon>delete</mat-icon>
    </button>

    <button
      class="ph"
      *ngIf="
        profile?.isAdmin() &&
        !isSelectionEmpty &&
        selectionIsOnlyOneFolder &&
        !selectionContainSystemFolder &&
        !selectionContainsElementWithViewAccess
      "
      mat-icon-button
      [matTooltip]="'Set as Project Template' | translate"
      (click)="
        $event.stopPropagation(); setProjectOrEntityTemplateFolder(selection.folders[0], 'project-template-folder')
      ">
      <mat-icon>snippet_folder</mat-icon>
    </button>

    <button
      class="ph rotate-180"
      *ngIf="
        profile?.isAdmin() &&
        !isSelectionEmpty &&
        selectionIsOnlyOneFolder &&
        !selectionContainSystemFolder &&
        !selectionContainsElementWithViewAccess
      "
      mat-icon-button
      [matTooltip]="'Set as Project Entity Template' | translate"
      (click)="
        $event.stopPropagation();
        setProjectOrEntityTemplateFolder(selection.folders[0], 'project-entity-template-folder')
      ">
      <mat-icon>snippet_folder</mat-icon>
    </button>

    <apex-search
      #searchComponent
      [ngClass]="{ dn: !search }"
      (focusChange)="!$event && (search = false)">
    </apex-search>
    <button
      *ngIf="!search"
      mat-icon-button
      [matTooltip]="'Search' | translate"
      (click)="search = true; searchComponent.focus()">
      <mat-icon>search</mat-icon>
    </button>
    <button
      mat-icon-button
      [matTooltip]="
        '{view, select, grid {List} list {Grid} other {Unknown}}'
          | translate: { view, _comment: 'View it will change into' }
      "
      (click)="setView(view === 'grid' ? 'list' : 'grid')">
      <mat-icon>{{ view === 'grid' ? 'view_list' : 'view_module' }}</mat-icon>
    </button>
    <button
      class="ph"
      mat-icon-button
      [matTooltip]="'Sort' | translate"
      [matMenuTriggerFor]="sortMenu">
      <mat-icon>sort_by_alpha</mat-icon>
    </button>
    <button
      class="ps"
      mat-icon-button
      [matTooltip]="'More' | translate"
      [matMenuTriggerFor]="headerActionsMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #headerTemplate>
  <div
    class="header df d-ac t-ac oh p-c"
    (mousedown)="$event.stopPropagation()"
    (click)="$event.stopPropagation()">
    <ng-container *ngTemplateOutlet="breadcrumbTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="headerActionsTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #foldersGridTemplate>
  <div class="folders items df">
    <div
      *ngFor="let f of folder.Children"
      class="item df oh">
      <div
        #card
        class="folder {{ 'folder-' + f.id }} f1 df ac ams oh"
        apexDrag
        apexDrop
        [(selected)]="f.selected"
        [name]="folderName(f)"
        model="folder"
        [modelId]="f.id"
        dragIcon="folder"
        [dragIconClass]="f.modelName ? null : 'primary-color'"
        [apexDragDisabled]="f.modelName ? true : false"
        [apexDropDisabled]="f.modelName === 'upload' || f.modelName === 'share' ? true : false"
        [dropDisabled]="f.modelName === 'upload' || f.modelName === 'share' ? true : false"
        [ngClass]="{ selected: f.selected }"
        (mousedown)="$event.stopPropagation()"
        (click)="$event.stopPropagation(); isWeb || selectionMode ? (f.selected = !f.selected) : setNewFolderId(f.id)"
        (dblclick)="setNewFolderId(f.id)"
        (press)="selectionMode = true"
        (apexDrop)="$event ? uploadFromDragEvent($event, f ? f : folder) : dropIntoFolder(f)">
        <div
          class="f1 df ac"
          mat-ripple>
          <mat-icon
            [class.empty]="f.modelName !== 'share' && !f.hasChildren && !f.hasFiles"
            class="ams"
            color="primary"
            [matTooltip]="folderIconTooltip(f)">
            {{ folderIcon(f) }}
          </mat-icon>
          <span class="mat-body-2 f1 oh df ac">
            <span
              class="truncate"
              [matTooltip]="folderName(f)">
              {{ folderName(f) }}
            </span>
          </span>
          <button
            *ngIf="f.modelName !== 'upload' && f?.access?.edit"
            mat-icon-button
            (mousedown)="$event.stopPropagation()"
            (click)="$event.stopPropagation()"
            [matMenuTriggerFor]="folderMore"
            [matMenuTriggerData]="{ moreFolder: f }">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <button
      mat-button
      (apexInView)="$event && loadMoreChildren()"
      *ngIf="!loadingMoreFolders && folder?.Children?.length < childrenCount"
      (click)="loadMoreChildren()">
      <T str="Load more"></T>
    </button>
  </div>
</ng-template>

<ng-template #filesGridTemplate>
  <div class="files items df">
    <div
      *ngFor="let f of folder.Files"
      class="item df oh">
      <div
        #card
        class="file {{ 'file-' + f.id }} mat-elevation-z2 f1 df ac ams oh"
        [ngClass]="{ selected: f.selected }"
        mat-ripple
        apexDrag
        [(selected)]="f.selected"
        [name]="f.name"
        model="file"
        [modelId]="f.id"
        dragIcon="insert_drive_file"
        [matTooltip]="f.name"
        (mousedown)="$event.stopPropagation()"
        (click)="$event.stopPropagation(); isWeb || selectionMode ? (f.selected = !f.selected) : openFile(f)"
        (dblclick)="openFile(f)"
        (apexInView)="$event && (f.show = true)"
        inViewOnlyOnce
        (press)="!isWeb && (selectionMode = true)">
        <div class="df c w-100 h-100 oh">
          <apex-file-preview
            *ngIf="f.show && f?.name"
            class="f1 oh"
            [file]="f"
            dragable="false">
          </apex-file-preview>
          <div class="df ac">
            <span class="f1 amls oh df ac">
              <span class="truncate oh">{{ f.name }}</span>
              <mat-icon
                *ngIf="f.shared"
                [inline]="true"
                class="amls"
                >people</mat-icon
              >
            </span>
            <button
              mat-icon-button
              [matMenuTriggerFor]="fileMore"
              [matMenuTriggerData]="{ moreFile: f }"
              (mousedown)="$event.stopPropagation()"
              (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      mat-button
      (apexInView)="$event && loadMoreFiles()"
      *ngIf="!loadingMoreFiles && folder?.Files?.length < filesCount"
      (click)="loadMoreFiles()">
      <T str="Load more"></T>
    </button>
  </div>
</ng-template>

<ng-template #foldersListTemplate>
  <table
    #folderTable
    mat-table
    [dataSource]="folder.Children"
    class="folders w-100"
    matSort
    [matSortActive]="sort.active"
    [matSortDirection]="sort.direction"
    (matSortChange)="setMatSort($event.active)">
    <ng-container matColumnDef="selected">
      <th
        mat-header-cell
        *matHeaderCellDef></th>
      <td
        mat-cell
        *matCellDef="let f">
        <mat-checkbox
          *ngIf="f.id !== 'share'"
          [(ngModel)]="f.selected"
          (click)="$event.stopPropagation()">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        <T str="Name"></T>
      </th>
      <td
        mat-cell
        *matCellDef="let f">
        <div class="df ac">
          <mat-icon
            [class.empty]="f.modelName !== 'share' && !f.hasChildren && !f.hasFiles"
            class="amrs"
            color="primary"
            [matTooltip]="folderIconTooltip(f)">
            {{ folderIcon(f) }}
          </mat-icon>
          <span class="df ac oh">
            <span class="truncate oh">
              {{ folderName(f) }}
            </span>
          </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="updatedAt">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        <T str="Last modified"></T>
      </th>
      <td
        mat-cell
        *matCellDef="let f">
        {{ f.updatedAt ? (f.updatedAt | date: 'shortDate') : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="size">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        <T str="Size"></T>
      </th>
      <td
        mat-cell
        *matCellDef="let f">
        {{ f.size ? (f.size | fileSize: true) : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="more">
      <th
        mat-header-cell
        *matHeaderCellDef></th>
      <td
        mat-cell
        *matCellDef="let f">
        <button
          *ngIf="f?.access?.edit && f.modelName !== 'upload' && f.id === 'share'"
          mat-icon-button
          (click)="$event.stopPropagation()"
          [matMenuTriggerFor]="folderMore"
          [matMenuTriggerData]="{ moreFolder: f }">
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"></tr>
    <tr
      #row
      class="{{ 'folder-' + f.id }} clickable"
      [ngClass]="{ selected: f.selected }"
      mat-row
      *matRowDef="let f; columns: displayedColumns"
      apexDrag
      apexDrop
      [(selected)]="f.selected"
      [name]="folderName(f)"
      model="folder"
      [modelId]="f.id"
      dragIcon="folder"
      [dragIconClass]="f.modelName ? null : 'primary-color'"
      [apexDragDisabled]="f.modelName ? true : false"
      [apexDropDisabled]="f.modelName === 'upload' ? true : false"
      [dropDisabled]="f.modelName === 'upload' ? true : false"
      (mousedown)="$event.stopPropagation()"
      (click)="!isWeb && setNewFolderId(f.id)"
      (dblclick)="setNewFolderId(f.id)"
      (apexDrop)="$event ? uploadFromDragEvent($event, f ? f : folder) : dropIntoFolder(f)"></tr>
  </table>
  <button
    mat-button
    (apexInView)="$event && loadMoreChildren()"
    *ngIf="!loadingMoreFolders && folder?.Children?.length < childrenCount"
    (click)="loadMoreChildren()">
    <T str="Load more"></T>
  </button>
</ng-template>

<ng-template #filesListTemplate>
  <table
    #fileTable
    mat-table
    [dataSource]="folder.Files"
    class="files w-100">
    <ng-container matColumnDef="selected">
      <th
        mat-header-cell
        *matHeaderCellDef></th>
      <td
        mat-cell
        *matCellDef="let f">
        <mat-checkbox
          [(ngModel)]="f.selected"
          (click)="$event.stopPropagation()">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        *matHeaderCellDef>
        <T str="Name"></T>
      </th>
      <td
        mat-cell
        *matCellDef="let f">
        <div class="df ac">
          <div class="file-preview amrs">
            <apex-file-preview
              *ngIf="f.show && f?.name"
              size="24"
              [file]="f">
            </apex-file-preview>
          </div>
          <span
            class="oh df ac"
            [matTooltip]="f.name">
            <span class="truncate oh">{{ f.name }}</span>
            <mat-icon
              *ngIf="f.shared"
              [inline]="true"
              class="amls"
              >people</mat-icon
            >
          </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="updatedAt">
      <th
        mat-header-cell
        *matHeaderCellDef>
        <T str="Last modified"></T>
      </th>
      <td
        mat-cell
        *matCellDef="let f">
        {{ f.updatedAt ? (f.updatedAt | date: 'shortDate') : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="size">
      <th
        mat-header-cell
        *matHeaderCellDef>
        <T str="Size"></T>
      </th>
      <td
        mat-cell
        *matCellDef="let f">
        {{ f.size ? (f.size | fileSize: true) : '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="more">
      <th
        mat-header-cell
        *matHeaderCellDef></th>
      <td
        mat-cell
        *matCellDef="let f">
        <button
          mat-icon-button
          [matMenuTriggerFor]="fileMore"
          [matMenuTriggerData]="{ moreFile: f }"
          (click)="$event.stopPropagation()">
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"></tr>
    <tr
      #row
      mat-row
      *matRowDef="let f; columns: displayedColumns"
      class="clickable"
      [ngClass]="{ selected: f.selected }"
      apexDrag
      [(selected)]="f.selected"
      [name]="f.name"
      model="file"
      [modelId]="f.id"
      dragIcon="insert_drive_file"
      (mousedown)="$event.stopPropagation()"
      (click)="!isWeb && openFile(f)"
      (dblclick)="openFile(f)"
      (apexInView)="$event && (f.show = true)"
      inViewOnlyOnce></tr>
  </table>
  <button
    mat-button
    (apexInView)="$event && loadMoreFiles()"
    *ngIf="!loadingMoreFiles && folder?.Files?.length < filesCount"
    (click)="loadMoreFiles()">
    <T str="Load more"></T>
  </button>
</ng-template>

<div class="f1 df p-c oh">
  <div
    class="df ds amts oh"
    (mousedown)="$event.stopPropagation()">
    <apex-folder-tree
      [folder]="folder"
      (folderChange)="$event && folder && $event.id !== folder.id && (id = $event.id)"
      (folderDrop)="dropIntoFolder($event)">
    </apex-folder-tree>
  </div>
  <div
    *ngIf="folder"
    class="df c f5 oh"
    apexDrop
    [dropDisabled]="id === 'home'"
    [apexDropDisabled]="true"
    (apexDrop)="$event && uploadFromDragEvent($event, folder)"
    (click)="resetSelection()">
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    <ng-container *ngIf="!loading">
      <div
        *ngIf="
          folder?.access?.collaborator &&
          folder.modelName !== 'home' &&
          folder.modelName !== 'upload' &&
          folder.modelName !== 'share' &&
          !folder?.Children?.length &&
          !folder?.Files?.length
        "
        class="upload f1 df c ac jc"
        mat-ripple
        (click)="fileUploadInput.click()">
        <mat-icon>cloud_upload</mat-icon>
        <T str="Click or drag files here to add"></T>
      </div>
      <ng-container *ngIf="folder?.Children?.length || folder?.Files?.length">
        <div
          *ngIf="view === 'grid'"
          class="grid f1 oa amhs">
          <ng-container *ngTemplateOutlet="foldersGridTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="filesGridTemplate"></ng-container>
        </div>
        <div
          *ngIf="view === 'list'"
          class="list f1 oa">
          <ng-container *ngTemplateOutlet="foldersListTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="filesListTemplate"></ng-container>
        </div>
      </ng-container>
    </ng-container>
    <div
      *ngIf="loading || error"
      class="f1 df ac jc">
      <mat-spinner *ngIf="loading"></mat-spinner>
      <T
        str="Could not get folder"
        *ngIf="error"></T>
    </div>
  </div>
</div>
