<apex-box
  *ngIf="addonOrder"
  icon="track_changes"
  expanded>
  <ng-container title>
    {{ addonOrder.Addon?.name }}
  </ng-container>

  <mat-icon
    right
    class="status-icon amr"
    [matTooltip]="addonOrder.status | translateType"
    [ngClass]="{
      'info-color': addonOrder.status === 'new',
      'warning-color': addonOrder.status === 'denied',
      'success-color': addonOrder.status === 'accepted',
      'error-color': addonOrder.status === 'canceled',
    }">
    <ng-container *ngIf="addonOrder.status === 'new'">info</ng-container>
    <ng-container *ngIf="addonOrder.status === 'denied'">error</ng-container>
    <ng-container *ngIf="addonOrder.status === 'accepted'">check_circle</ng-container>
    <ng-container *ngIf="addonOrder.status === 'canceled'">cancel</ng-container>
  </mat-icon>

  <main class="df c">
    <section class="df addon-info">
      <section class="df c f1 ams">
        <apex-title-item
          class="amb"
          icon="description"
          fontSet="material-icons-sharp">
          <T
            title
            str="Description"></T>

          {{ addonOrder.Addon?.description ?? '' }}
        </apex-title-item>

        <apex-title-item
          icon="category"
          fontSet="material-icons-sharp">
          <T
            title
            str="Category"></T>

          {{ addonOrder.addonCategoryPath | listArray: ' / ' : 'name' }}
        </apex-title-item>
      </section>

      <aside class="ams mat-elevation-z2">
        <apex-file-usage-preview
          (click)="openImages()"
          *ngIf="addonOrder.Addon?.images?.length"
          fit="contain"
          [fileUsages]="addonOrder.Addon?.images">
        </apex-file-usage-preview>
      </aside>
    </section>

    <mat-divider></mat-divider>

    <section class="display-grid-cards card-width-150 ams">
      <apex-title-item>
        <T
          title
          str="ID"></T>

        {{ addonOrder.id }}
      </apex-title-item>

      <apex-title-item>
        <T
          title
          str="Quantity"></T>

        {{ addonOrder.quantity }}
      </apex-title-item>

      <apex-title-item *ngIf="addonOrder.Markings?.length">
        <T
          title
          str="Markings"></T>

        <button
          mat-icon-button
          [matBadge]="addonOrder.Markings?.length"
          (click)="openMarkings()">
          <mat-icon svgIcon="floorplan"></mat-icon>
        </button>
      </apex-title-item>

      <apex-title-item>
        <T
          title
          str="Client"></T>

        {{ addonOrder.Client?.name }}
      </apex-title-item>

      <apex-title-item *ngIf="addonOrder.ClientComment">
        <T
          title
          str="Comment"></T>

        {{ addonOrder.ClientComment }}
      </apex-title-item>

      <apex-title-item>
        <T
          title
          str="Created"></T>

        {{ addonOrder.createdAt | date: 'shortDate' }}
      </apex-title-item>

      <apex-title-item>
        <T
          title
          str="Processed"></T>

        {{ addonOrder.dateProcessed | date: 'shortDate' }}
      </apex-title-item>

      <ng-container *ngIf="addonOrder.SignedFileUsage">
        <apex-title-item>
          <T
            title
            str="Signed PDF"></T>

          <button
            type="button"
            mat-icon-button
            [matTooltip]="'Signed PDF' | translate"
            (click)="openSignedPDF(addonOrder.SignedFileUsage)">
            <mat-icon>receipt</mat-icon>
          </button>
        </apex-title-item>
      </ng-container>

      <ng-container
        *ngIf="addonOrder.Apartment?.Project?.id !== case.ProjectId || addonOrder.ApartmentId !== case.ApartmentId">
        <apex-title-item class="f1">
          <T
            title
            str="Project"></T>

          {{ addonOrder.Apartment?.Project?.name }}
        </apex-title-item>

        <apex-title-item class="f1">
          <T
            title
            str="Unit"></T>

          {{ addonOrder.Apartment?.name }}
        </apex-title-item>
      </ng-container>

      <apex-title-item *ngIf="addonOrder.ManagerId !== case.CaseManagerId">
        <T
          title
          str="Manager"></T>

        <div class="df ac">
          <apex-avatar
            class="amrs"
            [user]="addonOrder.Manager"
            [tooltip]="'Manager' | translate"
            size="small">
          </apex-avatar>
          {{ addonOrder.Manager?.name }}
        </div>
      </apex-title-item>

      <apex-title-item>
        <T
          title
          str="Manager comment"></T>

        {{ addonOrder.ManagerComment }}
      </apex-title-item>
    </section>
  </main>
</apex-box>
