import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { File } from 'projects/apex/src/app/models/file';
import { snack } from '../../modules/snack.module';
import { t } from '../translate/translate.function';

export type FileViewerDialogData = {
  file?: File;
  files?: File[];
  showFolderAction: boolean;
};

@Component({
  selector: 'apex-file-viewer-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class FileViewerDialogComponent implements OnInit {
  viewFile: File;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: FileViewerDialogData,
    public ref: MatDialogRef<FileViewerDialogComponent>,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.data && this.data.showFolderAction !== false) {
      this.data.showFolderAction = true;
    }

    if (!this.data?.file && !this.data?.files?.length) {
      throw new Error('one or more files required');
    }

    if (this.data?.file) {
      this.viewFile = this.data.file;
    }

    if (this.data.files?.length) {
      if (!this.viewFile) {
        this.viewFile = this.data.files[0];
      }
    }

    this.ref?.addPanelClass(['apex-fullscreen-dialog', 'tablet', 'phone']);
  }

  goToFolder(): void {
    void this.router.navigate(['folder', this.data.file.FolderId]);
  }

  downloadFile(file?: File): void {
    if (!file) {
      return;
    }

    if (!file?.signed?.withDispositionUrl) {
      snack(t('Trouble with your file, error will be reported to APEX'));

      throw new Error('downloadFile ran without withDispositionUrl');
    }

    window.open(file.signed.withDispositionUrl, '_blank');
  }
}
