import { Component, Input } from '@angular/core';
import { ResponseCaseLogDto } from '../../../../../../../generated/apex-rest';
import { CaseLog } from '../../../models/case-log';
import { IDN } from '../../reporting/project-cases-by-day/project-cases-by-day.types';
import { CaseLogUserPipe } from './pipes/case-log-user.pipe';

@Component({
  selector: 'apex-case-log-system-message',
  templateUrl: './system-message.component.html',
})
export class CaseLogSystemMessageComponent {
  @Input() caseLog: CaseLog | ResponseCaseLogDto;

  caseLogUserPipe = new CaseLogUserPipe();

  get user(): IDN | null {
    return this.caseLogUserPipe.transform(this.caseLog);
  }
}
