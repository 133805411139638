<form>
  <div mat-dialog-title>
    <div class="df jsb ac f1">
      <h1>
        <T
          str="Input"
          *ngIf="!data?.title"></T>
        <ng-container *ngIf="data?.title">
          {{ data.title }}
        </ng-container>
      </h1>
      <button
        type="button"
        mat-icon-button
        (click)="ref.close()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <p>{{ data.text }}</p>
    <mat-form-field>
      <input
        matInput
        name="value"
        [(ngModel)]="data.value"
        cdkFocusInitial
        apexSelectOnFocus />
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      type="button"
      mat-button
      (click)="ref.close()">
      <T str="Cancel"></T>
    </button>
    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="invalid"
      (click)="ref.close(data.value)">
      <T str="Save"></T>
    </button>
  </mat-dialog-actions>
</form>
