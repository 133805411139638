import { Case } from '../../models/case';

export interface ObjectPosition {
  id: number;
  name: string;
  lat: number;
  lng: number;
  distance: number;
}

export const caseParamModelKeys = {
  Object: 'ObjectId',
  Project: 'ProjectId',
  Apartment: 'ApartmentId',
  Client: 'ClientId',
  Tenancy: 'TenancyId',
  Agreement: 'AgreementId',
  CaseStatus: 'CaseStatus',
  ContractorId: 'ContractorId',
  CaseManager: 'CaseManagerId',
  Category: 'CaseCategoryId',
  Field: 'FieldId',
  ObjectField: 'ObjectFieldId',
  Checklist: 'ChecklistId',
  apartmentFieldNumberSearch: 'apartmentFieldNumberSearch',
  from: 'from',
  to: 'to',
  Meter: 'meter',
  Ownership: 'ObjectOwnershipId',

  Contractor: 'Contractor',

  AddonCategory: 'AddonCategory',
  Tag: 'Tag',
};

export const caseParamSettingKeys = [
  'showOpen',
  'showCompleted',

  'showCaseManagers',
  'hideWithChecklist',
  'showChecklistItems',
  'showArchived',

  'textSearch',

  'onlyUnread',
  'overdue',

  'sort',
  'sortOrder',

  'deadline',
  'deadlineFrom',
  'deadlineTo',

  'page',

  'showNoCaseManager',
  'showNoContractor',
  'showNoField',
  'hideFromProjectMom',
];

export interface CaseListEntity extends Case {
  selected?: boolean;
}
