import { t } from '../../components/translate/translate.function';

type PinturaTranslations = Record<string, string | ((...args: unknown[]) => string)>;

const annotate = (): PinturaTranslations => ({
  annotateLabel: t('Annotate', { _context: 'image-editor' }),
});

const core = (): PinturaTranslations => ({
  labelReset: t('Reset', { _context: 'image-editor' }),
  labelDefault: t('Default', { _context: 'image-editor' }),
  labelAuto: t('Auto', { _context: 'image-editor' }),
  labelNone: t('None', { _context: 'image-editor' }),
  labelEdit: t('Edit', { _context: 'image-editor' }),
  labelClose: t('Close', { _context: 'image-editor' }),

  // defaults
  labelColor: t('Color', { _context: 'image-editor' }),
  labelWidth: t('Width', { _context: 'image-editor' }),
  labelSize: t('Size', { _context: 'image-editor' }),
  labelOffset: t('Offset', { _context: 'image-editor' }),
  labelAmount: t('Amount', { _context: 'image-editor' }),
  labelInset: t('Inset', { _context: 'image-editor' }),
  labelRadius: t('Radius', { _context: 'image-editor' }),

  // controls
  labelColorPalette: t('Color palette', { _context: 'image-editor' }),

  // sizes
  labelSizeExtraSmall: t('Extra small', { _context: 'image-editor' }),
  labelSizeSmall: t('Small', { _context: 'image-editor' }),
  labelSizeMediumSmall: t('Medium small', { _context: 'image-editor' }),
  labelSizeMedium: t('Medium', { _context: 'image-editor' }),
  labelSizeMediumLarge: t('Medium large', { _context: 'image-editor' }),
  labelSizeLarge: t('Large', { _context: 'image-editor' }),
  labelSizeExtraLarge: t('Extra large', { _context: 'image-editor' }),

  // default buttons

  labelButtonCancel: t('Cancel', { _context: 'image-editor' }),
  labelButtonUndo: t('Undo', { _context: 'image-editor' }),
  labelButtonRedo: t('Redo', { _context: 'image-editor' }),
  labelButtonRevert: t('Revert', { _context: 'image-editor' }),
  labelButtonExport: t('Done', { _context: 'image-editor' }),

  // zoom
  labelZoomIn: t('Zoom in', { _context: 'image-editor' }),
  labelZoomOut: t('Zoom out', { _context: 'image-editor' }),
  labelZoomFit: t('Fit to view', { _context: 'image-editor' }),
  labelZoomActual: t('Actual size', { _context: 'image-editor' }),

  statusLabelLoadImage: (state: { task: string; error: { code: string } }): string => {
    if (!state || !state.task) {
      return t('Waiting for image', { _context: 'image-editor' });
    }

    if (state.error) {
      return state.error.code === 'IMAGE_TOO_SMALL'
        ? t('Minimum image size is {minWidth} &times; {minHeight}', { _context: 'image-editor' })
        : t('Error loading image', { _context: 'image-editor' });
    }

    if (state.task === 'blob-to-bitmap') {
      return t('Preparing image&hellip;', { _context: 'image-editor' });
    }

    return t('Loading image&hellip;', { _context: 'image-editor' });
  },

  // processing status message
  statusLabelProcessImage: (state: { task: string; error: unknown }): string => {
    if (!state || !state.task) {
      return undefined;
    }

    if (state.task === 'store') {
      if (state.error) {
        return t('Error uploading image', { _context: 'image-editor' });
      }

      return t('Uploading image&hellip;', { _context: 'image-editor' });
    }

    if (state.error) {
      return t('Error processing image', { _context: 'image-editor' });
    }

    return t('Processing image&hellip;', { _context: 'image-editor' });
  },
});

const MarkupEditor = (): PinturaTranslations => ({
  shapeLabelButtonSelectSticker: t('Select image', { _context: 'image-editor' }),

  shapeTitleButtonTextLayoutAutoWidth: t('Auto width', { _context: 'image-editor' }),
  shapeTitleButtonTextLayoutAutoHeight: t('Auto height', { _context: 'image-editor' }),
  shapeTitleButtonTextLayoutFixedSize: t('Fixed size', { _context: 'image-editor' }),

  shapeTitleButtonFlipHorizontal: t('Flip Horizontal', { _context: 'image-editor' }),
  shapeTitleButtonFlipVertical: t('Flip Vertical', { _context: 'image-editor' }),
  shapeTitleButtonRemove: t('Remove', { _context: 'image-editor' }),
  shapeTitleButtonDuplicate: t('Duplicate', { _context: 'image-editor' }),
  shapeTitleButtonMoveToFront: t('Move to front', { _context: 'image-editor' }),

  shapeLabelInputText: t('Edit text', { _context: 'image-editor' }),

  shapeLabelInputCancel: t('Cancel', { _context: 'image-editor' }),
  shapeLabelInputConfirm: t('Confirm', { _context: 'image-editor' }),

  shapeLabelStrokeNone: t('No outline', { _context: 'image-editor' }),

  shapeLabelFontStyleNormal: t('Normal', { _context: 'image-editor' }),
  shapeLabelFontStyleBold: t('Bold', { _context: 'image-editor' }),
  shapeLabelFontStyleItalic: t('Italic', { _context: 'image-editor' }),
  shapeLabelFontStyleItalicBold: t('Bold Italic', { _context: 'image-editor' }),

  shapeTitleBackgroundColor: t('Fill color', { _context: 'image-editor' }),

  shapeTitleCornerRadius: t('Corner radius', { _context: 'image-editor' }),

  shapeTitleFontFamily: t('Font', { _context: 'image-editor' }),
  shapeTitleFontSize: t('Font size', { _context: 'image-editor' }),
  shapeTitleFontStyle: t('Font style', { _context: 'image-editor' }),
  shapeTitleLineHeight: t('Line height', { _context: 'image-editor' }),

  shapeTitleLineStart: t('Start', { _context: 'image-editor' }),
  shapeTitleLineEnd: t('End', { _context: 'image-editor' }),
  shapeTitleStrokeWidth: t('Line width', { _context: 'image-editor' }),
  shapeTitleStrokeColor: t('Line color', { _context: 'image-editor' }),

  shapeTitleLineDecorationBar: t('Bar', { _context: 'image-editor' }),
  shapeTitleLineDecorationCircle: t('Circle', { _context: 'image-editor' }),
  shapeTitleLineDecorationSquare: t('Square', { _context: 'image-editor' }),
  shapeTitleLineDecorationArrow: t('Arrow', { _context: 'image-editor' }),
  shapeTitleLineDecorationCircleSolid: t('Circle solid', { _context: 'image-editor' }),
  shapeTitleLineDecorationSquareSolid: t('Square solid', { _context: 'image-editor' }),
  shapeTitleLineDecorationArrowSolid: t('Arrow solid', { _context: 'image-editor' }),

  shapeTitleColorTransparent: t('Transparent', { _context: 'image-editor' }),
  shapeTitleColorWhite: t('White', { _context: 'image-editor' }),
  shapeTitleColorSilver: t('Silver', { _context: 'image-editor' }),
  shapeTitleColorGray: t('Gray', { _context: 'image-editor' }),
  shapeTitleColorBlack: t('Black', { _context: 'image-editor' }),
  shapeTitleColorNavy: t('Navy', { _context: 'image-editor' }),
  shapeTitleColorBlue: t('Blue', { _context: 'image-editor' }),
  shapeTitleColorAqua: t('Aqua', { _context: 'image-editor' }),
  shapeTitleColorTeal: t('Teal', { _context: 'image-editor' }),
  shapeTitleColorOlive: t('Olive', { _context: 'image-editor' }),
  shapeTitleColorGreen: t('Green', { _context: 'image-editor' }),
  shapeTitleColorYellow: t('Yellow', { _context: 'image-editor' }),
  shapeTitleColorOrange: t('Orange', { _context: 'image-editor' }),
  shapeTitleColorRed: t('Red', { _context: 'image-editor' }),
  shapeTitleColorMaroon: t('Maroon', { _context: 'image-editor' }),
  shapeTitleColorFuchsia: t('Fuchsia', { _context: 'image-editor' }),
  shapeTitleColorPurple: t('Purple', { _context: 'image-editor' }),

  shapeTitleTextColor: t('Font color', { _context: 'image-editor' }),
  shapeTitleTextAlign: t('Text align', { _context: 'image-editor' }),
  shapeTitleTextAlignLeft: t('Left align text', { _context: 'image-editor' }),
  shapeTitleTextAlignCenter: t('Center align text', { _context: 'image-editor' }),
  shapeTitleTextAlignRight: t('Right align text', { _context: 'image-editor' }),

  shapeLabelToolMove: t('Move', { _context: 'image-editor' }),
  shapeLabelToolView: t('View', { _context: 'image-editor' }),
  shapeLabelToolSharpie: t('Sharpie', { _context: 'image-editor' }),
  shapeLabelToolEraser: t('Eraser', { _context: 'image-editor' }),
  shapeLabelToolPath: t('Path', { _context: 'image-editor' }),
  shapeLabelToolRectangle: t('Rectangle', { _context: 'image-editor' }),
  shapeLabelToolEllipse: t('Ellipse', { _context: 'image-editor' }),
  shapeLabelToolArrow: t('Arrow', { _context: 'image-editor' }),
  shapeLabelToolLine: t('Line', { _context: 'image-editor' }),
  shapeLabelToolText: t('Text', { _context: 'image-editor' }),
  shapeLabelToolPreset: t('Stickers', { _context: 'image-editor' }),
});

const crop = (): PinturaTranslations => ({
  cropLabel: t('Crop', { _context: 'image-editor' }),

  cropLabelButtonRecenter: t('Recenter', { _context: 'image-editor' }),
  cropLabelButtonRotateLeft: t('Rotate left', { _context: 'image-editor' }),
  cropLabelButtonRotateRight: t('Rotate right', { _context: 'image-editor' }),
  cropLabelButtonFlipHorizontal: t('Flip horizontal', { _context: 'image-editor' }),
  cropLabelButtonFlipVertical: t('Flip vertical', { _context: 'image-editor' }),

  cropLabelSelectPreset: t('Crop shape', { _context: 'image-editor' }),

  cropLabelCropBoundary: t('Crop boundary', { _context: 'image-editor' }),
  cropLabelCropBoundaryEdge: t('Edge of image', { _context: 'image-editor' }),
  cropLabelCropBoundaryNone: t('None', { _context: 'image-editor' }),

  cropLabelTabRotation: t('Rotation', { _context: 'image-editor' }),
  cropLabelTabZoom: t('Zoom', { _context: 'image-editor' }),
});

const decorate = (): PinturaTranslations => ({
  decorateLabel: t('Decorate', { _context: 'image-editor' }),
});

const filter = (): PinturaTranslations => ({
  filterLabel: t('Filter', { _context: 'image-editor' }),
  filterLabelChrome: t('Chrome', { _context: 'image-editor' }),
  filterLabelFade: t('Fade', { _context: 'image-editor' }),
  filterLabelCold: t('Cold', { _context: 'image-editor' }),
  filterLabelWarm: t('Warm', { _context: 'image-editor' }),
  filterLabelPastel: t('Pastel', { _context: 'image-editor' }),
  filterLabelMonoDefault: t('Mono', { _context: 'image-editor' }),
  filterLabelMonoNoir: t('Noir', { _context: 'image-editor' }),
  filterLabelMonoWash: t('Wash', { _context: 'image-editor' }),
  filterLabelMonoStark: t('Stark', { _context: 'image-editor' }),
  filterLabelSepiaDefault: t('Sepia', { _context: 'image-editor' }),
  filterLabelSepiaBlues: t('Blues', { _context: 'image-editor' }),
  filterLabelSepiaRust: t('Rust', { _context: 'image-editor' }),
  filterLabelSepiaColor: t('Color', { _context: 'image-editor' }),
});

const finetune = (): PinturaTranslations => ({
  finetuneLabel: t('Finetune', { _context: 'image-editor' }),

  finetuneLabelBrightness: t('Brightness', { _context: 'image-editor' }),
  finetuneLabelContrast: t('Contrast', { _context: 'image-editor' }),
  finetuneLabelSaturation: t('Saturation', { _context: 'image-editor' }),
  finetuneLabelExposure: t('Exposure', { _context: 'image-editor' }),
  finetuneLabelTemperature: t('Temperature', { _context: 'image-editor' }),
  finetuneLabelGamma: t('Gamma', { _context: 'image-editor' }),
  finetuneLabelClarity: t('Clarity', { _context: 'image-editor' }),
  finetuneLabelVignette: t('Vignette', { _context: 'image-editor' }),
});

const frame = (): PinturaTranslations => ({
  frameLabel: t('Frame', { _context: 'image-editor' }),

  frameLabelMatSharp: t('Mat', { _context: 'image-editor' }),
  frameLabelMatRound: t('Bevel', { _context: 'image-editor' }),
  frameLabelLineSingle: t('Line', { _context: 'image-editor' }),
  frameLabelLineMultiple: t('Zebra', { _context: 'image-editor' }),
  frameLabelEdgeSeparate: t('Inset', { _context: 'image-editor' }),
  frameLabelEdgeOverlap: t('Plus', { _context: 'image-editor' }),
  frameLabelEdgeCross: t('Lumber', { _context: 'image-editor' }),
  frameLabelCornerHooks: t('Hook', { _context: 'image-editor' }),
  frameLabelPolaroid: t('Polaroid', { _context: 'image-editor' }),
});

const redact = (): PinturaTranslations => ({
  redactLabel: t('Redact', { _context: 'image-editor' }),
});

const resize = (): PinturaTranslations => ({
  resizeLabel: t('Resize', { _context: 'image-editor' }),

  resizeLabelFormCaption: t('Image output size', { _context: 'image-editor' }),

  resizeLabelInputWidth: t('w', { _context: 'image-editor' }),
  resizeTitleInputWidth: t('Width', { _context: 'image-editor' }),
  resizeLabelInputHeight: t('h', { _context: 'image-editor' }),
  resizeTitleInputHeight: t('Height', { _context: 'image-editor' }),

  resizeTitleButtonMaintainAspectRatio: t('Maintain aspectratio', { _context: 'image-editor' }),
});

const sticker = (): PinturaTranslations => ({
  stickerLabel: t('Sticker', { _context: 'image-editor' }),
});

const getPinturaLocale = (): PinturaTranslations => ({
  ...annotate(),
  ...crop(),
  ...core(),
  ...MarkupEditor(),
  ...decorate(),
  ...filter(),
  ...finetune(),
  ...frame(),
  ...redact(),
  ...resize(),
  ...sticker(),

  labelButtonExport: t('Save', { _context: 'image-editor' }),
});

export default getPinturaLocale;
