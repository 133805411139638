import { NgModule } from '@angular/core';

import { ListArrayPipe } from './list-array.pipe';

@NgModule({
  declarations: [ListArrayPipe],
  exports: [ListArrayPipe],
  imports: [],
  providers: [],
})
export class ListArrayPipeModule {}
