import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { isPercentIncrease } from 'projects/apex/src/app/utils/math';
import { Meter, MeterValue } from './meter.model';

interface MeterValueTableData extends MeterValue {
  isError: boolean;
  isWarning: boolean;
}

@Component({
  selector: 'apex-meter-values',
  templateUrl: './value-list.component.html',
})
export class MeterValuesComponent implements OnChanges {
  @Input() meter: Meter;
  @Input() values: MeterValue[];

  displayedColumns: string[] = ['user', 'value', 'date'];
  dataSource = new MatTableDataSource<MeterValueTableData>();

  ngOnChanges(change: SimpleChanges): void {
    if (change.meter && this.meter?.Values) {
      this.dataSource.data = this.buildData();
    }

    if (change.values && this.values) {
      this.dataSource.data = this.buildData();
    }
  }

  getName(data: MeterValue): string {
    return data.Creator?.name;
  }

  private buildData(): MeterValueTableData[] {
    return this.meter.Values.map((v, i) => ({
      ...v,
      isError: this.isError(v, i),
      isWarning: this.isWarning(v, i),
    }));
  }

  private isError(meterValue: MeterValue, index: number): boolean {
    return isPercentIncrease(meterValue.value, this.meter.Values[index + 1]?.value, this.meter.error);
  }

  private isWarning(meterValue: MeterValue, index: number): boolean {
    return isPercentIncrease(meterValue.value, this.meter.Values[index + 1]?.value, this.meter.warning);
  }
}
