import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { MatTooltipModule } from '@angular/material/tooltip';
import { TextClampComponent } from '../text-clamp/text-clamp.component';
import { SubtitleItemComponent } from './subtitle-item.component';
import { TitleItemComponent } from './title-item.component';

@NgModule({
  declarations: [TitleItemComponent, SubtitleItemComponent],
  exports: [TitleItemComponent, SubtitleItemComponent],
  imports: [CommonModule, MatIconModule, MatTooltipModule, TextClampComponent],
  providers: [],
})
export class ItemModule {}
