<apex-box
  class="no-padding"
  icon="checklist"
  [loading]="loading">
  <ng-container title>
    {{ checklist?.name }}
  </ng-container>

  <div
    right
    class="df ac">
    <apex-progress
      class="bg-color-main"
      [count]="checklistItems.length"
      [completed]="completedCases"
      [small]="true">
    </apex-progress>

    <button
      type="button"
      mat-icon-button
      [disabled]="loading"
      [matTooltip]="'Go to checklist' | translate"
      (click)="navigateToChecklist($event)"
      [routerLink]="['/checklist', checklist.id]">
      <mat-icon>launch</mat-icon>
    </button>
  </div>

  <section
    class="df c set-max-height scrollable-vertical"
    style="--max-height: 270px">
    <ng-container *ngIf="!checklistItems?.length">
      <div class="ams">
        <T str="No checklist items to show"></T>
      </div>
    </ng-container>

    <ng-container *ngIf="checklistItems.length">
      <apex-checklist-section
        class=""
        [checklist]="checklist"
        [checklistItems]="checklistItems"
        [checklistSections]="checklistSections">
      </apex-checklist-section>
    </ng-container>
  </section>
</apex-box>
