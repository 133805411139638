import { CdkDrag, CdkDragDrop, CdkDragPlaceholder, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseSidebarItemPinnedDto } from '../../../../../../generated/apex-rest';
import { environment } from '../../../environments/environment';
import { Profile } from '../../models/profile';
import { UserService } from '../../services/user/user.service';
import { SidebarItemComponent } from '../sidebar-item/sidebar-item.component';
import { FilterSidebarItemsPipe } from '../sidebar/pipes/filter-sidebar-items.pipe';
import { SidebarItemAccessPipe } from '../sidebar/pipes/sidebar-item-access.pipe';
import { SortSidebarItemsPipe } from '../sidebar/pipes/sort-sidebar-items.pipe';
import { SidebarService } from '../sidebar/services/sidebar.service';
import { staticSidebarItems } from '../sidebar/sidebar-items';
import { SidebarItem } from '../sidebar/sidebar-items.types';
import { TranslateModule } from '../translate/translate.module';

@Component({
  selector: 'apex-sidebar-items',
  templateUrl: './sidebar-items.component.html',
  styleUrls: ['./sidebar-items.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    RouterLink,
    RouterLinkActive,
    FilterSidebarItemsPipe,
    SidebarItemAccessPipe,
    MatExpansionModule,
    SidebarItemComponent,
    CdkDropList,
    CdkDragPlaceholder,
    CdkDrag,
    SortSidebarItemsPipe,
  ],
})
export class SidebarItemsComponent implements OnInit {
  @Input() profile: Profile;

  // static array of sidebar items that can be pinned
  staticSidebarItems: SidebarItem[] = staticSidebarItems;
  sidebarExpansionPanelState = false;

  signOutUrl = `${environment.api}/user/auth/sign-out?destination=app`;

  constructor(
    private userService: UserService,
    protected readonly sidebarService: SidebarService,
  ) {}

  ngOnInit(): void {
    this.sidebarExpansionPanelState = this.sidebarService.getSidebarExpansionState();

    // async function to eventually fetch the sidebarItemsPinned from the backend
    void this.sidebarService.readCollection();
  }

  drop(event: CdkDragDrop<ResponseSidebarItemPinnedDto[]>): void {
    // Make a copy to not mutate the original array, need it for name and url for bookmarks after drop action
    const responseSidebarItemPinnedDtos = this.sidebarService.sidebarItemsPinned;

    // Move item in array using cdkDropListDropped event
    moveItemInArray(responseSidebarItemPinnedDtos, event.previousIndex, event.currentIndex);

    // creates an array of observables from the sorted array of sidebarItems
    forkJoin<ResponseSidebarItemPinnedDto[]>(
      responseSidebarItemPinnedDtos.map((sidebarItem, index: number) =>
        this.sidebarService.updateSortOrder(sidebarItem.id, { ...sidebarItem, sortOrder: index }),
      ),
    )
      .pipe(
        map((sortedResponseSidebarPinnedDtos) =>
          // Add name and url to the sorted items from the original array -> responseSidebarItemPinnedDtos
          sortedResponseSidebarPinnedDtos.map((sortedSidebarItem) => {
            const responseSidebarItemPinnedDto = responseSidebarItemPinnedDtos.find(
              (sidebarItem) => sidebarItem.id === sortedSidebarItem.id,
            );

            if (responseSidebarItemPinnedDto) {
              sortedSidebarItem.name = responseSidebarItemPinnedDto.name;
              sortedSidebarItem.url = responseSidebarItemPinnedDto.url;
            }

            return sortedSidebarItem;
          }),
        ),
      )
      .subscribe((sortedItems) => {
        this.sidebarService.sidebarItemsPinned = sortedItems;
      });
  }

  toggleSidebarExpansionPanel(): void {
    this.sidebarExpansionPanelState = !this.sidebarExpansionPanelState;

    this.sidebarService.setSidebarExpansionState(this.sidebarExpansionPanelState);
  }

  signOut(): void {
    this.userService.clearProfile();
  }
}
