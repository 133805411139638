import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '../translate/translate.module';

import { TranslatorComponent } from './translator.component';

@NgModule({
  declarations: [TranslatorComponent],
  exports: [TranslatorComponent],
  imports: [CommonModule, MatIconModule, MatRippleModule, MatButtonModule, TranslateModule],
  providers: [],
})
export class TranslatorModule {}
