<ng-container *ngIf="!isApp">
  <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="isApp">
  <apex-topbar [drawer]="drawer"> </apex-topbar>

  <mat-drawer-container class="df c f1 oh print-scroll-visible">
    <mat-drawer
      #drawer
      [mode]="mode$ | async">
      <apex-sidebar [drawer]="drawer"></apex-sidebar>
    </mat-drawer>
    <div
      class="scrollable f1 df c h-100 print-scroll-visible"
      cdkScrollable>
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>
</ng-container>

<apex-upload></apex-upload>
