import { Case as APEXCase } from './case';
import { FileUsage } from './file-usage';
import { Model } from './model';

export interface GEOMETRY {
  type: string;
  coordinates: [number, number];
  properties?: {
    [key: string]: unknown;
    imageWidth: number;
    imageHeight: number;
  };
}

export enum MarkingModelType {
  AddonApartment = 'addon-apartment',
  Case = 'case',
}

type MarkingData = {
  imageWidth?: number;
  imageHeight?: number;
};

export class Marking extends Model<Marking> {
  description: string;
  icon: string;
  modelId: number;
  model: MarkingModelType;
  geometry: GEOMETRY;
  CreatorId: number;

  FileUsageId: number;
  FileUsage?: FileUsage;

  correct: boolean;
  data: MarkingData;

  Case?: APEXCase;
}
