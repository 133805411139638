import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutocompleteModule } from '../../../components/autocomplete/autocomplete.module';
import { FileUsageModule } from '../../../components/file-usage/file-usage.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { AutoFocusDirectiveModule } from '../../../directives/auto-focus/auto-focus..directive.module';
import { InViewDirectiveModule } from '../../../directives/in-view/in-view.directive.module';
import { SizeDirectiveModule } from '../../../directives/size/size.module';
import { CaseNewMessageModule } from '../../case/new-message/new-message.module';
import { MeterValueModule } from '../../object/project/meter/value-form.module';
import { ChecklistItemService, ChecklistService } from '../checklist.service';
import { DeleteChecklistItemDialogComponent } from './delete-item-dialog.component';
import { DeviationDialogModule } from './deviation/module';
import { ChecklistItemComponent } from './item.component';
import { ChecklistItemListComponent } from './list.component';
import { NewChecklistItemDialogComponent } from './new-item-dialog.component';

@NgModule({
  declarations: [
    ChecklistItemComponent,
    ChecklistItemListComponent,
    DeleteChecklistItemDialogComponent,
    NewChecklistItemDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,

    DeviationDialogModule,

    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatProgressBarModule,
    MatRippleModule,
    MatBadgeModule,

    OverlayModule,

    FileUsageModule,

    CaseNewMessageModule,
    AutocompleteModule,
    TranslateModule,
    InViewDirectiveModule,
    AutoFocusDirectiveModule,
    MeterValueModule,

    SizeDirectiveModule,
  ],
  providers: [ChecklistService, ChecklistItemService],
  exports: [
    ChecklistItemComponent,
    ChecklistItemListComponent,
    DeleteChecklistItemDialogComponent,
    NewChecklistItemDialogComponent,
  ],
})
export class ChecklistItemModule {}
