import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ResponseSidebarItemPinnedDto, SidebarModel } from '../../../../../../generated/apex-rest';
import { Bookmark } from '../../features/bookmark/bookmark.model';
import { BookmarkPinnedOrNotPipe } from '../../features/bookmark/pipes/pinned-bookmark.pipe';
import { SidebarService } from '../sidebar/services/sidebar.service';
import { TranslateModule } from '../translate/translate.module';

@Component({
  selector: 'apex-bookmark-pin-buttons',
  templateUrl: './bookmark-pin-buttons.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatTooltipModule, MatIconModule, BookmarkPinnedOrNotPipe, MatButtonModule],
  providers: [],
})
export class BookmarkPinButtonsComponent {
  @Input() bookmark: Bookmark;
  @Input() pinnedBookmarks: ResponseSidebarItemPinnedDto[];

  constructor(private sidebarService: SidebarService) {}

  async addPinSidebarItem(bookmark: Bookmark): Promise<void> {
    const created = await this.sidebarService.create(String(bookmark.id), SidebarModel.Bookmark);

    this.pinnedBookmarks = [...this.pinnedBookmarks, created];

    await this.sidebarService.readCollection();
  }

  async removePinSidebarItem(bookmark: Bookmark): Promise<void> {
    const id = this.pinnedBookmarks.find((sidebarItemPin) => sidebarItemPin.modelId === String(bookmark.id))?.id;

    await this.sidebarService.delete(String(id));

    this.pinnedBookmarks = this.pinnedBookmarks.filter(
      (sidebarItemPin) => sidebarItemPin.modelId !== String(bookmark.id),
    );

    await this.sidebarService.readCollection();
  }
}
