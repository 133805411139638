import { Pipe, PipeTransform } from '@angular/core';
import { TranslateTypes, tType } from '../translate.function';

@Pipe({
  name: 'translateType',
})
export class TranslateTypePipe implements PipeTransform {
  transform(type: TranslateTypes): string {
    return tType(type);
  }
}
