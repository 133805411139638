import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep, flatten, isEqual, pick, remove } from 'lodash-es';
import { ClientPageService } from 'projects/apex/src/app/features/client-page/client-page.service';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { Marking } from 'projects/apex/src/app/models/marking';
import { MarkingService } from 'projects/apex/src/app/services/marking/marking.service';
import { Observable, Subscription, concat } from 'rxjs';
import { snackErr } from '../../modules/snack.module';
import { AutocompleteTypes } from '../autocomplete/autocomplete.types';
import { t } from '../translate/translate.function';
import { FileUsageViewerData } from './file-usage-viewer.types';

@Component({
  selector: 'apex-file-usage-viewer-dialog',
  templateUrl: './file-usage-viewer-dialog.component.html',
})
export class FileUsageViewerDialogComponent implements OnInit {
  originalFileUsages: FileUsage[];
  originalMarkings: Marking[];

  saveDisabled: boolean;
  deleteDisabled: boolean;

  deleteAllSub: Subscription;

  loading = false;
  loadingProgress = 0;

  constructor(
    @Optional() private markingService: MarkingService,
    @Optional() private clientService: ClientPageService,
    @Inject(MAT_DIALOG_DATA) public data: FileUsageViewerData,
    public ref: MatDialogRef<FileUsageViewerDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.data.saveDisabled = true;
    this.originalFileUsages = this.data.fileUsages;
    this.data.fileUsages = cloneDeep(this.data.fileUsages);
    this.ref?.addPanelClass(['apex-fullscreen-dialog', 'tablet', 'phone']);
    this.setSaveStatus();
  }

  submit(): void {
    this.setSaveStatus();

    if (!this.saveDisabled) {
      if (this.data?.modelData?.model && this.data?.modelData?.modelId) {
        this.ref.disableClose = true;

        let markingToDelete = [];

        if (this.originalMarkings?.length) {
          markingToDelete = this.originalMarkings
            .filter((m) => !this.getAllMarkings().find((mm) => mm.id === m.id))
            .map((m) =>
              this.delete(
                this.data.fileUsages.find((f) => f.id === m.FileUsageId),
                m,
              ),
            );
        }

        const markingToSave = [].concat(
          ...this.data.fileUsages.map((f) =>
            f.Markings
              ? f.Markings.filter((m) => {
                  const oldMarking = this.originalMarkings?.find((mm) => mm.id === m.id);

                  return oldMarking ? !isEqual(m, oldMarking) : true;
                }).map((m) => this.save(f, m))
              : [],
          ),
        );

        const markingRequests = [].concat(markingToDelete, markingToSave);

        if (markingRequests?.length) {
          const markings: Marking[] = [];
          const markingsToRemove: Marking[] = [];

          concat(...markingRequests).subscribe({
            next: (marking: Marking) => {
              if (!marking.deletedAt) {
                markings.push(marking);
              } else {
                markingsToRemove.push(marking);
              }

              this.loadingProgress += (1 / markingRequests.length) * 100;
            },
            error: (err: Error) => {
              snackErr(t('Could not save'), err);
            },
            complete: () => {
              this.loadingProgress = 100;

              this.data.fileUsages.forEach((f) => {
                const addMarkings = markings.filter((m) => m.FileUsageId === f.id);
                const removeMarkings = markingsToRemove.filter((m) => m.FileUsageId === f.id);

                remove(f.Markings, (m) => removeMarkings.findIndex((rm) => rm.FileUsageId === m.id) !== -1 || !m.id);

                addMarkings.forEach((addMarking) => {
                  if (f.Markings.findIndex((marking) => marking.id === addMarking.id) === -1) {
                    f.Markings.push(addMarking);
                  }
                });
              });

              Object.assign(this.originalFileUsages, this.data.fileUsages);

              this.ref.close({
                valid: true,
                comment: this.data.fileUsageViewerComment,
                markings: this.getAllMarkings(),
              });
            },
          });
        } else {
          this.ref.close({
            valid: true,
            comment: this.data.fileUsageViewerComment,
            markings: this.getAllMarkings(),
          });
        }
      } else {
        Object.assign(this.originalFileUsages, this.data.fileUsages);
        this.ref.close({
          valid: true,
          comment: this.data.fileUsageViewerComment,
          markings: this.getAllMarkings(),
        });
      }
    }
  }

  setSaveStatus(): void {
    if (this.data.markingData && this.data.mode === 'mark') {
      this.saveDisabled =
        this.getMarkingsCount() < this.data.markingData.min
          ? true
          : !!(this.data.markingData.max !== 0 && this.getMarkingsCount() > this.data.markingData.max);
    }
  }

  getMarkingsCount(): number {
    let count = 0;

    if (this.data.fileUsages) {
      this.data.fileUsages.forEach((fileUsage) => {
        count += fileUsage.Markings ? fileUsage.Markings.length : 0;
      });
    }

    return count;
  }

  getAllMarkings(): Marking[] {
    const markings = this.data.fileUsages
      .filter((fileUsage) => !!fileUsage.Markings)
      .map((fileUsage) => fileUsage.Markings);

    return flatten(markings);
  }

  setOriginalMarkings(): void {
    if (!this.originalMarkings) {
      this.originalMarkings = cloneDeep(this.getAllMarkings());
    }
  }

  deleteAllMarkings(): void {
    if (this.data.editable && this.data.fileUsages?.length) {
      this.data.fileUsages.forEach((f) => {
        f.Markings = [];
      });
    }
  }

  save(fileUsage: FileUsage, marking: Marking): Observable<Marking> {
    const m = pick(marking, [
      'id',
      'description',
      'icon',
      'modelId',
      'model',
      'geometry',
      'CreatorId',
      'FileUsageId',
      'correct',
      'data',
    ]) as Marking;

    if (this.data.client) {
      return this.clientService.saveMarking(fileUsage, m);
    } else {
      return this.markingService.saveMarking(fileUsage, m);
    }
  }

  delete(fileUsage: FileUsage, marking: Marking): Observable<Marking> {
    if (this.data.client) {
      return this.clientService.deleteMarking(fileUsage, marking);
    } else {
      return this.markingService.deleteMarking(fileUsage, marking);
    }
  }

  selfToAutocompleteType(self: string): AutocompleteTypes {
    return self as AutocompleteTypes;
  }
}
