import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { UserFavorite } from '../../models/user-favorite';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'apex-user-favorite-button',
  templateUrl: 'user-favorite-button.component.html',
})
export class UserFavoriteButtonComponent implements OnInit, OnDestroy {
  @Input() modelName: string;
  @Input() modelId: number;

  favorites: UserFavorite[] = [];

  private subscriptions: Subscription = new Subscription();

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.userService.filteredProfile$.subscribe((p) => {
        this.favorites = p?.UserFavorites ?? [];
      }),
    );

    this.subscriptions.add(
      this.userService.userFavorites$.subscribe((favorites) => {
        this.favorites = favorites?.filter((favorite) => favorite.modelName === this.modelName) ?? [];
      }),
    );

    if (!this.modelName || !this.modelId) {
      throw new Error(`modelName (${this.modelName}) or modelId (${this.modelId}) is invalid`);
    }

    if (!this.favorites) {
      throw new Error('Profile is not to be found');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async toggle($event: Event): Promise<void> {
    $event.stopPropagation();

    const on = this.favorites.some((f) => f.modelName === this.modelName && f.modelId === this.modelId);

    this.userService.favorite(this.modelName, this.modelId, on).subscribe({
      next: (uf: UserFavorite) => {
        if (!on) {
          this.favorites = [...this.favorites, uf];
        } else {
          const idx = this.favorites.indexOf(
            this.favorites.find((f) => f.modelName === this.modelName && f.modelId === this.modelId),
          );

          if (idx !== -1) {
            this.favorites.splice(idx, 1);
          }

          this.favorites = [...this.favorites];
        }
      },
    });
  }
}
