import { Injectable } from '@angular/core';
import {
  ConsentApi,
  CreateCompanyConsentCompanyContactDto,
  CreateCompanyConsentCompanyDto,
  CreateCompanyConsentDto,
  ResponseCompanyConsentCollectionItemDtoPageResponse,
  ResponseCompanyConsentCompanyConsentDto,
  ResponseCompanyConsentCompanyContactDto,
  ResponseCompanyConsentCompanyDto,
  ResponseCompanyConsentCompanyDtoPageResponse,
  ResponseCompanyConsentDto,
  ResponseCompanyConsentIDNDto,
  UpdateCompanyConsentCompanyDto,
} from '../../../../../../generated/apex-rest';
import { restAxiosConfig } from '../../utils/rest-axios-config';

@Injectable()
export class ConsentService {
  consentApi = new ConsentApi(restAxiosConfig());

  async createConsent(consent: CreateCompanyConsentDto): Promise<ResponseCompanyConsentDto> {
    if (!consent) {
      throw new Error('Consent is required');
    }

    const response = await this.consentApi.companyConsentControllerCreate(consent);

    return response.data?.Entity;
  }

  async readConsent(consentId: string): Promise<ResponseCompanyConsentDto> {
    if (!consentId) {
      throw new Error('ConsentId is required');
    }

    const response = await this.consentApi.companyConsentControllerRead(consentId);

    return response.data?.Entity;
  }

  async readConsents(
    orderBy?: 'id' | 'name' | 'createdAt' | 'updatedAt',
    order?: 'ASC' | 'DESC',
    page?: number,
    limit?: number,
    search?: string,
  ): Promise<ResponseCompanyConsentCollectionItemDtoPageResponse> {
    const response = await this.consentApi.companyConsentControllerReadCollection(page, limit, order, orderBy, search);

    return response.data;
  }

  async deleteConsent(consentId: string): Promise<boolean> {
    if (!consentId) {
      throw new Error('ConsentId is required');
    }

    const response = await this.consentApi.companyConsentControllerDelete(consentId);

    return response.data.deleted;
  }

  async createConsentCompany(
    consentId: string,
    consentCompany: CreateCompanyConsentCompanyDto,
  ): Promise<ResponseCompanyConsentCompanyDto> {
    const response = await this.consentApi.companyConsentCompanyControllerCreate(consentId, consentCompany);

    return response.data.Entity;
  }

  async updateConsentCompany(
    consentId: string,
    companyId: number,
    consentCompany: UpdateCompanyConsentCompanyDto,
  ): Promise<ResponseCompanyConsentCompanyDto> {
    const response = await this.consentApi.companyConsentCompanyControllerUpdate(consentId, companyId, consentCompany);

    return response.data.Entity;
  }

  async deleteConsentCompany(consentId: string, companyId: number): Promise<boolean> {
    const response = await this.consentApi.companyConsentCompanyControllerDelete(consentId, companyId);

    return response.data.deleted;
  }

  async readConsentCompanies(
    consentId: string,
    orderBy?: 'id' | 'name' | 'createdAt' | 'updatedAt',
    order?: 'ASC' | 'DESC',
    page?: number,
    limit?: number,
    search?: string,
  ): Promise<ResponseCompanyConsentCompanyDtoPageResponse> {
    const response = await this.consentApi.companyConsentCompanyControllerReadCollection(
      consentId,
      page,
      limit,
      order,
      orderBy,
      search,
    );

    return response.data;
  }

  async createConsentCompanyContact(
    consentId: string,
    companyId: number,
    contact: CreateCompanyConsentCompanyContactDto,
  ): Promise<ResponseCompanyConsentCompanyContactDto> {
    const response = await this.consentApi.companyConsentCompanyContactControllerCreate(consentId, companyId, contact);

    return response.data.Entity;
  }

  async deleteConsentCompanyContact(consentId: string, companyId: number, contactId: number): Promise<boolean> {
    const response = await this.consentApi.companyConsentCompanyContactControllerDelete(
      consentId,
      companyId,
      contactId,
    );

    return response.data.deleted;
  }

  async consentToConsent(
    ConsentId: string,
    CompanyId: number,
    approved: true,
  ): Promise<ResponseCompanyConsentCompanyConsentDto> {
    const response = await this.consentApi.companyConsentCompanyConsentControllerCreate(ConsentId, CompanyId, {
      approved,
    });

    return response.data.Entity;
  }

  async withdrawConsentToConsent(ConsentId: string, CompanyId: number): Promise<boolean> {
    const response = await this.consentApi.companyConsentCompanyConsentControllerDelete(ConsentId, CompanyId);

    return response.data.deleted;
  }

  async isConsentCompanyApproved(ConsentId: string, CompanyId: number): Promise<boolean> {
    const response = await this.consentApi.companyConsentCompanyControllerApproved(ConsentId, CompanyId);

    return response.data.approved;
  }

  async readMissingUserConsent(): Promise<ResponseCompanyConsentIDNDto[]> {
    const response = await this.consentApi.companyConsentControllerReadMissingUserConsent();

    return response.data.Collection;
  }
}
