import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioGroup } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'projects/apex/src/app/components/confirm-dialog/confirm-dialog.component';
import { FileUsageComponent } from 'projects/apex/src/app/components/file-usage/file-usage.component';
import { ModelRoleComponent } from 'projects/apex/src/app/components/model-role/model-role.component';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { TenancyService } from 'projects/apex/src/app/features/object/project/tenancy/tenancy.service';
import { Tenancy } from 'projects/apex/src/app/models/tenancy';
import { snack, snackErr } from 'projects/apex/src/app/modules/snack.module';
import { Subscription, forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { MetaType, Obj } from '../../object.model';
import { Room } from '../project.model';

@Component({
  selector: 'apex-object-project-tenancy',
  templateUrl: './tenancy.component.html',
})
export class TenancyComponent implements OnInit, OnDestroy {
  @Input() edit = false;

  @ViewChild(ModelRoleComponent) modelRoleComponent: ModelRoleComponent;
  @ViewChild('fileUsage') fileUsage: FileUsageComponent;
  @ViewChild('fileUsagePublic') fileUsagePublic: FileUsageComponent;
  @ViewChild('tenancyType') tenancyType: MatRadioGroup;

  tenancy: Tenancy;

  hasFocus = false;

  objects: Room[];
  filteredObjects: Room[];

  objectIds: number[];

  ParentId: number = this.route.parent.parent.snapshot.params.pid;

  MetaType = MetaType;

  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private service: TenancyService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    const sub = this.route.data.subscribe({
      next: (data) => {
        this.tenancy = data.tenancy;

        this.objectIds = this.tenancy?.Objects ? this.tenancy.Objects.map((o) => o.id) : [];
      },
    });

    this.subscriptions.push(sub);
    this.edit = !!this.router.url.includes('edit');
  }

  onSubmit(): void {
    this.tenancy.Objects = this.objectIds.map((id) => ({ id }) as Obj);
    this.tenancy.active = this.tenancy.active ?? false;
    this.tenancy.active = !!this.tenancy.active;

    const sub = this.service
      .save(this.tenancy)
      .pipe(
        map((res) => res.Entity),
        mergeMap((tenancy) => {
          if (this.tenancy.id) {
            return of(tenancy);
          }

          this.fileUsage.selfId = tenancy.id;
          this.fileUsagePublic.selfId = tenancy.id;
          this.modelRoleComponent.modelId = tenancy.id;

          return forkJoin([
            this.fileUsage.saveAll(),
            this.fileUsagePublic.saveAll(),
            this.modelRoleComponent.saveAll(),
          ]).pipe(map(() => tenancy));
        }),
      )
      .subscribe({
        next: (tenancy) => {
          snack(t('Saved'));
          void this.router.navigate(['object', 'project', this.ParentId, 'tenancy', tenancy.id]);
        },
        error: (err) => {
          snackErr(t('Could not save'), err);
        },
      });

    this.subscriptions.push(sub);
  }

  remove(id: number): void {
    const idx = this.tenancy.Objects.map((o) => o.id).indexOf(id);

    if (idx !== -1) {
      this.tenancy.Objects.splice(idx, 1);
    }
  }

  filterObjects(objName: string): Room[] {
    return this.objects.filter((o) => o.name.toLowerCase().includes(objName));
  }

  back(): void {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }

  delete(): void {
    this.subscriptions.push(
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            text: t('Are you sure you want to delete {name} and all children?', {
              name: this.tenancy?.name,
            }),
          },
        })
        .afterClosed()
        .subscribe((should) => {
          if (should) {
            this.subscriptions.push(
              this.service.delete(this.tenancy.id).subscribe((_) => {
                void this.router.navigate(['object', 'project', this.ParentId, 'tenancy']);
              }),
            );
          }
        }),
    );
  }

  addEntryCard(): void {
    void this.router.navigate(['entry-card', 'new'], {
      relativeTo: this.route,
    });
  }
}
