import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'lodash-es';
import { addParentToModelName } from 'projects/apex/src/app/components/autocomplete/autocomplete.component';
import { AutocompleteService } from 'projects/apex/src/app/components/autocomplete/autocomplete.service';
import {
  AutocompleteString,
  AutocompleteTypes,
} from 'projects/apex/src/app/components/autocomplete/autocomplete.types';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import {
  booleanFromBooleanOrString,
  favoriteAvailable,
  firstCharIsLowerCase,
} from 'projects/apex/src/app/utils/functions';
import { Observable, of, Subject, timer } from 'rxjs';
import { catchError, debounce, map, switchMap, tap } from 'rxjs/operators';
import { Autocomplete } from '../../../models/autocomplete';
import { UserFavorite } from '../../../models/user-favorite';
import { constants } from '../../../utils/constants';

@Component({
  selector: 'apex-autocomplete-text',
  templateUrl: './text.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteTextComponent),
      multi: true,
    },
  ],
})
export class AutocompleteTextComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() label: string;
  @Input() hint: string;
  @Input() disabled: boolean;
  @Input() models: Autocomplete[];

  @Output() textChange = new EventEmitter();
  @Output() modelChange = new EventEmitter();

  @Input()
  get text(): string {
    return this.textValue;
  }

  set text(text: string) {
    this.setValue(text);
  }

  @Input()
  get type(): AutocompleteTypes | AutocompleteString {
    return this.innerType;
  }

  set type(t: AutocompleteTypes | AutocompleteString) {
    if (firstCharIsLowerCase(t)) {
      this.innerType = t as AutocompleteTypes;

      return;
    }

    this.innerType = AutocompleteTypes[t];
  }

  @Input()
  get required(): boolean | string {
    return this.innerRequired;
  }

  set required(value: boolean | string) {
    this.innerRequired = booleanFromBooleanOrString(value);
  }

  queryRequest$ = new Subject<string>();
  queryResponse$: Observable<Autocomplete[]> = this.queryRequest$.pipe(
    tap(() => {
      this.models = [];
      this.loading = true;
    }),
    debounce((q: string | Autocomplete) => {
      if (!q && this.favoriteAvailable) {
        return of(null);
      }

      return timer(constants.inputDebounceTime);
    }),
    switchMap((q: string) => {
      if (!q && this.favoriteAvailable) {
        return this.userService.favorites$(this.type).pipe(
          map((favs: UserFavorite[]) =>
            favs.map((f) => ({
              id: f.modelId,
              name: f.name,
              Parent: null,
            })),
          ),
        );
      }

      return this.service
        .queryString(this.innerType, new HttpParams().set('q', q || '*'))
        .pipe(catchError(() => of([])));
    }),
  );

  sub = this.queryResponse$.subscribe({
    next: (models: Autocomplete[]) => {
      this.models = models;
      this.loading = false;
    },
  });

  focus: boolean;
  loading: boolean;
  favoriteAvailable: boolean;

  textValue: string;

  onChange: (text: string) => void = noop;
  onTouch: (text: string) => void = noop;

  private innerRequired = false;
  private innerType: AutocompleteTypes;

  constructor(
    private service: AutocompleteService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.favoriteAvailable = favoriteAvailable(this.type);
  }

  setValue(text: string): void {
    this.textValue = text;
    this.onChange(text);
    this.onTouch(text);
    this.textChange.emit(text);

    if (this.focus) {
      this.queryRequest$.next(text);
    }
  }

  writeValue(text: string): void {
    this.textValue = text;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  displayModel(model?: Autocomplete): string | undefined {
    return model ? this.getModelName(model) : '';
  }

  getModelName(model: Autocomplete): string {
    return this.addParentToModelName(model.name, model);
  }

  addParentToModelName(name: string, model: Autocomplete): string {
    return addParentToModelName(name, model);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
