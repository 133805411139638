<div
  mat-dialog-content
  class="df">
  <apex-file-usage
    #fileUsage
    class="f1"
    [self]="self"
    [selfId]="selfId"
    [name]="name"
    [title]="title"
    [splitPdf]="splitPdf"
    view="Grid">
  </apex-file-usage>
</div>
<div
  mat-dialog-actions
  class="df">
  <div></div>
  <button
    mat-raised-button
    (click)="confirm()"
    color="primary">
    <T str="Ok"></T>
  </button>
</div>
