export abstract class BaseModel<T> {
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;

  abstract id: number | string;

  constructor(model?: Partial<T>) {
    Object.assign(this, model);
  }
}

/**
 * Represents a model that has INTEGER (number) as ID.
 */
export class Model<T> extends BaseModel<T> {
  id: number;
}

/**
 * Represents a model that has UUID (string) as ID.
 */
export class UUIDModel<T> extends BaseModel<T> {
  id: string;
}
