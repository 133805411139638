import { Pipe, PipeTransform } from '@angular/core';
import { isNil, upperFirst } from 'lodash-es';
import { environment } from '../../../../environments/environment';

type ObjectWithInspectionValues = {
  projectId?: string | number | null;
  apartmentId?: string | number | null;
  objectId?: string | number | null;
};

@Pipe({
  name: 'inspectionLink',
})
export default class InspectionLinkPipe implements PipeTransform {
  transform(obj: ObjectWithInspectionValues): string {
    const url = new URL(environment.inspectionUrl);

    ['projectId', 'apartmentId', 'objectId'].forEach((k) => {
      if (!isNil(obj[k])) {
        url.searchParams.set(upperFirst(k).replace('Id', ''), obj[k]);
      }
    });

    return url.toString();
  }
}
