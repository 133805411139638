<div class="filters">
  <div class="cases-filter-shortcuts am">
    <div class="df ac w f1">
      <mat-button-toggle-group
        multiple
        hideMultipleSelectionIndicator="true"
        hideSingleSelectionIndicator="true">
        <mat-button-toggle
          [checked]="queryParams?.showOpen"
          [matTooltip]="'Open' | translate"
          (click)="updateQueryParams('showOpen', !queryParams?.showOpen)">
          <mat-icon>panorama_fish_eye</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          [checked]="queryParams?.showCompleted"
          [matTooltip]="'Done' | translate"
          (click)="updateQueryParams('showCompleted', !queryParams?.showCompleted)">
          <mat-icon>check_circle_outline</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          [checked]="queryParams?.showCaseManagers"
          [matTooltip]="'All case managers' | translate"
          (click)="updateQueryParams('showCaseManagers', !queryParams?.showCaseManagers)">
          <mat-icon svgIcon="casemanager"></mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          [checked]="queryParams?.onlyUnread"
          [matTooltip]="'Only unread' | translate"
          (click)="updateQueryParams('onlyUnread', !queryParams?.onlyUnread)">
          <mat-icon>priority_high</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          [checked]="queryParams?.overdue"
          [matTooltip]="'Overdue' | translate"
          (click)="updateQueryParams('overdue', !queryParams?.overdue)">
          <mat-icon>alarm</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle>
          <ng-container *ngIf="commercial">
            <apex-object-gps-search-button
              class="disabled-color line-height-1 gps-toggle-button-color-black"
              (found)="foundGPS($event.id)">
            </apex-object-gps-search-button>
          </ng-container>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <div class="f1"></div>

      <div class="df ac">
        <button
          mat-button
          color="primary"
          (click)="markCasesAsRead()">
          <T str="Mark cases read"></T>
        </button>

        <button
          type="button"
          mat-icon-button
          [matTooltip]="'Reset filters' | translate"
          (click)="clearFilters()">
          <mat-icon>clear_all</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <T str="Unit"></T>
      </ng-template>

      <div class="am">
        <div
          class="inspection-disable"
          *ngIf="hasProject">
          <apex-filter-chips
            #project
            [model]="FilterType.Project"
            [label]="'Project' | translate"
            (selectedModelsChange)="projectChange()">
          </apex-filter-chips>
        </div>

        <apex-filter-chips
          #apartment
          [model]="FilterType.Apartment"
          [label]="'Unit' | translate"
          (selectedModelsChange)="apartmentChange()"
          [disabled]="!project?.selectedModels?.length">
        </apex-filter-chips>

        <ng-container *ngIf="project?.selectedModels?.length === 1">
          <ng-container *ngIf="project?.selectedModels | apartmentFieldNumbersForProject | async as fieldNumbers">
            <div class="df">
              <mat-form-field class="f1">
                <mat-label>
                  <T
                    str="Field"
                    context="apartment"
                    _context="apartment"></T>
                </mat-label>

                <mat-select
                  [disabled]="!fieldNumbers.length"
                  [(value)]="apartmentFieldNumberSearch"
                  (selectionChange)="updateQueryParams('apartmentFieldNumberSearch', $event.value)">
                  <mat-option [value]="null">
                    <T str="All"></T>
                  </mat-option>
                  <mat-option
                    *ngFor="let fieldNumber of fieldNumbers"
                    [value]="fieldNumber.fieldNumber">
                    {{ fieldNumber.fieldNumber }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
        </ng-container>

        <div *ngIf="commercial">
          <apex-object-selector
            #object
            class="inspection-disable"
            [multiple]="true"
            [(ngModel)]="queryParams.Object"
            (ngModelChange)="setObject($event)">
          </apex-object-selector>

          <apex-filter-chips
            #tenancy
            [model]="FilterType.Tenancy"
            [label]="'Tenancy' | translate">
          </apex-filter-chips>

          <apex-filter-chips
            #agreement
            [model]="FilterType.Agreement"
            [label]="'Agreement' | translate">
          </apex-filter-chips>

          <apex-filter-chips
            #meter
            [model]="FilterType.Meter"
            [label]="'Meter' | translate">
          </apex-filter-chips>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <T str="Metadata"></T>
      </ng-template>

      <div class="am">
        <apex-textarea
          [value]="queryParams?.textSearch"
          (debouncedChange)="updateQueryParams('textSearch', $event)"
          [label]="'Search' | translate">
        </apex-textarea>

        <apex-filter-chips
          #checklist
          [model]="FilterType.Checklist"
          [label]="'Checklist' | translate"
          (selectedModelsChange)="checklistChange()">
        </apex-filter-chips>

        <div class="df c apb">
          <mat-checkbox
            [checked]="queryParams?.showChecklistItems"
            (change)="updateQueryParams('showChecklistItems', $event.checked)">
            <T str="Show checklist items"></T>
          </mat-checkbox>

          <mat-checkbox
            [checked]="queryParams?.hideWithChecklist"
            (change)="updateQueryParams('hideWithChecklist', $event.checked)">
            <T str="Hide cases with checklist"></T>
          </mat-checkbox>

          <mat-checkbox
            [checked]="queryParams?.hideFromProjectMom"
            *ngIf="false"
            (change)="updateQueryParams('hideFromProjectMom', $event.checked)">
            <T str="Hide cases from mom"></T>
          </mat-checkbox>
        </div>

        <mat-form-field class="w-100">
          <mat-label>
            <T str="Status"></T>
          </mat-label>
          <mat-select
            multiple
            [value]="queryParams?.CaseStatus | queryParamAsArray"
            (selectionChange)="updateQueryParams('CaseStatus', $event.value)">
            <mat-option value="accepted">
              <T str="Accepted"></T>
            </mat-option>
            <mat-option value="declined">
              <T str="Declined"></T>
            </mat-option>
            <mat-option value="new">
              <T str="Pending"></T>
            </mat-option>
            <mat-option value="completed">
              <T str="Completed"></T>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <apex-filter-chips
          class="inspection-disable"
          #category
          [model]="FilterType.CaseCategory"
          [label]="'Category' | translate"
          (selectedModelsChange)="categoryChange($event)"
          queryParamName="Category">
        </apex-filter-chips>

        <apex-filter-chips
          #addonCategory
          [disabled]="!category.hasSelectedId(14)"
          [model]="FilterType.AddonCategory"
          [label]="'Addon Category' | translate"
          queryParamName="AddonCategory">
        </apex-filter-chips>

        <ng-container *ngIf="noObject | async; else objectFields">
          <apex-filter-chips
            class="inspection-disable"
            [model]="FilterType.Field"
            [label]="'Discipline' | translate">
          </apex-filter-chips>
        </ng-container>
        <ng-template #objectFields>
          <apex-filter-chips
            class="inspection-disable"
            [model]="FilterType.ObjectField"
            [queryParamName]="'ObjectField'"
            [label]="'Discipline' | translate">
          </apex-filter-chips>
        </ng-template>

        <apex-filter-chips
          #tag
          (selectedModelsChange)="tagChange()"
          [model]="FilterType.Tag"
          [label]="'Tags' | translate"
          queryParamName="Tag">
        </apex-filter-chips>

        <div class="df c apb">
          <mat-checkbox
            [checked]="queryParams?.showArchived"
            (change)="updateQueryParams('showArchived', $event.checked)">
            <T str="Show archived"></T>
          </mat-checkbox>

          <mat-checkbox
            [checked]="queryParams?.deadline ? true : false"
            (change)="updateQueryParams('deadline', $event.checked)">
            <T str="Deadline"></T>
          </mat-checkbox>
        </div>

        <div class="df c">
          <mat-form-field>
            <mat-label>
              <T str="From"></T>
            </mat-label>
            <input
              matInput
              [matDatepicker]="from"
              [value]="moment(queryParams?.deadlineFrom * 1000)"
              (dateChange)="updateQueryParams('deadlineFrom', $event.value)" />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="from">
            </mat-datepicker-toggle>
            <mat-datepicker #from></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>
              <T str="To"></T>
            </mat-label>
            <input
              matInput
              [matDatepicker]="to"
              [value]="moment(queryParams?.deadlineTo * 1000)"
              (dateChange)="updateQueryParams('deadlineTo', $event.value)" />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="to">
            </mat-datepicker-toggle>
            <mat-datepicker #to></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <T str="Roles"></T>
      </ng-template>

      <div class="am">
        <apex-autocomplete
          type="Client"
          [label]="'Client' | translate"
          [hint]="'Search for a client and find project and unit' | translate"
          (modelChange)="clientChange($event)">
        </apex-autocomplete>

        <apex-autocomplete
          type="User"
          [label]="'Case manager' | translate"
          [ngModel]="queryParams?.CaseManager"
          (modelChange)="updateQueryParams('CaseManager', $event?.id)">
        </apex-autocomplete>

        <apex-autocomplete
          type="User"
          [label]="'Contractor' | translate"
          [ngModel]="queryParams?.ContractorId"
          (modelChange)="updateQueryParams('ContractorId', $event?.id)">
        </apex-autocomplete>

        <div class="df c">
          <mat-checkbox
            [checked]="queryParams?.showNoCaseManager"
            (change)="updateQueryParams('showNoCaseManager', $event.checked)">
            <T str="Missing case manager"></T>
          </mat-checkbox>
          <mat-checkbox
            [checked]="queryParams?.showNoContractor"
            (change)="updateQueryParams('showNoContractor', $event.checked)">
            <T str="Missing contractor"></T>
          </mat-checkbox>
          <mat-checkbox
            [checked]="queryParams?.showNoField"
            (change)="updateQueryParams('showNoField', $event.checked)">
            <T str="Missing discipline"></T>
          </mat-checkbox>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <T str="Export"></T>
      </ng-template>

      <div class="am">
        <ng-container *ngIf="hasPayment()">
          <div
            [matTooltip]="'Too many cases for PDF export, 250 is the limit, please change your filters'"
            [matTooltipDisabled]="count <= 250">
            <button
              mat-button
              color="primary"
              [matTooltip]="'Create PDF export' | translate"
              [disabled]="count > 250"
              (click)="export()"
              [apexAnalyticsClick]="{
                eventCategory: 'case',
                eventAction: 'export',
                eventLabel: 'pdf',
              }">
              <mat-icon>picture_as_pdf</mat-icon>
              <span>
                <T str="PDF"></T>
              </span>
            </button>
          </div>

          <div
            [matTooltip]="'Too many cases for PDF export, 250 is the limit, please change your filters'"
            [matTooltipDisabled]="count <= 250">
            <button
              mat-button
              color="primary"
              [matTooltip]="'Create PDF export' | translate"
              [disabled]="count > 250"
              (click)="export(false)"
              [apexAnalyticsClick]="{
                eventCategory: 'case',
                eventAction: 'export',
                eventLabel: 'pdf',
              }">
              <mat-icon>picture_as_pdf</mat-icon>
              <span>
                <T str="PDF (Minimal)"></T>
              </span>
            </button>
          </div>

          <div>
            <button
              mat-button
              color="primary"
              [matTooltip]="'Create CSV export' | translate"
              [disabled]="doingCSV || doingExcel"
              (click)="exportCSV()"
              [apexAnalyticsClick]="{
                eventCategory: 'case',
                eventAction: 'export',
                eventLabel: 'csv',
              }">
              <mat-icon *ngIf="!doingCSV">insert_drive_file</mat-icon>
              <mat-spinner
                *ngIf="doingCSV"
                [mode]="exportProgress > 0 ? 'determinate' : 'indeterminate'"
                [value]="exportProgress"
                diameter="24"
                style="display: inline-block">
              </mat-spinner>
              <span class="amlxs">
                <T str="CSV"></T>
              </span>
            </button>
          </div>
          <div>
            <button
              mat-button
              color="primary"
              [matTooltip]="'Create Excel export' | translate"
              [disabled]="doingExcel || doingCSV"
              (click)="exportExcel()"
              [apexAnalyticsClick]="{
                eventCategory: 'case',
                eventAction: 'export',
                eventLabel: 'excel',
              }">
              <mat-icon *ngIf="!doingExcel">insert_drive_file</mat-icon>
              <mat-spinner
                *ngIf="doingExcel"
                [mode]="exportProgress > 0 ? 'determinate' : 'indeterminate'"
                [value]="exportProgress"
                diameter="24"
                style="display: inline-block">
              </mat-spinner>
              <span class="amlxs">
                <T str="Excel"></T>
              </span>
            </button>
          </div>
        </ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
