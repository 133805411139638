<div
  class="pointer mat-elevation-z2"
  (click)="projectInvolvedService.toggleSelectUser(user)"
  [class.selected]="isSelected()">
  <div class="jsb oh df r">
    <div class="w-85 apts apbs apls">
      <div class="df jsb">
        <h4
          class="rm apbs"
          [title]="extendedUser.name">
          {{ extendedUser.name }}
        </h4>
        <div class="df jfe aprs mat-caption">
          <span>{{ extendedUser.companyName }}</span>
        </div>
      </div>
      <div class="truncate">
        <span>{{ extendedUser?.fieldNames | join: ', ' }}&nbsp;</span>
      </div>
      <div>
        <span *ngFor="let role of extendedUser?.roles; let last = last"
          >{{ role | translateProjectInvolvedUserRole }}<ng-container *ngIf="!last">, </ng-container></span
        >
      </div>
    </div>

    <div
      class="two-column f1 gap-0"
      [ngClass]="{
        'one-column': extendedUser.fieldColors?.length === 1,
        'two-column': extendedUser.fieldColors?.length >= 2,
        'display-grid': extendedUser.fieldColors?.length >= 1,
        'display-flex': extendedUser.fieldColors?.length === 0,
      }">
      <div
        [ngClass]="{
          'one-box': extendedUser.fieldColors?.length === 1,
          'two-box': extendedUser.fieldColors?.length === 2,
          'three-box': extendedUser.fieldColors?.length >= 3,
        }"
        *ngFor="let fieldColor of extendedUser.fieldColors"
        class="field-name width-100 height-100"
        [style.background-color]="fieldColor">
        &nbsp;
      </div>
    </div>
  </div>
</div>
