<apex-box
  class="no-padding inspections"
  [expanded]="expanded"
  icon="fact_check">
  <T
    title
    str="Inspections ({inspections})"
    [vars]="{ inspections: inspections?.length }">
  </T>

  <ng-container right>
    <a
      mat-icon-button
      target="_blank"
      (click)="$event.stopPropagation()"
      [href]="{ objectId, projectId, apartmentId } | inspectionLink"
      [matTooltip]="'New inspection' | translate">
      <mat-icon>add</mat-icon>
    </a>
  </ng-container>

  <div
    class="aph empty"
    *ngIf="!inspections?.length">
    <p class="am">
      <T str="No inspections found"></T>
    </p>
  </div>

  <mat-nav-list *ngIf="inspections?.length">
    <ng-container *ngFor="let inspection of inspections; last as last">
      <mat-list-item
        lines="3"
        [class.ped]="inspection.old"
        [routerLink]="['/inspection', inspection.id]"
        routerLinkActive="router-link-active">
        <h2 matListItemTitle>
          {{
            [inspection.id, inspection.Object?.name, inspection.Project?.name, inspection.Apartment?.name]
              | listArray: ' - '
          }}
        </h2>

        <span matListItemLine>
          <!-- @note This is handled by caseCategories -->
          {{ inspection.Category?.name | translate }}
          -
          {{ inspection.User?.name }}
        </span>

        <div matListItemLine>
          <span [matTooltip]="'Created' | translate">
            {{ inspection.createdAt | date: 'short' }}
          </span>
        </div>

        <div matListItemMeta>
          <button
            type="button"
            matListItemIcon
            mat-icon-button
            class="pea"
            [matTooltip]="'PDF' | translate"
            *ngIf="inspection.FileUsage"
            (click)="showInspection(inspection); $event.stopPropagation()">
            <mat-icon>picture_as_pdf</mat-icon>
          </button>

          <button
            matListItemIcon
            mat-icon-button
            class="pea"
            color="warn"
            [matTooltip]="'Delete' | translate"
            (click)="deleteInspection(inspection); $event.preventDefault(); $event.stopPropagation()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>

    <mat-paginator
      *ngIf="inspections?.length"
      class="AK-50-bg"
      [pageSizeOptions]="[5, 10, 25, 50]"
      [length]="count ?? 0"
      [pageIndex]="page ?? 0"
      [pageSize]="limit ?? 5"
      (page)="changePage($event)">
    </mat-paginator>
  </mat-nav-list>
</apex-box>
