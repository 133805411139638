import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { Router } from '@angular/router';
import { FileService } from 'projects/apex/src/app/components/file-usage/file.service';
import { FileViewerDialogComponent } from 'projects/apex/src/app/components/file-viewer/dialog.component';
import { File as ApexFile } from 'projects/apex/src/app/models/file';
import { FolderService } from 'projects/apex/src/app/services/folder/folder.service';
import { favoriteAvailable } from 'projects/apex/src/app/utils/functions';
import { Observable, Subject, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SearchResult } from './search.model';
import { SearchService } from './search.service';

@Component({
  selector: 'apex-search',
  templateUrl: './search.component.html',
})
export class SearchComponent {
  @Output() focusChange = new EventEmitter<boolean>();

  @ViewChild(MatInput) input: MatInput;

  query: string;
  results: SearchResult[] = [];

  req = new Subject<string>();
  res: Observable<SearchResult[]> = this.req.pipe(
    switchMap((q: string) => this.searchService.search(q ?? '*')),
    catchError(() => of([])),
  );
  sub = this.res.subscribe({
    next: (results: SearchResult[]) => {
      this.results = results;
    },
  });

  favoriteAvailable = favoriteAvailable;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private searchService: SearchService,
    private folderSerivce: FolderService,
    private fileService: FileService,
  ) {}

  select(result: SearchResult): void {
    switch (result.model) {
      case 'folder':
        void this.router.navigate(['folder', result.id]);
        break;

      case 'file':
        this.fileService.get(Number(result.id)).subscribe({
          next: (file: ApexFile) => {
            this.dialog.open(FileViewerDialogComponent, { data: { file }, autoFocus: false });
          },
        });
        break;
    }

    this.query = '';
  }

  focus(): void {
    setTimeout(() => {
      this.input.focus();
    });
  }
}
