<main>
  <mat-tab-group
    #tabGroup
    animationDuration="0ms"
    (selectedIndexChange)="
      $event && floorplansLoading ? null : setFragment($event, $event ? marking.selectedIndex : id)
    "
    (animationDone)="marking?.resize()">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon
          class="amrs"
          [matTooltipDisabled]="!mobile"
          [matTooltip]="'List' | translate">
          list
        </mat-icon>
        <T
          class="ds mat-body-2 amrs"
          str="List {count, plural, =0 {} other {(#)}}"
          [vars]="{ count: count ?? 0 }">
        </T>
      </ng-template>
      <div class="case-and-case-list df">
        <apex-case-view
          #view
          *ngIf="!listFullSize && !mobile"
          class="f4 oh th ds amt aml amb amrs"
          [ngClass]="{ 'mat-elevation-z2': id }"
          [id]="id"
          [highlighted]="isSelectedCaseHighlighted()"
          (caseChange)="updateCase($event)"
          (next)="list.forwardOneCase()"
          (back)="list.backOneCase()">
        </apex-case-view>
        <div class="case-list-wrap">
          <div class="amr amt d-amls t-aml p-aml">
            <apex-case-list
              #list
              [openCaseOnClick]="listFullSize || mobile ? true : false"
              (idChange)="$event && id === $event ? view?.setCase($event) : setFragment(fragments.list, $event)"
              [cases]="cases"
              [highlightedCaseIds]="highlightedCaseIds"
              [dialogClass]="dialogClass"
              (caseChange)="updateCase($event)">
              <button
                type="button"
                mat-icon-button
                [matTooltip]="'Sort by category' | translate"
                [matMenuTriggerFor]="sortByCategory">
                <mat-icon>sort_by_alpha</mat-icon>
              </button>
              <mat-menu
                #sortByCategory="matMenu"
                xPosition="after"
                yPosition="below">
                <button
                  type="button"
                  mat-menu-item
                  (click)="updateQueryParams('sort', 'id')">
                  <T str="Case id"></T>
                </button>
                <button
                  type="button"
                  mat-menu-item
                  (click)="updateQueryParams('sort', 'name')">
                  <T str="Case name"></T>
                </button>
                <button
                  type="button"
                  mat-menu-item
                  (click)="updateQueryParams('sort', 'contractor')">
                  <T str="Contractor"></T>
                </button>
                <button
                  type="button"
                  mat-menu-item
                  (click)="updateQueryParams('sort', 'client')">
                  <T str="Customer"></T>
                </button>
                <button
                  type="button"
                  mat-menu-item
                  (click)="updateQueryParams('sort', 'apartment')">
                  <T str="Unit"></T>
                </button>
              </mat-menu>

              <button
                type="button"
                mat-icon-button
                [matTooltip]="'Sorty by order' | translate"
                [matMenuTriggerFor]="sortByOrder">
                <mat-icon>filter_list</mat-icon>
              </button>
              <mat-menu
                #sortByOrder="matMenu"
                xPosition="after"
                yPosition="below">
                <button
                  type="button"
                  mat-menu-item
                  (click)="updateQueryParams('sortOrder', 'asc')">
                  <T str="Ascending"></T>
                </button>
                <button
                  type="button"
                  mat-menu-item
                  (click)="updateQueryParams('sortOrder', 'desc')">
                  <T str="Descending"></T>
                </button>
              </mat-menu>
            </apex-case-list>
            <div
              *ngIf="!casesLoading && !cases?.length"
              class="df ac jc apt">
              <T str="No cases found"></T>
            </div>
            <div
              *ngIf="casesLoading"
              class="f1 df ac jc ap">
              <div class="ap">
                <mat-spinner></mat-spinner>
              </div>
            </div>
            <div class="df c jc ac">
              <p>{{ cases?.length ?? 0 }} / {{ count }}</p>
              <button
                class="btn-load-more-cases"
                mat-raised-button
                *ngIf="!casesLoading && !countLoading && cases && count > cases.length"
                [apexAnalyticsClick]="{
                  eventCategory: 'case',
                  eventAction: 'load-more',
                }"
                (click)="loadMoreCases()">
                <T str="Load more cases"></T>
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        *ngIf="showFab"
        mat-fab
        (click)="newCase()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-tab>

    <mat-tab
      *ngIf="isTakeover"
      disabled>
      <ng-template mat-tab-label>
        <div class="df f1 jfe pointer-events-all">
          <button
            mat-button
            (click)="openDialog()">
            <mat-icon
              class="amrs"
              svgIcon="floorplan"
              [matTooltipDisabled]="!mobile"
              [matTooltip]="'Markings' | translate">
            </mat-icon>
            <T
              class="ds mat-body-2 amrs"
              str="Markings {count, plural, =0 {} other {(#)}}"
              [vars]="{ count: markingCount ?? 0 }">
            </T>
          </button>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab *ngIf="!isTakeover">
      <ng-template mat-tab-label>
        <mat-icon
          class="amrs"
          svgIcon="floorplan"
          [matTooltipDisabled]="!mobile"
          [matTooltip]="'Markings' | translate">
        </mat-icon>
        <T
          class="ds mat-body-2 amrs"
          str="Markings {count, plural, =0 {} other {(#)}}"
          [vars]="{ count: markingCount ?? 0 }">
        </T>
      </ng-template>
      <div class="case-marking-wrap">
        <apex-case-marking
          #marking
          [cases]="cases"
          [floorplans]="floorplans"
          [highlightedCaseIds]="highlightedCaseIds"
          [dialogClass]="dialogClass"
          [loading]="floorplansLoading"
          (selectedIndexChanged)="setFragment(fragments.marking, $event)"
          (fileUsageClick)="newCaseFromMarking($event)"
          (caseChange)="updateCase($event); $event && id === $event.id ? view?.setCase($event.id) : null">
        </apex-case-marking>
      </div>
      <button
        *ngIf="showFab"
        mat-fab
        (click)="newCase()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-tab>
    <mat-tab *ngIf="showCalendar">
      <ng-template mat-tab-label>
        <mat-icon
          class="amrs"
          [matTooltipDisabled]="!mobile"
          [matTooltip]="'Calendar' | translate">
          calendar_today
        </mat-icon>
        <T
          class="ds mat-body-2 amrs"
          str="Calendar"></T>
      </ng-template>
      <apex-case-calendar [queryParams]="queryParams"></apex-case-calendar>
    </mat-tab>
    <mat-tab disabled>
      <ng-template mat-tab-label>
        <div class="df f1 jfe apex-case-filter-shortcuts-wrapper pointer-events-all">
          <apex-case-filter-shortcuts
            class="add-margin-right-small"
            #filter
            [standardParams]="standardParams"
            [queryParams]="queryParams"
            [count]="count"
            [refreshing]="casesLoading || countLoading"
            (refresh)="refresh()">
          </apex-case-filter-shortcuts>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</main>
