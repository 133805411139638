<mat-form-field
  class="f1"
  (click)="click()">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid
    #chipList
    [disabled]="disabled"
    [required]="required">
    <mat-chip-row
      *ngFor="let id of loop"
      (removed)="remove(id)"
      [removable]="!disabled">
      <apex-autocomplete-viewer
        type="Object"
        [id]="id">
      </apex-autocomplete-viewer>
      <mat-icon
        matChipRemove
        *ngIf="!disabled"
        >clear</mat-icon
      >
    </mat-chip-row>
    <input
      [matChipInputFor]="chipList"
      readonly />
  </mat-chip-grid>
  <mat-hint *ngIf="hint && showHint">
    {{ hint }}
  </mat-hint>

  <button
    type="button"
    matSuffix
    mat-icon-button
    [disabled]="disabled"
    [matTooltip]="'Select objects' | translate">
    <mat-icon>folder_open</mat-icon>
  </button>
</mat-form-field>
