import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileUsageService } from '../file-usage/file-usage.service';
import { TranslateModule } from '../translate/translate.module';
import { FilePreviewLoaderComponent } from './file-preview-loader.component';
import { FilePreviewModule } from './file-preview.module';

@NgModule({
  declarations: [FilePreviewLoaderComponent],
  exports: [FilePreviewLoaderComponent],
  imports: [CommonModule, FilePreviewModule, TranslateModule],
  providers: [FileUsageService],
})
export class FilePreviewLoaderModule {}
