import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIService } from 'projects/apex/src/app/services/http/http.service';
import { Observable } from 'rxjs';
import { ChecklistGroup } from '../../models/checklist-group';
import { Field } from '../../models/field';
import { ObjectField } from '../../models/object-field';
import { HttpExtra } from '../../services/http/http-extra';
import { CollectionResponse, EntityResponse } from '../../utils/types';
import { ChecklistGroupModels } from './checklist-group.types';

@Injectable()
export class ChecklistGroupService extends APIService<ChecklistGroup> {
  route = 'checklist-group';

  createGroupFromTemplate(templateId: number, extra?: HttpExtra): Observable<EntityResponse<ChecklistGroup>> {
    return this.http.get<EntityResponse<ChecklistGroup>>(
      `${this.apiUrl}/${this.route}/from-template/${templateId}`,
      this.options(extra),
    );
  }

  createGroupFromTemplateOnModel(
    templateId: number,
    model: string,
    modelId: number,
    extra?: HttpExtra,
  ): Observable<EntityResponse<ChecklistGroup>> {
    return this.http.get<EntityResponse<ChecklistGroup>>(
      `${this.apiUrl}/${model}/${modelId}/${this.route}/from-template/${templateId}`,
      this.options(extra),
    );
  }

  deleteChecklists(id: ChecklistGroup, completeAndArchiveCases: boolean): Observable<EntityResponse<ChecklistGroup>> {
    let params = new HttpParams();

    params = params.append('completeAndArchiveCases', completeAndArchiveCases.toString());

    const options = this.options();

    return this.http.get<EntityResponse<ChecklistGroup>>(`${this.apiUrl}/${this.route}/${id}/checklist`, {
      headers: options.headers,
      withCredentials: options.withCredentials,
      params,
    });
  }

  queryByModel(
    model: ChecklistGroupModels,
    modelId: number,
    extra?: HttpExtra,
  ): Observable<CollectionResponse<ChecklistGroup>> {
    return this.http.get<CollectionResponse<ChecklistGroup>>(
      `${this.apiUrl}/${model}/${modelId}/${this.route}`,
      this.options(extra),
    );
  }

  getByModel(
    model: ChecklistGroupModels,
    modelId: number,
    groupId: number,
    extra?: HttpExtra,
  ): Observable<EntityResponse<ChecklistGroup>> {
    return this.http.get<EntityResponse<ChecklistGroup>>(
      `${this.apiUrl}/${model}/${modelId}/${this.route}/${groupId}`,
      this.options(extra),
    );
  }

  getFields(groupId: number, projectId: number): Observable<Field[]> {
    return this.http.get<Field[]>(
      `${this.apiUrl}/project/${projectId}/checklist-group/${groupId}/field`,
      this.options(),
    );
  }

  getObjectFields(groupId: number, objectId: number): Observable<ObjectField[]> {
    return this.http.get<ObjectField[]>(
      `${this.apiUrl}/object/${objectId}/checklist-group/${groupId}/object-field`,
      this.options(),
    );
  }

  bulkDelete(
    groups: ChecklistGroup[],
    completeAndArchiveCheck: boolean,
  ): Observable<CollectionResponse<[{ id: number; success: boolean }]>> {
    const options = {
      ...this.options(),
      params: {
        archiveCases: completeAndArchiveCheck,
      },
      body: groups,
    };

    return this.http.delete<CollectionResponse<[{ id: number; success: boolean }]>>(
      `${this.apiUrl}/checklist-group/bulk`,
      options,
    );
  }
}
