import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutocompleteModule } from 'projects/apex/src/app/components/autocomplete/autocomplete.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { HighlightPipeModule } from 'projects/apex/src/app/pipes/highlight/highlight-pipe.module';
import { IconPipeModule } from 'projects/apex/src/app/pipes/icon/icon-pipe.module';
import { ObjectService } from '../../object.service';
import { TenancyService } from '../../project/tenancy/tenancy.service';
import { ObjectSelectorDialogComponent } from './dialog.component';
import { ObjectSelectorComponent } from './selector.component';

const mine = [ObjectSelectorComponent, ObjectSelectorDialogComponent];

@NgModule({
  declarations: mine,
  exports: mine,
  imports: [
    CommonModule,
    FormsModule,

    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatRippleModule,
    MatDividerModule,
    MatInputModule,
    MatBadgeModule,
    MatChipsModule,
    ScrollingModule,

    TranslateModule,
    AutocompleteModule,
    HighlightPipeModule,
    IconPipeModule,
  ],
  providers: [ObjectService, TenancyService],
})
export class ObjectSelectorModule {}
