import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { DragItem } from 'projects/apex/src/app/components/drag/drag-item';
import { DragService } from 'projects/apex/src/app/components/drag/drag.service';
import { Subscription, fromEvent } from 'rxjs';
import { bufferCount, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'apex-drag',
  templateUrl: './drag.component.html',
})
export class DragComponent implements OnInit, OnDestroy {
  @Input() parent: ElementRef;
  @Input() singleMode = false;
  @Input() disableSelector = false;

  readonly defaultWidth = 100;

  startPos: { x: number; y: number };
  mousePos: { x: number; y: number };
  width: number;
  height: number;
  displaySelector: boolean;

  selectorSub: Subscription;

  constructor(public ds: DragService) {}

  ngOnInit(): void {
    if (this.parent) {
      this.parent.nativeElement.style.position = 'relative';
      this.selectorSub = fromEvent(this.parent.nativeElement, 'mousedown')
        .pipe(
          mergeMap((e: MouseEvent) => {
            this.ds.selectorChange.next(null);

            const rect = this.parent.nativeElement.getBoundingClientRect();

            this.startPos = {
              x: e.clientX - rect.left,
              y: e.clientY - rect.top,
            };
            this.mousePos = this.startPos;
            this.width = 0;
            this.height = 0;

            return fromEvent(window, 'mousemove').pipe(
              bufferCount(4),
              map((arr) => arr[arr.length - 1]),
              takeUntil(
                fromEvent(window, 'mouseup').pipe(
                  tap(() => {
                    this.displaySelector = false;
                  }),
                ),
              ),
            );
          }),
        )
        .subscribe({
          next: (e: MouseEvent) => {
            if (!this.ds.dragging) {
              const r = this.parent.nativeElement.getBoundingClientRect();

              this.mousePos = {
                x: e.clientX - r.left,
                y: e.clientY - r.top,
              };
              this.width = Math.abs(this.startPos?.x - this.mousePos?.x);
              this.height = Math.abs(this.startPos?.y - this.mousePos?.y);

              if (this.displaySelector || this.width > 50 || this.height > 50) {
                this.displaySelector = true;

                const sx = this.startPos.x + r.left;
                const sy = this.startPos.y + r.top;
                const mx = this.mousePos.x + r.left;
                const my = this.mousePos.y + r.top;
                const rect = {
                  left: sx < mx ? sx : mx,
                  top: sy < my ? sy : my,
                  right: sx > mx ? sx : mx,
                  bottom: sy > my ? sy : my,
                };

                this.ds.selectorChange.next(rect);
              }
            }
          },
        });
    }
  }

  itemsSelected(items: DragItem[]): DragItem[] {
    return items?.filter((i) => i.selected) ?? [];
  }

  isDragItem(i: DragItem): boolean {
    return this.ds.dragItem?.model === i.model && this.ds.dragItem?.modelId === i.modelId;
  }

  isReady(i: DragItem): boolean {
    return !!(i?.readyX && i?.readyY);
  }

  ngOnDestroy(): void {
    this.selectorSub?.unsubscribe();
  }
}
