import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModelRoleService } from 'projects/apex/src/app/components/model-role/model-role.service';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { ModelRole } from 'projects/apex/src/app/models/model-role';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import { hasDateExpired, isDateActive, periodDateToolTip } from 'projects/apex/src/app/utils/functions';
import { concat, merge } from 'rxjs';
import { map, toArray } from 'rxjs/operators';
import { snack } from '../../../modules/snack.module';
import { FolderShareData, FolderShareUser, FolderShareUserRole } from './share.types';

@Component({
  selector: 'apex-folder-share',
  templateUrl: './share.component.html',
})
export class FolderShareComponent implements OnInit, AfterViewInit {
  FolderShareUserRole = FolderShareUserRole;

  count = 0;
  modelRoles: ModelRole[] = [];

  users: FolderShareUser[] = [];

  loading = false;

  constructor(
    private userService: UserService,
    private modelRoleService: ModelRoleService,
    public dialogRef: MatDialogRef<FolderShareComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FolderShareData,
  ) {}

  ngOnInit(): void {
    this.data.folders = this.data?.folders?.filter((f) => !f?.modelName) ?? [];
    this.count = (this.data?.folders?.length ?? 0) + (this.data?.files?.length ?? 0);
  }

  ngAfterViewInit(): void {
    const requests = [].concat(
      // @todo Well, this is not that good, we do more bettery.
      this.data?.folders?.map((f) => this.modelRoleService.queryRoleForModel('folder', f.id, 'folder-view')) ?? [],
      this.data?.folders?.map((f) => this.modelRoleService.queryRoleForModel('folder', f.id, 'folder-edit')) ?? [],
      this.data?.files?.map((f) => this.modelRoleService.queryRoleForModel('file', f.id, 'file-view')) ?? [],
    );

    if (requests.length) {
      merge(...requests)
        .pipe(
          toArray(),
          map((res) => (res.length ? [].concat(...res) : [])),
        )
        .subscribe({
          next: (data) => {
            this.modelRoles = data;

            this.modelRoles.forEach((modelRole) => {
              let user = this.users.find((u) => u.id === modelRole.UserId);

              if (!user) {
                user = new FolderShareUser(modelRole.User);

                const modelRoles = this.modelRoles.filter((mr) => mr.UserId === user.id);

                let from: Date;
                let to: Date;

                modelRoles.forEach((mr) => {
                  if (from === undefined) {
                    from = mr.from;
                  }

                  if (from && from !== mr.from) {
                    from = null;
                  }

                  if (to === undefined) {
                    to = mr.to;
                  }

                  if (to && to !== mr.to) {
                    to = null;
                  }
                });

                user.from = from;
                user.to = to;

                const viewer = modelRoles.filter((mr) => mr.role === 'folder-view' || mr.role === 'file-view');
                const editor = modelRoles.filter((mr) => mr.role === 'folder-edit');

                if (viewer.length === this.count) {
                  user.shareRole = FolderShareUserRole.Viewer;
                } else if (editor.length === this.count) {
                  user.shareRole = FolderShareUserRole.Editor;
                } else {
                  user.shareRole = FolderShareUserRole.Varies;
                }

                this.users.push(user);
              }
            });
          },
          error: () => {
            this.loading = false;
          },
          complete: () => {
            this.loading = false;
          },
        });
    } else {
      this.loading = false;
    }
  }

  newUser(id: number): void {
    if (id) {
      if (!this.users.some((u) => u.id === id)) {
        this.userService.get(id).subscribe({
          next: (user) => {
            const folderShareUser = new FolderShareUser(user);

            folderShareUser.from = new Date();
            folderShareUser.shareRole = FolderShareUserRole.Viewer;

            this.users.push(folderShareUser);
          },
        });
      } else {
        snack(t('User is already in list'));
      }
    }
  }

  removeAccess(user: FolderShareUser): void {
    if (user) {
      const idx = this.users.indexOf(user);

      if (idx !== -1) {
        this.users.splice(idx, 1);
      }
    }
  }

  viewAccess(user: FolderShareUser): void {
    if (user) {
      user.shareRole = FolderShareUserRole.Viewer;
    }
  }

  editAccess(user: FolderShareUser): void {
    if (user) {
      user.shareRole = FolderShareUserRole.Editor;
    }
  }

  confirm(): void {
    this.loading = true;

    concat(
      merge(...this.changedModelRoles.map((mr) => this.modelRoleService.delete(mr))),
      merge(...this.newModelRoles.map((mr) => this.modelRoleService.saveRoleForModel(mr))),
    )
      .pipe(toArray())
      .subscribe({
        next: (newModelRoles: ModelRole[]) => {
          let modelRoles = this.modelRoles.filter(
            (modelRole) => !newModelRoles.find((mr) => mr.id === modelRole.id && mr.deletedAt),
          );

          modelRoles = modelRoles.concat(newModelRoles.filter((mr) => !mr.deletedAt));

          this.data?.folders?.forEach((f) => {
            f.shared = modelRoles.some((mr) => mr.model === 'folder' && mr.modelId === f.id);
          });

          this.data?.files?.forEach((f) => {
            f.shared = modelRoles.some((mr) => mr.model === 'file' && mr.modelId === String(f.id));
          });

          this.dialogRef.close(modelRoles);
        },
      });
  }

  isDateChanged(user: FolderShareUser, mr: ModelRole): boolean {
    return !!(user.from && (user.from !== mr.from || user.to !== mr.to));
  }

  isActive(user: FolderShareUser): boolean {
    return isDateActive(user.from, user.to);
  }

  hasExpired(user: FolderShareUser): boolean {
    return hasDateExpired(user.to);
  }

  periodToolTip(user: FolderShareUser): string {
    return periodDateToolTip(user.from, user.to);
  }

  get changedModelRoles(): ModelRole[] {
    return this.modelRoles.filter((mr) => {
      const user = this.users.find((u) => u.id === mr.UserId);

      if (!user) {
        return true;
      }

      if (user.shareRole === FolderShareUserRole.Viewer && mr.role === 'folder-edit') {
        return true;
      }

      if (user.shareRole === FolderShareUserRole.Editor && (mr.role === 'file-view' || mr.role === 'folder-view')) {
        return true;
      }

      if (this.isDateChanged(user, mr)) {
        return true;
      }

      return false;
    });
  }

  get newModelRoles(): ModelRole[] {
    const newModelRoles: ModelRole[] = [];

    this.users.forEach((user) => {
      const modelRoles = this.modelRoles.filter((mr) => mr.UserId === user.id);

      if (user.shareRole === FolderShareUserRole.Varies) {
        const changedModelRoles = modelRoles.filter((mr) => this.isDateChanged(user, mr));

        changedModelRoles.forEach((changedModelRole) => {
          const mr = new ModelRole({
            UserId: user.id,
            model: changedModelRole.model,
            modelId: changedModelRole.modelId,
            role: changedModelRole.role,
            from: user.from,
            to: user.to,
          });

          newModelRoles.push(mr);
        });
      }

      if (user.shareRole === FolderShareUserRole.Viewer) {
        const folders = this.data.folders.filter(
          (f) =>
            !modelRoles.some((mr) => mr.modelId === f.id && mr.role === 'folder-view' && !this.isDateChanged(user, mr)),
        );
        const files = this.data.files.filter(
          (f) =>
            !modelRoles.some(
              (mr) => mr.modelId === String(f.id) && mr.role === 'file-view' && !this.isDateChanged(user, mr),
            ),
        );

        folders.forEach((f) => {
          const oldModelRole = modelRoles.find((mr) => mr.model === 'folder' && mr.modelId === f.id);

          const mr = new ModelRole({
            UserId: user.id,
            model: 'folder',
            modelId: f.id,
            role: 'folder-view',
            from: user.from ?? oldModelRole?.from,
            to: user.to,
          });

          newModelRoles.push(mr);
        });

        files.forEach((f) => {
          const oldModelRole = modelRoles.find((mr) => mr.model === 'file' && mr.modelId === String(f.id));

          const mr = new ModelRole({
            UserId: user.id,
            model: 'file',
            modelId: String(f.id),
            role: 'file-view',
            from: user.from ?? oldModelRole?.from,
            to: user.to,
          });

          newModelRoles.push(mr);
        });
      }

      if (user.shareRole === FolderShareUserRole.Editor) {
        const folders = this.data.folders.filter(
          (f) =>
            !modelRoles.some((mr) => mr.modelId === f.id && mr.role === 'folder-edit' && !this.isDateChanged(user, mr)),
        );

        folders.map((f) => {
          const oldModelRole = modelRoles.find((mr) => mr.model === 'folder' && mr.modelId === f.id);

          const mr = new ModelRole({
            UserId: user.id,
            model: 'folder',
            modelId: f.id,
            role: 'folder-edit',
            from: user.from ?? oldModelRole?.from,
            to: user.to,
          });

          newModelRoles.push(mr);
        });
      }

      return [];
    });

    return newModelRoles;
  }
}
