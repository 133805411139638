import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ChecklistGroup } from '../../models/checklist-group';
import { ObjectField } from '../../models/object-field';
import { CollectionResponse } from '../../utils/types';
import { ChecklistGroupService } from './checklist-group.service';

export const ChecklistGroupsResolver: ResolveFn<CollectionResponse<ChecklistGroup>> = (
  route: ActivatedRouteSnapshot,
) => {
  const service = inject(ChecklistGroupService);

  const {
    checklistGroupPage,
    checklistGroupLimit,
    checklistGroupSearch,
    checklistGroupObject,
    checklistGroupProject,
    checklistGroupApartment,
    checklistGroupShowOpen,
    checklistGroupShowCompleted,
    checklistGroupField,
    checklistGroupObjectField,
  } = route.queryParams;

  const { pid: objectId, ProjectId: projectId } = route.params;

  const params = {
    page: checklistGroupPage,
    limit: checklistGroupLimit,
    search: checklistGroupSearch,
    order: 'desc',
    object: checklistGroupObject ?? '',
    project: checklistGroupProject ?? '',
    apartment: checklistGroupApartment ?? '',
    showOpen: checklistGroupShowOpen ?? true,
    showCompleted: checklistGroupShowCompleted ?? false,

    Field: checklistGroupField ?? null,
    ObjectField: checklistGroupObjectField ?? null,
  };

  if (!!objectId) {
    return service.queryByModel('object', objectId, { params });
  }

  if (!!projectId) {
    return service.queryByModel('project', projectId, { params });
  }

  return service
    .query({
      params,
    })
    .pipe(
      map((response) => ({
        ...response,
        Collection: response.Collection.map((group) => ({
          ...group,
          ObjectField: new ObjectField(group.ObjectField),
        })),
      })),
    );
};

export const ChecklistGroupResolver: ResolveFn<ChecklistGroup> = (route: ActivatedRouteSnapshot) => {
  const service = inject(ChecklistGroupService);

  const { id: groupId, pid: objectId, ProjectId: projectId } = route.params;

  if (!groupId) {
    return of(null);
  }

  if (!!objectId) {
    return service.getByModel('object', objectId, groupId).pipe(map((res) => res.Entity));
  }

  if (!!projectId) {
    return service.getByModel('project', projectId, groupId).pipe(map((res) => res.Entity));
  }

  return service.get(groupId).pipe(
    map((response) => response.Entity),
    map((group) => ({
      ...group,
      ObjectField: new ObjectField(group.ObjectField),
    })),
  );
};

export const FromChecklistGroupTemplateResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot) => {
  const service = inject(ChecklistGroupService);
  const router = inject(Router);
  const location = inject(Location);

  const tid = Number(route.paramMap.get('tid') ?? 0);

  if (!tid) {
    throw new Error('Missing tid');
  }

  return service.createGroupFromTemplate(tid).pipe(
    mergeMap((cg) => {
      if (cg?.Entity?.id) {
        return void router.navigate(['/', 'checklist-group', cg.Entity.id], { replaceUrl: true });
      }

      location.back();

      return of(false);
    }),
  );
};

export const ChecklistGroupFromTemplateByQueryParamResolver: ResolveFn<ChecklistGroup> = (
  route: ActivatedRouteSnapshot,
) => {
  const service = inject(ChecklistGroupService);

  const tid = Number(route.queryParams.ChecklistGroupTemplate);

  if (tid) {
    return service.createGroupFromTemplate(tid).pipe(map((res) => res.Entity));
  }

  return of(null);
};
