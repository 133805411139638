<div class="df jfs f1">
  <ng-content select="[left]"></ng-content>

  <ng-container *ngIf="icon">
    <mat-icon
      [matTooltip]="iconTooltip"
      class="amrs"
      [fontSet]="fontSet"
      [color]="iconColor">
      {{ icon }}
    </mat-icon>
  </ng-container>

  <div class="df c f1 text-wrapper oh">
    <span
      class="mat-caption ambxs"
      *ngIf="showTitle">
      <ng-content select="[title]"></ng-content>
    </span>

    <ng-content></ng-content>
  </div>
</div>
