import { Component } from '@angular/core';
import { BaseItemComponent } from './base-item.component';

/**
 * Title small. Main big.
 */
@Component({
  selector: 'apex-title-item',
  templateUrl: './title-item.component.html',
})
export class TitleItemComponent extends BaseItemComponent {}
