import { Pipe, PipeTransform } from '@angular/core';
import { ExtendedProjectInvolvedUserType } from '../../project-involved.service';
import { ProjectInvolvedUserRole } from '../project-involved-user-role.type';

@Pipe({
  name: 'usersForRole',
  standalone: true,
})
export class UsersForRolePipe implements PipeTransform {
  transform(role: ProjectInvolvedUserRole, users: ExtendedProjectInvolvedUserType[]): boolean {
    if (role === 'favorite') {
      return true;
    }

    return users.some((user) => user.roles && user.roles.includes(role));
  }
}
