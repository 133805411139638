import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ProgressBarMode } from '@angular/material/progress-bar';

@Component({
  selector: 'apex-box',
  templateUrl: 'box.component.html',
})
export class BoxComponent implements OnInit {
  @ViewChild('expansionPanel') expansionPanel: MatExpansionPanel;
  constructor(private elementRef: ElementRef<HTMLElement>) {}

  @ViewChild('titleRef') private titleRef: ElementRef<HTMLSpanElement>;

  @Input() icon = '';

  @Input() errorIcon = '';

  @Input()
  get expanded(): boolean {
    return this._expanded;
  }

  set expanded(value: boolean | string) {
    this._expanded = coerceBooleanProperty(value);
  }

  @Input()
  get expandable(): boolean {
    return this._expandable;
  }

  set expandable(value: boolean | string) {
    this._expandable = coerceBooleanProperty(value);
  }

  @HostBinding('class.disabled')
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean | string) {
    this._disabled = coerceBooleanProperty(value);
  }

  @HostBinding('class.invalid')
  @Input()
  get invalid(): boolean {
    return this._invalid;
  }

  set invalid(value: boolean | string) {
    this._invalid = coerceBooleanProperty(value);
  }

  getTitle(): string {
    return this.titleRef?.nativeElement?.innerText ?? '';
  }

  getElement(): HTMLElement | null {
    return this.elementRef?.nativeElement ?? null;
  }

  @Input() loading = false;
  @Input() loadingMode: ProgressBarMode = 'indeterminate';
  @Input() loadingProgress: number;
  private _expanded = false;
  private _expandable = true;
  private _disabled = false;
  private _invalid = false;

  ngOnInit(): void {
    /** @todo If this is very importante it should/could be done in the setters/getters instead */
    if (!this.expandable) {
      this.expanded = true;
    }
  }

  isExpanded(): boolean {
    return this.expansionPanel.expanded;
  }
}
