import { LOCALE_ID, Provider } from '@angular/core';
import { TranslationService } from '../components/translate/translation.service';

export class LocaleId extends String {
  constructor(private translationService: TranslationService) {
    super();
  }

  toString(): string {
    return this.translationService.getCurrentLocale();
  }

  valueOf(): string {
    return this.toString();
  }
}

export const LocaleProvider: Provider = {
  provide: LOCALE_ID,
  useClass: LocaleId,
  deps: [TranslationService],
};
