<mat-nav-list>
  <ng-container *ngFor="let group of groups; let l = last">
    <a
      mat-list-item
      lines="3"
      [routerLink]="['/checklist-group', group.id]">
      <mat-checkbox
        *ngIf="hasCheckboxes"
        matListItemIcon
        [checked]="group.selected"
        (change)="toggleChecklist(group, $event)"
        (click)="$event.stopPropagation()"
        [aria-label]="'Select for bulk delete' | translate"
        [matTooltip]="'Select for bulk delete' | translate">
      </mat-checkbox>

      <mat-icon
        *ngIf="!hasCheckboxes"
        matListItemIcon
        [matTooltip]="'Checklist group' | translate">
        group_work
      </mat-icon>

      <h2
        matListItemTitle
        [innerHTML]="group.name | highlight: (searchInput$ | async)"></h2>

      <span
        *ngIf="group.description"
        matListItemLine
        [innerHTML]="group.description | reduceHtmlToString | highlight: (searchInput$ | async)"></span>

      <span
        matListItemLine
        *ngIf="group.createdAt"
        >{{ group.createdAt | date: 'shortDate' }}
        <ng-container *ngIf="group.Field?.name"> - {{ group.Field.name }} </ng-container>
        <ng-container *ngIf="group.ObjectField?.fullName"> - {{ group.ObjectField.fullName }} </ng-container>

        <ng-container *ngIf="group.ObjectId"> - {{ group.Object?.name }} </ng-container>
        <ng-container *ngIf="group.ProjectId && !group.ApartmentId"> - {{ group.Project?.name }} </ng-container>
        <ng-container *ngIf="group.ApartmentId">
          - {{ group.Apartment?.name }} ({{ group.Project?.name }})
        </ng-container>
      </span>

      <span matListItemMeta>
        <apex-progress
          [small]="true"
          [count]="group.caseCount"
          [completed]="group.casesCompleted">
        </apex-progress>
      </span>
    </a>
    <mat-divider *ngIf="!l"></mat-divider>
  </ng-container>
</mat-nav-list>
