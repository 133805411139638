import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ObjectOwnership } from '../../../../models/object-ownership';
import { APIService } from '../../../../services/http/http.service';
import { CollectionResponse, DeleteResponse, EntityResponse } from '../../../../utils/types';

@Injectable()
export class ObjectOwnershipService extends APIService<ObjectOwnership> {
  queryByObject(objectId: number, page: number, limit: number): Observable<CollectionResponse<ObjectOwnership>> {
    this.setRoute(objectId);

    return super.query({
      params: {
        page: String(page),
        limit: String(limit),
      },
    });
  }

  getByObject(objectId: number, ownershipId: string): Observable<EntityResponse<ObjectOwnership>> {
    this.setRoute(objectId);

    return super.get(ownershipId);
  }

  saveByObject(ownership: ObjectOwnership): Observable<EntityResponse<ObjectOwnership>> {
    this.setRoute(ownership.ObjectId);

    return super.save(ownership);
  }

  deleteByObject(ownership: ObjectOwnership): Observable<DeleteResponse> {
    this.setRoute(ownership.ObjectId);

    return super.delete(ownership.id);
  }

  private setRoute(objectId: number): void {
    this.route = `object/${objectId}/ownership`;
  }
}
