import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { isEqual } from 'lodash-es';
import { constants } from 'projects/apex/src/app/utils/constants';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Agreement } from './agreement.model';
import { AgreementService } from './agreement.service';

@Component({
  selector: 'apex-agreements',
  templateUrl: './list.component.html',
})
export class AgreementsComponent implements OnInit, OnDestroy {
  @Input() objectId: number;

  agreements: Agreement[] = [];

  search = '';
  active = false;

  count: number;
  page = 0;
  limit = 5;

  viewAgreements$: Observable<Agreement[]>;

  private subscription = new Subscription();
  private searchSubject = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: AgreementService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.queryParams
        .pipe(
          map((params) => ({
            agreementSearch: params.agreementSearch,
            agreementActive: params.agreementActive === 'true',
            agreementLimit: Number(params.agreementLimit) || 5,
            agreementPage: Number(params.agreementPage) || 0,
          })),
          distinctUntilChanged((x, y) => isEqual(x, y)),
        )
        .subscribe((params) => {
          this.search = params.agreementSearch ?? this.search;
          this.active = params.agreementActive;
          this.limit = Number(params.agreementLimit) || 5;
          this.page = Number(params.agreementPage) || 0;
        }),
    );

    this.subscription.add(
      this.route.data.subscribe((data) => {
        this.objectId = data.project?.id ?? -1;
      }),
    );

    this.viewAgreements$ = this.route.data.pipe(
      map((data: { agreements: { Collection: Agreement[] } }) => data.agreements.Collection),
    );

    this.subscription.add(
      this.searchSubject.pipe(debounceTime(constants.inputDebounceTime)).subscribe((_) => this.updateQueryparams()),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onChangeSearch(): void {
    this.page = 0;

    this.searchSubject.next();
  }

  onChangePage(e: PageEvent): void {
    this.limit = e.pageSize;
    this.page = e.pageIndex;

    this.searchSubject.next();
  }

  updateQueryparams(): void {
    const queryParams: Params = {
      agreementSearch: this.search ?? '',
      agreementActive: this.active,
      agreementPage: this.page ?? 0,
      agreementLimit: this.limit ?? 5,
    };

    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }
}
