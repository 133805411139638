import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ImageViewerComponent } from './image-viewer.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileService } from 'projects/apex/src/app/components/file-usage/file.service';
import { PDFViewerModule } from 'projects/apex/src/app/components/pdf-viewer/pdf-viewer.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { URLCacheModule } from '../../pipes/url-cache/url-cache.module';

const components = [ImageViewerComponent];

@NgModule({
  imports: [
    CommonModule,
    PDFViewerModule,
    DragDropModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,

    PortalModule,

    URLCacheModule,
    OverlayModule,
  ],
  providers: [FileService],
  declarations: components,
  exports: components,
})
export class ImageViewerModule {}
