import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '../translate/translate.module';
import { DateTimeComponent } from './date-time.component';
import { ValidateDateTimeInputDirective, ValidateTimeDirective } from './date-time.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatAutocompleteModule,

    TranslateModule,
  ],
  exports: [DateTimeComponent, ValidateDateTimeInputDirective],
  declarations: [DateTimeComponent, ValidateTimeDirective, ValidateDateTimeInputDirective],
  providers: [],
})
export class DateTimeModule {}
