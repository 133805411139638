import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TextListComponent } from 'projects/apex/src/app/components/text-list/text-list.component';
import { TextListModule } from 'projects/apex/src/app/components/text-list/text-list.module';
import { User } from 'projects/apex/src/app/models/user';
import { Text } from '../../models/text';

interface UsageExplorerData {
  hideTextByIds: number[];
  selectedTexts: Text[];
}

@Component({
  selector: 'apex-usage-explorer',
  templateUrl: './usage-explorer.component.html',
})
export class UsageExplorerComponent implements OnInit {
  @ViewChild(TextListModule) textList: TextListComponent;

  profile: User;

  selectedTexts: Text[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UsageExplorerData,
    public ref: MatDialogRef<UsageExplorerComponent>,
  ) {}

  textFilter: (text: Text) => boolean = () => true;

  ngOnInit(): void {
    if (this.data.hideTextByIds) {
      this.textFilter = (text: Text): boolean => !this.data.hideTextByIds.find((id) => text.id === id);
    }

    if (this.data.selectedTexts) {
      this.selectedTexts = this.data.selectedTexts;
    }
  }

  choose(): void {
    this.ref.close({ texts: this.selectedTexts });
  }
}
