<form
  *ngIf="group"
  #form="ngForm"
  class="df c"
  (submit)="save()">
  <mat-form-field>
    <input
      matInput
      type="text"
      name="name"
      required
      apexAutoFocus
      [disabled]="disabled"
      [(ngModel)]="group.name"
      [placeholder]="'Title' | translate" />
  </mat-form-field>
  <div class="amb w-100">
    <apex-box
      expanded
      class="f1 amvs no-padding">
      <T
        title
        str="Description"></T>

      <editor
        [init]="{ placeholder: 'Description' | translate } | editorInit"
        name="description"
        [(ngModel)]="group.description"
        [disabled]="disabled">
      </editor>
    </apex-box>
  </div>
  <ng-container *ngIf="!disabled; else objectView">
    <apex-object-selector
      name="ObjectId"
      [multiple]="false"
      [(ngModel)]="group.ObjectId"
      [disabled]="disabled || !!group.ProjectId"
      (ngModelChange)="$event && (group.ProjectId = null); getObjectFields($event)">
    </apex-object-selector>
  </ng-container>
  <ng-template #objectView>
    <ng-container *ngIf="!!group?.Object?.name">
      <mat-form-field>
        <mat-label>
          <T str="Project"></T>
        </mat-label>
        <input
          matInput
          type="text"
          name="object-view"
          [ngModel]="group?.Object?.name"
          disabled />
      </mat-form-field>
    </ng-container>
  </ng-template>
  <ng-container *ngIf="!!group.ObjectId">
    <ng-container *ngIf="!disabled; else objectFieldView">
      <mat-form-field>
        <mat-label>
          <T str="Discipline"></T>
        </mat-label>
        <mat-select
          [value]="group.ObjectFieldId"
          name="ObjectField"
          (selectionChange)="setObjectField($event)"
          [disabled]="disabled">
          <mat-option [value]="null">
            <T str="None"></T>
          </mat-option>
          <ng-container *ngFor="let objectField of objectFields">
            <mat-option [value]="objectField.id">
              {{ objectField?.fullName }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-template #objectFieldView>
      <ng-container *ngIf="!!group?.ObjectField?.fullName">
        <mat-form-field>
          <mat-label>
            <T str="Discipline"></T>
          </mat-label>
          <input
            matInput
            type="text"
            name="object-field-view"
            [ngModel]="group.ObjectField.fullName"
            disabled />
        </mat-form-field>
      </ng-container>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="!disabled; else projectView">
    <apex-autocomplete
      name="ProjectId"
      type="Project"
      [label]="'Project' | translate"
      [(ngModel)]="group.ProjectId"
      [disabled]="disabled || !!group.ObjectId"
      (modelChange)="getFields($event)"
      (ngModelChange)="group.ApartmentId = null; $event && (group.ObjectId = null)">
    </apex-autocomplete>
  </ng-container>
  <ng-template #projectView>
    <ng-container *ngIf="!!group?.Project?.name">
      <mat-form-field>
        <mat-label>
          <T str="Project"></T>
        </mat-label>
        <input
          matInput
          type="text"
          name="project-view"
          [ngModel]="group?.Project?.name"
          disabled />
      </mat-form-field>
    </ng-container>
  </ng-template>
  <ng-container *ngIf="!!group.ProjectId || !!group.ApartmentId">
    <ng-container *ngIf="!disabled; else fieldView">
      <mat-form-field>
        <mat-label>
          <T str="Discipline"></T>
        </mat-label>
        <mat-select
          [value]="group.FieldId"
          name="Field"
          (selectionChange)="setField($event)"
          [disabled]="disabled">
          <mat-option [value]="null">
            <T str="None"></T>
          </mat-option>
          <ng-container *ngFor="let field of fields">
            <mat-option [value]="field.id">
              {{ field.name }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-template #fieldView>
      <ng-container *ngIf="!!group?.Field?.name">
        <mat-form-field>
          <mat-label>
            <T str="Discipline"></T>
          </mat-label>
          <input
            matInput
            type="text"
            name="field-view"
            [ngModel]="group?.Field?.name"
            disabled />
        </mat-form-field>
      </ng-container>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="!disabled; else apartmentView">
    <apex-autocomplete
      name="ApartmentId"
      type="Apartment"
      [label]="'Unit' | translate"
      [(ngModel)]="group.ApartmentId"
      [params]="{ ProjectId: group?.ProjectId ?? null }"
      [disabled]="disabled || !!group.ObjectId || !group.ProjectId">
    </apex-autocomplete>
  </ng-container>
  <ng-template #apartmentView>
    <ng-container *ngIf="!!group?.Apartment?.name">
      <mat-form-field>
        <mat-label>
          <T str="Apartment"></T>
        </mat-label>
        <input
          matInput
          type="text"
          name="apartment-view"
          [ngModel]="group?.Apartment?.name"
          disabled />
      </mat-form-field>
    </ng-container>
  </ng-template>
  <apex-autocomplete
    name="CaseManagerId"
    type="User"
    [label]="'Case manager' | translate"
    [disabled]="disabled"
    [(ngModel)]="group.CaseManagerId">
  </apex-autocomplete>
  <button
    type="submit"
    class="dn"></button>
</form>
