<ng-container *ngIf="profile$ | async as profile">
  <button
    mat-button
    [apexAutoFocus]="!!autofocus"
    [color]="color"
    [attr.color]="color"
    [matTooltip]="text"
    [matTooltipDisabled]="profile.showTextOnButtons"
    [class.mat-mdc-icon-button]="!profile.showTextOnButtons"
    [class.mat-mdc-raised-button]="raised"
    [disabled]="disabled">
    <ng-container *ngIf="profile.showTextOnButtons; else showIcon">
      <div
        class="df ac"
        [class.row]="direction === 'horizontal'"
        [class.column]="direction === 'vertical'">
        <mat-icon
          [class.amts]="direction === 'vertical'"
          [svgIcon]="svgIcon">
          {{ icon }}
        </mat-icon>

        <span class="ams truncate">
          {{ text }}
        </span>
      </div>
    </ng-container>
    <ng-template #showIcon>
      <mat-icon [svgIcon]="svgIcon">
        {{ icon }}
      </mat-icon>
    </ng-template>
  </button>
</ng-container>
