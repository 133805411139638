<div
  class="df c"
  [ngClass]="{
    'mat-elevation-z2': checklistSection?.id && !checklistSection?.ParentId,
    amb: checklistSection?.id && !checklistSection?.ParentId,
  }">
  <div
    *ngIf="checklistSection?.id"
    class="checklist-section df ac">
    <div class="aml f1 mat-body-2">{{ step }} {{ checklistSection.name }}</div>
    <div class="amrs">
      <button
        mat-icon-button
        [disabled]="!items.length && !sections.length && !checklistSection?.description ? true : false"
        (click)="expanded = !expanded">
        <mat-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</mat-icon>
      </button>
    </div>
  </div>
  <ng-container *ngIf="expanded">
    <div *ngIf="checklistSection?.id && checklistSection?.description">
      <div class="amh amvs mat-caption">{{ checklistSection.description }}</div>
      <mat-divider></mat-divider>
    </div>
    <apex-checklist-item-list
      *ngIf="checklistSection?.id && items?.length"
      [sectionName]="step"
      [checklist]="checklist"
      [checklistItems]="items"
      (deleteComplete)="deleteComplete.emit()">
    </apex-checklist-item-list>
    <apex-checklist-section
      *ngFor="let section of sections; let i = index"
      [checklist]="checklist"
      [checklistItems]="checklistItems"
      [checklistSections]="checklistSections"
      [checklistSection]="section"
      [step]="step ? step + '.' + (i + items?.length + 1) : '' + (i + 1)">
    </apex-checklist-section>
    <apex-checklist-item-list
      *ngIf="!checklistSection?.id && items?.length"
      [sectionNameOffset]="sections.length"
      [checklist]="checklist"
      [checklistItems]="items"
      (deleteComplete)="deleteComplete.emit()">
    </apex-checklist-item-list>
  </ng-container>
</div>
