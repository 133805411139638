<mat-drawer-container
  class="f1"
  (swipeRight)="mobile && (opened = false)"
  (swipeLeft)="mobile && (opened = true)"
  hasBackdrop="false">
  <mat-drawer
    [mode]="mobile ? 'over' : 'side'"
    position="end"
    [(opened)]="opened"
    class="d-w-20 t-w-30 p-w-100">
    <mat-selection-list
      #selectedTextsList
      [(ngModel)]="selection"
      [multiple]="multiple"
      [compareWith]="compareFunction">
      <mat-list-option
        *ngFor="let t of filteredTexts(texts)"
        [value]="t">
        <div
          class="f1 df ac truncate"
          (click)="$event.stopPropagation(); id = t.id; mobile && (opened = false)">
          {{ t.name }}
        </div>
      </mat-list-option>
    </mat-selection-list>
  </mat-drawer>
  <mat-drawer-content class="df">
    <apex-text-edit
      [text]="text"
      (textSaved)="textSaved($event)"
      (textDeleted)="textDeleted(text)">
    </apex-text-edit>
  </mat-drawer-content>
</mat-drawer-container>
