<div class="image-container f1">
  <p
    class="mat-caption"
    *ngIf="!ready">
    <T str="Loading"></T>
  </p>
  <img
    id="image"
    #image
    [class.vh]="!ready"
    [src]="imageUrl"
    (load)="imageLoaded($event)"
    (error)="imageError()" />
</div>
