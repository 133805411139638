import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ChecklistTemplateApi,
  ResponseClonedChecklistTemplateDtoEntityResponse,
} from '../../../../../../generated/apex-rest';
import { ObjectField } from '../../models/object-field';
import { ProjectToFieldTemplate } from '../../models/project-to-field-template';
import { HttpExtra } from '../../services/http/http-extra';
import { APIService, HttpService } from '../../services/http/http.service';
import { restAxiosConfig } from '../../utils/rest-axios-config';
import { CollectionResponse, DeleteResponse, EntityResponse } from '../../utils/types';
import { ChecklistTemplate, ChecklistTemplateItem, ChecklistTemplateSection } from './checklist-template.model';

type ModelName = 'object' | 'project';
type Collection = CollectionResponse<ChecklistTemplate>;
type Entity = EntityResponse<ChecklistTemplate>;

@Injectable()
export class ChecklistTemplateService extends APIService<ChecklistTemplate> {
  route = 'checklist-template';

  queryModel(modelName: ModelName, modelId: number, extra?: HttpExtra): Observable<Collection> {
    return this.http.get<Collection>(`${this.apiUrl}/${modelName}/${modelId}/${this.route}`, this.options(extra));
  }

  getByModel(modelName: ModelName, modelId: number, templateId: number, extra?: HttpExtra): Observable<Entity> {
    return this.http.get<Entity>(
      `${this.apiUrl}/${modelName}/${modelId}/${this.route}/${templateId}`,
      this.options(extra),
    );
  }

  addToModel(association: ProjectToFieldTemplate): Observable<ProjectToFieldTemplate> {
    const { model, modelId, ChecklistTemplateId } = association;

    return this.http.post<ProjectToFieldTemplate>(
      `${this.apiUrl}/${model}/${modelId}/checklist-template/${ChecklistTemplateId}/add`,
      association,
      this.options(),
    );
  }

  deleteFromModel(modelName: ModelName, modelId: number, templateId: number): Observable<DeleteResponse> {
    return this.http.get<DeleteResponse>(
      `${this.apiUrl}/${modelName}/${modelId}/checklist-template/${templateId}/remove`,
      this.options(),
    );
  }

  getObjectFields(templateId: number): Observable<ObjectField[]> {
    return this.http
      .get<ObjectField[]>(`${this.apiUrl}/${this.route}/${templateId}/object-field`, this.options())
      .pipe(map((objectFields) => objectFields.map((objectField) => new ObjectField(objectField))));
  }

  async cloneTemplate(templateId: number): Promise<ResponseClonedChecklistTemplateDtoEntityResponse> {
    const api = new ChecklistTemplateApi(restAxiosConfig());

    const response = await api.checklistTemplateControllerClone(templateId);

    return response.data;
  }
}

@Injectable()
export class ChecklistTemplateItemService extends HttpService<ChecklistTemplateItem> {
  route = 'checklist-template';

  getItems(templateId: number): Observable<ChecklistTemplateItem[]> {
    return this.http.get<ChecklistTemplateItem[]>(`${this.apiUrl}/${this.route}/${templateId}/item`, this.options);
  }

  saveItem(templateId: number, model: ChecklistTemplateItem): Observable<ChecklistTemplateItem> {
    return model.id
      ? this.http.post<ChecklistTemplateItem>(
          `${this.apiUrl}/${this.route}/${templateId}/item/${model.id}`,
          model,
          this.options,
        )
      : this.http.post<ChecklistTemplateItem>(`${this.apiUrl}/${this.route}/${templateId}/item`, model, this.options);
  }

  deleteItem(templateId: number, model: ChecklistTemplateItem): Observable<ChecklistTemplateItem> {
    return this.http.delete<ChecklistTemplateItem>(
      `${this.apiUrl}/${this.route}/${templateId}/item/${model.id}`,
      this.options,
    );
  }
}

@Injectable()
export class ChecklistTemplateSectionService extends HttpService<ChecklistTemplateSection> {
  route = 'checklist-template';

  getSections(templateId: number): Observable<ChecklistTemplateSection[]> {
    return this.http.get<ChecklistTemplateSection[]>(
      `${this.apiUrl}/${this.route}/${templateId}/section`,
      this.options,
    );
  }

  saveSection(templateId: number, model: ChecklistTemplateSection): Observable<ChecklistTemplateSection> {
    return model.id
      ? this.http.post<ChecklistTemplateSection>(
          `${this.apiUrl}/${this.route}/${templateId}/section/${model.id}`,
          model,
          this.options,
        )
      : this.http.post<ChecklistTemplateSection>(
          `${this.apiUrl}/${this.route}/${templateId}/section`,
          model,
          this.options,
        );
  }

  deleteSection(templateId: number, model: ChecklistTemplateSection): Observable<ChecklistTemplateSection> {
    return this.http.delete<ChecklistTemplateSection>(
      `${this.apiUrl}/${this.route}/${templateId}/section/${model.id}`,
      this.options,
    );
  }
}
