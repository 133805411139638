import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { HttpExtra } from 'projects/apex/src/app/services/http/http-extra';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Tenancy } from '../../../../models/tenancy';
import { TenancyService } from './tenancy.service';

export const TenancyResolver: ResolveFn<Tenancy> = (route: ActivatedRouteSnapshot) => {
  const service = inject(TenancyService);

  const id = Number(route?.params?.tid);

  return id
    ? service.get(id).pipe(map((res) => res.Entity))
    : of(
        new Tenancy({
          VAT: 25,

          from: new Date(),

          description: '',
          informationExternal: '',
          informationInternal: '',
        }),
      );
};

export const TenanciesResolver: ResolveFn<Tenancy[]> = (route: ActivatedRouteSnapshot) => {
  const service = inject(TenancyService);

  const { showAsClient } = route.queryParams;

  const extra: HttpExtra = {
    params: {
      showAsClient: showAsClient ?? false,
    },
  };

  return service.query(extra).pipe(map((res) => res.Collection));
};

export const PortalTenanciesResolver: ResolveFn<Tenancy[]> = () => {
  const service = inject(TenancyService);

  return service.queryAsClient().pipe(map((res) => res.Collection));
};

export const ObjectTenanciesResolver: ResolveFn<Tenancy[]> = (route: ActivatedRouteSnapshot) => {
  const service = inject(TenancyService);

  const id = Number(route?.parent?.parent?.params?.pid);

  return id ? service.queryWithObject(id).pipe(map((res) => res.Collection)) : of([]);
};

export const ObjectTenanciesQueryParamResolver: ResolveFn<Tenancy[]> = (route: ActivatedRouteSnapshot) => {
  const service = inject(TenancyService);

  const id = Number(route?.queryParams.Object);

  return id ? service.queryWithObject(id).pipe(map((res) => res.Collection)) : of([]);
};

export const TenancyQueryParamResolver: ResolveFn<Tenancy> = (route: ActivatedRouteSnapshot) => {
  const service = inject(TenancyService);

  const id = Number(route?.queryParams.Tenancy);

  if (id) {
    return service.get(id).pipe(map((res) => res.Entity));
  }

  return of(null);
};

export const TenancyObjectTreeResolver: ResolveFn<Tenancy[]> = (route: ActivatedRouteSnapshot) => {
  const service = inject(TenancyService);

  const id = Number(route.params.tid);

  return id ? service.tree(id).pipe(map((res) => res.Collection)) : of([]);
};
