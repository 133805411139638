import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FolderTreeComponent } from 'projects/apex/src/app/components/folder/tree/tree.component';

@Component({
  selector: 'apex-folder-tree-dialog',
  templateUrl: './dialog.component.html',
})
export class FolderTreeDialogComponent implements OnInit {
  @ViewChild(FolderTreeComponent) tree: FolderTreeComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: null,
    public ref: MatDialogRef<FolderTreeDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.ref?.addPanelClass(['apex-fullscreen-dialog', 'phone']);
  }

  confirm(): void {
    this.ref.close({
      folder: this.tree.folder,
    });
  }

  get disabled(): boolean {
    return (
      !this.tree?.folder ||
      this.tree?.folder?.modelName === 'upload' ||
      this.tree?.folder?.id === 'home' ||
      this.tree?.folder?.id === 'share'
    );
  }
}
