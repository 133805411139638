import { FileUsage } from '../../models/file-usage';
import { Marking, MarkingModelType } from '../../models/marking';

export enum FileUsageViewerMode {
  View = 'view',
  Mark = 'mark',
  Draw = 'draw',
}

export interface FileUsageViewerModelData {
  model: MarkingModelType;
  modelId?: number;
}

export interface FileUsageViewerMarkingData {
  min?: number;
  max?: number;
  width?: number;
  height?: number;
  showId?: boolean;
  showModelId?: boolean;
  highlightedMarkingIds?: number[];
  highlightedMarkingModelIds?: number[];
}

export interface FileUsageViewerDrawingData {
  min?: number;
  max?: number;
}

export interface FileUsageViewerData {
  fileUsages: FileUsage[];
  mode: FileUsageViewerMode;
  editable: boolean;
  client: boolean;

  disabled?: boolean;
  editLocked?: boolean;
  deleteDisabled?: boolean;
  saveDisabled?: boolean;
  hidePreviews?: boolean;
  fileUsageViewerComment?: string;
  showFileUsageViewerComment?: boolean;
  startingIndex?: number;
  fullscreen?: boolean; // if the component should start in fullscreen
  selfOpened?: boolean; // if the component is a dialog opened by fileUsageView
  modelData?: FileUsageViewerModelData;
  markingData?: FileUsageViewerMarkingData;
  drawingData?: FileUsageViewerDrawingData;
  hideFullscreen?: boolean;

  text?: string;

  hideHeader?: boolean;
  hideHeaderTitle?: boolean;
  hideHeaderMeta?: boolean;
}

export interface FileUsageViewerCloseData {
  valid: boolean;
  comment: string;
  markings: Marking[];
}
