import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import AvatarTooltipPipe from './avatar-tooltip.pipe';
import { AvatarComponent } from './avatar.component';

@NgModule({
  imports: [CommonModule, MatTooltipModule],
  exports: [AvatarComponent],
  declarations: [AvatarComponent, AvatarTooltipPipe],
  providers: [],
})
export class AvatarModule {}
