import { ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { FileUsageViewerComponent } from 'projects/apex/src/app/components/file-usage-viewer/file-usage-viewer.component';
import {
  FileUsageViewerData,
  FileUsageViewerMode,
} from 'projects/apex/src/app/components/file-usage-viewer/file-usage-viewer.types';
import { Case } from 'projects/apex/src/app/models/case';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { Marking, MarkingModelType } from 'projects/apex/src/app/models/marking';
import { sortFileUsages } from '../../../utils/functions';
import { CaseService } from '../case.service';
import { CaseViewDialogComponent } from '../view/dialog.component';

@Component({
  selector: 'apex-case-marking',
  templateUrl: './marking.component.html',
})
export class CaseMarkingComponent implements OnChanges {
  @Input() floorplans: FileUsage[] = [];
  @Input() cases: Case[] = [];

  @Input() highlightedCaseIds: number[] = [];
  @Input() editable = true;
  @Input() selectedIndex = 0;
  @Input() dialogClass: string;
  @Input() loading: boolean;
  @Input() disabled = false;

  @Output() fileUsageClick = new EventEmitter<{ marking: Marking; fileUsage: FileUsage }>();
  @Output() caseChange = new EventEmitter<Case>();
  @Output() selectedIndexChanged = new EventEmitter<number>();

  @ViewChild('hoverTemplate') hoverTemplate: CdkPortal;
  @ViewChild('fileUsageViewer') fileUsageViewer: FileUsageViewerComponent;

  public selectedMarking: Marking;
  public fileUsageViewerData: FileUsageViewerData;
  public hoverCase: Case;
  public isHoverCaseHighlighted: boolean;

  hoverTarget: HTMLElement;
  hoverRef: OverlayRef;

  dialogRef: MatDialogRef<CaseViewDialogComponent>;

  private readonly markingWidth = 20;
  private readonly markingHeight = 20;

  constructor(
    private dialog: MatDialog,
    private caseService: CaseService,
    private overlay: Overlay,
    private router: Router,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.highlightedCaseIds && this.fileUsageViewer?.fileUsageViewerData?.markingData) {
      this.fileUsageViewer.fileUsageViewerData.markingData.highlightedMarkingModelIds = this.highlightedCaseIds;
    }

    if (changes.floorplans || changes.cases) {
      this.updateFileUsageViewerData();
    }
  }

  updateFileUsageViewerData(): void {
    this.fileUsageViewerData = {
      fileUsages: this.getFloorplans(this.floorplans, this.cases),
      mode: FileUsageViewerMode.Mark,
      modelData: { model: MarkingModelType.Case },
      markingData: {
        width: this.markingWidth,
        height: this.markingHeight,
        showModelId: true,
        highlightedMarkingModelIds: this.highlightedCaseIds,
      },
      editable: this.editable,
      disabled: this.disabled,
      editLocked: true,
      client: false,
      deleteDisabled: true,
      hideFullscreen: true,
      startingIndex: this.selectedIndex,
    };
  }

  refreshFloorplans(): void {
    this.hoverMarkingEnd();
    this.fileUsageViewerData.fileUsages = this.getFloorplans(this.floorplans, this.cases);
  }

  newCase(event: { marking: Marking; fileUsage: FileUsage }): void {
    this.fileUsageClick.emit(event);
  }

  viewCase(marking: Marking): void {
    this.selectedMarking = marking;

    if (marking.modelId) {
      if (this.dialogRef) {
        this.dialogRef.componentInstance.viewComponent.id = marking.modelId;
      } else {
        this.caseService.get(marking.modelId).subscribe({
          next: (c: Case) => {
            this.hoverRef?.dispose();
            this.dialogRef = this.dialog.open(CaseViewDialogComponent, {
              data: {
                case: c,
                highlighted: !!this.highlightedCaseIds.find((id) => id === c.id),
                contentClass: this.dialogClass,
              },
            });

            const floorplan = this.fileUsageViewerData.fileUsages.find(
              (f) => f.id === this.selectedMarking.FileUsageId,
            );
            const subs = [
              this.dialogRef.componentInstance.next.subscribe({
                next: () => {
                  let index = floorplan.Markings.map((m) => m.id).indexOf(this.selectedMarking.id) + 1;

                  if (index > floorplan.Markings.length - 1) {
                    index = 0;
                  }

                  if (floorplan.Markings[index]) {
                    this.viewCase(floorplan.Markings[index]);
                  }
                },
              }),
              this.dialogRef.componentInstance.back.subscribe({
                next: () => {
                  let index = floorplan.Markings.map((m) => m.id).indexOf(this.selectedMarking.id) - 1;

                  if (index > floorplan.Markings.length - 1) {
                    index = 0;
                  }

                  if (floorplan.Markings[index]) {
                    this.viewCase(floorplan.Markings[index]);
                  }
                },
              }),
              this.dialogRef.componentInstance.caseChange.subscribe({
                next: (changedCase: Case) => {
                  const caseMarking = floorplan.Markings.find((m) => m.modelId === changedCase.id);

                  caseMarking.Case = changedCase;

                  if (changedCase.Markings?.length) {
                    Object.assign(caseMarking, changedCase.Markings[0]);
                  } else {
                    floorplan.Markings = floorplan.Markings.filter((m) => m.modelId !== changedCase.id);
                  }

                  this.caseChange.emit(changedCase);
                },
              }),
              this.dialogRef.afterClosed().subscribe({
                next: (res) => {
                  subs.forEach((s) => s?.unsubscribe());
                  this.dialogRef = null;

                  if (res?.case?.id) {
                    const caseMarking = floorplan.Markings.find((m) => m.modelId === res.case.id);

                    if (caseMarking) {
                      caseMarking.Case = res.case;

                      if (res.case.Markings?.length) {
                        Object.assign(caseMarking, res.case.Markings[0]);
                      } else {
                        floorplan.Markings = floorplan.Markings.filter((m) => m.modelId !== res.case.id);
                      }
                    } else {
                      floorplan.Markings = floorplan.Markings.filter((m) => m.modelId !== res.case.id);
                    }

                    this.caseChange.emit(res.case);
                  }

                  if (res?.navigate) {
                    void this.router.navigate(res.navigate.commands, res.navigate.extras);
                  }
                },
              }),
            ];
          },
        });
      }
    }
  }

  getFloorplans(floorplans: FileUsage[], cases: Case[]): FileUsage[] {
    floorplans?.forEach((f) => {
      f.Markings = [];
    });

    if (cases?.length) {
      const caseFloorplans = floorplans;

      cases
        .filter((c) => !c.archivedAt)
        .forEach((c: Case) => {
          c?.Markings?.forEach((m: Marking) => {
            if (m.FileUsageId) {
              const existingFloorplan = caseFloorplans.find((cf) => Number(cf.id) === Number(m.FileUsageId));

              if (existingFloorplan) {
                const marking = cloneDeep(m);

                marking.Case = c;
                delete marking.FileUsage;
                existingFloorplan.Markings = (existingFloorplan.Markings ? existingFloorplan.Markings : []).concat([
                  marking,
                ]);
              } else if (m.FileUsage) {
                const marking = cloneDeep(m);
                const fileUsage = cloneDeep(m.FileUsage);

                marking.Case = c;
                delete marking.FileUsage;
                fileUsage.Markings = [marking];
                caseFloorplans.push(fileUsage);
              }
            }
          });
          c?.Floorplans?.forEach((f: FileUsage) => {
            const existingFloorplan = caseFloorplans.find((cf) => Number(cf.id) === Number(f.id));

            if (!existingFloorplan) {
              caseFloorplans.push(cloneDeep(f));
            }
          });
        });

      return sortFileUsages(caseFloorplans);
    } else {
      return sortFileUsages(floorplans);
    }
  }

  resize(): void {
    // @todo resize fileUsageViewer
  }

  hoverMarking(event: { target: EventTarget; marking: Marking }): void {
    if (event.marking.modelId) {
      const arrowSize = 20;
      const arrowOffset = 30;
      const panelOffset = arrowSize / 2;
      const positions: ConnectionPositionPair[] = [
        // top center
        {
          overlayX: 'center',
          overlayY: 'bottom',
          originX: 'center',
          originY: 'top',
          panelClass: ['bottom', 'center'],
          offsetY: -1 * panelOffset,
        },
        // top left
        {
          overlayX: 'start',
          overlayY: 'bottom',
          originX: 'center',
          originY: 'top',
          panelClass: ['bottom', 'left'],
          offsetX: -1 * arrowOffset,
          offsetY: -1 * panelOffset,
        },
        // top right
        {
          overlayX: 'end',
          overlayY: 'bottom',
          originX: 'center',
          originY: 'top',
          panelClass: ['bottom', 'right'],
          offsetX: arrowOffset,
          offsetY: -1 * panelOffset,
        },
        // bottom center
        {
          overlayX: 'center',
          overlayY: 'top',
          originX: 'center',
          originY: 'bottom',
          panelClass: ['top', 'center'],
          offsetY: panelOffset,
        },
        // bottom left
        {
          overlayX: 'start',
          overlayY: 'top',
          originX: 'center',
          originY: 'bottom',
          panelClass: ['top', 'left'],
          offsetX: -1 * arrowOffset,
          offsetY: panelOffset,
        },
        // bottom right
        {
          overlayX: 'end',
          overlayY: 'top',
          originX: 'center',
          originY: 'bottom',
          panelClass: ['top', 'right'],
          offsetX: arrowOffset,
          offsetY: panelOffset,
        },
      ];

      const config = new OverlayConfig();

      config.positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(event.target as HTMLElement)
        .withPush(false)
        .withFlexibleDimensions(false)
        .withPositions(positions);

      this.hoverCase = event.marking.Case;
      this.isHoverCaseHighlighted = this.highlightedCaseIds?.indexOf(this.hoverCase.id) !== -1;

      if (this.hoverRef) {
        return;
      }

      this.hoverRef = this.overlay.create(config);
      this.hoverRef.attach(this.hoverTemplate);
    }
  }

  hoverMarkingEnd(): void {
    if (this.hoverRef) {
      this.hoverRef.detach();
      this.hoverRef.dispose();

      this.hoverRef = null;
    }
  }
}
