<h1 mat-dialog-title>{{ data?.label }}</h1>
<div mat-dialog-content>
  <form
    #mrForm="ngForm"
    class="df c">
    <apex-autocomplete
      *ngIf="!data?.CRM"
      name="name"
      class="f1 amb"
      type="User"
      [label]="data?.label ?? ''"
      [(ngModel)]="modelRole.UserId"
      [params]="data?.params ? data?.params : undefined"
      required
      (modelChange)="setUserName($event)">
    </apex-autocomplete>

    <apex-autocomplete
      *ngIf="data?.CRM"
      name="name"
      class="f1 amb"
      type="CRMUser"
      [label]="data?.label ?? ''"
      [(ngModel)]="modelRole.CRMUserId"
      [params]="data?.params ? data?.params : undefined"
      [required]="true"
      (modelChange)="setUserName($event)">
    </apex-autocomplete>

    <ng-container *ngIf="data.allowedRoles?.length && !data.role">
      <mat-form-field class="f1 amb">
        <mat-label>
          <T str="Role"></T>
        </mat-label>
        <mat-select
          name="roles"
          [(ngModel)]="modelRole.role"
          required>
          <mat-option
            *ngFor="let r of data.allowedRoles"
            [value]="r.value">
            <!-- Translation is handled in ts -->
            {{ r.title | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <mat-form-field class="f1">
      <mat-label>
        <T str="Period"></T>
      </mat-label>
      <mat-date-range-input
        [rangePicker]="picker"
        required>
        <input
          matStartDate
          name="from"
          [(ngModel)]="modelRole.from"
          [placeholder]="'Start date' | translate"
          required />
        <input
          matEndDate
          name="to"
          [(ngModel)]="modelRole.to"
          [placeholder]="'End date' | translate" />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconPrefix
        [for]="picker">
      </mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>
</div>

<div
  mat-dialog-actions
  class="df">
  <button
    mat-button
    mat-dialog-close>
    <T str="Cancel"></T>
  </button>
  <button
    mat-raised-button
    (click)="save()"
    color="primary"
    [disabled]="!mrForm.valid">
    <T str="Save"></T>
  </button>
</div>
