import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HammerTimeModule } from '../../../directives/hammer-time/hammer-time.module';
import { TranslateModule } from '../../translate/translate.module';
import { FileViewerPreviewComponent } from './file-viewer-preview.component';

@NgModule({
  declarations: [FileViewerPreviewComponent],
  imports: [CommonModule, MatIconModule, MatTooltipModule, TranslateModule, HammerTimeModule, MatRippleModule],
  exports: [FileViewerPreviewComponent],
})
export class FileViewerPreviewModule {}
