import { Model } from '../../../../../models/model';
import { Tenancy } from '../../../../../models/tenancy';
import { User } from '../../../../../models/user';
import { EntryGroup } from '../entry-group/entry-group.model';

export class EntryCard extends Model<EntryCard> {
  id: number;

  TenancyId: number;
  Tenancy?: Tenancy;
  UserId: number;
  User: User;

  name: string;
  mail: string;
  mobile: number;

  description: string;
  pin: string;
  serialNumber: string;

  active: boolean;

  EntryGroups?: EntryGroup[];
}
