<mat-form-field class="df c">
  <mat-label>
    <T str="Tags"></T>
  </mat-label>
  <mat-chip-grid #chipList>
    <mat-chip-row
      *ngFor="let tag of selectedTags"
      [disableRipple]="!disabled"
      [removable]="!disabled"
      (removed)="remove(tag)">
      {{ tag.name }}
      <ng-container *ngIf="!disabled">
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </ng-container>
    </mat-chip-row>
    <ng-container *ngIf="!disabled">
      <input
        #tagInput
        [placeholder]="'Add tag' | translate"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (keyup)="handleKey($event)"
        (matChipInputTokenEnd)="add($event)" />
    </ng-container>
  </mat-chip-grid>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="selected($event)">
    <ng-container *ngIf="loading">
      <mat-spinner [diameter]="24"></mat-spinner>
    </ng-container>
    <ng-container *ngIf="!loading">
      <ng-container *ngFor="let tag of filteredTags$ | async">
        <mat-option
          [value]="tag"
          class="df autocomplete-option">
          <div class="w-100 h-100 df ac">
            <div class="df f1 truncate">
              {{ getViewValue(tag) }}
            </div>
            <ng-container *ngIf="!tag.id">
              <mat-icon class="position-absolute right-0"> new_label</mat-icon>
            </ng-container>
          </div>
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
