import { Pipe, PipeTransform } from '@angular/core';
import { CaseContractorStatus as RestCaseContractorStatus } from '../../../../../../../generated/apex-rest';
import { Color } from '../../../components/charts/charts.colors';
import { t } from '../../../components/translate/translate.function';
import { CaseContractorStatus } from '../../../models/case-contractor';

type ContractorWithCaseContractorStatus = {
  CaseContractor?: {
    status: CaseContractorStatus | RestCaseContractorStatus;
  };
};

@Pipe({
  name: 'statusMessageForContractor',
  standalone: true,
})
export class StatusMessageForContractorPipe implements PipeTransform {
  transform(contractor: ContractorWithCaseContractorStatus): string {
    switch (contractor?.CaseContractor?.status) {
      case CaseContractorStatus.New:
        return t('Pending');
      case CaseContractorStatus.Accepted:
        return t('Accepted');
      case CaseContractorStatus.Declined:
        return t('Denied');
      case CaseContractorStatus.Completed:
        return t('Done');
      default:
        return '';
    }
  }
}

@Pipe({
  name: 'statusColorForContractor',
  standalone: true,
})
export class StatusColorForContractorPipe implements PipeTransform {
  transform(contractor: ContractorWithCaseContractorStatus): Color {
    switch (contractor?.CaseContractor?.status) {
      case CaseContractorStatus.New:
        return Color.AY300;
      case CaseContractorStatus.Accepted:
        return Color.AT500;
      case CaseContractorStatus.Declined:
        return Color.AR300;
      case CaseContractorStatus.Completed:
        return Color.AG300;
      default:
        return Color.AT700;
    }
  }
}

@Pipe({
  name: 'statusIconForContractor',
  standalone: true,
})
export class StatusIconForContractorPipe implements PipeTransform {
  transform(contractor: ContractorWithCaseContractorStatus): string {
    switch (contractor?.CaseContractor?.status) {
      case CaseContractorStatus.New:
        return 'pending';
      case CaseContractorStatus.Accepted:
        return 'thumb_up';
      case CaseContractorStatus.Declined:
        return 'thump_down';
      case CaseContractorStatus.Completed:
        return 'task_alt';
      default:
        return '';
    }
  }
}
