import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '../translate/translate.module';

import { AutoFocusDirectiveModule } from '../../directives/auto-focus/auto-focus..directive.module';
import { ButtonComponent } from './button.component';

@NgModule({
  declarations: [ButtonComponent],
  exports: [ButtonComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, MatTooltipModule, TranslateModule, AutoFocusDirectiveModule],
  providers: [],
})
export class ButtonModule {}
