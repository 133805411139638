<div mat-dialog-title>
  <div class="df jsb ac f1">
    <h1>
      <T
        str="New message on {count, plural, =1 {one case} other {# cases}}"
        [vars]="{ count: data.cases?.length ?? 0 }">
      </T>
    </h1>
    <button
      mat-icon-button
      (click)="component?.sub?.unsubscribe(); dialogRef.close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
<div
  mat-dialog-content
  class="df c jsb">
  <apex-case-new-message
    #component
    [disabled]="dialogRef.disableClose"
    [cases]="data.cases"
    (new)="savedCaseCount = savedCaseCount + 1"
    (messageComplete)="dialogRef.close()">
  </apex-case-new-message>
</div>
<div mat-dialog-actions>
  <div></div>
  <button
    mat-raised-button
    color="primary"
    [disabled]="component?.newMessageForm?.invalid || dialogRef.disableClose"
    (click)="save()">
    <T str="Save"></T>
  </button>
  <mat-progress-bar
    *ngIf="dialogRef.disableClose"
    mode="determinate"
    [value]="(savedCaseCount / data.cases.length) * 100">
  </mat-progress-bar>
</div>
