<ng-container *ngIf="profile$ | async as profile">
  <div class="df r jsb ac f1 aphs">
    <div class="df ac">
      <button
        mat-icon-button
        type="button"
        (click)="toggle()">
        <mat-icon
          *ngIf="drawer.opened"
          [attr.aria-label]="'Close menu' | translate"
          >close
        </mat-icon>
        <mat-icon
          *ngIf="!drawer.opened"
          [attr.aria-label]="'Open menu' | translate"
          >menu
        </mat-icon>
      </button>

      <img
        src="/assets/branding-apex-logo-h-fv2.svg"
        height="56"
        alt="APEX Logo" />
    </div>

    <div class="df ac">
      <a
        mat-icon-button
        routerLink="/case"
        routerLinkActive="router-link-active">
        <mat-icon>list</mat-icon>
      </a>

      <apex-requests-button></apex-requests-button>
    </div>

    <div class="df ac">
      <apex-notification-button></apex-notification-button>
      <apex-bookmark-button></apex-bookmark-button>
      <apex-utils-button></apex-utils-button>
    </div>
  </div>
</ng-container>
