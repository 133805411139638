<button
  type="button"
  mat-icon-button
  [matTooltip]="'Utilities' | translate"
  [matMenuTriggerFor]="utilsMenu">
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu
  #utilsMenu="matMenu"
  [overlapTrigger]="false">
  <button
    type="button"
    mat-menu-item
    (click)="qrCode()">
    <mat-icon>qr_code_2</mat-icon>
    <T str="QR Code"></T>
  </button>

  <mat-divider></mat-divider>

  <button
    type="button"
    mat-menu-item
    (click)="setStartpage()">
    <mat-icon>add_home</mat-icon>
    <T str="Set startpage"></T>
  </button>
</mat-menu>
