<header
  *ngIf="group"
  class="ac tree-header"
  [ngClass]="{
    dn: hideHeader,
    df: !hideHeader,
  }">
  <button
    mat-icon-button
    (click)="expanded = !expanded"
    class="expand-button">
    <mat-icon>{{ expanded ? 'arrow_drop_down' : 'arrow_right' }}</mat-icon>
  </button>
  <div class="f1 df r ac oh">
    <apex-progress
      [count]="group.caseCount"
      [completed]="group.casesCompleted"
      [small]="true">
    </apex-progress>
    <div class="amhs f1 df c oh">
      <div class="truncate mat-subtitle-2">{{ group.name }}</div>
      <div class="truncate mat-caption">
        {{ group.description | reduceHtmlToString }}
      </div>
    </div>
  </div>
</header>
<main
  [ngClass]="{ dn: !expanded }"
  [style.marginLeft]="hideHeader ? null : 38 + 'px'">
  <mat-expansion-panel
    *ngFor="let c of group?.Checklists || []"
    [expanded]="showItems">
    <mat-expansion-panel-header>
      <div class="f1 df ac oh">
        <mat-icon class="ams">list_alt</mat-icon>
        <apex-progress
          [count]="c.caseCount"
          [completed]="c.casesCompleted"
          [small]="true">
        </apex-progress>
        <div class="amhs f1 df c oh">
          <div class="mat-body-2 truncate">{{ c.name }}</div>
        </div>
        <a
          class="amrs"
          mat-icon-button
          [matTooltip]="'Go to checklist' | translate"
          [routerLink]="['/', 'checklist', c.id]"
          (click)="$event.stopPropagation()">
          <mat-icon>open_in_new</mat-icon>
        </a>
      </div>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <apex-checklist-group-checklist
        [checklist]="c"
        (countChange)="updateCount()">
      </apex-checklist-group-checklist>
    </ng-template>
  </mat-expansion-panel>
  <div>
    <apex-checklist-group-tree
      *ngFor="let c of group?.Children || []"
      [group]="c"
      [step]="step + 1"
      [showItems]="showItems"
      (countChange)="updateCount()">
    </apex-checklist-group-tree>
  </div>
</main>
