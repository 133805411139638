<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid
    #chipList
    [disabled]="disabled">
    <mat-chip-row
      *ngFor="let m of selectedModels"
      [removable]="true"
      (removed)="remove(m)">
      {{ getModelName(m) }}
      <mat-icon matChipRemove>clear</mat-icon>
    </mat-chip-row>
    <input
      #queryInput
      [(ngModel)]="query"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      (focus)="models = null; queryRequest$.next(query)"
      (click)="queryRequest$.next(query); autoTrigger.openPanel()" />
  </mat-chip-grid>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="selected($event?.option?.value)">
    <ng-container *ngIf="!loading">
      <mat-option
        *ngFor="let m of filteredModels"
        [value]="m"
        [title]="getModelName(m)"
        class="df autocomplete-option">
        <div class="w-100 h-100 df ac">
          <div class="f1 truncate">
            {{ m?.name }}
            <div
              class="mat-caption"
              *ngIf="m?.Parent">
              {{ m.Parent.name }}
              <ng-container *ngIf="m?.Parent?.Parent"> / {{ m.Parent.Parent.name }} </ng-container>
            </div>
          </div>
          <div class="position-absolute right-0">
            <apex-user-favorite-button
              *ngIf="favoriteAvailable"
              [modelName]="model"
              [modelId]="m.id">
            </apex-user-favorite-button>
          </div>
        </div>
      </mat-option>
    </ng-container>
    <mat-option
      *ngIf="loading"
      disabled
      class="loading">
      <mat-spinner diameter="35"></mat-spinner>
    </mat-option>
    <mat-option
      *ngIf="!loading && !models?.length && !filteredModels?.length"
      disabled>
      <T
        str="No matches found"
        *ngIf="model || !favoriteAvailable"></T>
      <T
        str="No favorites saved"
        *ngIf="!(model || !favoriteAvailable)"></T>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
