import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { startCase, toLower } from 'lodash-es';
import { Observable, Subject, Subscription, of, timer } from 'rxjs';
import { debounce, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Autocomplete } from '../../models/autocomplete';
import { queryCaseCategoriesAsFilter$ } from '../../models/case-category.data';
import { Filter } from '../../models/filter';
import { UserFavorite } from '../../models/user-favorite';
import { FilterService } from '../../services/filter/filter.service';
import { FilterType } from '../../services/filter/filter.service.types';
import { UserService } from '../../services/user/user.service';
import { constants } from '../../utils/constants';
import { favoriteAvailable, sortAutocompleteFn } from '../../utils/functions';

@Component({
  selector: 'apex-filter-chips',
  templateUrl: './filter-chips.component.html',
})
export class FilterChipsComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() queryParamName: string;
  @Input() model: FilterType;
  @Input() disabled: boolean;

  @Output() selectedModelsChange = new EventEmitter<Filter[]>();

  @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;
  @ViewChild('queryInput') queryInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') auto: MatAutocomplete;

  queryValue: string;

  get query(): string {
    return this.queryValue;
  }

  set query(value: string) {
    this.queryValue = value;
    this.queryRequest$.next(value);
  }

  modelIdsValue: number[];
  selectedModels: Filter[] = [];
  filteredModels: Filter[] = [];
  models: Filter[];

  loading = false;
  favoriteAvailable: boolean;

  sub: Subscription;

  queryRequest$ = new Subject<string>();
  queryResponse$: Observable<Filter[]> = this.queryRequest$.pipe(
    tap(() => {
      this.loading = true;
      this.ref.detectChanges();
    }),
    debounce((q: string) => {
      if (!q && this.favoriteAvailable) {
        return of(null);
      }

      return timer(constants.inputDebounceTime);
    }),
    switchMap((query: string) => {
      if (this.models) {
        return this.runQuery(query);
      } else {
        if (!query && this.favoriteAvailable && this.userService.favoritesCount(this.model)) {
          return this.getUserFavorites();
        }

        let service = this.filterService.getFilter(this.model, '*');

        if (this.model === FilterType.CaseCategory) {
          service = queryCaseCategoriesAsFilter$();
        }

        return service.pipe(
          mergeMap((filters: Filter[]) => {
            this.models = filters.filter((f) => !this.selectedModels?.find((m) => m.id === f.id));

            return this.runQuery(query);
          }),
        );
      }
    }),
  );

  private subscriptions: Subscription = new Subscription();

  constructor(
    private filterService: FilterService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.favoriteAvailable = favoriteAvailable(this.model);

    if (!this.queryParamName) {
      this.queryParamName = startCase(toLower(this.model));
    }

    this.subscriptions.add(
      this.route.queryParams
        .pipe(
          filter((qp) => !!qp),
          map((qp) => qp[this.queryParamName]),
          mergeMap((modelIds) => {
            if (modelIds) {
              modelIds = Array.isArray(modelIds) ? modelIds : [modelIds];

              let service = this.filterService.getFilter(this.model, '*');

              if (this.model === FilterType.CaseCategory) {
                service = queryCaseCategoriesAsFilter$();
              }

              return service.pipe(
                map((models) =>
                  models.filter((m) => modelIds.find((id: number) => Number(id) === m.id || id === m.id)),
                ),
                tap((filters) => {
                  const foundFilters = filters.filter((returnedFilter) => modelIds.includes(String(returnedFilter.id)));

                  if (foundFilters.length !== modelIds.length) {
                    const queryParams: Params = {};

                    queryParams[this.queryParamName] = foundFilters.map((foundFilter) => foundFilter.id);

                    void this.router.navigate([], {
                      queryParams,
                      queryParamsHandling: 'merge',
                    });
                  }
                }),
              );
            }

            return of([] as Filter[]);
          }),
        )
        .subscribe((models) => {
          this.selectedModels = models;
        }),
    );

    this.subscriptions.add(
      this.queryResponse$.subscribe({
        next: (filters: Filter[]) => {
          this.filteredModels = filters;
          this.loading = false;
          this.ref.detectChanges();
        },
      }),
    );

    this.subscriptions.add(
      this.route.queryParams.subscribe({
        next: (qp: Params) => {
          if (!qp[this.queryParamName]) {
            this.selectedModels = [];
          }
        },
      }),
    );
  }

  selected(model: Filter): void {
    this.selectedModels.push(model);
    this.selectedModelsChange.emit(this.selectedModels);
    this.query = null;
    this.queryInput.nativeElement.value = '';
    this.navigate();
  }

  remove(model: Filter): void {
    const idx = this.selectedModels.indexOf(model);

    if (idx !== -1) {
      this.selectedModels.splice(idx, 1);
      this.selectedModelsChange.emit(this.selectedModels);
      this.navigate();
    }
  }

  navigate(): void {
    const ids = this.selectedModels.map((m) => m.id);

    void this.router.navigate([], {
      queryParams: { [this.queryParamName]: ids.length ? ids : null },
      queryParamsHandling: 'merge',
    });
  }

  runQuery(query: string): Observable<Filter[]> {
    if (!query && this.favoriteAvailable && this.userService.favoritesCount(this.model)) {
      return this.getUserFavorites();
    }

    if (!query) {
      query = '*';
    }

    return of(this.getfilteredModels(query));
  }

  getUserFavorites(): Observable<Filter[]> {
    return this.userService.favorites$(this.model).pipe(
      map((favs: UserFavorite[]) =>
        favs.map((f) => ({
          id: f.modelId,
          name: f.name,
          Parent: null,
        })),
      ),
    );
  }

  getfilteredModels(query: string): Filter[] {
    if (query && typeof query === 'string') {
      return this.models
        .filter(
          (m: Filter) =>
            !this.selectedModels.find((s) => s.id === m.id) &&
            (query === '*' || query === '' || m?.name?.toLowerCase().includes(query.toLowerCase())),
        )
        .sort((a, b) => sortAutocompleteFn(a, b));
    }

    return [];
  }

  getModelName(model: Autocomplete): string {
    return this.addParentToModelName(model.name, model);
  }

  addParentToModelName(name: string, model: Autocomplete): string {
    if (model.Parent) {
      name += ` (${model.Parent.name})`;

      return this.addParentToModelName(name, model.Parent);
    }

    return name;
  }

  hasSelectedId(id: number | string): boolean {
    const stringId = String(id);

    return this.selectedModels.map((m) => String(m.id)).includes(stringId);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
