import { Model } from '../../../../models/model';
import { Obj } from '../../object.model';

export class Agreement extends Model<Agreement> {
  ObjectId: number;
  Object?: Obj;

  CreatorId: number;

  name: string;
  description: string;

  from: Date;
  to: Date;
  active: boolean;
}
