import { Component, Input } from '@angular/core';
import { IDN } from '../../features/reporting/project-cases-by-day/project-cases-by-day.types';
import { UserWithCustomerOrCompany } from './user-with-customer-or-company.type';

@Component({
  selector: 'apex-user-card',
  templateUrl: './user-card.component.html',
})
export class UserCardComponent {
  @Input() user: UserWithCustomerOrCompany;
  @Input() client = false;
  @Input() clientUrl: (string | number)[] | string | null | undefined = undefined;
  @Input() subtitle = '';
  @Input() subtitleIcon = '';
  @Input() hideButtons = false;

  @Input() close?: () => void;
  showTitle = false;

  get Company(): IDN | undefined {
    if (this.user.Company) {
      return this.user.Company;
    }

    if (this.user.Customer) {
      return this.user.Customer;
    }

    return undefined;
  }

  get CompanyId(): number | undefined {
    if (this.user.CompanyId) {
      return this.user.CompanyId;
    }

    if (this.user.CustomerId) {
      return this.user.CustomerId;
    }

    return undefined;
  }
}
