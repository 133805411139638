import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { Autocomplete } from 'projects/apex/src/app/models/autocomplete';
import { Case, Contractor } from 'projects/apex/src/app/models/case';
import { User } from 'projects/apex/src/app/models/user';
import { snackErr } from 'projects/apex/src/app/modules/snack.module';
import { Subject, concat, from, of } from 'rxjs';
import { catchError, concatMap, finalize, last, tap } from 'rxjs/operators';
import { CaseService } from '../case.service';
import { CaseNewMessageComponent } from '../new-message/new-message.component';

@Component({
  selector: 'apex-case-mass-edit',
  templateUrl: './mass-edit.component.html',
})
export class CaseMassEditComponent implements OnInit {
  @Input() cases: Case[] = [];

  @ViewChild('newMessage', { static: false }) newMessage: CaseNewMessageComponent;

  ProjectId: number;
  removeProject: boolean;
  ApartmentId: number;
  removeApartment: boolean;
  ClientId: number;
  removeClient: boolean;
  CaseManagerId: number;
  removeCaseManager: boolean;
  FieldId: number;
  removeField: boolean;
  CaseCategoryId: number;

  deadline: Moment;

  currentContractorId: number;
  Contractors: Autocomplete[] = [];
  removeOtherContractors: boolean;

  loading = false;
  loadingProgress = 0;

  profile: User;
  isCaseManager = false;

  isSaving$ = new Subject<boolean>();

  constructor(
    private caseSerivce: CaseService,
    @Inject(MAT_DIALOG_DATA) public dialogData: { cases: Case[] },
    public dialogRef: MatDialogRef<CaseMassEditComponent>,
  ) {}

  ngOnInit(): void {
    this.dialogRef.addPanelClass(['apex-fullscreen-dialog', 'phone']);

    if (this.dialogData?.cases?.length) {
      this.cases = this.dialogData.cases;
      this.isCaseManager = !!this.cases.find((c) => c?.access?.caseManager);
    } else {
      this.dialogRef.close();
    }
  }

  addContractor(user: Autocomplete): void {
    if (user) {
      this.Contractors.push(user);
      this.currentContractorId = null;
    }
  }

  removeContractor(user: Autocomplete): void {
    const idx = this.Contractors.map((c) => c.id).indexOf(user.id);

    if (idx !== -1) {
      this.Contractors.splice(idx, 1);
    }
  }

  save(): void {
    this.loadingProgress = 0;
    this.loading = true;
    this.isSaving$.next(true);
    from(this.cases)
      .pipe(
        concatMap((c: Case) => {
          if (c?.access?.caseManager) {
            // Project
            if (this.ProjectId) {
              c.ProjectId = this.ProjectId;
            }

            if (this.removeProject) {
              c.ProjectId = null;
            }

            // Apartment
            if (this.ApartmentId) {
              c.ApartmentId = this.ApartmentId;
            }

            if (this.removeApartment) {
              c.ApartmentId = null;
            }

            // Client
            if (this.ClientId) {
              c.ClientId = this.ClientId;
            }

            if (this.removeClient) {
              c.ClientId = null;
            }

            // CaseManager
            if (this.CaseManagerId) {
              c.CaseManagerId = this.CaseManagerId;
            }

            if (this.removeCaseManager) {
              c.CaseManagerId = null;
            }

            // Field
            if (this.FieldId) {
              c.FieldId = this.FieldId;
            }

            if (this.removeField) {
              c.FieldId = null;
            }

            // CaseCategory
            if (this.CaseCategoryId) {
              c.CaseCategoryId = this.CaseCategoryId;
            }

            // Deadline
            if (this.deadline && this.deadline?.isValid()) {
              c.deadline = this.deadline.hour(16).valueOf();
            } else {
              c.deadline = c.deadline * 1000;
            }
          }

          const caseContractors = c.Contractor ? c.Contractors.concat([c.Contractor]) : c.Contractors;
          const contractorsToRemove = caseContractors.filter(
            (con1) => !this.Contractors.find((con2) => con2.id === con1.id),
          );
          const contractorsToAdd = this.Contractors.filter(
            (con1) => !caseContractors.find((con2) => con2.id === con1.id),
          );

          return concat(
            c?.access?.caseManager ? this.caseSerivce.save(c) : of(c),
            this.removeOtherContractors
              ? from(contractorsToRemove).pipe(
                  concatMap((contractor: Contractor) => this.caseSerivce.removeContractor(c.id, contractor.id)),
                )
              : of(undefined),
            from(contractorsToAdd).pipe(concatMap((contractor) => this.caseSerivce.addContractor(c.id, contractor.id))),
            this.newMessage?.caseLog?.message
              ? this.caseSerivce.postCaseLog(c.id, this.newMessage.caseLog)
              : of(undefined),
            this.caseSerivce.get(c.id),
          ).pipe(
            catchError((err) => {
              if (err.status !== 400) {
                snackErr(t('Could not get case #{id}', { id: c.id }), err);
              }

              return of(null);
            }),
            last(),
            tap((savedCase) => {
              this.loadingProgress = this.loadingProgress + (1 / this.cases.length) * 100;

              if (savedCase) {
                Object.assign(c, savedCase);
              }
            }),
          );
        }),
        finalize(() => {
          this.isSaving$.next(false);
          this.loading = false;

          if (this.newMessage?.caseLog) {
            this.newMessage.newMessageForm.reset();
          }
        }),
      )
      .subscribe();
  }

  pacRemoveChange(which: string, checked: boolean): void {
    if (checked) {
      if (which === 'project') {
        this.removeApartment = true;
        this.removeClient = true;
      }

      if (which === 'apartment') {
        this.removeClient = true;
      }
    }
  }
}
