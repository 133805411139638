<div>
  <a
    mat-icon-button
    routerLink="/request"
    [matTooltip]="'Requests' | translate"
    *ngIf="requests?.count">
    <mat-icon
      [matBadge]="requests?.count"
      matBadgePosition="after">
      inbox
    </mat-icon>
  </a>
</div>
