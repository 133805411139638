import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { UploadModule } from 'projects/apex/src/app/components/upload/upload.module';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgendaButtonModule } from './components/agenda-button/agenda-button.module';
import { AvatarModule } from './components/avatar/avatar.module';
import { AvatarsModule } from './components/avatar/avatars.module';
import DoubleStarComponent from './components/double-star/double-star.component';
import { QrCodeModule } from './components/qr-code/qr-code.module';
import { SidebarService } from './components/sidebar/services/sidebar.service';
import { SidebarModule } from './components/sidebar/sidebar.module';
import { TopbarModule } from './components/topbar/topbar.module';
import { TranslateModule } from './components/translate/translate.module';
import { UserFavoriteButtonModule } from './components/user-favorite-button/user-favorite-button.module';
import UtilsButtonModule from './components/utils-button/utils-button.module';
import { BookmarkModule } from './features/bookmark/bookmark.module';
import { ChecklistGroupModule } from './features/checklist-group/checklist-group.module';
import { CompanyConsentReminderDialogModule } from './features/consent/reminder/company-consent-reminder-dialog.module';
import HelpURLService from './features/help-url/services/help-url.service';
import { ApartmentActionsModule } from './features/project/apartment-actions/apartment-actions.module';
import { RequestModule } from './features/request/request.module';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { RequestStatusInterceptor } from './interceptors/status/request-status.interceptor';
import StatusServiceModule from './interceptors/status/status.service.module';
import { SnackModule } from './modules/snack.module';
import { FilterService } from './services/filter/filter.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { MarkingService } from './services/marking/marking.service';
import { NotificationServiceModule } from './services/notification/notification.service.module';
import { PusherServiceModule } from './services/notification/pusher.service.module';
import { UserService } from './services/user/user.service';
import { RaygunErrorHandler } from './utils/error-handler';
import { locale } from './utils/functions';
import { localeInitializer } from './utils/locale-init';
import { LocaleProvider } from './utils/locale-provider';
import { appAuthDestinationProvider } from './utils/types/app-url.token';

@NgModule({
  declarations: [AppComponent, DoubleStarComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    HttpClientModule,

    MatMomentDateModule,
    MatSidenavModule,
    HammerModule,
    CdkScrollableModule,

    AppRoutingModule,

    SidebarModule,
    TopbarModule,

    // Global services
    SnackModule,
    UploadModule,
    StatusServiceModule,

    CompanyConsentReminderDialogModule,

    // Exposes the components to the Browser (CustomElements)
    UserFavoriteButtonModule,
    BookmarkModule,
    QrCodeModule,
    RequestModule,
    AgendaButtonModule,
    AvatarsModule,
    AvatarModule,
    UtilsButtonModule,

    // Remote these next ones
    ChecklistGroupModule,
    ApartmentActionsModule,

    TranslateModule,
    PusherServiceModule,
    NotificationServiceModule,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    LocaleProvider,
    {
      provide: APP_INITIALIZER,
      useValue: (): Promise<void> => Promise.resolve(localeInitializer(locale())),
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: locale(),
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestStatusInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: RaygunErrorHandler,
    },
    LocalStorageService,
    SidebarService,
    FilterService,

    UserService,
    HelpURLService,

    MarkingService,
    {
      provide: TINYMCE_SCRIPT_SRC,
      useValue: `${environment.appUrl}/tinymce/tinymce.min.js`,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
    appAuthDestinationProvider(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
