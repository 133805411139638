import { CdkDragEnd } from '@angular/cdk/drag-drop';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { File } from 'projects/apex/src/app/models/file';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import { d2r, getImageUrlFromHEIC, getUserRelationForCase } from 'projects/apex/src/app/utils/functions';
import { take } from 'rxjs/operators';
import { FileUsage } from '../../models/file-usage';
import { Marking } from '../../models/marking';
import { User } from '../../models/user';
import { getURLFromCache } from '../../pipes/url-cache/url-cache.pipe';
import { constants } from '../../utils/constants';

@Component({
  selector: 'apex-image-viewer',
  templateUrl: './image-viewer.component.html',
})
export class ImageViewerComponent implements OnInit, OnChanges {
  @Input() profile: User;
  @Input() file: File;
  @Input() fileUsage: FileUsage;
  @Input() markings: Marking[] = [];

  @Input() edit = true;
  @Input() highlightedMarkingIds: number[] = [];
  @Input() highlightedMarkingModelIds: number[] = [];
  @Input() saveDisabled: boolean;
  @Input() deleteDisabled: boolean;

  @Output() loadingChange = new EventEmitter<boolean>();
  @Output() errorChange = new EventEmitter<boolean>();

  @Output() markingCreate = new EventEmitter<Marking>();
  @Output() markingChange = new EventEmitter<Marking>();
  @Output() markingEnter = new EventEmitter<{ marking: Marking; target: HTMLElement }>();
  @Output() markingLeave = new EventEmitter<{ marking: Marking; target: HTMLElement }>();
  @Output() markingClicked = new EventEmitter<Marking>();

  @ViewChild('box') box: ElementRef<HTMLDivElement>;
  @ViewChild('image') image: ElementRef<HTMLImageElement>;

  bufferedLoading: string = '';

  readonly mr = 20;

  loadingValue: boolean;

  get loading(): boolean {
    return this.loadingValue;
  }

  set loading(loading: boolean) {
    this.loadingValue = loading;

    if (!this.loading) {
      void this.resize();
    }

    this.loadingChange.emit(this.loading);
  }

  errorValue: boolean;

  get error(): boolean {
    return this.errorValue;
  }

  set error(error: boolean) {
    this.errorValue = error;

    if (error) {
      this.loading = false;
    }

    this.loadingChange.emit(this.error);
  }

  dragging: boolean;

  fullscreenEnabled: boolean;
  fullscreen: boolean;

  sw = 0;

  boxw = 0;
  boxh = 0;
  boxx = 0;
  boxy = 0;

  imgw = 0;
  imgh = 0;

  degrees = 0;
  resizeZoom = 100;
  currentZoom = 100;
  readonly MAXZOOM = 500;
  readonly MINZOOM = 25;

  getUserRelationForCase = getUserRelationForCase;

  constructor(
    private elementRef: ElementRef,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.fullscreenEnabled = document.fullscreenEnabled;
    this.userService.filteredProfile$.pipe(take(1)).subscribe({
      next: (profile: User) => {
        this.profile = profile;
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.file && this.file) {
      const previous = getURLFromCache(changes.file.previousValue?.signed?.url);
      const current = getURLFromCache(changes.file.currentValue?.signed?.url);

      if (previous !== current) {
        this.loading = true;
        this.error = false;
        void this.resize();
      }
    }
  }

  async resize(original?: boolean): Promise<void> {
    this.currentZoom = 100;

    if (this.elementRef && this.image) {
      this.imgw = this.image.nativeElement.naturalWidth;
      this.imgh = this.image.nativeElement.naturalHeight;

      const widthZoom = (this.elementRef.nativeElement.offsetWidth / this.imgw) * 100;
      const heightZoom = (this.elementRef.nativeElement.offsetHeight / this.imgh) * 100;

      this.resizeZoom = widthZoom < heightZoom ? widthZoom : heightZoom;

      if (original) {
        this.currentZoom = 100 * (100 / this.resizeZoom);
      }

      this.boxw = this.imgw * (this.currentZoom / 100) * (this.resizeZoom / 100);
      this.boxh = this.imgh * (this.currentZoom / 100) * (this.resizeZoom / 100);

      if (this.file?.type.startsWith('image/heic') && !this.bufferedLoading) {
        this.bufferedLoading = await getImageUrlFromHEIC(this.file.signed.url);
        this.loading = false;
        this.error = false;
      }
    }
  }

  boxDragEnd(event: { distance: { x: number; y: number } }): void {
    this.boxx += event.distance.x;
    this.boxy += event.distance.y;
    setTimeout(() => {
      this.dragging = false;
    }, 300);
  }

  markingDragEnd(event: CdkDragEnd, marking?: Marking): void {
    if (marking && this.edit) {
      const rad = (this.degrees * Math.PI) / 180;
      const distanceX = event.distance.x * Math.cos(rad) + event.distance.y * Math.sin(rad);
      const distanceY = -event.distance.x * Math.sin(rad) + event.distance.y * Math.cos(rad);
      const xpos = marking.geometry.coordinates[0] / (this.imgw / this.boxw) + distanceX;
      const ypos = marking.geometry.coordinates[1] / (this.imgh / this.boxh) + distanceY;

      const pos = {
        x: 0,
        y: 0,
      };

      if (xpos <= this.boxw && xpos >= 0) {
        pos.x = xpos;
      } else {
        if (xpos > this.boxw) {
          pos.x = this.boxw - this.mr / 2;
        } else {
          pos.x = 0;
        }
      }

      if (ypos <= this.boxh && ypos >= 0) {
        pos.y = ypos;
      } else {
        if (ypos > this.boxh) {
          pos.y = this.boxh - this.mr / 2;
        } else {
          pos.y = 0;
        }
      }

      marking.data = {
        imageWidth: this.imgw,
        imageHeight: this.imgh,
      };
      marking.geometry.coordinates = [pos.x * (this.imgw / this.boxw), pos.y * (this.imgh / this.boxh)];
    }

    marking.correct = true;
    this.markingChange.emit(marking);
    setTimeout(() => {
      this.dragging = false;
    }, 300);
  }

  boxClick(event: MouseEvent): void {
    if (!this.dragging) {
      const relativePos = this.getRelativeMarkingPos(
        event.clientX - this.box.nativeElement.getBoundingClientRect().left,
        event.pageY - this.box.nativeElement.getBoundingClientRect().top - window.scrollY,
      );
      const marking = new Marking();

      marking.icon = 'brightness_1';
      marking.geometry = {
        type: 'Point',
        coordinates: [relativePos.x * (this.imgw / this.boxw), relativePos.y * (this.imgh / this.boxh)],
      };
      marking.data = {
        imageWidth: this.imgw,
        imageHeight: this.imgh,
      };
      marking.description = '';
      marking.correct = true;
      this.markingCreate.emit(marking);
    }
  }

  isMarkingHighlighted(marking: Marking): boolean {
    if (this.highlightedMarkingIds?.length) {
      return this.highlightedMarkingIds?.indexOf(marking.id) !== -1;
    } else if (this.highlightedMarkingModelIds?.length) {
      return this.highlightedMarkingModelIds?.indexOf(marking.modelId) !== -1;
    }

    return false;
  }

  getMarkingPos(marking: Marking): { x: number; y: number } {
    const rad = d2r(this.degrees);
    const markingX = marking.geometry.coordinates[0] / (this.imgw / this.boxw);
    const markingY = marking.geometry.coordinates[1] / ((marking.correct ? this.imgh : this.imgw) / this.boxh);
    const sin = Math.sin(rad);
    const cos = Math.cos(rad);
    const cx = this.boxw / 2;
    const cy = this.boxh / 2;
    const ow = this.mr / 2;
    const oh = this.mr / 2;

    return {
      x: (markingX - cx) * cos - (markingY - cy) * sin + cx - ow,
      y: (markingX - cx) * sin + (markingY - cy) * cos + cy - oh,
    };
  }

  getRelativeMarkingPos(x: number, y: number): { x: number; y: number } {
    const rad = d2r(this.degrees);
    const sin = Math.sin(rad);
    const cos = Math.cos(rad);
    const cx = this.boxw / 2;
    const cy = this.boxh / 2;

    const rx = (x - cx) * cos + (y - cy) * sin + cx;
    const ry = (cx - x) * sin + (y - cy) * cos + cy;

    return {
      x: rx,
      y: ry,
    };
  }

  zoom(amount: number): void {
    if (
      (amount > 0 && this.currentZoom + amount < this.MAXZOOM) ||
      (amount < 0 && this.currentZoom + amount > this.MINZOOM)
    ) {
      this.currentZoom += amount;
      this.boxw = this.imgw * (this.currentZoom / 100) * (this.resizeZoom / 100);
      this.boxh = this.imgh * (this.currentZoom / 100) * (this.resizeZoom / 100);
    }
  }

  onWheel(event: WheelEvent): void {
    event.stopPropagation();
    event.preventDefault();

    if (event.deltaY > 0) {
      this.zoom(-5);
    } else {
      this.zoom(5);
    }
  }

  toggleFullscreen(): void {
    this.fullscreen ? this.exitFullscreen() : this.requestFullscreen();
  }

  requestFullscreen(): void {
    if (!this.fullscreen) {
      this.elementRef.nativeElement.requestFullscreen();
      this.fullscreen = true;
    }
  }

  exitFullscreen(): void {
    if (this.fullscreen) {
      void document.exitFullscreen();
      this.fullscreen = false;
    }
  }

  validColor(color: string): boolean {
    return constants.pattern.hexColor.test(String(color));
  }
}
