import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import Cropper from 'cropperjs';
import { Subject, Subscription } from 'rxjs';
import { CroppedImageResult, CroppedImageSize } from './image-crop.types';

@Component({
  selector: 'apex-image-crop',
  templateUrl: './image-crop.component.html',
})
export class ImageCropComponent implements OnChanges, OnDestroy {
  @Input() imageUrl: string;
  @Input() cropperOptions: Cropper.Options;
  @Input() cropCircle = false;
  @Input() croppedImageSize: CroppedImageSize = {
    width: 128,
    height: 128,
  };

  @HostBinding('class.crop-circle')
  get classCropCirle(): boolean {
    return this.cropCircle;
  }

  @Output() croppedImage = new EventEmitter<CroppedImageResult>();
  crop$ = new Subject<CroppedImageResult>();

  cropper: Cropper;
  image: HTMLImageElement;

  loadedError = false;
  ready = false;

  private subscription = new Subscription();

  ngOnChanges(change: SimpleChanges): void {
    if (change.imageUrl && this.cropper) {
      if (this.imageUrl) {
        this.cropper.replace(this.imageUrl);
      } else {
        this.cropper.destroy();
        this.cropper = null;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  imageLoaded(ev: Event): void {
    this.loadedError = false;

    this.image = ev.target as HTMLImageElement;

    if (this.cropper) {
      this.cropper.destroy();
      this.cropper = null;
    }

    this.cropper = new Cropper(this.image, {
      ...this.cropperOptions,
      ready: (): void => {
        this.ready = true;
      },
    });
  }

  imageError(): void {
    this.loadedError = true;
  }

  crop(): void {
    if (this.ready && this.cropper) {
      let canvas = this.cropper.getCroppedCanvas(this.croppedImageSize);

      if (this.cropCircle) {
        canvas = this.getRoundedCanvas(canvas);
      }

      canvas.toBlob(
        (blob) => {
          const croppedImageData: CroppedImageResult = {
            imageData: this.cropper.getImageData(),
            cropData: this.cropper.getCropBoxData(),
            blob,
          };

          this.crop$.next(croppedImageData);
          this.croppedImage.emit(croppedImageData);
        },
        'image/png',
        1,
      );
    }
  }

  private getRoundedCanvas = (sourceCanvas: HTMLCanvasElement): HTMLCanvasElement => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const width = sourceCanvas.width;
    const height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
    context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
    context.fill();

    return canvas;
  };
}
