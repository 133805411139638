<div
  class="week-view df c f1"
  [class.loading-screen]="loading">
  <div class="range-cases-wrapper df mat-elevation-z2">
    <div class="left df jsb ac c">
      <h3
        class="date df tac rm aptxs"
        [matTooltip]="'Week {week}' | translate: { week: currentMoment.isoWeek() }">
        {{ currentMoment.isoWeek() }}
      </h3>
      <button
        type="button"
        mat-icon-button
        *ngIf="rangeCases?.length > 2"
        (click)="showAll = !showAll">
        <mat-icon>{{ showAll ? 'expand_less' : 'expand_more' }}</mat-icon>
      </button>
    </div>
    <div class="right f1">
      <div class="date-name-wrapper df">
        <ng-container *ngFor="let column of dayColumns">
          <div
            class="date-container df jc f1"
            [ngClass]="{ today: column.dayMoment.isSame(today, 'd') }">
            <div
              class="date-name mat-h3 rm aph"
              matRipple
              (click)="goToView.emit({ slot: { moment: column.dayMoment } })">
              {{ column.weekDay }} {{ column.dayMoment?.date() }}
            </div>
          </div>
        </ng-container>
      </div>

      <div class="dates-wrapper f1 df">
        <ng-container *ngFor="let column of dayColumns">
          <div class="date f1"></div>
        </ng-container>
      </div>

      <div class="range-cases-container">
        <ng-container *ngFor="let case of rangeCases; let i = index">
          <div
            class="case-wrapper"
            [ngClass]="{
              'from-before': !case.fromWeek,
              'to-after': !case.toWeek,
              rc: !case.id,
            }"
            [ngStyle]="{
              'width.%': case.length,
              'left.%': case.start,
            }"
            *ngIf="showAll || i < 2">
            <div
              class="case mat-caption"
              [ngClass]="{
                rc: !case.id,
              }"
              [matTooltip]="case.name"
              matRipple
              (click)="!case.id ? rcMenuTrigger.openMenu() : openCase(case, rcMenuTrigger)"
              #rcMenuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="rcMenu"
              [matMenuTriggerData]="{ case: case }">
              <div class="truncate">
                {{ case.name }}
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!showAll && rangeCases?.length > 2">
          <div
            class="more mat-caption new"
            matRipple
            (click)="showAll = !showAll">
            <T
              str="{count} more"
              [vars]="{ count: rangeCases.length - 2 }">
            </T>
          </div>
        </ng-container>
        <ng-container *ngIf="showAll && rangeCases?.length > 2">
          <div
            class="more mat-caption new"
            matRipple
            (click)="showAll = !showAll">
            <T str="show less"></T>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="week-cases-wrapper df f1">
    <div class="df c">
      <ng-container *ngFor="let time of timeValues; first as first">
        <div
          class="time-container new f1"
          (click)="makeCase(time)">
          <span
            class="time mat-caption"
            [class.op-0]="first">
            {{ time }}
          </span>
        </div>
      </ng-container>
    </div>

    <ng-container *ngFor="let column of dayColumns">
      <apex-calendar-day-view
        [dateMoment]="column.dayMoment"
        [showTime]="false"
        [showRange]="false"
        [cases]="cases"
        [repeatableCases]="repeatableCases"
        [futureRepeatableCases]="futureRepeatableCases"
        (viewCase)="viewCase.emit($event)"
        (createCase)="createCase.emit($event)"
        (createCaseFromRepeatableCase)="createCaseFromRepeatableCase.emit($event)"
        (editRepeatableCase)="editRepeatableCase.emit($event)"
        (deleteRepeatableCase)="deleteRepeatableCase.emit($event)">
      </apex-calendar-day-view>
    </ng-container>
  </div>
</div>

<mat-menu #rcMenu="matMenu">
  <ng-template
    matMenuContent
    let-caseData="case"
    let-slot="slot">
    <button
      mat-menu-item
      (click)="createCaseFromRepeatableCase.emit(caseData)">
      <T str="Create case from repeatable case"></T>
    </button>
    <button
      mat-menu-item
      (click)="editRepeatableCase.emit(caseData)">
      <T str="Edit repeatable case"></T>
    </button>
    <button
      mat-menu-item
      (click)="deleteRepeatableCase.emit(caseData)">
      <T str="Delete repeatable case series"></T>
    </button>
  </ng-template>
</mat-menu>
