import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'apexAvatarPipe',
})
export default class AvatarTooltipPipe implements PipeTransform {
  transform(tooltip: string, user: { name: string }): string {
    if (!user) {
      return tooltip;
    }

    if (!tooltip) {
      return `${user.name}`;
    }

    return `${user.name} - ${tooltip}`;
  }
}
