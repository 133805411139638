import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToRouterLink',
  standalone: true,
})
export class StringToRouterLinkPipe implements PipeTransform {
  transform(link: string): string {
    const url = new URL(link, 'https://app.apexapp.io/');

    return url.pathname;
  }
}
