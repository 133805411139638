import { ChecklistDialogReturnData } from './checklist-dialog.types';

export const setChecklistDialogResultValue = (
  res: ChecklistDialogReturnData,
): { templateId; model; modelId; extra } => {
  const { templateId, apartmentId, projectId, objectId, ...extraRes } = res;

  let model = null;
  let modelId = null;

  if (!!apartmentId) {
    model = 'apartment';
    modelId = apartmentId;
  } else if (!!projectId) {
    model = 'project';
    modelId = projectId;
  } else if (!!objectId) {
    model = 'object';
    modelId = objectId;
  }

  Object.keys(extraRes).forEach((k) => extraRes[k] === undefined && delete extraRes[k]);

  return {
    templateId,
    model,
    modelId,
    extra: { params: extraRes },
  };
};
