import { APEXEnvironment } from '../app/utils/types';

export const environment: APEXEnvironment = {
  staging: true,
  production: false,
  test: false,
  assetsUrl: 'https://staging.apexapp.io/assets',
  api: 'https://staging-api.apexapp.io',
  restApi: 'https://staging-rest.apexapp.io',
  apexUrl: 'https://staging.apexapp.io',
  appUrl: 'https://staging.apexapp.io',
  inspectionUrl: 'https://staging-inspection.apexapp.io',
  reportingUrl: 'https://staging-reporting.apexapp.io',
  requestsUrl: 'https://staging-requests.apexapp.io',
  printUrl: 'https://staging-print.apexapp.io',
  portalUrl: 'https://staging-portal.apexapp.io',
  bucket: 'files-staging.apexapp.io',
  bucketUrl: 'https://files-staging.apexapp.io',
  pdfViewerUrl: 'https://pdfviewer.apexapp.io',
  pdfWorkerSrc: 'https://staging.apexapp.io/assets/libs/pdf.worker.min.js',
  logoutUrl: 'https://staging.apexapp.io/auth/sign-out',

  transifexToken: '1/d21a0676ec72779a598b876fbf7c422f776a6e70',

  oAuthCallbackUrl: 'https://staging-api.apexapp.io/user/auth/code',

  pusherInstanceId: 'eac27e58-5c06-4639-a769-74a3fc0badbe',
};
