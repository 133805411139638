<form
  #checklistTemplateItemForm="ngForm"
  class="checklist-template-item apvs df bg-save"
  [class.saved]="saved"
  [cdkDragDisabled]="!checklistTemplateItem.id || disabled"
  cdkDrag>
  <button
    class="asc"
    type="button"
    mat-icon-button
    cdkDragHandle
    [disabled]="!checklistTemplateItem.id || disabled">
    <mat-icon>drag_handle</mat-icon>
  </button>

  <section class="form-wrapper df c f1">
    <section class="df f1 p-c row1">
      <div class="df f1 t-c p-c">
        <div class="p-df df ac f1">
          <mat-form-field
            class="df f1 amr"
            subscriptSizing="dynamic">
            <input
              matInput
              type="text"
              name="name"
              required
              [disabled]="disabled"
              [placeholder]="'Name' | translate"
              [(ngModel)]="checklistTemplateItem.name"
              (ngModelChange)="saveData$.next()"
              (focus)="nameFocus = true"
              (blur)="nameFocus = false" />
            <mat-hint *ngIf="nameFocus && associateUnit">
              <T
                str="Add {binding} to include name of unit"
                [vars]="{
                  binding: '\{{name}}',
                }"></T>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="df f2 p-amt t-amt ac">
          <mat-form-field
            class="f2 amr"
            subscriptSizing="dynamic">
            <textarea
              class="f2"
              matInput
              name="description"
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="6"
              [disabled]="disabled"
              [placeholder]="'Description' | translate"
              [(ngModel)]="checklistTemplateItem.description"
              (ngModelChange)="saveData$.next()"
              (focus)="descFocus = true"
              (blur)="descFocus = false">
            </textarea>
            <mat-hint *ngIf="descFocus && associateUnit">
              <T
                str="Add {binding} to include name of unit"
                [vars]="{
                  binding: '\{{name}}',
                }"></T>
            </mat-hint>
          </mat-form-field>
        </div>

        <apex-autocomplete
          class="d-fdot5 f1 amr amt df d-asc"
          name="category"
          required
          [disabled]="disabled"
          type="CaseCategory"
          [label]="'Category' | translate"
          [(ngModel)]="checklistTemplateItem.CategoryId"
          (ngModelChange)="saveData$.next()">
        </apex-autocomplete>

        <mat-checkbox
          class="amr d-asc df f1"
          name="associateUnit"
          [(ngModel)]="associateUnit"
          [matTooltip]="'Associate item to unit' | translate">
          <T str="Associate unit"></T>
        </mat-checkbox>
      </div>
    </section>

    <section
      class="df f1 p-c row2"
      *ngIf="associateUnit">
      <mat-form-field class="f1 amr">
        <mat-label>
          <T str="Meter Types"></T>
        </mat-label>
        <mat-select
          name="meterTypes"
          multiple
          [(ngModel)]="checklistTemplateItem.data.meterTypes"
          [disabled]="!hasCommercial"
          (ngModelChange)="saveData$.next()">
          <mat-option [value]="MeterType.ColdWater">
            <T str="Cold Water"></T>
          </mat-option>
          <mat-option [value]="MeterType.DistrictHeating">
            <T str="District Heating"></T>
          </mat-option>
          <mat-option [value]="MeterType.HotWater">
            <T str="Hot Water"></T>
          </mat-option>
          <mat-option [value]="MeterType.Power">
            <T
              str="Power"
              [vars]="{ context: 'meter' }">
            </T>
          </mat-option>
          <mat-option [value]="MeterType.Temperature">
            <T
              str="Temperature"
              [vars]="{ context: 'meter' }"></T>
          </mat-option>
          <mat-option [value]="MeterType.Moisture">
            <T
              str="Moisture"
              [vars]="{ context: 'meter' }"></T>
          </mat-option>
          <mat-option [value]="MeterType.SprinklerPressure">
            <T str="Sprinkler Pressure"></T>
          </mat-option>
          <mat-option [value]="MeterType.Other">
            <T str="Other"></T>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="f1 amr"
        [matTooltip]="'Coming soon' | translate">
        <mat-label>
          <T str="Object Types"></T>
        </mat-label>
        <mat-select
          name="objectTypes"
          multiple
          [(ngModel)]="checklistTemplateItem.data.objectTypes"
          [disabled]="true || !hasCommercial">
          <mat-option [value]="ObjectType.Project">
            <T str="Project"></T>
          </mat-option>
          <mat-option [value]="ObjectType.Building">
            <T str="Building"></T>
          </mat-option>
          <mat-option [value]="ObjectType.Floor">
            <T str="Floor"></T>
          </mat-option>
          <mat-option [value]="ObjectType.Room">
            <T str="Room"></T>
          </mat-option>
          <mat-option [value]="ObjectType.Unit">
            <T str="Unit"></T>
          </mat-option>
          <mat-option [value]="ObjectType.Residence">
            <T str="Residence"></T>
          </mat-option>
          <mat-option [value]="ObjectType.Elevator">
            <T str="Elevator"></T>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="f1 amr"
        [matTooltip]="'Coming soon' | translate">
        <mat-label>
          <T str="Disciplines"></T>
        </mat-label>
        <mat-select
          name="fields"
          multiple
          [(ngModel)]="checklistTemplateItem.data.fields"
          [disabled]="true || !hasCommercial">
          <!-- mat-option ngfor fields -->
        </mat-select>
      </mat-form-field>

      <div
        class="df ac w p-jsb"
        [matTooltip]="'Coming soon' | translate">
        <mat-checkbox
          class="amr"
          name="tenancy"
          [(ngModel)]="checklistTemplateItem.data.tenancy"
          [disabled]="true || !hasCommercial">
          <T str="Tenancy"></T>
        </mat-checkbox>

        <mat-checkbox
          class="amr"
          name="agreement"
          [(ngModel)]="checklistTemplateItem.data.agreement"
          [disabled]="true || !hasCommercial">
          <T str="Agreement"></T>
        </mat-checkbox>

        <mat-checkbox
          class="amr"
          name="apartment"
          [(ngModel)]="checklistTemplateItem.data.apartment"
          [disabled]="true || !hasProject">
          <T str="Apartment"></T>
        </mat-checkbox>
      </div>
    </section>
  </section>

  <div
    class="df ac jc p-c"
    [class.c]="associateUnit">
    <button
      type="button"
      mat-icon-button
      color="primary"
      [matTooltip]="'Duplicate' | translate"
      [disabled]="disabled || !checklistTemplateItemForm.valid"
      (click)="duplicateItem()">
      <mat-icon>content_copy</mat-icon>
    </button>
    <button
      type="button"
      mat-icon-button
      color="primary"
      [matTooltip]="'Files' | translate"
      [disabled]="disabled || !checklistTemplateItem?.id"
      (click)="openFileUsage()">
      <mat-icon
        [matBadge]="fuCount"
        [matBadgeHidden]="!fuCount">
        attach_file
      </mat-icon>
    </button>
    <button
      [disabled]="disabled"
      color="warn"
      type="button"
      mat-icon-button
      (click)="remove()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</form>
