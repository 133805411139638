import { Injectable } from '@angular/core';

type IntersectionCallback = (iv: boolean) => void;

@Injectable()
export class IntersectionService {
  private intersectionObserver: IntersectionObserver;
  private map = new Map<Element, IntersectionCallback>();

  private supportsIntersectionObserver = true;

  constructor() {
    if (!IntersectionObserver) {
      this.supportsIntersectionObserver = false;
    } else {
      this.supportsIntersectionObserver = true;
      this.intersectionObserver = new IntersectionObserver((entries) => this.observerCallback(entries));
    }
  }

  public register(element: Element, func: IntersectionCallback): boolean {
    this.map.set(element, func);

    if (!this.supportsIntersectionObserver) {
      this.callback(element, true);

      return false;
    }

    this.intersectionObserver.observe(element);

    return true;
  }

  public unregister(element: Element): boolean {
    this.map.delete(element);

    if (!this.supportsIntersectionObserver) {
      return false;
    }

    this.intersectionObserver.unobserve(element);

    return true;
  }

  protected callback(element: Element, inView: boolean): void {
    const mappedElementFunction = this.map.get(element);

    if (mappedElementFunction) {
      mappedElementFunction(inView);
    }
  }

  private observerCallback(entries: Array<IntersectionObserverEntry>): void {
    for (const entry of entries) {
      this.callback(entry.target, entry && entry.isIntersecting);
    }
  }
}
