import { AfterViewInit, Component, ElementRef, Renderer2 } from '@angular/core';

import { translateRegexp } from './pipe/translate-regexp.pipe';

@Component({
  selector: 'apex-translate-regexp',
  template: '<ng-content></ng-content>',
})
export class TranslateRegexpComponent implements AfterViewInit {
  private node: HTMLElement;

  private first = true;
  private tries = 0;
  private maxTries = 5;

  constructor(
    private elr: ElementRef,
    private r2: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    if (this.first) {
      this.first = false;
      this.node = this.elr.nativeElement.childNodes[0];
    }

    if (this.node) {
      this.r2.setValue(this.node, translateRegexp(this.node.nodeValue));
    } else {
      // @todo this is done because we need that juicy angularjs to keep up
      if (++this.tries > this.maxTries) {
        return;
      }

      setTimeout(() => {
        this.node = this.elr.nativeElement.childNodes[0];
        this.ngAfterViewInit();
      }, 25);
    }
  }
}
