import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { AddonOrder } from '../../../models/addon-order';
import { FileUsage } from '../../../models/file-usage';

import { FileUsageViewerDialogComponent } from '../../../components/file-usage-viewer/file-usage-viewer-dialog.component';
import { FileUsageViewerMode } from '../../../components/file-usage-viewer/file-usage-viewer.types';
import { Case } from '../../../models/case';

@Component({
  selector: 'apex-case-addon-order',
  templateUrl: './case-addon-order.component.html',
})
export class CaseAddonOrderComponent implements OnChanges, OnDestroy {
  @Input() case: Case;

  addonOrder: AddonOrder;
  addonImages: FileUsage[] = [];
  categoryImages: FileUsage[] = [];

  private subscription = new Subscription();

  constructor(private dialog: MatDialog) {}

  ngOnChanges(change: SimpleChanges): void {
    if (change.case && this.case?.AddonOrder) {
      this.addonOrder = new AddonOrder(this.case.AddonOrder);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openImages(): void {
    this.dialog.open(FileUsageViewerDialogComponent, {
      data: {
        editable: false,
        client: false,
        mode: FileUsageViewerMode.View,
        fileUsages: this.addonOrder?.Addon?.images ?? [],
      },
    });
  }

  openMarkings(): void {
    this.dialog.open(FileUsageViewerDialogComponent, {
      data: {
        mode: FileUsageViewerMode.View,
        fileUsages: this.addonOrder.floorplansWithMarkings,
        editable: false,
        client: false,
        modelData: {
          model: 'addon-apartment',
          modelId: this.addonOrder.AddonApartmentId,
        },
      },
    });
  }

  openSignedPDF(fileUsages?: FileUsage[]): void {
    if (!fileUsages?.length) {
      return;
    }

    this.dialog.open(FileUsageViewerDialogComponent, {
      data: {
        mode: FileUsageViewerMode.View,
        fileUsages,
        editable: false,
        client: false,
      },
    });
  }
}
