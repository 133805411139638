<div
  mat-dialog-title
  class="df">
  <h1 class="f1"><T str="Add users"></T></h1>
  <button
    [matTooltip]="'Close' | translate"
    mat-icon-button
    (click)="dialogRef.close()">
    <mat-icon>clear</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <apex-project-involved-users
    [id]="data.id"
    [type]="data.type"></apex-project-involved-users>

  <div>
    <h2><T str="Other user"></T></h2>
    <apex-autocomplete
      class="f1"
      name="ContractorId"
      type="User"
      [excludeIds]="data.excludedUserIds"
      (ngModelChange)="selectedContractor($event)"
      [label]="'Contractor' | translate"
      [(ngModel)]="selectedSingleUser.id"
      (modelChange)="$event ? (selectedSingleUser.name = $event.name) : null">
    </apex-autocomplete>
  </div>

  <section class="df ac jfe">
    <p>
      <T str="Notify"></T>
      :
    </p>
    <mat-checkbox
      [(ngModel)]="involvedService.notifyOnAddSms"
      name="notifyOnAddSms"
      ><T str="SMS"></T
    ></mat-checkbox>
    <mat-checkbox
      [(ngModel)]="involvedService.sendEmail"
      name="sendMail"
      ><T str="Mail"></T
    ></mat-checkbox>
  </section>
</div>

<div mat-dialog-actions>
  <button
    mat-button
    mat-dialog-close>
    <T str="Cancel"></T>
  </button>
  <div class="display-flex ac">
    <p class="amr">
      <T
        str="Added {count}"
        [vars]="{ count: involvedService.selectedUserCount() }"></T>
    </p>

    <button
      color="primary"
      mat-raised-button
      (click)="save()">
      <T str="Save"></T>
    </button>
  </div>
</div>
