import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import ProfileService from '../../features/user/profile/profile.service';
import { QrCodeComponent } from '../qr-code/qr-code.component';

@Component({
  selector: 'apex-utils-button',
  templateUrl: './utils-button.component.html',
})
export default class UtilsButtonComponent {
  constructor(
    private profileService: ProfileService,
    private dialog: MatDialog,
  ) {}

  qrCode(): void {
    const component = new QrCodeComponent(this.dialog);

    component.click();
  }

  async setStartpage(): Promise<void> {
    const url = location.href.replace(location.origin, '');

    await this.profileService.setStartpage(this.profileService.userId, url);
  }
}
