import { Injectable } from '@angular/core';
import { User } from 'projects/apex/src/app/models/user';
import { Observable } from 'rxjs';
import { Apartment } from '../../models/apartment';
import { APIService } from '../../services/http/http.service';
import { createApiUrl } from '../../utils/functions';
import { CollectionResponse, EntityResponse } from '../../utils/types';

@Injectable()
export class ClientService extends APIService<User> {
  route = 'client';

  getClientApartments(clientId: number): Observable<CollectionResponse<Apartment>> {
    if (!clientId) {
      throw new Error('ClientID is required');
    }

    return this.http.get<CollectionResponse<Apartment>>(createApiUrl('client', clientId, 'apartment'), this.options());
  }

  disableClient(clientId: number): Observable<EntityResponse<User>> {
    if (!clientId) {
      throw new Error('ClientID is required');
    }

    return this.http.get<EntityResponse<User>>(createApiUrl('client', clientId, 'disable'), this.options());
  }
}
