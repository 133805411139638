<apex-box icon="description">
  <ng-container title>
    {{ agreement?.name }}
  </ng-container>

  <section class="df c">
    <div class="df ac ams">
      <mat-icon
        class="amrs"
        [matTooltip]="'Agreement' | translate">
        description
      </mat-icon>
      <a
        class="mat-h3 rm"
        [routerLink]="['/object', 'project', objProjectId, 'agreement', agreement?.id]">
        {{ agreement?.name }}
      </a>
    </div>
    <div class="df ac ams">
      <mat-icon
        class="amrs"
        [matTooltip]="agreement?.Object?.type | translateType">
        {{ agreement?.Object | icon }}
      </mat-icon>
      <a
        class="mat-h3 rm"
        [routerLink]="['/object', 'project', objProjectId, 'object', agreement?.Object?.id]">
        {{ agreement?.Object?.name }}
      </a>
    </div>
    <div class="df ac ams">
      <div class="df ac f1 amrs">
        <mat-icon
          class="amrs"
          [matTooltip]="'Period' | translate">
          date_range
        </mat-icon>
        {{ agreement?.from | dater | date: 'shortDate' }} - {{ agreement?.to | dater | date: 'shortDate' }}
      </div>
      <mat-icon
        *ngIf="agreement?.active"
        [matTooltip]="'Active' | translate"
        class="success-color amrs">
        check_circle
      </mat-icon>
    </div>
  </section>
</apex-box>
