import { Component, ElementRef, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { FabItem } from '../fab.types';

@Component({
  selector: 'apex-fab-menu',
  templateUrl: './menu.component.html',
})
export class FabMenuComponent {
  @Input() icon = 'add';
  @Input() color: ThemePalette = 'primary';

  @Input() buttons: FabItem[] = [];

  get filteredButtons(): FabItem[] {
    return this.buttons?.filter((b) => b.visible !== false) ?? [];
  }

  constructor(private ref: ElementRef) {}
}
