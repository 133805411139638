import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AvatarModule } from '../../components/avatar/avatar.module';
import { BoxModule } from '../../components/box/box.module';
import { ItemModule } from '../../components/item/item.module';
import { TranslateModule } from '../../components/translate/translate.module';
import { DaterModule } from '../../pipes/dater/dater.module';
import { NameOrNoneModule } from '../../pipes/name-or-none/name-or-none.pipe.module';
import UserCardOverlayComponent from './user-card-overlay.component';
import { UserCardComponent } from './user-card.component';
import { UserCardDirective } from './user-card.directive';

@NgModule({
  declarations: [UserCardDirective, UserCardComponent, UserCardOverlayComponent],
  exports: [UserCardDirective, UserCardComponent],
  imports: [
    CommonModule,
    RouterModule,
    DaterModule,

    MatIconModule,
    MatButtonModule,
    MatTooltipModule,

    TranslateModule,
    AvatarModule,
    BoxModule,
    MatCardModule,
    ItemModule,
    MatDividerModule,
    NameOrNoneModule,
  ],
  providers: [],
})
export class UserCardModule {}
