import { Configuration } from '../../../../../generated/apex-rest';
import { environment } from '../../environments/environment';

export const restAxiosConfig = (): Configuration =>
  new Configuration({
    basePath: environment.restApi,
    baseOptions: {
      headers: {
        'content-type': 'application/json',
      },
      withCredentials: true,
    },
  });
