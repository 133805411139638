import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Case } from '../../../models/case';

@Component({
  selector: 'apex-case-status-message-dialog',
  templateUrl: './status-message-dialog.component.html',
})
export class CaseStatusMessageDialogComponent implements OnInit {
  type: 'decline' | 'complete' = 'decline';
  message = '';
  visibleForClient = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      case: Case;
      type: 'decline' | 'complete';
    },
    public dialogRef: MatDialogRef<CaseStatusMessageDialogComponent>,
  ) {}

  ngOnInit(): void {
    if (this.data?.type) {
      this.type = this.data.type;
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }

  confirm(): void {
    this.dialogRef.close({
      message: this.message,
      visibleForClient: this.visibleForClient,
    });
  }
}
