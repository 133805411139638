<h1 mat-dialog-title>
  <T
    str="Crop image"
    *ngIf="!data?.dialogTitle"></T>
  <ng-container *ngIf="data?.dialogTitle">
    {{ data.dialogTitle }}
  </ng-container>
</h1>
<div
  mat-dialog-content
  class="df f1">
  <apex-image-crop
    #imageCrop
    [imageUrl]="data?.imageUrl"
    [cropperOptions]="data?.cropperOptions"
    [cropCircle]="data?.cropCircle"
    [croppedImageSize]="data?.croppedImageSize"
    (croppedImage)="save($event)">
  </apex-image-crop>
</div>

<div
  mat-dialog-actions
  class="df">
  <button
    mat-flat-button
    mat-dialog-close>
    <T str="Cancel"></T>
  </button>
  <button
    mat-raised-button
    (click)="imageCrop?.crop()"
    color="primary">
    <T str="Save"></T>
  </button>
</div>
