import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ChecklistGroupService } from 'projects/apex/src/app/features/checklist-group/checklist-group.service';
import { ChecklistGroupModels } from 'projects/apex/src/app/features/checklist-group/checklist-group.types';
import { Checklist } from 'projects/apex/src/app/features/checklist/checklist.model';
import { ChecklistService } from 'projects/apex/src/app/features/checklist/checklist.service';
import { ChecklistGroup } from 'projects/apex/src/app/models/checklist-group';
import { Subscription, forkJoin } from 'rxjs';
import { BoxComponent } from '../../../components/box/box.component';
import {
  ChecklistDialogDirective,
  ChecklistGroupDialogDirective,
} from '../../../components/checklist-dialog/checklist-dialog.directive';

@Component({
  selector: 'apex-checklist-groups',
  templateUrl: './groups.component.html',
})
export class ChecklistGroupsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('boxComponent') boxComponent: BoxComponent;
  @Output() checklistsChange = new EventEmitter<Checklist[]>();
  @Output() groupsChange = new EventEmitter<ChecklistGroup[]>();

  @Input() model: ChecklistGroupModels;
  @Input() modelId: number;

  @Input() projectId: number; // Needed if model is apartment

  @Input() box = true;
  @Input() expanded = true;
  @Input() embedded = false;
  @Input() checklistsExpanded: null | boolean = null;

  @Input() fetchForModel = true;

  checklistsValue: Checklist[] = [];
  groupsValue: ChecklistGroup[] = [];

  showCompleted = false;

  @Input()
  get checklists(): Checklist[] {
    return this.checklistsValue;
  }

  set checklists(checklists: Checklist[]) {
    this.checklistsValue = checklists;
    this.checklistsChange.emit(this.checklists);
  }

  @Input()
  get groups(): ChecklistGroup[] {
    return this.groupsValue;
  }

  set groups(groups: ChecklistGroup[]) {
    this.groupsValue = groups;
    this.groupsChange.emit(this.groups);
  }

  caseCount = 0;
  casesCompleted = 0;

  loading: boolean;

  private subscriptions = new Subscription();

  constructor(
    private dialog: MatDialog,
    private cs: ChecklistService,
    private cgs: ChecklistGroupService,
    private checklistDialogDirective: ChecklistDialogDirective,
    private checklistGroupDialogDirective: ChecklistGroupDialogDirective,
    private route: ActivatedRoute,
  ) {
    this.subscriptions.add(
      this.route.queryParams.subscribe({
        next: (qp) => {
          this.showCompleted = qp.showCompletedChecklists === 'true';

          this.getChecklistGroups();
        },
      }),
    );
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.checklistGroupDialogDirective.groupsChange.subscribe((group: ChecklistGroup) => {
        if (group) {
          if (this.groups?.length) {
            this.groups.unshift(group);
          } else {
            this.groups = [group];
          }

          this.updateCounts();
        }
      }),
    );

    this.subscriptions.add(
      this.checklistDialogDirective.checklistChange.subscribe((checklist: Checklist) => {
        if (checklist) {
          if (this.checklists?.length) {
            this.checklists.push(checklist);
          } else {
            this.checklists = [checklist];
          }
        }
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model || changes.modelId) {
      this.getChecklistGroups();
    }

    this.updateCounts();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getChecklistGroups(): void {
    if (this.model && this.modelId && this.fetchForModel) {
      this.loading = true;

      forkJoin({
        groups: this.cgs.queryByModel(this.model, this.modelId, {
          params: {
            showOpen: 'true',
            showCompleted: this.showCompleted ? 'true' : 'false',
          },
        }),
        checklists: this.cs.queryByModel(this.model, this.modelId, {
          params: {
            open: 'true',
            completed: this.showCompleted ? 'true' : 'false',
          },
        }),
      }).subscribe({
        next: (res) => {
          this.groups = res.groups?.Collection;
          this.checklists = res.checklists?.Collection;

          this.updateCounts();
        },
        error: () => {
          this.groups = [];
          this.checklists = [];
        },
        complete: () => {
          this.loading = false;
        },
      });
    }
  }

  addGroup(): void {
    this.checklistGroupDialogDirective.createChecklistGroupFromTemplate(
      this.model ? this.model : null,
      this.modelId ? this.modelId : null,
      false,
      null,
      this.projectId ? this.projectId : null,
    );
  }

  addChecklist(): void {
    this.checklistDialogDirective.createChecklistFromTemplate(
      this.model ? this.model : null,
      this.modelId ? this.modelId : null,
      false,
      null,
      this.projectId ? this.projectId : null,
    );
  }

  updateCounts(): void {
    let caseCount = 0;
    let casesCompleted = 0;

    if (this.groups?.length) {
      caseCount += this.groups.map((g) => g.caseCount).reduce((a, b) => a + b);
      casesCompleted += this.groups.map((g) => g.casesCompleted).reduce((a, b) => a + b);
    }

    if (this.checklists?.length) {
      caseCount += this.checklists.map((c) => c.caseCount).reduce((a, b) => a + b);
      casesCompleted += this.checklists.map((c) => c.casesCompleted).reduce((a, b) => a + b);
    }

    this.caseCount = caseCount;
    this.casesCompleted = casesCompleted;
  }
}
