<ng-container *ngIf="tenancy && tenancy.id">
  <ng-container *ngIf="edit">
    <ng-container *ngTemplateOutlet="editTenancy"></ng-container>
  </ng-container>
  <ng-container *ngIf="!edit">
    <ng-container *ngTemplateOutlet="viewTenancy"></ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="tenancy && !tenancy.id">
  <ng-container *ngTemplateOutlet="newTenancy"></ng-container>
</ng-container>

<!-- templates -->

<ng-template #viewTenancy>
  <section>
    <h2 class="amh rmb df jsb ac">
      {{ tenancy.name }}

      <button
        mat-icon-button
        [routerLink]="['./edit']"
        routerLinkActive="router-link-active">
        <mat-icon>edit</mat-icon>
      </button>
    </h2>
    <section clasS="info-cards df r w">
      <!-- external -->
      <section class="info-card external df c mat-elevation-z2 am">
        <h3 class="mat-headline-6 df ac am">
          <mat-icon class="amr">menu_open</mat-icon>
          <T str="External"></T>
        </h3>

        <mat-divider></mat-divider>

        <div class="df ac amt">
          <mat-icon
            [matTooltip]="'Rooms' | translate"
            class="am">
            meeting_room
          </mat-icon>
          <mat-chip-listbox *ngIf="tenancy.Objects && tenancy.Objects.length">
            <mat-chip-option *ngFor="let Object of tenancy.Objects">{{ Object.name }}</mat-chip-option>
          </mat-chip-listbox>
          <p *ngIf="!tenancy.Objects || !tenancy.Objects.length">
            <T str="No rooms"></T>
          </p>
        </div>

        <div
          *ngIf="tenancy.TenantCustomerId"
          class="df ac">
          <mat-icon
            class="am"
            [matTooltip]="'Tenant' | translate">
            business
          </mat-icon>
          <div>{{ tenancy.TenantCustomer?.name }}</div>
        </div>

        <div class="df ac jsb">
          <div class="df ac">
            <mat-icon
              class="am"
              [matTooltip]="'Period' | translate">
              date_range
            </mat-icon>
            {{ tenancy.from | date: 'shortDate' }} - {{ tenancy.to | date: 'shortDate' }}
          </div>
          <mat-checkbox
            class="ped apr"
            [(ngModel)]="tenancy.active"
            ><T str="Active"></T
          ></mat-checkbox>
        </div>

        <div class="df afe f1 amb">
          <div class="df ac">
            <mat-icon
              class="am"
              [matTooltip]="'External information' | translate">
              menu
            </mat-icon>
            <div [innerHTML]="tenancy?.informationExternal"></div>
          </div>
        </div>
      </section>

      <!-- internal -->
      <section class="info-card internal df c mat-elevation-z2 am">
        <h3 class="mat-headline-6 df ac am">
          <mat-icon class="amr flip">menu_open</mat-icon>
          <T str="Internal"></T>
        </h3>

        <mat-divider></mat-divider>

        <div class="df ac">
          <mat-icon
            class="am"
            [matTooltip]="'Description' | translate">
            short_text
          </mat-icon>
          {{ tenancy.description }}
        </div>

        <div class="df ac">
          <mat-icon
            class="am"
            [matTooltip]="'VAT' | translate">
            attach_money
          </mat-icon>
          <div>{{ tenancy?.VAT ? tenancy.VAT : 0 }}%</div>
        </div>

        <div class="df ac">
          <mat-icon
            class="am"
            [matTooltip]="'Last updated' | translate">
            today
          </mat-icon>
          {{ tenancy.updatedAt | date: 'shortDate' }}
        </div>

        <div class="df afe f1 amb">
          <div class="df ac">
            <mat-icon
              class="am"
              [matTooltip]="'Internal information' | translate">
              menu
            </mat-icon>
            <div [innerHTML]="tenancy?.informationInternal"></div>
          </div>
        </div>
      </section>

      <apex-file-usage
        class="am info-card"
        view="List"
        [title]="'Files' | translate"
        self="tenancy"
        [selfId]="tenancy.id"
        name="files">
      </apex-file-usage>

      <apex-file-usage
        class="am info-card"
        view="List"
        [title]="'Public Files' | translate"
        [description]="'Will be shared with clients' | translate"
        self="tenancy"
        [selfId]="tenancy.id"
        name="public-files">
      </apex-file-usage>

      <section class="info-card am">
        <apex-model-role
          *ngIf="tenancy.TenantCustomerId"
          [CRM]="false"
          model="tenancy"
          [modelId]="tenancy.id ?? 0"
          role="administrator"
          [label]="'Administrator' | translate"
          [title]="'Administrators' | translate">
        </apex-model-role>
        <apex-model-role
          *ngIf="!tenancy.TenantCustomerId"
          [CRM]="true"
          model="tenancy"
          [modelId]="tenancy.id ?? 0"
          role="tenant"
          [label]="'Tenant' | translate"
          [title]="'Tenants' | translate">
        </apex-model-role>
      </section>

      <apex-entry-cards
        [tenancyId]="tenancy.id"
        (newEntryCardClick)="addEntryCard()"
        class="am set-width"
        style="--width: calc(100% - 32px)">
      </apex-entry-cards>

      <apex-entry-groups
        [tenancyId]="tenancy.id"
        class="am set-width"
        style="--width: calc(100% - 32px)">
      </apex-entry-groups>
    </section>
  </section>
</ng-template>

<ng-template #editTenancy>
  <section class="am">
    <h2 class="mat-h2">
      <T str="Edit"></T>
      {{ tenancy.name }}
    </h2>
    <ng-container *ngTemplateOutlet="newEditTenancy"></ng-container>
  </section>
</ng-template>

<ng-template #newTenancy>
  <section class="am">
    <h2 class="mat-h2">
      <T str="New Tenancy"></T>
    </h2>
    <ng-container *ngTemplateOutlet="newEditTenancy"></ng-container>
  </section>
</ng-template>

<ng-template #newEditTenancy>
  <form
    (ngSubmit)="onSubmit()"
    #tenancyForm="ngForm"
    class="df c ap amv mat-elevation-z2"
    novalidate>
    <section class="df f1 container">
      <!-- external -->
      <section class="external df c f1">
        <h3 class="mat-headline-6 df ac">
          <mat-icon class="amr">menu_open</mat-icon>
          <T str="External"></T>
        </h3>

        <mat-divider></mat-divider>

        <mat-form-field class="">
          <input
            matInput
            type="text"
            name="name"
            required
            [(ngModel)]="tenancy.name"
            [placeholder]="'Name' | translate" />
        </mat-form-field>

        <div class="amb">
          <apex-autocomplete
            type="Customer"
            name="customer"
            [(ngModel)]="tenancy.TenantCustomerId"
            [label]="'Company' | translate">
          </apex-autocomplete>
        </div>

        <apex-object-selector
          [meta]="MetaType.Commercial"
          [multiple]="true"
          name="objectIds"
          [queryParams]="{ RootId: ParentId, children: true }"
          [(ngModel)]="objectIds"
          required>
        </apex-object-selector>

        <div class="df ac">
          <mat-form-field class="f1 amr">
            <mat-label>
              <T str="Rental Period"></T>
            </mat-label>
            <mat-date-range-input
              [rangePicker]="picker"
              required>
              <input
                matStartDate
                name="from"
                [(ngModel)]="tenancy.from"
                [placeholder]="'Start date' | translate"
                required />
              <input
                matEndDate
                name="to"
                [(ngModel)]="tenancy.to"
                [placeholder]="'End date' | translate" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker">
            </mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
          <mat-checkbox
            name="active"
            [(ngModel)]="tenancy.active">
            <T str="Active"></T>
          </mat-checkbox>
        </div>

        <apex-file-usage
          class="amv"
          #fileUsage
          view="List"
          [title]="'Files' | translate"
          self="tenancy"
          [selfId]="tenancy.id || 0"
          name="files">
        </apex-file-usage>

        <apex-file-usage
          class="amv"
          #fileUsagePublic
          view="List"
          [title]="'Public Files' | translate"
          [description]="'Will be shared with clients' | translate"
          self="tenancy"
          [selfId]="tenancy.id || 0"
          name="public-files">
        </apex-file-usage>

        <apex-model-role
          *ngIf="tenancy.TenantCustomerId"
          [CRM]="false"
          model="tenancy"
          [modelId]="tenancy?.id ?? 0"
          role="administrator"
          [label]="'Administrator' | translate"
          [title]="'Administrators' | translate">
        </apex-model-role>
        <apex-model-role
          *ngIf="!tenancy.TenantCustomerId"
          [CRM]="true"
          model="tenancy"
          [modelId]="tenancy?.id ?? 0"
          role="tenant"
          [label]="'Tenant' | translate"
          [title]="'Tenants' | translate">
        </apex-model-role>

        <div class="df afe f1 amv">
          <apex-box
            expanded
            class="db f1 no-padding">
            <T
              title
              str="External Information"></T>

            <editor
              [init]="{ placeholder: 'External Information' | translate } | editorInit"
              name="informationExternalHtml"
              [(ngModel)]="tenancy.informationExternal">
            </editor>
          </apex-box>
        </div>
      </section>

      <mat-divider [vertical]="true"></mat-divider>

      <!-- internal -->
      <section class="internal df c f1 apl">
        <h3 class="mat-headline-6 df ac">
          <mat-icon class="amr flip">menu_open</mat-icon>
          <T str="Internal"></T>
        </h3>

        <mat-divider></mat-divider>

        <mat-form-field class="">
          <input
            matInput
            type="text"
            name="description"
            [(ngModel)]="tenancy.description"
            [placeholder]="'Description' | translate" />
        </mat-form-field>

        <mat-form-field class="">
          <input
            matInput
            type="text"
            name="VAT"
            [(ngModel)]="tenancy.VAT"
            [placeholder]="'VAT' | translate" />
        </mat-form-field>

        <div class="df afe f1 amv">
          <apex-box
            expanded
            class="db f1 no-padding">
            <T
              title
              str="Internal Information"></T>

            <editor
              [init]="{ placeholder: 'Internal Information' | translate } | editorInit"
              name="informationInternalHtml"
              [(ngModel)]="tenancy.informationInternal">
            </editor>
          </apex-box>
        </div>
      </section>
    </section>

    <section class="actions df jsb">
      <div>
        <button
          *ngIf="tenancy.id"
          type="button"
          mat-button
          color="warn"
          (click)="delete()"
          routerLinkActive="router-link-active">
          <T str="Delete"></T>
        </button>
      </div>
      <div>
        <button
          type="button"
          mat-button
          color="primary"
          (click)="back()"
          routerLinkActive="router-link-active">
          <T str="Back"></T>
        </button>
        <button
          [disabled]="!tenancyForm.form.valid || !tenancy.from"
          type="submit"
          color="primary"
          mat-raised-button>
          <T str="Save"></T>
        </button>
      </div>
    </section>
  </form>
</ng-template>
