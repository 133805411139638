import { Model } from './model';

import { File } from './file';
import { Marking } from './marking';
import { User } from './user';

export class FileUsage extends Model<FileUsage> {
  name: string;

  UserId?: number;
  User?: User;

  FileId?: number;
  File?: File;

  self: string;
  selfId: string;

  fileName: string;
  fileSort: number;

  isNew?: boolean;

  Markings?: Marking[];
}
