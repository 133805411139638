<div mat-dialog-title>
  <div class="df jsb ac f1">
    <h1>
      <T str="Case filters"></T>
    </h1>
    <button
      mat-icon-button
      (click)="ref.close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
<!-- Note rpi class is used because of mat-tab needs to get 100% width -->
<div
  mat-dialog-content
  class="rpi">
  <apex-case-filter
    #filter
    [standardParams]="data.standardParams"
    [count]="data.count">
  </apex-case-filter>
</div>
<div mat-dialog-actions>
  <div></div>
  <button
    mat-raised-button
    type="button"
    color="primary"
    (click)="ref.close()">
    <T str="Ok"></T>
  </button>
</div>
