<apex-box expanded>
  <T
    title
    str="Messages"
    [vars]="{ count: caseLogs?.length ?? 0 }"></T>
  <T
    description
    str="Showing {visible} of {count} messages"
    [vars]="{ count: caseLogs?.length ?? 0, visible }"></T>

  <div class="df ac jsb">
    <button
      mat-stroked-button
      (click)="$event.stopPropagation(); showSystemMessages = !showSystemMessages">
      {{ filterTooltip }}
    </button>
  </div>

  <div class="amvs">
    <mat-divider></mat-divider>
  </div>

  <ng-container *ngFor="let caseLog of caseLogs">
    <ng-container *ngIf="!caseLog.system && caseLog.UserId">
      <apex-case-log-user-message
        [showUserCard]="showUserCard"
        [disableToggleActions]="disableToggleActions"
        [disabledMessageThumbnails]="disableMessageThumbnails"
        [caseLog]="caseLog">
      </apex-case-log-user-message>
    </ng-container>
    <ng-container *ngIf="showSystemMessages && (caseLog.system || !caseLog.UserId)">
      <apex-case-log-system-message [caseLog]="caseLog"> </apex-case-log-system-message>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!visible">
    <p class="ambs">
      <T str="No messages to show"></T>
    </p>
  </ng-container>

  <ng-content select="[pagination]"></ng-content>
</apex-box>
