<div
  class="mat-elevation-z2"
  apexSize
  apexSizeBreakPoint="xsmall">
  <ng-container *ngFor="let checklistItem of filteredChecklistItems(checklistItems, true); let i = index">
    <apex-checklist-item
      [sectionName]="sectionName ? sectionName + '.' + (i + sectionNameOffset + 1) : '' + (i + sectionNameOffset + 1)"
      [checklist]="checklist"
      [checklistItem]="checklistItem"
      [checklistItems]="checklistItems">
    </apex-checklist-item>
    <mat-divider></mat-divider>
  </ng-container>
</div>
<mat-divider *ngIf="hasBoth()"></mat-divider>
<div
  class="mat-elevation-z2"
  apexSize
  apexSizeBreakPoint="xsmall">
  <ng-container *ngFor="let checklistItem of filteredChecklistItems(checklistItems, false); let i = index">
    <apex-checklist-item
      [sectionName]="
        sectionName
          ? sectionName + '.' + (i + itemsFromTemplateCount + sectionNameOffset + 1)
          : '' + (i + itemsFromTemplateCount + sectionNameOffset + 1)
      "
      [checklist]="checklist"
      [checklistItem]="checklistItem"
      [checklistItems]="checklistItems"></apex-checklist-item>
    <mat-divider></mat-divider>
  </ng-container>
</div>
