import { Component } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'apex-agenda-button',
  templateUrl: './agenda-button.component.html',
})
export class AgendaButtonComponent {
  constructor(private router: Router) {}

  showAgenda(): void {
    const now = moment();

    void this.router.navigate(['case'], {
      queryParams: {
        deadline: true,
        deadlineFrom: now.startOf('day').valueOf() / 1000,
        deadlineTo: now.endOf('day').valueOf() / 1000,
      },
      fragment: 'list',
    });
  }
}
