import { formatDate } from '@angular/common';
import { HttpRequest } from '@angular/common/http';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Params } from '@angular/router';
import axios from 'axios';
import heic2any from 'heic2any';
import { difference, isNil, noop, orderBy, round } from 'lodash-es';
import moment, { Moment } from 'moment';
import { Observable, merge } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Color } from '../components/charts/charts.colors';
import { t } from '../components/translate/translate.function';
import { Obj } from '../features/object/object.model';
import { Autocomplete } from '../models/autocomplete';
import { Case, Contractor } from '../models/case';
import { CaseContractorStatus } from '../models/case-contractor';
import { Customer } from '../models/customer';
import { CustomerRight } from '../models/customer-right';
import { FileUsage } from '../models/file-usage';
import { User } from '../models/user';
import { constants } from './constants';
import { fallbackLocale } from './fallback-language';
import { supportedLocales } from './locale-init';
import { ObjectWithCustomerId, ObjectWithDataAndParent } from './types';

export const locale = (): string => {
  const lfn = localeFromNavigator();

  if (supportedLocales[lfn]) {
    return lfn;
  }

  return fallbackLocale;
};

export const localeFromNavigator = (): string => {
  const language = navigator.language;

  // We might be somewhere where language is nothing.
  if (!language) {
    return fallbackLocale;
  }

  const split = String(language).split('-');
  const first = split[0];

  if (first) {
    return first.toLowerCase();
  }

  return fallbackLocale;
};

/**
 * This function takes in two objects, each with a CustomerId property and check if they match,
 * e.i. to validate if the current user owns a certain project.
 * @param a any object that has a CustomerId property, e.g. User, Project, etc.
 * @param b any object that has a CustomerId property, e.g. User, Project, etc.
 */
export const isSelfOwned = (a: ObjectWithCustomerId, b: ObjectWithCustomerId): boolean =>
  a?.CustomerId && b?.CustomerId && a.CustomerId === b.CustomerId;

/**
 * This function takes in one objects and removes all keys that have an undefined value,
 * e.i. if you need to patch an object but don't want to set the undefined values
 * @param obj any object
 * @param keysToIgnore
 */
export const removeUndefined = <T>(obj: T, keysToIgnore?: string[]): T => {
  const objectWithoutUndefinedKeys = obj;

  difference(Object.keys(objectWithoutUndefinedKeys), keysToIgnore).forEach((key) => {
    if (objectWithoutUndefinedKeys[key] === undefined || objectWithoutUndefinedKeys[key] === null) {
      delete objectWithoutUndefinedKeys[key];
    }
  });

  return objectWithoutUndefinedKeys;
};

/**
 * Check if every item in an array is the same.
 *
 * @param arr Array to check
 * @returns True if all are equal, false otherwise
 */
export const everyEqual = <T>(arr: Array<T>): boolean => arr.every((v) => v === arr[0]);

const baseCollator = new Intl.Collator(locale(), {
  numeric: true,
  caseFirst: 'upper',
});

type ObjectWithName = { name: string };

export const nameCompare = (a: ObjectWithName, b: ObjectWithName): number =>
  baseCollator.compare(a?.name ?? '', b?.name ?? '');

export const naturalCompare = (a: string, b: string): number => baseCollator.compare(a, b);

export const naturalCompareByKey =
  <T>(key: string) =>
  (a: T, b: T): number =>
    baseCollator.compare(a[key], b[key]);

export const simpleCompare = (a: string, b: string): number => baseCollator.compare(a, b);

export const sortNatural = <T>(
  list: Array<T>,
  key: string,
  options: Intl.CollatorOptions = {
    numeric: true,
    caseFirst: 'upper',
  },
): Array<T> => {
  const collator = new Intl.Collator(locale(), options);

  return list.sort((a, b) => collator.compare(a[key], b[key]));
};

export interface ITotals {
  priceExcludingVAT: number;
  priceVAT: number;
  priceIncludingVAT: number;
}

export const getTotals = (price: number, VAT: number, quantity: number): ITotals => {
  const priceExcludingVAT = quantity * price;
  const priceVAT = priceExcludingVAT * (VAT / 100);
  const priceIncludingVAT = priceExcludingVAT + priceVAT;

  return {
    priceExcludingVAT,
    priceVAT,
    priceIncludingVAT,
  };
};

export const sumTotals = (totals: ITotals[]): ITotals => {
  const sum = {
    priceExcludingVAT: 0,
    priceVAT: 0,
    priceIncludingVAT: 0,
  };

  if (totals?.length && Array.isArray(totals)) {
    totals?.forEach((total) => {
      sum.priceExcludingVAT += total?.priceExcludingVAT || 0;
      sum.priceVAT += total?.priceVAT || 0;
      sum.priceIncludingVAT += total?.priceIncludingVAT || 0;
    });
  }

  return sum;
};

/**
 * Presents the user with a download.
 *
 * @param filename Name of file
 * @param data Content of the file
 * @param type Mimetype
 */
export const presentDownload = (filename: string, data: BlobPart, type: string): void => {
  const a = document.createElement('a');

  document.body.appendChild(a);

  const b = new Blob([data], { type });
  const u = URL.createObjectURL(b);

  a.href = u;
  a.download = filename;
  a.click();

  URL.revokeObjectURL(u);
  a.remove();
};

// @todo Fix this
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const XLSX: any;

export type JSType = string | number | boolean | Date;

export type XLSXJSType = string | number | Date;

export const presentDownloadExcel = (filename: string, data: XLSXJSType[][], sheetName = 'Sheet'): void => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(data);

  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, filename);
};

/**
 * Presents the user with a download using an url.
 *
 * @param url url of file
 * @param name name of file
 */
export const presentDownloadURL = (url: string, name: string): void => {
  void fetch(url).then(async (res: Response) => {
    const b = await res.blob();

    presentDownload(name, b, b.type);
  });
};

/**
 * Assigns the values in from into to.
 * @param from Copy from
 * @param to Copy to
 *
 * @param callback
 * @deprecated do not use
 */
export const lazyAssignData = <T>(from: Record<string, unknown>, to: T, callback?: () => void): void => {
  Object.keys(from).forEach((k) => {
    to[k] = from[k];
  });

  if (callback && typeof callback === 'function') {
    return callback();
  }
};

/**
 * Filter for array that will deliver unique results.
 */
export const unique = <T>(value: T, index: number, self: T[]): boolean => self?.indexOf(value) === index;

/**
 * Utility function for printing the current stack trace.
 *
 * Maybe to find out how you ended up somewhere.
 */
const printStack = (): void => {
  new Error().stack
    .split('\n')
    .slice(2, 4)
    .map((l) => l.trim().replace('at ', ''))
    .concat([''])
    .forEach((l) => console.log(l));
};

export const printStackTrace = !environment.production ? printStack : noop;

const transformQueryParam = (value: unknown): unknown => {
  if (typeof value === 'string') {
    if (value === 'true' || value === 'false') {
      return value === 'true';
    }

    if (Number(value)) {
      return Number(value);
    }
  }

  if (Array.isArray(value)) {
    return value.map(transformQueryParam);
  }

  return value;
};

/**
 * Utility function for converting queryParams from string values into numbers and booleans
 *
 * @param params to transform
 */
export const transformQueryParams = (params: Params): Params => {
  const validParams = {};

  Object.keys(params).forEach((key) => {
    validParams[key] = transformQueryParam(params[key]);
  });

  return validParams;
};

export const getCaseQueryParams = (inputParams: Params): Params => {
  const params = {
    ...inputParams,
  };

  if (!params.hasOwnProperty('showOpen')) {
    params.showOpen = true;
  }

  return transformQueryParams(params);
};

export const autocompleteSplitterString = '&&&&&&&&';

/**
 * Utility function to get count of parents inside an autocomplete model
 *
 * @param model to check for parent
 * @param currentLoop loop count
 */
export const getAutocompleteParentAmount = (model: Autocomplete, currentLoop: number): number => {
  if (model.Parent) {
    currentLoop++;

    return getAutocompleteParentAmount(model.Parent, currentLoop);
  }

  return currentLoop;
};

/**
 * Utility function to sort an autocomplete string
 *
 * @param model to sort string
 * @param previous not to be used
 */
export const sortAutocompleteString = (model: Autocomplete, previous: string): string => {
  previous += `${autocompleteSplitterString}${model.name}`;

  if (model.Parent) {
    return sortAutocompleteString(model.Parent, previous);
  }

  return previous;
};

/**
 * Utility function to sort autocomplete models
 */
export const sortAutocompleteFn = (a: Autocomplete, b: Autocomplete): number => {
  const aParentAmount = getAutocompleteParentAmount(a, 0);
  const bParentAmount = getAutocompleteParentAmount(b, 0);

  if (aParentAmount === bParentAmount) {
    const aSortString = sortAutocompleteString(a, '').split(autocompleteSplitterString).reverse().join(' ');
    const bSortString = sortAutocompleteString(b, '').split(autocompleteSplitterString).reverse().join(' ');

    return simpleCompare(aSortString, bSortString);
  }

  if (aParentAmount === 0) {
    return 1;
  }

  if (bParentAmount === 0) {
    return -1;
  }

  return aParentAmount < bParentAmount ? -1 : 1;
};

export const sortFileUsageFn = (a: FileUsage, b: FileUsage): number =>
  a.fileSort > b.fileSort ? 1 : b.fileSort > a.fileSort ? -1 : 0;

/**
 * Sorts fileUsages by Markings.length and then defined sortOrder.
 *
 * @param fileUsages FileUsages to sort.
 */
export const sortFileUsages = (fileUsages: FileUsage[]): FileUsage[] => {
  if (!fileUsages?.length) {
    return [];
  }

  return orderBy(fileUsages, [(o): number => o.Markings?.length ?? 0, 'fileSort'], ['desc', 'asc']);
};

export const getUserRelationForCase = (user: User, caseToCheck: Case): string => {
  if (caseToCheck && user) {
    const selfContractor = caseToCheck?.Contractors?.find((contractor: Contractor) => contractor.id === user.id);

    if (selfContractor) {
      return selfContractor.CaseContractor?.status ?? '';
    }

    if (caseToCheck.Contractor && caseToCheck.Contractor.CustomerId === user.CustomerId) {
      return caseToCheck.CaseStatusId === 2 ? 'accepted' : 'new';
    }

    // Contractors might be part of my inner circle.
    const contractorCompanies = (caseToCheck.Contractors || []).map((contractor: Contractor) => contractor.CustomerId);

    if (contractorCompanies.indexOf(user.CustomerId) !== -1) {
      return caseToCheck.CaseStatusId === 2 ? 'accepted' : 'new';
    }

    if (caseToCheck.access?.contractor) {
      return caseToCheck.CaseStatusId === 2 ? 'accepted' : 'new';
    }
  }

  return 'beep';
};

/**
 * Scrolls #wrap to given position.
 *
 * @param top Top
 * @param left Left
 */
export const scrollWrap = (top: number, left: number): void => {
  const wrap = document.getElementById('wrap');

  if (wrap) {
    wrap.scrollTop = top;
    wrap.scrollLeft = left;
  }
};

/**
 * Emits boolean true if user closes dialog using backdrop or esc.
 * To be used when you want a dialog to always return data.
 *
 * @param ref MatDialogRef
 */
export const onDialogClose = <T>(ref: MatDialogRef<T>): Observable<boolean> =>
  merge(
    ref.backdropClick().pipe(map(() => true)),
    ref.keydownEvents().pipe(
      map((event: KeyboardEvent) => {
        const key = event.key;

        return key === 'Escape' || key === 'Esc';
      }),
    ),
  ).pipe(
    filter((value) => value),
    take(1),
  );

/**
 * Gets Expires from query params in a url.
 *
 * @param url URL to check
 * @returns Expired value from query params.
 */
export const getExpiredFromURL = (url: string): number => {
  const u = new URL(url);
  const m = u.search.match(/Expires=([0-9]+)/);

  return m && Number(m[1]) > 0 ? Number(m[1]) : 0;
};

/**
 * Check if an url has expired.
 *
 * Uses "Expired" from query params.
 *
 * @param url URL to check
 */
export const urlExpired = (url: string): boolean => {
  const m = moment().utc().unix();
  const e = getExpiredFromURL(url);

  return e <= m;
};

/**
 * Check if a fileUsage has expired.
 *
 * Checks "isNew" on the fileUsage first, then uses the "urlExpired" function
 *
 * @param fileUsage
 */
export const fileUsageExpired = (fileUsage: FileUsage): boolean =>
  !fileUsage?.isNew && fileUsage?.File?.signed?.url && urlExpired(fileUsage.File.signed.url);

/**
 * Check if model type has favorites
 *
 * @param model ModelName to check
 */
export const favoriteAvailable = (model: string): boolean => !!constants.favoritesAvailable.find((f) => f === model);

/**
 * Transforms degrees to radians
 *
 * @param d degrees to transform to radians
 */
export const d2r = (d: number): number => d * (Math.PI / 180);

/**
 * Gets the top most item in a collection.
 */
export const getTopInCollection = (
  pid: number,
  collection: { id: number; ParentId?: number }[],
): { id: number; ParentId?: number } => {
  const f = collection.find((c) => c.id === pid);

  if (!f) {
    return null;
  }

  if (f.ParentId) {
    return getTopInCollection(f.ParentId, collection);
  }

  return f;
};

/**
 * Supported algorithms.
 */
type CryptoAlgorithms = 'SHA-512' | 'SHA-256';

export const sha256base64 = async (message: string): Promise<string> => {
  const sha = await sha256(message);

  return window.btoa(sha);
};

/**
 * Creates a SHA-256 HEX digest.
 */
export const sha256 = async (message: string): Promise<string> => cryptoDigestHEX(message, 'SHA-256');
/**
 * Creates a SHA-512 HEX digest.
 */
export const sha512 = async (message: string): Promise<string> => cryptoDigestHEX(message, 'SHA-512');

/**
 * Creates a digest buffer.
 */
const cryptoDigestBuffer = (message: string, algorithm: CryptoAlgorithms = 'SHA-512'): Promise<ArrayBuffer> => {
  const msgUint8 = new TextEncoder().encode(message);

  return crypto.subtle.digest(algorithm, msgUint8);
};
/**
 * Creates a digest HEX.
 */
const cryptoDigestHEX = async (message: string, algorithm: CryptoAlgorithms = 'SHA-512'): Promise<string> => {
  const hashBuffer = await cryptoDigestBuffer(message, algorithm);

  return Array.from(new Uint8Array(hashBuffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
};

/**
 * Calculates surcharge.
 *
 * Returns value in percent
 *
 * @param priceIn in price
 * @param priceOut out price
 */
export const calculateSurcharge = (priceIn: number, priceOut: number): number =>
  round(((priceOut - priceIn) / priceIn) * 100, 2);

/**
 * Calculates out price using surcharge.
 *
 * @param priceIn in price
 * @param surcharge in percent
 */
export const calculateOutPriceBySurcharge = (priceIn: number, surcharge: number): number =>
  (surcharge / 100) * priceIn + priceIn;

/**
 * Get the closest address upwards for object.
 *
 * @param Object object to find address for
 * @param Objects objects to check (will just use objects that are parents og object)
 */
export const getClosestAddress = (
  Object: Obj,
  Objects: Obj[],
): { address: string; zipcode: string; postal: string } => {
  if (Object?.data?.address && Object?.data?.zipcode && Object?.data?.postal) {
    return {
      address: Object?.data?.address,
      zipcode: Object?.data?.zipcode,
      postal: Object?.data?.postal,
    };
  }

  if (Object?.ParentId) {
    const parent = Objects.find((o) => o.id === Object.ParentId);

    if (parent) {
      return getClosestAddress(parent, Objects);
    }
  }

  return {
    address: '',
    zipcode: '',
    postal: '',
  };
};

export interface Rectangle {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

/**
 * Checks if point is within square
 *
 * @param r1
 * @param r2
 */
export const isRectInRect = (r1: Rectangle, r2: Rectangle): boolean =>
  r1.left <= r2.right && r1.top < r2.bottom && r1.right > r2.left && r1.bottom > r2.top;

const euc = encodeURIComponent;

export const objectToQuery = <T>(o: T): string =>
  Object.keys(o)
    .map((k) => `${euc(k)}=${euc(o[k])}`)
    .join('&');

export const filterObjectToQuery = <T>(o: T): string =>
  Object.keys(o)
    .filter((k) => !!o[k])
    .map((k) => `${euc(k)}=${euc(o[k])}`)
    .join('&');

/**
 * Checks if a character is lower-case.
 *
 * @param char The character to test.
 */
export const charIsLowerCase = (char: string): boolean => /[a-z]/.test(char);

/**
 * Checks if a character is upper-case.
 *
 * @param char The character to test.
 */
export const charIsUpperCase = (char: string): boolean => /[A-Z]/.test(char);

/**
 * Checks if a strings first character is lower-case.
 *
 * @param str The string to test.
 */
export const firstCharIsLowerCase = (str: string): boolean => {
  const char = str?.charAt(0);

  return char && charIsLowerCase(char);
};

/**
 * Checks if a strings first character is upper-case.
 *
 * @param str The string to test.
 */
export const firstCharIsUpperCase = (str: string): boolean => {
  const char = str?.charAt(0);

  return char && charIsUpperCase(char);
};

export const stringIncludes = (value: string, substring: string): boolean =>
  value?.toLowerCase()?.includes(substring?.toLowerCase()) ?? false;

export const isWeak = (color: string, threshold = 235): boolean => {
  if (!color) {
    return false;
  }

  color = color.charAt(0) === '#' ? color.substring(1, 7) : color;

  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  return r * 0.299 + g * 0.587 + b * 0.114 > threshold;
};

export const getStatusMessage = (status: CaseContractorStatus): string => {
  switch (status) {
    case CaseContractorStatus.New:
      return t('Pending');
    case CaseContractorStatus.Accepted:
      return t('Accepted');
    case CaseContractorStatus.Declined:
      return t('Denied');
    case CaseContractorStatus.Completed:
      return t('Done');
    default:
      return '';
  }
};

export const getStatusColor = (status: CaseContractorStatus): Color => {
  switch (status) {
    case CaseContractorStatus.New:
      return Color.AT500;
    case CaseContractorStatus.Accepted:
      return Color.AY300;
    case CaseContractorStatus.Declined:
      return Color.AR300;
    case CaseContractorStatus.Completed:
      return Color.AG300;
    default:
      return Color.AT700;
  }
};

/**
 * Returns a string/icon representation for a Contractor status.
 *
 * @param status Contractor Status.
 * @returns String/icon representation.
 */
export const getStatusIcon = (status: CaseContractorStatus): string => {
  switch (status) {
    case CaseContractorStatus.New:
      return 'pending';
    case CaseContractorStatus.Accepted:
      return 'thumb_up';
    case CaseContractorStatus.Declined:
      return 'thump_down';
    case CaseContractorStatus.Completed:
      return 'task_alt';
    default:
      return '';
  }
};

/**
 * Parses a boolean or a string.
 * True if value and value isn't false and value isn't 'false'.
 *
 * @param value The boolean or string.
 */
export const booleanFromBooleanOrString = (value: boolean | string): boolean =>
  value !== null && value !== false && `${value}` !== 'false';

export function queryParamToArray(key: string, queryParams: Params, asInt?: true): number[];
export function queryParamToArray(key: string, queryParams: Params, asInt?: false): string[];

/**
 * Transforms a single value or a array of values from queryParams into an array.
 *
 * @param key
 * @param queryParams
 * @param asInt
 * @returns
 */
export function queryParamToArray(key: string, queryParams: Params, asInt = true): number[] | string[] {
  const param = queryParams[key];

  if (!param) {
    return [];
  }

  const params = [];

  if (!Array.isArray(param)) {
    params.push(param);
  } else {
    params.push(...param);
  }

  return params.map((s) => (asInt ? parseInt(s, 10) : s));
}

/**
 * Will mark all form controls in form as pristine.
 *
 * @param form
 */
export const markFormPristine = (form: UntypedFormGroup | NgForm): void => {
  Object.keys(form.controls).forEach((control) => {
    form.controls[control].markAsPristine();
  });
};

interface Month {
  name: string;
  translated: string;
}

/**
 * getMonths()
 *
 * @returns An array of months of a year, zero indexed with the translated name.
 */
export const getMonths = (): Month[] => [
  {
    name: 'January',
    translated: t('January'),
  },
  {
    name: 'February',
    translated: t('February'),
  },
  {
    name: 'March',
    translated: t('March'),
  },
  {
    name: 'April',
    translated: t('April'),
  },
  {
    name: 'May',
    translated: t('May'),
  },
  {
    name: 'June',
    translated: t('June'),
  },
  {
    name: 'July',
    translated: t('July'),
  },
  {
    name: 'August',
    translated: t('August'),
  },
  {
    name: 'September',
    translated: t('September'),
  },
  {
    name: 'October',
    translated: t('October'),
  },
  {
    name: 'November',
    translated: t('November'),
  },
  {
    name: 'December',
    translated: t('December'),
  },
];

type Year = number;

/**
 * Returns an array of years from 2016 until now.
 *
 * @returns An array of years.
 */
export const getYears = (): Year[] =>
  Array.from(
    {
      length: new Date().getFullYear() - 2016 + 1,
    },
    (_, i) => 2016 + i,
  );

/**
 * Helper function to use Angular formatDate with locale.
 *
 * @param d Date to format.
 * @param format Angular format string.
 * @returns Date formatted.
 */
export const quickDate = (d: Date, format: string): string => formatDate(d, format, locale());

export const hostMatchesApp: boolean =
  // Live environments
  window.location.hostname === 'app.apexapp.io' ||
  window.location.hostname === 'sandy.apexapp.io' ||
  window.location.hostname === 'staging.apexapp.io' ||
  window.location.hostname === 'portal.apexapp.io' ||
  window.location.hostname === 'inspection.apexapp.io' ||
  // Local environments
  window.location.hostname === 'app.heimdaltest.no' ||
  (window.location.hostname === 'localhost' && String(window.location.port) === '4500') ||
  (window.location.hostname === 'localhost' && String(window.location.port) === '4203') ||
  (window.location.hostname === 'localhost' && String(window.location.port) === '4201');

export const isDateActive = (from: Date | Moment, to?: Date | Moment): boolean =>
  (to ? moment(to).isSameOrAfter(moment(), 'd') : true) && (from ? moment(from).isSameOrBefore(moment(), 'd') : false);

export const hasDateExpired = (date?: Date | Moment): boolean => (date ? moment(date).isBefore(moment(), 'd') : false);

export const periodDateToolTip = (from: Date | Moment, to?: Date | Moment): string =>
  isDateActive(from, to) ? t('Active') : hasDateExpired(to) ? t('Expired') : t('Not active');

/**
 *
 * @param from From Date
 * @param to To Date
 * @param sameAndBefore whether or not the function should check if it is the same or before, or only before
 * @returns return true if from is before to, else false
 */
export const isFromBeforeTo = (from: Date, to: Date, sameAndBefore = true): boolean => {
  let fromBeforeTo = false;

  if (from && to) {
    fromBeforeTo = sameAndBefore ? moment(from).isSameOrBefore(moment(to)) : moment(from).isBefore(moment(to));
  } else {
    // empty is allowed
    fromBeforeTo = true;
  }

  return fromBeforeTo;
};

/**
 * Determines if a HttpRequest is towards current API.
 */
export const isAPIRequest = (request: HttpRequest<unknown>): boolean =>
  request?.url?.startsWith(environment.api) ?? false;

/**
 * Takes an array of params and creates a URL towards our Api.
 * Filters out null or undefined.
 */
export const createApiUrl = (...segments: (string | number)[]): string =>
  [environment.api, ...segments.filter((i) => !isNil(i)).map((i) => String(i))].join('/');

/**
 * Takes an array of params and creates a URL towards our App.
 * Filters out null or undefined.
 */
export const createAppUrl = (...segments: (string | number)[]): string =>
  [environment.appUrl, ...segments.filter((i) => !isNil(i)).map((i) => String(i))].join('/');

/**
 * Parse a string into CSV data.
 *
 * @param input
 * @param delimiter
 */
export const parseCSV = (input: string, delimiter: string): string[][] => {
  // ref: http://stackoverflow.com/a/1293163/2343
  // This will parse a delimited string into an array of
  // arrays. The default delimiter is the comma, but this
  // can be overridden in the second argument.

  // Converted from apex-frontend

  delimiter = delimiter || ',';

  const objPattern = new RegExp(
    // Delimiters.
    `(\\${delimiter}|\\r?\\n|\\r|^)` +
      // Quoted fields.
      `(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|` +
      // Standard fields.
      `([^\"\\${delimiter}\\r\\n]*))`,
    'gi',
  );

  const arrData: string[][] = [[]];

  let arrMatches = null;

  while (!!(arrMatches = objPattern.exec(input))) {
    const strMatchedDelimiter = arrMatches[1];

    if (strMatchedDelimiter.length && strMatchedDelimiter !== delimiter) {
      arrData.push([]);
    }

    let strMatchedValue: string;

    if (arrMatches[2]) {
      strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"');
    } else {
      strMatchedValue = arrMatches[3];
    }

    arrData[arrData.length - 1].push(strMatchedValue);
  }

  return arrData;
};

/**
 * Reads a file handle and resolves it as a string.
 *
 * @param file
 */
export const readFileAsString = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onerror = reject;

    fileReader.onload = (): void => {
      resolve(fileReader.result as string);
    };

    fileReader.readAsText(file);
  });

/**
 * Reads a file handle and resolves it as a ArrayBuffer.
 *
 * @param file
 */
export const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onerror = reject;

    fileReader.onload = (): void => {
      resolve(fileReader.result as ArrayBuffer);
    };

    fileReader.readAsArrayBuffer(file);
  });

export const getUserGeolocation = (): Observable<GeolocationPosition> =>
  new Observable<GeolocationPosition>((observer) => {
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (pos: GeolocationPosition) => {
          observer.next(pos);
          observer.complete();
        },
        (err: GeolocationPositionError) => observer.error(err),
        {
          enableHighAccuracy: true,
          timeout: 120 * 1000,
          maximumAge: 60 * 1000,
        },
      );
    } else {
      observer.error(new Error('Unsupported browser'));
    }
  });

export const getUserGeolocationPromise = (): Promise<GeolocationPosition | null> => {
  if (window.navigator && window.navigator.geolocation) {
    return new Promise((resolve, reject) => {
      window.navigator.geolocation.getCurrentPosition(
        (pos: GeolocationPosition) => {
          resolve(pos);
        },
        (err: GeolocationPositionError) => reject(err),
        {
          enableHighAccuracy: true,
          timeout: 30 * 1000,
          maximumAge: 300 * 1000,
        },
      );
    });
  }

  return null;
};

/**
 * Allows safe adding of a number of parameters that might not be numbers.
 */
export const unsafeAdd = (...numbers: (number | undefined | null)[]): number =>
  (numbers ?? []).reduce((p, c) => {
    if (!isNil(c) && isFinite(c)) {
      return p + c;
    }

    return p;
  }, 0);

/**
 * Traverses upwards an object to find the first occurrence of 'key' in the data object.
 */
export const traverseObjectToFindKeyInData = <T>(obj: ObjectWithDataAndParent, key: string): T | null => {
  let ref = obj.parent;

  while (ref) {
    const found = ref.data[key];

    if (found) {
      return found as T;
    }

    ref = ref.parent;
  }

  return null;
};

/**
 * Format seconds into readable format.
 */
export const humanReadableSeconds = (inputSeconds: number): string => {
  const years = Math.floor(inputSeconds / 31536000);
  const days = Math.floor((inputSeconds % 31536000) / 86400);
  const hours = Math.floor(((inputSeconds % 31536000) % 86400) / 3600);
  const minutes = Math.floor((((inputSeconds % 31536000) % 86400) % 3600) / 60);
  const seconds = (((inputSeconds % 31536000) % 86400) % 3600) % 60;

  return t(
    '{years, select, 0 {} other {{years, plural, =1 {# year} other {# years}}}} {days, select, 0 {} other {{days, plural, =1 {# day} other {# days}}}} {hours, select, 0 {} other {{hours, plural, =1 {# hour} other {# hours}}}} {minutes, select, 0 {} other {{minutes, plural, =1 {# minute} other {# minutes}}}}',
    {
      years,
      days,
      hours,
      minutes,
      seconds,
      _context: 'formatting',
    },
  ).trim();
};

export const humanReadableMinutes = (inputMinutes: number): string => humanReadableSeconds(inputMinutes * 60);
export const humanReadableHours = (inputHours: number): string => humanReadableMinutes(inputHours * 60);

/**
 * Checks if a Customer has a given CustomerRight
 */
export const hasUserCustomerRight = (customer: Customer | undefined, right: keyof CustomerRight): boolean =>
  !!customer?.CustomerRight?.[right] ?? false;

export const base64StringToBlob = (b64Data: string, type = '', sliceSize = 512): Blob => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type });
};

export const blobToBase64String = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = reject;

    reader.onload = (): void => {
      resolve(reader.result as string);
    };

    reader.readAsDataURL(blob);
  });

export const getImageUrlFromHEIC = async (url: string): Promise<string | null> => {
  const canShowHeic = MediaSource.isTypeSupported('image/heic');

  // If browser supports HEIC, return the URL as is.
  if (canShowHeic) {
    return url;
  }

  const response = await axios.get<Blob>(url, { responseType: 'blob' });

  if (response) {
    const jpegBlog = await heic2any({ blob: response.data, toType: 'image/webp' });

    if (Array.isArray(jpegBlog)) {
      console.error('Can not convert HEIC to WebP');

      return null;
    }

    const base64 = await blobToBase64String(jpegBlog);

    return base64;
  }

  return null;
};
