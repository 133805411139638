<apex-page-info [title]="title"></apex-page-info>

<apex-checklist-group-filter></apex-checklist-group-filter>
<section
  *ngIf="(viewGroups$ | async)?.length"
  class="list-container add-margin">
  <div class="bulk-delete-actions">
    <mat-checkbox
      (change)="selectAllChecklistGroups()"
      [checked]="!!selectedGroups?.length && selectedGroups.length === groups.length"
      [indeterminate]="!!selectedGroups?.length && selectedGroups.length !== groups.length">
      <T str="Select all"></T>
    </mat-checkbox>
    <apex-button
      icon="delete"
      [text]="'Delete selected' | translate"
      [disabled]="!selectedGroups?.length"
      (click)="bulkDeleteChecklistGroup()"></apex-button>
  </div>
  <div class="mat-elevation-z2">
    <apex-checklist-group-list
      [groups]="viewGroups$ | async"
      [hasCheckboxes]="true"
      (selectedChange)="updateSelected($event)">
    </apex-checklist-group-list>
  </div>
  <mat-divider></mat-divider>
  <mat-paginator
    class="AK-50-bg"
    [pageSizeOptions]="[25, 50, 100]"
    [length]="count"
    [pageIndex]="page"
    [pageSize]="limit"
    showFirstLastButtons
    (page)="onChangePage($event)">
  </mat-paginator>
</section>
<div
  *ngIf="!(viewGroups$ | async)?.length"
  class="df ac jc am ap">
  <T str="No groups found"></T>
</div>
<button
  mat-fab
  apexChecklistGroupDialog
  [matTooltip]="'New checklist group' | translate"
  type="button">
  <mat-icon>add</mat-icon>
</button>
