import { Component, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { UploadService } from 'projects/apex/src/app/components/upload/upload.service';
import { UploadData, UploadStatus } from 'projects/apex/src/app/components/upload/upload.types';

@Component({
  selector: 'apex-upload',
  templateUrl: './upload.component.html',
})
export class UploadComponent {
  @ViewChild('uploadTable') uploadTable: MatTable<UploadData>;

  displayedColumns = ['name', 'status'];

  UploadStatus = UploadStatus;

  cleared = false;
  expanded = true;

  constructor(public uploadService: UploadService) {
    this.uploadService.listChange.subscribe({
      next: () => {
        this.cleared = false;
      },
    });
  }

  get successfulUploads(): number {
    return this.uploadService.list.filter((l) => l.status === UploadStatus.Success)?.length;
  }

  get failedUploads(): number {
    return this.uploadService.list.filter((l) => l.status === UploadStatus.Error)?.length;
  }

  get hasOngoingUploads(): boolean {
    return !!this.uploadService.list.find(
      (l) => l.status === UploadStatus.Pending || l.status === UploadStatus.Ongoing,
    );
  }
}
