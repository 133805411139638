import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InputDialogData } from './input-dialog.types';

@Component({
  selector: 'apex-input-dialog',
  templateUrl: './input-dialog.component.html',
})
export class InputDialogComponent {
  readonly timerInterval = 250;
  timeProgress = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InputDialogData,
    public ref: MatDialogRef<InputDialogComponent>,
  ) {}

  get invalid(): boolean {
    return !!(!this.data.value || this.data.invalidValues?.find((v) => v === this.data.value));
  }
}
