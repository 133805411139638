import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'projects/apex/src/app/components/confirm-dialog/confirm-dialog.component';
import { TextService } from 'projects/apex/src/app/features/text/text.service';
import { User } from 'projects/apex/src/app/models/user';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import { constants } from 'projects/apex/src/app/utils/constants';
import { Observable, Subject, of } from 'rxjs';
import { catchError, debounceTime, mergeMap, skipWhile } from 'rxjs/operators';
import { Text } from '../../models/text';
import { t } from '../translate/translate.function';

@Component({
  selector: 'apex-text-edit',
  templateUrl: './text-edit.component.html',
})
export class TextEditComponent implements OnInit, OnChanges {
  @Output() textChange = new EventEmitter<Text>();
  @Output() textSaved = new EventEmitter<Text>();
  @Output() textDeleted = new EventEmitter<Text>();

  @ViewChild('form') form: NgForm;

  profile: User;
  isAdmin: boolean;
  canEdit: boolean;

  textValue: Text;

  @Input()
  get text(): Text {
    return this.textValue;
  }

  set text(text: Text) {
    this.textValue = text;
    this.textChange.emit(this.text);
    this.checkIfUserCanEditText();
  }

  html: string;

  req = new Subject<Text>();
  res: Observable<Text> = this.req.pipe(
    debounceTime(constants.inputDebounceTime),
    mergeMap((text: Text) => this.textService.save(text).pipe(catchError(() => of(null)))),
  );
  sub = this.res.subscribe((text) => {
    if (text) {
      this.text = text;
      this.textSaved.emit(text);
    }
  });

  constructor(
    private userService: UserService,
    private textService: TextService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.userService.filteredProfile$.subscribe({
      next: (profile: User) => {
        this.profile = profile;
        this.isAdmin = this.userService.isAdmin(this.profile);
      },
    });
  }

  ngOnChanges(): void {
    if (!this.text) {
      this.text = new Text();
    }

    this.html = this.text.html;
  }

  save(): void {
    if (this.text && this.form?.valid) {
      this.req.next(this.text);
    }
  }

  delete(): void {
    if (this.text?.id) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            text: t('Are you sure you want to delete this text?'),
          },
        })
        .afterClosed()
        .pipe(
          skipWhile((res) => !res),
          mergeMap(() => this.textService.delete(this.text)),
        )
        .subscribe({
          next: () => {
            this.text = new Text();
            this.textDeleted.emit(this.text);
          },
        });
    }
  }

  checkIfUserCanEditText(): void {
    this.canEdit = !!(this.profile && (!this.text?.id || this.text.CreatorId === this.profile.id || this.isAdmin));
  }
}
