import { Injectable } from '@angular/core';
import { Obj } from 'projects/apex/src/app/features/object/object.model';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { HttpExtra } from 'projects/apex/src/app/services/http/http-extra';
import { CollectionResponse } from 'projects/apex/src/app/utils/types';
import { Observable } from 'rxjs';
import { Tenancy } from '../../../../models/tenancy';
import { APIService } from '../../../../services/http/http.service';

@Injectable()
export class TenancyService extends APIService<Tenancy> {
  route = 'tenancy';

  queryWithObject(ObjectId: number, extra?: HttpExtra): Observable<CollectionResponse<Tenancy>> {
    return this.http.get<CollectionResponse<Tenancy>>(
      `${this.apiUrl}/object/${ObjectId}/${this.route}`,
      this.options(extra),
    );
  }

  queryAsClient(extra?: HttpExtra): Observable<CollectionResponse<Tenancy>> {
    return this.http.get<CollectionResponse<Tenancy>>(`${this.apiUrl}/tenancy/as-client`, this.options(extra));
  }

  tree(id: number): Observable<CollectionResponse<Obj>> {
    return this.http.get<CollectionResponse<Obj>>(`${this.apiUrl}/${this.route}/${id}/tree`, this.options());
  }

  getFloorplans(id: number): Observable<FileUsage[]> {
    return this.http.get<FileUsage[]>(`${this.apiUrl}/${this.route}/${id}/floorplans`, this.options());
  }
}
